import { Button } from 'antd'
import { ReactComponent as CheckIcon } from '@common/svg/check.svg'
import styles from './PaymentType.module.scss'

// https://wadiz.atlassian.net/wiki/spaces/TF/pages/16241623278/-+API#%EC%B6%A9%EC%A0%84-PG-%EC%97%B0%EB%8F%99-%EC%A4%80%EB%B9%84
export type PayType = 'bi001' | 'bi003' | null // 신용카드, 계좌이체
type PaymentTypeFunction = {
  value: PayType
  onChange: (type: PayType) => void
}

export const PaymentType: React.FC<PaymentTypeFunction> = ({ value, onChange }) => (
  <div className={styles.container}>
    <div className={styles.title}>{'결제수단'}</div>
    <div className={styles.buttonWrap}>
      <Button
        className={value === 'bi001' ? styles.activate : ''}
        size="large"
        onClick={() => onChange('bi001')}
        icon={<CheckIcon style={{ width: 20, height: 20 }} />}
      >
        {'신용카드'}
      </Button>
      <Button
        className={value === 'bi003' ? styles.activate : ''}
        size="large"
        onClick={() => onChange('bi003')}
        icon={<CheckIcon style={{ width: 20, height: 20 }} />}
      >
        {'실시간 계좌이체'}
      </Button>
    </div>
  </div>
)

