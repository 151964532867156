/**
 * 캠페인 상태값
 */
export const campaignStatusTable = {
  temp: '임시저장',
  insp_req: '검수요청',
  invalid: '수정필요',
  wait: '진행대기',
  ing_update: '진행(수정필요)',
  ing_insp: '진행(검수중)',
  ing: '진행',
  stop_creation_off: '중단(소재OFF)',
  stop_camp_off: '중단(캠페인OFF)',
  stop_camp_range: '중단(캠페인기간)',
  stop_by_admin: '강제중단(소재OFF)',
  complete: '종료',
  del_ready: '종료',
  del_complete: '종료',
} as const

export type CampaignStatusType = keyof typeof campaignStatusTable
export type CampaignStatusDescriptionType = (typeof campaignStatusTable)[CampaignStatusType]

export const campaignStatusMessageTable: { [key in CampaignStatusType]: string } = {
  temp: '',
  insp_req: '검수가 요청된 상태입니다.',
  invalid: '일부 수정이 필요한 소재가 있습니다.',
  wait: '모든 소재가 정상이며 시작일이 도래하면 캠페인이 진행됩니다.',
  ing_update: '캠페인이 정상적으로 진행 중이나 일부 수정이 필요한 소재가 있습니다.',
  ing_insp: '캠페인이 정상적으로 진행 중이며 변경된 소재를 검수하고 있습니다.',
  ing: '캠페인이 정상적으로 진행 중입니다.',
  stop_creation_off: '모든 소재 혹은 모든 광고세트가 OFF되어 캠페인이 중단되었습니다.',
  stop_camp_off: '캠페인이 OFF되어 캠페인이 중단되었습니다.',
  stop_camp_range: '프로젝트 기간이 변경되었습니다. 캠페인 기간을 재설정해주세요.',
  stop_by_admin: '관리자가 강제로 캠페인을 중단했습니다.',
  complete: '캠페인이 종료되었습니다.',
  del_ready: '캠페인이 종료되었습니다.',
  del_complete: '캠페인이 종료되었습니다.',
}

