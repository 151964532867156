import React, { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router';

const PgFailDialog = (props:any) => {
  const navigate = useNavigate();

  useEffect(() => {
    /** 결제 실패 다이얼로그 */
    $('#pgFailDialog').dialog({
      title: '결제 실패 안내', width: 900, closeOnEscape: true,
    });

    /** 확인 */
    $(document).on('click', '#pgFailConfirmBtn', () => {
      $('#pgFailDialog').dialog('close');
      props.setPgModalStatus(null);
      if (localStorage.getItem('chargeShowType') === 'general') {
        navigate('/bzm/charge');
      } else {
        navigate('/camp/make01/req');
      }
    });

    return () => {
      $('#pgFailDialog').dialog('close');
      $('#pgFailDialog').dialog('destroy');
    };
  }, []);
  return (
        <Fragment>
            <form>
                <div id="pgFailDialog" className="dialog" style={{ display: 'none' }}>
                    <div className="dialog-body">
                        <div className="wrap-section wrap-tbl">
                            <div className="box-body">
                                <div className="tbl">
                                    <dl className="vertical">
                                        <dd>
                                            <div className="form-group">
                                                <p className="comp-txt center">
                                                <span className="table">
                                                    <span className="table-cell">
                                                        <b className="fz-20 fc-1 fw-medium">결제 승인에 실패했습니다.</b>
                                                    </span>
                                                </span>
                                                </p>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    실패 사유
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                            <span className="comp-txt">
                                                <span className="table">
                                                    <span className="table-cell">
                                                        <b className="fz-14 fc-2">사유: {props.failInfo.message}</b><br/>
                                                    </span>
                                                </span>
                                                <span className="table">
                                                    <span className="table-cell">
                                                        <b className="fz-14 fc-2">코드: {props.failInfo.code}</b><br/>
                                                    </span>
                                                </span>
                                                <span className="table">
                                                    <span className="table-cell">
                                                        <b className="fz-14 fc-2">주문번호: {props.failInfo.orderId}</b><br/>
                                                    </span>
                                                </span>
                                                <span className="table">
                                                    <span className="table-cell">
                                                        <b className="fz-14 fc-2">결제 승인이 실패되었습니다. 실패사유를 확인하신 후 재시도를 하시거나,<br/>
                                                            계속 실패되시는 경우 고객센터로 문의 주시기 바랍니다.
                                                        </b>
                                                    </span>
                                                </span>
                                            </span>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    문의 안내
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                            <span className="comp-txt">
                                                <span className="table">
                                                    <span className="table-cell">
                                                        <b className="fz-14 fc-2">고객센터 1661-9056 </b>
                                                    </span>
                                                </span>
                                            </span>
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dialog-footer">
                        <button type="button" className="btn btn-primary large" id='pgFailConfirmBtn'>확인</button>
                    </div>
                </div>
            </form>
        </Fragment>
  );
};

export default PgFailDialog;
