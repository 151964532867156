import { Modal, Button } from 'antd'
import aiImage from './ai.png'
import styles from './AIInspectionInModal.module.scss'

type AIInspectionInModalFunction = {
  isOpen: boolean
  onClose: () => void
}
export const AIInspectionInModal: React.FC<AIInspectionInModalFunction> = ({ isOpen, onClose }) => {
  return (
    <Modal
      width={640}
      open={isOpen}
      closable={false}
      title={
        <div className={styles.modalTitle}>
          <h3>{'1차 심의 요청이 완료되었습니다.'}</h3>
        </div>
      }
      footer={[
        <div className={styles.modalFooter} key={'footer'}>
          <Button type="primary" block size="large" onClick={onClose} style={{ width: 200 }}>
            {'확인'}
          </Button>
        </div>,
      ]}
      className={styles.containAIInspectionInModal}
    >
      <div className={styles.modalContent}>
        <div className={styles.ai}>
          <img src={aiImage} alt={'ai'} />
          <div className={styles.notification}>
            {'요청하신 캠페인은'} <b>{'AI 심의로 진행되며 최대 60분 이내로 완료'}</b>
            {'됩니다.'}
            <br />
            {
              '1차 심의가 승인되면 자동으로 2차 심의가 시작되며, 2차 심의 승인 시 캠페인 시작일부터 자동으로 광고가 노출됩니다.'
            }
          </div>
        </div>
        <ul className={styles.progress}>
          <li>
            <span>{'1차 심의'}</span>
            <span>{'와디즈 AI'}</span>
          </li>
          <div className={styles.line} />
          <li>
            <span>{'2차 심의'}</span>
            <span>{'Meta'}</span>
          </li>
          <div className={styles.line} />
          <li>
            <span>{'광고 집행'}</span>
          </li>
        </ul>
        <ul className={styles.comment}>
          <li>{'2차 심의는 메타에서 진행하며 최대 12시간까지 소요될 수 있습니다.'}</li>
          <li>
            {
              '2차 심의 승인 시점으로부터 캠페인 종료 시점까지 24시간 미만인 경우 캠페인이 등록되지 않을 수 있으니 캠페인 기간을 여유있게 설정해주세요.'
            }
          </li>
        </ul>
      </div>
    </Modal>
  )
}
