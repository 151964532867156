import { axios } from '../axios'

type ReturnType = {
  ok: boolean
  data: boolean
}

type FunctionType = (campId: string | number) => Promise<ReturnType>
export const fetchCreationEditingCheck: FunctionType = (campId) =>
  axios.get(`/api/camp/make02/validCrationDataEditing?campId=${campId}`)
