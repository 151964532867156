// TODO 아직 라이브러리 alias를 만들지 않아서 여기에 둔다. 추후 옮기자
// TODO 라이브러리로 옮길때, DailyListTab/SearchToolBox 상태도 확인하자
import { useState } from 'react';
import { Button, Popover } from 'antd';
import { ReactComponent as ChevronDownIcon } from './chevronDown.svg';

type PopButtonFunction = {
  content: React.ReactNode;
  children: React.ReactNode;
  isDataExist: boolean;
};
export const PopButton: React.FC<PopButtonFunction> = ({
  content,
  children,
  isDataExist = false,
}) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <Popover
      content={content}
      trigger='click'
      open={isOpen}
      onOpenChange={setOpen}
      placement='bottomLeft'
      arrow={false}
    >
      <Button
        style={
          isDataExist && !isOpen
            ? { borderRadius: 20, backgroundColor: '#e7f9f9' }
            : isOpen
            ? { borderRadius: 20, borderColor: '#00c4c4' }
            : { borderRadius: 20 }
        }
        icon={
          <ChevronDownIcon
            style={{
              width: 16,
              height: 16,
              fontSize: 16,
              fill: '#495057',
              transition: 'transform 0.2s',
              transform: isOpen ? 'scale(1, -1)' : 'scale(1,1)',
            }}
          />
        }
        iconPosition='end'
      >
        {children}
      </Button>
    </Popover>
  );
};
