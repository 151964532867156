import { ApiUtil3 } from '../legacy'

export type PaymentPgParam = {
  clientKey: string
  customerKey: string
  orderId: string
  orderName: string
  successUrl: string
  failUrl: string
}

export type PaymentProps = {
  projectId: string
  cost: number
  txDiv: 'bi001' | 'bi003'
}

type ResponseType = {
  ok: boolean
  msg?: string // ok가 false라면 메시지를 심어줌
  data: PaymentPgParam
}

type FunctionType = (props: PaymentProps) => Promise<ResponseType>
export const fetchPostPaymentReady: FunctionType = (props) =>
  ApiUtil3.post('/api/pg/payment/ready', props).then((res) => res.data)
