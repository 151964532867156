import React, {
  createContext,
  Fragment, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import { AgGridReact } from 'ag-grid-react';
import { useForm } from 'react-hook-form';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useOutletContext } from 'react-router';
import AdminAccountRegPopUP from './AdminAccountRegPopUP';
import { ApiUtil } from '@biz/api'
import NoDataTemplate from '../common/NoDataTemplate';
import { ApiUtil2 } from '@biz/api'
import Pagination from '../common/Pagination';
import alertify from 'alertifyjs'

export interface IMngAdminAccountSrchForm {
    useYn: string,
    adminRoleNm: string,
    query: string,
}

interface IMngAdminAccount {
    loginId: string,
    useYn: string,
    adminRoleNm: string,
    memberName: string,
    hp: string,
    adminExpireDate: string
    lastLoginTime: string,
    modifier: string
}

export interface IMngAdminAccountList {
    ok: boolean;
    data: IMngAdminAccount[];
}

interface IAdminRole {
    id: string,
    adminRoleNm: string,
    roleList: string
}

export interface IAdminRoleList {
    ok: boolean;
    data: IAdminRole;
}

const dateFormatRenderer = (props: ICellRendererParams) => {
  const { adminExpireDate } = props.data;
  if (adminExpireDate === '' || adminExpireDate === undefined) return '-';
  const year = adminExpireDate.substring(0, 4);
  const month = adminExpireDate.substring(4, 6);
  const day = adminExpireDate.substring(6, 8);
  return `${year}-${month}-${day}`;
};

const dateFormatRendererWithTime = (props: ICellRendererParams) => {
  if (props.value === undefined) return '-';
  const { date } = props.value;
  const { time } = props.value;
  return dayjs(new Date(date.year, date.month - 1, date.day, time.hour, time.minute)).format('YYYY-MM-DD HH:mm');
};

const useYnRenderer = (props: ICellRendererParams) => {
  const { useYn } = props.data;
  return useYn === true ? '활성' : '비활성';
};

export const AdminIdContext = createContext({
  adminId: 'step01',
  setAdminId: (value: any) => value,
});

const MngAdminAccount = () => {
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [adminId, setAdminId] = useState<any>(null);
  const [adminRoles, setAdminRoles] = useState<string[]>([]);
  const childRef = useRef<any>(null);
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>();
  const setTitle = useOutletContext<any>();
  const accountDetailRenderer = (adminInfo: ICellRendererParams) => {
    const { value } = adminInfo;

    return <button onClick={() => {
      setIsClicked(true);
      setAdminId(adminInfo.data.id);
    }}>{value}</button>;
  };

  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'loginId',
      headerName: 'ID',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
      cellRenderer: accountDetailRenderer,
    },
    {
      field: 'useYn',
      headerName: '상태',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: useYnRenderer,
    },
    {
      field: 'adminRoleNm',
      headerName: '권한',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      // cellRenderer: NoticeDetailRenderer,
    },
    {
      field: 'memberName',
      headerName: '담당자명',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'hp',
      headerName: '휴대전화',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'adminExpireDate',
      headerName: '유효기간',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: dateFormatRenderer,
    },
    {
      field: 'lastLoginTime',
      headerName: '최근 접속일시',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: dateFormatRendererWithTime,
    },
    {
      field: 'updateTime',
      headerName: '최근 수정일시',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: dateFormatRendererWithTime,
    },
    {
      field: 'modifier',
      headerName: '수정자',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },

  ]);
    // 컬럼 이동 금지 suppressMovable: true
  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: true,
  }), []);

  const {
    register, handleSubmit, setValue, watch,
  } = useForm<IMngAdminAccountSrchForm>();

  useEffect(() => {
    setTitle('관리자 계정 관리');
    $('#useYnFilter').select2({
      width: 'auto',
      minimumResultsForSearch: Infinity,
    }).on('select2:select', (e) => setValue('useYn', e.params.data.id));

    $('#adminRoleFilter').select2({
      width: '200',
      minimumResultsForSearch: Infinity,
    }).on('select2:select', (e) => setValue('adminRoleNm', e.params.data.id));

    ApiUtil.get<IAdminRoleList>('/api/mngAccount/admin/roleList').then((resp: any) => {
      setAdminRoles(resp.data.data);
    });
    return () => {
      setTitle('');
    };
  }, []);

  const onGridReady = useCallback(() => {
    const {
      useYn, adminRoleNm, query,
    } = watch();
    ApiUtil2.post<IMngAdminAccountList>('/api/mngAccount/admin/list', {
        useYn, adminRoleNm, query
    }).then((resp) => {
      setRowData(resp.data.data);
    });
  }, []);
    // 그리드에서 페이징 변경시 pagination 에 있는 함수 호출
  const changePagination = () => {
        childRef.current!.onPaginationChanged();
  };

  const deleteHandler = () => {
    const selectedRows = gridRef.current!.api.getSelectedRows();
    const adminIds = selectedRows.map((adminData) => adminData.id);
    if (adminIds.length === 0) {
      alertify.error('삭제할 계정을 선택하신 후 다시 시도해 주세요.\n');
    } else {
      alertify.confirm('선택하신 계정을 삭제 하시겠습니까? <br> 삭제 후 복구가 불가 합니다.', () => {
        ApiUtil2.post('/api/mngAccount/admin/delete', { adminIds }).then(() => {
          onGridReady();
          alertify.success('정상적으로 삭제 되었습니다.\n');
        });
      }).set({ labels: { cancel: '취소', ok: '삭제' } }).setHeader('');
    }
  };

  const adminAccountRegPopUpHandler = () => {
    setIsClicked(true);
  };

  return (
    <Fragment>
      {isClicked === true ? (
        <AdminIdContext.Provider value={{ adminId, setAdminId }}>
          <AdminAccountRegPopUP setIsClicked={setIsClicked} adminRoles={adminRoles} onGridReady={onGridReady} />
        </AdminIdContext.Provider>
      ) : null}
      <section className="wrap-section wrap-datagrid">
        <form onSubmit={handleSubmit(onGridReady)}>
          <div className="wrap-filter">
            <div className="inner-filter">
              <div className="box-left">
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2"> 계정 상태</p>
                  </div>
                  <div className="box-filter">
                    <select className="select2-single w-150" id="useYnFilter" {...register('useYn')}>
                      <option value={'all'}>전체</option>
                      <option value={'true'}>활성</option>
                      <option value={'false'}>비활성</option>
                    </select>
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">권한</p>
                  </div>
                  <div className="box-filter">
                    <select className="select2-single w-150" id="adminRoleFilter" {...register('adminRoleNm')}>
                      <option value={'all'}>전체</option>
                      {adminRoles.map((role: any) => (
                        <option key={role.id} value={role.adminRoleNm}>
                          {role.adminRoleNm}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">ID / 담당자명</p>
                  </div>
                  <div className="box-filter">
                    <div className="input-group">
                      <div className="inner-input-group">
                        <input
                          type="text"
                          className="tf-comm"
                          placeholder="ID / 담당자명을 입력해 주세요."
                          id="query"
                          {...register('query')}
                        />
                      </div>
                      <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-right">
                <button type="submit" className="btn btn-tertiary-mint btn-ico">
                  필터 조회
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="box-header">
          <div className="box-tit">
            <button type="button" className="btn btn btn-primary" onClick={adminAccountRegPopUpHandler}>
              등록
            </button>
            <button type="button" className="btn btn btn-secondary-outline" onClick={deleteHandler}>
              삭제
            </button>
          </div>
          <div className="box-option">
            <Link to="/mngAccount/admin/role">
              <button type="button" className="btn btn-primary-outline">
                권한 관리
              </button>
            </Link>
            <Link to="/mngAccount/admin/ip">
              <button type="button" className="btn btn-info-outline">
                접속 IP 관리
              </button>
            </Link>
          </div>
        </div>
        <div className="box-body">
          <div className="ag-grid">
            <div className="ag-grid-inner">
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                // onGridReady={onGridReady}
                rowHeight={48}
                pagination={true}
                paginationPageSize={10}
                suppressPaginationPanel={true}
                onPaginationChanged={changePagination}
                domLayout={'autoHeight'}
                noRowsOverlayComponent={NoDataTemplate}
                rowSelection={'multiple'}
              ></AgGridReact>
            </div>
          </div>
        </div>
        <div className="box-footer">
          <Pagination gridRef={gridRef} ref={childRef} />
        </div>
      </section>
    </Fragment>
  )
};
// MngAdminAccount.propsTypes = {
//   adminInfo: PropTypes.node.isRequired,
// };
export default React.memo(MngAdminAccount);
