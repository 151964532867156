import { Fragment, useEffect, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router'
import { useLocation } from 'react-router-dom'
import alertify from 'alertifyjs'
import AccAdjustDialogTaxbill from './dialog/AccAdjustDialogTaxbill'
import AccAdjustInfoDet from './AccAdjustInfoDet'
import AccAdjustInfoTaxbillInfo from './AccAdjustInfoTaxbillInfo'
import AccAdjustInfoSingle from './AccAdjustInfoSingle'
import AccAdjustInfoTransfer from './AccAdjustInfoTransfer'
import { ApiUtil2 } from '@biz/api'

const AccAdjustInfo = () => {
  const setTitle = useOutletContext<any>()
  const location = useLocation()
  const navigate = useNavigate()

  const [adjustId, setAdjustId] = useState<string | undefined>(undefined)
  const [adjust, setAdjust] = useState<any>(undefined)

  // 임의조정 종류(단건/금액이동)
  const [adjustDiv, setAdjustDiv] = useState<string>('single')

  // dialog 상태값. 단순히 dialog를 생성/삭제하면 안됨. 이 상태값으로 해당 컴포넌트를 보여주고 숨기는 기능이 들어가야함
  const [taxbillDialog, setTaxbillDialog] = useState<boolean>(false)

  const loadAccAdjust = () => {
    ApiUtil2.get('/api/accAdjust/reg/adjustInfo', {
      params: {
        id: adjustId,
      },
    }).then((resp) => {
      const adjustInfo = resp.data.data

      setAdjustDiv(adjustInfo.adjustDiv === 'at' ? 'at' : 'single')
      setAdjust({ ...adjust, ...adjustInfo })
    })
  }

  const openTaxbillRegDialog = () => {
    setTaxbillDialog(true)
  }

  useEffect(() => {
    setTitle('임의조정 관리')
    const param = new URLSearchParams(location.search)
    const accAdjustId = param.get('id')
    if (accAdjustId === null) {
      alertify.alert('비정상 접근입니다.', () => navigate('/accAdjust/mng'))
    } else {
      setAdjustId(accAdjustId)
    }
  }, [])

  useEffect(() => {
    if (adjustId === undefined) return
    loadAccAdjust()
  }, [adjustId, taxbillDialog])

  return (
    <Fragment>
      {/* Wrap-Tbl : Start */}
      <section className="wrap-section wrap-tbl">
        <div className="box-header">
          <h2 className="fz-20 fc-1 fw-bold">임의조정 대상 정보</h2>
        </div>
        <div className="box-body">
          <div className="tbl">
            <dl>
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">임의조정 방식</span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  {/* Comp-Radio : Start */}
                  <div className="comp-radio">
                    <input type="radio" id="inp-radio-07" value="single" disabled checked={adjustDiv === 'single'} />
                    <label htmlFor="inp-radio-07">단일 임의조정</label>
                  </div>
                  {/* Comp-Radio : End */}
                  {/* Comp-Radio : Start */}
                  <div className="comp-radio">
                    <input type="radio" id="inp-radio-08" value="transfer" disabled checked={adjustDiv === 'at'} />
                    <label htmlFor="inp-radio-08">임의조정 금액이동</label>
                  </div>
                  {/* Comp-Radio : End */}
                </div>
              </dd>
            </dl>
            {adjustDiv === 'single' ? (
              <AccAdjustInfoSingle adjust={adjust} />
            ) : (
              <AccAdjustInfoTransfer adjust={adjust} />
            )}
          </div>
        </div>
      </section>
      {/* Wrap-Tbl : End */}

      <AccAdjustInfoDet adjust={adjust} adjustDiv={adjustDiv} />
      {adjust?.taxbill === true ? <AccAdjustInfoTaxbillInfo adjustId={adjustId} /> : null}
      {/* Wrap-Btn-Group : Start */}
      <div className="wrap-btn-group">
        <div className="box-center">
          {adjust?.taxbill === false ? (
            <button type="button" className="btn btn-secondary-outline large" onClick={openTaxbillRegDialog}>
              세금계산서 발행
            </button>
          ) : null}
          <button type="submit" className="btn btn-primary large" onClick={() => navigate('/accAdjust/mng')}>
            확인
          </button>
        </div>
      </div>
      {/* Wrap-Btn-Group : End */}
      {taxbillDialog ? <AccAdjustDialogTaxbill setTaxbillDialog={setTaxbillDialog} accAdjustId={adjustId} /> : null}
    </Fragment>
  )
}
export default AccAdjustInfo
