import { useState } from 'react';
import { Button, DatePicker, Popover, Flex } from 'antd';
import dayjs, { type Dayjs } from 'dayjs';
import { RedoOutlined } from '@ant-design/icons';
import { ReactComponent as ChevronDownIcon } from './chevronDown.svg';
import { ReactComponent as CalendarOutline } from './calendarOutline.svg';

type RangePickerValue = [Dayjs | null, Dayjs | null];
type SearchToolBoxFunction = {
  downloader: React.ReactNode;
  value: RangePickerValue;
  onChange: (param?: RangePickerValue | null) => void;
};

export const SearchToolBox: React.FC<SearchToolBoxFunction> = ({
  downloader,
  value,
  onChange,
}) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <Flex justify="space-between">
      <Flex gap={16}>
        <Popover
          content={
            <DatePicker.RangePicker
              value={value}
              onChange={onChange}
              suffixIcon={<CalendarOutline style={{ width: 16, color: '#495057' }} />}
              cellRender={(current) => {
                return <div className="ant-picker-cell-inner">{(current as Dayjs).date()}</div>
              }}
              size="middle"
              disabledDate={(current: Dayjs) => current > dayjs().startOf('day')}
            />
          }
          trigger="click"
          open={isOpen}
          onOpenChange={setOpen}
          placement="bottomLeft"
          arrow={false}
        >
          <Button
            style={
              !!value?.[0] && !isOpen
                ? { borderRadius: 20, backgroundColor: '#e7f9f9' }
                : isOpen
                ? { borderRadius: 20, borderColor: '#00c4c4' }
                : { borderRadius: 20 }
            }
            icon={
              <ChevronDownIcon
                style={{
                  width: 16,
                  height: 16,
                  fontSize: 16,
                  fill: '#495057',
                  transition: 'transform 0.2s',
                  transform: isOpen ? 'scale(1, -1)' : 'scale(1,1)',
                }}
              />
            }
            iconPosition="end"
          >
            {'기간'}
          </Button>
        </Popover>
        <div>
          <Button
            type="text"
            icon={<RedoOutlined style={{ transform: 'rotate(270deg)' }} />}
            style={{ backgroundColor: '#fff', padding: '0' }}
            onClick={() => onChange([dayjs().subtract(1, 'week'), dayjs()])}
          >
            {'초기화'}
          </Button>
          <Button
            onClick={() => onChange([...value])}
            style={{
              borderColor: '#495057',
              backgroundColor: '#495057',
              color: '#fff',
              marginLeft: 16,
            }}
          >
            {'조회'}
          </Button>
        </div>
      </Flex>
      {downloader}
    </Flex>
  )
};
