import { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { ApiUtil2 } from '@biz/api'

const PgSuccessDialog = (props: any) => {
  const navigate = useNavigate()
  const [pgType, setPgType] = useState('')
  useEffect(() => {
    console.log('page : PgSuccessDialog.tsx')
    setPgType(localStorage.getItem('pgType') as string)
    /** 결제 성공 다이얼로그 */
    $('#pgSuccessDialog').dialog({
      title: localStorage.getItem('pgType') === 'atpt' ? '자동 충전조건 변경' : '결제 성공 안내',
      width: 900,
      closeOnEscape: true,
    })

    /** 확인 */
    $(document).on('click', '#pgSuccessConfirmBtn', () => {
      $('#pgSuccessDialog').dialog('close')
      props.setPgModalStatus(null)
      if (localStorage.getItem('chargeShowType') === 'general') {
        navigate('/bzm/charge')
      } else {
        navigate('/camp/make01/req')
      }
    })

    /** 알림 메소드 호출 */
    if (localStorage.getItem('pgType') !== 'atpt') {
      const adSpend = localStorage.getItem('chargeAmount')
      ApiUtil2.post('/api/bzm/sendInAndExteranlBellAlert', { adSpend }).then(() => {
        console.log('알림 발송 됨.')
      })
    }

    return () => {
      localStorage.removeItem('currUserNo')
      localStorage.removeItem('pgType')
      $('#pgSuccessDialog').dialog('close')
      $('#pgSuccessDialog').dialog('destroy')
    }
  }, [])

  const PgType = () => {
    if (localStorage.getItem('pgType') === 'creditOrAtrt' || localStorage.getItem('pgType') === 'atam') {
      return (
        <span className="comp-txt">
          <span className="table">
            <span className="table-cell">
              <b className="fz-14 fc-2">충전 금액: {Number(localStorage.getItem('chargeAmount')).toLocaleString()} </b>
              <br />
            </span>
          </span>
          <span className="table">
            <span className="table-cell">
              <b className="fz-14 fc-2">충전 사업자 번호: {localStorage.getItem('currUserNo')} </b>
              <br />
            </span>
          </span>
        </span>
      )
    }
    if (localStorage.getItem('pgType') === 'atpt') {
      return (
        <span className="comp-txt">
          <span className="table">
            <span className="table-cell">
              <b className="fz-14 fc-2">
                충전 조건: {Number(localStorage.getItem('atptMinBalance')).toLocaleString()}{' '}
              </b>
              <br />
            </span>
          </span>
          <span className="table">
            <span className="table-cell">
              <b className="fz-14 fc-2">충전 금액: {Number(localStorage.getItem('atptBzmoney')).toLocaleString()} </b>
              <br />
            </span>
          </span>
          <span className="table">
            <span className="table-cell">
              <b className="fz-14 fc-2">충전 사업자 번호: {localStorage.getItem('currUserNo')} </b>
              <br />
            </span>
          </span>
        </span>
      )
    }
    return null
  }

  return (
    <Fragment>
      <form>
        <div id="pgSuccessDialog" className="dialog" style={{ display: 'none' }}>
          <div className="dialog-body">
            <div className="wrap-section wrap-tbl">
              <div className="box-body">
                <div className="tbl">
                  <dl className="vertical">
                    <dd>
                      <div className="form-group">
                        <p className="comp-txt center">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-20 fc-1 fw-medium">
                                {pgType === 'atpt' ? '자동 충전 조건이 변경되었습니다.' : '결제가 완료되었습니다.'}
                              </b>
                            </span>
                          </span>
                        </p>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">상세 내용</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <PgType />
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div className="dialog-footer">
            <button type="button" className="btn btn-primary large" id="pgSuccessConfirmBtn">
              확인
            </button>
          </div>
        </div>
      </form>
    </Fragment>
  )
}

export default PgSuccessDialog
