import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useOutletContext } from 'react-router-dom'
import { ColDef, GridSizeChangedEvent, ICellRendererParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import alertify from 'alertifyjs'

import { ApiUtil, ApiUtil2 } from '@biz/api'

import NoDataTemplate from '../../../common/NoDataTemplate'
import Pagination from '../../../common/Pagination'

import PostpaidMoneyManagementTab from '../PostpaidMoneyManagementTab'

import { AllowedEntrepreneurRegDialog } from './AllowedEntrepreneurRegDialog'
import { PomPermissionByServiceDialog } from './PomPermissionByServiceDialog'

interface IAEMSearchForm {
  aemReqEnum: string
  query?: string
}

interface IAEM {
  data: any
  biznoConfigId: number
  regDate: string
  registrant: string
  bizno: string
  comment: string
  actYn: boolean
}

const successEvent = (msg: string) => {
  alertify.success(msg, 1)
}
const confirmSuccess = (msg: string) => {
  successEvent(msg)
}
const AllowedEntrepreneurManagement = () => {
  const setTitle = useOutletContext<any>()
  const [isRegDialogOpen, setIsRegDialogOpen] = useState(false)
  const [isPermissionDialogOpened, setIsPermissionDialogOpened] = useState<boolean>(false)
  const childRef = useRef<any>(null)
  const gridRef = useRef<AgGridReact>(null)
  const [rowData, setRowData] = useState<any[]>()
  const { register, handleSubmit, getValues, setValue } = useForm<IAEMSearchForm>()
  const AEMDelRenderer = (props: ICellRendererParams) => {
    const deleteBtn = () => {
      alertify
        .confirm(
          '',
          '삭제하시겠습니까?',
          () => {
            ApiUtil.post(
              '/api/bzmAdmin/postpaidMoneyManagement/AEM/delete?biznoConfigId=' + props.data.biznoConfigId
            ).then(() => {
              confirmSuccess('삭제 완료하였습니다.')
              onGridReady()
            })
          },
          {}
        )
        .set({ labels: { cancel: '취소', ok: '확인' } })
    }
    return (
      <span>
        <button onClick={deleteBtn} className="btn btn-primary xsmall">
          삭제
        </button>
      </span>
    )
  }

  const tooltipRenderer = (props: ICellRendererParams) => {
    let comment = props.data.comment
    let configId = props.data.biznoConfigId
    const tooltip = (
      <div id={configId} style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
        {comment}
      </div>
    )
    if (comment.length > 30) {
      $('#' + `${configId}`).tooltip({
        content: '<p class="fz-12 fc-0">' + comment + '</p>',
      })
    }
    return tooltip
  }

  const onGridReady = useCallback(() => {
    /** 20230704 검색어 필수였는데 없애 달라 요청 함 */
    // if ($('#query').val() === '') {
    //     alertify.error('검색어를 입력해주세요.');
    //     return;
    // }
    const data: IAEMSearchForm = getValues()
    ApiUtil2.get<IAEM>('/api/bzmAdmin/postpaidMoneyManagement/AEM/list', {
      params: {
        data: { ...data },
      },
    }).then((resp) => {
      setRowData(resp.data.data)
    })
  }, [])

  const onGridSizeChanged = (params: GridSizeChangedEvent) => {
    params.api.sizeColumnsToFit()
  }
  const changePagination = () => {
    childRef.current!.onPaginationChanged()
  }

  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'regDate',
      headerName: '등록일',
      cellClass: 'ag-left-aligned-cell',
      width: 100,
    },
    {
      field: 'registrant',
      headerName: '등록자',
      cellClass: 'ag-left-aligned-cell',
      width: 50,
    },
    {
      field: 'bizno',
      headerName: '사업자등록번호(주민등록번호)',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
      width: 100,
    },
    {
      field: 'comment',
      headerName: '하용 사유',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
      width: 200,
      cellRenderer: tooltipRenderer,
    },
    {
      field: 'delete',
      headerName: '삭제',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      width: 50,
      cellRenderer: AEMDelRenderer,
    },
  ])

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      resizable: false,
      suppressMovable: true,
      sortable: true,
    }),
    []
  )

  useEffect(() => {
    setTitle('후불머니 관리')
    // onGridReady();
    return () => {
      setTitle('')
    }
  }, [])

  useEffect(() => {
    // 검색 구분
    $('#srchType')
      .select2({
        width: '200',
        placeholder: '선택',
        minimumResultsForSearch: Infinity,
      })
      .on('select2:select', (e) => {
        setValue('aemReqEnum', e.params.data.id)
      })
  }, [])

  const AEMRegDialogHandler = () => {
    setIsRegDialogOpen(true)
  }
  const PomPermissionByServiceDialogHandler = () => {
    setIsPermissionDialogOpened(true)
  }

  return (
    <>
      {isRegDialogOpen && (
        <AllowedEntrepreneurRegDialog setIsDialogOpened={setIsRegDialogOpen} onGridReady={onGridReady} />
      )}
      {isPermissionDialogOpened && (
        <PomPermissionByServiceDialog setIsDialogOpened={setIsPermissionDialogOpened} onGridReady={onGridReady} />
      )}
      <PostpaidMoneyManagementTab />
      <section className="wrap-section wrap-datagrid">
        <form onSubmit={handleSubmit(onGridReady)}>
          <div className="wrap-filter">
            <div className="inner-filter">
              <div className="box-left">
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">검색 구분</p>
                  </div>
                  <div className="box-filter">
                    <select className="select2-single w-150" id="srchType" {...register('aemReqEnum')}>
                      <option value="BIZ_NO" defaultChecked={true}>
                        사업자등록번호
                      </option>
                      <option value="REGISTRANT">등록자</option>
                    </select>
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">검색어</p>
                  </div>
                  <div className="box-filter">
                    <div className="input-group comp-search">
                      <div className="inner-input-group">
                        <input
                          type="text"
                          className="tf-comm"
                          id="query"
                          placeholder="검색어를 입력해 주세요."
                          {...register('query')}
                        ></input>
                        <i className="ico ico-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-right">
                <button type="submit" className="btn btn-tertiary-mint btn-ico">
                  <i className="ico ico-filter"></i>필터 조회
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="box-header">
          <div className="box-tit">
            <button type="button" className="btn btn btn-primary" onClick={AEMRegDialogHandler}>
              신규 등록
            </button>
            <button type="button" className="btn btn btn-primary" onClick={PomPermissionByServiceDialogHandler}>
              서비스 설정
            </button>
          </div>
        </div>
        <div className="box-body">
          <div className="ag-grid">
            <div className="ag-grid-inner">
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowHeight={48}
                pagination={true}
                paginationPageSize={10}
                suppressPaginationPanel={true}
                onPaginationChanged={changePagination}
                onGridSizeChanged={onGridSizeChanged}
                domLayout={'autoHeight'}
                noRowsOverlayComponent={NoDataTemplate}
              />
              {rowData === undefined ? <NoDataTemplate /> : null}
            </div>
          </div>
        </div>
        <div className="box-footer">
          <Pagination gridRef={gridRef} ref={childRef} />
        </div>
      </section>
    </>
  )
}

export default AllowedEntrepreneurManagement
