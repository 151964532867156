import type { RouteObject } from 'react-router-dom'
import type { QueryClient } from '@tanstack/react-query'
import { DefaultLayout } from '../DefaultLayout'
import { SalesDpPush } from './SalesDpPush'
import { FinanceDpPush } from './FinanceDpPush'

type FunctionType = (queryClient: QueryClient) => RouteObject
export const getDefaultLayoutRoute: FunctionType = (queryClient: QueryClient) => ({
  element: <DefaultLayout />,
  children: [
    { path: '/mngRpt/dpPushSales', element: <SalesDpPush /> },
    { path: '/mngRpt/dpPushFinance', element: <FinanceDpPush /> },
  ],
})
