export const ctaTable = {
  APPLY_NOW: '지금신청하기',
  RESERVE_NOW_FB: '지금 예약하기',
  DOWNLOAD_FB: '다운로드',
  GET_A_COUPON: '쿠폰 받기',
  LEARN_MORE_FB: '더 알아보기',
  ORDER_NOW: '지금 주문하기',
  REQUEST_RESERVATION: '예약 요청하기',
  SHOPPING_NOW: '지금 쇼핑하기',
  JOIN_FB: '가입하기',
  MORE_VIDEO: '동영상 더 보기',
  BUY_2: '구매하기',
  USE_THE_APP: '앱 사용하기',
  BUY_1: '구매하기',
} as const
export type CTAListType = keyof typeof ctaTable
export type CTAListDescriptionType = (typeof ctaTable)[CTAListType]
