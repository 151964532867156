import React, { Fragment, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import alertify from 'alertifyjs'
import { ApiUtil } from '@biz/api'
import { ApiUtil2 } from '@biz/api'
import { ISystemData } from '../../community/systemData/SystemData';

const SystemDataForm = () => {
  const setTitle = useOutletContext<any>();
  const [privateText, setPrivateText] = useState<string>('');
  const [termsText, setTermsText] = useState<string>('');
  const [agreePrivateVersion, setAgreePrivateVersion] = useState<string>('');
  const [agreeTermsVersion, setAgreeTermsVersion] = useState<string>('');
  const [agreeYn1, setAgreeYn1] = useState<boolean>(false);
  const [agreeYn2, setAgreeYn2] = useState<boolean>(false);

  useEffect(() => {
    setTitle('개인정보 처리방침 및 광고 서비스 이용약관');
    // eslint-disable-next-line no-use-before-define
    getRecentPrivateData();
    // eslint-disable-next-line no-use-before-define
    getRecentTermsData();

    return () => {
      setTitle('');
    };
  }, []);

  const agreeYn1Handler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgreeYn1(e.target.checked);
  };
  const agreeYn2Handler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgreeYn2(e.target.checked);
  };

  const successEvent = (msg: string) => {
    alertify.success(msg, 1, () => {
      console.log(msg);
    });
  };

  const confirmSuccess = (msg: string) => {
    successEvent(msg);
  };

  const errorEvent = (msg: string) => {
    alertify.error(msg, 1, () => {
      console.log(msg);
    });
  };

  const confirmError = (msg: string) => {
    errorEvent(msg);
  };

  const getRecentPrivateData = () => {
    ApiUtil2.get<ISystemData>('/api/makerCommon/setting/getRecentPrivateData').then((resp) => {
      if (resp.data.data !== undefined) {
        setAgreePrivateVersion(resp.data.data.version);
        const temp = resp.data.data.text;
        setPrivateText(temp);
      } else {
        setAgreePrivateVersion('null');
        setPrivateText('null');
      }
    });
  };

  const getRecentTermsData = () => {
    ApiUtil2.get<ISystemData>('/api/makerCommon/setting/getRecentTermsData').then((resp) => {
      if (resp.data.data !== undefined) {
        setAgreeTermsVersion(resp.data.data.version);
        const temp = resp.data.data.text;
        setTermsText(temp);
      } else {
        setAgreeTermsVersion('null');
        setTermsText('null');
        console.log(termsText);
      }
    });
  };

  const submitHandler = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    if (agreeYn1 === false || agreeYn2 === false) {
      confirmError('모두 동의해야 합니다.');
      return;
    }
    const body = {
      agreePrivateVersion,
      agreeTermsVersion,
    };
    /** 성공 실패 분기해야 함. */
    ApiUtil.post('/api/maker/setting/agreeYn/post', body).then(() => {
      console.log(body);
      confirmSuccess('약관 동의가 완료되었습니다.');
    });
    window.location.href = '/main';
  };

  return (
        <Fragment>
            <section className="wrap-section wrap-tbl wrap-terms">
                <div className="box-header">
                    <div className="box-tit">
                        <h2 className="fz-20 fc-1 fw-bold">개인정보 처리방침</h2>
                        <h3 className="fz-12 fc-3"><span className="fz-12 fc-5">( 필수 )</span></h3>
                    </div>
                    <div className="box-option">
                        <div className="comp-checkbox">
                            <input type="checkbox" id="inp-check-01" onChange={(e) => {
                              agreeYn1Handler(e);
                            }}/>
                            <label htmlFor="inp-check-01">동의</label>
                        </div>
                    </div>
                </div>
                <div className="box-body">
                    <div className="tbl">
                        <dl className="vertical">
                            <dd>
                                <div className="form-group">
                                    <ul className="inner-content-terms">
                                        <li dangerouslySetInnerHTML={{ __html: privateText }}></li>
                                    </ul>
                                </div>
                            </dd>
                        </dl>
                    </div>
                </div>
            </section>
            <section className="wrap-section wrap-tbl wrap-terms">
                <div className="box-header">
                    <div className="box-tit">
                        <h2 className="fz-20 fc-1 fw-bold">광고 서비스 이용약관</h2>
                        <h3 className="fz-12 fc-3"><span className="fz-12 fc-5">( 필수 )</span></h3>
                    </div>
                    <div className="box-option">
                        <div className="comp-checkbox">
                            <input type="checkbox" id="inp-check-02" onChange={(e) => {
                              agreeYn2Handler(e);
                            }}/>
                            <label htmlFor="inp-check-02">동의</label>
                        </div>
                    </div>
                </div>
                <div className="box-body">
                    <div className="tbl">
                        <dl className="vertical">
                            <dd>
                                <div className="form-group">
                                    <ul className="inner-content-terms">
                                        <li dangerouslySetInnerHTML={{ __html: termsText }}></li>
                                    </ul>
                                </div>
                            </dd>
                        </dl>
                    </div>
                </div>
            </section>
            <div className="wrap-btn-group">
                <div className="box-center">
                    <button type="button" className="btn btn-primary xlarge" onClick={submitHandler}>개인정보 처리방침 및 광고 서비스 이용약관 동의</button>
                </div>
            </div>
        </Fragment>
  );
};

export default SystemDataForm;
