import React, {
  Fragment, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useOutletContext } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { useForm } from 'react-hook-form';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { DataFormat } from 'select2';
import BzmTab from './BzmTab';
import NoDataTemplate from '../common/NoDataTemplate';
import Pagination from '../common/Pagination';
import { ApiUtil2 } from '@biz/api'
import SingleSelect2 from '../accAdjust/SingleSelect2';
import RangeDatePicker from "../accAdjust/RangeDatePicker";
import dayjs, {Dayjs} from "dayjs";

interface IBzmMngDebtsByMakerSearchForm {
    startDate:string;
    endDate:string;
    srchType: string;
    query?: string;
}
interface IBzmMngDebtsByMaker {
    data:any;
}

// 렌더러 모음
const costRenderer = (props: ICellRendererParams) => {
  if (props.value === 0 || props.value === undefined || props.value === null || props.value === '') return '-';
  return Number(props.value).toLocaleString();
};

const txDivRenderer = (props: ICellRendererParams) => {
    if (props.value === 'bt002') return '부채 발생';
    else return '부채 상환';
};

const updateTimeRenderer = (props: ICellRendererParams) => {
    return dayjs(`${props.value.date.year}-${props.value.date.month}-${props.value.date.day} ${props.value.time.hour}:${props.value.time.minute}`).format('YYYY-MM-DD HH:mm');

};

const BzmMngDebtsByMaker = () => {
  const setTitle = useOutletContext<any>();
  const childRef = useRef<any>(null);
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>();

  const {
    register, handleSubmit, getValues, setValue,
  } = useForm<IBzmMngDebtsByMakerSearchForm>();

  const onGridReady = useCallback(() => {
    const data : IBzmMngDebtsByMakerSearchForm = getValues();
    ApiUtil2.get<IBzmMngDebtsByMaker>('/api/bzmAdmin/mng/bzmMngDebt/list', {
      params: {
        data: { ...data },
      },
    }).then((resp) => {
      setRowData(resp.data.data);
    });
  }, []);
    // 데이터그리드 관련
  const changePagination = () => {
        childRef.current!.onPaginationChanged();
  };
  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'txTime',
      headerName: '일시',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
        cellRenderer : updateTimeRenderer,
    },
    {
      field: 'loginId',
      headerName: '메이커ID',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'makerNm',
      headerName: '메이커명',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'txDiv',
      headerName: '구분',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: txDivRenderer,
    },
      {
          field: 'debtWithVat',
          headerName: '부채 발생액',
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-center-aligned-cell',
          cellRenderer: costRenderer
      },
    {
      field: 'userNo',
      headerName: '사업자 번호',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'cost',
      headerName: '유상 비즈머니',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: costRenderer,
    },
    {
      field: 'free',
      headerName: '쿠폰',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: costRenderer,
    },
    {
      field: 'campNm',
      headerName: '캠페인명',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'campId',
      headerName: '캠페인ID',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'remainingDebtsByCamp',
      headerName: '캠페인 부채 잔액',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: costRenderer,
    },
    {
      field: 'remainingDebtsByBzm',
      headerName: '계정 부채 잔액',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: costRenderer,
    },
  ]);
  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: true,
    suppressMovable: true,
    sortable: true,
  }), []);

    const registerReqDate = (start:Dayjs, end:Dayjs) => {
        const startDate = start.format('YYYYMMDD');
        const endDate = end.format('YYYYMMDD');
        setValue('startDate', startDate);
        setValue('endDate', endDate);
    };

    const srchTypeData:DataFormat[] = [{ id: 'MAKER_ID', text: '메이커 ID', selected: true }, { id: 'MAKER_NM', text: '메이커명' }];

  const updateSrchType = (value: any) => {
    setValue('srchType', value);
  };

  useEffect(() => {
    setTitle('비즈머니 관리');
    return () => {
      setTitle('');
    };
  }, []);

  useEffect(() => {
    const noDataGrid = (document.querySelector<HTMLElement>('.ag-root-wrapper-body.ag-focus-managed.ag-layout-auto-height')! as any);
    const noTreeDataGrid2 = (document.querySelector<HTMLElement>('.ag-root-wrapper.ag-ltr.ag-layout-auto-height')! as any);
    const noDataTempleteArea = (document.querySelector<HTMLElement>('.ag-overlay-wrapper.ag-layout-auto-height.ag-overlay-no-rows-wrapper')! as any);
    if (noDataGrid !== null) {
      noDataGrid.style.height = '548px';
    }
    if (noTreeDataGrid2 !== null) {
      noTreeDataGrid2.style.height = '548px';
    }
    if (noDataTempleteArea !== null) {
      noDataTempleteArea.style.height = '548px';
    }
  }, []);

  return (
        <Fragment>
            <BzmTab/>
            <section className="wrap-section wrap-datagrid">
                <form onSubmit={handleSubmit(onGridReady)}>
                    <div className="wrap-filter">
                        <div className="inner-filter">
                            <div className="box-left">
                                <div className="item-filter">
                                    <div className="filter-tit">
                                        <p className="fz-12 fc-2">조회 기간</p>
                                    </div>
                                    <div className="box-filter">
                                        <RangeDatePicker changeEvent={registerReqDate} option={{ startDate: dayjs().startOf('day').subtract(30, 'd') }} />
                                    </div>
                                </div>
                                <div className="item-filter">
                                    <div className="filter-tit">
                                        <p className="fz-12 fc-2">검색 구분</p>
                                    </div>
                                    <div className="box-filter">
                                        <SingleSelect2 data={srchTypeData} selectEvent={updateSrchType} />
                                    </div>
                                </div>
                                <div className="item-filter">
                                    <div className="filter-tit">
                                        <p className="fz-12 fc-2">검색어</p>
                                    </div>
                                    <div className="box-filter">
                                        <div className="input-group comp-search">
                                            <div className="inner-input-group">
                                                <input type="text" className="tf-comm" placeholder="검색어를 입력해 주세요." {...register('query')}></input>
                                                <i className="ico ico-search"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-right">
                                <button type="submit" className="btn btn-tertiary-mint btn-ico"><i
                                    className="ico ico-filter"></i>필터 조회
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="box-body">
                    <div className="ag-grid">
                        <div className="ag-grid-inner">
                            <AgGridReact
                                ref={gridRef}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                rowHeight={48}
                                pagination={true}
                                paginationPageSize={10}
                                suppressPaginationPanel={true}
                                onPaginationChanged={changePagination}
                                domLayout={'autoHeight'}
                                noRowsOverlayComponent={NoDataTemplate}
                            />
                            { rowData === undefined ? <NoDataTemplate/> : null}
                        </div>
                    </div>
                </div>
                <div className="box-footer">
                    <Pagination gridRef={gridRef} ref={childRef}/>
                </div>
            </section>
        </Fragment>
  );
};
export default BzmMngDebtsByMaker;
