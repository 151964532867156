import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Form, FormInstance } from 'antd'
import type { GeoInfoType } from '@biz/api' // source type
import { useCampaignAppendInfoMutation, loadAdditionalCampaignInfo, loadCachedLocationList } from '@biz/query'
import { targetFormRegistry } from '@biz/ui'

const { AgeRangePickerName, GenderSelectorName, LocationSelectorName } = targetFormRegistry
export const useUpdateCampaign = (formInstance?: FormInstance) => {
  const queryClient = useQueryClient()

  const form = formInstance || Form.useFormInstance()
  const updateCampaign = useCampaignAppendInfoMutation()

  return useCallback(async () => {
    const v = form.getFieldsValue()
    const locationList = loadCachedLocationList(queryClient)()
    const location = ((v[LocationSelectorName] || []) as string[])
      .map((name) => {
        const foundLocationData = (locationList || []).find((n) => n?.name === name)
        return foundLocationData
      })
      .filter((n) => !!n) as GeoInfoType[]

    const vAge = v[AgeRangePickerName] || []
    const maxAge = `${vAge[1]}${vAge[1] === 65 ? '+' : ''}`
    const minAge = `${vAge[0]}`

    const genderDiv = v[GenderSelectorName] || []

    const campaignData = await loadAdditionalCampaignInfo(queryClient)()
    const { adSetExtYn, id: adSetId, campId } = campaignData || {}
    return updateCampaign.mutateAsync({ location, maxAge, minAge, genderDiv, adSetExtYn, adSetId, campId })
  }, [updateCampaign, form])
}
