import { fetchDailyDownload, fetchAdDownload } from '@biz/api'

export const useDownloadFile =
  () =>
  async ({ index, params }: { index: 'daily' | 'ad'; params: string }) => {
    try {
      const response = await(index === 'daily' ? fetchDailyDownload : fetchAdDownload)(params)
      const url = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = url

      const startDate = params.match(/startDate=(\d{8})/)?.[1] || ''
      const endDate = params.match(/endDate=(\d{8})/)?.[1] || ''
      link.setAttribute('download', `매출리포트-${startDate}~${endDate}.csv`)
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(url)
      link?.parentNode?.removeChild(link)
    } catch (err) {
      console.error(err)
    }
  }
