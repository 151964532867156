import React, {
  Fragment, useEffect, useImperativeHandle, forwardRef, useState, useRef,
} from 'react';
import { PlainObject } from 'select2';
// 공통 페이징은 디자인을 추가로 해야함

const Pagination2 = (props: any, ref: any) => {
  const [isFirst, chkFirst] = useState(true);
  const [isLast, chkLast] = useState(true);
  const [isSelectDisabled, chkSelectDisabled] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const { gridRef } = props;
  const pagesizeSelect = useRef<HTMLSelectElement>(null);

  const pageSizes = [10, 20, 30, 40, 50];
  const pageInterval = 10;

  const [pageNumObj, pageMoves] = useState({
    firstPageNum: 1,
    lastPageNum: 0,
    curPageNum: 1,
  });

  const pageSizeOptTag: any[] = [];
  pageSizes.forEach((value, index) => {
    pageSizeOptTag.push(<option key={index} value={value}>{value} Row</option>);
  });

  const PageNumberNav = () => {
    const pageNumbers: JSX.Element[] = [];
    if (pageNumObj.lastPageNum === 0) return <Fragment></Fragment>;
    for (let i = pageNumObj.firstPageNum; i <= pageNumObj.lastPageNum; i += 1) {
      pageNumbers.push(<a key={i} href="#!" className={ `page-num${pageNumObj.curPageNum === i ? ' selected' : ''}` } data-value={i} id={`pageNumberMove_${i}`} >{i}</a>);
    }
    return <Fragment>{ pageNumbers }</Fragment>;
  };

  const onPageSizeChanged = () => {
    gridRef.current!.api!.paginationSetPageSize((pagesizeSelect.current!).value as unknown as number);
  };
  const onPaginationChanged = () => {
    if (!gridRef.current!.api) return;
      // 페이징 체크박스 부분에서 페이징 및 체크박스 관련 이벤트 발생시 체크박스 모두 해제 후
      // 페이지에 맞는 로우만 전체 체크박스 선택
      gridRef.current!.api.deselectAll();

      // Initialize pagination data
      const paginationSize = gridRef.current!.api.paginationGetPageSize();
      const currentPageNum = gridRef.current!.api.paginationGetCurrentPage();
      const totalPages = gridRef.current!.api.paginationGetTotalPages();
      // getDisplayedRowCount -> paginationGetRowCount 로 변경 트리그리드에서 트리를 펼칠때 총 카운트가 변경되는 문제 발생
      const totalRowsCount = gridRef.current!.api.paginationGetRowCount();

      // Calculate current page row indexes
      const currentPageRowStartIndex = (currentPageNum * paginationSize);
      let currentPageRowLastIndex = (currentPageRowStartIndex + paginationSize);
      if (currentPageRowLastIndex > totalRowsCount) currentPageRowLastIndex = (totalRowsCount);

      for (let i = 0; i < totalRowsCount; i += 1) {
        const isWithinCurrentPage = (i >= currentPageRowStartIndex && i < currentPageRowLastIndex);
          gridRef.current!.api.getDisplayedRowAtIndex(i).setRowSelectable(isWithinCurrentPage);
      }

      if (totalPages > 0) {
        pageMoves((prevPage) => {
          const currPage = { ...prevPage };

          currPage.lastPageNum = totalPages > currPage.firstPageNum + pageInterval - 1 ? currPage.firstPageNum + pageInterval - 1 : totalPages;
          currPage.firstPageNum = currPage.lastPageNum >= pageInterval && currPage.lastPageNum - pageInterval + 1 < currPage.firstPageNum ? currPage.lastPageNum - pageInterval + 1 : currPage.firstPageNum;
          currPage.curPageNum = currentPageNum + 1;

          if (currPage.curPageNum > currPage.lastPageNum) {
            currPage.lastPageNum = currPage.curPageNum;
            currPage.firstPageNum = currPage.curPageNum - pageInterval + 1 > 0 ? currPage.curPageNum - pageInterval + 1 : 1;
          } else if (currPage.curPageNum < currPage.firstPageNum) {
            currPage.firstPageNum = currPage.curPageNum;
            currPage.lastPageNum = currPage.curPageNum + pageInterval - 1;
          }

          return currPage;
        });
      } else {
        pageMoves({
          firstPageNum: 1,
          lastPageNum: 0,
          curPageNum: 1,
        });
      }
      setTotalRows(totalRowsCount);
      chkSelectDisabled(totalPages === 0);
      chkFirst(currentPageNum === 0);
      chkLast(totalPages === 0 || totalPages === currentPageNum + 1);
  };

  // 부모컴포넌트에서 자식 컴포넌트의 함수를 실행하기 위한 forwardRef와 useImperativeHandle을 사용
  useImperativeHandle(ref, () => ({
    onPaginationChanged,
  }));
  useEffect(() => {
    $('#onBtFirst').on('click', () => {
      pageMoves((prevState) => ({ ...prevState, curPageNum: 1 }));
      gridRef.current!.api.paginationGoToFirstPage();
    });
    $('#onBtPrevious').on('click', () => {
      pageMoves((prevState) => ({ ...prevState, curPageNum: prevState.curPageNum - 1 }));
      gridRef.current!.api.paginationGoToPreviousPage();
    });
    $('#onBtNext').on('click', () => {
      pageMoves((prevState) => ({ ...prevState, curPageNum: prevState.curPageNum + 1 }));
      gridRef.current!.api.paginationGoToNextPage();
    });
    $('#onBtLast').on('click', () => {
      pageMoves((prevState) => ({ ...prevState, curPageNum: gridRef.current!.api.paginationGetTotalPages() }));
      gridRef.current!.api.paginationGoToLastPage();
    });

    $(document).on('click', '[id^=pageNumberMove_]', (e) => {
      gridRef.current!.api.paginationGoToPage(Number(e.target.dataset.value) - 1);
      pageMoves((prevState) => ({ ...prevState, curPageNum: Number(e.target.dataset.value) }));
    });

    $(pagesizeSelect.current as PlainObject).select2({
      width: '120px',
      minimumResultsForSearch: Infinity,
      dropdownCssClass: 'small',
    }).on('select2:select', () => {
      onPageSizeChanged();
    });
    onPaginationChanged();
  }, []);
  return (
        <Fragment>
            <div className="box-left">
              <div className="pagenation">
                <span className="list-total">총 <em className="fw-medium">{totalRows.toLocaleString(undefined, { })}</em>건</span>
              </div>
            </div>
            <div className="box-right">
              <div className="pagenation">
                  <select className="select2-single small" disabled={isSelectDisabled} ref={pagesizeSelect}>
                      {pageSizeOptTag}
                  </select>
                  <div className="page-nav">
                      <button className="btn-nav first" id="onBtFirst" disabled={isFirst}><i className="ico"></i></button>
                      <button className="btn-nav prev" id="onBtPrevious" disabled={isFirst}><i className="ico"></i></button>
                      <PageNumberNav/>
                      <button className="btn-nav next" id="onBtNext" disabled={isLast}><i className="ico"></i></button>
                      <button className="btn-nav last" id="onBtLast" disabled={isLast}><i className="ico"></i></button>
                  </div>
              </div>
            </div>
        </Fragment>
  );
};
export default forwardRef(Pagination2);
