import { ApiUtil3 } from '../legacy'

export type PaymentSuccessProps = {
  orderId: string
  paymentKey: string
  amount: string | number
}

type FunctionType = (props: PaymentSuccessProps) => Promise<any> // API를 캡쳐하면 OK만 보임...
export const fetchPostPaymentSuccess: FunctionType = (props) =>
  ApiUtil3.post('/api/pg/payment/success', props).then((res) => res.data)
