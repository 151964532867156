import React, {useEffect, useRef, useState} from 'react';
import dayjs from 'dayjs';
import _ from "lodash";
/*
daterangepicker 리액트 컴포넌트로 대충 만듦
AccAdjustDialogSearchUserNo.tsx 참고
*/
export const daterangepickerDefaultOption = {
  startDate: dayjs().startOf('day').subtract(7, 'd'),
  endDate: dayjs().startOf('day'),
  opens: 'left',
  ranges: {
    '오늘 ': [dayjs().startOf('day'), dayjs().endOf('day')],
    '어제 ': [dayjs().startOf('day').subtract(1, 'days'), dayjs().endOf('day').subtract(1, 'days')],
    '이번 주': [dayjs().startOf('day').day(0), dayjs().day(1).endOf('day').subtract(-7, 'd')
      .subtract(59, 'm')],
    '저번 주': [dayjs().startOf('day').subtract(1, 'week').day(0), dayjs().endOf('day').subtract(1, 'week').day(6)
      .subtract(59, 'm')],
    '최근 7일': [dayjs().startOf('day').subtract(7, 'd'), dayjs().endOf('day').subtract(1, 'd')],
    '최근 30일': [dayjs().startOf('day').subtract(30, 'd'), dayjs().endOf('day').subtract(1, 'd')],
  },
};

const RangeDatePicker = (props: any) => {
  const datepicker = useRef<any>();
  const [datePickerStr, setDatePickerStr] = useState("");

  const datePickerPrevEvent = () => {
    $(datepicker.current).data('daterangepicker').subtractInterval();
    const start = $(datepicker.current).data('daterangepicker').startDate.format('YYYYMMDD');
    const end = $(datepicker.current).data('daterangepicker').endDate.format('YYYYMMDD');

    props.changeEvent(start, end);
  };
  const datePickerNextEvent = () => {
    $(datepicker.current).data('daterangepicker').addInterval();
    const start = $(datepicker.current).data('daterangepicker').startDate.format('YYYYMMDD');
    const end = $(datepicker.current).data('daterangepicker').endDate.format('YYYYMMDD');

    props.changeEvent(start, end);
  };

  useEffect(() => {
    const option = {
      ...daterangepickerDefaultOption, ...props.option,
    };

    $(datepicker.current).daterangepicker(option, (start:any, end:any) => {
      props.changeEvent(start, end);
      setDatePickerStr($(datepicker.current).data('daterangepicker').chosenLabel);
    });

    props.changeEvent(option.startDate, option.endDate);
    setDatePickerStr(_.isEmpty(props.defaultStr) ? '사용자 설정' : props.defaultStr);
  }, []);

  return (
    <div className="comp-datepicker">
      <div className="inner-datepicker">
        <i className="ico ico-calendar"></i>
        <input type="text" className="tf-comm datepicker-range" readOnly ref={datepicker}/>
        <span className="fz-12 fc-2">{datePickerStr}</span>
      </div>
      <button type="button" className="btn btn-prev" onClick={datePickerPrevEvent}></button>
      <button type="button" className="btn btn-next" onClick={datePickerNextEvent}></button>
    </div>
  );
};

export default RangeDatePicker;
