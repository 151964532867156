import { axios } from '../axios'

export type AttachedFileInfoType = Partial<{
  afId: number // 270157699
  fileName: string // 'cute1.jpg'
  fileSize: number // 753843
  filePath: string // 'https://rc2-bizstatic.wadiz.kr/static/camp_attach/20241003/50929130/270157693/270157695.jpg'
  lastModified: string // '2024-10-03 09:29:40'
  uploadId: number
}>
type AttachedInfoType = {
  id: number
  campId: number
  project2Id: number
  afs: AttachedFileInfoType[]
}

type ResponseType = {
  ok: boolean
  data: AttachedInfoType
}

type FunctionType = (campaignId: number) => Promise<ResponseType>
export const fetchAttachedDocumentList: FunctionType = (campaignId: number) =>
  // Promise.resolve({
  //   ok: true,
  //   data: {
  //     id: 270157698,
  //     campId: 270157698,
  //     project2Id: 'F26257',
  //     afs: [
  //       {
  //         afId: 270157699,
  //         fileName: 'cute1.jpg',
  //         fileSize: 753843,
  //         filePath: 'https://rc2-bizstatic.wadiz.kr/static/camp_attach/20241003/50929130/270157693/270157695.jpg',
  //         lastModified: '2024-10-03 09:29:40',
  //       },
  //     ],
  //   },
  // })
  axios.get(`/api/campAttach/${campaignId}`)
