import { Fragment, useEffect } from 'react'
import { loadTossPayments } from '@tosspayments/payment-sdk'
import alertify from 'alertifyjs'
import { ApiUtil3 } from '@biz/api'
import { PgParam } from './Charge'

const AutoChargeChangePopUp = (props: any) => {
  let currUserNoInPopUp: any
  let currProjectIdInPopUp: any
  let atptBzmoney: any
  let atptMinBalance: any
  useEffect(() => {
    $('#chargeUserNo').select2({
      width: '330px',
      minimumResultsForSearch: Infinity,
      placeholder: '선택하세요.',
    })
    const userNoDatasInAutoChargePopup: any[] = []
    /** 초기 데이터 삽입 */
    if (props.userNos.usingUserNo === undefined) {
      props.userNos.all.forEach((item: any) =>
        userNoDatasInAutoChargePopup.push({ id: item.userNo, text: item.userNo, projectId: item.projectId })
      )
    } else if (props.userNos.usingUserNo !== undefined && !props.userNos.changeable) {
      /** 활성 사업자번호가 있고, 충전 사업자번호 변경 가능 조건이 해당하지 않는 경우 */
      userNoDatasInAutoChargePopup.push({
        id: props.userNos.usingUserNo.userNo,
        text: props.userNos.usingUserNo.userNo,
        projectId: props.userNos.usingUserNo.projectId,
      })
      currUserNoInPopUp = props.userNos.usingUserNo.userNo
      currProjectIdInPopUp = props.userNos.usingUserNo.projectId
    } else if (props.userNos.usingUserNo !== undefined && props.userNos.changeable) {
      /** 활성 사업자번호가 있고, 충전 사업자 번호 변경 가능 조건이 가능한 경우 */
      userNoDatasInAutoChargePopup.push({
        id: props.userNos.usingUserNo.userNo,
        text: props.userNos.usingUserNo.userNo,
        projectId: props.userNos.usingUserNo.projectId,
      })
      props.userNos.all.forEach((item: any) => {
        if (item.userNo !== props.userNos.usingUserNo.userNo) {
          userNoDatasInAutoChargePopup.push({ id: item.userNo, text: item.userNo, projectId: item.projectId })
        }
      })
    }
    /** Select2 */
    $('#chargeUserNo')
      .select2({
        width: '330px',
        minimumResultsForSearch: Infinity,
        placeholder: '선택하세요.',
        data: userNoDatasInAutoChargePopup,
      })
      .on('select2:select', (e: any) => {
        currUserNoInPopUp = e.params.data.id
        currProjectIdInPopUp = e.params.data.projectId
      })

    if (props.userNos.usingUserNo !== undefined && !props.userNos.changeable) {
      /** M1720 desc-a2 */
      $('#chargeUserNo').select2().val(props.userNos.usingUserNo.userNo).trigger('change')
      $('#chargeUserNo').attr('disabled', 'disabled')
    } else if (props.userNos.usingUserNo === undefined && props.userNos.all.length === 0) {
      /** 연결 된 사업자 번호가 없을 때 */
      $('#chargeUserNo').select2({ placeholder: '등록된 프로젝트가 없습니다.' })
      $('#chargeUserNo').attr('disabled', 'disabled')
    }
    /** 툴팁 */
    $('#conditionOfChangeUserNoInDialog').tooltip({
      content:
        '<p class="fz-12 fc-0 fw-medium">다음의 조건이 충족되야 변경이 가능합니다.</p>' +
        '<p class="fz-12 fc-0"> 1. 자동충전 비활성화되어야 합니다.</br>' +
        '2. 쿠폰을 포함하여 비즈머니 잔액이 0원이 되야 합니다.</br>' +
        '3. 무통장 입금 대기건이 없어야 합니다.</br>' +
        '4. 진행중이거나 정산이 완료되지 않은 캠페인이 없어야 합니다.</br>' +
        '5. 진행중인 광고대행이 없어야 합니다.</br>' +
        '6. 진행중인 환불 요청이 없어야 합니다.</br>' +
        '</p>',
    })

    /** 다이얼로그 */
    $('#AutoChargeChangePopUp').dialog({
      title: '자동 충전 변경',
      width: 900,
      closeOnEscape: true,
    })

    /** 충전 금액 키업 이벤트 */
    $('#atptBzmoney').on('keyup', () => {
      const value = $('#atptBzmoney').val() as string
      if (/[^0-9,]/g.test(value)) {
        $('#atptBzmoney').val(value.substring(0, value.length - 1))
      } else {
        $('#atptBzmoney').val(value.replace(/,/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,'))
        atptBzmoney = value.replaceAll(',', '')
      }
    })
    $('#atptMinBalance').on('keyup', () => {
      const value = $('#atptMinBalance').val() as string
      if (/[^0-9,]/g.test(value)) {
        $('#atptMinBalance').val(value.substring(0, value.length - 1))
      } else {
        $('#atptMinBalance').val(value.replace(/,/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,'))
      }
      atptMinBalance = value.replaceAll(',', '')
    })
    /** 충전 금액 포커스 아웃 이벤트 */
    $('#atptBzmoney').focusout(() => {
      const value = $('#atptBzmoney').val() as string
      if (Number(value.replaceAll(',', '')) < 10000) {
        $('#atptBzmoney').val('')
      } else if (/[^0-9,]/g.test(value)) {
        $('#atptBzmoney').val('')
      }
    })

    $('#atptMinBalance').focusout(() => {
      const value = $('#atptMinBalance').val() as string
      if (Number(value.replaceAll(',', '')) < 10000) {
        $('#atptMinBalance').val('')
      } else if (/[^0-9,]/g.test(value)) {
        $('#atptMinBalance').val('')
      }
    })

    /** 취소 */
    $(document).on('click', '#autoChargeInfoCloseBtn', () => {
      $('#AutoChargeChangePopUp').dialog('close')
      props.getAutoChargeInfo()
      props.setIsAutoChargeChangePopUpOpened(false)
    })

    // 카드 자동 결제 등록 창 띄우기
    const cardAutoBill = () =>
      ApiUtil3.post('/api/pg/billingAuth/ready', {
        projectId: currProjectIdInPopUp,
        bzmoney: atptBzmoney,
        minBalance: atptMinBalance,
      }).then((res) => {
        if (!res.data.data.ok) {
          if (res.data.data.msg !== undefined) {
            alertify.error(res.data.data.msg)
          }
        }
        const pgParam: PgParam = { ...res.data.data }
        loadTossPayments(pgParam.clientKey).then((tossPayments) => {
          tossPayments
            .requestBillingAuth('카드', {
              customerKey: pgParam.customerKey,
              successUrl: `${window.location.origin}/${pgParam.successUrl}`,
              failUrl: `${window.location.origin}/${pgParam.failUrl}`,
            })
            .then()
        })
      })

    /** 등록 */
    $(document).on('click', '#autoChargeInfoRegBtn', () => {
      if (currUserNoInPopUp === undefined) {
        alertify.error('충전 사업자 번호를 선택해주세요.')
      } else if (Number(atptMinBalance) < 10000 || Number(atptBzmoney) < 10000) {
        alertify.error('최소 충전 금액은 10,000원 이상 입니다.')
      } else if ($('#atptMinBalance').val() === '' || $('#atptBzmoney').val() === '') {
        alertify.error('입력을 다시 확인 해 주세요.')
      } else {
        localStorage.removeItem('pgType')
        localStorage.removeItem('atptBzmoney')
        localStorage.removeItem('atptMinBalance')
        localStorage.setItem('pgType', 'atpt')
        localStorage.setItem('atptBzmoney', atptBzmoney)
        localStorage.setItem('atptMinBalance', atptMinBalance)
        cardAutoBill()
      }
    })

    return () => {
      $('#AutoChargeChangePopUp').dialog('destroy')
    }
  }, [])
  return (
    <Fragment>
      <form>
        <div id="AutoChargeChangePopUp" className="dialog" style={{ display: 'none' }}>
          <div className="dialog-body">
            <div className="wrap-section wrap-tbl">
              <div className="box-body">
                <div className="tbl">
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">충전사업자번호</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <select className="select2-single expand" id="chargeUserNo">
                          <option></option>
                        </select>
                      </div>
                      <div className="form-group">
                        <p className="comp-txt">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-12 fc-3 lh20">
                                <i className="fz-12 fc-5">*</i>선택한 사업자번호를 기준으로 비즈머니가 관리됩니다.
                              </b>
                              <b className="fz-12 fc-3 lh20">
                                <i className="fz-12 fc-5">*</i>사업자번호 변경조건
                                <button className="ico-tooltip" title="" id="conditionOfChangeUserNoInDialog"></button>
                              </b>
                            </span>
                          </span>
                        </p>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">충전 조건</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <p className="comp-txt">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-12 fc-3 lh20">비즈머니가 아래 금액 이하가 되면 자동으로 충전합니다.</b>
                            </span>
                          </span>
                        </p>
                      </div>
                      <div className="form-group">
                        <div className="input-group expand">
                          <div className="inner-input-group">
                            <input
                              type="text"
                              className="tf-comm tf-unit"
                              id="atptMinBalance"
                              placeholder="금액을 입력해 주세요."
                            />
                            <span className="fz-14 fc-1">원</span>
                          </div>
                          <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                        </div>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">충전 금액</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <p className="comp-txt">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-12 fc-3 lh20">비즈머니 1회 자동 충전 아래 금액만큼 충전합니다.</b>
                            </span>
                          </span>
                        </p>
                      </div>
                      <div className="form-group">
                        <div className="input-group expand">
                          <div className="inner-input-group">
                            <input
                              type="text"
                              className="tf-comm tf-unit"
                              id="atptBzmoney"
                              placeholder="충전 금액을 입력해 주세요."
                            />
                            <span className="fz-14 fc-1">원</span>
                          </div>
                          <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                        </div>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div className="dialog-footer">
            <button type="button" className="btn btn-secondary-outline large" id="autoChargeInfoCloseBtn">
              취소
            </button>
            <button type="button" className="btn btn-primary large" id="autoChargeInfoRegBtn">
              카드 등록
            </button>
          </div>
        </div>
      </form>
    </Fragment>
  )
}

export default AutoChargeChangePopUp
