import { useQuery } from '@tanstack/react-query'
import { fetchPayTypeCampaignInfo } from '@biz/api'
import { useCurrentCampaignIdQuery } from '@biz/query'

export const useCampaignPayInfoQuery = (isEnabled: boolean, payType?: 'BIZ_MONEY' | 'POST_PAYMENT') => {
  const { data: campaignId } = useCurrentCampaignIdQuery()
  const enabled = isEnabled && Boolean(campaignId)
  return useQuery({
    queryKey: ['@apps/biz-center/targetCreatePage/payStep/useCampaignInfo'],
    queryFn: () => (!!campaignId && !!payType ? fetchPayTypeCampaignInfo(campaignId, payType) : Promise.reject({})),
    enabled,
    refetchOnMount: 'always',
    cacheTime: 0,
  })
}
