import React, { Fragment, useEffect } from 'react';

const AccAdjustRegTaxbill = (props: any) => {
  // const [taxbillYn, setTaxbillYn] = useState<string>(props.getValues('taxbillYn'));

  // const changeTaxbill = (e: any) => {
  //   setTaxbillYn(e.target.value);
  // };

  useEffect(() => {
    // window.console.log(`세금계산서 발행 여부 : ${props.getValues('taxbillYn')} (${props.getValues('taxbillYn') === 'false'})`);
    // props.setValue('taxbillYn', taxbillYn);
  }, []);

  return (
    <Fragment>
      <section className="wrap-section wrap-tbl">
        <div className="box-header">
          <h2 className="fz-20 fc-1 fw-bold">세금계산서 발행</h2>
        </div>
        <div className="box-body">
          <div className="tbl">
            <dl>
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">발행 진행 여부</span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  {/* Comp-Radio : Start */}
                  <div className="comp-radio">
                    <input type="radio" id="inp-radio-05" value='false' {...props.register('taxbillYn')}/>
                    <label htmlFor="inp-radio-05">미발행</label>
                  </div>
                  {/* Comp-Radio : End */}
                  {/* Comp-Radio : Start */}
                  <div className="comp-radio">
                    <input type="radio" id="inp-radio-06" value='true' {...props.register('taxbillYn')}/>
                    <label htmlFor="inp-radio-06">발행</label>
                  </div>
                  {/* Comp-Radio : End */}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default AccAdjustRegTaxbill;
