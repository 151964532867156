import { useMutation, useQueryClient, type UseMutationResult } from '@tanstack/react-query'
import { fetchPostCreativesDataDelete } from '@biz/api'
import { loadCurrentCampaignId } from '../useCurrentCampaignIdQuery'
import { silentUpdateCreativeData } from '../useCreativeDataQuery'

type FunctionType = () => UseMutationResult<unknown, unknown, number, unknown>
export const useCreativeAssetDeleteMutation: FunctionType = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (id) => {
      const campId = (await loadCurrentCampaignId(queryClient)()) as number
      return fetchPostCreativesDataDelete({ campId, id }).then((res) => {
        silentUpdateCreativeData(queryClient)
        return res
      })
    },
  })
}
