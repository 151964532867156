import React, { Fragment, useEffect } from 'react';

const ItgDialog = (props :any) => {
  useEffect(() => {
    $('#itgClose').click(() => {
      $('#itgDialog').dialog('close');
    });
    return () => {
      // 페이지를 벗어나는 이동 시 destory 해줘야 한다. 다른 방법이 있는지 모름
      if ($('#itgDialog').length > 0 && $.data($('#itgDialog')[0], 'dialog')) {
        $('#itgDialog').dialog('destroy')
      }
    };
  }, []);

  return (
      <Fragment>
          <form>
              <div id="itgDialog" className="dialog" style={{ display: 'none' }}>
                  <div className="dialog-body">
                      <div className="wrap-section no-border">
                          <div className="box-body">
                              <div className="wrap-preview">
                                  <div className="box-body">
                                      <div className="box-group flex-container">
                                          <div className="box-left">
                                              <img
                                                  src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Insta-01.png"
                                                  alt="img1"/>
                                          </div>
                                          <div className="box-right">
                                              <img
                                                  src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Insta-02.png"
                                                  alt="img2"/>
                                          </div>
                                      </div>
                                      <div className="box-group">
                                          <div className="box-left">
                                              <img
                                                  src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Ico-logo-wadiz-48x48.png"
                                                  className="logo" alt="img3"/>
                                          </div>
                                          <div className="box-right">
                                              <p className="fz-14 fc-1 fw-medium lh15">와디즈</p>
                                              <p className="fz-12 fc-3 lh15">광고</p>
                                          </div>
                                      </div>
                                      <div className="box-group">
                                          <div className="comp-thumb"></div>
                                      </div>
                                      <div className="box-group">
                                          <div className="box-row flex-container">
                                              <div className="box-left">
                                                  <span id="itgClickBtn" className="fz-14 fc-1 fw-medium lh15">{props.row !== undefined ? props.row.clickBtnDiv : ''}</span>
                                              </div>
                                              <div className="box-right">
                                                  <i className="ico ico-arrow"></i>
                                              </div>
                                          </div>
                                          <div className="box-row border-top">
                                              <img
                                                  src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Insta-03.png"
                                                  className="logo" alt="img5"/>
                                          </div>
                                      </div>
                                      <div className="box-group">
                                          {
                                              props.row !== undefined ? props.row.adTitle01.split('\n').map((data: any, k:any) => (
                                                  // eslint-disable-next-line react/jsx-key
                                                  <p id="itgAdDesc" className="fz-14 fc-2 lh15 edit" key={k}>
                                                      {data}
                                                      <br />
                                                  </p>
                                              )) : ''
                                          }
                                      </div>
                                  </div>
                                  <div className="box-footer">
                                      <img
                                          src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Insta-04.png"
                                          className="logo" alt="img6"/>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="dialog-footer">
                      <button id="itgClose" type="button" className="btn btn-secondary-outline large">닫기</button>
                  </div>
              </div>
          </form>
      </Fragment>
  );
};

export default ItgDialog;
