import React, { Fragment, useEffect } from 'react'
import alertify from 'alertifyjs'
import dayjs from 'dayjs'
import { ApiUtil } from '@biz/api'

import _ from 'lodash'

const DlDialog = (props: any) => {
  const [count, setCount] = React.useState(0)
  let expireDate: String = dayjs().startOf('day').add(7, 'd').format('YYYYMMDD')

  const errorEvent = (msg: string) => {
    alertify.error(msg, 1)
  }

  const confirmError = (msg: string) => {
    errorEvent(msg)
  }

  function handleMenuDownloadForGet(props: any) {
    if ($('#pwd').val() !== '' && $('#msg').val() !== '' && expireDate !== undefined) {
      if (_.isEmpty(props.url)) {
        ApiUtil.get('/api/common/validate/dlForm', { params: { data: { pwd: $('#pwd').val() } } }).then((resp) => {
          if (resp.data.ok === false) {
            confirmError(resp.data.data.detailMessage)
            return
          } else {
            window.location.assign(
              `${props.dlUrl}${`?pwd=${encodeURIComponent($('#pwd').val() as string)}&msg=${encodeURIComponent(
                $('#msg').val() as string
              )}&expireDate=${expireDate}`}${
                _.isEmpty(props.dlParam)
                  ? ''
                  : Object.entries(props.dlParam)
                      .map((v: any) => `&${v[0]}=${encodeURIComponent(v[1])}`)
                      .join('')
              }`
            )
            props.setIsDlDialogOpened(false)
          }
        })
      } else {
        ApiUtil.get('/api/common/validate/dlForm', {
          params: {
            data: {
              pwd: $('#pwd').val(),
              msg: $('#msg').val(),
              expireDate: expireDate,
            },
          },
        }).then((resp) => {
          if (resp.data.ok === false) {
            confirmError(resp.data.data.detailMessage)
            return
          } else {
            const queryParams: any = {
              pwd: $('#pwd').val(),
              msg: $('#msg').val(),
              expireDate: expireDate,
            }

            let url = props.url.includes('download')
              ? props.url
              : props.type === '다운로드' || props.type === undefined
              ? `/api${props.url}/download`
              : '/api/integration/log/forAdmin/download'

            for (const key in props) {
              if (props.hasOwnProperty(key) && key !== 'url' && key !== 'setIsDlDialogOpened') {
                queryParams[key] = props[key]
              }
            }

            // Construct the final URL
            const queryString = Object.entries(queryParams)
              .map(([key, value]) => `${key}=${value}`)
              .join('&')
            window.location.assign(`${url}?${queryString}`)
            props.setIsDlDialogOpened(false)
          }
        })
      }
    } else {
      confirmError('미입력 항목이 있습니다.')
    }
  }

  function handleMenuDownloadForPost(props: any) {
    if ($('#pwd').val() !== '' && $('#msg').val() !== '' && expireDate !== undefined) {
      if (_.isEmpty(props.url)) {
        ApiUtil.get('/api/common/validate/dlForm', { params: { data: { pwd: $('#pwd').val() } } }).then((resp) => {
          if (resp.data.ok === false) {
            confirmError(resp.data.data.detailMessage)
            return
          } else {
            window.location.assign(
              `${props.dlUrl}${`?pwd=${encodeURIComponent($('#pwd').val() as string)}&msg=${encodeURIComponent(
                $('#msg').val() as string
              )}&expireDate=${expireDate}`}${
                _.isEmpty(props.dlParam)
                  ? ''
                  : Object.entries(props.dlParam)
                      .map((v: any) => `&${v[0]}=${encodeURIComponent(v[1])}`)
                      .join('')
              }`
            )
            props.setIsDlDialogOpened(false)
          }
        })
      } else {
        ApiUtil.get('/api/common/validate/dlForm', {
          params: {
            data: {
              pwd: $('#pwd').val(),
              msg: $('#msg').val(),
              expireDate: expireDate,
            },
          },
        }).then((resp) => {
          if (resp.data.ok === false) {
            confirmError(resp.data.data.detailMessage)
            return
          } else {
            let url = props.url.includes('download')
              ? props.url
              : props.type === '다운로드' || props.type === undefined
              ? `/api${props.url}/download`
              : '/api/integration/log/forAdmin/download'

            const requestData: any = {}
            for (const key in props) {
              if (props.hasOwnProperty(key) && key !== 'url' && key !== 'setIsDlDialogOpened' && key !== 'method') {
                requestData[key] = props[key]
              }
            }
            requestData['pwd'] = $('#pwd').val()
            requestData['msg'] = $('#msg').val()
            requestData['expireDate'] = expireDate

            //POST
            fetch(url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(requestData),
            })
              .then((response) => {
                if (!response.ok) {
                  throw new Error('Network response was not ok')
                }
                // handle success
              })
              .catch((error) => {
                console.error('There has been a problem with your fetch operation:', error)
                // handle error
              })

            props.setIsDlDialogOpened(false)
          }
        })
      }
    } else {
      confirmError('미입력 항목이 있습니다.')
    }
  }

  useEffect(() => {
    /** 다이얼로그 오픈 */
    $('#dlDialog').dialog({
      width: 900,
      title:
        props.url === '/integration/log' && props.type === '접속로그 확인' ? '관리자 접속로그 확인' : '엑셀 다운로드',
      closeOnEscape: true,
    })
    $('#dlDialog').dialog('open')
  }, [])

  useEffect(() => {
    $('#msg').on('keyup', () => {
      const value = $('#msg').val() as string
      setCount(value.length)
      if (value.length > 400) {
        $('#msg').val(value.substring(0, 400))
      }
    })
  }, [])

  useEffect(() => {
    /** 다운로드 버튼 */
    $('#confirmBtn').on('click', function () {
      if (props.hasOwnProperty('method')) handleMenuDownloadForPost(props)
      else handleMenuDownloadForGet(props)
    })
  }, [])

  useEffect(() => {
    /** 다이얼로그 닫기 */
    $(document).on('click', '#closeBtn', () => {
      $('#dlDialog').dialog('close')
      props.setIsDlDialogOpened(false)
    })
    return () => {
      props.setIsDlDialogOpened(false)
      $('#dlDialog').dialog('destroy')
    }
  }, [])

  useEffect(() => {
    $('#diDlaog_datepickerSingle').daterangepicker(
      {
        singleDatePicker: true,
        opens: 'left',
        minDate: dayjs().startOf('day').add(7, 'd').format('YYYYMMDD'),
      },
      (date: any) => {
        expireDate = date.format('YYYYMMDD')
      }
    )

    return () => {
      // 달력관련 모든걸 remove 해줘야 한다 한페이지에서 동작하는거라 이거 안해주면 달력 관련 소스가 계속 늘어남
      const daterangepickerCheck = document.querySelector<HTMLElement>('.daterangepicker')! as any

      if (daterangepickerCheck !== null) {
        daterangepickerCheck.remove()
      }
    }
  }, [])

  return (
    <Fragment>
      <form id="dialog">
        <div id="dlDialog" className="dialog">
          <div className="dialog-body">
            <div className="wrap-section wrap-tbl">
              <div className="box-body">
                <div className="tbl">
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">비밀번호</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <div className="input-group expand">
                          <div className="inner-input-group">
                            <input
                              id="pwd"
                              type="password"
                              className="tf-comm"
                              placeholder="비밀번호를 입력해 주세요."
                            />
                          </div>
                          <p className="fz-12 fc-4">영문, 숫자, 특수기호를 모두 조합하여 9~15자까지 입력해 주세요.</p>
                          <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                        </div>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">사유</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <div className="input-group expand">
                          <div className="inner-input-group">
                            <textarea id="msg" className="tf-textarea"></textarea>
                          </div>
                          <p className="fz-12 fc-3">
                            <span className="fc-2">{count}</span> / 400
                          </p>
                          <p className="fz-12">
                            <i className="ico"></i>체크 / 에러 문구 내용 영역
                          </p>
                        </div>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">파일 사용 기한</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <div className="comp-datepicker">
                          <div className="inner-datepicker">
                            <i className="ico ico-calendar"></i>
                            <input
                              id="diDlaog_datepickerSingle"
                              type="text"
                              className="tf-comm datepicker-single"
                              readOnly={true}
                            />
                          </div>
                        </div>
                      </div>
                    </dd>
                  </dl>
                  <dl className="vertical">
                    <dd>
                      <div className="form-group">
                        <div className="comp-help">
                          <ul className="help-list">
                            <li className="list">
                              <i className="ico ico-help"></i>
                              <span className="fz-14 fc-2">
                                다운로드 시 사유을 정확하게 기입하여 주시기를 바랍니다.
                              </span>
                            </li>
                            <li className="list">
                              <span className="fz-14 fc-4 bullet">
                                사용 후 파기하시기 바라며, 기입하신 사유 외에 다른 용도로 사용하여 발생하는 문제의
                                책임은 다운로드하시는 당사자에게 있음을 유의하여 주시기 바랍니다.
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div className="dialog-footer">
            <button id="closeBtn" type="button" className="btn btn-secondary-outline large">
              취소
            </button>
            <button id="confirmBtn" type="button" className="btn btn-primary large">
              확인
            </button>
          </div>
        </div>
      </form>
    </Fragment>
  )
}

export default DlDialog
