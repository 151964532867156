import { useBlocker } from 'react-router-dom'
import { Modal, Button } from 'antd'
import { useCampaignStatus, useCreativeDataQuery } from '@biz/query'
import styles from './BlockNavigationOnEditingModal.module.scss'

export const BlockNavigationOnEditingModal: React.FC<{ onCancel?: () => void }> = ({ onCancel }) => {
  const { inOperation } = useCampaignStatus()
  const { data } = useCreativeDataQuery()
  const blocker = useBlocker(({ currentLocation, nextLocation, historyAction }) =>
    !inOperation?.() ? false : !!historyAction && !!data?.find((n) => n.editStatus === 'editing')
  )
  return (
    <Modal
      width={760}
      open={blocker.state === 'blocked'}
      closable={false}
      title={<div className={styles.modalTitle}>{'수정하신 소재가 있습니다.'}</div>}
      footer={[
        <div className={styles.modalFooter} key={'git-type-select-footer'}>
          <Button
            type="default"
            block
            size="large"
            onClick={() => {
              blocker?.reset?.()
              setTimeout(() => onCancel?.(), 100)
            }}
            style={{ width: 200 }}
          >
            {'취소'}
          </Button>
          <Button type="primary" block size="large" onClick={() => blocker?.proceed?.()} style={{ width: 200 }}>
            {'이동하기'}
          </Button>
        </div>,
      ]}
      className={styles.containBlockNavigationOnEditingModal}
    >
      <ul className={styles.customBody}>
        <li>수정하신 소재를 ‘변경요청’ 혹은 ‘검수요청‘ 하지 않고 다른 페이지로 이동 하시겠습니까?</li>
        <li>이동 시에는 수정된 내용이 메타에 반영되지 않습니다.</li>
        <li className={styles.strong}>
          * 변경요청’ 혹은 ‘검수요청’을 진행하지 않은 소재의 경우 테두리의 색이 빨간색으로 표시됩니다.
        </li>
      </ul>
    </Modal>
  )
}
