import { axios } from '../axios'

export type CampaignValidationResult = {
  campBudgetValid: boolean
  campPeriodValid: boolean
  creationCnt: number //  1
  creationCorrectCnt: number // 1
  minCampBudgetTotal1: number // 15000
  projectValid: boolean
  errors?: { field: string; convertedMsg: string }[]
}

type ReturnType = {
  ok: boolean
  data: CampaignValidationResult
}

type FunctionType = (campId: string | number) => Promise<ReturnType>
export const fetchCampaignValidation: FunctionType = (campId) =>
  axios.get(`/api/camp/make02/campInspValid?campId=${campId}`)
