import React, { Fragment, useEffect } from 'react';

const TreeGridHeightLayout = () => {
  useEffect(() => {
    const noDataGridTree = (document.querySelector<HTMLElement>('.ag-root-wrapper-body.ag-focus-managed.ag-layout-auto-height')! as any);
    const noTreeDataGridTree = (document.querySelector<HTMLElement>('.ag-root-wrapper.ag-ltr.ag-layout-auto-height')! as any);
    setTimeout(() => {
      noDataGridTree.style.height = '548px';
      noTreeDataGridTree.style.height = '548px';
      const noData = (document.querySelector<HTMLElement>('#treeNoData')! as any);
      if (noData !== null) {
        noData.style.display = '';
      }
    }, 500);
  }, []);
  return (<Fragment>
      <div className="comp-no-data" id="treeNoData" style={{ display: 'none' }}>
      <div className="box-group">
        <i className="ico ico-no-data"></i>
        <p className="fz-18 fc-2">
          데이터가 존재하지 않습니다.
        </p>
      </div>
    </div>
  </Fragment>);
};

export default TreeGridHeightLayout;
