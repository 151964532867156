import React, { Fragment, useEffect } from 'react';

const GdnDialog = (props :any) => {
  useEffect(() => {
    $('#gdnClose').click(() => {
      $('#gdnDialog').dialog('close');
    });
    return () => {
      // 페이지를 벗어나는 이동 시 destory 해줘야 한다. 다른 방법이 있는지 모름
      if ($('#gdnDialog').length > 0 && $.data($('#gdnDialog')[0], 'dialog')) {
        $('#gdnDialog').dialog('destroy')
      }
    };
  }, []);

  return (
      <Fragment>
          <form>
              <div id="gdnDialog" className="dialog" style={{ display: 'none' }}>
                  <div className="dialog-body">
                      <div className="wrap-section no-border">
                          <div className="box-body">
                              <div className="wrap-preview">
                                  <div className="box-body">
                                      <div className="box-group">
                                          <img
                                              src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Google-01.png"
                                              alt="img1"/>
                                      </div>
                                      <div className="box-group">
                                          <div className="box-left">
                                              <img
                                                  src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Ico-logo-wadiz-60x17.png"
                                                  alt="img2"/>
                                          </div>
                                      </div>
                                      <div className="box-group">
                                          <p id="gdnAdTitle01" className="fz-14 fc-1 fw-medium lh15">{props.row !== undefined ? props.row.adTitle01 : ''}</p>
                                      </div>
                                      <div className="box-group">
                                          <p id="gdnAdTitle02" className="fz-14 fc-1 fw-medium lh15">{props.row !== undefined ? props.row.adTitle02 : ''}</p>
                                      </div>
                                      <div className="box-group">
                                          <div className="comp-thumb"></div>
                                      </div>
                                      <div className="box-group">
                                          {
                                              props.row !== undefined ? props.row.adDesc.split('\n').map((data: any, k:any) => (
                                                  // eslint-disable-next-line react/jsx-key
                                                  <p id="gdnAdDesc" className="fz-14 fc-2 lh15 edit" key={k}>
                                                      {data}
                                                      <br />
                                                  </p>
                                              )) : ''
                                          }
                                      </div>
                                      <div className="box-group flex-container">
                                          <div className="box-left">
                                              <span className="fz-12 fc-1">와디즈</span>
                                          </div>
                                          <div className="box-right">
                                              <button type="button" id="gdnClickBtn" className="btn btn-secondary-outline xsmall w-auto">{props.row !== undefined ? props.row.clickBtnDiv : ''}</button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="dialog-footer">
                      <button id="gdnClose" type="button" className="btn btn-secondary-outline large">닫기</button>
                  </div>
              </div>
          </form>
      </Fragment>
  );
};

export default GdnDialog;
