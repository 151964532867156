import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {AgGridReact} from "ag-grid-react";
import {ColDef, ICellRendererParams} from "ag-grid-community";
import NoDataTemplate from "../../../common/NoDataTemplate";
import { ApiUtil2 } from '@biz/api'
import {IPLTargetInfo} from "./PomLimitManagementDialog";
const tooltipRenderer = (props: ICellRendererParams) => {
    let comment = props.data.comment;
    let projectConfigHisId = props.data.projectConfigHisId;
    const tooltip = <div id={projectConfigHisId}
                         style={{textOverflow: 'ellipsis',
                             whiteSpace: 'nowrap',
                             overflow: 'hidden',}}>{comment}</div>
    $('#'+`${projectConfigHisId}`).tooltip({
        content: '<p class="fz-12 fc-0">'+comment+'</p>'
    });
    return (
        tooltip
    )
};
const PLHistoryGrid = (props: any) => {
    const gridRefOfAlHistory = useRef<AgGridReact>(null);
    const costRenderer = (e: any) => (e.data.pomLimitValue === 0 || e.data.pomLimitValue === undefined ? '-' : e.data.pomLimitValue.toLocaleString());

    const [rowData, setRowData] = useState([]);
    const [columnDefs] = useState<ColDef[]>([
        {
            field: 'updateDate',
            headerName: '날짜',
            cellClass: 'ag-center-aligned-cell',
            width: 50,
        },
        {
            field: 'modifier',
            headerName: '수정자',
            cellClass: 'ag-center-aligned-cell',
            width: 50
        },
        {
            field: 'pomLimitValue',
            headerName: '한도액(단위: 만원)',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-right-aligned-cell',
            width: 75,
            cellRenderer: costRenderer
        },
        {
            field: 'comment',
            headerName: '조정사유',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
            width: 200,
            cellRenderer: tooltipRenderer,
        }
    ]);

    const onGridReady = () => {
        ApiUtil2.get<IPLTargetInfo>('/api/bzmAdmin/postpaidMoneyManagement/PL/targetInfo?projectConfigId='+props.projectConfigId
        ).then((resp) => {
            setRowData(resp.data.data);
        });
    };

    const defaultColDef = useMemo<ColDef>(() => ({
        flex: 1,
        resizable: false,
        suppressMovable: true,
        sortable: true,
    }), []);

    useEffect(()=>{
        onGridReady()
    }, [])

    return (
        <Fragment>
            <AgGridReact
                ref={gridRefOfAlHistory}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowHeight={48}
                suppressPaginationPanel={true}
                domLayout={'autoHeight'}
            />
            { rowData === undefined ? <NoDataTemplate/> : null}
        </Fragment>
    );
};

export default PLHistoryGrid;
