import React, {
  Fragment, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import dayjs, { Dayjs } from 'dayjs';
import { useForm } from 'react-hook-form';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import qs from 'qs';
import { useMutation } from '@tanstack/react-query' // try next react-query;
import BzmTab from './BzmTab';
import NoDataTemplate from '../common/NoDataTemplate';
import Pagination from '../common/Pagination';
import { ApiUtil2 } from '@biz/api'
import alertify from 'alertifyjs'
import { IMultiSelect, multiSelectHandler, toMultiSelect } from '../accAdjust/AccAdjustTargetUtil';
import RangeDatePicker from '../accAdjust/RangeDatePicker';
import DlDialog from "../mngMakerAccount/DlDialog";

interface IMngDepositSearchForm {
  reqStartDate:string;
  reqEndDate:string;
  makerStatus:string | undefined;
  atamStatus:string;
  searchType: string;
  keyword?: string;

}
interface IMngDeposit {
  data:any;
  makerId:number;
  makerLoginId:string;
  makerNm:string;
  makerStatus:string;
  accAtamId:number;
  accStatus:string;
  atamStatus:string;
  accBankCode:string;
  accCode:string;
  accUserName:string;
  cost:number;
  userNo:number;
  sidNo:number;
  reqTime:string;
  updateTime:string;
  updateAdmin:string;
  hp:string;
}
interface IRejectReq {
  ok:boolean;
  data:any;
  accAtamIds : number[];
  successCnt:number;
  failCnt:number;
}
interface IConfirmReq {
  ok:boolean;
  data:any;
  accAtamIds : number[];
  successCnt:number;
  failCnt:number;
}
const makerStatusData = {
  all: { text: '전체', selected: true },
  NM: { text: '정상', selected: true },
  IA: { text: '휴면', selected: true },
  DO: { text: '탈퇴', selected: true },
};
// 렌더러 모음
const accBankAccountRenderer = (props: ICellRendererParams) => (`${props.data.accBankCode}  ${props.data.accCode}`);
const costRenderer = (props: ICellRendererParams) => (props.data.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
const userNoRenderer = (props: ICellRendererParams) => (props.data.bizDiv === 'user' ? props.data.sidNo : props.data.userNo);
const reqTimeRenderer = (props: ICellRendererParams) => (dayjs(props.data.reqTime).format('YYYY-MM-DD HH:mm'));
const updateTimeRenderer = (props: ICellRendererParams) => (dayjs(props.data.updateTime).format('YYYY-MM-DD HH:mm'));
const makerDetailRenderer = (props: ICellRendererParams) => {
  const { accAtamId } = props.data;
  return <Link className="txt-link" to={`/bzmAdmin/mng/mngDeposit/detail/${accAtamId}`}>{props.data.makerLoginId}</Link>;
};

const BzmMngDeposit = () => {
  const setTitle = useOutletContext<any>();
  // const [statusList, setStatusList] = useState<string[][]>([]);
  const childRef = useRef<any>(null);
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>();
  const [makerStatus, setMakerStatus] = useState<IMultiSelect>(toMultiSelect(makerStatusData));
  const [makerStatusLabel, setMakerStatusLabel] = useState<string>('전체');
  const rejectReqForm = useForm<IRejectReq>();
  const confirmReqForm = useForm<IConfirmReq>();
  const {
    register, handleSubmit, getValues, setValue,
  } = useForm<IMngDepositSearchForm>();
  const updateMakerStatusForm = (value: string) => setValue('makerStatus', value);
  const updateMakerStatusLabel = (label: string) => setMakerStatusLabel(label);
  const updateMakerStatus = (newState: Map<string, boolean>) => setMakerStatus({ ...makerStatus, selects: newState });

  const [isDlDialogOpened, setIsDlDialogOpened] = useState<boolean>(false);
  const [dlParam, setDlParam] = useState<any>(null);

  // 다운로드
  const onBtnExport = useCallback(() => {
    setDlParam({
      ...getValues(),
    });

    setIsDlDialogOpened(true);
  }, []);

  const MakerStatusSelectBox = () => (
      <div className="dropdown-menu expand">
        <ul className="opt-selectbox" onClick={(e:any) => e.stopPropagation()} onKeyUp={(e:any) => e.handleKeyUp} role="presentation">
          {
            Array.from(makerStatus.texts, ([key, value]) => ({ key, value }))
              .map(({ key, value }) => {
                const checkboxId = `ms-cb-${key}`;
                return <li className="opt-menu" key={key}>
                    <div className="comp-checkbox small">
                      <input name="makerStatus" type="checkbox"
                             id={checkboxId}
                             onChange={() => 0}
                             onClick={(e: any) => { multiSelectHandler(key, e.target.checked, makerStatus, updateMakerStatus, updateMakerStatusLabel, updateMakerStatusForm); }}
                             checked={makerStatus.selects.get(key) === true}/>
                      <label htmlFor={checkboxId}>{value}</label>
                    </div>
                  </li>;
              })
          }
        </ul>
      </div>
  );
  const onGridReady = useCallback(() => {
    const data : IMngDepositSearchForm = getValues();
    ApiUtil2.get<IMngDeposit>('/api/bzmAdmin/mng/bzmMngDeposit/depositList', {
      params: {
        data: { ...data },
      },
    }).then((resp) => {
      setRowData(resp.data.data);
    });
  }, []);
  // 데이터그리드 관련
  const changePagination = () => {
    childRef.current!.onPaginationChanged();
  };
  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'makerLoginId',
      headerName: '메이커ID',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellRenderer: makerDetailRenderer,
      minWidth: 300,
    },
    {
      field: 'makerNm',
      headerName: '메이커명',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'makerStatusDesc',
      headerName: '계정 상태',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'atamStatusDesc',
      headerName: '입금상태',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'accBankAccount',
      headerName: '입금 계좌 번호',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: accBankAccountRenderer,
      minWidth: 300,
    },
    {
      field: 'accUserNm',
      headerName: '입금자명',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'cost',
      headerName: '무통장 입금 신청 금액',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: costRenderer,
    },
    {
      field: 'userNo',
      headerName: '지급 충전 사업자 번호',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: userNoRenderer,
    },
    {
      field: 'reqTime',
      headerName: '입금 신청일시',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: reqTimeRenderer,
    },
    {
      field: 'updateTime',
      headerName: '최근 수정일시',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: updateTimeRenderer,
    },
    {
      field: 'updateId',
      headerName: '수정자',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
  ]);
  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: true,
  }), []);

  // 신청 취소 관련
  const rejectApi = () => {
    const {
      accAtamIds,
    } = rejectReqForm.getValues();
    const result = ApiUtil2.post<IRejectReq>('/api/bzmAdmin/mng/bzmMngDeposit/rejectReq', {
      accAtamIds,
    });
    return result;
  };
  const rejectApiEvent = useMutation(rejectApi, {
    onSuccess: (resp) => {
      console.log(`무통장 취소 resp --> ${resp.data.data.failCnt}`);
      if (resp.data.ok) {
        // 여기서 계속 수정 처리
        alertify.success(`정상적으로 신청 취소가 진행 되었습니다. <br> (성공 : ${resp.data.data.successCnt} 건 , 실패 : ${resp.data.data.failCnt} 건)`);
        onGridReady();
      }
    },
  });

  const rejectEvent = () => {
    const selectedRows = gridRef.current!.api.getSelectedRows();
    const checkedCount = selectedRows.length;
    if (checkedCount === 0) {
      alertify.error('입금 대기 상태의 무통장 입금 요청 건을 선택해 주세요.');
      return;
    }
    const accAmtmIds = selectedRows.map((checkedData:IMngDeposit) => checkedData.accAtamId);
    alertify.confirm(`선택하신 ${checkedCount} 건의 무통장 입금 요청 건에 대해 <br>신청 취소를 진행 하시겠습니까?<br><br>진행 후 다시 요청 상태로 변경은 불가 합니다.`, () => {
      rejectReqForm.setValue('accAtamIds', accAmtmIds);
      rejectApiEvent.mutate();
    }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
  };

  // 입금 완료 관련
  const confirmApi = () => {
    const {
      accAtamIds,
    } = confirmReqForm.getValues();
    const result = ApiUtil2.post<IConfirmReq>('/api/bzmAdmin/mng/bzmMngDeposit/confirmReq', {
      accAtamIds,
    });
    return result;
  };
  const confirmApiEvent = useMutation(confirmApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 여기서 계속 수정 처리
        alertify.success(`정상적으로 입금 완료 및 유상 비즈머니 지급이 완료 되었습니다.  <br> (성공 : ${resp.data.data.successCnt} 건 , 실패 : ${resp.data.data.failCnt} 건)`);
        onGridReady();
      }
    },
  });
  const confirmEvent = () => {
    const selectedRows = gridRef.current!.api.getSelectedRows();
    const checkedCount = selectedRows.length;
    if (checkedCount === 0) {
      alertify.error('입금 대기 상태 및 계정상태가 정상인 <br> 무통장 입금 요청 건을 선택해 주세요.');
      return;
    }
    const accAmtmIds = selectedRows.map((checkedData:IMngDeposit) => checkedData.accAtamId);
    alertify.confirm(`선택하신 ${checkedCount} 건의 무통장 입금 요청 건에 대해 <br>입금 완료를 진행 하시겠습니까?<br><br>진행 후 다시 요청 상태로 변경은 불가 합니다. <br> 지급된 유상 비즈머니에 대해 지급 취소가 불가 합니다.`, () => {
      confirmReqForm.setValue('accAtamIds', accAmtmIds);
      confirmApiEvent.mutate();
    }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
  };
  const registerReqDate = (start:Dayjs, end:Dayjs) => {
    const startDate = start.format('YYYYMMDD');
    const endDate = end.format('YYYYMMDD');
    setValue('reqStartDate', startDate);
    setValue('reqEndDate', endDate);
  };
  useEffect(() => {
    setTitle('비즈머니 관리');
    return () => {
      setTitle('');
    };
  }, []);

  useEffect(() => {
    // 입금 상태
    $('#atamStatus').select2({
      width: '150',
      minimumResultsForSearch: Infinity,
    }).on('select2:select', (e) => {
      setValue('atamStatus', e.params.data.id);
    });

    // 검색 구분
    $('#srchType').select2({
      width: '150',
      placeholder: '선택',
      minimumResultsForSearch: Infinity,
    }).on('select2:select', (e) => {
      setValue('searchType', e.params.data.id);
    });
  }, []);
  return (
    <Fragment>
      <BzmTab />
      <section className="wrap-section wrap-datagrid">
        <form onSubmit={handleSubmit(onGridReady)}>
          <div className="wrap-filter">
            <div className="inner-filter">
              <div className="box-left">
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">입금 신청 기간</p>
                  </div>
                  <div className="box-filter">
                    <RangeDatePicker changeEvent={registerReqDate} />
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">계정 상태</p>
                  </div>
                  <div className="box-filter">
                    <div id="makerStatus" className="comp-dropdown select2 w-150 selected">
                      <a href="#javascript" className="dropdown-toggle" data-toggle="dropdown">
                        <div className="box-left">
                          <span className="fz-14">{makerStatusLabel}</span>
                        </div>
                        <div className="box-right">
                          <i className="ico ico-arrow"></i>
                        </div>
                      </a>
                      <MakerStatusSelectBox />
                    </div>
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">입금 상태</p>
                  </div>
                  <div className="box-filter">
                    <select className="select2-single w-150" id="atamStatus" {...register('atamStatus')}>
                      <option value="" defaultChecked={true}>
                        전체
                      </option>
                      <option value="WAIT">입금 대기</option>
                      <option value="CONFIRM">입금 완료</option>
                      <option value="REJECT">신청 취소</option>
                    </select>
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">검색 구분</p>
                  </div>
                  <div className="box-filter">
                    <select className="select2-single w-150" id="srchType" {...register('searchType')}>
                      <option value="MAKER_ID" defaultChecked={true}>
                        메이커ID
                      </option>
                      <option value="MAKER_NM">메이커명</option>
                      <option value="ACCOUNT_NM">입금자명</option>
                      <option value="USER_NO">지급 충전 사업자 번호</option>
                      <option value="BANK_ACCOUNT">입금 계좌번호</option>
                    </select>
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">검색어</p>
                  </div>
                  <div className="box-filter">
                    <div className="input-group comp-search">
                      <div className="inner-input-group">
                        <input
                          type="text"
                          className="tf-comm"
                          placeholder="검색어를 입력해 주세요."
                          {...register('keyword')}
                        ></input>
                        <i className="ico ico-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-right">
                <button type="submit" className="btn btn-tertiary-mint btn-ico">
                  <i className="ico ico-filter"></i>필터 조회
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="box-header">
          <div className="box-tit">
            <button type="button" className="btn btn btn-primary" onClick={rejectEvent}>
              신청 취소
            </button>
            <button type="button" className="btn btn-primary-outline" onClick={confirmEvent}>
              입금 완료
            </button>
          </div>
          <div className="box-option">
            <button type="button" className="btn btn btn-secondary-outline btn-ico" onClick={onBtnExport}>
              <i className="ico ico-download"></i>다운로드
            </button>
          </div>
        </div>
        <div className="box-body">
          <div className="ag-grid">
            <div className="ag-grid-inner">
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                suppressRowClickSelection={true}
                rowSelection={'multiple'}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                // onGridReady={onGridReady}
                rowHeight={48}
                pagination={true}
                paginationPageSize={10}
                suppressPaginationPanel={true}
                onPaginationChanged={changePagination}
                domLayout={'autoHeight'}
                noRowsOverlayComponent={NoDataTemplate}
              ></AgGridReact>
            </div>
          </div>
        </div>
        <div className="box-footer">
          <Pagination gridRef={gridRef} ref={childRef} />
        </div>
      </section>
      {isDlDialogOpened ? (
        <DlDialog
          setIsDlDialogOpened={setIsDlDialogOpened}
          dlUrl={'/api/bzmAdmin/mng/bzmMngDeposit/download'}
          dlParam={dlParam}
        />
      ) : null}
    </Fragment>
  )
};
export default BzmMngDeposit;
