import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import alertify from 'alertifyjs'
import { Modal } from 'antd'
import { LabelBadge } from '@platform/share/ui'
import { ApiUtil2 } from '@biz/api'
import { ApiUtil } from '@biz/api'
import { PageTitle } from '@biz/ui'

import { Af } from '../../TargetCreateLayout/legacy/CampAttachDialog'
import CampAttachDialogForAdmin from '../../TargetCreateLayout/legacy/CampAttachDialogForAdmin'
import { CreationInspectionHistoryModal } from '../../views/mngAd/creation/CreationInspectionHistoryModal'
import CreationAIHistoryModal from '../../views/mngAd/creation/CreationAIHistoryModal'

import type { CreationDetail } from '@biz/api'
import CreationDataInspMemoDialog from './CreationDataInspMemoDialog'
import { CreativeStatusLabels } from './CreativeStatusLabels'
import PreviewImgDialog from './PreviewImgDialog'
import { GdnCreationDataIdContext } from './CreationDetailGdn'
import { FailReasonInputModal } from './FailReasonInputModal'
import { CreationDetailCard } from './CreationDetailCard'

import styles from './CreationDetail.module.scss'

export const getAttachBtn = () => {
  const [isOpenedCampAttachDialog, setIsOpenedCampAttachDialog] = useState<boolean>(false)
  const campDialogAfRef = useRef<Af[]>()
  return {
    isOpenedCampAttachDialog,
    setIsOpenedCampAttachDialog,
    campDialogAfRef,
  }
}

export const getMemoBtn = () => {
  const [isOpenedInspMemoDialog, setIsOpenedInspMemoDialog] = useState<boolean>(false)
  return {
    isOpenedInspMemoDialog,
    setIsOpenedInspMemoDialog,
  }
}

export const inspStatus: any = {
  ing: 'AI – 검수중',
  error: 'AI – 실패',
  ok: 'AI – 승인',
  fail: 'AI – 반려',
}
export const inspStatusColor: any = {
  ing: styles.info, // 'btn-info',
  error: styles.secondary, // 'btn-secondary',
  ok: styles.primary, // 'btn-primary',
  fail: styles.danger, // 'btn-danger',
}
interface CreationDetailResponse {
  ok: boolean
  data: CreationDetail[]
}

/**
 * 소재 검수 상세 (개별 검수)
 */
const CreationDetailFb = (props: any) => {
  const campIdRef = useRef<number>()
  const creationDataIdRef = useRef<string>('')

  const [creationDataId, setCreationDataId] = useState<string>('')
  const [creationData, setCreationData] = useState<CreationDetail[]>([])
  const creationDataChunk = _.chunk(creationData, 3)
  const [fileData, setFileData] = useState<any[]>([])

  const [showCreationDataInspHisDialog, setShowCreationDataInspHisDialog] = useState<boolean>(false) // 검수 이력 다이얼로그
  const [isStopCreation, setIsStopCreation] = useState(false) // 소재 중단 여부 (반려 X)
  const [isOpenStopConfirmModal, setIsOpenStopConfirmModal] = useState(false) // 소재 중단 컨펌 모달
  const [isOpenFailReasonModal, setIsOpenFailReasonModal] = useState(false) // 소재 중단, 반려 사유 입력 모달
  const [failReasonMessage, setFailReasonMessage] = useState('') // 소재 중단, 반려 사유 입력 내용
  const [isLoading, setIsLoading] = useState(false)

  const UPDATE_CREATION_STATUS_API_ENDPOINT = `/api/admin/campaigns/creations/${creationDataId}/status`

  // 검수이력 다이얼로그 파람값
  const setCreationDataInspHisParams = (creationDataId: string, index: number) => {
    creationDataIdRef.current = creationDataId
    setShowCreationDataInspHisDialog(true)
  }

  // 챗지피티 다이얼로그 관련
  const [showCreationChatGptDialog, setShowCreationChatGptDialog] = useState<boolean>(false)
  const setChatGptDialogParams = (creationDataId: string, campId: number) => {
    creationDataIdRef.current = creationDataId
    campIdRef.current = campId
    setShowCreationChatGptDialog(true)
  }

  const setDialogParams = (campId: number, creationDataId: string, campAttach: any) => {
    campIdRef.current = campId
    creationDataIdRef.current = creationDataId
    campDialogAfRef.current = []
    campDialogAfRef.current = campAttach.afs.map(
      (v: Af) => new Af(v.fileName, v.fileSize, v.filePath, v.afId, v.lastModified)
    )
    setIsOpenedCampAttachDialog(true)
  }

  const setDialogMemoParams = (campId: number, creationDataId: string) => {
    campIdRef.current = campId
    creationDataIdRef.current = creationDataId
    setIsOpenedInspMemoDialog(true)
  }

  const getPrevData = async () => {
    ApiUtil2.get<CreationDetailResponse>('/api/mngAd/creation/detail', {
      params: { data: { campId: props.campId } },
    }).then((resp) => {
      setCreationData(resp.data.data)
    })
  }

  const getAf = async (id: any) => {
    ApiUtil2.post('/api/mngAd/creation/getFiles', { refId: id }).then((resp) => {
      // eslint-disable-next-line no-use-before-define
      if (resp.data.data.length !== 0) {
        setFileData(resp.data.data)
      }
    })
  }

  const event = (id: any) => {
    creationDataIdRef.current = id
    setCreationDataId(id)
    getAf(id)
    $('#previewDialog').dialog({ width: 1200, title: '이미지 미리보기', closeOnEscape: true })
    $('#previewDialog').dialog('open')
  }

  // 소재 승인
  const handleConfirm = (campId: any, id: any) => {
    alertify
      .confirm('', () => {
        // FIXME: 신규 API
        // ApiUtil.post(UPDATE_CREATION_STATUS_API_ENDPOINT, {
        ApiUtil.post('/api/mngAd/creation/updateCreationStatus', {
          id, //creationDataId
          campId: campId,
          status: 'ok',
        }).then((resp) => {
          if (resp.data.data.intervalValid !== undefined && !resp.data.data.intervalValid) {
            alertify.alert('', resp.data.data.resultMsg).set('label', '확인')
          }
          getPrevData()
        })
      })
      .set({ labels: { cancel: '취소', ok: '승인' } })
      .setHeader('해당 소재를 검수 승인하시겠습니까?')
  }

  // 소재 반려 및 중단 사유 입력 모달 열기
  const openRejectReasonModal = (isStopCreation = false) => {
    setIsOpenFailReasonModal(true)
  }

  // 소재 반려 및 중단 (모달)
  const handleReject = (campId: any, id: any, isStopCreation = false) => {
    campIdRef.current = campId
    creationDataIdRef.current = id
    setCreationDataId(id)

    if (isStopCreation) {
      setIsStopCreation(true)
      setIsOpenStopConfirmModal(true)
    } else {
      setIsStopCreation(false)
      openRejectReasonModal()
    }
  }

  const errorEvent = (msg: string) => {
    alertify.error(msg, 1)
  }

  const confirmError = (msg: string) => {
    errorEvent(msg)
  }

  // 소재 중단, 반려 모달 확인 버튼 클릭 핸들러
  const handleFailConfirmButtonClick = () => {
    setIsLoading(true)

    if (failReasonMessage === '') {
      setIsLoading(false)
      confirmError('반려사유를 작성해주세요')
      return
    }

    // FIXME: 신규 API
    // ApiUtil.post(UPDATE_CREATION_STATUS_API_ENDPOINT, {
    ApiUtil.post('/api/mngAd/creation/updateCreationStatus', {
      id: creationDataIdRef.current,
      campId: campIdRef.current,
      status: isStopCreation ? 'stop_by_admin' : 'fail',
      msg: failReasonMessage,
    })
      .then(() => {
        getPrevData()
        setIsLoading(false)
        setIsOpenFailReasonModal(false)
        setFailReasonMessage('')

        // 소재 노출 중단한 경우
        if (isStopCreation) {
          alertify.success('소재 노출이 중단되었습니다.')
        }
      })
      .catch(() => {
        setIsLoading(false)
        setFailReasonMessage('')

        // 소재 노출 중단한 경우
        if (isStopCreation) {
          alertify.error('소재 중단에 실패했습니다.')
        }
      })
  }

  useEffect(() => {
    getPrevData()

    $('#closePreview').click(() => {
      $('#previewDialog').dialog('close')
    })

    return () => {
      const dialog = $('#previewDialog')

      // dialog 인스턴스가 존재할 때만 destroy
      // if (dialog.data('ui-dialog')) {
      dialog.dialog('destroy')
      // }
    }
  }, [])

  const { isOpenedCampAttachDialog, setIsOpenedCampAttachDialog, campDialogAfRef } = getAttachBtn()

  const { isOpenedInspMemoDialog, setIsOpenedInspMemoDialog } = getMemoBtn()

  return (
    <div className={styles.container}>
      <PageTitle>소재 검수</PageTitle>
      <GdnCreationDataIdContext.Provider value={{ creationDataId, setCreationDataId }}>
        <PreviewImgDialog fileData={fileData} />
      </GdnCreationDataIdContext.Provider>
      {isOpenedCampAttachDialog ? (
        <CampAttachDialogForAdmin
          setIsOpenedCampAttachDialog={setIsOpenedCampAttachDialog}
          campId={campIdRef.current}
          creationDataId={creationDataIdRef.current}
          afs={campDialogAfRef}
        />
      ) : null}
      {isOpenedInspMemoDialog ? (
        <CreationDataInspMemoDialog
          setIsOpenedInspMemoDialog={setIsOpenedInspMemoDialog}
          campId={campIdRef.current}
          creationDataId={creationDataIdRef.current}
        />
      ) : null}
      {showCreationDataInspHisDialog ? (
        <CreationInspectionHistoryModal
          isOpen={showCreationDataInspHisDialog}
          creationDataId={creationDataIdRef.current}
          searchType="admin"
          setShowCreationDataInspHisDialog={setShowCreationDataInspHisDialog}
        />
      ) : null}
      {showCreationChatGptDialog ? (
        <CreationAIHistoryModal
          setShowCreationChatGptDialog={setShowCreationChatGptDialog}
          campId={campIdRef.current}
          creationDataId={creationDataIdRef.current}
        />
      ) : null}
      {/* 소재 중단 확인을 위한 컨펌 모달 */}
      {isOpenStopConfirmModal && (
        <Modal
          title="소재를 중단하시겠어요?"
          width={400}
          open={isOpenStopConfirmModal}
          okText="중단하기"
          cancelText="취소"
          centered
          className={styles.stopConfirmModal}
          classNames={{
            content: styles.content,
            body: styles.body,
            header: styles.header,
            footer: styles.footer,
          }}
          onOk={() => {
            setIsOpenStopConfirmModal(false)
            openRejectReasonModal(true)
          }}
          onCancel={() => setIsOpenStopConfirmModal(false)}
        >
          <p>
            현재 진행 상태인 소재입니다. <br />
            소재를 신중하게 확인한 후에 중단해 주세요.
          </p>
        </Modal>
      )}

      {/* 반려, 중단 사유 작성 모달 */}
      <FailReasonInputModal
        isOpen={isOpenFailReasonModal}
        campId={campIdRef.current}
        creationDataId={creationDataIdRef.current}
        isStopCreation={isStopCreation}
        isLoading={isLoading}
        getPrevData={getPrevData}
        handleConfirm={handleFailConfirmButtonClick}
        handleClose={() => {
          setIsOpenFailReasonModal(false)
          setFailReasonMessage('')
        }}
        handleChange={(event) => {
          setFailReasonMessage(event.target.value)
        }}
      />

      {creationDataChunk.map((chunk: CreationDetail[], chunkIndex: number) => (
        <section className="wrap-section wrap-tbl" key={chunkIndex}>
          <div className="box-body">
            <div className="tbl">
              <dl className="vertical">
                <dd>
                  <div className="form-group">
                    <div className={`${styles.row}`}>
                      {chunk.map((data: CreationDetail, index: number) => (
                        <CreationDetailCard
                          key={data.id}
                          type="single"
                          data={data}
                          index={index}
                          handleReject={handleReject}
                          handleConfirm={handleConfirm}
                          setChatGptDialogParams={setChatGptDialogParams}
                          setDialogParams={setDialogParams}
                          setCreationDataInspHisParams={setCreationDataInspHisParams}
                          setDialogMemoParams={setDialogMemoParams}
                          event={event}
                        />
                      ))}
                    </div>
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </section>
      ))}
    </div>
  )
}

export default CreationDetailFb
