import { useQuery } from '@tanstack/react-query'
import { fetchFacebookLink } from '@biz/api'
import { useCurrentCampaignIdQuery } from '../useCurrentCampaignIdQuery'

export const useFacebookLinkQuery = (enabled: boolean, snsAdId?: string | number) => {
  const { data: campaignId } = useCurrentCampaignIdQuery()
  return useQuery({
    queryKey: ['@biz/query/createTargetAd/FacebookLink', snsAdId],
    queryFn: () =>
      !!campaignId && !!snsAdId ? fetchFacebookLink(campaignId, snsAdId).then((n) => n.data) : Promise.reject({}),
    enabled: Boolean(snsAdId) && enabled,
  })
}
