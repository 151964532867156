import React, { Fragment, useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ICellRendererParams } from 'ag-grid-community';
import { Link } from 'react-router-dom';
import { defaultColDef } from './AccAdjustTargetUtil';
import { ApiUtil2 } from '@biz/api'

const taxbillDetailLinkRenderer = (props: ICellRendererParams) => {
  const { adjustTargetId } = props.data;
  return <Link className="txt-link" to={`/accAdjust/taxbill/info?id=${adjustTargetId}&prev=adjust`}>{props.value}</Link>;
};

const AccAdjustRegTaxbillInfo = (props: any) => {
  const [rowData, setRowData] = useState<any[]>([]);

  const columnDefs = [
    {
      headerName: '발행대상 정보 및 발행상태',
      children: [
        {
          field: 'makerLoginId', headerName: '메이커ID', cellClass: 'ag-left-aligned-cell', cellRenderer: taxbillDetailLinkRenderer,
        },
        { field: 'userNo', headerName: '발행 사업자번호/주민번호', cellRenderer: taxbillDetailLinkRenderer },
        { field: 'bizDivDesc', headerName: '구분' },
        { field: 'taxbillStatusDesc', headerName: '발행 상태' },
        { field: 'sendDate', headerName: '발행일자' },
        { field: 'comEmail', headerName: '이메일' },
      ],
    },
    {
      headerName: '임의조정 금액',
      children: [
        { field: 'cost', headerName: '공급가액', cellClass: 'ag-center-aligned-cell' },
        { field: 'vat', headerName: '부가세', cellClass: 'ag-center-aligned-cell' },
        { field: 'total', headerName: '합계', cellClass: 'ag-center-aligned-cell' },
      ],
    },
  ];

  const loadTaxbill = (adjustId: string) => {
    ApiUtil2.get('/api/accAdjust/reg/taxbil', {
      params: {
        id: adjustId,
      },
    }).then((resp) => {
      const taxbillInfo = resp.data.data;
      setRowData(taxbillInfo);
    });
  };

  useEffect(() => {
    // do something
  }, []);

  useEffect(() => {
    if (props.adjustId !== undefined) {
      loadTaxbill(props.adjustId);
    }
  }, [props.adjustId]);

  return (
    <Fragment>
      <section className="wrap-section wrap-datagrid wrap-double-header">
      {/* <section className="wrap-section wrap-tbl"> */}
        <div className="box-header">
          <h2 className="fz-20 fc-1 fw-bold">세금계산서 발행 내역</h2>
        </div>
        <div className="box-body">
          <div className="ag-grid">
            <div className="ag-grid-inner">
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowHeight={48}
                pagination={false}
                domLayout={'autoHeight'}
               />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default AccAdjustRegTaxbillInfo;
