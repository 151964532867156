import { useQuery, useQueryClient, type QueryClient } from '@tanstack/react-query'
import type { UserGroupType } from '@biz/api'

const queryKey = ['@biz/query/auth/RoleGroup']
export const setRoleGroup = (queryClient: QueryClient) => async (roleGroup: UserGroupType | string) =>
  queryClient.setQueryData(queryKey, roleGroup)

export const useRoleGroupQuery = () => {
  const queryClient = useQueryClient()
  return {
    ...useQuery({
      queryKey,
      queryFn: () => '',
    }),
    update: setRoleGroup(queryClient),
  }
}
