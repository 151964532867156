import { createContext, Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { ApiUtil3, downloadUrl } from '@biz/api'
import AfDialog from './AfDialog'

export const GdnCreationDataAfIdContext = createContext({
  afId: 'step01',
  setAfId: (value: any) => value,
})

const PreviewImgDialog = (props: any) => {
  const [afId, setAfId] = useState<any>(null)
  const chunkedFile = _.chunk(props.fileData, 5)

  // 다운로드용
  const imgLoad = (id: any, afName: any) => {
    ApiUtil3.post('/api/file/downloadReq', { fileId: id }).then((resp: any) => {
      downloadUrl(resp.data.data, afName)
    })
  }

  // 원본보기 다이얼로그
  // const event = (id: any) => {
  //   setAfId(id);
  //   const find = props.fileData.find((e: any) => e.id === Number(id));
  //   $('#original').empty();
  //   const elemType = `${find.afMeta.fileDiv}` === 'VIDEO' ? 'video' : 'img';
  //   const element:any = document.createElement(elemType);
  //
  //   if (find.afMeta.width === undefined || find.afMeta.height === undefined) {
  //     const file = new File([find.fileName], find.afName);
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onloadend = function (e:any) {
  //       const image = new Image();
  //       image.src = e.target.result;
  //       image.onload = function () {
  //         element.width = Number(image.width);
  //         element.height = Number(image.height);
  //       };
  //     };
  //   } else {
  //     // eslint-disable-next-line prefer-destructuring
  //     element.width = Number(`${find.afMeta.width}`);
  //     // eslint-disable-next-line prefer-destructuring
  //     element.height = Number(`${find.afMeta.height}`);
  //   }
  //
  //   element.alt = find.afName;
  //
  //   if (elemType === 'video') {
  //     const source = document.createElement('source');
  //     element.type = 'video';
  //     element.controls = true;
  //     element.muted = true;
  //     source.src = find.fileName;
  //     element.appendChild(source);
  //     $('#original').append(element);
  //     element.play();
  //   } else {
  //     element.src = find.fileName;
  //     $('#original').append(element);
  //   }
  //
  //   $('#afDialog').dialog({
  //     width: element.width + 100, title: '원본 보기', closeOnEscape: true,
  //   });
  //   $('#afDialog').children('.dialog-body').css('height', element.height);
  //   $('.dialog-body').children('.wrap-section.wrap-tbl.no-border').css('height', '100%');
  //   $('.wrap-section.wrap-tbl.no-border').children('.box-body').css('height', '100%');
  //   $('.box-body').children('#original').css('height', '100%');
  //   $('#afDialog').dialog('open');
  // };

  useEffect(() => {
    $('[id^=detailImg_]').on('click', (e) => {
      window.open(e.currentTarget.dataset.url)
    })

    $('[id^=detailImgDown_]').on('click', (e) => {
      imgLoad(e.currentTarget.dataset.id, e.currentTarget.dataset.afname)
    })
  }, [props.fileData])

  return (
    <Fragment>
      <GdnCreationDataAfIdContext.Provider value={{ afId, setAfId }}>
        <AfDialog />
      </GdnCreationDataAfIdContext.Provider>
      <form>
        <div id="previewDialog" className="dialog" style={{ display: 'none' }}>
          <div className="dialog-body">
            <div className="wrap-section wrap-tbl wrap-thumb-history">
              <div className="box-body">
                <div className="tbl">
                  <dl className="vertical">
                    <dd>
                      {chunkedFile.map((a: any, k: any) => (
                        <div className="form-group" key={k}>
                          <div className="flex-container">
                            {a.map((aa: any, kk: any) => (
                              <div className="box-group col-10-2" key={kk}>
                                <div
                                  className="comp-thumb"
                                  id={`detailImg_${aa.id}_${aa.afName}_${kk}`}
                                  data-url={aa.fileName}
                                >
                                  {aa.afName.split('.')[1] === 'PNG' ||
                                  aa.afName.split('.')[1] === 'png' ||
                                  aa.afName.split('.')[1] === 'jpg' ? (
                                    <span className="txt-size">
                                      {aa.afMeta.width}x{aa.afMeta.height}
                                    </span>
                                  ) : (
                                    <i className="ico ico-video"></i>
                                  )}
                                  {aa.afMeta.fileDiv === 'VIDEO' && aa.afMeta.ext !== ' gif' ? (
                                    // eslint-disable-next-line jsx-a11y/media-has-caption
                                    <video
                                      autoPlay
                                      controls
                                      muted
                                      // style={{ width: '100%' }}
                                    >
                                      <source src={`${aa.fileName}`} />
                                    </video>
                                  ) : (
                                    <img
                                      src={`${aa.fileName}`}
                                      // style={{ width: '100%' }}
                                      alt="img22"
                                    />
                                  )}
                                </div>
                                <Link
                                  to="#!"
                                  className="txt-underline"
                                  id={`detailImgDown_${aa.id}_${aa.afName}_${kk}`}
                                  data-id={aa.id}
                                  data-afname={aa.afName}
                                >
                                  <p className="fz-12 fc-2">{aa.afName}</p>
                                </Link>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div className="dialog-footer">
            <button id="closePreview" type="button" className="btn btn-secondary-outline large">
              닫기
            </button>
          </div>
        </div>
      </form>
    </Fragment>
  )
}

export default PreviewImgDialog
