import { axios } from '../axios'
import { CTAListType } from './ctaListType'

type DuplicationDataType = {
  adDesc: string
  adTitle01: string
  adTitle02: string
  campId: number
  clickBtnDiv: CTAListType
  id: number
  sortOrder: number
}

type AppendSlotDataType = {
  campId: number
  clickBtnDiv: CTAListType
  sortOrder: number
}

export type CreativeAssetInsertParam = AppendSlotDataType | DuplicationDataType
type ReturnType = {
  ok: boolean
  data: string
}

type FunctionType = (data: Partial<CreativeAssetInsertParam>) => Promise<ReturnType>
export const fetchPostCreativesDataInsert: FunctionType = (data) =>
  axios.post('/api/camp/make02/fbCreationDataInsert', data)
