import React, {
  Fragment, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridSizeChangedEvent, ICellRendererParams } from 'ag-grid-community';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useOutletContext } from 'react-router';
import alertify from 'alertifyjs'
import Pagination from '../../common/Pagination';
import NoDataTemplate from '../../common/NoDataTemplate';
import { ApiUtil2 } from '@biz/api'
import { ApiUtil } from '@biz/api'

interface IFaq {
    faqId: number;
    faqNum: number;
    title: string;
    sortOrder: any;
    useYn: string;
}

export interface INoticeList {
    ok: boolean;
    data: IFaq[];
}

export interface INoticeDetail {
    ok: boolean;
    data: IFaq;
}

export interface srchForm {
    useYn: string,
    sortOrder: string,
    title?: string,
}

const faqDetailRenderer = (props: ICellRendererParams) => {
  const { faqId } = props.data;
  return <Link className="txt-link" to={`/community/faq/modify/${faqId}`}>{props.value}</Link>;
};

const successEvent = (msg: string) => {
  alertify.success(msg, 1);
};
const confirmSuccess = (msg: string) => {
  successEvent(msg);
};

const FaqForAdmin = () => {
  const setTitle = useOutletContext<any>();
  const childRef = useRef<any>(null);
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>();
  const useYnRenderer = (props: ICellRendererParams) => {
    // eslint-disable-next-line react/prop-types
    const { faqId } = props.data;
    // eslint-disable-next-line react/prop-types
    const { useYn } = props.data;
    const useYnChanger = () => {
      ApiUtil2.post('/api/community/faq/useYnChanger', { faqId }).then(() => {
        confirmSuccess('공개여부가 변경되었습니다.');
        // eslint-disable-next-line no-use-before-define
        onGridReady();
      });
    };
    return useYn === true ? <button onClick={useYnChanger}>공개</button>
      : <button onClick={useYnChanger}>미공개</button>;
  };
  const delRenderer = (props: ICellRendererParams) => {
    const deleteBtn = () => {
      alertify.confirm('', '삭제하시겠습니까?', () => {
        // eslint-disable-next-line react/prop-types
        ApiUtil.post('/api/community/faq/delete', { faqId: props.data.faqId }).then(() => {
          confirmSuccess('삭제되었습니다.');
          // eslint-disable-next-line no-use-before-define
          onGridReady();
        });
      }, {}).set({ labels: { cancel: '취소', ok: '확인' } });
    };
    return (
            <span>
          <button
              onClick={
                  deleteBtn
              }
              className="btn btn-primary xsmall">
            삭제
          </button>
    </span>
    );
  };
  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'faqNum',
      headerName: '번호',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      width: 50,
    },
    {
      field: 'sortOrder',
      headerName: '정렬순위',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      width: 50,
    },
    {
      field: 'faqDiv',
      headerName: '카테고리',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
      width: 50,
    },
    {
      field: 'title',
      headerName: '제목',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
      cellRenderer: faqDetailRenderer,
      width: 200,
    },
    {
      field: 'useYn',
      headerName: '공개여부',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: useYnRenderer,
      width: 50,
    },
    {
      field: '삭제',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: delRenderer,
      width: 50,
    },

  ]);
    // 컬럼 이동 금지 suppressMovable: true
  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: true,
  }), []);
  const sortOrderList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const {
    register, handleSubmit, setValue, getValues,
  } = useForm<srchForm>();

  // onGridSizeChanged will get called when grid is ready and every time the grid's width changes
  const onGridSizeChanged = (params: GridSizeChangedEvent) => {
    params.api.sizeColumnsToFit();
  };

  // 그리드 데이터 시작
  useEffect(() => {
    $('#useYnFilter').select2({
      width: '150',
      minimumResultsForSearch: Infinity,
    }).on('select2:select', (e) => setValue('useYn', e.params.data.id));

    $('#sortOrderFilter').select2({
      width: '150',
      minimumResultsForSearch: Infinity,
    }).on('select2:select', (e) => setValue('sortOrder', e.params.data.id));
    setTitle('FAQ 관리');

    return () => {
      setTitle('');
    };
  }, []);

  const onGridReady = useCallback(() => {
    const {
      useYn, sortOrder, title,
    } = getValues();
    ApiUtil2.get('/api/community/faq', {
      params: { data: { useYn, sortOrder, title } },
    }).then((resp) => {
      setRowData(resp.data.data);
    });
  }, []);
    // 그리드에서 페이징 변경시 pagination 에 있는 함수 호출
  const changePagination = () => {
        childRef.current!.onPaginationChanged();
  };

  return (
        <Fragment>
            <section className="wrap-section wrap-datagrid">
                <form onSubmit={handleSubmit(onGridReady)}>
                    <div className="wrap-filter">
                        <div className="inner-filter">
                            <div className="box-left">
                                <div className="item-filter">
                                    <div className="filter-tit">
                                        <p className="fz-12 fc-2">공개여부</p>
                                    </div>
                                    <div className="box-filter">
                                        <select className="select2-single" id="useYnFilter" {...register('useYn')}>
                                            <option value="all">전체</option>
                                            <option value="true">공개</option>
                                            <option value="false">미공개</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="item-filter">
                                    <div className="filter-tit">
                                        <p className="fz-12 fc-2">정렬순위</p>
                                    </div>
                                    <div className="box-filter">
                                        <select className="select2-single"
                                                id="sortOrderFilter" {...register('sortOrder')}>
                                            {sortOrderList.map((k) => <option key={k}
                                                                              value={k}>{k === 0 ? '전체' : k}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="item-filter">
                                    <div className="filter-tit">
                                        <p className="fz-12 fc-2">검색어</p>
                                    </div>
                                    <div className="box-filter">
                                        <div className="input-group comp-search">
                                            <div className="inner-input-group">
                                                <input id="title" type="text" {...register('title')} className="tf-comm"
                                                       placeholder="제목 혹은 내용을 입력하세요."></input>
                                                <i id="search" className="ico ico-search"></i>
                                            </div>
                                            <p className="fz-12"><i className="ico"></i>체크 / 에러 문구 내용 영역</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-right">
                                <button type="submit" className="btn btn-tertiary-mint btn-ico">
                                    <i className="ico ico-filter"></i>조회
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="box-header">
                    <div className="box-tit">
                        <h2 className="fz-20 fc-1 fw-bold">FAQ 리스트</h2>
                    </div>
                    <div className="box-option">
                        <Link to="/community/faq/post">
                            <button type="button" className="btn btn-primary">신규 작성</button>
                        </Link>
                    </div>
                </div>
                <div className="box-body">
                    <div className="ag-grid">
                        <div className="ag-grid-inner">
                            <AgGridReact
                                ref={gridRef}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                onGridReady={onGridReady}
                                rowHeight={48}
                                pagination={true}
                                paginationPageSize={10}
                                suppressPaginationPanel={true}
                                onPaginationChanged={changePagination}
                                onGridSizeChanged={onGridSizeChanged}
                                domLayout={'autoHeight'}
                                noRowsOverlayComponent={NoDataTemplate}
                            ></AgGridReact>
                        </div>
                    </div>
                </div>
                <div className="box-footer">
                    <Pagination gridRef={gridRef} ref={childRef}/>
                </div>
            </section>
        </Fragment>
  );
};
export default FaqForAdmin;
