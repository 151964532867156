import React, { Fragment } from 'react';
import { useNavigate } from 'react-router';

const InquiryForHome = () => {
  const navigator = useNavigate();

  return (
    <Fragment>
      <div className="col col-4">
        <div className="box box-channel-list">
          <section className="wrap-section wrap-tbl">
            <div className="box-header">
              <div className="box-tit">
                <h2 className="fz-20 fw-bold fc-1">문의</h2>
              </div>
            </div>
            <div className="box-body">
              <div className="tbl">
                <dl className="vertical">
                  <dd>
                    <div className="form-group">
                      <div className="inquiry-group">
                        {/*<div className="inquiry-element kakao">*/}
                        {/*    <i className="ico"></i>*/}
                        {/*    <button onClick={() => window.open('http://ad-wadiz.channel.io/', '_blank')} className="fz-16 txt-link">와디즈 비즈센터</button>*/}
                        {/*</div>*/}
                        {/*<div className="inquiry-element email">*/}
                        {/*    <i className="ico"></i>*/}
                        {/*    <p className="fz-16 fc-4">biz-ad@wadiz.kr</p>*/}
                        {/*</div>*/}
                        <a onClick={() => window.location.assign(`${import.meta.env.VITE_WADIZ_HELP_CENTER}`)}>
                          <div className="inquiry-element email">
                            <i className="ico"></i>
                            <p className="fz-16 fc-4">광고 문의하기</p>
                          </div>
                        </a>
                        {/* <div className="inquiry-element tel"> */}
                        {/*    <i className="ico"></i> */}
                        {/*    <p className="fz-16 fc-4">1661-9056</p> */}
                        {/* </div> */}
                      </div>
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
            <div className="box-footer">
              <div className="box-center">
                <button
                  type="button"
                  className="btn btn-primary large"
                  onClick={() =>
                    window.open(
                      'https://bizstatic.wadiz.kr/static/usingGuide/%EC%99%80%EB%94%94%EC%A6%88+%EB%B9%84%EC%A6%88%EC%84%BC%ED%84%B0+%EC%9D%B4%EC%9A%A9%EA%B0%80%EC%9D%B4%EB%93%9C.pdf'
                    )
                  }
                >
                  이용 가이드
                </button>
                <button
                  type="button"
                  className="btn btn-primary-outline large"
                  onClick={() => navigator('/makerCommon/faq')}
                >
                  FAQ
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Fragment>
  )
};

export default InquiryForHome;
