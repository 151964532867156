import React, {
  Fragment, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import dayjs, { type Dayjs } from 'dayjs';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  GridSizeChangedEvent,
  ICellRendererParams,
} from 'ag-grid-community';
import { useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router';
import { toast } from 'react-toastify';
import Pagination from '../common/Pagination';
import CustomTooltip from '../example/CustomTooltip';
import { ApiUtil2 } from '@biz/api'
import NoDataTemplate from '../common/NoDataTemplate';
import DlDialog from '../mngMakerAccount/DlDialog';

interface MakerRptGrid {
  startDate: any;
  endDate: any;
}

const rptDivEnum: any = {
  rptDiv01: '매체비 및 대행수수료 상세 내역 (캠페인)',
  rptDiv02: '매체비 및 대행수수료 상세 내역 (광고 대행)',
  rptDiv03: '포인트 충전/사용/잔액 관리 (기간조회)',
  rptDiv04: '포인트 충전/사용/잔액 관리 (시점조회)',
  rptDiv05: '충전금 이동내역 확인',
  rptDiv06: '충전금 및 정산 현황(총괄판)',
  rptDiv07: '프로젝트별 후불광고비 상세',
  rptDiv07TEMP: '프로젝트별 후불광고비 상세 TEMP',
  rptDiv08: '캠페인별 후불광고비 상세',
  rptDiv09: '메이커별 무상쿠폰 지급이력',
  rptDiv10: '후불머니 정산금 대사',
};
export const fileStatusEnum: any = {
  WAITING: '생성대기',
  FAILED: '생성실패',
  EXPIRATION: '기간만료',
  SUCCEEDED: '다운로드',
};

const Finance = () => {
  /** 설정 부분 */
  const setTitle = useOutletContext<any>();
  const gridRef = useRef<AgGridReact>(null);
  const childRef = useRef<any>(null);
  const [rowData, setRowData] = useState<any[]>();
  const [isDlDialogOpened, setIsDlDialogOpened] = useState<boolean>(false);

  const dlParamRef = useRef({});

  const downloadRenderer = (props: ICellRendererParams) => {
    const downloadBtn = () => {
      dlParamRef.current = {
        id: props.data.id,
      };

      setIsDlDialogOpened(true);
    };
    return (
      <span>
        <button
          onClick={downloadBtn}
          className='btn btn-primary xsmall'
          disabled={props.data.fileStatus !== 'SUCCEEDED'}
        >
          {fileStatusEnum[`${props.data.fileStatus}`]}
        </button>
      </span>
    );
  };

  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: '리포트 구분',
      field: 'rptDiv',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
      cellRenderer: (props: ICellRendererParams) =>
        rptDivEnum[`${props!.data.rptDiv}`],
      width: 25,
    },
    {
      headerName: '조회기간',
      field: 'searchDate',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      width: 20,
    },
    {
      headerName: '요청일시',
      field: 'reqTime',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      width: 20,
    },
    {
      headerName: '요청자',
      field: 'memberId',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      width: 15,
    },
    {
      field: '다운로드',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: downloadRenderer,
      width: 20,
    },
  ]);
  const { setValue, getValues } = useForm<MakerRptGrid>();
  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      resizable: true,
      suppressMovable: true,
      sortable: true,
      tooltipComponent: CustomTooltip,
    }),
    []
  );
  const onGridReady = useCallback(() => {
    ApiUtil2.post('/api/mngRpt/finance/list', {}).then((resp) =>
      setRowData(resp.data.data)
    );
  }, []);
  /** 설정 부분 */
  /** 이벤트 함수 부분 */
  const datePickerNextEvent = () => {
    $('#datepicker').data('daterangepicker').addInterval();
    const startDate = $('#datepicker')
      .data('daterangepicker')
      .startDate.format('YYYYMMDD');
    const endDate = $('#datepicker')
      .data('daterangepicker')
      .endDate.format('YYYYMMDD');
    setValue('startDate', startDate);
    setValue('endDate', endDate);
  };
  const datePickerPrevEvent = () => {
    $('#datepicker').data('daterangepicker').subtractInterval();
    const startDate = $('#datepicker')
      .data('daterangepicker')
      .startDate.format('YYYYMMDD');
    const endDate = $('#datepicker')
      .data('daterangepicker')
      .endDate.format('YYYYMMDD');
    setValue('startDate', startDate);
    setValue('endDate', endDate);
  };
  const onGridSizeChanged = (params: GridSizeChangedEvent) => {
    params.api.sizeColumnsToFit();
  };
  const changePagination = () => {
    childRef.current!.onPaginationChanged();
  };
  const financeRptInsert = () => {
    const { startDate, endDate } = getValues();
    const rptDiv = $('#rptDivSelect option:selected').val();
    const makerId = $('#makerIdInput').val();
    ApiUtil2.post('/api/mngRpt/finance/insert', {
      startDate,
      endDate,
      rptDiv,
      makerId,
    }).then(() => onGridReady());
  };
  const financeRptInsertTemp = () => {
    // temp 삭제 예정
    const { startDate, endDate } = getValues();
    const rptDiv = $('#rptDivSelect option:selected').val();
    const makerId = $('#makerIdInput').val();
    const temp = 'temp';
    ApiUtil2.post('/api/mngRpt/finance/insert', {
      startDate,
      endDate,
      rptDiv,
      makerId,
      temp,
    }).then(() => onGridReady());
  };
  /** 이벤트 함수 부분 */
  /** 훅 부분 */
  useEffect(() => {
    setTitle('재무 리포트');
    $('#tempInserBtn').css('display', 'none');
    $('.select2-single').select2({
      width: 'auto',
      minimumResultsForSearch: Infinity,
    });
    $('#rptDivSelect').on('select2:select', (e: any) => {
      if (e.target.value === 'rptDiv04') {
        $('#datepickerSingleDom').css('display', 'block');
        $('#datepickerDom').css('display', 'none');
        setValue(
          'startDate',
          $('#datepickerSingle')
            .data('daterangepicker')
            .startDate.format('YYYYMMDD')
        );
        $('#tempInserBtn').css('display', 'none'); // 삭제 예정
      } else if (e.target.value === 'rptDiv07') {
        $('#tempInserBtn').css('display', ''); // 삭제 예정
        $('#datepickerSingleDom').css('display', 'none');
        $('#datepickerDom').css('display', 'block');
        setValue(
          'startDate',
          $('#datepicker').data('daterangepicker').startDate.format('YYYYMMDD')
        );
        setValue(
          'endDate',
          $('#datepicker').data('daterangepicker').endDate.format('YYYYMMDD')
        );
      } else {
        $('#datepickerSingleDom').css('display', 'none');
        $('#datepickerDom').css('display', 'block');
        setValue(
          'startDate',
          $('#datepicker').data('daterangepicker').startDate.format('YYYYMMDD')
        );
        setValue(
          'endDate',
          $('#datepicker').data('daterangepicker').endDate.format('YYYYMMDD')
        );
        $('#tempInserBtn').css('display', 'none'); // 삭제 예정
      }
    });
    document.addEventListener('keydown', (e) => {
      if (e.keyCode === 192) onGridReady();
    });
  }, []);
  useEffect(() => {
    $('#datepickerSingle').daterangepicker(
      {
        singleDatePicker: true,
        startDate: dayjs().endOf('day').subtract(1, 'd'),
      },
      (start: any) => {
        setValue('startDate', start.format('YYYYMMDD'));
      }
    );
    const datePickerOptionConfig = {
      opens: 'left',
      startDate: dayjs().startOf('day').subtract(7, 'd'),
      endDate: dayjs().endOf('day').subtract(1, 'd'),
      ranges: {
        '오늘 ': [
          dayjs().startOf('day'),
          dayjs().endOf('day').subtract(59, 'm'),
        ],
        '어제 ': [
          dayjs().startOf('day').subtract(1, 'days'),
          dayjs().endOf('day').subtract(59, 'm').subtract(1, 'days'),
        ],
        '이번 주': [
          dayjs().startOf('day').day(0),
          dayjs().day(1).endOf('day').subtract(-7, 'd').subtract(59, 'm'),
        ],
        '저번 주': [
          dayjs().startOf('day').subtract(1, 'week').day(0),
          dayjs().endOf('day').subtract(1, 'week').day(6).subtract(59, 'm'),
        ],
        '최근 7일': [
          dayjs().startOf('day').subtract(7, 'd'),
          dayjs().endOf('day').subtract(1, 'd').subtract(59, 'm'),
        ],
        '최근 30일': [
          dayjs().startOf('day').subtract(30, 'd'),
          dayjs().endOf('day').subtract(1, 'd').subtract(59, 'm'),
        ],
      },
    };
    $('#datepicker').daterangepicker(
      datePickerOptionConfig,
      (start: any, end: any) => {
        $('#datepickerSpan').text(
          $('#datepicker').data('daterangepicker').chosenLabel
        );
        setValue('startDate', start.format('YYYYMMDD'));
        if (Math.abs(start.diff(end, 'days')) > 31) {
          console.error('일자 초과');
          toast.error('한달을 초과한 레포트는 사용하실 수 없습니다.');
          const startTo31 = start.add(31, 'day');
          setValue('endDate', startTo31.format('YYYYMMDD'));
          $('#datepicker').data('daterangepicker').endDate = startTo31;
        } else {
          setValue('endDate', end.format('YYYYMMDD'));
        }
      }
    );
    setValue(
      'startDate',
      $('#datepicker').data('daterangepicker').startDate.format('YYYYMMDD')
    );
    setValue(
      'endDate',
      $('#datepicker').data('daterangepicker').endDate.format('YYYYMMDD')
    );
    return () => {
      $('.daterangepicker').remove();
    };
  }, []);
  /** 훅 부분 */
  return (
    <Fragment>
      <section className="wrap-section wrap-tbl">
        <div className="box-body">
          <div className="tbl">
            <dl>
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">리포트 구분</span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  <select className="select2-single w-300" id="rptDivSelect" defaultValue="rptDiv01">
                    <option value="rptDiv01">매체비 및 대행수수료 상세 내역 (캠페인)</option>
                    <option value="rptDiv02">매체비 및 대행수수료 상세 내역 (광고 대행)</option>
                    <option value="rptDiv03">포인트 충전/사용/잔액 관리 (기간조회)</option>
                    <option value="rptDiv04">포인트 충전/사용/잔액 관리 (시점조회)</option>
                    <option value="rptDiv05">충전금 이동내역 확인</option>
                    <option value="rptDiv06">충전금 및 정산 현황(총괄판)</option>
                    <option value="rptDiv07">프로젝트별 후불광고비 상세</option>
                    <option value="rptDiv08">캠페인별 후불광고비 상세</option>
                    <option value="rptDiv09">메이커별 무상쿠폰 지급이력</option>
                    <option value="rptDiv10">후불머니 정산금 대사</option>
                  </select>
                </div>
              </dd>
            </dl>
            <dl className="column-two">
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">조회기간</span>
                </div>
              </dt>
              <dd id="datepickerDom">
                <div className="form-group">
                  <div className="comp-datepicker">
                    <div className="inner-datepicker">
                      <i className="ico ico-calendar"></i>
                      <input type="text" className="tf-comm datepicker-range right" id="datepicker" readOnly />
                      <span className="fz-12 fc-2" id="datepickerSpan">
                        사용자 설정
                      </span>
                    </div>
                    <button type="button" className="btn btn-prev" onClick={datePickerPrevEvent}></button>
                    <button type="button" className="btn btn-next" onClick={datePickerNextEvent}></button>
                  </div>
                </div>
              </dd>
              <dd id="datepickerSingleDom" style={{ display: 'none' }}>
                <div className="form-group">
                  <div className="comp-datepicker">
                    <div className="inner-datepicker">
                      <i className="ico ico-calendar"></i>
                      <input type="text" className="tf-comm datepicker-single" id="datepickerSingle" readOnly />
                    </div>
                  </div>
                </div>
              </dd>
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">메이커ID</span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  <div className="input-group">
                    <div className="inner-input-group">
                      <input type="text" className="tf-comm" id="makerIdInput" placeholder="ID를 입력해 주세요." />
                    </div>
                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div className="box-footer">
          <div className="box-right">
            <button type="button" className="btn btn btn-secondary-outline btn-ico" onClick={onGridReady}>
              <i className="ico ico-refresh"></i>조회
            </button>
            <button type="button" className="btn btn-primary" onClick={financeRptInsert}>
              생성
            </button>
            {/* temp 삭제 예정 */}
            <button type="button" className="btn btn-primary" id="tempInserBtn" onClick={financeRptInsertTemp}>
              TEMP 생성
            </button>
          </div>
        </div>
      </section>

      <section className="wrap-section wrap-datagrid">
        <div className="box-body">
          <div className="ag-grid">
            <div className="ag-grid-inner">
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                // onGridReady={onGridReady}
                // onFirstDataRendered={onFirstDataRendered}
                onGridSizeChanged={onGridSizeChanged}
                rowHeight={48}
                domLayout={'autoHeight'}
                pagination={true}
                paginationPageSize={10}
                suppressPaginationPanel={true}
                onPaginationChanged={changePagination}
                noRowsOverlayComponent={NoDataTemplate}
              ></AgGridReact>
            </div>
          </div>
        </div>
        <div className="box-footer">
          <Pagination gridRef={gridRef} ref={childRef} />
        </div>
      </section>
      {isDlDialogOpened ? (
        <DlDialog
          setIsDlDialogOpened={setIsDlDialogOpened}
          dlUrl={'/api/mngRpt/finance/download'}
          dlParam={dlParamRef.current}
        />
      ) : null}
    </Fragment>
  )
};

export default Finance;
