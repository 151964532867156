import { useState } from 'react'
import { Button } from 'antd'
import type { TermSelector } from '@biz/api'
import { TermModal } from './TermModal'
import styles from './TermButton.module.scss'

type TermButtonFunction = {
  selector: TermSelector
}
const termTable = {
  PRIVATE_DATA: '개인정보 처리방침',
  TERMS: '광고 서비스 이용약관',
}
export const TermButton: React.FC<TermButtonFunction> = ({ selector }) => {
  const [isOpen, setOpen] = useState(false)
  return (
    <>
      <Button
        type="text"
        className={styles.termButton}
        onClick={() => {
          setOpen(true)
        }}
      >
        {termTable[selector]}
      </Button>
      <TermModal
        selector={selector}
        title={termTable[selector]}
        isOpen={isOpen}
        onClose={() => {
          setOpen(false)
        }}
      />
    </>
  )
}
