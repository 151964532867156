import qs from 'qs';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Cookies } from 'react-cookie';
import { ApiUtil2 } from '@biz/api'
import alertify from 'alertifyjs'
import { loginForm } from '../../AdminLoginPage/Login'
import { ApiUtil } from '@biz/api'

const AuthPopUp = (props:any) => {
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);
  const navigate = useNavigate();
  const cookies = new Cookies();

  useEffect(() => {
    const countdown = setInterval(() => {
      if (parseInt(String(seconds), 10) > 0) {
        setSeconds(parseInt(String(seconds), 10) - 1);
      }
      if (parseInt(String(seconds), 10) === 0) {
        if (parseInt(String(minutes), 10) === 0) {
          clearInterval(countdown);
        } else {
          setMinutes(parseInt(String(minutes), 10) - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => clearInterval(countdown);
  }, [minutes, seconds]);

  useEffect(() => {
    $('#authDialog').dialog({
      title: '인증번호', closeOnEscape: true,
    });

    $(document).on('click', '#closeBtn', () => {
      $('#authDialog').dialog('close');
      props.setIsOpenedPopUp(false);
    });

    $('#validateBtn').on('click', () => {
      const authCode = $('#authCode').val() as string;
      if (authCode === '') {
        alertify.error('인증번호를 입력하세요');
        return;
      }
      /** 설정 > 휴대전화 변경 인증 */
      if (props.authType === 'hpAuth') {
        ApiUtil2.post('/api/admin/setting/validateAuthCode', { hp: props.hp, authCode }).then(() => {
          alertify.success('저장되었습니다.');
          $('#authDialog').dialog('close');
          props.setIsOpenedPopUp(false);
        });
        /** 로그인 처리 */
      } else if (props.authType === 'loginAuth') {
        ApiUtil.post<loginForm>('/api/common/login/doLogin', qs.stringify({ memberId: props.memberId, password: props.password, authCode }), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }).then((response) => {
          if (response.data) {
            alertify.alert('로그인', '로그인성공', () => {
              // 아이디 저장 유무에 따른 쿠키 저장
              const checked = $('#idAutoSave').prop('checked');
              if (checked) cookies.set('idAutoSave', $('#memberId').val());
              else cookies.set('idAutoSave', '');
              // setStatus(true);
              // setLogin(true);
              localStorage.setItem('user', JSON.stringify(response.data));
              // 로그인 유저 정보를 localStorage 저장하여 로그인 상태 여부를 확인
              props.setLoginStatus(true);
              // loginSuccessEvent();
              // loginSuccess();
              navigate('/main');
            });
          }
          $('#authDialog').dialog('close');
          props.setIsOpenedPopUp(false);
        }).catch((e) => {
          console.log(e);
        });
      }
    });

    $('#resendBtn').on('click', () => {
      ApiUtil2.post('/api/admin/setting/generateAuthCode').then(() => {
        setMinutes(5);
        setSeconds(0);
        alertify.success('인증번호를 재발송했습니다.');
      });
    });

    return () => {
      props.setIsOpenedPopUp(false);
      $('#authDialog').dialog('destroy');
    };
  }, []);
  return (
        <Fragment>
            <form>
            <div id="authDialog" className="dialog" style={{ display: 'none' }}>
                <div className="dialog-body">
                    <div className="wrap-section wrap-tbl">
                        <div className="box-body">
                            <div className="tbl">
                                <dl className="vertical">
                                    <dd>
                                        <div className="form-group">
                                            <p className="comp-txt center">
                                                <span className="table">
                                                    <span className="table-cell">
                                                        <b className="fz-20 fc-1 fw-medium">2차 인증번호가 발송되었습니다.</b>
                                                        <b className="fz-12 fc-3 txt-center">인증번호를 입력해주세요.</b>
                                                    </span>
                                                </span>
                                            </p>
                                        </div>
                                    </dd>
                                </dl>
                                <dl className="vertical">
                                    <dd>
                                        <div className="form-group">
                                            <div className="input-group expand w-100">
                                                <div className="inner-input-group">
                                                    <input type="text" className="tf-comm" id='authCode' placeholder="인증번호를 입력해 주세요."/>
                                                </div>
                                                <p className="fz-12 fc-4">인증번호 유효시간: <span className="fc-7">{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>
                                                    <button type="button" id='resendBtn' disabled={minutes >= 2}
                                                            className="btn btn-secondary-outline xsmall w-auto">재전송
                                                    </button>
                                                </p>
                                                <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                            </div>
                                            <button type="button" className="btn btn-primary w-100" id='validateBtn'>확인</button>
                                        </div>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dialog-footer">
                    <button type="button" className="btn btn-secondary-outline large"
                            id='closeBtn'>닫기
                    </button>
                </div>
            </div>
            </form>
        </Fragment>
  );
};

export default React.memo(AuthPopUp);
