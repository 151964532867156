import React, { Fragment, useEffect } from 'react';

const NoConsultingTemplate = () => {
  useEffect(() => {
    const noDataGrid = (document.querySelector<HTMLElement>('.ag-root-wrapper-body.ag-focus-managed.ag-layout-auto-height')! as any);
    const noTreeDataGrid2 = (document.querySelector<HTMLElement>('.ag-root-wrapper.ag-ltr.ag-layout-auto-height')! as any);
    const noDataTempleteArea = (document.querySelector<HTMLElement>('.ag-overlay-wrapper.ag-layout-auto-height.ag-overlay-no-rows-wrapper')! as any);
    if (noDataGrid !== null) {
      noDataGrid.style.height = '548px';
    }
    if (noTreeDataGrid2 !== null) {
      noTreeDataGrid2.style.height = '548px';
    }
    if (noDataTempleteArea !== null) {
      noDataTempleteArea.style.height = '548px';
    }
  }, []);
  return (<Fragment>
        <div className="comp-no-data">
            <div className="box-group">
                <i className="ico ico-no-data"></i>
                <p className="fz-18 fc-2">
                    대행 신청 내역이 없습니다.<br/>
                    우측 상단의 [광고 대행 신청]을 눌러서 광고 대행을 신청하세요.
                </p>
            </div>
        </div>
    </Fragment>);
};

export default NoConsultingTemplate;
