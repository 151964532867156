import { Input, Form } from 'antd'
import { targetFormRegistry } from '@biz/ui'
import styles from './InputAdDescription.module.scss'

type InputAdProps = {
  containerName: number
  onUpdate?: () => void
  disabled?: boolean
}
export const InputAdDescription: React.FC<InputAdProps> = ({ containerName, onUpdate, disabled }) => (
  <Form.Item name={[containerName, targetFormRegistry.InputAdDescriptionName]} className={styles.InputAdDescription}>
    <Input.TextArea
      placeholder="광고 설명을 입력하세요(선택)"
      showCount
      maxLength={10000}
      autoSize={{ minRows: 1, maxRows: 6 }}
      onBlur={onUpdate}
      disabled={disabled}
    />
  </Form.Item>
)
