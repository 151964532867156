import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { ColDef, GridSizeChangedEvent, ICellRendererParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useNavigate } from 'react-router'
import alertify from 'alertifyjs'
import dayjs, { Dayjs } from 'dayjs'

import { ApiUtil, ApiUtil2, campaignStatusTable } from '@biz/api'
import { PageTitle } from '@biz/ui'

import { GridCustomHeader } from '../../views/common/GridCustomHeader'
import NoDataTemplate from '../../views/common/NoDataTemplate'
import Pagination from '../../views/common/Pagination'
import DlDialog from '../../views/mngMakerAccount/DlDialog'

import CreationDataInspHisDlDialog from './CreationDataInspHisDlDialog'
import RangeDatePicker2 from './RangeDatePicker2'

export interface FilterOptions {
  /** 캠페인 조회기준 */
  allYn: string
  /** 조회기간 - 시작일 */
  startDate: string
  /** 조회기간 - 종료일 */
  endDate: string
  /** 검색어 */
  keyword?: string
  /** 검색어 타입 */
  srchType?: string
  /** 캠페인 상태 */
  campViewStatus?: string
  /** 검수필요유무 */
  catInspDiv?: string
  /** 심의 방식 */
  inspectionMode?: string
  api: { sizeColumnsToFit: () => void }
}

const inspDetailRenderer = (props: ICellRendererParams) => {
  const { campId } = props.data
  return (
    <Link className="txt-link" to={`/mngAd/creationDetail/${props.data.mediaDiv4}/${campId}`}>
      {props.data.inspCnt}
    </Link>
  )
}

const ReorNewinspCntRenderer = (props: ICellRendererParams) => {
  const { newInspCnt } = props.data
  const { reInspCnt } = props.data
  return `${newInspCnt}/${reInspCnt}`
}

const LOCAL_FILTER_DATA_NAME = 'prevFilterData'

/**
 * 소재 관리
 */
const Creation = () => {
  const gridRef: any = useRef(null) // useRef 에러 수정(초기화)
  const childRef = useRef<any>(null)
  const [rowData, setRowData] = useState<any[]>([])
  const savedData = localStorage.getItem(LOCAL_FILTER_DATA_NAME)
  const defaultValues = savedData
    ? JSON.parse(savedData)
    : { startDate: dayjs().startOf('day').subtract(7, 'd').format('YYYYMMDD') }

  const { getValues, register, handleSubmit, setValue } = useForm<FilterOptions>({ defaultValues })
  console.log(defaultValues, 'set default')
  const navigate = useNavigate()
  const url = useLocation()
  const [isDlDialogOpen, setIsDlDialogOpen] = useState<boolean>(false)
  const [isInspDlDialogOpen, setIsInspDlDialogOpen] = useState<boolean>(false)
  const [type, setType] = useState<String>('')
  const [disabled, setDisabled] = useState(getValues('allYn') === 'alln')
  const [campViewStatusList, setCampViewStatusList] = useState<string[][]>([])

  const initTooltip = () => {
    $('#wabizInspReqDate').tooltip({
      content:
        '<p class="fz-12 fc-0 fw-medium"></p><p class="fz-12 fc-0">해당 캠페인에서 가장 최근에 검수가 요청된 소재의 검수요청 시간을 표기합니다.</p>',
    })
  }

  const onCellClicked = (params: any) => {
    if (params.event.altKey === true) {
      // alt키
      window.open(`/wabiz/event/${params.data.campId}`)
    }
  }

  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'makerNm',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerName: '메이커명',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
      width: 110,
    },
    {
      field: 'campId',
      headerName: '캠페인ID',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
      width: 80,
    },
    {
      field: 'campNm',
      headerName: '캠페인명',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
      width: 200,
    },
    {
      field: 'projectId',
      headerName: '프로젝트ID',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      width: 90,
    },
    {
      field: 'projectDiv3',
      headerName: '유형',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      width: 90,
    },
    {
      field: 'inspCnt',
      headerComponent: GridCustomHeader,
      headerComponentParams: { header1: '신규/수정', header2: '검수소재' },
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: ReorNewinspCntRenderer,
      width: 80,
    },
    {
      field: 'inspCnt',
      headerComponent: GridCustomHeader,
      headerComponentParams: {
        header1: '검수소재',
        header2: '(일반/검수필요)',
      },
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: inspDetailRenderer,
      width: 80,
    },
    {
      field: 'catInspConfig',
      headerName: '심의 방식',
      headerClass: 'ag-center-aligned-header',
      width: 80,
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: (params: ICellRendererParams) =>
        params.data.catInspConfig.catInspDiv === 'aiApprove' ? 'AI 자동 심의' : '관리자 심의',
    },
    {
      field: 'catInspDivDesc',
      headerName: '검수필요 유무',
      headerClass: 'ag-center-aligned-header',
      getQuickFilterText: (params) => {
        const { catInspDiv } = params.data
        return catInspDiv
      },
      cellClass: 'ag-center-aligned-cell',
      width: 100,
    },
    {
      field: 'okCnt',
      headerComponent: GridCustomHeader,
      headerComponentParams: { header1: '승인', header2: '소재' },
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      width: 50,
    },
    {
      field: 'rejectCnt',
      headerComponent: GridCustomHeader,
      headerComponentParams: { header1: '반려', header2: '소재' },
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      width: 50,
    },
    {
      field: 'wabizInspReqDate',
      headerName: '검수요청 시간',
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="wabizInspReqDate" title=""></a>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>',
      },
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      width: 120,
      cellRenderer: (props: ICellRendererParams) =>
        props!.data.wabizInspReqDate === undefined ? '-' : props!.data.wabizInspReqDate,
    },
    {
      field: 'campPerd',
      headerName: '캠페인 기간',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      width: 150,
    },
    {
      field: 'campViewStatus',
      headerName: '캠페인 상태',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      width: 100,
      cellRenderer: (props: ICellRendererParams) =>
        campaignStatusTable[props.data.campViewStatus as keyof typeof campaignStatusTable],
    },
    {
      field: 'wabizInspAdminLoginId',
      headerName: '최종 수정자',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      width: 120,
    },
    {
      field: 'wabizInspUpdateDate',
      headerName: '최종 처리시간',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      width: 120,
      cellRenderer: (props: ICellRendererParams) =>
        props!.data.wabizInspUpdateDate === undefined ? '-' : props!.data.wabizInspUpdateDate,
    },
  ])

  const onBtnExport = useCallback(() => {
    setIsDlDialogOpen(true)
    setType('다운로드')
  }, [])

  const popupParent = useMemo(() => document.body, [])

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      resizable: true,
      suppressMovable: true,
      sortable: true,
    }),
    []
  )

  // onGridSizeChanged will get called when grid is ready and every time the grid's width changes
  const onGridSizeChanged = (params: GridSizeChangedEvent) => {
    params.api.sizeColumnsToFit()
  }

  const getDateDiff = (d1: any, d2: any) => {
    const date1 = new Date(d1)
    const date2 = new Date(d2)
    const diffDate = date1.getTime() - date2.getTime()
    return Math.abs(diffDate / (1000 * 60 * 60 * 24)) // 밀리세컨 * 초 * 분 * 시 = 일
  }

  const onGridReady = () => {
    let dateDiff = getDateDiff(dayjs(getValues('startDate')), dayjs(getValues('endDate')))
    if (dateDiff < 30) {
      const allYn = getValues('allYn')
      const startDate = getValues('startDate')
      const endDate = getValues('endDate')
      const campViewStatus = getValues('campViewStatus')
      const catInspDiv = getValues('catInspDiv')
      const srchType = getValues('srchType')
      const keyword = getValues('keyword')
      const inspectionMode = getValues('inspectionMode')

      const data = {
        allYn,
        startDate,
        endDate,
        campViewStatus,
        catInspDiv,
        srchType,
        keyword,
        inspectionMode,
      }
      console.log(data, '-save')
      localStorage.setItem(LOCAL_FILTER_DATA_NAME, JSON.stringify(data))

      // ApiUtil2.get('/api/admin/campaigns/creations', {
      // 기존 URL 임시 원복
      ApiUtil2.get('/api/mngAd/creation/list', {
        params: {
          data,
        },
      }).then((resp) => {
        initTooltip()
        setRowData(resp.data.data)
      })
    } else {
      alertify.error('조회 기간은 최대 30일까지 설정할 수 있습니다.')
    }
  }

  const inspHandler = () => {
    const selectedRows = gridRef.current!.api.getSelectedRows()
    const campIds = selectedRows.map((campData: { campId: any }) => campData.campId)
    if (campIds.length === 0) {
      alertify.error('검수할 항목을 선택하신 후 다시 시도해 주세요.\n')
    } else {
      navigate('/mngAd/creationDetail/all', { state: { campIds } })
    }
  }

  const changePagination = () => {
    childRef.current!.onPaginationChanged()
  }

  // datepicker
  const registerDate = (start: Dayjs, end: Dayjs) => {
    setValue('startDate', start.format('YYYYMMDD'))
    setValue('endDate', end.format('YYYYMMDD'))
  }

  useEffect(() => {
    /** 캠페인 조회 기준 */
    $('#allYn')
      .select2({
        width: '150',
        minimumResultsForSearch: Infinity,
        placeholder: '선택해 주세요.',
      })
      .on('select2:select', (event) => {
        if (event.params.data.id === 'ally') {
          setDisabled(false)
        } else if (event.params.data.id === 'alln') {
          setDisabled(true)
        }

        setValue('allYn', event.params.data.id)
      })

    $('#catInspDiv')
      .select2({
        width: '150',
        minimumResultsForSearch: Infinity,
        placeholder: '선택해 주세요.',
      })
      .on('select2:select', (e) => {
        // onQuickFilterChanged(e.params.data.id) //어차피 진입시
        setValue('catInspDiv', e.params.data.id)
      })

    $('#inspectionMode')
      .select2({
        width: '150',
        minimumResultsForSearch: Infinity,
        placeholder: '선택해 주세요.',
      })
      .on('select2:select', (e) => {
        setValue('inspectionMode', e.params.data.id)
      })

    $('#campViewStatus')
      .select2({
        width: '150',
        minimumResultsForSearch: Infinity,
        placeholder: '선택해 주세요.',
      })
      .on('select2:select', (e) => {
        // onQuickFilterChanged(e.params.data.id) //어차피 진입시
        setValue('campViewStatus', e.params.data.id)
      })

    $('#srchType')
      .select2({
        width: '150',
        minimumResultsForSearch: Infinity,
        placeholder: '선택해 주세요.',
      })
      .on('select2:select', (e) => {
        setValue('srchType', e.params.data.id)
      })

    ApiUtil.get('/api/mngAd/creation/getCampViewStatus').then((resp) => {
      const options = Object.keys(resp.data.data).map((key) => [String(key), resp.data.data[key]])
      setCampViewStatusList(options)
    })

    /** 글자수 */
    $('#keyword').on('keyup', () => {
      const value = $('#keyword').val() as string
      if (value.length > 30) {
        $('#keyword').val(value.substring(0, 30))
      }
    })
  }, [])

  useEffect(() => {
    // 캠페인 상태 필터의 기본값 설정
    if (campViewStatusList?.length > 0 && defaultValues.campViewStatus) {
      setValue('campViewStatus', defaultValues.campViewStatus)
      $('#campViewStatus').val(defaultValues.campViewStatus).trigger('change') // select2 업데이트
    }
  }, [defaultValues, campViewStatusList])

  return (
    <>
      {isDlDialogOpen && (
        <DlDialog
          url={url.pathname}
          type={type}
          setIsDlDialogOpened={setIsDlDialogOpen}
          catInspDiv={$('#catInspDiv').val()}
          campViewStatus={$('#campViewStatus').val()}
          srchType={$('#srchType').val()}
          keyword={$('#keyword').val()}
          allYn={$('#allYn').val()}
          startDate={getValues('startDate')}
          endDate={getValues('endDate')}
          inspectionMode={getValues('inspectionMode')}
        />
      )}
      {isInspDlDialogOpen && <CreationDataInspHisDlDialog setIsInspDlDialogOpened={setIsInspDlDialogOpen} />}
      <PageTitle>소재 검수</PageTitle>
      <section className="wrap-section wrap-datagrid">
        <div className="wrap-filter">
          <form onSubmit={handleSubmit(onGridReady)}>
            <div className="inner-filter">
              <div className="box-left">
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">캠페인 조회 기준</p>
                  </div>
                  <div className="box-filter">
                    <select id="allYn" className="select2-single" {...register('allYn')}>
                      <option value="ally">전체 캠페인</option>
                      <option value="alln">검수 요청</option>
                    </select>
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">조회기간</p>
                  </div>
                  <div className="box-filter">
                    <RangeDatePicker2
                      changeEvent={registerDate}
                      option={{
                        startDate: dayjs(defaultValues.startDate),
                        endDate: dayjs(defaultValues.endDate),
                      }}
                      disabled={disabled}
                      onGridReady={onGridReady}
                    />
                  </div>
                </div>
                {/** 캠페인 상태 */}
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">캠페인 상태</p>
                  </div>
                  <div className="box-filter">
                    <select id="campViewStatus" className="select2-single" {...register('campViewStatus')}>
                      <option value="all">전체</option>
                      {campViewStatusList.map((status: any, index: number) => {
                        return (
                          <option key={index} value={status[0]}>
                            {status[1]}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>

                {/** 심의 방식 */}
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">심의 방식</p>
                  </div>
                  <div className="box-filter">
                    <select id="inspectionMode" className="select2-single" {...register('inspectionMode')}>
                      <option value="all">전체</option>
                      <option value="adminApprove">관리자 심의</option>
                      <option value="aiApprove">AI 자동 심의</option>
                    </select>
                  </div>
                </div>

                {/** 검수필요유무 */}
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">검수 필요 유무</p>
                  </div>
                  <div className="box-filter">
                    <select id="catInspDiv" className="select2-single" {...register('catInspDiv')}>
                      <option value="all">전체</option>
                      <option value="must">검수필요</option>
                      <option value="kwd">일반</option>
                    </select>
                  </div>
                </div>

                {/** 검색어 */}
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">검색어</p>
                  </div>
                  <div className="box-filter">
                    <select id="srchType" className="select2-single" {...register('srchType')}>
                      <option value="makerNm">메이커명</option>
                      <option value="projectId">프로젝트ID</option>
                      <option value="campId">캠페인ID</option>
                      <option value="campNm">캠페인명</option>
                    </select>
                  </div>
                  <div className="box-filter">
                    <div className="input-group">
                      <div className="inner-input-group">
                        <input
                          id="keyword"
                          type="text"
                          className="tf-comm w-350"
                          placeholder="검색어를 입력해 주세요."
                          {...register('keyword')}
                        ></input>
                      </div>
                      <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-right">
                <button type="submit" className="btn btn-tertiary-mint btn-ico">
                  <i className="ico ico-filter"></i>필터 조회
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="box-header">
          <div className="box-tit">
            <button type="button" className="btn btn btn-primary" onClick={inspHandler} disabled={!disabled}>
              검수 진행
            </button>
            <button
              type="button"
              className="btn btn-primary-outline"
              onClick={() => {
                navigate('/mngAd/creation/categoryInspMng')
              }}
            >
              카테고리 관리
            </button>
            <button
              type="button"
              className="btn btn-primary-outline"
              onClick={() => {
                navigate('/mngAd/creation/creationCheckMng')
              }}
            >
              셀프 체크리스트
            </button>
          </div>
          <div className="box-option">
            <button
              type="button"
              className="btn btn btn-secondary-outline btn-ico"
              onClick={() => {
                setIsInspDlDialogOpen(true)
              }}
            >
              검수이력 다운로드
            </button>
            <button type="button" className="btn btn btn-secondary-outline btn-ico" onClick={onBtnExport}>
              <i className="ico ico-download"></i>다운로드
            </button>
          </div>
        </div>
        <div className="box-body">
          <div className="ag-grid">
            <div className="ag-grid-inner">
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowSelection={'multiple'}
                // onGridReady={onGridReady} ISMS
                onGridSizeChanged={onGridSizeChanged}
                rowHeight={48}
                // getRowHeight={getRowHeight}
                pagination={true}
                paginationPageSize={10}
                popupParent={popupParent}
                suppressPaginationPanel={true}
                suppressExcelExport={true}
                suppressScrollOnNewData={true}
                onPaginationChanged={changePagination}
                domLayout={'autoHeight'}
                noRowsOverlayComponent={NoDataTemplate}
                alwaysShowHorizontalScroll={true}
                suppressRowClickSelection={true}
                suppressContextMenu={true}
                enableCellTextSelection={true}
                onCellClicked={onCellClicked}
              ></AgGridReact>
            </div>
          </div>
        </div>
        <div className="box-footer">
          <Pagination gridRef={gridRef} ref={childRef} />
        </div>
      </section>
    </>
  )
}

export default Creation
