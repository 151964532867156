import type { CreativeType } from '@biz/query'
import { targetFormRegistry } from '../settings/targetFormRegistry'

const {
  CreativeAssetsId,
  InputAdCopyName,
  ImageManagerName,
  CTASelectorName,
  InputAdTitleName,
  InputAdDescriptionName,
  // CreativeAssetsName,
} = targetFormRegistry

export const makeCreativeAssetField = (n: CreativeType) => ({
  [CreativeAssetsId]: n.id,
  [InputAdCopyName]: n.adDesc,
  [ImageManagerName]: n.afList,
  [CTASelectorName]: n.clickBtnDiv,
  [InputAdTitleName]: n.adTitle01,
  [InputAdDescriptionName]: n.adTitle02,
})

export const AssetFieldToCreativeData = (n: any) => ({
  id: n[CreativeAssetsId],
  adDesc: n[InputAdCopyName],
  clickBtnDiv: n[CTASelectorName],
  adTitle01: n[InputAdTitleName],
  adTitle02: n[InputAdDescriptionName],
})
