import styles from './PageTitle.module.scss'

type PageTitleFunction = {
  children?: React.ReactNode
}
export const PageTitle: React.FC<PageTitleFunction> = ({ children }) => (
  <div className={styles.PageTitle}>
    <div>
      <h2 className={styles.header}>{children}</h2>
    </div>
  </div>
)
