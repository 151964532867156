import { axios } from '../axios'
import { CategoryInspectionConfig } from './category'

interface Response {
  ok: boolean
  data: boolean
}

/**
 * 카테고리 검수 정보 저장
 * @see https://wadiz.atlassian.net/l/cp/YsETfS1Z
 */
export const postCategory = (param: CategoryInspectionConfig[]): Promise<Response> =>
  axios.post('/api/mngAd/creation/categoryInspMng/saveCateInspConfig', param)
