import { axiosInstance } from '../axios'
type DownFunction = (params: string) => Promise<File>
export const fetchDailyDownload: DownFunction = (params) =>
  axiosInstance
    .get(`/api/mngRpt/sales/dp/daily/download?${params}`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'text/plain; charset=euc-kr',
      },
    })
    .then((res) => res.data)
