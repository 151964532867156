import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { DataFormat } from 'select2';
import { useForm } from 'react-hook-form';
import { Dayjs } from 'dayjs';
import { Dialog } from 'rc-easyui';
import NoDataTemplate from '../../common/NoDataTemplate';
import Pagination from '../../common/Pagination';
import { ApiUtil2 } from '@biz/api'
import alertify from 'alertifyjs'
import {
  campGrid,
  consultingGrid,
  defaultColDef,
  IComponents,
  IMakerSearchForm,
  IMultiSelect,
  ITxSearchForm,
  makerGrid,
  multiSelectHandler,
  toMultiSelect,
} from '../AccAdjustTargetUtil';
import RangeDatePicker from '../RangeDatePicker';
import SingleSelect2 from '../SingleSelect2';

const makerStatusData = {
  all: { text: '전체', selected: true },
  nm: { text: '정상', selected: true },
  ia: { text: '휴면', selected: true },
  do: { text: '탈퇴', selected: true },
};

const resizableColDef = { ...defaultColDef, ...{ resizable: true } };

const AccAdjustDialogSearchUserNo = (props: any) => {
  // grid 요소
  const childRef = useRef<any>(null);
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>([]);
  const [columnDefs, setColumnDefs] = useState<IComponents>(makerGrid);
  const [makerStatus, setMakerStatus] = useState<IMultiSelect>(toMultiSelect(makerStatusData));
  const [makerStatusLabel, setMakerStatusLabel] = useState<string>('전체');

  // dialog 요소
  const dialogRef = useRef<any>();
  const [selectedTab, setSelectedTab] = useState<string>('maker');

  // form
  const {
    setValue, getValues, register,
  } = useForm<ITxSearchForm | IMakerSearchForm>();

  const updateMakerStatusForm = (value: string) => setValue('makerStatus', value);
  const updateMakerStatusLabel = (label: string) => setMakerStatusLabel(label);
  const updateMakerStatus = (newState: Map<string, boolean>) => setMakerStatus({ ...makerStatus, selects: newState });

  const moveToCenter = () => {
    dialogRef.current.center();
  };

  const changeTab = (tabName: string) => {
    // $('#accStatus').val('all').trigger('change');
    // $('#searchType').val('all').trigger('change');

    if (tabName === 'camp') {
      setColumnDefs({ ...campGrid });
    } else if (tabName === 'consulting') {
      setColumnDefs({ ...consultingGrid });
    } else {
      setColumnDefs({ ...makerGrid });
    }

    setRowData([]);

    setSelectedTab(tabName);
  };

  // 취소 버튼
  const closeUserNoPopup = () => {
    // $('#accAdjustRegUserNoPopup').dialog('close');
    props.setUserNoDialog(false);
  };

  // 확인 버튼
  const selectUserNo = () => {
    const selects = gridRef!.current!.api.getSelectedRows();
    if (selects === undefined || selects.length < 1) {
      alertify.error('임의조정 대상을 선택해 주세요.');
      return;
    }

    props.setAdjustTarget(selects[0]);
    closeUserNoPopup();
  };

  const registerDate = (start:Dayjs, end:Dayjs) => {
    setValue('startDate', start.format('YY-MM-DD'));
    setValue('endDate', end.format('YY-MM-DD'));
  };

  const updateSearchTypeForMaker = (value: any) => {
    setValue('searchTypeForMaker', value);
  };

  const updateSearchTypeForCamp = (value: any) => {
    setValue('searchTypeForCamp', value);
  };

  const updateSearchTypeForConsulting = (value: any) => {
    setValue('searchTypeForConsulting', value);
  };

  const accStatuses:DataFormat[] = [{ id: 'all', text: '전체', selected: true }, { id: 'true', text: '활성' }, { id: 'false', text: '비활성' }];
  const updateAccStatus = (value: any) => {
    setValue('accStatus', value);
  };

  useEffect(() => {
    // component 생성
    // $('#accAdjustRegUserNoPopup').dialog({
    //   width: 1200, title: '임의조정 대상 조회', closeOnEscape: true,
    // });

    // makeSelect2('searchTypeForMaker', makerGrid.searchTypes, setValue);
    // makeSelect2('searchTypeForCamp', campGrid.searchTypes, setValue);
    // makeSelect2('searchTypeForConsulting', consultingGrid.searchTypes, setValue);

    // makeSelect2('accStatus', accStatuses, setValue);

    // const startDate = dayjs().startOf('day').subtract(30, 'd');
    // const endDate = dayjs().startOf('day');
    // registerDate(startDate, endDate);

    /** datepicker */
    // makeDateRangePicker('#datepicker', startDate, endDate, registerDate);
    // moveToCenter();
    // return () => {
    // $('#accAdjustRegUserNoPopup').dialog('destroy');
    // };
  }, []);

  const loadData = () => {
    ApiUtil2.get(columnDefs.searchUrl, {
      params: {
        data: columnDefs.callback(getValues()),
      },
    }).then((resp) => {
      setRowData(resp.data.data);
    });
  };

  const changePagination = () => {
    childRef!.current!.onPaginationChanged();
  };

  // 넘 복잡해서 따로 뺌
  const MakerStatusSelectBox = () => (
    <div className="dropdown-menu expand">
      <ul className="opt-selectbox" onClick={(e:any) => e.stopPropagation()} onKeyUp={(e:any) => e.handleKeyUp} role="presentation">
        {
          Array.from(makerStatus.texts, ([key, value]) => ({ key, value }))
            .map(({ key, value }) => {
              const checkboxId = `ms-cb-${key}`;
              return <li className="opt-menu" key={key}>
                <div className="comp-checkbox small">
                  <input name="makerStatus" type="checkbox"
                         id={checkboxId}
                         onChange={() => 0}
                         onClick={(e: any) => { multiSelectHandler(key, e.target.checked, makerStatus, updateMakerStatus, updateMakerStatusLabel, updateMakerStatusForm); }}
                         checked={makerStatus.selects.get(key) === true}/>
                  <label htmlFor={checkboxId}>{value}</label>
                </div>
              </li>;
            })
        }
      </ul>
    </div>
  );

  return (
    <Fragment>
      <form>
        <Dialog title="임의조정 대상 조회" modal resizable shadow={false} ref={dialogRef} style={{ width: '1200px' }}>
          <div className="dialog">
            <div className="dialog-body">
              {/* Comp-Tab : Start */}
              {/* <TabButtons /> */}
              <div className="comp-tab">
                <button className={`tab ${selectedTab === 'camp' ? 'selected' : ''}`} onClick={() => changeTab('camp')}>
                  <span className="tab-item">캠페인 조회</span>
                </button>
                <button className={`tab ${selectedTab === 'consulting' ? 'selected' : ''}`}
                        onClick={() => changeTab('consulting')}>
                  <span className="tab-item">광고 대행 조회</span>
                </button>
                <button className={`tab ${selectedTab === 'maker' ? 'selected' : ''}`} onClick={() => changeTab('maker')}>
                  <span className="tab-item">메이커 조회</span>
                </button>
              </div>
              {/* Comp-Tab : End */}

              {/* Comp-Tab-Content : Start */}
              <div className="comp-tab-content selected">
                {/* Wrap-Datagrid : Start */}
                <section className="wrap-section wrap-datagrid">
                  {/* Wrap-Filter : Start */}
                  <div className="wrap-filter">
                    <div className="inner-filter">
                      <div className="box-left">
                        <div className="item-filter" style={{ display: `${selectedTab === 'maker' ? 'none' : ''}` }}>
                          <div className="filter-tit">
                            <p className="fz-12 fc-2">조회기간</p>
                          </div>
                          <div className="box-filter">
                            <RangeDatePicker changeEvent={registerDate}
                                             /* option={{ startDate: dayjs().startOf('day').subtract(30, 'd') }} */
                            />
                            {/*
                            <div className="comp-datepicker">
                              <div className="inner-datepicker">
                                <i className="ico ico-calendar"></i>
                                <input id="datepicker" type="text" className="tf-comm datepicker-range right" readOnly />
                              </div>
                            </div>
                            */}
                          </div>
                        </div>
                        {/* 메이커 탭 아닐떄만 보여줌 */}
                        <div className="item-filter" style={{ display: `${selectedTab === 'maker' ? 'none' : ''}` }}>
                          <div className="filter-tit">
                            <p className="fz-12 fc-2">메이커ID/메이커명</p>
                          </div>
                          <div className="box-filter">
                            <div className="input-group">
                              <div className="inner-input-group">
                                <input type="text" className="tf-comm" {...register('maker')}/>
                              </div>
                              <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                            </div>
                          </div>
                        </div>
                        {/* 메이커 탭에서만 보여줌 */}
                        <div className="item-filter" style={{ display: `${selectedTab === 'maker' ? '' : 'none'}` }}>
                          <div className="filter-tit">
                            <p className="fz-12 fc-2">계정상태</p>
                          </div>
                          <div className="box-filter">
                            <div className="comp-dropdown select2 w-150 selected">
                              <a href="src/components/views/accAdjust/dialog/AccAdjustDialogSearchUserNo#javascript" className="dropdown-toggle" data-toggle="dropdown">
                                <div className="box-left">
                                  <span className="fz-14">{makerStatusLabel}</span>
                                </div>
                                <div className="box-right">
                                  <i className="ico ico-arrow"></i>
                                </div>
                              </a>
                              <MakerStatusSelectBox />
                            </div>
                          </div>
                        </div>
                        <div className="item-filter" style={{ display: `${selectedTab === 'maker' ? '' : 'none'}` }}>
                          <div className="filter-tit">
                            <p className="fz-12 fc-2">활성여부</p>
                          </div>
                          <div className="box-filter">
                            {/* <select className="select2-single w-150" id="accStatus" /> */}
                            <SingleSelect2 data={accStatuses} selectEvent={updateAccStatus} />
                          </div>
                        </div>
                        <div className="item-filter">
                          <div className="filter-tit">
                            <p className="fz-12 fc-2">검색구분</p>
                          </div>
                          <div className="box-filter" style={{ display: `${selectedTab === 'maker' ? '' : 'none'}` }}>
                            {/* Select2 : Start */}
                            {/* <select className="select2-single w-150" id="searchTypeForMaker" /> */}
                            <SingleSelect2 data={makerGrid.searchTypes} selectEvent={updateSearchTypeForMaker} clss={'w-150'} />
                            {/* Select2 : End */}
                          </div>
                          <div className="box-filter" style={{ display: `${selectedTab === 'camp' ? '' : 'none'}` }}>
                            {/* Select2 : Start */}
                            {/* <select className="select2-single w-150" id="searchTypeForCamp" /> */}
                            <SingleSelect2 data={campGrid.searchTypes} selectEvent={updateSearchTypeForCamp} clss={'w-150'} />
                            {/* Select2 : End */}
                          </div>
                          <div className="box-filter" style={{ display: `${selectedTab === 'consulting' ? '' : 'none'}` }}>
                            {/* Select2 : Start */}
                            {/* <select className="select2-single w-150" id="searchTypeForConsulting" /> */}
                            <SingleSelect2 data={consultingGrid.searchTypes} selectEvent={updateSearchTypeForConsulting} clss={'w-150'} />
                            {/* Select2 : End */}
                          </div>
                        </div>

                        <div className="item-filter">
                          <div className="filter-tit">
                            <p className="fz-12 fc-2">검색어</p>
                          </div>
                          <div className="box-filter">
                            {/* Input-Group : Start */}
                            <div className="input-group">
                              <div className="inner-input-group">
                                <input type="text" className="tf-comm" placeholder="검색어를 입력해 주세요." {...register('keyword')} />
                              </div>
                              <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                            </div>
                            {/* Input-Group : End */}
                          </div>
                        </div>
                      </div>
                      <div className="box-right">
                        <button type="submit" className="btn btn-tertiary-mint btn-ico" onClick={loadData}>
                          <i className="ico ico-filter"></i>필터 조회
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="box-body">
                    <div className="ag-grid">
                      <div className="ag-grid-inner">
                        <AgGridReact
                          ref={gridRef}
                          rowData={rowData}
                          // onGridReady={moveToCenter}
                          onGridSizeChanged={moveToCenter}
                          columnDefs={columnDefs.colDefs}
                          defaultColDef={resizableColDef}
                          onPaginationChanged={changePagination}
                          rowHeight={48}
                          pagination={true}
                          paginationPageSize={10}
                          suppressPaginationPanel={true}
                          // suppressRowClickSelection={true}
                          rowSelection={'single'}
                          domLayout={'autoHeight'}
                          noRowsOverlayComponent={NoDataTemplate}
                        ></AgGridReact>
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <Pagination
                      gridRef={gridRef}
                      ref={childRef}
                    />
                  </div>
                </section>
                {/* Wrap-Datagrid : End */}
              </div>
              {/* Comp-Tab-Content : End */}
            </div>
            <div className="dialog-footer">
              <button type="button" className="btn btn-secondary-outline large" onClick={closeUserNoPopup}>취소</button>
              <button type="button" className="btn btn-primary large" onClick={selectUserNo}>확인</button>
            </div>
          </div>
        </Dialog>
      </form>
    </Fragment>
  );
};

export default AccAdjustDialogSearchUserNo;
