import { Modal, Button } from 'antd'
import type { FundingType } from '../fundingType'
import styles from './SelectorModal.module.scss'

type SelectorModalFunction = {
  isOpen: boolean
  onResult: (result: FundingType) => void
}
export const SelectorModal: React.FC<SelectorModalFunction> = ({ isOpen, onResult }) => (
  <Modal
    width={400}
    open={isOpen}
    closable={false}
    className={styles.modalSelectorModal}
    footer={[
      <div className={styles.modalFooter} key={'container'}>
        <Button type="default" block size="large" onClick={() => onResult('FUNDING_OPEN')} key={'오픈예정'}>
          {'오픈 예정'}
        </Button>
        <Button type="primary" block size="large" onClick={() => onResult('FUNDING_ING')} key={'펀딩진행중'}>
          {'펀딩 진행중'}
        </Button>
      </div>,
    ]}
  >
    <div>{'해당 프로젝트는 펀딩 프로젝트입니다.'}</div>
    <div>{'광고를 진행할 펀딩 상태를 선택해주세요.'}</div>
  </Modal>
)
