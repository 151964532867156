import React, { Fragment, useEffect, useState } from 'react';
import ReactHtmlParser from 'html-react-parser';
import { ApiUtil2 } from '@biz/api'
import { ISystemData } from './SystemData';

const SystemDataDialog = (props:any) => {
  const [text, setText] = useState<string>('');
  const [systemDataDiv, setSystemDataDiv] = useState<string>('');
  const [regTime, setRegTime] = useState<string>('');

  useEffect(() => {
    $('#close').click(() => {
      $('#advApiRegDialog').dialog('close');
      props.setIsClicked(false);
    });
    return () => {
      $('#advApiRegDialog').dialog('destroy');
    };
  }, []);

  useEffect(() => {
    ApiUtil2.post<ISystemData>('/api/community/systemData/getDetail', { systemDataId: props.systemDataId }).then((resp) => {
      if (resp.data.data.systemDataDiv === 'PRIVATE_DATA') {
        $('#advApiRegDialog').dialog({ width: 1200, title: '개인정보 처리방침', closeOnEscape: true });
      }
      if (resp.data.data.systemDataDiv === 'TERMS') {
        $('#advApiRegDialog').dialog({ width: 1200, title: '광고 서비스 이용약관', closeOnEscape: true });
      }
      setRegTime(resp.data.data.regTime);
      setSystemDataDiv(resp.data.data.systemDataDiv);
      const temp = resp.data.data.text;
      setText(temp);
      $('#advApiRegDialog').dialog('open');
    });
  }, [props.systemDataId]); // 이 값이 변할때마다 호출

  return (
        <Fragment>
            <div className="form-group">
                <div id="advApiRegDialog" className="dialog" style={{ display: 'none' }}>
                    <div className="dialog-body">
                        <section className="wrap-section wrap-tbl wrap-terms">
                            <div className="box-header">
                                <div className="box-tit">
                                    <h2 className="fz-20 fc-1 fw-bold">{systemDataDiv === 'PRIVATE_DATA' ? '개인정보 처리방침' : '광고서비스 이용약관' }</h2>
                                    <h3 className="fz-12 fc-3">{regTime}</h3>
                                </div>
                            </div>
                            <div className="box-body" id="bs">
                                <div className="tbl">
                                    <dl className="vertical">
                                        <dd>
                                            <div className="form-group">
                                                <ul className="inner-content-terms">
                                                    <li>
                                                        <span className="fz-14">{ReactHtmlParser(text)}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="dialog-footer">
                        <button type="button" className="btn btn-secondary-outline large" id="close">닫기</button>
                    </div>
                </div>
            </div>
        </Fragment>
  );
};
export default SystemDataDialog;
