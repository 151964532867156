import { Modal, Button } from 'antd'
import ReactHtmlParser from 'html-react-parser'
import type { TermSelector } from '@biz/api'
import { useTermQuery } from './_queries/useTermQuery'
import styles from './TermModal.module.scss'

type TermModalFunction = {
  selector: TermSelector
  title: string
  isOpen: boolean
  onClose: () => void
}
export const TermModal: React.FC<TermModalFunction> = ({ title, isOpen, onClose, selector }) => {
  const { data, isLoading } = useTermQuery({ selector })
  const { regTime, text } = data || { regTime: '', text: '' }
  return (
    !isLoading && (
      <Modal
        width={1200}
        open={isOpen}
        closable={false}
        title={<div className={styles.modalTitle}>{title}</div>}
        footer={[
          <div className={styles.modalFooter}>
            <Button type="default" block size="large" onClick={onClose} key={'닫기'} style={{ width: 150 }}>
              {'닫기'}
            </Button>
          </div>,
        ]}
        className={styles.containTermModal}
      >
        <div className={styles.container}>
          <div className="dialog-body">
            <section className="wrap-section wrap-tbl wrap-terms">
              <div className="box-header">
                <div className="box-tit">
                  <h2 className="fz-20 fc-1 fw-bold">{title}</h2>
                  <h3 className="fz-12 fc-3">{regTime}</h3>
                </div>
              </div>
              <div className="box-body" id="bs">
                <div className="tbl">
                  <dl className="vertical">
                    <dd>
                      <div className="form-group">
                        <ul className="inner-content-terms">
                          <li>
                            <span className="fz-14">{ReactHtmlParser(text)}</span>
                          </li>
                        </ul>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Modal>
    )
  )
}
