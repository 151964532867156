import { Button } from 'antd';
import { ReactComponent as DownloadSvg } from './download.svg';
import { useDownloadFile } from './_queries/useDownloadFile';

type DownloadFunctionProps = {
  targetName: 'daily' | 'ad';
  params: string;
};
export const SalesReportDownload: React.FC<DownloadFunctionProps> = ({
  params,
  targetName,
}) => {
  const downloader = useDownloadFile();
  const doDownload = () => {
    downloader({
      index: targetName,
      params,
    });
  };
  return (
    <Button
      onClick={doDownload}
      icon={<DownloadSvg style={{ fill: '#495057', width: 18, height: 18 }} />}
    >
      {'매출 리포트 다운로드'}
    </Button>
  );
};
