import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {AgGridReact} from "ag-grid-react";
import NoDataTemplate from "../../../common/NoDataTemplate";
import Pagination from "../../../common/Pagination";
import { ApiUtil2 } from '@biz/api'
import {ColDef, GridSizeChangedEvent, ICellRendererParams} from "ag-grid-community";
import {useOutletContext} from "react-router-dom";
import {useForm} from "react-hook-form";
import alertify from 'alertifyjs'
import PostpaidMoneyManagementTab from "../PostpaidMoneyManagementTab";
import PomLimitRegDialog from "./PomLimitRegDialog";
import PomLimitManagementDialog from "./PomLimitManagementDialog";

interface IPLSearchForm {
    plReqEnum: string;
    query?: string;
}

interface IPL {
    data: any;
    regDate: string;
    registrant: string;
    updateDate: string;
    modifier: string;
    projectDiv: string;
    project2Id: string;
    pomLimitValue: number;
}
const PomLimitManagement = () => {
    const setTitle = useOutletContext<any>();
    const childRef = useRef<any>(null);
    const gridRef = useRef<AgGridReact>(null);
    const [rowData, setRowData] = useState<any[]>();
    const {
        register, handleSubmit, getValues, setValue,
    } = useForm<IPLSearchForm>();

    const onGridReady = useCallback(() => {
/*        if ($('#query').val() === '') {
            alertify.error('검색어를 입력해주세요.');
            return;
        }*/
        const data: IPLSearchForm = getValues();
        ApiUtil2.get<IPL>('/api/bzmAdmin/postpaidMoneyManagement/PL/list', {
            params: {
                data: {...data},
            },
        }).then((resp) => {
            setRowData(resp.data.data);
        });
    }, []);

    const onGridSizeChanged = (params: GridSizeChangedEvent) => {
        params.api.sizeColumnsToFit();
    };
    const changePagination = () => {
        childRef.current!.onPaginationChanged();
    };

    const projectDivRenderer = (props: ICellRendererParams) => {
        return props.data.projectDiv === 'STORE' ? '스토어' : '펀딩';
    }
    const costRenderer = (e: any) => (e.data.pomLimitValue === 0 || e.data.pomLimitValue === undefined ? '-' : e.data.pomLimitValue.toLocaleString());

    const [isManagementDialogOpened, setIsManagementDialogOpened] = useState(false);
    const [isPLRegDialogOpened, setIsPLRegDialogOpened] = useState(false);
    const [projectConfigId, setProjectConfigId] = useState(null);
    const managementRenderer = (props: ICellRendererParams) => {
        return <button onClick={() => {
            setIsManagementDialogOpened(true);
            setProjectConfigId(props.data.projectConfigId);
        }}>관리</button>;
    }

    const [columnDefs] = useState<ColDef[]>([
        {
            field: 'regDate',
            headerName: '최초 등록일',
            cellClass: 'ag-left-aligned-cell',
            width: 75
        },
        {
            field: 'registrant',
            headerName: '등록자',
            cellClass: 'ag-left-aligned-cell',
            width: 75
        },
        {
            field: 'updateDate',
            headerName: '최종 수정일',
            cellClass: 'ag-left-aligned-cell',
            width: 75
        },
        {
            field: 'modifier',
            headerName: '최종 수정자',
            cellClass: 'ag-left-aligned-cell',
            width: 75
        },
        {
            field: 'projectDiv',
            headerName: '프로젝트 유형',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
            width: 75,
            cellRenderer: projectDivRenderer
        },
        {
            field: 'project2Id',
            headerName: '프로젝트ID',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
            width: 75,
        },
        {
            field: 'pomLimitValue',
            headerName: '한도액(단위: 만원)',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-center-aligned-cell',
            width:50,
            cellRenderer: costRenderer,
        },
        {
            field: 'mangement',
            headerName: '관리',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-center-aligned-cell',
            width:50,
            cellRenderer: managementRenderer,
        }
    ]);

    const defaultColDef = useMemo<ColDef>(() => ({
        flex: 1,
        resizable: false,
        suppressMovable: true,
        sortable: true,
    }), []);

    useEffect(() => {
        setTitle('후불머니 관리');
        return () => {
            setTitle('');
        };
    }, []);

    useEffect(() => {
        // 검색 구분
        $('#srchType').select2({
            width: '200',
            placeholder: '선택',
            minimumResultsForSearch: Infinity,
        }).on('select2:select', (e) => {
            setValue('plReqEnum', e.params.data.id);
        });
    }, []);

    const AlRegDialogHandler = () => {
        setIsPLRegDialogOpened(true);
    }

    return (
        <Fragment>
            {isManagementDialogOpened ? <PomLimitManagementDialog setIsManagementDialogOpened={setIsManagementDialogOpened} projectConfigId={projectConfigId} onGridReady={onGridReady}/> : null}
            {isPLRegDialogOpened ? <PomLimitRegDialog setIsAlRegDialogOpened={setIsPLRegDialogOpened} onGridReady={onGridReady}/> : null}
            <PostpaidMoneyManagementTab/>
            <section className="wrap-section wrap-datagrid">
                <form onSubmit={handleSubmit(onGridReady)}>
                    <div className="wrap-filter">
                        <div className="inner-filter">
                            <div className="box-left">
                                <div className="item-filter">
                                    <div className="filter-tit">
                                        <p className="fz-12 fc-2">검색 구분</p>
                                    </div>
                                    <div className="box-filter">
                                        <select className="select2-single w-150"
                                                id="srchType" {...register('plReqEnum')}>
                                            <option value="PROJECT_ID" defaultChecked={true}>프로젝트ID</option>
                                            <option value="REGISTRANT">최초 등록자</option>
                                            <option value="MODIFIER">최종 수정지</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="item-filter">
                                    <div className="filter-tit">
                                        <p className="fz-12 fc-2">검색어</p>
                                    </div>
                                    <div className="box-filter">
                                        <div className="input-group comp-search">
                                            <div className="inner-input-group">
                                                <input type="text" className="tf-comm" id='query'
                                                       placeholder="검색어를 입력해 주세요." {...register('query')}></input>
                                                <i className="ico ico-search"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-right">
                                <button type="submit" className="btn btn-tertiary-mint btn-ico"><i
                                    className="ico ico-filter"></i>필터 조회
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="box-body">
                    <div className="ag-grid">
                        <div className="ag-grid-inner">
                            <AgGridReact
                                ref={gridRef}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                rowHeight={48}
                                pagination={true}
                                paginationPageSize={10}
                                suppressPaginationPanel={true}
                                onPaginationChanged={changePagination}
                                onGridSizeChanged={onGridSizeChanged}
                                domLayout={'autoHeight'}
                            />
                            { rowData === undefined ? <NoDataTemplate/> : null}
                        </div>
                    </div>
                </div>
                <div className="box-footer">
                    <Pagination gridRef={gridRef} ref={childRef}/>
                </div>
                <div className="box-footer">
                    <div className="box-tit"></div>
                    <div className="box-option">
                        <button type="button" className="btn btn btn-primary" onClick={AlRegDialogHandler}>신규 등록</button>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default PomLimitManagement;
