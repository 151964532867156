import { useMutation } from '@tanstack/react-query'
import { CategoryInspectionConfig, postCategory } from '@biz/api'

const mutationKey = ['@biz/query/category/useCreateCategoryMutation']

/**
 * 카테고리 저장
 */
export const useCreateCategoryMutation = () => {
  return useMutation(
    (formData: CategoryInspectionConfig[]) => {
      return postCategory(formData)
    },
    {
      mutationKey: mutationKey,
    }
  )
}
