import { useCallback } from 'react'
import { Form, FormInstance } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useCampaignMutation, useCampaignStatus, useCampaignInfoQuery } from '@biz/query'
import { CampaignCaption, targetFormRegistry } from '@biz/ui'
import { TitleCaption } from './TitleCaption'
import { BottomCaption } from './BottomCaption'
import { RangePicker } from './RangePicker'

const { CampaignRangeName } = targetFormRegistry
export type CampaignRangeType = { [CampaignRangeName]: Dayjs[] }
export const CampaignRange: React.FC = () => {
  const { data: campaignInfoData } = useCampaignInfoQuery()
  const { data: campaignInfo } = campaignInfoData || {}
  const { isBeforeInspection } = useCampaignStatus()
  const makeCampaign = useCampaignMutation()
  const form = Form.useFormInstance()
  const projectId = Form.useWatch<string, FormInstance<any>>(targetFormRegistry.ProjectSelectorName, form)
  const doChangeSelect = useCallback(
    () =>
      makeCampaign.mutateAsync({ ...form.getFieldsValue(), fieldName: 'campTime' }).then((isSuccess) => {
        if (!isSuccess) {
          form.setFieldValue(CampaignRangeName, [dayjs(campaignInfo?.campStartTime), dayjs(campaignInfo?.campEndTime)])
          form.validateFields()
        }
      }),
    [makeCampaign, form]
  )
  return (
    <div>
      <CampaignCaption>캠페인 기간</CampaignCaption>
      <dd style={{ paddingTop: 0, paddingBottom: 24 }}>
        <TitleCaption />
        <Form.Item
          name={CampaignRangeName}
          rules={[
            { required: true, message: '캠페인 기간을 입력해 주세요' },
            {
              validator(_, value) {
                const startDay = value?.[0]
                const endDay = value?.[1]

                if (!startDay || !endDay) {
                  return Promise.resolve()
                }

                if (isBeforeInspection() && startDay < dayjs().add(120, 'minute')) {
                  return Promise.reject(new Error('시작 시간은 현재 시간보다 120분 이상 지난 시간을 선택해 주세요.'))
                }
                if (endDay < startDay.add(36, 'hour')) {
                  return Promise.reject(
                    new Error(
                      `광고 진행 시간은 최소 36시간 이상으로 해주세요. (현재는 ${endDay.diff(startDay, 'hour')}시간, ${
                        endDay.diff(startDay, 'minute') % 60
                      }분 차이입니다.)`
                    )
                  )
                }
                return Promise.resolve()
              },
            },
          ]}
        >
          <RangePicker disabled={!projectId} onChange={doChangeSelect} />
        </Form.Item>
        <BottomCaption />
      </dd>
    </div>
  )
}
