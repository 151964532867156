import { useQuery, useQueryClient, type QueryClient } from '@tanstack/react-query'
import { fetchAvailableCreativeUpdate } from '@biz/api'
import { loadCurrentCampaignId, useCurrentCampaignIdQuery } from '@biz/query'

const rootQueryKey = '@apps/biz/TargetCreatePage/AvailableCreativeUpdate'
export const useAvailableCreativeUpdateQuery = () => {
  const { data: campaignId } = useCurrentCampaignIdQuery()
  return useQuery({
    queryKey: [rootQueryKey, campaignId || 0],
    queryFn: () => (!!campaignId ? fetchAvailableCreativeUpdate(campaignId) : Promise.reject()),
    enabled: Boolean(campaignId),
  })
}

export const loadAvailableCreativeUpdate = (queryClient: QueryClient) => async () => {
  const campaignId = await loadCurrentCampaignId(queryClient)()
  return await queryClient.fetchQuery({
    queryKey: [rootQueryKey, campaignId || 0],
    queryFn: () => (!!campaignId ? fetchAvailableCreativeUpdate(campaignId) : Promise.reject()),
  })
}

export const useAvailableCreativeUpdate = () => {
  const queryClient = useQueryClient()
  return loadAvailableCreativeUpdate(queryClient)
}
