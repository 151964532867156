import alertify from 'alertifyjs'
import { ApiUtil } from '@biz/api'

export const fileDownload = async (filePath: any, fileName: any) => {
  const response = await fetch(filePath)
  const file = await response.blob()
  ApiUtil.get('/api/common/isLogin').then((resp) => {
    if (resp.data.data === true) {
      const downloadUrl = window.URL.createObjectURL(file) // 해당 file을 가리키는 url 생성
      const anchorElement = document.createElement('a')
      document.body.appendChild(anchorElement)
      anchorElement.download = fileName as any // a tag에 download 속성을 줘서 클릭할 때 다운로드가 일어날 수 있도록 하기
      anchorElement.href = downloadUrl // href에 url 달아주기
      anchorElement.click() // 코드 상으로 클릭을 해줘서 다운로드를 트리거
      document.body.removeChild(anchorElement) // cleanup - 쓰임을 다한 a 태그 삭제
    } else {
      alertify.error('와디즈 비즈센터 회원만 다운로드 가능합니다. ')
    }
  })
}
