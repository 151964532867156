import { axios } from '../axios'

export type PaymentInfoType = {
  campBudgetTotal1: number // 300000
  campBudgetTotal2: number //366300
  campBudgetTotalExp: number //333000
  campBudgetTotalVat: number //33300
  campBuff: number //3000
  campEndTime: string // '202410240000'
  campId: number //299362390
  campPeriod: number //3
  campStartTime: string // '202410220000'
  project2Id: string // 'F26767'
  wadizFee: number //30000
}

type ReturnType = {
  ok: boolean
  data: PaymentInfoType
}

type FunctionType = (campId: string | number, payType: 'BIZ_MONEY' | 'POST_PAYMENT') => Promise<ReturnType>
export const fetchPayTypeCampaignInfo: FunctionType = (campId, payType) =>
  axios.get(`/api/camp/make02/getCampInfo2?campId=${campId}&payType=${payType}`)
