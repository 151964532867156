import { Form } from 'antd'
import { useProjectListQuery } from '@biz/query'
import { targetFormRegistry } from '@biz/ui'
import { ProjectBrief } from './ProjectBrief'

const { ProjectSelectorName } = targetFormRegistry
export const ProjectBriefItem: React.FC = () => {
  const { data: projectList } = useProjectListQuery()
  return (
    <Form.Item dependencies={[ProjectSelectorName, 'projectFundingDiv']} style={{ paddingBottom: 8, marginBottom: 0 }}>
      {({ getFieldValue }) => {
        const selectedProjectName = getFieldValue(ProjectSelectorName)
        const fundingType = getFieldValue('projectFundingDiv')
        const project = projectList?.find((n) => n.id === selectedProjectName)
        return !!project && <ProjectBrief fundingType={fundingType} project={project} />
      }}
    </Form.Item>
  )
}
