import React, {
  Fragment, useRef,
} from 'react';
import { Dialog } from 'rc-easyui';
import dayjs from 'dayjs';
import alertify from 'alertifyjs'

const CampProjectSelectDialogForConsulting = (props:any) => {
  const dialogRef = useRef<any>();
  const defaultToday = dayjs().format('YYYYMMDD');
  const campTodayPeriodIngCheck = Number(dayjs(props.projectAdPossEndDate).diff(dayjs(defaultToday), 'day', false) + Number(1));
  const campTodayPeriodOpenCheck = Number(dayjs(props.projectRcsEndDate).diff(dayjs(defaultToday), 'day', false) + Number(1));
  const campPeriodCheckIng = Number(dayjs(props.projectAdPossEndDate).diff(dayjs(props.projectAdPossStartDate), 'day', false) + Number(1));
  const campPeriodCheckOpen = Number(dayjs(props.projectRcsEndDate).diff(dayjs(props.projectRcsStartDate), 'day', false) + Number(1));
  const fundingOpen = () => {
    if (props.projectRcsEndDate !== undefined && props.projectRcsEndDate !== 'undefined') {
      if (campTodayPeriodOpenCheck < 1 || campPeriodCheckOpen < 3) {
        alertify.error('해당 프로젝트를 선택할 수 없습니다.');
        return;
      }
    }
    props.setValue('projectFundingDiv','FUNDING_OPEN')
    props.setWadizFundingText(`[오픈 예정] ${props.projectNm}`);
    props.setProjectSelectDialog(false);
  };

  const fundingIng = () => {
    if (props.projectAdPossEndDate !== undefined && props.projectAdPossEndDate !== 'undefined') {
      if (campTodayPeriodIngCheck < 1 || campPeriodCheckIng < 3) {
        alertify.error('해당 프로젝트를 선택할 수 없습니다.');
        return;
      }
    }
    props.setValue('projectFundingDiv','FUNDING_ING')
    props.setWadizFundingText(`[펀딩 진행중] ${props.projectNm}`);
    props.setProjectSelectDialog(false);
  };
  return (
        <Fragment>
            <form>
                <Dialog modal resizable shadow={false} ref={dialogRef} style={{ width: '400px' }}>
                    <div className="dialog">
                        <div className="dialog-body">
                            <div className="tbl">
                                <dl className="vertical">
                                    <dd>
                                        <div className="form-group">
                                            <p className="comp-txt">
                                                <span className="table">
                                                    <span className="table-cell">
                                                        <b className="fz-14 fc-4">해당 프로젝트는 펀딩 프로젝트입니다.<br/>광고를 진행할 펀딩 상태를 선택해주세요.<br/><br/></b>
                                                    </span>
                                                </span>
                                            </p>
                                        </div>
                                    </dd>
                                </dl>

                            </div>
                        </div>
                        <div className="dialog-footer">
                            <button type="button" className="btn btn-secondary-outline" onClick={fundingOpen}>오픈 예정</button>
                            <button type="button" className="btn btn-primary" onClick={fundingIng}>펀딩 진행중</button>
                        </div>
                    </div>
                </Dialog>
            </form>
        </Fragment>

  );
};
export default CampProjectSelectDialogForConsulting;
