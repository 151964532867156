import { useCallback } from 'react'
import { InputNumber, Button } from 'antd'
import { thousandsGrouping } from '@common/utils'
import styles from './ChargeAmountInput.module.scss'

type ChargeAmountInputFunction = {
  value: number
  onChange: (num: number) => void
}

const moneyList = [10000, 30000, 100000, 300000, 1000000]
export const ChargeAmountInput: React.FC<ChargeAmountInputFunction> = ({ value, onChange }) => {
  const addChange = (num: number) => {
    onChange(value + num)
  }
  const formatter = useCallback(
    (value: number | undefined) => `${value || 10000}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '      ',
    []
  )
  const parser = useCallback((value: string | undefined) => Number((value || '10000 ').replace(/[^\d]+/g, '')), [])
  return (
    <div style={{ position: 'relative' }}>
      <div className={styles.title}>{'충전 금액'}</div>
      <InputNumber
        autoFocus
        step={10000}
        controls={false}
        min={10000}
        formatter={formatter}
        parser={parser}
        className={styles.inputBox}
        value={value}
        onChange={(num) => onChange(num || 10000)}
        size="large"
      />
      <div className={styles.won}>{'원'}</div>
      <div className={styles.appendMoneyList}>
        {moneyList.map((m) => (
          <Button key={m} type="primary" onClick={() => addChange(m)}>
            {`+${thousandsGrouping(m)}원`}
          </Button>
        ))}
      </div>
    </div>
  )
}
