import type { MakerInfoType } from './fetchLoginCheckMakerLoginInfo'

//  apps/biz-center/src/pages/AdminLoginPage/Login.tsx 여기 인터페이스를 가져옴 여러 형태의 데이터를 넣게 되어 있으면 복잡할 텐데...
type LoginFormType = {
  data: any
  memberId: string
  password: string
}

export const loginLocalStorage = {
  setUserInfo(userInfo: MakerInfoType | LoginFormType) {
    localStorage.setItem('user', JSON.stringify(userInfo))
  },
  getUserInfo() {
    return JSON.parse(localStorage.getItem('user') || '{}')
  },
  isExistUser() {
    return !!localStorage.getItem('user')
  },
  clear() {
    localStorage.removeItem('user')
    localStorage.removeItem('shadow')
    localStorage.removeItem('shadowRole')
  },

  getShadowInfo() {
    return {
      shadowInfo: localStorage.getItem('shadow'),
      shadowRoleInfo: localStorage.getItem('shadowRole'),
    }
  },
}
