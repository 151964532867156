import { Modal, Button } from 'antd'
import styles from './AIInspectionReconfirmationModal.module.scss'

type AIInspectionReconfirmationModalFunction = {
  isOpen: boolean
  onCancel: () => void
  onNextProcess: () => void
}
export const AIInspectionReconfirmationModal: React.FC<AIInspectionReconfirmationModalFunction> = ({
  isOpen,
  onCancel,
  onNextProcess,
}) => {
  return (
    <Modal
      width={410}
      open={isOpen}
      closable={false}
      centered
      title={
        <div className={styles.modalTitle}>
          <h3>잠깐만요! 메이커님께서 요청하신 캠페인은 AI 자동 검수 대상입니다.</h3>
        </div>
      }
      footer={[
        <div className={styles.modalFooter} key={'footer'}>
          <Button type="default" block size="large" onClick={onCancel} style={{ width: 200 }}>
            {'취소'}
          </Button>
          <Button type="primary" block size="large" onClick={onNextProcess} style={{ width: 200 }}>
            {'진행요청'}
          </Button>
        </div>,
      ]}
      className={styles.containerAIInspectionReconfirmationModal}
    >
      <div className={styles.modalContent}>
        AI 심의가 <b>승인</b>될 경우 60분 이내로 Meta에 등록되어 수정이 어려워집니다. 그대로 진행하시겠어요?
      </div>
    </Modal>
  )
}
