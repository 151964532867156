import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { DataFormat } from 'select2'
import SingleSelect2 from '../accAdjust/SingleSelect2'
import { AgGridReact } from 'ag-grid-react'
import NoDataTemplate from '../common/NoDataTemplate'
import Pagination from '../common/Pagination'
import { useOutletContext } from 'react-router'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { ApiUtil2 } from '@biz/api'
import _, { isEmpty } from 'lodash'
import { useForm } from 'react-hook-form'
import MdSyncMonitoringMemoDialog from './MdSyncMonitoringMemoDialog'
import DlDialog from '../mngMakerAccount/DlDialog'

interface MdMonitoringSearchForm {
  startDate: string
  endDate: string
  campErrDiv?: string
  searchType: string
  searchTxt?: string
}

const MdSyncMonitoring = () => {
  const setTitle = useOutletContext<any>()

  const childRef = useRef<any>(null)
  const gridRef = useRef<AgGridReact>(null)
  const [rowData, setRowData] = useState<[]>([])
  const [showMemoDialog, setShowMemoDialog] = useState<boolean>(false)
  const [selectedLog, setSelectedLog] = useState<any>(null)

  const [isDlDialogOpened, setIsDlDialogOpened] = useState<boolean>(false)
  const [dlParam, setDlParam] = useState<any>(null)

  const { register, setValue, handleSubmit, getValues } = useForm<MdMonitoringSearchForm>()

  const snsCampRenderer = (props: ICellRendererParams) => {
    const { value } = props
    const { url, campErrDiv } = props.data

    return _.isEmpty(url) || _.isEmpty(value) || campErrDiv != '캠페인 등록 오류' ? (
      value
    ) : (
      <a className="txt-link" href={url} target="_blank">
        {value}
      </a>
    )
  }

  const snsAdRenderer = (props: ICellRendererParams) => {
    const { value } = props
    const { url, campErrDiv } = props.data

    return _.isEmpty(url) || _.isEmpty(value) || campErrDiv != '소재 등록 오류' ? (
      value
    ) : (
      <a className="txt-link" href={url} target="_blank">
        {value}
      </a>
    )
  }

  const memoRenderer = (props: ICellRendererParams) => {
    const memo = props.value
    const str = _.isEmpty(memo) ? '메모 없음' : memo.length > 30 ? memo.substring(0, 30) + '...' : memo

    return (
      <a
        className="txt-link"
        onClick={() => {
          setShowMemoDialog(true)
          setSelectedLog(props.data)
        }}
      >
        {str}
      </a>
    )
  }

  const dateFormatRenderer = (props: ICellRendererParams) => {
    if (typeof props.value == 'undefined' || isEmpty(props.value)) return '-'
    const { date } = props.value
    const { time } = props.value

    return typeof props.value == 'undefined' || isEmpty(props.value)
      ? '-'
      : dayjs(new Date(date.year, date.month - 1, date.day, time.hour, time.minute, time.second)).format(
          'YYYY-MM-DD HH:mm:ss'
        )
  }

  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'logTime',
      headerName: '일시',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: dateFormatRenderer,
    },
    {
      field: 'campErrDiv',
      headerName: '구분',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'memberName',
      headerName: '메이커명',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'campNm',
      headerName: '비즈센터 캠페인 명',
      cellClass: 'ag-center-aligned-cell',
    },
    { field: 'campId', headerName: '비즈센터 캠페인 ID', cellClass: 'ag-center-aligned-cell' },
    { field: 'creationLinkId', headerName: '비즈센터 소재 ID', cellClass: 'ag-center-aligned-cell' },
    {
      field: 'snsCampId',
      headerName: '메타 캠페인 ID',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: snsCampRenderer,
    },
    { field: 'snsAdId', headerName: '메타 소재 ID', cellClass: 'ag-center-aligned-cell', cellRenderer: snsAdRenderer },
    {
      field: 'campErrDesc',
      headerName: '상세 내용',
      wrapText: true,
      autoHeight: true,
      cellStyle: { whiteSpace: 'pre-line' },
    },
    {
      field: 'memo',
      headerName: '메모',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: memoRenderer,
      wrapText: true,
      autoHeight: true,
      cellStyle: { whiteSpace: 'pre-line' },
    },
  ])

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      resizable: true,
      suppressMovable: true,
    }),
    []
  )

  const changePagination = () => {
    childRef.current!.onPaginationChanged()
  }

  const onGridReady = () => {
    const data: MdMonitoringSearchForm = getValues()
    data.searchTxt = data.searchTxt == undefined ? '' : encodeURIComponent(data.searchTxt)
    if (data.campErrDiv == 'ALL') data.campErrDiv = ''

    ApiUtil2.get('/api/mngMonitoring/mdSyncMonitoring/list', {
      params: {
        ...data,
      },
    }).then((resp) => {
      setRowData(resp.data.data)
    })
  }

  const onBtnExport = useCallback(() => {
    const data: MdMonitoringSearchForm = getValues()

    setDlParam({
      ...data,
    })

    setIsDlDialogOpened(true)
    // window.location.assign(`/api/mngMonitoring/mdSyncMonitoring/download?startDate=${data.startDate}&endDate=${data.endDate}&searchType=${data.searchType}&searchTxt=${data.searchTxt}&campErrDiv=${data.campErrDiv}`);
  }, [])

  const datepicker = useRef<any>()
  const [datepickerSpan, setDatepickerSpan] = useState('사용자 설정')
  const daterangepickerDefaultOption = {
    startDate: dayjs().startOf('day').subtract(6, 'd'),
    endDate: dayjs().endOf('day'),
    opens: 'left',
    ranges: {
      '오늘 ': [dayjs().startOf('day'), dayjs().endOf('day')],
      '어제 ': [dayjs().startOf('day').subtract(1, 'days'), dayjs().endOf('day').subtract(1, 'days')],
      '이번 주': [dayjs().startOf('day').day(0), dayjs().day(1).endOf('day').subtract(-7, 'd').subtract(59, 'm')],
      '저번 주': [
        dayjs().startOf('day').subtract(1, 'week').day(0),
        dayjs().endOf('day').subtract(1, 'week').day(6).subtract(59, 'm'),
      ],
      '최근 7일': [dayjs().startOf('day').subtract(7, 'd'), dayjs().endOf('day').subtract(1, 'd')],
      '최근 30일': [dayjs().startOf('day').subtract(30, 'd'), dayjs().endOf('day').subtract(1, 'd')],
    },
  }

  const campErrDivs: DataFormat[] = [
    { id: 'ALL', text: '전체', selected: true },
    { id: 'camp_sync_err', text: '캠페인 등록 오류' },
    { id: 'ad_sync_err', text: '소재 등록 오류' },
    { id: 'camp_status_err', text: '캠페인 상태 이상' },
    { id: 'ad_status_err', text: '소재 상태 이상' },
    { id: 'budget_err', text: '예산 설정 이상' },
  ]
  const searchTypes: DataFormat[] = [
    { id: 'memberName', text: '메이커명', selected: true },
    { id: 'campNm', text: '비즈센터 캠페인 명' },
    { id: 'campId', text: '비즈센터 캠페인 ID' },
    { id: 'creationLinkId', text: '비즈센터 소재 ID' },
    { id: 'snsCampId', text: '메타 캠페인 ID' },
    { id: 'snsAdId', text: '메타 소재 ID' },
  ]

  const datePickerPrevEvent = () => {
    $(datepicker.current).data('daterangepicker').subtractInterval()
    const startDate = $(datepicker.current).data('daterangepicker').startDate.format('YYYYMMDD')
    const endDate = $(datepicker.current).data('daterangepicker').endDate.format('YYYYMMDD')
    setValue('startDate', startDate)
    setValue('endDate', endDate)
  }
  const datePickerNextEvent = () => {
    $(datepicker.current).data('daterangepicker').addInterval()
    const startDate = $(datepicker.current).data('daterangepicker').startDate.format('YYYYMMDD')
    const endDate = $(datepicker.current).data('daterangepicker').endDate.format('YYYYMMDD')
    setValue('startDate', startDate)
    setValue('endDate', endDate)
  }
  useEffect(() => {
    setTitle('매체 연동 모니터링')

    const option = {
      ...daterangepickerDefaultOption,
    }

    $(datepicker.current).daterangepicker(option, (start: any, end: any) => {
      setDatepickerSpan($(datepicker.current).data('daterangepicker').chosenLabel)
      setValue('startDate', start.format('YYYYMMDD'))
      setValue('endDate', end.format('YYYYMMDD'))
    })

    const startDate = $(datepicker.current).data('daterangepicker').startDate.format('YYYYMMDD')
    const endDate = $(datepicker.current).data('daterangepicker').endDate.format('YYYYMMDD')
    setValue('startDate', startDate)
    setValue('endDate', endDate)

    setValue('campErrDiv', '')
    setValue('searchType', 'memberName')
  }, [])
  return (
    <Fragment>
      <section className="wrap-section wrap-datagrid">
        <div className="wrap-filter">
          <form onSubmit={handleSubmit(onGridReady)}>
            <div className="inner-filter">
              <div className="box-left">
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">조회기간</p>
                  </div>
                  <div className="box-filter">
                    <div className="comp-datepicker">
                      <div className="inner-datepicker">
                        <i className="ico ico-calendar"></i>
                        <input type="text" className="tf-comm datepicker-range" readOnly ref={datepicker} />
                        <span className="fz-12 fc-2 daterangepicker_span">{datepickerSpan}</span>
                      </div>
                      <button type="button" className="btn btn-prev" onClick={datePickerPrevEvent}></button>
                      <button type="button" className="btn btn-next" onClick={datePickerNextEvent}></button>
                    </div>
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">구분</p>
                  </div>
                  <div className="box-filter">
                    <SingleSelect2
                      data={campErrDivs}
                      selectEvent={(value: any) => setValue('campErrDiv', value == 'ALL' ? '' : value)}
                      clss={'w-200'}
                      option={{ width: '200' }}
                    />
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">검색대상</p>
                  </div>
                  <div className="box-filter">
                    <SingleSelect2
                      data={searchTypes}
                      selectEvent={(value: any) => {
                        setValue('searchType', value)
                      }}
                      clss={'w-200'}
                      option={{ width: '200' }}
                    />
                  </div>
                </div>
                <div className="item-filter">
                  <div className="box-filter">
                    <div className="input-group comp-search">
                      <div className="inner-input-group">
                        <input
                          type="text"
                          className="tf-comm"
                          placeholder="검색어를 입력해 주세요."
                          maxLength={100}
                          {...register('searchTxt')}
                        />
                        <i className="ico ico-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-right">
                <button type="submit" className="btn btn-tertiary-mint btn-ico">
                  <i className="ico ico-filter"></i>검색
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="box-header">
          <div className="box-option">
            <button type="button" className="btn btn btn-secondary-outline btn-ico" onClick={onBtnExport}>
              <i className="ico ico-download"></i>다운로드
            </button>
          </div>
        </div>
        <div className="box-body">
          <div className="ag-grid">
            <div className="ag-grid-inner">
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                // onGridReady={onGridReady}
                rowHeight={48}
                pagination={true}
                paginationPageSize={10}
                suppressPaginationPanel={true}
                suppressRowClickSelection={true}
                onPaginationChanged={changePagination}
                domLayout={'autoHeight'}
                noRowsOverlayComponent={NoDataTemplate}
              />
            </div>
          </div>
        </div>
        <div className="box-footer">
          <Pagination gridRef={gridRef} ref={childRef} />
        </div>
      </section>
      {showMemoDialog ? (
        <MdSyncMonitoringMemoDialog
          setShowMemoDialog={setShowMemoDialog}
          selectedLog={selectedLog}
          // onGridReady={onGridReady}
        />
      ) : null}
      {isDlDialogOpened ? (
        <DlDialog
          setIsDlDialogOpened={setIsDlDialogOpened}
          dlUrl={'/api/mngMonitoring/mdSyncMonitoring/download'}
          dlParam={dlParam}
        />
      ) : null}
    </Fragment>
  )
}

export default MdSyncMonitoring
