import { Query, useQuery, useQueryClient, type QueryClient } from '@tanstack/react-query'
import { fetchCreativesManageData, CreativeDataType } from '@biz/api'
import { useCurrentCampaignIdQuery, loadCurrentCampaignId } from '../useCurrentCampaignIdQuery'
import type { ImageMetaDataType } from '../useImageListQuery'

// biz/api에서 선언된 타입을 apps로 올리지 않기 위해서 다시 선언함.
// 특히 ImageData
export type CreativeType = CreativeDataType & { afList?: ImageMetaDataType[] }
const masterQueryKey = '@apps/biz-center/CreativeAssetsTab/CreativeData'

export const silentUpdateCreativeData = async (queryClient: QueryClient) => {
  const campaignId = await loadCurrentCampaignId(queryClient)()
  if (!!campaignId) {
    const res = await fetchCreativesManageData(campaignId)
    const assets = res.data.map((n) => ({
      creationViewStatus: n.creationViewStatus,
      syncLastTime: n.syncLastTime,
      ...n.creationData,
    }))
    queryClient.setQueryData([masterQueryKey, campaignId], assets)
    return assets
  }
}

export const useCreativeDataQuery = () => {
  const queryClient = useQueryClient()
  const { data: campaignId } = useCurrentCampaignIdQuery()
  return {
    ...useQuery({
      queryKey: [masterQueryKey, campaignId],
      queryFn: () =>
        !!campaignId
          ? fetchCreativesManageData(campaignId).then((res) =>
              res.data.map((n) => ({
                creationViewStatus: n.creationViewStatus,
                syncLastTime: n.syncLastTime,
                ...n.creationData,
              }))
            )
          : Promise.resolve([]),
      enabled: Boolean(campaignId),
      refetchOnMount: true, // QueryClient 의 기본 설정이 false로 되어있고, 해당 Hook은 mount마다 refetch 해야하는 query 이므로 true로 설정
    }),
    update: () => silentUpdateCreativeData(queryClient),
  }
}
