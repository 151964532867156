import { Outlet } from 'react-router-dom'
import { Layout } from 'antd'
import { Header, Footer, LeftMenu } from '../layout'
import styles from './DefaultLayout.module.scss'

export const DefaultLayout: React.FC = () => (
  <Layout className={styles.DefaultLayout}>
    <Layout.Header className={styles.header}>
      <Header />
    </Layout.Header>
    <Layout>
      <Layout.Sider width={240}>
        <LeftMenu />
      </Layout.Sider>
      <Layout>
        <Layout.Content className={styles.content}>
          <Outlet />
        </Layout.Content>
        <Layout.Footer className={styles.footer}>
          <Footer />
        </Layout.Footer>
      </Layout>
    </Layout>
  </Layout>
)

