import React, {
  Fragment, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { ApiUtil2 } from '@biz/api'
import NoDataTemplate from '../common/NoDataTemplate';
import alertify from 'alertifyjs'
import MngMakerAccountLogTooltip from './MngMakerAccountLogTooltip';

export interface IWadizUser {
    userId:number
    userName: string
    userStatus: string
    nickName: string
    mobile:string
}

const MngMakerAccountDialog = (parentProps :any) => {
  const addedMakerIds:IWadizUser[] = [];
  const [dialogData, setDialogData] = useState<IWadizUser[]>();
  const gridRefOfSrchedMakers = useRef<AgGridReact>(null);
  const gridRefOfSelectedMakers = useRef<AgGridReact>(null);
  const [flag, setFlag] = useState<boolean>(false);

  const errorEvent = (msg: string) => {
    alertify.error(msg, 1);
  };

  const confirmError = (msg: string) => {
    errorEvent(msg);
  };

  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: true,
    tooltipComponent: MngMakerAccountLogTooltip,
  }), []);

  const selectedColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: true,
    tooltipComponent: MngMakerAccountLogTooltip,
  }), []);

  /** 100자 초과되는지 */
  const textCheck = (event:any) => {
    const text = event.target.value;
    if (event.target.value.length >= 100) text.slice(0, -2);
  };

  const onApiReady = useCallback(() => {
    if ($('#dialogKeyword').val() === undefined || $('#dialogKeyword').val() === '') {
      confirmError('조회하실 메이커ID나 메이커명을 입력해주세요.');
      return;
    }
    ApiUtil2.get('/api/mngAccount/maker/srchMaker', {
      params: {
        data: {
          keyword: $('#dialogKeyword').val(),
        },
      },
    }).then((resp) => {
      setDialogData(resp.data.data);
    });
  }, []);

  const addBtnRenderer = (e:any) => {
    let addDisabled = false;
    addedMakerIds.forEach((disabled:any) => {
      if (`${e.data.wadizUserId}` === `${disabled.userId}`) {
        addDisabled = true;
      }
    });
    return <button className='btn btn-primary xsmall' id={`add-${e.data.wadizUserId}`} data-id={e.data.wadizUserId} data-makernm={e.data.memberName} data-makerstatus={e.data.makerStatus} data-loginid={e.data.loginId} data-hp={e.data.hp} disabled={addDisabled}>추가</button>;
  };
  const delBtnRenderer = (e:any) => <button className='btn btn-primary xsmall' id={`del-${e.data.userId}`} data-id={e.data.userId}>삭제</button>;

  const [columnDialogDefs] = useState<ColDef[]>([
    {
      field: 'loginId',
      tooltipField: 'loginId',
      headerName: '메이커ID',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
    },
    {
      field: 'memberName',
      headerName: '메이커명',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
    },
    {
      field: '구분',
      headerName: '',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: addBtnRenderer,
    },
  ]);

  const [columnCheckedDialogDefs] = useState<ColDef[]>([
    {
      field: 'userName',
      tooltipField: 'userName',
      headerName: '메이커ID',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
    },
    {
      field: 'nickName',
      headerName: '메이커명',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
    },
    {
      field: '구분',
      headerName: '',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: delBtnRenderer,
    },
  ]);

  useEffect(() => {
    const noDataGrid = (document.querySelector<HTMLElement>('.ag-root-wrapper-body.ag-focus-managed.ag-layout-auto-height')! as any);
    const noTreeDataGrid2 = (document.querySelector<HTMLElement>('.ag-root-wrapper.ag-ltr.ag-layout-auto-height')! as any);
    const noDataTempleteArea = (document.querySelector<HTMLElement>('.ag-overlay-wrapper.ag-layout-auto-height.ag-overlay-no-rows-wrapper')! as any);
    if (noDataGrid !== null) {
      noDataGrid.style.height = '548px';
    }
    if (noTreeDataGrid2 !== null) {
      noTreeDataGrid2.style.height = '548px';
    }
    if (noDataTempleteArea !== null) {
      noDataTempleteArea.style.height = '548px';
    }
  }, []);

  useEffect(() => {
    /** 다이얼로그 오픈 */
    $('#makerSelectDialog').dialog({ width: 1200, title: '와디즈 비즈센터 미연동 계정 조회', closeOnEscape: true });
    $('#makerSelectDialog').dialog('open');
  }, []);

  useEffect(() => {
    /** 조회버튼 */
    $(document).on('click', '#dialogSrchBtn', () => {
      onApiReady();
    });

    /** 엔터키 이벤트 */
    $(document).on('keyup', '#dialogKeyword', (e:any) => {
      if (e.keyCode === 13) {
        onApiReady();
        return false;
      }
      return true;
    });

    /** 연동하기 */
    $(document).on('click', '#makerSelectDialogBtn', () => {
      if (addedMakerIds.length !== 0) {
        ApiUtil2.post('/api/mngAccount/maker/createMaker', addedMakerIds).then((resp) => {
          if (resp.data.data) {
            $('#makerSelectDialog').dialog('close');
            parentProps.setIsMakerSelectDialogOpened(false);
            parentProps.onGridReady();
          }
        });
      } else {
        confirmError('연동하실 계정을 선택해주세요.');
      }
    });

    /** 추가버튼 */
    $(document).on('click', '[id^=add-]', (e:any) => {
      const userId = e.currentTarget.dataset.id;
      const nickName = e.currentTarget.dataset.makernm;
      const userName = e.currentTarget.dataset.loginid;
      const userStatus = e.currentTarget.dataset.makerstatus;
      const mobile = e.currentTarget.dataset.hp;

      if (gridRefOfSelectedMakers.current !== null) {
        addedMakerIds.push({
          userName, userStatus, mobile, userId, nickName,
        });
        setFlag(true);
        $(`#add-${userId}`).prop('disabled', true);
        gridRefOfSelectedMakers.current!.api.setRowData(addedMakerIds);
        $('#selectedCnt').text(`${addedMakerIds.length}`);
      }
    });

    /** 삭제버튼 */
    $(document).on('click', '[id^=del-]', (e:any) => {
      const { id } = e.currentTarget.dataset;
      addedMakerIds.splice(0);
      if (gridRefOfSelectedMakers.current !== null) {
                gridRefOfSelectedMakers.current!.api.forEachNode((node) => {
                  const { data } = node;
                  if (data.userId !== id) addedMakerIds.push(data);
                });
                gridRefOfSelectedMakers.current!.api.setRowData(addedMakerIds);
                $(`#add-${id}`).prop('disabled', false);
                $('#selectedCnt').text(`${addedMakerIds.length}`);
                if (addedMakerIds.length === 0) {
                  setFlag(false);
                }
      }
    });
  }, []);

  useEffect(() => {
    /** 다이얼로그 닫기 */
    $(document).on('click', '#close2', () => {
      $('#makerSelectDialog').dialog('close');
      parentProps.setIsMakerSelectDialogOpened(false);
    });
    return () => {
      parentProps.setIsMakerSelectDialogOpened(false);
      $('#makerSelectDialog').dialog('destroy');
    };
  }, []);

  return (
        <Fragment>
            <form>
                <div id="makerSelectDialog" className="dialog" style={{ display: 'none' }}>
                    <div className="dialog-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col col-6">
                                    <div className="wrap-section wrap-datagrid">
                                        <div className="box-header">
                                            <div className="box-tit expand">
                                                <div className="input-group comp-search expand">
                                                    <div className="inner-input-group">
                                                        <input id="dialogKeyword" type="text" className="tf-comm"
                                                               placeholder="조회하실 메이커ID나 메이커명을 입력해 주세요." onChange={textCheck}></input>
                                                        <i className="ico ico-search"></i>
                                                    </div>
                                                </div>
                                                <button id="dialogSrchBtn" type="button" className="btn btn-primary w-100">조회</button>
                                            </div>
                                        </div>
                                        <div className="box-body">
                                            <div className="ag-grid">
                                                <div className="ag-grid-inner">
                                                    <AgGridReact
                                                        ref={gridRefOfSrchedMakers}
                                                        rowData={dialogData}
                                                        columnDefs={columnDialogDefs}
                                                        defaultColDef={defaultColDef}
                                                        tooltipShowDelay={0}
                                                        tooltipHideDelay={5000}
                                                        rowHeight={48}
                                                        domLayout={'autoHeight'}
                                                        noRowsOverlayComponent={NoDataTemplate}
                                                    />
                                                    { gridRefOfSrchedMakers.current! !== null &&  gridRefOfSrchedMakers.current!.props.rowData !==  undefined ?  null : dialogData !== undefined  ? null :  <NoDataTemplate/>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-6">
                                    <div className="wrap-section wrap-datagrid">
                                        <div className="box-header">
                                            <div className="box-tit">
                                                <h2 className="fz-18 fc-1">선택 대상</h2>
                                                <h3 className="fz-12 fc-3"><span className="fc-5" id='selectedCnt'>{addedMakerIds.length === undefined ? 0 : addedMakerIds.length}</span>개 계정 선택</h3>
                                            </div>
                                        </div>
                                        <div className="box-body">
                                            <div className="ag-grid">
                                                <div className="ag-grid-inner">
                                                    <AgGridReact
                                                        ref={gridRefOfSelectedMakers}
                                                        columnDefs={columnCheckedDialogDefs}
                                                        defaultColDef={selectedColDef}
                                                        tooltipShowDelay={0}
                                                        tooltipHideDelay={5000}
                                                        rowHeight={48}
                                                        domLayout={'autoHeight'}
                                                         noRowsOverlayComponent={NoDataTemplate}
                                                        />
                                                        { !flag ? <NoDataTemplate/> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dialog-footer">
                        <button id="close2" type="button" className="btn btn-secondary-outline large">취소</button>
                        <button type="button" className="btn btn-primary large" id="makerSelectDialogBtn">연동</button>
                    </div>
                </div>
            </form>
        </Fragment>
  );
};

export default MngMakerAccountDialog;
