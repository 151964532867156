import { axios } from '../axios'

export type PaymentData = Partial<{
  campId: number // 299817330
  payType: 'BIZ_MONEY' | 'POST_PAYMENT'
  project2Id: string // 'F26767'
}>
export type PaymentResult =
  | {
      intervalValid: boolean
      resultMsg: string
    }
  | {
      errors: { convertedMsg: string; field: string }[]
      msg: string
      title: string
    }
type ResponseType = {
  ok: boolean
  data: PaymentResult
}
type FunctionType = (data: PaymentData) => Promise<ResponseType>
export const fetchPostPayment: FunctionType = (data) => axios.post('/api/camp/make02/inspReq', data)
