import { useState, useCallback } from 'react'
import { Checkbox, Form } from 'antd'
import { CampaignCaption, targetFormRegistry, UpdateConfirmModal } from '@biz/ui'
import { useCampaignStatus } from '@biz/query'
import { useUpdateCampaign } from '../_hooks/useUpdateCampaign'
import styles from './GenderSelector.module.scss'

type GenderSelectorImplProps = {
  value?: string[]
  onChange?: (v: string[]) => void
}
const GenderSelectorImpl: React.FC<GenderSelectorImplProps> = ({ value, onChange }) => {
  const { isFinished, isAdmin, inOperation } = useCampaignStatus()

  const [changeConfirmData, setChangeConfirmData] = useState<{
    oldContent: string
    newContent: string
    updateCallback: () => void
  } | null>(null)

  const doChange = useCallback(
    (v: string[]) => {
      const genderList = v.length !== 0 ? v : ['MALE', 'FEMALE']
      if (!inOperation?.()) {
        onChange?.(genderList)
        return
      }
      setChangeConfirmData({
        oldContent: `성별: ${value?.map((n) => (n === 'MALE' ? '남' : '여')).join('/')}`,
        newContent: `성별: ${genderList.map((n) => (n === 'MALE' ? '남' : '여')).join('/')}`,
        updateCallback: () => onChange?.(genderList),
      })
    },
    [onChange, value]
  )
  return (
    <>
      <Checkbox.Group
        className={styles.GenderSelector}
        options={[
          { label: '남성', value: 'MALE' },
          { label: '여성', value: 'FEMALE' },
        ]}
        value={value}
        onChange={doChange}
        disabled={isFinished?.() || isAdmin?.()}
      />
      <UpdateConfirmModal
        isOpen={changeConfirmData !== null}
        title={'캠페인타겟을 수정 하시겠습니까?'}
        onClose={() => setChangeConfirmData(null)}
        onConfirm={() => {
          changeConfirmData?.updateCallback()
          setChangeConfirmData(null)
        }}
        oldContent={changeConfirmData?.oldContent || ''}
        newContent={changeConfirmData?.newContent || ''}
      />
    </>
  )
}

export const GenderSelector: React.FC = () => {
  const doUpdate = useUpdateCampaign()
  return (
    <div>
      <CampaignCaption>{'성별'}</CampaignCaption>
      <Form.Item name={targetFormRegistry.GenderSelectorName} required>
        <GenderSelectorImpl onChange={doUpdate} />
      </Form.Item>
    </div>
  )
}
