import { axios } from '../axios'

export type ConstInfo = {
  currentCost: number // 995055
  userNo: string // '9001011******'
}

type ReturnType = {
  ok: boolean
  data: ConstInfo
}

type FunctionType = (campId: string | number, payType: 'BIZ_MONEY' | 'POST_PAYMENT') => Promise<ReturnType>
export const fetchMoneyInfo: FunctionType = (campId, payType) =>
  axios.get(`/api/camp/make02/getMoneyInfo?campId=${campId}&payType=${payType}`)
