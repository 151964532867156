import { Dropdown, Button, type MenuProps } from 'antd'
import { ReactComponent as MoreIcon } from '@common/svg/more-horiz.svg'
import styles from './ContextMenu.module.scss'

type ContextMenuFunction = Partial<{
  isPreview: boolean
  value?: number
  onEdit: () => void
  onGotoFacebook?: () => void
  editCaption: string
  onCopy: () => void
  onDelete: () => void
  disabled?: boolean
}>
export const ContextMenu: React.FC<ContextMenuFunction> = ({
  value: creativeId,
  onEdit,
  editCaption,
  onCopy,
  onDelete,
  onGotoFacebook,
  disabled,
}) => {
  const baseMenu: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div className={styles.menuItem} onClick={onEdit}>
          {editCaption}
        </div>
      ),
    },

    {
      key: '2',
      label: (
        <div className={styles.menuItem} onClick={onCopy}>
          {'복제'}
        </div>
      ),
      disabled: !onCopy,
    },
    {
      key: '3',
      label: (
        <div className={styles.menuItem} onClick={onDelete}>
          {'삭제'}
        </div>
      ),
      disabled: !onDelete,
    },
    {
      key: '4',
      label: <div>{`소재ID : ${creativeId || ''}`}</div>,
    },
  ]
  if (!!onGotoFacebook) {
    baseMenu.splice(1, 0, {
      key: '12',
      label: (
        <div className={styles.menuItem} onClick={onGotoFacebook}>
          {'페이스북 미리보기'}
        </div>
      ),
    })
  }
  return (
    <Dropdown
      className={styles.containContextMenu}
      menu={{ items: baseMenu }}
      placement="bottomRight"
      disabled={disabled}
    >
      <Button className={styles.button} type="text" icon={<MoreIcon style={{ width: 20 }} />} />
    </Dropdown>
  )
}
