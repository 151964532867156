import { useQuery, useQueryClient } from '@tanstack/react-query'
import { fetchAlertUnreadCount } from '@biz/api'

export const useAlertUnreadCountQuery = () => {
  const queryClient = useQueryClient()
  const queryKey = ['@biz/query/frame/AlertCount']
  return {
    ...useQuery({
      queryKey,
      queryFn: () => fetchAlertUnreadCount().then((res) => res?.data || 0),
    }),
    forceUpdate: (cnt: number) => queryClient.setQueryData(queryKey, cnt),
  }
}
