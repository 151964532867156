import axios from 'axios'
import type { AxiosResponse } from 'axios'

export interface Payload {
  campId: number
  creationLinkId: number
  useYn: boolean
}

export interface Response {
  ok: boolean
  creationLinkId: number
  useYn: boolean
}

/**
 * 광고세트 타겟 설정 ON/OFF 업데이트
 */
export const fetchPostAdSetCreationOnOff = ({
  campId,
  creationLinkId,
  useYn,
}: Payload): Promise<AxiosResponse<Response>> => {
  const payload = {
    creationLinkId,
    useYn,
    campId,
  }
  const result = axios.post('/api/camp/make02/fbAdSetCreationOnOffUpdate', payload)

  return result
}
