import React, {
  Fragment, useEffect,
} from 'react';
import { useForm } from 'react-hook-form';
import { ApiUtil2 } from '@biz/api'
import alertify from 'alertifyjs'
import { IMngMakerAccountSrchForm } from './MngMakerAccount';

const MngMakerAccountPersonalDialog = (props :any) => {
  const {
    getValues, setValue, watch,
  } = useForm<IMngMakerAccountSrchForm>();
  console.log(watch());

  const successEvent = (msg: string) => {
    alertify.success(msg, 1);
  };

  const confirmSuccess = (msg: string) => {
    successEvent(msg);
  };

  const errorEvent = (msg: string) => {
    alertify.error(msg, 1);
  };

  const confirmError = (msg: string) => {
    errorEvent(msg);
  };

  function regexFunc(value: any) {
    const regex = /[^-.0-9]/g; // 숫자와 .만가능
    const pattern = /^\d*(\.\d{0,1})?$/; // 소수점 1째자리까지인가?
    if (Number(value) > 100 || Number(value) < 0 || regex.test(value) || !pattern.test(value) || value === '') {
      confirmError('보증금 요율은 0~100 사이의 숫자를 입력해 주세요. \n 소수점 한자리까지 입력 가능합니다.');
      $('#normalBasicFees').val('');
    }
    return value;
  }

  useEffect(() => {
    /** 다이얼로그 오픈 */
    $('#mngMakerDepDialog').dialog({ width: 600, title: '보증금 설정', closeOnEscape: true });
    ApiUtil2.get('/api/mngAccount/maker/getDepRates').then((resp) => {
      // eslint-disable-next-line no-use-before-define
      setValue('beforeDepRates', Number(resp.data.data.depBufRate));
      $('#normalBasicFees').val(resp.data.data.depBufRate);
    });
    $('#mngMakerDepDialog').dialog('open');
  }, []);

  useEffect(() => {
    $('#normalBasicFees').on('change', () => {
      regexFunc($('#normalBasicFees').val());
    });
  }, [$('#normalBasicFees').val()]);

  useEffect(() => {
    /** 보증금 설정 비율 */
    $('#submitDep').on('click', () => {
      if ($('#normalBasicFees').val() === '') {
        return;
      }
      regexFunc($('#normalBasicFees').val());

      ApiUtil2.post('/api/mngAccount/maker/updateDepRates', {
      // eslint-disable-next-line no-use-before-define
        afterDepRates: Number($('#normalBasicFees').val()),
      }).then(() => {
        confirmSuccess('정상적으로 수정 되었습니다. \n 변경된 보증금은 변경 시점 후 \n 생성되는 캠페인에 한하여 적용됩니다.');
        props.setIsDepDialogOpened(false);
        $('#mngMakerDepDialog').dialog('close');
      });
    });
  }, []);

  useEffect(() => {
    /** 다이얼로그 닫기 */
    $(document).on('click', '#closeDep', () => {
      $('#mngMakerDepDialog').dialog('close');
      props.setIsDepDialogOpened(false);
    });
    return () => {
      props.setIsDepDialogOpened(false);
      $('#mngMakerDepDialog').dialog('destroy');
    };
  }, []);

  return (
        <Fragment>
            <form>
                <div id="mngMakerDepDialog" className="dialog" style={{ display: 'none' }}>
                    <div className="dialog-body">
                        <div className="wrap-section wrap-tbl">
                            <div className="box-body">
                                <div className="tbl">
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    보증금
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group expand">
                                                    <div className="inner-input-group">
                                                        <input id='normalBasicFees' className="tf-comm tf-unit" value={getValues('beforeDepRates')}/>
                                                        <span className="fz-14 fc-1">%</span>
                                                    </div>
                                                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl className="vertical">
                                        <dd>
                                            <div className="form-group">
                                                <div className="comp-help">
                                                    <ul className="help-list">
                                                        <li className="list">
                                                            <span className="fz-14 fc-4 bullet">캠페인 광고비의 추가 소진 시 예산 부족을 대비한 보증금 설정</span>
                                                        </li>
                                                        <li className="list">
                                                            <span className="fz-14 fc-4 bullet">전체 메이커 대상으로 설정</span>
                                                        </li>
                                                        <li className="list">
                                                            <span className="fz-14 fc-4 bullet">변경 된 후 ‘검수요청’하는 캠페인 부터 적용</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dialog-footer">
                        <button id="closeDep" type="button" className="btn btn-secondary-outline large">취소</button>
                        <button id="submitDep" type="button" className="btn btn-primary large">수정</button>
                    </div>
                </div>
            </form>
        </Fragment>
  );
};

export default MngMakerAccountPersonalDialog;
