import { useQuery } from '@tanstack/react-query'
import { fetchUseIdentical } from '@biz/api'
import { useCurrentCampaignIdQuery } from '@biz/query'

export const useUserIdenticalQuery = (isEnabled: boolean) => {
  const { data: campaignId } = useCurrentCampaignIdQuery()

  return useQuery({
    queryKey: ['@apps/biz-center/targetCreatePage/useUserIdentical'],
    queryFn: () => (!!campaignId ? fetchUseIdentical(campaignId) : Promise.reject()),
    enabled: isEnabled && Boolean(campaignId),
  })
}
