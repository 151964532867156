import { axios } from '../axios'

type ReturnType = {
  ok: boolean
  data: {
    creationCnt: number
    creationCorrectCnt: number
  }
}

type FunctionType = (campId: string | number, creationDataId?: string | number) => Promise<ReturnType>
export const fetchCreationValidCheck: FunctionType = (campId, creationDataId) =>
  axios.get(
    `/api/camp/make02/creationInspValid?campId=${campId}${creationDataId ? '&creationDataId=' + creationDataId : ''}`
  )
