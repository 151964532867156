import React, { Fragment, useEffect } from 'react';

const FbDialog = (props :any) => {
  useEffect(() => {
    $('#fbClose').click(() => {
      $('#fbDialog').dialog('close');
        // props.setIsClicked(false);
    });
    return () => {
      // 페이지를 벗어나는 이동 시 destory 해줘야 한다. 다른 방법이 있는지 모름
      // if ($('#fbDialog').length > 0 && $.data($('#fbDialog')[0], 'dialog')) {
      $('#fbDialog').dialog('destroy')
      // }
      // props.setIsClicked(false);
    };
  }, []);

  return (
      <Fragment>
          <form>
              <div id="fbDialog" className="dialog" style={{ display: 'none' }}>
                  <div className="dialog-body">
                      <div className="wrap-section no-border">
                          <div className="box-body">
                              <div className="wrap-preview">
                                  <div className="box-body">
                                      <div className="box-group flex-container">
                                          <div className="box-left">
                                              <img
                                                  src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Facebook-01.png"
                                                  alt="img1"/>
                                          </div>
                                          <div className="box-right">
                                              <img
                                                  src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Facebook-02.png"
                                                  alt="img2"/>
                                          </div>
                                      </div>
                                      <div className="box-group">
                                          <div className="box-left">
                                              <img
                                                  src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Ico-logo-wadiz-48x48.png"
                                                  className="logo" alt="img3"/>
                                          </div>
                                          <div className="box-right">
                                              <p className="fz-14 fc-1 fw-medium lh15">와디즈</p>
                                              <p className="fz-12 fc-3 lh15">광고</p>
                                          </div>
                                      </div>
                                      <div className="box-group">
                                          {
                                              props.row !== undefined ? props.row.adDesc.split('\n').map((data: any, k:any) => (
                                                  // eslint-disable-next-line react/jsx-key
                                                  <p id="fbAdDesc" className="fz-14 fc-2 lh15 edit" key={k}>
                                                      {data}
                                                      <br />
                                                  </p>
                                              )) : ''
                                          }
                                      </div>
                                      <div className="box-group">
                                          <div className="comp-thumb"></div>
                                      </div>
                                      <div className="box-group">
                                          <div className="box-row">
                                              <span className="fz-12 fc-3 lh15">wadiz.kr</span>
                                          </div>
                                          <div className="box-row flex-container">
                                              <div className="box-left">
                                                  <p id="fbAdTitle01" className="fz-14 fc-1 fw-medium lh15">{props.row !== undefined ? props.row.adTitle01 : ''}</p>
                                              </div>
                                              <div className="box-right">
                                                  <button id="fbClickBtn" type="button" className="btn btn-secondary-outline xsmall w-auto">{props.row !== undefined ? props.row.clickBtnDiv : ''}</button>
                                              </div>
                                          </div>
                                          <div className="box-row">
                                              <p id="fbAdTitle02" className="fz-12 fc-4 lh15">{props.row !== undefined ? props.row.adTitle02 : ''}</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="box-footer">
                                      <div className="box-group">
                                          <img
                                              src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Facebook-03.png"
                                              alt="img5"/>
                                      </div>
                                      <div className="box-group">
                                          <img
                                              src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Facebook-04.png"
                                              alt="img6"/>
                                      </div>

                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="dialog-footer">
                      <button id="fbClose" type="button" className="btn btn-secondary-outline large">닫기
                      </button>
                  </div>
              </div>
          </form>
      </Fragment>
  );
};

export default FbDialog;
