import React, {
  Fragment, useEffect, useState,
} from 'react';
import { useOutletContext } from 'react-router';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import MngMdGgUpdate from './MngMdGgUpdate';
import MngMdFbUpdate from './MngMdFbUpdate';

const MngMdUpdate = () => {
  const url = useLocation();
  const [mediaConfigId, setMediaConfigId] = useState(null);
  const [mediaDiv2, setMediaDiv2] = useState('fbitg');
  const query:any = qs.parse(url.search.replace('?', ''));
  const setTitle = useOutletContext<any>();
  useEffect(() => {
    setMediaDiv2(query.mediaDiv2);
    setMediaConfigId(query.mediaConfigId);
    setTitle('매체 및 광고세트 설정 수정');
  }, []);
  return (
      <Fragment>{mediaDiv2 === 'fbitg' ? <MngMdFbUpdate mediaConfigId={mediaConfigId}/> : <MngMdGgUpdate mediaConfigId={mediaConfigId}/>}</Fragment>
  );
};

export default MngMdUpdate;
