import { useRef } from 'react'
import { Switch } from 'antd'
import alertify from 'alertifyjs'
import { campaignStatusTable, campaignStatusMessageTable, CampaignStatusType } from '@biz/api'
import { TooltipHelp } from '@biz/ui'
import { useCampaignInfoQuery, useCreationsByAdSetQuery } from '@biz/query'
import { useCampaignTurnOnMutation } from './_queries/useCampaignTurnOnMutation'
import styles from './CampaignStatus.module.scss'

// $color-mint-600: #00c4c4;
// $color-yellow-600: #ffad15;
// $color-gray-800: #343a40;
// $color-red-600: #f66;
export const cTable: { [key in CampaignStatusType]: string } = {
  temp: '#00c4c4',
  insp_req: '#00c4c4',
  invalid: '#ffad15',
  wait: '#00c4c4',
  ing_update: '#ffad15',
  ing_insp: '#ffad15',
  ing: '#00c4c4',
  stop_creation_off: '#ffad15',
  stop_camp_off: '#ffad15',
  stop_camp_range: '#ffad15',
  stop_by_admin: '#f66',
  complete: '#343a40',
  del_ready: '#343a40',
  del_complete: '#343a40',
}

export const CampaignStatus: React.FC = () => {
  const { data, refetch } = useCampaignInfoQuery()
  const turnOn = useCampaignTurnOnMutation()
  const { invalidateQuery: invalidateCreationsByAdSetQuery } = useCreationsByAdSetQuery()
  const campaignStatus = data?.data?.campViewStatus || 'temp'
  const { useYn } = data?.data || { useYn: true }
  const switchRef = useRef(useYn)
  const description = campaignStatusTable[campaignStatus]
  const tooltipMessage = campaignStatusMessageTable[campaignStatus]
  return (
    <dl>
      <dd className={styles.containCampaignStatus}>
        <div className={styles.statusCell}>
          {'캠페인 상태'}
          <TooltipHelp tooltip={tooltipMessage} />
          <div className={styles.status} style={{ color: cTable[campaignStatus] }}>
            {description}
          </div>
        </div>
        <div className={styles.statusCell}>
          <div>{'캠페인 ON/OFF '}</div>
          <div className={styles.status}>
            <Switch
              value={switchRef.current}
              onChange={(onOff) => {
                switchRef.current = onOff
                turnOn
                  .mutateAsync(onOff)
                  .then((res) => {
                    const useYn = res?.data?.useYn
                    switchRef.current = useYn !== undefined ? useYn : true // type guard
                    alertify.success(`캠페인이 ${switchRef.current ? 'ON' : 'OFF'} 되었습니다.`)
                  })
                  .then(() => {
                    refetch()
                    invalidateCreationsByAdSetQuery()
                  })
              }}
            />
          </div>
        </div>
      </dd>
    </dl>
  )
}
