import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { fetchAdSetInfo, type AdSetInfo } from '@biz/api'
import { useCurrentCampaignIdQuery } from '@biz/query'

type UseFunctionType = () => UseQueryResult<AdSetInfo[]>
export const useAdSetInfoQuery: UseFunctionType = () => {
  const { data: campaignId } = useCurrentCampaignIdQuery()
  return useQuery({
    queryKey: ['@apps/biz-center/TargetInfoTab/AdSetInfo', campaignId],
    queryFn: () => campaignId && fetchAdSetInfo(campaignId).then((res) => res.data),
    enabled: Boolean(campaignId),
    refetchOnMount: true,
  })
}
