import React, { Fragment, useEffect, useState } from 'react';
import alertify from 'alertifyjs'
import { ApiUtil2 } from '@biz/api'
import AutoChargeChangePopUp from './AutoChargeChangePopUP';

const AutoCharge = () => {
  const [autoChargeInfo, setAutoChargeInfo] = useState<any>(null);
  const [isAutoChargeInfoDataLoaded, setAutoChargeInfoDataLoaded] = useState<boolean>(false);
  const [isAutoChargeChangePopUpOpened, setIsAutoChargeChangePopUpOpened] = useState<boolean>(false);
  const [userNos, setUserNos] = useState([]);

  const getAutoChargeInfo = () => {
    ApiUtil2.get('/api/bzm/common/findAutoChargeInfo').then((resp) => {
      setAutoChargeInfoDataLoaded(true);
      setAutoChargeInfo(resp.data.data);
    });
  };

  useEffect(() => {
    ApiUtil2.get('/api/bzm/common/findUserNos').then((resp) => {
      setUserNos(resp.data.data);
    });
    getAutoChargeInfo();
  }, []);

  const autoChargeChangeHandler = () => {
    setIsAutoChargeChangePopUpOpened(true);
  };

  const AutoChargeCondition = () => {
    if (isAutoChargeInfoDataLoaded) {
      if (autoChargeInfo.atptActYn && autoChargeInfo.atptMinBalance !== undefined) {
        return <b className="fz-14 fc-2">{autoChargeInfo.atptMinBalance.toLocaleString()}원 이하일 때</b>;
      }
    }
    return <b className="fz-14 fc-2">-</b>;
  };

  const AutoChargeAmount = () => {
    if (isAutoChargeInfoDataLoaded) {
      if (autoChargeInfo.atptActYn && autoChargeInfo.atptBzmoney !== undefined) {
        return <b className="fz-14 fc-2">{autoChargeInfo.atptBzmoney.toLocaleString()}원 충전</b>;
      }
    }

    return <b className="fz-14 fc-2">-</b>;
  };

  const AutoChargeCardNo = () => {
    if (isAutoChargeInfoDataLoaded) {
      if (autoChargeInfo.atptActYn && autoChargeInfo.atptCardNo !== undefined) {
        return <b className="fz-14 fc-2">{autoChargeInfo.atptCardNo}</b>;
      }
    }
    return <b className="fz-14 fc-2">-</b>;
  };

  const AutoChargeDelComponent = () => {
    if (isAutoChargeInfoDataLoaded) {
      if (autoChargeInfo.atptActYn) {
        const autoChargeDelHandler = () => {
          alertify.confirm('본 내역을 삭제하면 더 이상<br>자동충전이 진행되지 않습니다.', () => {
            ApiUtil2.post('/api/bzm/charge/delCard').then(() => {
              getAutoChargeInfo();
              alertify.success('자동충전 등록 내역이 삭제되었습니다.');
            });
          }).set({ labels: { cancel: '취소', ok: '삭제' } }).setHeader('자동충전 등록 내역을 삭제합니다.');
        };
        return <button type="button" className="btn btn-secondary-outline w-auto" onClick={autoChargeDelHandler}>삭제</button>;
      }
    }

    return null;
  };
  return (
        <Fragment>
            {isAutoChargeChangePopUpOpened ? <AutoChargeChangePopUp setIsAutoChargeChangePopUpOpened={setIsAutoChargeChangePopUpOpened} userNos={userNos} getAutoChargeInfo={getAutoChargeInfo}/> : null}
            <div className="wrap-section wrap-tbl">
                <div className="box-header">
                    <div className="box-tit">
                        <h2 className="fz-20 fc-1 fw-bold">자동 충전<button className="ico-tooltip" title="" id='autoChargeTooltip'/></h2>
                    </div>
                </div>
                <div className="box-body">
                    <div className="tbl">
                        <dl className="column-two">
                            <dt>
                                <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        충전 조건
                                    </span>
                                </div>
                            </dt>
                            <dd>
                            <div className="form-group">
                                <span className="comp-txt">
                                    <span className="table">
                                        <span className="table-cell">
                                            <AutoChargeCondition/>
                                        </span>
                                    </span>
                                </span>
                                </div>
                            </dd>
                            <dt>
                                <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        충전 금액
                                    </span>
                                </div>
                            </dt>
                            <dd>
                                <div className="form-group">
                                    <span className="comp-txt">
                                        <span className="table">
                                            <span className="table-cell">
                                                <AutoChargeAmount/>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        신용카드번호
                                    </span>
                                </div>
                            </dt>
                            <dd>
                                <div className="form-group">
                                    <span className="comp-txt">
                                        <span className="table">
                                            <span className="table-cell">
                                                <AutoChargeCardNo/>
                                            </span>
                                        </span>
                                    </span>
                                    <AutoChargeDelComponent/>
                                </div>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className="box-footer">
                    <div className="box-right">
                        <button type="button" className="btn btn-primary-outline" onClick={autoChargeChangeHandler}>변경</button>
                    </div>
                </div>
            </div>
        </Fragment>
  );
};

export default AutoCharge;
