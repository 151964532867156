import React, {
  Fragment, useEffect,
} from 'react';
import { ApiUtil2 } from '@biz/api'
import alertify from 'alertifyjs'

const MngMakerAccountPersonalDialog = (props :any) => {
  console.log(props.row);
  const successEvent = (msg: string) => {
    alertify.success(msg, 1);
  };

  const confirmSuccess = (msg: string) => {
    successEvent(msg);
  };

  const errorEvent = (msg: string) => {
    alertify.error(msg, 1);
  };

  const confirmError = (msg: string) => {
    errorEvent(msg);
  };

  function personalRegexFunc(value: any) {
    const regex = /[^-.0-9]/g; // 숫자와 .만가능
    const pattern = /^\d*(\.\d{0,1})?$/; // 소수점 1째자리까지인가?
    if (regex.test(value) || !pattern.test(value) || value === '') {
      confirmError('기본 정률 수수료는 0~100 사이의 숫자를 입력해 주세요. \n 소수점 한자리까지 입력 가능합니다.');
      $('#personalBasicFees').val('');
    }
    return value;
  }

  useEffect(() => {
    /** 다이얼로그 오픈 */
    $('#personalDialog').dialog({ width: 600, title: '기본 수수료 설정', closeOnEscape: true });
    $('#personalDialog').dialog('open');
    $('#personalBasicFees').val(Number(props.row.basicFees));
    $('#makerDetail').text(`${props.row.makerLoginId}/${props.row.makerNm}의 정률 수수료만 변경 됩니다.`);
  }, []);

  useEffect(() => {
    $('#personalSubmit').on('click', () => {
      const value = personalRegexFunc($('#personalBasicFees').val());
      if (value === '') {
        return;
      }
      // makerId, 개인수수료
      ApiUtil2.post('/api/mngAccount/maker/detail/updateFees', {
        makerId: Number(props.row.makerId),
        afterBasicFees: Number($('#personalBasicFees').val()),
      }).then(() => {
        props.onGridReady();
        confirmSuccess('정상적으로 수정 되었습니다. \n 변경된 수수료는 변경 시점 후 \n 생성되는 캠페인에 한하여 적용됩니다.');
        props.setIsPersonalDialogOpened(false);
        $('#personalDialog').dialog('close');
      });
    });
  }, []);

  useEffect(() => {
    $('#personalBasicFees').on('change', () => {
      personalRegexFunc($('#personalBasicFees').val());
    });
  }, [$('#personalBasicFees').val()]);

  useEffect(() => {
    /** 다이얼로그 닫기 */
    $(document).on('click', '#personalClose', () => {
      $('#personalDialog').dialog('close');
      props.setIsPersonalDialogOpened(false);
    });
    return () => {
      props.setIsPersonalDialogOpened(false);
      $('#personalDialog').dialog('destroy');
    };
  }, []);

  return (
        <Fragment>
            <form>
                <div id="personalDialog" className="dialog" style={{ display: 'none' }}>
                    <div className="dialog-body">
                        <div className="wrap-section wrap-tbl">
                            <div className="box-body">
                                <div className="tbl">
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                            <span className="fz-16 fc-1">
                                                기본 수수료
                                            </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group expand">
                                                    <div className="inner-input-group">
                                                        <input id="personalBasicFees"
                                                                      className="tf-comm tf-unit"
                                                                      step={0.1}
                                                        />
                                                        <span className="fz-14 fc-1">%</span>
                                                    </div>
                                                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl className="vertical">
                                        <dd>
                                            <div className="form-group">
                                                <div className="comp-help">
                                                    <ul className="help-list">
                                                        <li className="list">
                                                            <span id="makerDetail" className="fz-14 fc-4 bullet"></span>
                                                        </li>
                                                        <li className="list">
                                                            <span className="fz-14 fc-4 bullet">변경한 시점 이후 생성된 캠페인에 한하여 적용됩니다.</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dialog-footer">
                        <button id="personalClose" type="button" className="btn btn-secondary-outline large">취소</button>
                        <button id="personalSubmit" type="button" className="btn btn-primary large">수정</button>
                    </div>
                </div>
            </form>
        </Fragment>
  );
};

export default MngMakerAccountPersonalDialog;
