import { nanoid } from 'nanoid';
import dayjs from 'dayjs';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { ParamType } from '@biz/api'
import { thousandsGrouping } from './_util/thousandsGrouping';
import { useAdListQuery } from './_queries/useAdListQuery';

const columns: ColumnsType = [
  {
    title: <span>{'일자'}</span>,
    align: 'left',
    fixed: 'left',
    className: `$ ant-table-cell-fix-left-last`,
    dataIndex: 'basicDate',
    key: 'basicDate',
    render: (basicDate) =>
      basicDate === '합계' ? (
        '합계'
      ) : (
        <div>{!basicDate ? '' : dayjs(basicDate).format('YYYY-MM-DD')}</div>
      ),
    onCell: (record, index) =>
      record?.basicDate !== '합계'
        ? {}
        : { style: { backgroundColor: '#fafafa' } }, // TODO: 추후 아래
  },
  {
    title: '광고',
    align: 'left',
    children: [
      {
        title: '광고유형',
        dataIndex: 'adDiv',
        render: (v) => v ?? '',
      },
      {
        title: '광고상품',
        dataIndex: 'adProduct',
        render: (v) => v ?? '',
      },
    ],
  },
  {
    title: '금액',
    align: 'left',
    children: [
      {
        title: '광고비',
        dataIndex: 'adUse',
        render: (data) => thousandsGrouping(data),
      },
      {
        title: '유상 소진',
        dataIndex: 'paidUse',
        render: (data) => thousandsGrouping(data),
      },
      {
        title: '후불머니',
        dataIndex: 'pomUse',
        render: (data) => thousandsGrouping(data),
      },
      {
        title: '무상소진',
        dataIndex: 'freeUse',
        render: (data) => thousandsGrouping(data),
      },
    ],
  },
];

type AdTableFunction = {
  params: ParamType;
};
export const AdTable: React.FC<AdTableFunction> = (props) => {
  const { isLoading, data } = useAdListQuery(props);
  const { list, stats } = data || { list: [], stats: {} };
  return (
    <Table
      style={{ marginTop: 16 }}
      scroll={{ x: 'max-content' }}
      dataSource={
        list?.length > 0 ? [...list, { basicDate: '합계', ...stats }] : []
      }
      columns={columns}
      loading={isLoading}
      pagination={{
        position: ['bottomCenter'],
        pageSize: 20,
        total: list?.length || 0,
        showSizeChanger: false,
      }}
      tableLayout='fixed'
      locale={{
        emptyText: (
          <div
            style={{
              height: 200,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {'아직 이용내역이 없어요.'}
          </div>
        ),
      }}
      rowKey={() => nanoid()}
      rowClassName={(record, index) =>
        index === list?.length ? 'ant-table-footer' : ''
      }
    />
  );
};
