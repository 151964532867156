import { axios } from '../axios'
import { AIInspectionHistory } from './AIInspection'

export interface AIInspectionHistoryResponse {
  ok: boolean
  data: AIInspectionHistory[]
}

/**
 * AI 소재 검수 이력 조회
 * @see https://wadiz.atlassian.net/wiki/spaces/TF/pages/16490266772/-+API#GPT-%EA%B2%80%EC%88%98-%EC%9D%B4%EB%A0%A5-%EC%A1%B0%ED%9A%8C---AI-%EA%B2%80%EC%88%98-%EC%9D%B4%EB%A0%A5-%EC%A1%B0%ED%9A%8C
 */
export const getAIInspectionHistory = (
  creationDataId: string | number,
  type: 'admin' | 'maker' = 'admin'
): Promise<AIInspectionHistoryResponse> =>
  axios.get(`/api/${type}/campaigns/creations/${creationDataId}/ai-inspection-history`)

/**
 * 메이커용 AI 소재 검수 이력 조회 (Legacy)
 * @see https://wadiz.atlassian.net/wiki/spaces/TF/pages/16490266772/-+API#(%EB%A9%94%EC%9D%B4%EC%BB%A4)%EC%86%8C%EC%9E%AC%EB%B3%84-GPT-%EA%B2%80%EC%88%98-%EC%9D%B4%EB%A0%A5-%EC%A1%B0%ED%9A%8C---AI-%EA%B2%80%EC%88%98-%EC%9D%B4%EB%A0%A5-%EC%A1%B0%ED%9A%8C
 */
export const getAIInspectionHistoryForMaker = (
  creationDataId: string | number,
): Promise<AIInspectionHistoryResponse> =>
  axios.get(`/api/camp/make02/chatGptHis/${creationDataId}`)
