import React, { useState } from 'react'
import { useCurrentCampaignIdQuery } from '@biz/query'
import { ToolBox } from '../ToolBox'
import { CreativeAssetsContainer } from './CreativeAssetsContainer'
import { AttachDocumentModal } from './AttachDocumentModal'
import styles from './CreativeAssetsTab.module.scss'

const CreativeAssetsTabImpl: React.FC<{ projectId: string; onPrev: () => void }> = ({ projectId, onPrev }) => {
  const { data: campaignId } = useCurrentCampaignIdQuery()
  const [isOpen, setOpen] = useState(false)
  return (
    <section className={styles.CreativeAssetsTab}>
      <CreativeAssetsContainer />
      <ToolBox onAttachDocument={() => setOpen(true)} onPrev={onPrev} />
      <AttachDocumentModal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        projectId={projectId}
        campaignId={campaignId || ''}
      />
    </section>
  )
}

export const CreativeAssetsTab = React.memo(CreativeAssetsTabImpl)
