import React, {
  Fragment, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import NoDataTemplate from '../../common/NoDataTemplate';
import { ApiUtil2 } from '@biz/api'
import alertify from 'alertifyjs'
import { ApiUtil } from '@biz/api'
import SystemDataDialog from './SystemDataDialog';
// import Pagination from '../../common/Pagination';

export interface ISystemData {
    data: any;
    id: string;
    systemDataDiv: string;
    version: string;
    text: string;
    regTime: string;
}
export interface ISystemDataList {
    ok: boolean;
    data: ISystemData[];
}

const successEvent = (msg:string) => {
  alertify.success(msg, 1, () => {
    console.log(msg);
  });
};

const confirmSuccess = (msg:string) => {
  successEvent(msg);
};

const SystemData = () => {
  const setTitle = useOutletContext<any>();
  const gridRefForPrivate = useRef<AgGridReact>(null);
  const gridRefForTerms = useRef<AgGridReact>(null);
  const [privateData, setPrivateData] = useState<any[]>();
  const [termsData, setTermsData] = useState<any[]>();
  const [systemDataId, setSystemDataId] = useState('');
  const [isClicked, setIsClicked] = useState(false);

  const systemDataDetailRenderer = (props: ICellRendererParams) => (
      <a href="#javascript" className="txt-link"
          onClick={() => {
            // eslint-disable-next-line react/prop-types
            const changeId = props.data.id;
            setSystemDataId(changeId);
            setIsClicked(true);
          }}
      >보기</a>
  );

  const DeleteMessageRenderer = (props: ICellRendererParams) => {
    const deleteBtn = () => {
      alertify.confirm('', '삭제하시겠습니까?', () => {
        ApiUtil.post('/api/community/systemData/delete', { systemDataId: props.data.id }).then((resp) => {
          console.log(resp);
          confirmSuccess('삭제되었습니다.');
          // 새로고침
          if (props.data.systemDataDiv === 'PRIVATE_DATA') {
            // eslint-disable-next-line no-use-before-define
            onGridReadyForPrivateData();
          } else {
            // eslint-disable-next-line no-use-before-define
            onGridReadyForTermsData();
          }
        });
      }, () => {
        console.log('취소');
      }).set({ labels: { cancel: '취소', ok: '확인' } });
    };
    return (
    <span>
          <a href="#javascript" className="txt-link"
              onClick={
                  deleteBtn
              }>
            삭제
          </a>
    </span>
    );
  };

  const [columnDefsPrivate] = useState<ColDef[]>([
    {
      field: 'regTime',
      headerName: '등록일시',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'version',
      headerName: '버전',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'text',
      headerName: '내용보기',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: systemDataDetailRenderer,
    },
    {
      field: 'bronze',
      width: 150,
      headerName: '삭제',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: DeleteMessageRenderer,
    },
  ]);

  const [columnDefsTerms] = useState<ColDef[]>([
    {
      field: 'regTime',
      headerName: '등록일시',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'version',
      headerName: '버전',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'text',
      headerName: '내용보기',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: systemDataDetailRenderer,
    },
    {
      field: 'bronze',
      width: 150,
      headerName: '삭제',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: DeleteMessageRenderer,
    },
  ]);

  useEffect(() => {
    setTitle('개인정보 처리방침 및 광고 서비스 이용약관');
    return () => {
      setTitle('');
    };
  }, []);

  // 컬럼 이동 금지 suppressMovable: true
  const defaultColDefForPrivateData = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: true,
  }), []);

  const defaultColDefForTermsData = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: true,
  }), []);

  const onGridReadyForPrivateData = useCallback(() => {
    ApiUtil2.get('/api/community/systemData/getPrivateData').then((resp) => {
      setPrivateData(resp.data.data);
    });
  }, []);

  const onGridReadyForTermsData = useCallback(() => {
    ApiUtil2.get('/api/community/systemData/getTermsData').then((resp) => {
      if (resp.data.data.length === 0) {
        setTermsData([]);
        const gridAll = (document.querySelectorAll<HTMLElement>('.ag-root-wrapper-body.ag-focus-managed.ag-layout-auto-height')! as any);
        gridAll.forEach((gridHeightInfo:any) => {
          if (gridHeightInfo.style.height === '') {
            // 그리드 2개가 있을 경우 높이 조절이 정상적으로 안되어서 강제로 조정
            // eslint-disable-next-line no-param-reassign
            gridHeightInfo.style.height = '548px';
          }
        });
      } else {
        setTermsData(resp.data.data);
      }
    });
  }, []);

  // const changePaginationForPrivate = () => {
  //       childRefForPrivate.current!.onPaginationChanged();
  // };
  //
  // const changePaginationForTerms = () => {
  //       childRefForTerms.current!.onPaginationChanged();
  // };

  return (
        <Fragment>
            {isClicked === true ? <SystemDataDialog systemDataId={systemDataId} setIsClicked={setIsClicked}/> : null }
            <div className="container-fluid">
                <div className="row">
                    <div className="col col-6">
                        <section className="wrap-section wrap-datagrid">
                            <div className="box-header">
                                <div className="box-tit">
                                    <h2 className="fz-20 fc-1 fw-bold">개인정보 처리방침</h2>
                                </div>
                                <div className="box-option">
                                    <Link to="/community/systemData/PrivateDataPost"><button type="button" className="btn btn-primary">신규 작성</button></Link>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="ag-grid">
                                    <div className="ag-grid-inner">
                                        <AgGridReact
                                            ref={gridRefForPrivate}
                                            rowData={privateData}
                                            columnDefs={columnDefsPrivate}
                                            defaultColDef={defaultColDefForPrivateData}
                                            onGridReady={onGridReadyForPrivateData}
                                            rowHeight={48}
                                           /* pagination={true}
                                            paginationPageSize={10}
                                            suppressPaginationPanel={true} */
                                            suppressScrollOnNewData={true}
                                           /* onPaginationChanged={changePaginationForPrivate} */
                                            domLayout={'autoHeight'}
                                            noRowsOverlayComponent = { NoDataTemplate }
                                        ></AgGridReact>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="col col-6">
                        <section className="wrap-section wrap-datagrid">
                            <div className="box-header">
                                <div className="box-tit">
                                    <h2 className="fz-20 fc-1 fw-bold">광고 서비스 이용약관</h2>
                                </div>
                                <div className="box-option">
                                    <Link to="/community/systemData/termsPost"><button type="button" className="btn btn-primary">신규 작성</button></Link>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="ag-grid">
                                    <div className="ag-grid-inner">
                                        <AgGridReact
                                            ref={gridRefForTerms}
                                            rowData={termsData}
                                            columnDefs={columnDefsTerms}
                                            defaultColDef={defaultColDefForTermsData}
                                            onGridReady={onGridReadyForTermsData}
                                            rowHeight={48}
                                            /* pagination={true}
                                            paginationPageSize={10}
                                            suppressPaginationPanel={true} */
                                            // suppressScrollOnNewData={true}
                                            domLayout={'autoHeight'}
                                           /* onPaginationChanged={changePaginationForTerms} */
                                            noRowsOverlayComponent = { NoDataTemplate }
                                        ></AgGridReact>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Fragment>
  );
};

export default SystemData;
