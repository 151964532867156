import { Select, Form } from 'antd'
import { useCTAListQuery } from './_queries/useCTAListQuery'
import styles from './CTASelector.module.scss'

const CTAList: { [index: string]: string } = {
  APPLY_NOW: '지금신청하기',
  RESERVE_NOW_FB: '지금 예약하기',
  DOWNLOAD_FB: '다운로드',
  GET_A_COUPON: '쿠폰 받기',
  LEARN_MORE_FB: '더 알아보기',
  ORDER_NOW: '지금 주문하기',
  REQUEST_RESERVATION: '예약 요청하기',
  SHOPPING_NOW: '지금 쇼핑하기',
  JOIN_FB: '가입하기',
  MORE_VIDEO: '동영상 더 보기',
  BUY_2: '구매하기',
  USE_THE_APP: '앱 사용하기',
  BUY_1: '구매하기',
}

type CTASelectorFunction = {
  value?: string
  containerName: number
  onUpdate?: () => void
  disabled?: boolean
}
export const CTASelectorName = 'cta'
export const CTASelector: React.FC<CTASelectorFunction> = ({ containerName, value, onUpdate, disabled }) => {
  const { data } = useCTAListQuery()
  const ctaList = (data || []).map((n) => ({
    label: CTAList[n],
    value: n,
  }))
  return (
    <Form.Item name={[containerName, CTASelectorName]} className={styles.CTASelector} label={'행동 유도 버튼'}>
      <Select
        value={value}
        size="small"
        onChange={() => {}}
        className={styles.select}
        options={ctaList}
        optionRender={({ label }) => <div className={styles.item}>{label}</div>}
        onBlur={onUpdate}
        disabled={disabled}
      />
    </Form.Item>
  )
}
