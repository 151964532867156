import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import alertify from 'alertifyjs'
import { GlobalSpin } from '@platform/share/ui'
import { loadCampaignValidation } from './_queries'

type CreativeValidationStepFunction = {
  isProcess: boolean
  onCancel: () => void
  onNextProcess: () => void
}
export const CreativeValidationStep: React.FC<CreativeValidationStepFunction> = React.memo(
  ({ isProcess, onCancel, onNextProcess }) => {
    const queryClient = useQueryClient()
    loadCampaignValidation(queryClient)(isProcess).then((campaignFieldValidation) => {
      if (!campaignFieldValidation) {
        return
      }
      const validData = campaignFieldValidation?.data
      if (campaignFieldValidation?.ok === false) {
        ;(validData?.errors || []).forEach((n) => {
          const { field, convertedMsg } = n
          alertify.error(convertedMsg, { title: field })
        })
        onCancel()
      } else if (campaignFieldValidation?.ok === true) {
        // 레거시 코드에는 오류 상태에 대한 탭 이동이 있는데, 일단 그 부분은 무시한다.
        if (!validData?.projectValid || !validData?.campPeriodValid || !validData?.campBudgetValid) {
          alertify.error('필수 입력 값을 확인해 주세요')
          onCancel()
        } else if (validData?.creationCnt !== validData?.creationCorrectCnt) {
          alertify.error('최소 1개 이상의 소재를 완성해 주세요')
          onCancel()
        } else {
          onNextProcess()
        }
      }
    })
    return isProcess && <GlobalSpin loading={true} />
  },
  (prevProps, nextProps) =>
    prevProps.isProcess === nextProps.isProcess &&
    prevProps.onCancel === nextProps.onCancel &&
    prevProps.onNextProcess === nextProps.onNextProcess
)
