import { Flex, Tabs, type TabsProps } from 'antd'
import { Breadcrumb } from '@platform/share/ui'
import { PageTitle } from '@biz/ui'
import { DailyListTab } from './DailyListTab'
import { AdListTab } from './AdListTab'
import styles from './SalesDpPush.module.scss'

const tabItems: TabsProps['items'] = [
  {
    key: 'daily',
    label: '일자별 내역 보기',
    children: <DailyListTab />,
  },
  {
    key: 'ad',
    label: '광고별 내역 보기',
    children: <AdListTab />,
  },
]

export const SalesDpPush = () => (
  <section className={styles.content}>
    <Breadcrumb path={['리포트 관리', 'DP/PUSH : 매출 리포트']} />
    <PageTitle>{'DP/PUSH : 매출 리포트'}</PageTitle>
    <Flex gap={40} vertical style={{ marginTop: 24 }}>
      <Tabs items={tabItems} style={{ marginTop: 40 }} />
    </Flex>
  </section>
)
