import axios from 'axios'
import { baseOptions } from './baseOptions'

export const instance = axios.create({
  ...baseOptions,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
})

instance.interceptors.response.use(
  function (response) {
    return response.data
  },
  function (err) {
    if (err?.response?.status === 401) {
      return Promise.reject(err)
    }
    return Promise.reject(err)
  }
)

export { instance as axios }
