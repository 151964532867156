import { Fragment, useRef, useState } from 'react'
import { Dialog } from 'rc-easyui'
import PreviewImgDialog2 from './PreviewImgDialog2'

/**
 * 소재 미리보기 다이얼로그
 */
const CreationDataInspHisPreviewDialog = (props: any) => {
  const dialogRef = useRef<any>()
  // 소재의 파일 상세보기 다이얼로그 출력
  const [fileDetailDialog, setFileDetailDialog] = useState<boolean>(false)

  const closeEvent = () => {
    props.setShowCreationDataPreviewDialog(false)
  }

  return (
    <Fragment>
      {fileDetailDialog ? (
        <PreviewImgDialog2
          fileData={props.previewData.creationDataInspHisAfDtos}
          setFileDetailDialog={setFileDetailDialog}
        />
      ) : null}
      <Dialog title="" modal resizable shadow={false} ref={dialogRef} style={{ width: '600px' }}>
        <div className="dialog">
          <div className="dialog-body">
            <div className="wrap-section no-border">
              <div className="box-body">
                <div className="wrap-preview">
                  <div className="box-body">
                    <div className="box-group flex-container">
                      <div className="box-left">
                        <img
                          src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Facebook-01.png"
                          alt="img1"
                        />
                      </div>
                      <div className="box-right">
                        <img
                          src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Facebook-02.png"
                          alt="img2"
                        />
                      </div>
                    </div>
                    <div className="box-group">
                      <div className="box-left">
                        <img
                          src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Ico-logo-wadiz-48x48.png"
                          className="logo"
                          alt="img3"
                        />
                      </div>
                      <div className="box-right">
                        <p className="fz-14 fc-1 fw-medium lh15">와디즈</p>
                        <p className="fz-12 fc-3 lh15">광고</p>
                      </div>
                    </div>
                    <div className="box-group">
                      <p
                        id="fbAdDesc"
                        dangerouslySetInnerHTML={{ __html: props.previewData.adDesc.replace(/\n/g, '<br/>') }}
                        className="fz-14 fc-2 lh15"
                      ></p>
                    </div>
                    <div className="box-group">
                      <div className="comp-thumb" onClick={() => setFileDetailDialog(true)}>
                        <div className="txt-state mint">
                          {JSON.parse(props.previewData.creationDataInspHisAfDtos[0].afMeta).fileDiv === 'VIDEO'
                            ? '동영상'
                            : '이미지'}
                        </div>
                        <div className="txt-state blue">
                          {JSON.parse(props.previewData.creationDataInspHisAfDtos[0].afMeta).ext.toUpperCase()}
                        </div>
                        {props.previewData.creationType !== undefined && props.previewData.creationType === 'slide' ? (
                          <div className="txt-state black">슬라이드</div>
                        ) : null}
                        {JSON.parse(props.previewData.creationDataInspHisAfDtos[0].afMeta).fileDiv === 'VIDEO' &&
                        JSON.parse(props.previewData.creationDataInspHisAfDtos[0].afMeta).ext !== 'gif' ? (
                          // eslint-disable-next-line jsx-a11y/media-has-caption
                          <video
                            autoPlay
                            controls
                            muted
                            loop
                            /*style={{ width: '100%' }}*/
                          >
                            <source src={`${props.previewData.creationDataInspHisAfDtos[0].fileName}`} />
                          </video>
                        ) : (
                          <img src={`${props.previewData.creationDataInspHisAfDtos[0].fileName}`} alt="img22" />
                        )}
                      </div>
                    </div>
                    <div className="box-group">
                      <div className="box-row">
                        <span className="fz-12 fc-3 lh15">wadiz.kr</span>
                      </div>
                      <div className="box-row flex-container">
                        <div className="box-left">
                          <p id="fbAdTitle01" className="fz-14 fc-1 fw-medium lh15">
                            {props.previewData !== undefined ? props.previewData.adTitle01 : ''}
                          </p>
                        </div>
                        <div className="box-right">
                          <button id="fbClickBtn" type="button" className="btn btn-secondary-outline xsmall w-auto">
                            {props.previewData !== undefined ? props.previewData.clickBtnDivText : ''}
                          </button>
                        </div>
                      </div>
                      <div className="box-row">
                        <p id="fbAdTitle02" className="fz-12 fc-4 lh15">
                          {props.previewData !== undefined ? props.previewData.adTitle02 : ''}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <div className="box-group">
                      <img
                        src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Facebook-03.png"
                        alt="img5"
                      />
                    </div>
                    <div className="box-group">
                      <img
                        src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Facebook-04.png"
                        alt="img6"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dialog-footer">
            <button id="fbClose" type="button" className="btn btn-secondary-outline large" onClick={closeEvent}>
              닫기
            </button>
          </div>
        </div>
      </Dialog>
    </Fragment>
  )
}

export default CreationDataInspHisPreviewDialog
