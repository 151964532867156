import { IHeaderParams } from 'ag-grid-community';

export interface ICustomHeaderParams {
  header1: string;
  header2: string;
}
export class GridCustomHeader {
  private agParams!: ICustomHeaderParams & IHeaderParams;

  private eGui!: HTMLDivElement;

  init(agParams: ICustomHeaderParams & IHeaderParams) {
    this.agParams = agParams;
    this.eGui = document.createElement('div');
    this.eGui.className = 'ag-header-cell-label';
    this.eGui.innerHTML = `
            <div class="customHeaderLabel ag-header-cell-text">${this.agParams.header1}<br/>${this.agParams.header2}</div>
        `;
  }

  getGui() {
    return this.eGui;
  }
}
