import { axios } from '../axios'
import { type PaymentInfoType } from './fetchPayTypeCampaignInfo'

export type AvailableCreativeUpdateResponse = {
  additionalCharge: boolean //  true
  changeCampBudgetTotal1: number // 125000
  cost: number // 83297
  balanceDto: PaymentInfoType
}

type ReturnType = {
  ok: boolean
  data: AvailableCreativeUpdateResponse
}

type FunctionType = (campaignId: number) => Promise<ReturnType>
export const fetchAvailableCreativeUpdate: FunctionType = (campaignId) =>
  axios.get(`/api/camp/make02/calculateBalance?campId=${campaignId}`)
