import { useQuery, useQueryClient, type QueryClient } from '@tanstack/react-query'

const queryKey = ['@biz/query/auth/ShadowLogin']
export const setShadowLogin = (queryClient: QueryClient) => (turn: boolean) => queryClient.setQueryData(queryKey, turn)
export const useShadowLoginQuery = () => {
  const queryClient = useQueryClient()
  return {
    ...useQuery({
      queryKey,
      queryFn: () => false,
    }),
    update: setShadowLogin(queryClient),
  }
}
