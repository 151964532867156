import { useState } from 'react';
import { spreadParam, type ParamType } from '@biz/api'
import { SalesReportDownload } from '../SalesReportDownload';
import { AdTable } from '../Tables';
import { SearchToolBox } from './SearchToolBox';
import { useProductsQuery } from './_queries/useProductsQuery';

export const AdListTab: React.FC = () => {
  const { isLoading, data } = useProductsQuery();
  const [params, setParams] = useState<ParamType | undefined>();
  return (
    <>
      {!isLoading && (
        <SearchToolBox
          products={data || []}
          onChange={setParams}
          downloader={
            !!params && (
              <SalesReportDownload
                params={spreadParam(params)}
                targetName={'ad'}
              />
            )
          }
        />
      )}
      {!!params && <AdTable params={params} />}
    </>
  );
};
