import { Fragment, useEffect, useState } from 'react'
import { Tooltip } from 'antd'
import dayjs from 'dayjs'

import SingleDatePicker from './SingleDatePicker'

const AccAdjustRegDet = (props: any) => {
  const [adjustDiv2, setAdjustDiv2] = useState<string>(props.getValues('adjustDiv2'))
  const [commentLength, setCommentLength] = useState<number>(0)

  const changeAdjustDiv2 = (e: any) => {
    setAdjustDiv2(e.target.value)
  }

  const comma = (str: string) => {
    const str2 = String(str)
    return str2.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
  }

  const uncomma = (str: string) => {
    const str2 = String(str)
    return str2.replace(/[^\d]+/g, '')
  }

  const inputNumberFormat = (e: any) => {
    e.target.value = comma(uncomma(e.target.value))
  }

  useEffect(() => {
    // to do something.
  }, [])

  const changePaidExpireTime = (date: any) => {
    props.setValue('paidExpireTime', date.format('YYYYMMDD'))
  }

  const changeFreeExpireTime = (date: any) => {
    props.setValue('freeExpireTime', date.format('YYYYMMDD'))
  }

  useEffect(() => {
    if (props.adjustDiv === 'single') {
      setAdjustDiv2('plus')
    } else {
      setAdjustDiv2('at')
    }
  }, [props.adjustDiv])

  useEffect(() => {
    props.setValue('adjustDiv2', adjustDiv2)
    if (adjustDiv2 === 'minus') {
      const option = {
        singleDatePicker: true,
        autoUpdateInput: true,
        locale: {
          format: 'YY-MM-DD',
        },
        startDate: dayjs().startOf('day').subtract(-3, 'month'),
      }
      $('#paidDisabledDate').daterangepicker(option)
      $('#freeDisabledDate').daterangepicker(option)
    }
  }, [adjustDiv2])

  return (
    <Fragment>
      <section className="wrap-section wrap-tbl">
        <div className="box-header">
          <h2 className="fz-20 fc-1 fw-bold">임의조정 내역</h2>
        </div>
        <div className="box-body">
          <div className="tbl">
            <dl>
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">임의조정 구분</span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  {props.adjustDiv === 'single' ? (
                    <Fragment>
                      <div className="comp-radio">
                        <input
                          type="radio"
                          id="inp-radio-03"
                          value="plus"
                          checked={adjustDiv2 === 'plus'}
                          onChange={changeAdjustDiv2}
                        />
                        <label htmlFor="inp-radio-03">가산</label>
                      </div>
                      <div className="comp-radio">
                        <input
                          type="radio"
                          id="inp-radio-04"
                          value="minus"
                          checked={adjustDiv2 === 'minus'}
                          onChange={changeAdjustDiv2}
                        />
                        <label htmlFor="inp-radio-04">감산</label>
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <span className="comp-txt">
                        <span className="table">
                          <span className="table-cell">
                            <b className="fz-14 fc-7">
                              기입하시는 금액에 맞추어 각 충전 사업자 번호의 비즈머니를 감산/가산 합니다.
                            </b>
                          </span>
                        </span>
                      </span>
                    </Fragment>
                  )}
                </div>
              </dd>
            </dl>
            <dl className="column-two">
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">
                    임의조정 금액 (유상)
                    <Tooltip title="실제 지급되는 임의조정 금액으로 세금계산서 발행 시 합계 금액에 기입됩니다.">
                      <i className="ico-tooltip" />
                    </Tooltip>
                  </span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  {/* Input-Group : Start */}
                  <div className="input-group">
                    <div className="inner-input-group">
                      <input
                        type="text"
                        className="tf-comm tf-unit"
                        placeholder="금액을 입력해 주세요."
                        onKeyUp={inputNumberFormat}
                        {...props.register('paidCost')}
                      />
                      <span className="fz-12 fc-1">원</span>
                    </div>
                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                  </div>
                  {/* Input-Group : End */}
                </div>
              </dd>
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">임의조정 금액 (쿠폰)</span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  {/* Input-Group : Start */}
                  <div className="input-group">
                    <div className="inner-input-group">
                      <input
                        type="text"
                        className="tf-comm tf-unit"
                        placeholder="금액을 입력해 주세요."
                        onKeyUp={inputNumberFormat}
                        {...props.register('freeCost')}
                      />
                      <span className="fz-12 fc-1">원</span>
                    </div>
                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                  </div>
                  {/* Input-Group : End */}
                </div>
              </dd>
            </dl>
            <dl className="column-two">
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">유효기간 (유상)</span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  {adjustDiv2 === 'minus' ? (
                    <div className="comp-datepicker disabled">
                      <div className="inner-datepicker">
                        <i className="ico ico-calendar"></i>
                        <input type="text" className="tf-comm datepicker-single" readOnly id="paidDisabledDate" />
                      </div>
                    </div>
                  ) : (
                    <SingleDatePicker
                      changeEvent={changePaidExpireTime}
                      option={{ startDate: dayjs().startOf('day').subtract(-3, 'month') }}
                    />
                  )}
                </div>
              </dd>
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">유효기간 (쿠폰)</span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  {adjustDiv2 === 'minus' ? (
                    <div className="comp-datepicker disabled">
                      <div className="inner-datepicker">
                        <i className="ico ico-calendar"></i>
                        <input type="text" className="tf-comm datepicker-single" readOnly id="freeDisabledDate" />
                      </div>
                    </div>
                  ) : (
                    <SingleDatePicker
                      changeEvent={changeFreeExpireTime}
                      option={{ startDate: dayjs().startOf('day').subtract(-3, 'month') }}
                    />
                  )}
                </div>
              </dd>
            </dl>
            <dl>
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">임의조정 사유</span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  {/* Input-Group : Start */}
                  <div className="input-group small expand">
                    <div className="inner-input-group">
                      <textarea
                        className="tf-textarea"
                        placeholder="1 ~ 100자 내로 입력해 주세요."
                        {...props.register('comment', {
                          maxLength: 100,
                          onChange: (e: any) => setCommentLength(e.target.value.length),
                        })}
                      ></textarea>
                    </div>
                    <p className="fz-12 fc-3">
                      <span className="fc-2">{commentLength}</span> / 100
                    </p>
                    <p className="fz-12">
                      <i className="ico"></i>체크 / 에러 문구 내용 영역
                    </p>
                  </div>
                  {/* Input-Group : End */}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default AccAdjustRegDet
