import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie'
import { Modal as AntModal } from 'antd'

import { ApiUtil } from '@biz/api'

export const cookies = new Cookies()
export const setCookie = (name: string, value: string, option?: any) => cookies.set(name, value, { ...option })
export const getCookie = (name: string) => cookies.get(name)

const Modal = ({ item }: { item: any }) => {
  const [isOpen, setIsOpen] = useState(false)
  const cookie = getCookie(`DO_NOT_SHOW_AGAIN.${item.boardId}`)

  useEffect(() => {
    if (Number(cookie) === Number(item.boardId)) {
      return
    }

    setIsOpen(true)
  }, [])

  return (
    <AntModal
      cancelText="더 이상 보지 않기"
      centered
      closable={false}
      mask={false}
      okText="확인"
      open={isOpen}
      title={item.title}
      onCancel={() => {
        setCookie(`DO_NOT_SHOW_AGAIN.${item.boardId}`, item.boardId, { maxAge: 60 * 60 * 24 * 30 })
        setIsOpen(false)
      }}
      onOk={() => setIsOpen(false)}
    >
      <div className="dialog-body">
        <span className="fz-16 fc-2" dangerouslySetInnerHTML={{ __html: item.text }} />
      </div>
    </AntModal>
  )
}

const NoticePopUp = () => {
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    ApiUtil.get('/api/makerCommon/notice/listForPopUp').then((response) => {
      setData(response.data.data)
    })
  }, [])

  return (
    <>
      {data.map((item: any) => (
        <Modal item={item} key={item.boardId} />
      ))}
    </>
  )
}

export default React.memo(NoticePopUp)
