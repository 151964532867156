import { useQuery, useQueryClient, type QueryClient } from '@tanstack/react-query'

const queryKey = ['@biz/query/auth/MemberId']
export const setMemberId = (queryClient: QueryClient) => async (memberId: number | undefined) =>
  queryClient.setQueryData(queryKey, memberId)

export const useMemberIdQuery = () => {
  const queryClient = useQueryClient()
  return {
    ...useQuery({
      queryKey,
      queryFn: () => 0,
    }),
    update: setMemberId(queryClient),
  }
}
