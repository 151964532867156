import dayjs from 'dayjs'
import type { ProjectInfoType } from '@biz/api'
import type { FundingType } from '../../FundingTypeItem/fundingType'

const timeFormat = (t?: string) => (!t ? '미설정' : dayjs(t).format('YY-MM-DD hh:mm A'))
export const makeProjectNotice = (selector: FundingType | undefined, projectInfo: ProjectInfoType) => {
  const startTime = selector === 'FUNDING_OPEN' ? projectInfo?.rcsPostTime : projectInfo?.adPossStartTime
  const endTime = selector === 'FUNDING_OPEN' ? projectInfo?.rcsEndTime : projectInfo?.adPossEndTime
  return {
    title: `${
      projectInfo?.projectDiv === 'STORE' ? '' : selector === 'FUNDING_OPEN' ? '[오픈 예정]' : '[펀딩 진행중]'
    } ${projectInfo?.projectNm || ''}`,
    period: `프로젝트 기간 : ${timeFormat(startTime)} ~ ${timeFormat(endTime)}`,
  }
}
