import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import alertify from 'alertifyjs'
import { legacyAPIErrorFilter, fetchCampaignUpdateCheck, type CampaignInfoType } from '@biz/api'
import { loadCampaignInfo } from '@biz/query'

export const useCampaignUpdateCheck = () => {
  const queryClient = useQueryClient()
  const updateCheck = useCallback(
    async (newTotal: number) => {
      const { ok, data: campaignInfo } = await loadCampaignInfo(queryClient)()
      if (ok === false) {
        return { ok: false }
      }
      const { id, campStartTime, campEndTime } = campaignInfo as CampaignInfoType
      return await queryClient.fetchQuery({
        queryKey: ['@biz/query/createTargetAd/CampaignUpdateCheck'],
        queryFn: () =>
          legacyAPIErrorFilter(
            fetchCampaignUpdateCheck({
              campId: id,
              campStartTime,
              campEndTime,
              campBudget: newTotal,
              campUpdateDiv: 'campBudget',
            }),
            '업데이트 확인을 실패했습니다. 잠시후 다시 시도해 주세요'
          ),
      })
    },
    [queryClient]
  )
  return useCallback(
    (preValue: number, currentValue: number) =>
      new Promise<{
        cmd: 'update' | 'restore' | 'reCheck'
        cost?: number
        checkData?: {
          rptTotalCost: number
          reducedBudget: number
          beforeCampBudgetTotal: number
          afterCampBudgetTotal: number
          cost: number
        }
      }>(async (resolve) => {
        const res = await updateCheck(currentValue)
        if (res?.ok === false) {
          resolve({ cmd: 'restore' })
          return
        }

        const {
          cost,
          additionalCharge,
          /*메타 동기화된 캠페인 금액 수정시 */
          rptTotalCost,
          reducedBudget,
          afterCampBudgetTotal,
        } = res?.data || {}

        if (cost !== undefined && cost < 0 && reducedBudget !== undefined) {
          resolve({
            cmd: 'reCheck',
            checkData: {
              // campaignName: campaignInfo?.data?.campNm || '',
              rptTotalCost: rptTotalCost || 0,
              reducedBudget,
              beforeCampBudgetTotal: preValue,
              afterCampBudgetTotal: afterCampBudgetTotal || 0,
              cost,
            },
          })
          return
        }

        const difference = Math.abs(cost || 0)
        const msg =
          cost !== undefined && cost < 0
            ? `캠페인 총예산이 ${preValue} 원 에서  ${currentValue} 원으로 변경됩니다. <br><br><span class="fz-12 fc-2"><i class="fz-12 fc-5">*</i>  단, SNS광고의 예산을 변경 시 연동 딜레이로 인해 실제 사용한 비용이 변경하신 예산보다 <br>많은 경우 일일 예산이 조정될 수 있습니다.</span><br><br><span class="fz-12 fc-2"><i class="fz-12 fc-5">*</i>  예산 변경으로 예치금 이하의 금액이 소진되면 잔여 예치금은 캠페인 종료 후 D+4일에 반환됩니다.</span>`
            : additionalCharge
            ? `캠페인의 총 예산을 <br> ${preValue} 원 에서  ${currentValue} 원 으로 <br> 변경 하시겠습니까? <br><br> 증액하시는 예산으로 인해 <br> 예치금에  ${difference} 원이 추가로 <br>결제됩니다. (VAT 포함 금액)`
            : `캠페인 총예산이 ${preValue} 원 에서  ${currentValue} 원으로 변경됩니다.`
        alertify
          .confirm(
            msg,
            () => {
              resolve({ cmd: 'update', cost })
            },
            () => {
              resolve({ cmd: 'restore' })
            }
          )
          .set({ labels: { cancel: '취소', ok: '확인' } })
          .setHeader('캠페인 비용 변경 확인')
      }),
    [updateCheck]
  )
}
