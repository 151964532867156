import alertify from 'alertifyjs'
// const getByteFunction = (value:any) => {
//   const pattern = /%u/g;
//   const krPattern = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
//   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//   // @ts-ignore
//   if ((`${value}`).match(krPattern)) return value.length + (`${escape(value)}%u`).match(pattern).length;
//   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//   // @ts-ignore
//   return value.length + (`${escape(value)}%u`).match(pattern).length - 1;
// };

const charByteSize = (ch: any) => {
  const charCode = ch.charCodeAt(0)
  if (charCode <= 0x00007f) {
    return 1
  }
  if (charCode <= 0x0007ff) {
    return 2
  }
  if (charCode <= 0x00ffff) {
    return 3
  }
  return 4
}

// 문자열을 UTF-8로 변환했을 경우 차지하게 되는 byte 수를 리턴한다.
const getByteFunction = (str: any) => {
  let size = 0

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < str.length; i++) {
    size += charByteSize(str.charAt(i))
  }
  return size
}
const test2 = () => {
  alertify.error('에러창')
}
export { test2, getByteFunction }
