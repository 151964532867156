import { useEffect } from 'react'

export const PushAdRedirect = () => {
  useEffect(() => {
    window.open('http://business.wadiz.kr/adstudio/push/step01')
    window.history.back()
  }, [])

  return null
}
