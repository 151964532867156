import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog } from 'rc-easyui'
import { ApiUtil, ApiUtil2, ApiUtil3 } from '@biz/api'
import { Af } from './PomInquiryRegDialog'
import { bytesToSize } from '../fileUploader/MultiFileUploaderForConsulting'
import { fileDownHandler } from '../bizmoneyAdmin/postpaidMoney/pomInquiry/PomInquiryDetail'
import { IPomInquiryDetailForMaker } from './PomInquiryDetailDialog'
import alertify from 'alertifyjs'

const PomInquiryModificationDialog = (props: any) => {
  const dialogRef = useRef<any>()
  const [projectNm, setProjectNm] = useState('')
  const [status, setStatus] = useState('')
  const [comment, setComment] = useState('')
  const [text, setText] = useState('')
  const [pomLimitValue, setPomLimitValue] = useState<number>(0)
  const [textLength, setTextLength] = useState(0)
  const afs = useRef<Af[]>([])
  const afsSize = useRef<number>(0)
  const uploadIdRef = useRef<number>()
  const [fileCnt, setFileCnt] = useState<number>(0)
  const maxFileSize = 31457280
  const scrollRef = useRef(null)
  const fileUploadHandler = async (e: any) => {
    const file: File = e.target.files[0]
    const fileName: string = file.name
    const fileSize: number = file.size

    if (
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg' ||
      file.type === 'image/png' ||
      file.type === 'application/pdf'
    ) {
      // 성공
    } else {
      alertify.error('다음의 확장자만 허용합니다.<br/>JPEG, JPG, PNG, PDF')
      return false
    }

    if (afs.current.length >= 5) {
      alertify.error('파일은 최대 5개 업로드 가능합니다.')
      return
    }

    if (afsSize.current + fileSize >= maxFileSize) {
      alertify.error('업로드 가능한 용량을 초과하였습니다.\n' + '30MB 이내')
      return
    }

    await ApiUtil.get('/api/common/getUploadId').then((response) => {
      uploadIdRef.current = response.data.data
    })

    const xhr = new XMLHttpRequest()
    const data = { uploadId: uploadIdRef.current, originFileName: file.name, myS3Upload: 'pom_inquiry' }
    ApiUtil3.post('/api/file/uploadReq', data).then((resp) => {
      const { presignUrl } = resp.data.data
      const { publicUrl } = resp.data.data

      xhr.responseType = 'json'
      xhr.open('PUT', presignUrl, true) // xhr url에다가 s3 url 반환해줘야 함.
      xhr.setRequestHeader('Access-Control-Allow-Headers', '*')
      xhr.send(file)

      const af = new Af(fileName, fileSize, publicUrl, uploadIdRef.current)
      afs.current.push(af)
      afsSize.current += fileSize
      setFileCnt(afs.current.length)

      // @ts-ignore
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
    })
  }
  const fileDeleteHandler = (e: any) => {
    let id = e.currentTarget.id
    let idxToDel = afs.current.findIndex((it) => {
      return it.uploadId == id
    })
    if (idxToDel !== -1) {
      const temp = [...afs.current]
      temp.splice(idxToDel, 1)
      afs.current = temp
    }
    let filesSize = 0
    afs.current.map((it: any) => (filesSize += it.fileSize))
    afsSize.current -= filesSize
    setFileCnt(afs.current.length)
  }

  useEffect(() => {
    ApiUtil2.get<IPomInquiryDetailForMaker>('/api/pom/pomInquiry/detail?pomInquiryId=' + props.pomInquiryId).then(
      (resp) => {
        let result = resp.data.data
        setProjectNm(result.projectNm)
        setText(result.text)
        setPomLimitValue(result.pomLimitValue)
        setComment(result.comment)
        setTextLength(result.text.length)
        if (result.status === 'WAITING') {
          setStatus('대기')
        } else if (result.status === 'REJECTED') {
          setStatus('반려')
        } else if (result.status === 'HOLDING') {
          setStatus('보류')
        } else if (result.status === 'PERMITTED') {
          setStatus('승인')
        }
        afs.current = result.afs.map((v: Af) => new Af(v.fileName, v.fileSize, v.filePath, v.afId))
        setFileCnt(result.afs.length)
      }
    )

    const inputName = 'pomLimitValue'
    $('#' + inputName).on('keyup', () => {
      let value = $('#' + inputName).val() as string
      const regex = /^\d+$/
      if (!regex.test(value)) {
        $('#' + inputName).val(value.replace(/[^\d]/g, ''))
      }
      if (value.length > 6) {
        $('#' + inputName).val(value.substring(0, 6))
      }
    })

    $('#regBtn').on('click', () => {
      if ($('#pomLimitValue').val() === '') {
        alertify.error('한도 신청금액을 입력해주세요.')
        return
      }
      if (afs.current.length === 0) {
        alertify.error('필수 첨부 서류를 업로드해 주세요.')
        return
      }
      let data = {
        pomInquiryId: props.pomInquiryId,
        text: $('#text').val(),
        afs: afs.current,
        pomLimitValue: $('#pomLimitValue').val(),
      }
      alertify
        .confirm(
          '',
          '수정하시겠습니까?',
          () => {
            ApiUtil3.post('/api/pom/pomInquiry/modify', { ...data }).then(() => {
              alertify.success('수정이 완료되었습니다.')
              props.setIsPomInquiryModificationDialogOpened(false)
              props.onGridReady()
            })
          },
          {}
        )
        .set({ labels: { cancel: '취소', ok: '확인' } })
    })
    $(document).on('click', '#cancelBtn', () => {
      props.setIsPomInquiryModificationDialogOpened(false)
    })
  }, [])

  return (
    <Fragment>
      <form>
        <Dialog title="수정하기" modal resizable shadow={false} ref={dialogRef} style={{ width: '900px' }}>
          <div className="dialog-body">
            <div className="wrap-section wrap-tbl">
              <div className="box-body">
                <div className="tbl">
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">처리 결과</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <p className="comp-txt">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-14 fc-4">{status}</b>
                            </span>
                          </span>
                        </p>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">사유</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <p className="comp-txt">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-14 fc-4">{!!comment ? comment : '-'}</b>
                            </span>
                          </span>
                        </p>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">선택 항목</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <p className="comp-txt">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-14 fc-4">{projectNm}</b>
                            </span>
                          </span>
                        </p>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">한도 신청 금액</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <div className="input-group">
                          <div className="inner-input-group">
                            <input
                              type="number"
                              step="1"
                              className="tf-comm"
                              placeholder="숫자만 입력해 주세요."
                              value={pomLimitValue}
                              onChange={(e) => setPomLimitValue(Number(e.target.value))}
                              id="pomLimitValue"
                            />
                            <span className="fz-12 fc-1">만원</span>
                          </div>
                        </div>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">문의내용</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <div className="input-group small">
                          <div className="inner-input-group">
                            <textarea
                              className="tf-textarea"
                              id="text"
                              maxLength={500}
                              defaultValue={text}
                              style={{ width: 500, minWidth: 500, minHeight: 300 }}
                              onChange={(e) => setTextLength(e.target.value.length)}
                            />
                          </div>
                          <p className="fz-12 fc-3">
                            <span className="fc-2" id="byteCount">
                              {textLength}
                            </span>{' '}
                            / 500
                          </p>
                        </div>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">첨부파일</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <div className="comp-file-upload expand">
                          <input type="file" id="fileUploader" multiple={true} onChange={fileUploadHandler} />
                          <i className="ico i-16 ico-file"></i>
                          <input
                            type="text"
                            id="filePlaceHolder"
                            className="tf-comm"
                            placeholder="파일을 선택하세요."
                            readOnly={true}
                          />
                          <label className="btn" htmlFor="fileUploader">
                            첨부파일 +
                          </label>
                        </div>
                        <div>
                          <p className="fz-12 fc-3">파일 개수는 최대 5개, 총 30MB</p>
                        </div>
                      </div>
                    </dd>
                  </dl>
                  <div ref={scrollRef}>
                    {afs.current.length > 0 ? (
                      <dl>
                        <dt></dt>
                        <dd>
                          <div className="form-group">
                            <div className="comp-file-list">
                              <div className="file-list-top">
                                <span className="fz-14 fc-2">
                                  첨부 파일
                                  <span className="fc-5">
                                    <span className="fc-2" id="fileCnt">
                                      {fileCnt}
                                    </span>
                                  </span>
                                  개
                                </span>
                              </div>
                              <div className="file-list-bottom">
                                {afs.current.map((af, key: number) => {
                                  return (
                                    <div className="file-item" key={key} id={`file-${af.uploadId}`}>
                                      <i className="ico ico-file-list"></i>
                                      <span className="fz-12 fc-2">{af.fileName}</span>
                                      <span className="fz-12 fc-3">({bytesToSize(af.fileSize)})</span>
                                      <button onClick={() => fileDownHandler(af.filePath, af.fileName)}>
                                        <i className="ico ico-download"></i>
                                      </button>
                                      <button onClick={fileDeleteHandler} id={`${af.uploadId}`}>
                                        <i className="ico ico-delete" />
                                      </button>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        </dd>
                      </dl>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dialog-footer">
            <button type="button" className="btn btn-outline-primary" id={'cancelBtn'}>
              취소
            </button>
            <button type="button" className="btn btn-primary" id={'regBtn'}>
              제출
            </button>
          </div>
        </Dialog>
      </form>
    </Fragment>
  )
}

export default PomInquiryModificationDialog
