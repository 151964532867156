import { useQuery } from '@tanstack/react-query'
import { fetchCreationValidCheck, type ValidCheckInfoType } from '@biz/api'
import { useCurrentCampaignIdQuery } from '@biz/query'

export const useCreationValidQuery = (isEnabled: boolean, creationDataId?: number) => {
  const { data: campaignId } = useCurrentCampaignIdQuery()
  return useQuery({
    queryKey: ['@apps/biz/TargetCreatePage/useCreationValidQuery', `${campaignId}${creationDataId || ''}`],
    queryFn: () =>
      !!campaignId ? fetchCreationValidCheck(campaignId, creationDataId).then((res) => res.data) : Promise.reject({}),

    enabled: isEnabled && Boolean(campaignId),
  })
}
