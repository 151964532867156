import { ChangeEvent, useCallback, useState, useEffect, Fragment } from 'react'

import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import alertify from 'alertifyjs'
import { ApiUtil3, uploadUrl } from '@biz/api'
import { MultiFileValidationGg } from './FileGgLogoValidator'

export interface IFileTypes {
  uploadId: number
  fileId: number
  publicUrl: string
  afName: string
  fileName: string
  imageDiv: string
  width: number
  height: number
  fileSize: number
  afMeta: any
  object: File
}

interface uploadFiles {
  uploadFiles: IFileTypes[] | any
}
const MultiFileUploaderGgLogo = (props: any) => {
  // const [isDragging, setIsDragging] = useState<boolean>(false);
  const [files, setFiles] = useState<IFileTypes[]>([])
  const ggUploadFileForm = useForm<IFileTypes>()
  const ggUploadFileForms = useForm<uploadFiles>()

  // const dragRef = useRef<any>(null);
  const settingFiles = (fileData: any) => {
    if (ggUploadFileForms.getValues('uploadFiles') === undefined) {
      ggUploadFileForms.setValue('uploadFiles', [fileData])
    } else {
      ggUploadFileForms.setValue('uploadFiles', [...ggUploadFileForms.getValues('uploadFiles'), fileData])
    }
    props.uploadFilesFunction(fileData)
  }
  const logFileUploadStep2 = async (
    res: any,
    file: any,
    imageDiv: string,
    width: number,
    height: number,
    fileSize: number
  ) => {
    const { fileId, presignUrl, publicUrl } = res.data.data
    await uploadUrl(presignUrl, file).then((uploadRes) => {
      if (uploadRes.status === 200) {
        // 업로드 완료 파일 업로드 성공 여부 알려주기
        ApiUtil3.post('/api/file/success', { uploadId: props.uploadId, fileId }).then((successRes) => {
          if (successRes.status === 200) {
            ggUploadFileForm.setValue('uploadId', props.uploadId)
            // ggUploadFileForm.setValue('object', file);
            ggUploadFileForm.setValue('fileId', fileId)
            ggUploadFileForm.setValue('publicUrl', publicUrl)
            ggUploadFileForm.setValue('afName', file.name)
            ggUploadFileForm.setValue('fileName', file.name)
            ggUploadFileForm.setValue('imageDiv', imageDiv)
            ggUploadFileForm.setValue('width', width)
            ggUploadFileForm.setValue('height', height)
            ggUploadFileForm.setValue('fileSize', fileSize)
            settingFiles(ggUploadFileForm.getValues())
          }
        })
      }
    })
  }
  const logoFileUpload = async (file: any, imageDiv: string, width: number, height: number, fileSize: number) => {
    await ApiUtil3.post('/api/file/uploadReq', {
      uploadId: props.uploadId,
      originFileName: file.name,
      myS3Upload: 'creationGg',
    }).then((res) => {
      if (res.status === 200) {
        logFileUploadStep2(res, file, imageDiv, width, height, fileSize)
      }
    })
  }
  const onChangeFiles = useCallback(
    (e: ChangeEvent<HTMLInputElement> | any): void => {
      let selectFiles: File[] = []
      const tempFiles: IFileTypes[] = files

      if (e.type === 'drop') {
        selectFiles = e.dataTransfer.files
        if (selectFiles.length > 5) {
          alertify.error('로고는 최대 5개까지 업로드가 가능합니다.')
          selectFiles = []
          return
        }
      } else {
        selectFiles = e.target.files
        if (selectFiles.length > 5) {
          alertify.error('로고는 최대 5개까지 업로드가 가능합니다.')
          selectFiles = []
          return
        }
      }
      if (tempFiles.length + selectFiles.length > 5) {
        alertify.error('로고는 최대 5개까지 업로드가 가능합니다.')
        selectFiles = []
        return
      }
      // 벨리데이션 체크 후 업로드 처리
      MultiFileValidationGg(
        selectFiles,
        (finalCheck: boolean, file: any, imageDiv: string, width: number, height: number, fileSize: number) => {
          if (finalCheck) {
            logoFileUpload(file, imageDiv, width, height, fileSize)
          }
        }
      )
    },
    [files]
  )

  const handleFilterFile = (id: number): void => {
    const delFiles = files.filter((file: IFileTypes) => {
      if (file.fileId !== id) {
        return file
      }
      return null
    })
    setFiles(delFiles)
    props.deleteFilesFunction(delFiles)
  }

  // const handleDragIn = useCallback((e: DragEvent): void => {
  //   e.preventDefault();
  //   e.stopPropagation();
  // }, []);
  //
  // const handleDragOut = useCallback((e: DragEvent): void => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //
  //   setIsDragging(false);
  // }, []);
  //
  // const handleDragOver = useCallback((e: DragEvent): void => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //
  //   if (e.dataTransfer!.files) {
  //     setIsDragging(true);
  //   }
  // }, []);
  //
  // const handleDrop = useCallback(
  //   (e: DragEvent): void => {
  //     e.preventDefault();
  //     e.stopPropagation();
  //
  //     onChangeFiles(e);
  //     setIsDragging(false);
  //   },
  //   [onChangeFiles],
  // );

  useEffect(() => {
    if (props.showUploadFile) {
      $('#ggLogoSingFileUploadDiv').removeClass('disabled')
      $('#ggLogoSingFileUpload').removeAttr('disabled')
    }
    if (props.uploadFiles !== undefined) {
      setFiles(props.uploadFiles)
    }
    $('#logoTooltip').tooltip({
      content:
        '<p class="fz-12 fc-0 fw-medium">광고 노출 시 출력되는 로고로 최대 5개까지 업로드 가능합니다.</p><p class="fz-12 fc-0"><br/>가로 모드 로고(4:1)<br/>권장 크기: 1200x300<br/>최소 크기: 512x128<br/><br/>정사각형 로고(1:1)<br/>권장 크기: 1200x1200<br/>최소 크기: 128x128<br/><br/>이미지 파일의 최대 크기는 5,120KB입니다.</p>',
    })
  }, [props.changeOrder])

  // const initDragEvents = useCallback((): void => {
  //   if (dragRef.current !== null) {
  //     dragRef.current.addEventListener('dragenter', handleDragIn);
  //     dragRef.current.addEventListener('dragleave', handleDragOut);
  //     dragRef.current.addEventListener('dragover', handleDragOver);
  //     dragRef.current.addEventListener('drop', handleDrop);
  //   }
  // }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);
  //
  // const resetDragEvents = useCallback((): void => {
  //   if (dragRef.current !== null) {
  //     dragRef.current.removeEventListener('dragenter', handleDragIn);
  //     dragRef.current.removeEventListener('dragleave', handleDragOut);
  //     dragRef.current.removeEventListener('dragover', handleDragOver);
  //     dragRef.current.removeEventListener('drop', handleDrop);
  //   }
  // }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);
  //
  // useEffect(() => {
  //   initDragEvents();
  //
  //   return () => resetDragEvents();
  // }, [initDragEvents, resetDragEvents]);

  // useEffect(() => {
  //   if (props.showUploadFile) {
  //     $('#ggLogoSingFileUploadDiv').removeClass('disabled');
  //     $('#ggLogoSingFileUpload').removeAttr('disabled');
  //   }
  //   $('#logoTooltip').tooltip({
  //     content: '<p class="fz-12 fc-0 fw-medium">광고 노출 시 출력되는 로고로 최대 5개까지 업로드 가능합니다.</p><p class="fz-12 fc-0"><br/>가로 모드 로고(4:1)<br/>권장 크기: 1200x300<br/>최소 크기: 512x128<br/><br/>정사각형 로고(1:1)<br/>권장 크기: 1200x1200<br/>최소 크기: 128x128<br/><br/>이미지 파일의 최대 크기는 5,120KB입니다.</p>',
  //   });
  //   return () => {
  //     $('#logoTooltip').remove();
  //   };
  // }, []);
  return (
    <Fragment>
      <dl className="vertical">
        <dt>
          <div className="dt-inner">
            <span className="fz-16 fc-1">
              로고
              <Link to="#" className="ico-tooltip tooltip-f" id="logoTooltip" title=""></Link>
            </span>
          </div>
        </dt>
        <dd>
          <div className="form-group">
            {/* //multiple selected */}
            <div className="comp-file-upload disabled" id="ggLogoSingFileUploadDiv">
              {/* <div className="box-group"> */}
              <input
                type="file"
                id="ggLogoSingFileUpload"
                onChange={onChangeFiles}
                accept="image/jpeg, image/jpg, image/png, image/gif"
                disabled
              />
              {/* <input type="file" multiple={true} onChange={onChangeFiles} ref={dragRef} accept="image/jpeg, image/jpg, image/png, image/gif"/> */}
              <i className="ico ico-file"></i>
              {/* <input type="text" className="tf-comm" placeholder={isDragging ? '로고 이미지를 업로드해 주세요.' : '로고 이미지를 업로드해 주세요'} */}
              {/*       readOnly={true} id="multiFileUploaderText" disabled/> */}
              {/* <span className="fz-12 fc-4">5MB 이하 / 5개 이하</span> */}
              <input type="text" className="tf-comm" placeholder="로고 이미지를 업로드해 주세요." readOnly={true} />
              <label className="btn" htmlFor="ggLogoSingFileUpload">
                업로드
              </label>
              {/* </div> */}
            </div>
            <div className="comp-file-list thum">
              <div className="file-list-top">
                <span className="fz-14 fc-2">
                  첨부 파일 총 <span className="fc-5">{files.length}</span>개
                </span>
              </div>
              <div className="file-list-bottom">
                {files.length > 0 &&
                  files.map((file: IFileTypes) => {
                    const {
                      fileId,
                      publicUrl,
                      // object: { name },
                    } = file

                    return (
                      <div className="file-item" key={fileId}>
                        <div className="comp-thumb">
                          <button onClick={() => handleFilterFile(fileId)} type="button">
                            <i className="ico ico-delete"></i>
                          </button>
                          {/* eslint-disable-next-line jsx-a11y/alt-text */}
                          <img src={publicUrl} />
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        </dd>
      </dl>
    </Fragment>
  )
}

export default MultiFileUploaderGgLogo
