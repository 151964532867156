import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useOutletContext } from 'react-router';
import { ApiUtil2 } from '@biz/api'
import { Collapse } from 'react-bootstrap';

const FaqForCommon = () => {
  const [data, setData] = useState([]);
  const [isExistData, setIsExistData] = useState<boolean>(true);
  const [isSrched, setIsSrched] = useState<boolean>(false);
  const title = useRef('');
  const faqDiv = useRef('ALL');
  const faqDivName = useRef('전체');
  const [faqCnt, setFaqCnt] = useState(0);
  const setTitle = useOutletContext<any>();
  // 머신러닝 FAQ 제어를 위해서
  const hash = window.location.hash.replace('#', '');

  const srchHandler = () => {
    ApiUtil2.get('/api/makerCommon/faq', {
      params: { data: { title: title.current, faqDiv: faqDiv.current } },
    }).then((resp) => {
      setData(resp.data.data);
      setIsExistData(true);
      setIsSrched(false);
      if (title?.current.trim() !== '' && title.current !== undefined) {
        setFaqCnt(resp.data.data.length);
        setIsSrched(true);
      }
      if (resp.data.data.length === 0) {
        setIsExistData(false);
      }
    });
  };

  const SrchResult = () => (
      <div className="box-group">
        <p className="fz-20 fc-1">{faqDivName.current} 카테고리 ‘{title.current}’ 검색 결과 <span className="fc-5">{faqCnt}</span> <span
            className="fc-4">건</span>
        </p>
      </div>
  );

  useEffect(() => {
    setTitle('FAQ');
    srchHandler();
    return () => {
      setTitle('');
    };
  }, []);

  useEffect(() => {
    $('#srchInput').on('keyup', (e:any) => {
      const value = $('#srchInput').val() as string;
      title.current = value;
      if (e.keyCode === 13) {
        srchHandler();
      }
    });
  }, []);

  const FaqItem = useCallback((item: any) => {
    let srchResult = item.item.title.replace(title.current, `<span class="txt-mark">${title.current}</span>`);
    return (
        <div className="panel panel-default">
          <div className="panel-heading" role="tab" id={`heading-${item.item.faqId}`}>
            <h4 className="panel-title">
              <a role="button" data-toggle="collapse" data-parent="#comp-accordion-01"
                 href={`#collapse-${item.item.faqId}`}
                 aria-controls={`collapse-${item.item.faqId}`} aria-expanded="true" className="collapsed">
                <div className="txt-state">{item.item.faqDiv}</div>
                <span
                    className="title" dangerouslySetInnerHTML={{ __html: srchResult }}></span>
                <i className="ico ico-arrow"></i>
              </a>
            </h4>
          </div>
          <div role="tabpanel" className={`panel-collapse collapse ${hash == item.item.faqId ? 'in' : ''}`} id={`collapse-${item.item.faqId}`}
               aria-labelledby={`heading-${item.item.faqId}`}>
            <div className="panel-body">
              <p className="fz-14 fc-2" dangerouslySetInnerHTML={{__html: item.item.text}}>
              </p>
            </div>
          </div>
        </div>
    );
  }, []);

  const NoData = () => (
      <div className="comp-tab-content selected">
        <div className="comp-no-data">
          <div className="box-group">
            <i className="ico ico-no-search"></i>
            <p className="fz-18 fc-2">검색 결과가 없습니다.</p>
            <p className="fz-14 fc-3">검색어를 다시 입력하여 검색해주세요.</p>
          </div>
        </div>
      </div>
  );

  const tapHandler = (e:any) => {
    faqDiv.current = e.target.id;
    faqDivName.current = e.target.dataset.nm;
    if (faqDiv.current !== '') {
      setIsSrched(true);
      srchHandler();
    }
  };

  return (
      <section className="wrap-section wrap-faq">
        <div className="box-body">
          <div className="box-top">
            <div className="input-group comp-search large expand">
              <div className="inner-input-group">
                <input type="text" className="tf-comm" placeholder="검색어를 입력해 주세요." id='srchInput'/>
                <i className="ico ico-search"></i>
              </div>
            </div>
            <button type="button" className="btn btn-primary large w-150" onClick={srchHandler}>검색</button>
            { isSrched === true ? <SrchResult/> : '' }
          </div>
          <div className="box-bottom">
            <div className="comp-tab">
              <button className={ faqDiv.current === 'ALL' ? 'tab selected' : 'tab' } onClick={tapHandler}>
                <span className="tab-item" id='ALL' data-nm='전체'>전체</span>
              </button>
              <button className={ faqDiv.current === 'REG_AD' ? 'tab selected' : 'tab' } onClick={tapHandler}>
                <span className="tab-item" id='REG_AD' data-nm='광고등록'>광고등록</span>
              </button>
              <button className={ faqDiv.current === 'INSP_AD' ? 'tab selected' : 'tab' } onClick={tapHandler}>
                <span className="tab-item" id='INSP_AD' data-nm='광고검수'>광고검수</span>
              </button>
              <button className={ faqDiv.current === 'MNG_AD' ? 'tab selected' : 'tab' } onClick={tapHandler}>
                <span className="tab-item" id='MNG_AD' data-nm='광고관리'>광고관리</span>
              </button>
              <button className={ faqDiv.current === 'BIZMONEY' ? 'tab selected' : 'tab' } onClick={tapHandler}>
                <span className="tab-item" id='BIZMONEY' data-nm='비즈머니'>비즈머니</span>
              </button>
              <button className={ faqDiv.current === 'TAX_BILL' ? 'tab selected' : 'tab' } onClick={tapHandler}>
                <span className="tab-item" id='TAX_BILL' data-nm='세금계산서'>세금계산서</span>
              </button>
              <button className={ faqDiv.current === 'ETC' ? 'tab selected' : 'tab' } onClick={tapHandler}>
                <span className="tab-item" id='ETC' data-nm='기타'>기타</span>
              </button>
            </div>
            <div className="comp-tab-content selected">
              <div className="panel-group comp-accordion" id="comp-accordion-01" role="tablist"
                   aria-multiselectable="true">
                { isExistData === true ? data.map((item:any, index:any) => (<FaqItem key={index} item={item}/>)) : <NoData/>}
              </div>
            </div>
          </div>
        </div>
      </section>
  );
};

export default FaqForCommon;
