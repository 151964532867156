import React, { Fragment } from 'react'
import Editor from 'ckeditor5-custom-build/build/ckEditor'
import { CKEditor } from '@ckeditor/ckeditor5-react'

const CkEditor5N = (props) => {
  const changeHandler = (editor) => {
    // eslint-disable-next-line react/prop-types
    props.setText(editor.getData())
  }

  return (
    <>
      <div className="row">
        <div className="document-editor__toolbar"></div>
      </div>
      <CKEditor
        editor={Editor}
        config={{
          toolbarLocation: 'top',
          removePlugins: ['EasyImage', 'ImageUpload', 'MediaEmbed'],
        }}
        onReady={(editor) => {
          document.querySelector('.document-editor__toolbar')?.appendChild(editor.ui.view.toolbar.element)
          editor.editing.view.change((writer) => {
            writer.setStyle('height', '600px', editor.editing.view.document.getRoot())
          })
        }}
        onChange={(event, editor) => {
          changeHandler(editor)
        }}
      />
    </>
  )
}

export default CkEditor5N
