import { Modal as AntModal, ModalProps as AntModalProps } from 'antd'
import classNames from 'classnames'

import { ReactComponent as CloseIcon } from '@common/svg/close.svg'
import styles from './Modal.module.scss'

const cx = classNames.bind(styles)

const ModalSize = {
  md: 560,
  lg: 640,
  xl: 840,
}
interface ModalProps extends AntModalProps {
  size?: keyof typeof ModalSize
  className?: string
}

const ModalComponent = ({ className, size = 'lg', ...props }: ModalProps) => {
  return (
    <AntModal
      closeIcon={<CloseIcon className={styles.closeIcon} />}
      className={cx(styles.modal, className)}
      width={ModalSize[size]}
      {...props}
    />
  )
}

ModalComponent.useModal = AntModal.useModal
export const Modal = ModalComponent
