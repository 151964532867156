import { forwardRef, Fragment, useMemo, useCallback, useEffect, useRef, useState } from 'react'
import { Dialog } from 'rc-easyui'
import _ from 'lodash'
import { AIInspectionHistory } from '@biz/api'
import { useAIInspectionHistoryQuery } from '@biz/query'
import CreationAIForm from './CreationAIForm'

// AI 심의 결과 이력 모달
const CreationAIHistoryModal = (props: any, ref: any) => {
  const dialogRef = useRef<any>()
  const gptFormRef = useRef<any>({})
  // TODO: 현재는 API 마이그레이션 전이라서 어드민 용으로만 사용
  const { data: aiInspectionHistory } = useAIInspectionHistoryQuery(props.creationDataId, 'admin')
  const [history, setHistory] = useState<AIInspectionHistory[]>([])

  useEffect(() => {
    if (aiInspectionHistory) {
      setHistory(aiInspectionHistory)
    }
  }, [aiInspectionHistory])

  useEffect(() => {
    if (props.creationDataGptHisId != null && gptFormRef.current[props.creationDataGptHisId] != null) {
      gptFormRef.current[props.creationDataGptHisId].scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      })
    }
  }, [history])

  return (
    <Fragment>
      <Dialog title={`AI 심의 결과`} modal resizable shadow={false} ref={dialogRef} style={{ width: '900px' }}>
        <div id="dialog37" className="dialog">
          <div className="dialog-body">
            <div className="wrap-section wrap-tbl">
              <div className="box-body">
                <div className="tbl">
                  <dl className="column-two">
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">캠페인ID</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <span className="comp-txt">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-14 fc-2">{props.campId}</b>
                            </span>
                          </span>
                        </span>
                      </div>
                    </dd>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">소재ID</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <span className="comp-txt">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-14 fc-2">{props.creationDataId}</b>
                            </span>
                          </span>
                        </span>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="wrap-section wrap-tbl h-500 scroll-y">
              <div className="box-body">
                <div className="tbl">
                  <dl className="vertical">
                    <dd>
                      <div className="form-group">
                        {history?.map((history: AIInspectionHistory, index) => (
                          <CreationAIForm
                            key={`${index}-${history.creationDataGptHisId}`}
                            history={history}
                            creationDataId={props.creationDataId}
                            ref={(e2: any) => {
                              gptFormRef.current[history.creationDataGptHisId] = e2
                            }}
                          />
                        ))}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div className="dialog-footer">
            <button
              type="button"
              className="btn btn-primary large"
              onClick={(e) => {
                props.setShowCreationChatGptDialog(false)
              }}
            >
              확인
            </button>
          </div>
        </div>
      </Dialog>
    </Fragment>
  )
}

export default forwardRef(CreationAIHistoryModal)
