import React, { useMemo } from 'react';
import { ITooltipParams } from 'ag-grid-community';

// eslint-disable-next-line react/display-name
export default (props: ITooltipParams & { color: string }) => {
  const data = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex!)!.data,
    [],
  );
  console.log(data);

  return (
        <div className="ag-grid-tooltip">
            <p className="fz-12 fc-0"> {data.loginId === undefined ? data.userName : data.loginId} </p>
        </div>
  );
};
