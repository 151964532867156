// TODO: react-query가 과거 버전이다. 업그레이드 해야 한다.
import { useQuery } from '@tanstack/react-query' // try next react-query;
import { fetchDailyList } from '@biz/api'
export const useDailyListQuery = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) =>
  useQuery({
    queryKey: [
      '@bizCenter/SalesReport/DpPush/DailyList',
      `${startDate}${endDate}`,
    ],
    queryFn: () => fetchDailyList(startDate, endDate),
    enabled: Boolean(startDate) && Boolean(endDate),
  });
