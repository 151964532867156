import { useEffect } from 'react'
import { Select } from 'antd'
import { ReactComponent as ChevronDownIcon } from '@common/svg/chevronDown.svg'
import { useBizNumberListInfoQuery } from '@biz/query'
import styles from './SelectBizNumber.module.scss'

type SelectType = { projectId: string; bizNo: string }
type SelectBizNumberFunction = {
  value?: string | number
  onChange?: (valueObj: SelectType) => void
}
export const SelectBizNumber: React.FC<SelectBizNumberFunction> = ({ value: selectedNumber, onChange }) => {
  const { data: bizNumberListInfo, isLoading } = useBizNumberListInfoQuery()
  const { current, disabled, list } = bizNumberListInfo || {} // userNo, bizDiv, projectId
  useEffect(() => {
    if (!!bizNumberListInfo && bizNumberListInfo.current) {
      onChange?.({ projectId: bizNumberListInfo.current?.projectId, bizNo: bizNumberListInfo.current?.userNo })
    }
  }, [bizNumberListInfo, onChange])
  return (
    <div className={styles.containSelectBizNumber}>
      {bizNumberListInfo && (
        <Select
          size="large"
          placeholder="사업자 번호 선택"
          loading={isLoading}
          options={(list || []).map((a) => ({ value: a.projectId, label: a.userNo }))}
          optionRender={(props) => {
            const { value, label } = props
            return (
              <div className={styles.optionItem}>
                <div style={{ color: value === selectedNumber ? '#00C4C4' : '#212529' }}>{label}</div>
              </div>
            )
          }}
          disabled={disabled}
          value={current?.userNo}
          onChange={(_, selectedObj) => {
            const { value, label } = selectedObj as { value: string; label: string }
            onChange?.({ projectId: value, bizNo: label })
          }}
          suffixIcon={<ChevronDownIcon className={styles.suffixIcon} />}
          className={styles.select}
        />
      )}
    </div>
  )
}
