import React, {
  Fragment, useCallback, useMemo, useRef, useState,
} from 'react';
import { Dialog } from 'rc-easyui';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { ApiUtil2 } from '@biz/api'

interface ICurrentBzm{
    ok:boolean;
    data:any;
    makerId:number;
    makerLoginId:string;
    makerNm:string;
    makerStatus:string;
    agreeYn:boolean;
    liveYn:boolean;
    userNo:string;
    sidNo:string;
    bizDiv:string;
    costPaid:number;
    costFree:number;
    campBalancePaid:number;
    campBalanceFree:number;
    returnableCostPaid:number;
    returnableCostFree:number;
}
// 렌더러 모음
const costRenderer = (props: ICellRendererParams) => {
  if (props.value === 0 || props.value === undefined || props.value === null || props.value === '') return '-';
  return Number(props.value).toLocaleString();
};
const liveYnRenderer = (props: ICellRendererParams) => {
  if (props.data.liveYn === null || props.data.liveYn === undefined) {
    return '-';
  } if (props.data.liveYn === true) {
    return '활성';
  }
  return '비활성';
};
const userNoRenderer = (props: ICellRendererParams) => (props.data.userNo !== undefined ? props.data.userNo : '-');
const BzmMngMakerAllCurrentBzmDialog = (props:any) => {
  const dialogRef = useRef<any>();
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>([]);
  const [colDefs] = useState<ColDef[]>([
    { field: 'makerLoginId', headerName: '메이커ID' },
    { field: 'userNo', headerName: '충전 사업자 번호', cellRenderer: userNoRenderer },
    { field: 'liveYn', headerName: '활성여부', cellRenderer: liveYnRenderer },
    { field: 'costPaid', headerName: '현재 보유 잔액 (유상)', cellRenderer: costRenderer },
    { field: 'costFree', headerName: '현재 보유 잔액 (쿠폰)', cellRenderer: costRenderer },
    { field: 'campBalancePaid', headerName: '예치금 (유상)', cellRenderer: costRenderer },
    { field: 'campBalanceFree', headerName: '예치금 (쿠폰)', cellRenderer: costRenderer },
    { field: 'returnableCostPaid', headerName: '환급 가능 금액 (유상)', cellRenderer: costRenderer },
    { field: 'returnableCostFree', headerName: '환급 가능 금액 (무상)', cellRenderer: costRenderer },
  ]);

  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: false,
    headerClass: 'ag-center-aligned-header',
    cellClass: 'ag-center-aligned-cell',
  }), []);

  const onGridReady = useCallback(() => {
    const { makerId } = props;
    ApiUtil2.get<ICurrentBzm>('/api/bzmAdmin/mng/bzmMngMaker/detailInfoByMaker', { params: { data: { makerId } } }).then((resp) => {
      setRowData(resp.data.data);
    });
  }, []);

  const closeEvent = () => {
    props.setCurrentBzmDialog(false);
  };

  return (
    <Fragment>
      <form>
        <Dialog title="전체 비즈머니 현황" modal resizable shadow={false} ref={dialogRef} style={{ width: '1800px' }}>
          <div className="dialog">
            <div className="dialog-body">
              <section className="wrap-section wrap-datagrid">
                <div className="box-body">
                  <div className="ag-grid">
                    <div className="ag-grid-inner">
                      <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={colDefs}
                        defaultColDef={defaultColDef}
                        rowHeight={48}
                        domLayout={'autoHeight'}
                        onGridReady={onGridReady}
                      ></AgGridReact>
                    </div>
                  </div>
                </div>
              </section>
              {/* Wrap-Datagrid : End */}
            </div>
            <div className="dialog-footer">
              <button type="button" className="btn btn-primary large" onClick={closeEvent}>
                확인
              </button>
            </div>
          </div>
        </Dialog>
      </form>
    </Fragment>
  )
};

export default BzmMngMakerAllCurrentBzmDialog;
