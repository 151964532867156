import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {AgGridReact} from "ag-grid-react";
import {ColDef, ICellRendererParams} from "ag-grid-community";
import NoDataTemplate from "../../../common/NoDataTemplate";
import { ApiUtil2 } from '@biz/api'
import {IPLTargetInfo} from "./PomLimitManagementDialog";
const projectDivRenderer = (props: ICellRendererParams) => {
    return props.data.projectDiv === 'STORE' ? '스토어' : '펀딩';
}
interface targetInfo {
    projectDiv: string;
    project2Id: string;
    pomLimitValue: number;
}
const PLTargetGrid = (props: any) => {
    const costRenderer = (e: any) => (e.data.pomLimitValue === 0 || e.data.pomLimitValue === undefined ? '-' : e.data.pomLimitValue.toLocaleString());
    const gridRef = useRef<AgGridReact>(null);
    const [rowData, setRowData] = useState<targetInfo[]>([]);
    const [columnDefs] = useState<ColDef[]>([
        {
            field: 'projectDiv',
            headerName: '프로젝트 유형',
            cellClass: 'ag-left-aligned-cell',
            width: 100,
            cellRenderer: projectDivRenderer
        },
        {
            field: 'project2Id',
            headerName: '프로젝트 번호',
            cellClass: 'ag-left-aligned-cell',
            width: 50
        },
        {
            field: 'pomLimitValue',
            headerName: '한도액(단위: 만원)',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
            width: 100,
            cellRenderer: costRenderer
        }
    ]);

    const onGridReady = () => {
        ApiUtil2.get<IPLTargetInfo>('/api/bzmAdmin/postpaidMoneyManagement/PL/targetInfo?projectConfigId='+props.projectConfigId
        ).then((resp) => {
            let recentData = resp.data.data[0];
            const arr: targetInfo[] = [{projectDiv : recentData.projectDiv, project2Id : recentData.project2Id, pomLimitValue : recentData.pomLimitValue}];
            setRowData(arr);
        });
    };

    const defaultColDef = useMemo<ColDef>(() => ({
        flex: 1,
        resizable: false,
        suppressMovable: true,
        sortable: true,
    }), []);

    useEffect(()=>{
        onGridReady()
    }, [])

    return (
        <Fragment>
            <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                onGridReady={() => onGridReady()}
                defaultColDef={defaultColDef}
                rowHeight={48}
                domLayout={'autoHeight'}
            />
            { rowData === undefined ? <NoDataTemplate/> : null}
        </Fragment>
    );
};

export default PLTargetGrid;
