import { axios } from '../axios'
import type { AuthorityType } from './AuthorityType'
import type { UserGroupType } from './UserGroupType'
export type MakerInfoType = {
  id: number //9892830
  loginId: string // 'eunjin.yeo+ad1@wadiz.kr'
  roleGroup: UserGroupType // 'USER_MAKER'
  authorities: AuthorityType[] //['ROLE_USER_MAKER', 'ROLE_LOGIN']
  memberName: string // 'ad1'
  msg?: string
}
type FunctionType = () => Promise<{ ok: boolean; data: MakerInfoType }>
export const fetchLoginCheckMakerLoginInfo: FunctionType = () => axios.get('/api/loginCheck/makerLoginInfo')
