import { ITooltipParams } from 'ag-grid-community';

// eslint-disable-next-line react/display-name
export default (props: ITooltipParams & { color: string }) => {
  return (
        <div className="ag-grid-tooltip">
            <p className="fz-12 fw-medium fc-0"> {props.value} </p>
        </div>
  );
};
