import React, { Fragment, useEffect, useState } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query' // try next react-query;
import { useNavigate, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { ApiUtil2 } from '@biz/api'
import alertify from 'alertifyjs'
import BzmMngCarAtrtDetPopUp from './dialog/BzmMngCarAtrtDetPopUp';
import BzmMngHistoryDialog from './dialog/BzmMngHistoryDialog';
import BzmMngExpireDialog from './dialog/BzmMngExpireDialog';
import BzmMngAtamDetPopUp from './dialog/BzmMngAtamDetPopUp';

interface IMngRefundDetail {
    data:any;
    accRefundId:number;
    makerId:number;
    makerLoginId:string;
    makerNm:string;
    makerStatus:string;
    refundStatus:string;
    refundAccountYn:boolean;
    bizDiv:string;
    userNo:number;
    sidNo:number;
    refundCostCard:number;
    refundCostAtrt:number;
    refundCostAtam:number;
    refundCostAdjust:number;
    refundCostCardOk:number;
    refundCostAtrtOk:number;
    refundCostCardOkAdmin:number;
    refundCostAtrtOkAdmin:number;
    refundCostAtamOk:number;
    refundCostAdjustOk:number;
    reqTime:string;
    updateTime:string;
    updateAdmin:string;
    hp:string;
    refundBank:string;
    refundAccNum:string;
    agreeTime:string;
    refundCardStatus:string;
    refundAtrtStatus:string;
    refundComment:string;
    ingComment:string;
    newIngComment:string
}
interface IRefundReject {
    ok:boolean;
    data:any;
    accRefundId:number;
    refundComment:string;
}
interface IRefund {
    ok:boolean;
    data:any;
    accRefundId:number;
    costDiv:string;
}
interface ISaveIngCommentReq {
    ok:boolean;
    data:any;
    refundAtamId : number;
    ingComment:string;
    makerId:number;
}
const MakerStatusMap:any = {
  IA: '휴면',
  NM: '정상',
  RA: '정상',
  DO: '탈퇴',
};
const RefundStatusMap:any = {
  req: '환불 신청',
  ing: '환불 진행중',
  rejected: '환불 거절',
  ok: '환불 완료',
};
const BzmMngDetail = () => {
  const setTitle = useOutletContext<any>();
  const navigate = useNavigate();
  const { accRefundId } = useParams();
  const [refundCostCard, setRefundCostCard] = useState('');
  const [refundCostAtrt, setRefundCostAtrt] = useState('');
  const [refundCostMng, setRefundCostMng] = useState('');
  const [refundCostTotal, setRefundCostTotal] = useState(0);
  const [refundCostCardOk, setRefundCostCardOk] = useState('');
  const [refundCostAtrtOk, setRefundCostAtrtOk] = useState('');
  const [refundCostMngOk, setRefundCostMngOk] = useState(0);
  const [refundCostTotalOk, setRefundCostTotalOk] = useState(0);
  const [refundCostDiv, setRefundCostDiv] = useState('');
  // 다이얼로그 관련
  const [detDialog, setDetDialog] = useState<boolean>(false);
  const [detAdminDialog, setDetAdminDialog] = useState<boolean>(false);
  const [hisDialog, setHisDialog] = useState<boolean>(false);
  const [expireDialog, setExpireDialog] = useState<boolean>(false);
  const [detCostDiv, setDetCostDiv] = useState('');
  const {
    setValue, getValues, register,
  } = useForm<IMngRefundDetail>();
  const saveIngCommentForm = useForm<ISaveIngCommentReq>();

  // 비즈머니 환불 상세내역 조회
  const getBzmMngRefundInfo = async () => {
    const { data } = await ApiUtil2.get<IMngRefundDetail>('/api/bzmAdmin/mng/detail', { params: { data: { accRefundId } } });
    return data;
  };

  const goRefundListEvent = () => {
    const commnet = document.getElementById('ingCommentArea') as HTMLInputElement;
    const comentValue = getValues('ingComment') === undefined ? '' : getValues('ingComment');
    if (comentValue !== commnet.value) {
      alertify.confirm('저장하지 않으시고 이전 화면으로 이동 하시겠습니까?', () => {
        navigate('/bzmAdmin/mng');
      }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('특이사항 항목에 변경하신 내용이 있습니다.');
    } else {
      navigate('/bzmAdmin/mng');
    }
  };
  const getBzmMngRefundInfoApi = useQuery(['getBzmMngRefundInfo'], getBzmMngRefundInfo, {
    enabled: false,
  })

  const getRefundDetailInfo = () => {
    getBzmMngRefundInfoApi.refetch().then((r) => {
      if (r.isSuccess) {
        const refund = r.data.data
        setValue('accRefundId', refund.accRefundId)
        setValue('makerId', refund.makerId)
        setValue('makerNm', refund.makerNm)
        setValue('makerLoginId', refund.makerLoginId)
        setValue('makerStatus', refund.makerStatus)
        setValue('refundStatus', refund.refundStatus)
        setValue('refundAccountYn', refund.refundAccountYn)
        setValue('bizDiv', refund.bizDiv)
        setValue('sidNo', refund.sidNo)
        setValue('userNo', refund.userNo)
        // 환불 신청 금액 관련
        setValue('refundCostCard', refund.refundCostCard)
        setValue('refundCostAtrt', refund.refundCostAtrt)
        setValue('refundCostAtam', refund.refundCostAtam)
        setValue('refundCostAdjust', refund.refundCostAdjust)
        setRefundCostCard(refund.refundCostCard.toLocaleString())
        setRefundCostAtrt(refund.refundCostAtrt.toLocaleString())
        setRefundCostMng((refund.refundCostAtam + refund.refundCostAdjust).toLocaleString())
        setRefundCostTotal(
          refund.refundCostCard + refund.refundCostAtrt + refund.refundCostAtam + refund.refundCostAdjust
        )
        // 환불 진행 금액 관련
        setValue('refundCostCardOk', refund.refundCostCardOk)
        setValue('refundCostAtrtOk', refund.refundCostAtrtOk)
        setValue('refundCostCardOkAdmin', refund.refundCostCardOkAdmin)
        setValue('refundCostAtrtOkAdmin', refund.refundCostAtrtOkAdmin)
        setValue('refundCostAtamOk', refund.refundCostAtamOk)
        setValue('refundCostAdjustOk', refund.refundCostAdjustOk)
        setRefundCostCardOk(refund.refundCostCardOk.toLocaleString())
        setRefundCostAtrtOk(refund.refundCostAtrtOk.toLocaleString())
        setRefundCostMngOk(
          refund.refundCostCardOkAdmin +
            refund.refundCostAtrtOkAdmin +
            refund.refundCostAtamOk +
            refund.refundCostAdjustOk
        )
        setRefundCostTotalOk(
          refund.refundCostCardOk +
            refund.refundCostAtrtOk +
            refund.refundCostAtamOk +
            refund.refundCostAdjustOk +
            refund.refundCostCardOkAdmin +
            refund.refundCostAtrtOkAdmin
        )
        setValue('reqTime', refund.reqTime)
        setValue('updateTime', refund.updateTime)
        setValue('updateAdmin', refund.updateAdmin)
        setValue('hp', refund.hp)
        setValue('refundBank', refund.refundBank)
        setValue('refundAccNum', refund.refundAccNum)
        setValue('ingComment', refund.ingComment)
        setValue('agreeTime', refund.agreeTime)
        setValue('refundCardStatus', refund.refundCardStatus)
        setValue('refundAtrtStatus', refund.refundAtrtStatus)
        // 진행 특이 사항 값 넣어주기
        if (refund.ingComment !== undefined) {
          const commnet = document.getElementById('ingCommentArea') as HTMLInputElement
          commnet.value = refund.ingComment
        }
      }
    })
  }
  const refundPgReq = async () => {
    const { data } = await ApiUtil2.post<IRefund>('/api/bzmAdmin/mng/refundPg', { accRefundId, costDiv: refundCostDiv })
    return data
  }
  const refundPgApi = useQuery(['refundPgReq'], refundPgReq, {
    enabled: false,
  })
  const refundPgApiEvent = () => {
    refundPgApi.refetch().then((r) => {
      if (r.isSuccess) {
        alertify.success(`정상적으로 ${r.data.data === 'card' ? ' 카드' : '실시간 계좌'} 환불이 완료되었습니다.`);
        getRefundDetailInfo();
      }
    }, (r) => {
      alertify.error(`${r.data.data === 'card' ? ' 카드' : '실시간 계좌'} 환불을 실패한 건이 있습니다.`);
    });
  };

  const refundAtamReq = async () => {
    const { data } = await ApiUtil2.post<IRefund>('/api/bzmAdmin/mng/refundAtam', { accRefundId });
    return data;
  };
  const refundAtamApi = useQuery(['refundAtamReq'], refundAtamReq, {
    enabled: false,
  })
  const refundAtamApiEvent = () => {
    refundAtamApi.refetch().then((r) => {
      if (r.isSuccess) {
        alertify.success('환불 진행이 완료되었습니다.');
        getRefundDetailInfo();
      }
    });
  };
  // 개인 정보 조회
  const confirmClickEvent = (e: any) => {
    if (e.target.id === 'hp') {
      alertify.confirm('조회하시려는 정보는 개인정보로 조회 시 시스템에 기록됩니다.', () => {
        ApiUtil2.get('/api/bzmAdmin/mng/getHp', { params: { data: { makerId: getValues('makerId') } } }).then((r:any) => {
          const hpTextHtml = document.getElementById('hpText') as HTMLInputElement;
          hpTextHtml.value = r.data.data.hp;
        });
      }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('메이커의 개인 정보를 조회하시겠습니까?');
    } else if (e.target.id === 'refundAccNum') {
      alertify.confirm('조회하시려는 정보는 개인정보로 조회 시 시스템에 기록됩니다.', () => {
        ApiUtil2.get('/api/bzmAdmin/mng/getRefundAccNum', { params: { data: { makerId: getValues('makerId') } } }).then((r:any) => {
          const refundAccInfoTextHtml = document.getElementById('refundAccInfoText') as HTMLInputElement;
          refundAccInfoTextHtml.value = `${getValues('refundBank')}  ${r.data.data.refundAccNum}`;
        });
      }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('메이커의 개인 정보를 조회하시겠습니까?');
    }
  };
  const openDetDialog = (e:any) => {
    setDetDialog(true);
    setDetCostDiv(e.currentTarget.value);
  };
  const openDetAdminDialog = () => {
    setDetAdminDialog(true);
  };
  //  환불 거절 사유 다이얼로그
  const rejectDialogOpen = () => {
    $('#refundRejectDialog').dialog({ width: 600, title: '환불 거절 사유', closeOnEscape: true });
    $('#refundRejectDialog').dialog('open');

    // 확인 버튼
    $('#submit').on('click', () => {
      const rejectReason = document.getElementById('rejectReason') as HTMLInputElement;
      if (rejectReason.value === null || rejectReason.value === undefined || rejectReason.value.trim() === '') {
        alertify.error('환불 거절 사유를 입력해 주세요.');
        return;
      }

      ApiUtil2.post<IRefundReject>('/api/bzmAdmin/mng/reject', {
        accRefundId, refundComment: rejectReason.value,
      }).then(() => {
        alertify.success('정상적으로 환불 거절을 진행 하였습니다.');
        $('#refundRejectDialog').dialog('close');
        getRefundDetailInfo();
      });
    });
  };

  const hisDialogOpen = () => {
    setHisDialog(true);
  };

  const expireDialogOpen = () => {
    setExpireDialog(true);
  };
  // 환불 관련
  const refundPg = (e:any) => {
    setRefundCostDiv(e.currentTarget.value);
    alertify.confirm('환불 진행 시 환불 이전 상태로 변경이 불가합니다.', () => {
      refundPgApiEvent();
    }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader(`${e.currentTarget.value === 'card' ? '카드' : '실시간 계좌 이체'} 환불을 진행 하시겠습니까?`);
  };
  const refundAtam = () => {
    alertify.confirm(`총 환불 잔여 금액 : ${(refundCostTotal - refundCostTotalOk).toLocaleString()} 원 <br>입금 완료 처리시 해당 환불 신청 건은 ’환불 완료’처리되며, <br> 이전 환불 진행 상태로 변경이 불가 합니다.`, () => {
      refundAtamApiEvent();
    }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('환불이 진행되지 않은 환불 신청 금액에 대해 입금 완료 처리를 하시겠습니까?');
  };
  // 메모 저장
  const saveIngCommentApi = () => {
    const {
      ingComment, makerId,
    } = saveIngCommentForm.getValues();
    const result = ApiUtil2.post<ISaveIngCommentReq>('/api/bzmAdmin/mng/saveIngComment', {
      accRefundId, ingComment, makerId,
    });
    return result;
  };
  const saveIngCommentApiEvent = useMutation(saveIngCommentApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 여기서 계속 수정 처리
        alertify.success('특이사항이 정상적으로 저장 되었습니다.');
        getRefundDetailInfo();
      }
    },
  });
  const saveIngComent = () => {
    const commnet = document.getElementById('ingCommentArea') as HTMLInputElement;
    setValue('newIngComment', commnet.value);
    saveIngCommentForm.setValue('ingComment', commnet.value);
    saveIngCommentForm.setValue('makerId', getValues('makerId'));
    saveIngCommentApiEvent.mutate();
  };
  useEffect(() => {
    setTitle('비즈머니 환불 상세내역');
    getRefundDetailInfo();
    return () => {
      setTitle('');
    };
  }, []);
  useEffect(() => {
    $('#close').click(() => {
      $('#refundRejectDialog').dialog('close');
    });
    return () => {
      // 페이지를 벗어나는 이동 시 destory 해줘야 한다. 다른 방법이 있는지 모름
      $('#refundRejectDialog').dialog('destroy');
    };
  }, []);

  useEffect(() => {
    if ((getValues('refundCostAtam') !== null && getValues('refundCostAtam') !== undefined && getValues('refundCostAtam') !== 0)
          || (getValues('refundCostAdjust') !== null && getValues('refundCostAdjust') !== undefined && getValues('refundCostAdjust') !== 0)) {
      let content = '';
      if ((getValues('refundCostAtam') !== null && getValues('refundCostAtam') !== undefined && getValues('refundCostAtam') !== 0)) {
        content = `무통장 입금 유상머니 : ${getValues('refundCostAtam').toLocaleString()} 원<br> `;
      }
      if ((getValues('refundCostAdjust') !== null && getValues('refundCostAdjust') !== undefined && getValues('refundCostAdjust') !== 0)) {
        content += `임의조정 유상머니 : ${getValues('refundCostAdjust').toLocaleString()} 원`;
      }
      $('#refundMngTooltip').tooltip({
        content: `<p class="fz-12 fc-0 fw-medium">${content}</p>`,
      });
    }

    // if ((getValues('refundCostAtamOk') !== null && getValues('refundCostAtamOk') !== undefined && getValues('refundCostAtamOk') !== 0)
    //       || (getValues('refundCostAdjustOk') !== null && getValues('refundCostAdjustOk') !== undefined && getValues('refundCostAdjustOk') !== 0)
    //       || (getValues('refundCostCardOkAdmin') !== null && getValues('refundCostCardOkAdmin') !== undefined && getValues('refundCostCardOkAdmin') !== 0)
    //       || (getValues('refundCostAtrtOkAdmin') !== null && getValues('refundCostAtrtOkAdmin') !== undefined && getValues('refundCostAtrtOkAdmin') !== 0)) {
    if (refundCostMngOk !== 0) {
      let content2 = '';
      if (getValues('refundCostCardOkAdmin') !== null && getValues('refundCostCardOkAdmin') !== undefined && getValues('refundCostCardOkAdmin') !== 0) {
        content2 = `카드 환불 실패: ${getValues('refundCostCardOk').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원 <br>`;
      }

      if (getValues('refundCostAtrtOkAdmin') !== null && getValues('refundCostAtrtOkAdmin') !== undefined && getValues('refundCostAtrtOkAdmin') !== 0) {
        content2 += `실시간 계좌 이체 환불 실패: ${getValues('refundCostCardOk').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원 <br>`;
      }

      if (getValues('refundCostAtamOk') !== null && getValues('refundCostAtamOk') !== undefined && getValues('refundCostAtamOk') !== 0) {
        content2 += `무통장 입금 유상머니: ${getValues('refundCostAtamOk').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원 <br>`;
      }
      if (getValues('refundCostAdjustOk') !== null && getValues('refundCostAdjustOk') !== undefined && getValues('refundCostAdjustOk') !== 0) {
        content2 += `임의조정 유상머니: ${getValues('refundCostAdjustOk').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원 <br>`;
      }

      $('#refundMngOkTooltip').tooltip({
        content: `<p class="fz-12 fc-0 fw-medium">${content2}</p>`,
      });
    }
    return () => {
      $('.tooltip').remove();
    };
  });
  return (
    <Fragment>
      {detDialog ? (
        <BzmMngCarAtrtDetPopUp setDetDialog={setDetDialog} accRefundId={accRefundId} costDiv={detCostDiv} />
      ) : null}
      {detAdminDialog ? <BzmMngAtamDetPopUp setDetAdminDialog={setDetAdminDialog} accRefundId={accRefundId} /> : null}
      {hisDialog ? <BzmMngHistoryDialog setHisDialog={setHisDialog} accRefundId={accRefundId} /> : null}
      {expireDialog ? (
        <BzmMngExpireDialog
          setExpireDialog={setExpireDialog}
          makerId={getValues('makerId')}
          showType="refund"
          accRefundId={accRefundId}
        />
      ) : null}
      <div className="wrap-section wrap-tbl">
        <div className="box-header">
          <div className="box-tit">
            <button type="button" className="btn btn-secondary-outline" onClick={goRefundListEvent}>
              이전 목록으로
            </button>
            {getValues('refundStatus') === 'req' ? (
              <button type="button" className="btn btn-danger-outline" onClick={rejectDialogOpen}>
                환불 거절
              </button>
            ) : null}
            {getValues('refundStatus') === 'req' || getValues('refundStatus') === 'ing' ? (
              <button type="button" className="btn btn-primary-outline" onClick={refundAtam}>
                환불 완료
              </button>
            ) : null}
          </div>
          <div className="box-option">
            <button type="button" className="btn btn-primary-outline" onClick={expireDialogOpen}>
              비즈머니 유효기간
            </button>
            <button type="button" className="btn btn-info-outline" onClick={hisDialogOpen}>
              히스토리
            </button>
          </div>
        </div>
        <div className="box-body">
          <div className="tbl">
            <dl className="vertical">
              <dd>
                <div className="form-group">
                  <div className="wrap-section wrap-tbl">
                    <div className="box-header">
                      <div className="box-tit">
                        <h2 className="fz-20 fc-1 fw-bold">메이커 계정정보</h2>
                      </div>
                    </div>
                    <div className="box-body">
                      <div className="tbl">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col col-3">
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">메이커ID</span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <div className="input-group expand">
                                      <div className="inner-input-group">
                                        <input
                                          type="text"
                                          className="tf-comm"
                                          value={getValues('makerLoginId') || '-'}
                                          disabled
                                        />
                                      </div>
                                      <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                    </div>
                                  </div>
                                </dd>
                              </dl>
                            </div>
                            <div className="col col-3">
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">메이커명</span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <div className="input-group expand">
                                      <div className="inner-input-group">
                                        <input
                                          type="text"
                                          className="tf-comm"
                                          value={getValues('makerNm') || '-'}
                                          disabled
                                        />
                                      </div>
                                      <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                    </div>
                                  </div>
                                </dd>
                              </dl>
                            </div>
                            <div className="col col-3">
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">충전 사업자 번호</span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <div className="input-group expand">
                                      <div className="inner-input-group">
                                        <input
                                          type="text"
                                          className="tf-comm"
                                          value={
                                            getValues('bizDiv') === 'user' ? getValues('sidNo') : getValues('userNo')
                                          }
                                          disabled
                                        />
                                      </div>
                                      <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                    </div>
                                  </div>
                                </dd>
                              </dl>
                            </div>
                            <div className="col col-3">
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">계정상태</span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <div
                                      className={
                                        getValues('makerStatus') === 'NM' || getValues('makerStatus') === 'RA'
                                          ? 'txt-state mint large'
                                          : 'txt-state red large'
                                      }
                                    >
                                      {MakerStatusMap[getValues('makerStatus')]}
                                    </div>
                                  </div>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wrap-section wrap-tbl">
                    <div className="box-header">
                      <div className="box-tit">
                        <h2 className="fz-20 fc-1 fw-bold">환불 정보</h2>
                      </div>
                    </div>
                    <div className="box-body">
                      <div className="tbl">
                        <dl className="column-two">
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">환불 상태</span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <div
                                className={
                                  getValues('refundStatus') === 'ok' ? 'txt-state mint large' : 'txt-state red large'
                                }
                              >
                                {RefundStatusMap[getValues('refundStatus')]}
                              </div>
                            </div>
                          </dd>
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">총 환불 진행 금액 / 신청 금액</span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <span className="comp-txt">
                                <span className="table">
                                  <span className="table-cell">
                                    <b className="fz-14 fc-2">{refundCostTotalOk.toLocaleString()} 원</b>
                                    <b className="fz-14 fc-2">/</b>
                                    <b className="fz-14 fc-2">{refundCostTotal.toLocaleString()} 원</b>
                                  </span>
                                </span>
                              </span>
                            </div>
                          </dd>
                        </dl>
                        <dl className="vertical column-three">
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">환불 진행 / 신청 금액 (카드)</span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <span className="comp-txt">
                                <span className="table">
                                  <span className="table-cell">
                                    <b className="fz-14 fc-2">{refundCostCardOk} 원</b>
                                    <b className="fz-14 fc-2">/</b>
                                    <b className="fz-14 fc-2">{refundCostCard} 원</b>
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div className="form-group">
                              {getValues('refundCostCard') !== null && getValues('refundCostCard') !== 0 ? (
                                <button
                                  type="button"
                                  className="btn btn-info small"
                                  value="card"
                                  onClick={openDetDialog}
                                >
                                  상세 내역
                                </button>
                              ) : null}

                              {getValues('refundCardStatus') !== null && getValues('refundCardStatus') === 'reg' ? (
                                <button type="button" className="btn btn-danger small" onClick={refundPg} value="card">
                                  환불 진행
                                </button>
                              ) : null}
                              {getValues('refundCardStatus') !== null && getValues('refundCardStatus') === 'fail' ? (
                                <button
                                  type="button"
                                  className="btn btn-secondary small"
                                  onClick={refundPg}
                                  value="card"
                                >
                                  환불 실패
                                </button>
                              ) : null}
                              {getValues('refundCardStatus') !== null && getValues('refundCardStatus') === 'ok' ? (
                                <button type="button" className="btn btn-primary small">
                                  환불 완료
                                </button>
                              ) : null}
                            </div>
                          </dd>
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">환불 진행 / 신청 금액 (실시간 계좌)</span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <span className="comp-txt">
                                <span className="table">
                                  <span className="table-cell">
                                    <b className="fz-14 fc-2">{refundCostAtrtOk} 원</b>
                                    <b className="fz-14 fc-2">/</b>
                                    <b className="fz-14 fc-2">{refundCostAtrt} 원</b>
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div className="form-group">
                              {getValues('refundCostAtrt') !== null && getValues('refundCostAtrt') !== 0 ? (
                                <button
                                  type="button"
                                  className="btn btn-info small"
                                  value="atrt"
                                  onClick={openDetDialog}
                                >
                                  상세 내역
                                </button>
                              ) : null}
                              {getValues('refundAtrtStatus') !== null && getValues('refundAtrtStatus') === 'reg' ? (
                                <button type="button" className="btn btn-danger small" onClick={refundPg} value="atrt">
                                  환불 진행
                                </button>
                              ) : null}
                              {getValues('refundAtrtStatus') !== null && getValues('refundAtrtStatus') === 'fail' ? (
                                <button
                                  type="button"
                                  className="btn btn-secondary small"
                                  onClick={refundPg}
                                  value="atrt"
                                >
                                  환불 실패
                                </button>
                              ) : null}
                              {getValues('refundAtrtStatus') !== null && getValues('refundAtrtStatus') === 'ok' ? (
                                <button type="button" className="btn btn-primary small">
                                  환불 완료
                                </button>
                              ) : null}
                            </div>
                          </dd>
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">환불 진행 / 신청 금액 (관리자 이체)</span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <span className="comp-txt">
                                <span className="table">
                                  <span className="table-cell">
                                    <b className="fz-14 fc-2">
                                      <Link to="#" id="refundMngOkTooltip" className="txt-tooltip tooltip-f" title="">
                                        {refundCostMngOk.toLocaleString()} 원
                                      </Link>
                                    </b>
                                    <b className="fz-14 fc-2">/</b>
                                    <b className="fz-14 fc-2">
                                      <Link to="#" id="refundMngTooltip" className="txt-tooltip tooltip-f" title="">
                                        {refundCostMng} 원
                                      </Link>
                                    </b>
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div className="form-group">
                              {(getValues('refundCostAtam') !== null && getValues('refundCostAtam') !== 0) ||
                              (getValues('refundCostAdjust') !== null && getValues('refundCostAdjust') !== 0) ? (
                                <button type="button" className="btn btn-info small" onClick={openDetAdminDialog}>
                                  상세 내역
                                </button>
                              ) : null}
                              {getValues('refundStatus') !== 'ok' ? (
                                <button type="button" className="btn btn-danger small" onClick={refundAtam}>
                                  입금완료 진행
                                </button>
                              ) : null}
                              {getValues('refundStatus') === 'ok' ? (
                                <button type="button" className="btn btn-primary small">
                                  입금 완료
                                </button>
                              ) : null}
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div className="wrap-section wrap-tbl">
                    <div className="box-header">
                      <div className="box-tit">
                        <h2 className="fz-20 fc-1 fw-bold">메이커 개인정보</h2>
                      </div>
                    </div>
                    <div className="box-body">
                      <div className="tbl">
                        <dl className="vertical column-two">
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">휴대전화 번호</span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <div className="input-group">
                                <div className="inner-input-group">
                                  <input
                                    type="text"
                                    className="tf-comm"
                                    id="hpText"
                                    value={
                                      getValues('agreeTime') === undefined ||
                                      (getValues('makerStatus') !== 'NM' && getValues('makerStatus') !== 'RA')
                                        ? '-'
                                        : getValues('hp')
                                    }
                                    disabled
                                  />
                                </div>
                                <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                              </div>
                              {/*ISMS2024-56 {
                                                            getValues('agreeTime') === undefined || (getValues('makerStatus') !== 'NM' && getValues('makerStatus') !== 'RA')
                                                              ? null : <button id="hp" type="button"
                                                                                 className="btn btn-primary-outline w-100"
                                                                                 onClick={confirmClickEvent}>상세 조회</button>
                                                        } */}
                            </div>
                          </dd>
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">환불 계좌 정보</span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <div className="input-group">
                                <div className="inner-input-group">
                                  <input
                                    type="text"
                                    className="tf-comm"
                                    id="refundAccInfoText"
                                    value={
                                      getValues('agreeTime') === undefined ||
                                      (getValues('makerStatus') !== 'NM' && getValues('makerStatus') !== 'RA')
                                        ? '-'
                                        : `${getValues('refundBank')} ${getValues('refundAccNum')}`
                                    }
                                    disabled
                                  />
                                </div>
                                <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                              </div>
                              {/*ISMS2024-56 {getValues('agreeTime') === undefined ||
                              (getValues('makerStatus') !== 'NM' && getValues('makerStatus') !== 'RA') ? null : (
                                <button
                                  id="refundAccNum"
                                  type="button"
                                  className="btn btn-primary-outline w-100"
                                  onClick={confirmClickEvent}
                                >
                                  상세 조회
                                </button>
                              )} */}
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div className="wrap-section wrap-tbl">
                    <div className="box-header">
                      <div className="box-tit">
                        <h2 className="fz-20 fc-1 fw-bold">추가 정보</h2>
                      </div>
                    </div>
                    <div className="box-body">
                      <div className="tbl">
                        <dl className="vertical">
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">진행 특이사항 (메모)</span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <div className="input-group expand">
                                <div className="inner-input-group">
                                  <textarea
                                    className="tf-textarea"
                                    placeholder="환불 진행 시 특이사항이 있다면 기입해 주세요. 400자 내로 기입이 가능합니다."
                                    maxLength={400}
                                    id="ingCommentArea"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                            <div className="form-group txt-right">
                              <button type="button" className="btn btn-primary" onClick={saveIngComent}>
                                저장
                              </button>
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      {
        <dialog>
          <div id="refundRejectDialog" className="dialog" style={{ display: 'none' }}>
            <div className="dialog-body">
              <div className="wrap-section wrap-tbl">
                <div className="box-body">
                  <div className="tbl">
                    <div className="form-group">
                      <dl>
                        <div className="input-group expand">
                          <textarea
                            className="tf-textarea large"
                            placeholder="환불 거절 사유를 입력해 주세요. 100자 내로 기입이 가능합니다."
                            {...register('refundComment')}
                            maxLength={100}
                            id="rejectReason"
                          ></textarea>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dialog-footer">
                <button id="close" type="button" className="btn btn-secondary-outline large">
                  취소
                </button>
                <button id="submit" type="button" className="btn btn-primary large">
                  확인
                </button>
              </div>
            </div>
          </div>
        </dialog>
      }
    </Fragment>
  )
};
export default BzmMngDetail;
