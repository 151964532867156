import React from 'react'
import { Modal } from 'antd'
import styles from './FailReasonInputModal.module.scss'

interface FailReasonModalProps {
  isOpen: boolean
  campId: number | undefined
  creationDataId: string | undefined
  isStopCreation?: boolean
  isLoading: boolean
  getPrevData: any
  handleConfirm: () => void
  handleClose: () => void
  handleChange: React.ChangeEventHandler<HTMLTextAreaElement>
}

/**
 * 소재 반려(중단) 사유 입력 모달 - v.신규
 */
export const FailReasonInputModal = ({
  isOpen,
  campId,
  creationDataId,
  isStopCreation,
  isLoading,
  handleConfirm,
  handleClose,
  handleChange,
}: FailReasonModalProps) => {
  const classNames = {
    content: styles.content,
    body: styles.body,
    header: styles.header,
    footer: styles.footer,
  }

  return (
    <Modal
      open={isOpen}
      title={`소재 ${isStopCreation ? '중단' : '반려'} 사유를 입력해 주세요`}
      width={900}
      className={styles.container}
      classNames={classNames}
      destroyOnClose
      closable
      centered
      okText={`${isStopCreation ? '중단' : '반려'}하기`}
      cancelText="취소"
      okButtonProps={{ loading: isLoading }}
      cancelButtonProps={{ loading: isLoading }}
      onOk={() => handleConfirm?.()}
      onCancel={handleClose}
    >
      <form>
        <input id="campId" type="hidden" defaultValue={campId!!} />
        <input id="creationDataId" type="hidden" defaultValue={creationDataId?.toString()} />
        <textarea
          className={styles.textArea}
          placeholder={`${isStopCreation ? '중단' : '반려'} 사유를 입력해 주세요.`}
          rows={15}
          onChange={handleChange}
        ></textarea>
      </form>
    </Modal>
  )
}
