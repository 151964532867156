import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {AgGridReact} from "ag-grid-react";
import NoDataTemplate from "../../../common/NoDataTemplate";
import {ColDef, ICellRendererParams} from "ag-grid-community";
import { ApiUtil2 } from '@biz/api'
export interface IPomInquiryHis {
    pomInquiryHisId: number;
    status: string;
    project2Id: string;
    updateTime: string;
    comment: string;
    modifier: string;
    data: any;
}
const tooltipRenderer = (props: ICellRendererParams) => {
    let comment = props.data.comment;
    let pomInquiryHisId = props.data.pomInquiryHisId;
    const tooltip = <div id={pomInquiryHisId}
                         style={{textOverflow: 'ellipsis',
                             whiteSpace: 'nowrap',
                             overflow: 'hidden',}}>{comment}</div>
    $('#'+`${pomInquiryHisId}`).tooltip({
        content: '<p class="fz-12 fc-0">'+comment+'</p>'
    });
    return (
        tooltip
    )
};
const PomInquiryProcessHistoryDialog = (props:any) => {
    const gridRef = useRef<AgGridReact>(null);

    const [rowData, setRowData] = useState([]);
    const [columnDefs] = useState<ColDef[]>([
        {
            field: 'status',
            headerName: '처리 상태',
            cellClass: 'ag-center-aligned-cell',
            width: 75,
        },
        {
            field: 'project2Id',
            headerName: '프로젝트 ID',
            cellClass: 'ag-center-aligned-cell',
            width: 75,
        },
        {
            field: 'updateTime',
            headerName: '상태 변경 일시',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-center-aligned-cell',

        },
        {
            field: 'comment',
            headerName: '변경 사유',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
            width: 200,
            cellRenderer: tooltipRenderer,
        },
        {
            field: 'modifier',
            headerName: '변경자',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-center-aligned-cell',
        }
    ]);
    const onGridReady = () => {
        ApiUtil2.get<IPomInquiryHis>('/api/bzmAdmin/postpaidMoneyManagement/inquiry/history?pomInquiryId='+props.pomInquiryId
        ).then((resp) => {
            setRowData(resp.data.data);
        });
    };
    const defaultColDef = useMemo<ColDef>(() => ({
        flex: 1,
        resizable: false,
        suppressMovable: true,
        sortable: true,
    }), []);

    useEffect(()=>{
        onGridReady()
    }, [])

    useEffect(() => {
        /** 다이얼로그 */
        $('#PomInquiryProcessHistoryDialog').dialog({
            title: '처리상태 히스토리', closeOnEscape: true, width: 900,
        });
        $(document).on('click', '#cancelBtn', () => {
            $('#PomInquiryProcessHistoryDialog').dialog('close');
            props.setIsHistoryDialogOpened(false);
            $('#PomInquiryProcessHistoryDialog').dialog('destroy');
        });
    }, []);
    return (
        <Fragment>
            <form>
                <div id='PomInquiryProcessHistoryDialog' className="dialog" style={{display: 'none'}}>
                    <div className="dialog-body">
                        <div className="wrap-section wrap-tbl">
                            <div className="box-body" style={{maxHeight: 500, overflow: 'auto'}}>
                                <div className="ag-grid">
                                    <div className="ag-grid-inner">
                                        <AgGridReact
                                            ref={gridRef}
                                            rowData={rowData}
                                            columnDefs={columnDefs}
                                            defaultColDef={defaultColDef}
                                            rowHeight={48}
                                            suppressPaginationPanel={true}
                                            domLayout={'autoHeight'}
                                        />
                                        { rowData === undefined ? <NoDataTemplate/> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dialog-footer">
                        <button type="button" className="btn btn-primary" id={'cancelBtn'}>확인
                        </button>
                    </div>
                </div>
            </form>
        </Fragment>
    );
};

export default PomInquiryProcessHistoryDialog;
