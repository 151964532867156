import { Modal, Button } from 'antd'
import { ReactComponent as InfoIcon } from '@common/svg/info.svg'
import styles from './UpdateConfirmModal.module.scss'

type UpdateConfirmModalFunction = {
  title: string
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  oldContent: string
  newContent: string
}
export const UpdateConfirmModal: React.FC<UpdateConfirmModalFunction> = ({
  isOpen,
  title,
  onClose,
  onConfirm,
  oldContent,
  newContent,
}) => {
  return (
    <Modal
      width={600}
      open={isOpen}
      closable={false}
      title={<div className={styles.modalTitle}>{title}</div>}
      footer={[
        <div className={styles.modalFooter}>
          <Button type="default" block size="large" onClick={onClose} key={'닫기'} style={{ width: 150 }}>
            {'취소'}
          </Button>
          <Button type="primary" block size="large" onClick={onConfirm} key={'확인'} style={{ width: 150 }}>
            {'확인'}
          </Button>
        </div>,
      ]}
      className={styles.containUpdateConfirmModal}
    >
      <div className={styles.customBody}>
        <ul>
          <li className={styles.infoList}>
            <InfoIcon />
            캠페인타겟이 아래와 같이 변경됩니다.
          </li>
          <li className={styles.subList}>
            <span>{'(기존) '}</span>
            <span>{oldContent}</span>
          </li>
          <li className={styles.subList}>
            <span>{'(변경) '}</span>
            <strong>{newContent}</strong>
          </li>
          <li className={styles.infoList}>
            <InfoIcon />
            캠페인타겟을 변경할 경우 머신러닝이 다시 시작됩니다.
          </li>
          <li className={styles.subList}>
            머신러닝에 대한 내용{' '}
            <a href="https://biz.wadiz.kr/makerCommon/faq#2983813489" target="_blank">
              FAQ
            </a>
            를 참고해 주세요.
          </li>
        </ul>
      </div>
    </Modal>
  )
}
