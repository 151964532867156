import { Button, Tooltip } from 'antd'
import alertify from 'alertifyjs'
import { type CreationViewStatusType, creationViewStatusTable } from '@biz/api'
import styles from './ReinspectionButton.module.scss'

const msgFn = (status: CreationViewStatusType, cb: () => void) => {
  alertify
    .confirm(creationViewStatusTable[status]?.updateMsg || '계속 진행하시겠습니까?', cb)
    .set({ labels: { cancel: '취소', ok: '신청' } })
    .setHeader('')
}
type ReinspectionButtonFunction = {
  status: CreationViewStatusType
  onInspection?: () => void
  disabled?: boolean
}
export const ReinspectionButton: React.FC<ReinspectionButtonFunction> = ({ status, onInspection, disabled }) => {
  const caption = creationViewStatusTable[status]?.cmd || '검수요청'

  return (
    <Tooltip placement="bottom" title={disabled ? '적어도 하나의 소재가 등록되어 있어야 합니다.' : ''}>
      <Button
        type="primary"
        size="small"
        disabled={disabled}
        className={styles.containReinspectionButton}
        onClick={() => {
          onInspection && msgFn(status, onInspection)
        }}
      >
        {caption}
      </Button>
    </Tooltip>
  )
}
