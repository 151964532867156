import { Form, Radio } from 'antd'
import { CampaignCaption, targetFormRegistry } from '@biz/ui'
import styles from './CampaignMedia.module.scss'

const { CampaignMediaName } = targetFormRegistry
export type CampaignMediaType = { [CampaignMediaName]: string }
export const CampaignMedia: React.FC = () => (
  <div>
    <CampaignCaption
      tooltip={
        <>
          <div className="fz-12 fc-0 fw-medium">{'광고를 노출할 매체를 선택해주세요.'}</div>
          <div className="fz-12 fc-0" style={{ textWrap: 'nowrap' }}>
            {'페이스북/인스타그램을 선택하시면 한번의 등록으로'}
          </div>
          <div className="fz-12 fc-0">{'페이스북 및 인스타그램에 동시 노출됩니다.'}</div>
        </>
      }
    >
      광고 노출 매체
    </CampaignCaption>
    <dd>
      <Form.Item name={CampaignMediaName}>
        <Radio.Group size="large" className={styles.radioCustom}>
          <Radio value="FBITG">{'페이스북/인스타그램'}</Radio>
          {/* <Radio value="FB">{'페이스북'}</Radio>
            <Radio value="ITG">{'인스타그램'}</Radio>
            <Radio value="GDN" disabled>
              {'구글 디스플레이 배너'}
            </Radio> */}
        </Radio.Group>
      </Form.Item>
    </dd>
  </div>
)
