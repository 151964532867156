import { useQuery, type UseQueryResult, type QueryClient } from '@tanstack/react-query'
import { legacyAPIErrorFilter, fetchCampaignInfo, type CampaignInfoType } from '@biz/api'
import { useCurrentCampaignIdQuery, loadCurrentCampaignId } from '../useCurrentCampaignIdQuery'

const queryKey = ['@biz/query/createTargetAd/useCampaignInfo']
const errorMsg = '캠페인 정보를 가져오지 못했습니다.'
type UseFunctionType = () => UseQueryResult<{ ok: boolean; data: CampaignInfoType }>
export const useCampaignInfoQuery: UseFunctionType = () => {
  const { data: campaignId } = useCurrentCampaignIdQuery()
  return useQuery({
    queryKey,
    queryFn: () => (!!campaignId ? legacyAPIErrorFilter(fetchCampaignInfo(campaignId), errorMsg) : Promise.resolve()),
    enabled: Boolean(campaignId),
  })
}

type LoadFunctionType = (queryClient: QueryClient) => () => Promise<{ ok: boolean; data: CampaignInfoType }>
export const loadCampaignInfo: LoadFunctionType = (queryClient) => async () => {
  const campaignId = await loadCurrentCampaignId(queryClient)()
  return await queryClient.fetchQuery({
    queryKey,
    queryFn: () =>
      !!campaignId ? legacyAPIErrorFilter(fetchCampaignInfo(campaignId), errorMsg) : Promise.reject(errorMsg),
  })
}

export const resetCampaignInfo = (queryClient: QueryClient) => {
  queryClient.setQueryData(queryKey, {})
}
