import { useCallback, useState, useEffect } from 'react'
import { Form } from 'antd'
import alertify from 'alertifyjs'
import { targetFormRegistry } from '@biz/ui'
import { useUsableCostQuery, useCampaignPayInfoQuery, useRemainAmountQuery } from '@biz/query'
import { useUserIdenticalQuery, usePaymentMutation } from './_queries'
import { PaymentSummaryModal } from './PaymentSummaryModal'
import { AIInspectionReconfirmationModal } from './AIInspectionReconfirmationModal'

type PaymentStepFunction = {
  isProcess: boolean
  payType: 'POST_PAYMENT' | 'BIZ_MONEY'
  onCancel: () => void
  onNextProcess: (props: { isSuccess: boolean; msg: string }) => void
  needAIInspection: () => boolean | undefined
}
export const PaymentStep: React.FC<PaymentStepFunction> = ({
  isProcess,
  payType,
  onCancel,
  onNextProcess,
  needAIInspection,
}) => {
  const { invalidateWithBizMoney: invalidateUsableCost } = useUsableCostQuery()
  const { refetch } = useCampaignPayInfoQuery(isProcess, payType)
  const { refetch: refetchAmount } = useRemainAmountQuery(isProcess, payType)
  const { data: userIdentical } = useUserIdenticalQuery(isProcess && payType === 'BIZ_MONEY')
  const { data: isUserIdentical } = userIdentical || {}

  const payment = usePaymentMutation()
  const doPayment = useCallback(() => {
    refetch().then((res) => {
      const project2Id = res?.data?.data?.project2Id
      if (!project2Id) {
        alertify.error('프로젝트 정보를 가져오지 못했습니다. 잠시후 다시 시도해주세요')
        onCancel()
        return
      }
      payment.mutateAsync({ project2Id, payType }).then((res) => {
        if (!res.ok) {
          const { msg, title } = (res.data || {}) as { msg: string; title: string }
          alertify.error(msg || '알수 없는 에러가 발생했습니다. 관리자에게 문의 해주세요', { title })
          return
        }
        if (payType === 'BIZ_MONEY') {
          invalidateUsableCost()
        }
        const { intervalValid, resultMsg } = res.data as { intervalValid: boolean; resultMsg: string }
        onNextProcess({ isSuccess: intervalValid, msg: resultMsg })
      })
    })
  }, [payType, refetch])

  const doConfirmPayment = useCallback(async () => {
    if (payType !== 'BIZ_MONEY') {
      doPayment()
      return
    }
    const res = await refetch()
    const campBudgetTotal2 = res?.data?.data?.campBudgetTotal2

    const resAmount = await refetchAmount()
    const currentCost = resAmount?.data?.currentCost

    if (!!currentCost && !!campBudgetTotal2 && currentCost < campBudgetTotal2) {
      alertify.error('보유 비즈머니가 부족합니다. <br> 비즈머니를 먼저 충전해주세요')
      onCancel()
      return
    }
    if (isUserIdentical !== false) {
      doPayment()
      return
    }
    alertify
      .confirm('충전 주체와 사용 주체가 다른 경우 와디즈는 불이익에 대하여 책임지지 않습니다.', doPayment)
      .set({ labels: { cancel: '취소', ok: '확인' } })
      .setHeader('선택한 프로젝트의 사업자번호와 비즈머니를 충전한 사업자번호가 다릅니다.')
  }, [payType, doPayment, refetch, refetchAmount])

  const [isOpenReconfirm, setOpenReconfirm] = useState(false)
  const doCheck = useCallback(() => {
    if (!needAIInspection()) {
      doConfirmPayment()
      return
    }
    setOpenReconfirm(true)
  }, [])

  return !isProcess ? null : (
    <>
      <PaymentSummaryModal
        payType={payType}
        isOpen={isProcess}
        onClose={onCancel}
        onConfirm={doCheck}
        isLoading={payment.isLoading}
      ></PaymentSummaryModal>
      <Form.Item dependencies={[targetFormRegistry.CampaignTotalName]}>
        {() => {
          refetch()
          return <></>
        }}
      </Form.Item>
      <AIInspectionReconfirmationModal
        isOpen={isOpenReconfirm}
        onCancel={() => {
          setOpenReconfirm(false)
        }}
        onNextProcess={() => {
          setOpenReconfirm(false)
          doConfirmPayment()
        }}
      />
    </>
  )
}
