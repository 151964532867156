import { axios } from '../axios'
import type { CampaignStatusType } from './campaignStatus'

export type CampaignInfoType = {
  abtEndYn: boolean // true
  abtStartDate: string //'20240912'
  abtUseYn: boolean //false
  accActYn: boolean //true
  actYn: boolean //true
  adminCampYn: boolean //false
  campBudgetTotal1: number //300000
  campDepBudget: number //0
  campDepBuff: number //0
  campDepFees: number //0
  campDepVat: number //0
  campEndTime: string //'202409141128'
  campGoalDiv: string //'CONVERSIONS'
  campHashNo: string //'20'
  campInspStartYn: boolean //false
  campNm: string //'임시_202409121126'
  campStartTime: string //'202409121128'
  campViewStatus: CampaignStatusType //'temp'
  creationDataCnt: number //1
  fixedBasicFees: number //1000
  id: number //238179033
  mediaDiv4: string // 'FBITG'
  payType: 'BIZ_MONEY' | 'POST_PAYMENT'
  projectFundingDiv: string //'NON'
  projectId: string
  snsActYn: boolean //true
  snsApiOkYn: boolean //true
  syncDirtyYn: boolean //false
  syncYn: boolean //false
  useYn: boolean //true
}

type ReturnType = {
  ok: boolean
  data: CampaignInfoType
}

type FunctionType = (campId: string | number) => Promise<ReturnType>
export const fetchCampaignInfo: FunctionType = (campId) => axios.get(`/api/camp/make02/getCampInfo?campId=${campId}`)
