import { axios } from '../axios'
import { CreationViewStatusType } from './creationViewStatusTable'

interface CampTime {
  date: {
    year: number
    month: number
    day: number
  }
  time: {
    hour: number
    minute: number
    second: number
    nano: number
  }
}

interface CreationData {
  id: number
  clickBtnDiv: string
  adTitle01: string
  adTitle02: string
  adDesc: string
  sortOrder: number
  validYn: boolean
  wabizInspStatus: string
  wabizInspReqDate?: CampTime
  actYn: boolean
  editStatus: string
}

export interface CreationByAdSet {
  id: number
  snsInspStatus: string
  syncYn: boolean
  creationViewStatus: CreationViewStatusType
  creationViewStatusDesc: string
  snsApiOkYn: boolean
  useYn: boolean
  catDiv: string
  catCode: string
  catCodeDesc: string
  wabizInspAdminId: string | null
  projectId: string
  campViewStatus: string
  campViewStatusDesc: string
  campId: number
  adSetId: number
  campNm: string
  mediaDiv4: string
  mediaDiv4Des: string
  mediaConfigSnsAdId: string
  campStartTime: CampTime
  campEndTime: CampTime
  campPeriodValid: boolean
  creationData: CreationData
  adSetOrder: 1 | 2 // 1: 논타겟 / 2: 리타겟
  creationDataId: string
  adTitle01: string
  adTitle02: string
  sortOrder: number
  snsAdId?: string // 메타 연동된 경우에만 존재
  snsAdSetId?: string // 메타 연동된 경우에만 존재
  adDesc: string
  adminCampYn: boolean
  actyn: boolean
  clickBtnDiv: string
  af: any[] // 타입이 명확하지 않아서 any[]로 설정
  orgHierarchy: any[] // 타입이 명확하지 않아서 any[]로 설정
}

interface ReturnType {
  ok: boolean
  data: CreationByAdSet[]
}

/**
 * 리타겟, 논타겟 광고세트 별 소재 데이터 조회
 */
export const fetchAdSetData = (campId: number): Promise<ReturnType> =>
  axios.get(`/api/camp/make02/fbCreationLinkSearch?campId=${campId}`)
