import { type ProjectInfoType } from '@biz/api'
import { type FundingType } from '../../FundingTypeItem/fundingType'
import { makeProjectNotice } from './makeProjectNotice'
import styles from './ProjectBrief.module.scss'

type ProjectBriefFunction = {
  fundingType: FundingType
  project: ProjectInfoType
}
export const ProjectBrief: React.FC<ProjectBriefFunction> = ({ fundingType, project }) => {
  const { title, period } = makeProjectNotice(fundingType, project)
  return project.projectDiv === 'FUNDING' ? (
    <ul className={styles.bottomContent}>
      <li>{title}</li>
      <li>{period}</li>
    </ul>
  ) : (
    <ul className={styles.bottomComment}>
      <li>{title}</li>
    </ul>
  )
}
