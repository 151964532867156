import { useQuery, useQueryClient, type UseQueryResult, type QueryClient } from '@tanstack/react-query'
import { fetchAdSetData, type CreationByAdSet } from '@biz/api'
import { useCurrentCampaignIdQuery } from '../useCurrentCampaignIdQuery'

/**
 * 리타겟, 논타겟 광고세트 별 소재 데이터 조회
 */
export const useCreationsByAdSetQuery = () => {
  const queryClient = useQueryClient()
  const { data: campaignId } = useCurrentCampaignIdQuery()
  const queryKey = ['@biz/query/createTargetAd/useCreationsByAdSetQuery', campaignId]

  const query = useQuery({
    queryKey,
    queryFn: () => {
      if (campaignId) {
        return fetchAdSetData(campaignId)
      }
    },
    select: (data) => {
      return data?.data
    },
    enabled: Boolean(campaignId),
    refetchOnMount: true,
  })

  const invalidateQuery = () => {
    queryClient.invalidateQueries(queryKey)
  }

  return {
    ...query,
    invalidateQuery,
  }
}
