import React, {
  Fragment, useCallback, useMemo, useRef, useState,
} from 'react';
import { Dialog } from 'rc-easyui';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import { ApiUtil2 } from '@biz/api'

interface IDepositHis{
    ok:boolean;
    data:any;
    atamStatusDesc:string;
    comment:string;
    userNo:string;
    bizDiv:string;
    sidNo:string;
    updateTime:string;
    updateId:string;
}
const updateTimeRenderer = (props: ICellRendererParams) => (dayjs(props.data.updateTime).format('YYYY-MM-DD HH:mm'));
const userNoRenderer = (props: ICellRendererParams) => (props.data.bizDiv === 'user' ? props.data.sidNo : props.data.userNo);
const BzmMngDepositHistoryDialog = (props:any) => {
  const dialogRef = useRef<any>();
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>([]);
  const [colDefs] = useState<ColDef[]>([
    {
      field: 'atamStatusDesc',
      headerName: '입금 상태',
    },
    {
      field: 'userNo',
      headerName: '지급된 충전 사업자 번호',
      cellRenderer: userNoRenderer,
    },
    {
      field: 'comment',
      headerName: '변경 내용',
    },
    {
      field: 'updateTime',
      headerName: '변경 일시',
      cellRenderer: updateTimeRenderer,
    },
    {
      field: 'updateId',
      headerName: '변경자',
    },
  ]);

  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: false,
    headerClass: 'ag-center-aligned-header',
    cellClass: 'ag-center-aligned-cell',
  }), []);

  const onGridReady = useCallback(() => {
    ApiUtil2.get<IDepositHis>('/api/bzmAdmin/mng/bzmMngDeposit/depositHisList', { params: { data: { accAtamId: props.accAtamId } } }).then((resp) => {
      setRowData(resp.data.data);
    });
  }, []);

  const closeEvent = () => {
    props.setHisDialog(false);
  };
  return (
        <Fragment>
            <form>
                <Dialog title="무통장 입금 히스토리" modal resizable shadow={false} ref={dialogRef} style={{ width: '1200px' }}>
                    <div className="dialog">
                        <div className="dialog-body">
                            <div className="comp-tab-content selected">
                                <section className="wrap-section wrap-datagrid">
                                    <div className="box-body">
                                        <div className="ag-grid">
                                            <div className="ag-grid-inner">
                                                <AgGridReact
                                                    ref={gridRef}
                                                    rowData={rowData}
                                                    columnDefs={colDefs}
                                                    defaultColDef={defaultColDef}
                                                    rowHeight={48}
                                                    domLayout={'autoHeight'}
                                                    onGridReady={onGridReady}
                                                ></AgGridReact>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                {/* Wrap-Datagrid : End */}
                            </div>
                            {/* Comp-Tab-Content : End */}
                        </div>
                        <div className="dialog-footer">
                            <button type="button" className="btn btn-primary large" onClick={closeEvent}>확인</button>
                        </div>
                    </div>
                </Dialog>
            </form>
        </Fragment>
  );
};

export default BzmMngDepositHistoryDialog;
