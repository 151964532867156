import { axios } from '../axios'

export type BizNumberInfo = {
  projectId: string // 'F28016'
  bizDiv: 'biz' | 'pom'
  userNo: string // '3395420998'
  accId: number // 1796905646
  free: number // 0
  paid: number // 1300000
  total: number // 1300000
}

export type BizNumberListInfo = {
  usingUserNo: BizNumberInfo
  changeable: false
  reason: string // '비즈머니 잔액 남음'
  all: BizNumberInfo[]
}

type ReturnType = {
  ok: boolean
  data: BizNumberListInfo
}

type FunctionType = () => Promise<ReturnType>
export const fetchBizNumberListInfo: FunctionType = () => axios.get('/api/bzm/common/findUserNos')
