export const Notification = () => (
  <ul>
    <li>{'비즈머니 충전은 카드 결제 / 계좌이체를 통해 가능합니다.'}</li>
    <li>{'충전한 비즈머니는 와디즈 비즈센터에서만 사용 가능하며, 다른 와디즈 서비스와 호환되지 않습니다.'}</li>
    <li>
      {
        '충전한 비즈머니가 모두 소진된 이후 추가 과금이 발생한 경우 마이너스 비즈머니가 발생할 수 있으며, 이에 대한 비용이 청구됩니다. 매월 마지막 날에 한 달 동안 사용한 유상 광고 금액에 대한 세금계산서가 발행됩니다.'
      }
    </li>
    <li style={{ listStyle: 'none' }}>{'(쿠폰금액 등 무상으로 지급된 비즈머니는 포함되지 않음.)'}</li>
    <li>{'비즈머니 충전에 대한 매출 전표는 부가세 신고 대상이 아니며 이에 대해 와디즈는 관여하지 않습니다.'}</li>
    <li>{'충전 주체와 사용 주체가 다른 경우 와디즈는 불이익에 대하여 책임지지 않습니다.'}</li>
    <li>
      {'결제에 문제가 있는 경우 하단 문서를 참조하세요'}
      <ul>
        <li>
          <a href="https://pgdownload.uplus.co.kr/banner/errguide/pc_errorguide.html" target="_blank" rel="noopener">
            {'https://pgdownload.uplus.co.kr/banner/errguide/pc_errorguide.html'}
          </a>
        </li>
      </ul>
    </li>
  </ul>
)

export const PurchaseReport = () => (
  <ul>
    <li>{'선택한 사업자번호를 기준으로 비즈머니가 관리됩니다.'}</li>
    <li>
      {'사업자번호는 다음의 조건이 충족되어야 변경이 가능합니다.'}
      <ul>
        <li>{'진행 중인 캠페인이 없어야 합니다. (종료 및 임시저장 상태의 캠페인만 있는 경우 변경 가능)'}</li>
        <li>{'진행 중인 광고 대행이 없어야 합니다. (종료 상태의 광고 대행만 변경 가능)'}</li>
        <li>{'비즈머니 잔액이 0원이어야 합니다.'}</li>
        <li>{'자동 충전이 해지되어야 합니다.'}</li>
      </ul>
    </li>
  </ul>
)

export const ChargeNotation = () => (
  <ul>
    <li>{'한개의 사업자번호만 활성화 할 수 있고, 나머지 사업자번호는 자동으로 비활성화 됩니다. '}</li>
    <li>{'활성 상태인 사업자번호에만 비즈머니 충전 및 광고 결제가 가능합니다.'}</li>
    <li>{'프로젝트를 만들 때 설정한 사업자번호가 아니더라도 광고를 결제할 수 있습니다.'}</li>
    <li>{"비즈머니 활성 상태 변경 및 자세한 안내는 ‘결제 관리' 메뉴에서 확인할 수 있습니다."}</li>
  </ul>
)
