import { axios } from '../axios'
import { type PaymentInfoType } from './fetchPayTypeCampaignInfo'

export type CampaignUpdateCheckParams = {
  campId?: string | number // campIdParam,
  campStartTime?: string // getValues('campStartTime'),
  campEndTime?: string // getValues('campEndTime'),
  campBudget?: number // getValues('campBudgetTotal1'),
  campUpdateDiv?: string // ßgetValues('campUpdateDiv'),
}
export type CampaignUpdateCheckInfoResultType = {
  afterCampBudgetTotal: number // 800000
  cost: number // -124300
  minCampBudget: number // 10000
  newCampBalance: number // 800000
  newCampBalanceDto: Partial<PaymentInfoType>
  oldCampBalance: number //1118700
  // 코드에만 있는 속성
  additionalCharge: boolean
  reducedBudget: number
  rptTotalCost: number
}

type ReturnType = {
  ok: boolean
  data: CampaignUpdateCheckInfoResultType
}

type FunctionType = (props: CampaignUpdateCheckParams) => Promise<ReturnType>
export const fetchCampaignUpdateCheck: FunctionType = ({
  campId,
  campStartTime,
  campEndTime,
  campBudget,
  campUpdateDiv,
}) =>
  axios.get(
    `/api/camp/make02/campUpdateCheck?campId=${campId}&campStartTime=${campStartTime}&campEndTime=${campEndTime}&campBudget=${campBudget}&campUpdateDiv=${campUpdateDiv}`
  )
