import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { nanoid } from 'nanoid'
import { useImageListQuery, type ImageMetaDataType } from '@biz/query'
import { MediaThumb } from './MediaThumb'
import styles from './ImageList.module.scss'

type ImageListFunction = {
  onSelect: (imageInfo: ImageMetaDataType) => void
}
export const ImageList: React.FC<ImageListFunction> = ({ onSelect }) => {
  const { data: imageList, isLoading } = useImageListQuery()
  return (
    <section className={styles.ImageList}>
      <div className={styles.container}>
        {isLoading ? (
          <div className={styles.spinContainer}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
          </div>
        ) : (
          <div className={styles.imageScrollBox}>
            {imageList?.map((n) => (
              <MediaThumb key={nanoid()} mediaData={n} onSelect={() => onSelect(n)} />
            ))}
          </div>
        )}
      </div>
    </section>
  )
}
