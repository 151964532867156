import { useMutation, useQueryClient } from '@tanstack/react-query'
import dayjs, { Dayjs } from 'dayjs'
import alertify from 'alertifyjs'
import { fetchPostInsertCampaign, fetchPostUpdateCampaign, fetchPostCampaign } from '@biz/api'
import { loadCurrentCampaignId, setCurrentCampaignId } from '../useCurrentCampaignIdQuery'

type CampaignParams = {
  campaignMedia: 'FBITG'
  campaignName: string
  projectSelect: string
  projectFundingDiv: string
  range: [Dayjs, Dayjs]
  totalCost: number
  fieldName: 'campName' | 'project' | 'campTime' | 'campBudget' // 캠페인명, 와디즈 프로젝트 선택, 캠페인 기간, 캠페인 총 예산
  campBudgetChange?: boolean
  cost?: number
}
export const useCampaignMutation = () => {
  // // 신규 API를 사용하는 코드
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({
      campaignMedia,
      campaignName,
      projectSelect,
      projectFundingDiv,
      range,
      totalCost,
      fieldName,
      campBudgetChange,
      cost,
    }: CampaignParams) => {
      const cachedCampaignId = await loadCurrentCampaignId(queryClient)()
      const campId =
        cachedCampaignId ||
        (await fetchPostCampaign({
          campNm: campaignName,
          campGoalDiv: 'CONVERSIONS',
          campStartTime: range[0].format('YYYYMMDDHHmm'),
          campEndTime: range[1].format('YYYYMMDDHHmm'),
          campBudgetTotal1: totalCost,
          campBudgetTotal2: 0,
          mediaDiv4: campaignMedia,
          projectId: projectSelect,
          adminCampYn: false,
        }).then((res) => {
          setCurrentCampaignId(queryClient)(res?.id || null)
          return res.id
        })) ||
        -1 // type guard
      return fetchPostUpdateCampaign({
        campId,
        // 처음 만들 때는 cachedCampaignId가 null이기 때문에 insert 명령을 호출한다.
        // 또한 다음 코드는 처음 만들때, 캠페인 명 오류로 인해 캠페인이 만들어지지 않는 문제가 있어서 넣어 둔다.
        campNm: cachedCampaignId === null ? `임시${dayjs().format('YYYYMMDDHHmm')}` : campaignName,
        campGoalDiv: 'CONVERSIONS',
        campStartTime: range[0].format('YYYYMMDDHHmm'),
        campEndTime: range[1].format('YYYYMMDDHHmm'),
        campBudgetTotal1: totalCost,
        field: fieldName,
        // campBudgetTotal2: 0,
        mediaDiv4: campaignMedia,
        projectFundingDiv, // ?
        projectId: projectSelect,
        adminCampYn: false,
        useYn: true,
        campBudgetChange,
        cost,
      }).then(
        (res) => {
          if (res.ok === true) {
            return true
          }
          const { msg, title } = res?.data as unknown as {
            msg: string
            title: string
          }
          alertify.error(msg || '알 수 없는 이유로 반영되지 않았습니다. 다시 반영해주세요.', {
            title,
          })
          return false
        },
        () => false
      )
    },
  })
  // // legacy api를 사용하는 코드
  // const queryClient = useQueryClient()
  // return useMutation({
  //   mutationFn: async ({
  //     campaignMedia,
  //     campaignName,
  //     projectSelect,
  //     projectFundingDiv,
  //     range,
  //     totalCost,
  //     fieldName,
  //     campBudgetChange,
  //     cost,
  //   }: CampaignParams) => {
  //     const cachedCampaignId = await loadCurrentCampaignId(queryClient)()
  //     const campId =
  //       cachedCampaignId ||
  //       (await fetchPostInsertCampaign().then((res) => {
  //         setCurrentCampaignId(queryClient)(res.data?.id || null)
  //         return res.data.id
  //       })) ||
  //       -1 // type guard
  //     return fetchPostUpdateCampaign({
  //       campId,
  //       // 처음 만들 때는 cachedCampaignId가 null이기 때문에 insert 명령을 호출한다.
  //       // 또한 다음 코드는 처음 만들때, 캠페인 명 오류로 인해 캠페인이 만들어지지 않는 문제가 있어서 넣어 둔다.
  //       campNm: cachedCampaignId === null ? `임시${dayjs().format('YYYYMMDDHHmm')}` : campaignName,
  //       campGoalDiv: 'CONVERSIONS',
  //       campStartTime: range[0].format('YYYYMMDDHHmm'),
  //       campEndTime: range[1].format('YYYYMMDDHHmm'),
  //       campBudgetTotal1: totalCost,
  //       field: fieldName,
  //       // campBudgetTotal2: 0,
  //       mediaDiv4: campaignMedia,
  //       projectFundingDiv, // ?
  //       projectId: projectSelect,
  //       adminCampYn: false,
  //       useYn: true,
  //       campBudgetChange,
  //       cost,
  //     }).then(
  //       (res) => {
  //         if (res.ok === true) {
  //           return true
  //         }
  //         const { msg, title } = res?.data as unknown as {
  //           msg: string
  //           title: string
  //         }
  //         alertify.error(msg || '알 수 없는 이유로 반영되지 않았습니다. 다시 반영해주세요.', {
  //           title,
  //         })
  //         return false
  //       },
  //       () => false
  //     )
  //   },
  // })
}
