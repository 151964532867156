import { useState } from 'react'
import { Modal, Button, Checkbox } from 'antd'
import type { CheckListInfo } from '../_queries'
import styles from './MakerCheckListModal.module.scss'

type MakerCheckListModalFunction = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  data?: CheckListInfo
}
export const MakerCheckListModal: React.FC<MakerCheckListModalFunction> = ({ isOpen, onClose, onConfirm, data }) => {
  const { categoryName, list } = data || {}
  const options = (list || []).map((item, index) => ({
    label: decodeURI(item.content),
    value: index,
  }))
  const [checked, setChecked] = useState<number[]>([])

  return (
    <Modal
      width={900}
      open={isOpen}
      closable={false}
      title={
        <div className={styles.modalTitle}>
          <span className={styles.strongTitle}>{categoryName}</span>
          <span>{' 카테고리에서 가장 많이 반려되는 사유입니다.'}</span>
        </div>
      }
      footer={[
        <div className={styles.modalFooter} key={'footer'}>
          <Button type="default" block size="large" onClick={onClose} style={{ width: 200 }}>
            {'수정하기'}
          </Button>
          <Button
            disabled={options.length !== checked.length}
            type="primary"
            block
            size="large"
            onClick={onConfirm}
            style={{ width: 200 }}
          >
            {'검수요청'}
          </Button>
        </div>,
      ]}
      className={styles.containMakerCheckListModal}
    >
      <div className={styles.customBody}>
        <Checkbox.Group options={options} onChange={setChecked} />
      </div>
    </Modal>
  )
}
