import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {AgGridReact} from "ag-grid-react";
import {ColDef, ICellRendererParams} from "ag-grid-community";
import NoDataTemplate from "../../../common/NoDataTemplate";
import { ApiUtil2 } from '@biz/api'
import {IPLTargetInfo} from "./PomLimitManagementDialog";
import {useForm} from "react-hook-form";

const PLProjectSearchDialog = (props: any) => {
    const {handleSubmit} = useForm();
    const gridRef = useRef<AgGridReact>(null);
    const projectDivRenderer = (props: ICellRendererParams) => {
        return props.data.projectDiv === 'STORE' ? '스토어' : '펀딩';
    }

    const projectSelectRenderer = (params: ICellRendererParams) => {
        $('.selectHandler').on('click', (e:any) => {
            props.setIsProjectSearchDialogOpened(false);
            props.setProject2Id.current = e.target.dataset.project2id;
            props.setProjectDiv.current = e.target.dataset.projectdiv;
        })
        return (
            <button className='selectHandler' data-project2id={params.data.project2Id} data-projectdiv={params.data.projectDiv}>선택</button>
        )
    }

    const [rowData, setRowData] = useState([]);
    const [columnDefs] = useState<ColDef[]>([
        {
            field: 'projectDiv',
            headerName: '프로젝트 유형',
            cellClass: 'ag-left-aligned-cell',
            width: 100,
            cellRenderer: projectDivRenderer
        },
        {
            field: 'project2Id',
            headerName: '프로젝트ID',
            cellClass: 'ag-left-aligned-cell',
            width: 100
        },
        {
            field: 'projectNm',
            headerName: '프로젝트 명',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
            width: 200,
        },
        {
            field: 'choice',
            headerName: '선택',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
            width: 50,
            cellRenderer: projectSelectRenderer
        }
    ]);

    const onGridReady = () => {
        ApiUtil2.get<IPLTargetInfo>('/api/bzmAdmin/postpaidMoneyManagement/PL/searchProject?project2Id=' + $('#project2Id_').val()
        ).then((resp) => {
            setRowData(resp.data.data);
        });
    };

    const defaultColDef = useMemo<ColDef>(() => ({
        flex: 1,
        resizable: false,
        suppressMovable: true,
        sortable: true,
    }), []);

    useEffect(() => {
        $('#projectSearchBtn').on('click', () => {
            onGridReady();
        });
        /** 다이얼로그 */
        $('#PLProjectSearchDialog').dialog({
            title: '프로젝트 ID 검색', closeOnEscape: true, width: 900,
        });
        $('#cancelBtn').on('click', () => {
            props.setIsProjectSearchDialogOpened(false);
        })

        return () => {
            $('#PLProjectSearchDialog').dialog('destroy');
        }
    }, [])

    return (
        <Fragment>
            <form onSubmit={handleSubmit(onGridReady)}>
                <div id="PLProjectSearchDialog" className="dialog" style={{display: 'none'}}>
                    <div className="dialog-body">
                        <div className="wrap-section wrap-tbl">
                            <div className="box-body">
                                <div className="tbl">
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    프로젝트 ID
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="inner-input-group">
                                                        <input type="input" className="tf-comm"
                                                               id="project2Id_"/>
                                                    </div>
                                                </div>
                                                <button type="button" className="btn btn-primary medium" id='projectSearchBtn'>ID검색</button>
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        <div className="wrap-section wrap-tbl">
                            <div className="box-body" style={{maxHeight: 500, overflow: 'auto'}}>
                                <div className="ag-grid">
                                    <div className="ag-grid-inner">
                                        <AgGridReact
                                            ref={gridRef}
                                            rowData={rowData}
                                            columnDefs={columnDefs}
                                            defaultColDef={defaultColDef}
                                            rowHeight={48}
                                            domLayout={'autoHeight'}
                                        />
                                        {rowData.length < 1 ? <NoDataTemplate/> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dialog-footer">
                        <button type="button" className="btn btn-secondary-outline" id={'cancelBtn'}>취소
                        </button>
                    </div>
                </div>
            </form>
        </Fragment>
    );
};

export default PLProjectSearchDialog;
