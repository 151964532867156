import React, {Fragment, useEffect} from 'react';

const PomNotQualifiedDialog = (props: any) => {
    useEffect(() => {
        /** 다이얼로그 */
        $('#PomNotQualifiedDialog').dialog({
            title: '불가사유', closeOnEscape: true, width: 550,
        });
        $(document).on('click', '#cancelBtn', () => {
            $('#PomNotQualifiedDialog').dialog('close');
            props.setIsDialogOpened(false);
            $('#PomNotQualifiedDialog').dialog('destroy');
        });
        return () => {
            $('#PomNotQualifiedDialog').dialog('destroy');
        };
    }, []);

    return (
        <Fragment>
            <form>
                <div id='PomNotQualifiedDialog' className="dialog" style={{display: 'none'}}>
                    <div className="dialog-body">
                        <span className="fz-16 fc-1">
                            <ul className="box-body" style={{ minHeight: 300 }}>
                                {props.resultData.data.map((item:any, idx:number) =>
                                    <li className="fz-16 fc-1" key={idx} dangerouslySetInnerHTML={ {__html: item} }>
                                    </li>
                                )}
                            </ul>
                        </span>
                    </div>
                    <div className="dialog-footer">
                        <button type="button" className="btn btn-secondary-outline" id={'cancelBtn'}>확인
                        </button>
                    </div>
                </div>
            </form>
        </Fragment>
    );
};

export default PomNotQualifiedDialog;