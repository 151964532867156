import { QueryClient, useQuery } from '@tanstack/react-query'
import { fetchMoneyInfo } from '@biz/api'
import { useCurrentCampaignIdQuery, loadCurrentCampaignId } from '../useCurrentCampaignIdQuery'

const queryKey = ['@apps/biz-center/targetCreatePage/RemainAmount']
export const useRemainAmountQuery = (isEnabled: boolean, payType?: 'BIZ_MONEY' | 'POST_PAYMENT') => {
  const { data: campaignId } = useCurrentCampaignIdQuery()
  return useQuery({
    queryKey,
    queryFn: () =>
      !!campaignId && !!payType ? fetchMoneyInfo(campaignId, payType).then((res) => res.data) : Promise.reject({}),
    refetchOnMount: 'always',
    enabled: isEnabled && Boolean(campaignId),
  })
}

export const loadRemainAmount = (queryClient: QueryClient) => async (payType: 'BIZ_MONEY' | 'POST_PAYMENT') => {
  const campaignId = await loadCurrentCampaignId(queryClient)()
  return await queryClient.fetchQuery({ queryKey, queryFn: () => fetchMoneyInfo(campaignId as number, payType) })
}
