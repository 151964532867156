import React, {Fragment} from 'react';
import {Link, useLocation} from "react-router-dom";

const PomStatusTab = () => {
    const url = useLocation();
    return (
        <Fragment>
            <div className="comp-tab">
                <Link to="/pom/status" className={url.pathname === '/pom/status' ? 'tab selected' : 'tab' }>
                    <span className="tab-item">현황</span>
                </Link>
                <Link to="/pom/pomInquiry" className={url.pathname === '/pom/pomInquiry' ? 'tab selected' : 'tab' }>
                    <span className="tab-item">한도 증액 신청 내역</span>
                </Link>
            </div>
        </Fragment>
    );
};

export default PomStatusTab;