import { Fragment, useEffect } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router'
import { useQuery, useQueryClient } from '@tanstack/react-query' // try next react-query;
import { useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useRoleQuery, useShadowLoginQuery } from '@biz/query'
import alertify from 'alertifyjs'
import { ApiUtil2 } from '@biz/api'

interface IMngMaker {
  data: any
  makerId: number
  makerLoginId: string
  makerNm: string
  userNo: string
  makerStatus: string
  makerStatusDesc: string
  basicFees: number
  refundYn: boolean
  privateVer: string
  termsVer: string
  agreeTime: string
  hp: string
  refundBank: string
  refundAccNum: string
  campAlertYn: boolean
  promotionAlertYn: boolean
  bizmoneyAlertYn: boolean
  nightAlertYn: boolean
}

const MngMakerAccountDetail = () => {
  const queryClient = useQueryClient()
  const { update: setShadowLogin } = useShadowLoginQuery()
  const { update: setAuth } = useRoleQuery()
  const navigate = useNavigate()
  const setTitle = useOutletContext<any>()
  const { makerId } = useParams()

  const { setValue } = useForm<IMngMaker>()

  const getPrevData = async () => {
    const promise = ApiUtil2.post<IMngMaker>('/api/mngAccount/maker/detail', { makerId: makerId })
    return promise
  }
  const makerDetail = useQuery(['makerDetail', makerId], getPrevData)

  const setPrevData = async () => {
    makerDetail.refetch().then((data: any) => {
      if (data.isSuccess) {
        const maker = data.data.data.data
        setValue('makerId', maker.id)
        setValue('makerLoginId', maker.projectId)
        setValue('makerNm', maker.makerNm)
        setValue('userNo', maker.userNo)
        setValue('makerStatus', maker.makerStatus)
        setValue('makerStatusDesc', maker.makerStatusDesc)
        setValue('basicFees', maker.basicFees === 'null' ? '-' : maker.basicFees)
        setValue('privateVer', maker.privateVer)
        setValue('termsVer', maker.termsVer)
        setValue('agreeTime', maker.agreeTime)
        setValue('hp', maker.hp)
        setValue('refundBank', maker.refundBank)
        setValue('refundAccNum', maker.refundAccNum)
        setValue('refundBank', maker.mediaDiv3Str)
        setValue('campAlertYn', maker.campAlertYn)
        setValue('promotionAlertYn', maker.promotionAlertYn)
        setValue('bizmoneyAlertYn', maker.bizmoneyAlertYn)
        setValue('nightAlertYn', maker.nightAlertYn)
      }
    })
  }

  const errorEvent = (msg: string) => {
    alertify.error(msg, 1)
  }

  const confirmError = (msg: string) => {
    errorEvent(msg)
  }

  const successEvent = (msg: string) => {
    alertify.success(msg, 1)
  }

  const confirmSuccess = (msg: string) => {
    successEvent(msg)
  }

  function regexFunc(value: any) {
    const regex = /[^-.0-9]/g // 숫자와 .만가능
    const pattern = /^\d*(\.\d{0,1})?$/ // 소수점 1째자리까지인가?
    if (regex.test(value)) {
      confirmError('기본 정률 수수료는 0~100 사이의 숫자를 입력해 주세요. \n 소수점 한자리까지 입력 가능합니다.')
      $('#basicFees').val('')
    }
    if (!pattern.test(value)) {
      confirmError('기본 정률 수수료는 0~100 사이의 숫자를 입력해 주세요. \n 소수점 한자리까지 입력 가능합니다.')
      $('#basicFees').val('')
    }
    return value
  }

  const confirmClickEvent = (e: any) => {
    if (e.target.id === 'hp') {
      alertify
        .confirm('조회하시려는 정보는 개인정보로 조회 시 시스템에 기록됩니다.', () => {
          ApiUtil2.get('/api/mngAccount/maker/detail/getHp', { params: { data: { makerId } } }).then((resp) => {
            $('#hpDetail').val(resp.data.data.hp)
          })
        })
        .set({ labels: { cancel: '취소', ok: '확인' } })
        .setHeader('메이커의 개인 정보를 조회하시겠습니까?')
    }
    if (e.target.id === 'acc') {
      alertify
        .confirm('조회하시려는 정보는 개인정보로 조회 시 시스템에 기록됩니다.', () => {
          ApiUtil2.get('/api/mngAccount/maker/detail/getRefundAccNum', { params: { data: { makerId } } }).then(
            (resp) => {
              $('#accDetail').val(`${resp.data.data.refundBank} ${resp.data.data.refundAccNum}`)
            }
          )
        })
        .set({ labels: { cancel: '취소', ok: '확인' } })
        .setHeader('메이커의 개인 정보를 조회하시겠습니까?')
    }
  }

  useEffect(() => {
    setTitle('메이커 계정정보')
    setPrevData()
    return () => {
      setTitle('')
    }
  }, [])

  useEffect(() => {
    $('#basicFees').on('change', () => {
      regexFunc($('#basicFees').val())
    })
  }, [$('#basicFees').val()])

  return (
    <Fragment>
      <div className="wrap-section wrap-tbl">
        <div className="box-header">
          <div className="box-tit">
            <Link to="/mngAccount/maker">
              <button type="button" className="btn btn-secondary-outline">
                이전 목록으로
              </button>
            </Link>
          </div>
          <div className="box-option">
            {makerDetail?.data?.data.data.makerStatus === 'NM' || makerDetail?.data?.data.data.makerStatus === 'RA' ? (
              <button
                type="button"
                className="btn btn-primary-outline"
                onClick={() => {
                  alertify
                    .confirm('해당 메이커 계정으로 쉐도우 로그인을 진행 하시겠습니까?', () => {
                      ApiUtil2.get(`/api/shadowLogin/login?makerId=${makerId}`, { params: { data: { makerId } } }).then(
                        (response) => {
                          alertify.alert('쉐도우 로그인', '쉐도우 로그인성공')
                          // 쉐도우 로그인 관련 기본 세팅을 진행한다.
                          setShadowLogin(true)
                          setAuth('ROLE_USER_MAKER')
                          localStorage.setItem('shadow', JSON.stringify(response.data))
                          $('#shadowLogoutBtn').show()
                          queryClient.invalidateQueries(['menus'])
                          queryClient.invalidateQueries(['subMenus'])
                          navigate('/main')
                        }
                      )
                    })
                    .set({ labels: { cancel: '취소', ok: '확인' } })
                    .setHeader('')
                }}
              >
                쉐도우 로그인
              </button>
            ) : null}
          </div>
        </div>
        <div className="box-body">
          <div className="tbl">
            <dl className="vertical">
              <dd>
                <div className="form-group">
                  <div className="wrap-section wrap-tbl">
                    <div className="box-header">
                      <div className="box-tit">
                        <h2 className="fz-20 fc-1 fw-bold">메이커 계정정보</h2>
                      </div>
                    </div>
                    <div className="box-body">
                      <div className="tbl">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col col-3">
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">메이커ID</span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <div className="input-group expand">
                                      <div className="inner-input-group">
                                        <input
                                          type="text"
                                          className="tf-comm"
                                          value={makerDetail?.data?.data.data.makerLoginId}
                                          disabled
                                        ></input>
                                      </div>
                                      <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                    </div>
                                  </div>
                                </dd>
                              </dl>
                            </div>
                            <div className="col col-3">
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">메이커명</span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <div className="input-group expand">
                                      <div className="inner-input-group">
                                        <input
                                          type="text"
                                          className="tf-comm"
                                          value={makerDetail?.data?.data.data.makerNm}
                                          disabled
                                        ></input>
                                      </div>
                                      <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                    </div>
                                  </div>
                                </dd>
                              </dl>
                            </div>
                            <div className="col col-3">
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">충전 사업자 번호</span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <div className="input-group expand">
                                      <div className="inner-input-group">
                                        <input
                                          type="text"
                                          className="tf-comm"
                                          value={
                                            makerDetail?.data?.data.data.userNo === 'null'
                                              ? '-'
                                              : makerDetail?.data?.data.data.userNo
                                          }
                                          disabled
                                        ></input>
                                      </div>
                                      <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                    </div>
                                  </div>
                                </dd>
                              </dl>
                            </div>
                            <div className="col col-3">
                              <dl className="vertical">
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">계정상태</span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <div className="txt-state mint large">
                                      {makerDetail?.data?.data.data.makerStatusDesc}
                                    </div>
                                  </div>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wrap-section wrap-tbl">
                    <div className="box-header">
                      <div className="box-tit">
                        <h2 className="fz-20 fc-1 fw-bold">설정 및 약관 동의정보</h2>
                      </div>
                    </div>
                    <div className="box-body">
                      <div className="tbl">
                        <dl className="vertical column-two">
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">기본 수수료</span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <div className="input-group">
                                <div className="inner-input-group">
                                  <NumberFormat
                                    id="basicFees"
                                    className="tf-comm tf-unit"
                                    step={0.1}
                                    value={
                                      makerDetail?.data?.data.data.basicFees === 'null'
                                        ? ''
                                        : makerDetail?.data?.data.data.basicFees
                                    }
                                    thousandSeparator={false}
                                    onChange={(e: any) => {
                                      setValue('basicFees', Number(e.target.value))
                                    }}
                                  />
                                  <span className="fz-14 fc-1">%</span>
                                </div>
                                <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                              </div>
                              <button
                                type="button"
                                className="btn btn-primary w-100"
                                onClick={() => {
                                  ApiUtil2.post('/api/mngAccount/maker/detail/updateFees', {
                                    makerId: Number(makerId),
                                    afterBasicFees: Number($('#basicFees').val()),
                                  }).then(() => {
                                    confirmSuccess(
                                      '정상적으로 수정 되었습니다. \n 변경된 수수료는 변경 시점 후 \n 생성되는 캠페인에 한하여 적용됩니다.'
                                    )
                                  })
                                }}
                              >
                                저장
                              </button>
                            </div>
                          </dd>
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">약관 동의정보</span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <p className="comp-txt">
                                <span className="table">
                                  <span className="table-cell">
                                    {makerDetail?.data?.data.data.agreeTime !== undefined &&
                                    makerDetail?.data?.data.data.agreeTime !== 'null' ? (
                                      <>
                                        <b className="fz-14 fc-2">
                                          개인정보 처리방침 {makerDetail?.data?.data.data.privateVer} /광고 서비스
                                          이용약관 {makerDetail?.data?.data.data.termsVer}
                                        </b>
                                        <b className="fz-14 fc-2">
                                          약관 동의 일시: {makerDetail?.data?.data.data.agreeTime}
                                        </b>
                                      </>
                                    ) : (
                                      <b className="fz-14 fc-2">광고 서비스 약관에 동의하지 않음</b>
                                    )}
                                  </span>
                                </span>
                              </p>
                            </div>
                          </dd>
                        </dl>
                        <dl className="vertical">
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">알림 수신 설정 정보</span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <div className="comp-checkbox block">
                                <input
                                  type="checkbox"
                                  id="inp-check-01"
                                  checked={
                                    makerDetail?.data?.data.data.agreeTime !== 'null'
                                      ? makerDetail?.data?.data.data.campAlertYn
                                      : false
                                  }
                                  disabled={true}
                                ></input>
                                <label htmlFor="inp-check-01">캠페인 알림 수신</label>
                              </div>
                              <div className="comp-checkbox block">
                                <input
                                  type="checkbox"
                                  id="inp-check-02"
                                  checked={
                                    makerDetail?.data?.data.data.agreeTime !== 'null'
                                      ? makerDetail?.data?.data.data.promotionAlertYn
                                      : false
                                  }
                                  disabled={true}
                                ></input>
                                <label htmlFor="inp-check-02">프로모션 알림 수신</label>
                              </div>
                              <div className="comp-checkbox block">
                                <input
                                  type="checkbox"
                                  id="inp-check-03"
                                  checked={
                                    makerDetail?.data?.data.data.agreeTime !== 'null'
                                      ? makerDetail?.data?.data.data.bizmoneyAlertYn
                                      : false
                                  }
                                  disabled={true}
                                ></input>
                                <label htmlFor="inp-check-03">비즈머니 충전 알림 수신</label>
                              </div>
                              <div className="comp-checkbox block">
                                <input
                                  type="checkbox"
                                  id="inp-check-04"
                                  checked={
                                    makerDetail?.data?.data.data.agreeTime !== 'null'
                                      ? makerDetail?.data?.data.data.nightAlertYn
                                      : false
                                  }
                                  disabled={true}
                                ></input>
                                <label htmlFor="inp-check-04">야간 알림 수신 (21시 ~ 익일 8시)</label>
                              </div>
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div className="wrap-section wrap-tbl">
                    <div className="box-header">
                      <div className="box-tit">
                        <h2 className="fz-20 fc-1 fw-bold">메이커 개인정보</h2>
                      </div>
                    </div>
                    <div className="box-body">
                      <div className="tbl">
                        <dl className="vertical column-two">
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">휴대전화 번호</span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <div className="input-group">
                                <div className="inner-input-group">
                                  <input
                                    id="hpDetail"
                                    type="text"
                                    className="tf-comm"
                                    value={
                                      makerDetail?.data?.data.data.agreeTime === undefined ||
                                      makerDetail?.data?.data.data.makerStatus === 'NONE' ||
                                      makerDetail?.data?.data.data.hp === '-'
                                        ? ''
                                        : makerDetail?.data?.data.data.hp
                                    }
                                    disabled
                                  ></input>
                                </div>
                                <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                              </div>
                              {/*ISMS2024-57 {makerDetail?.data?.data.data.agreeTime === undefined ||
                              makerDetail?.data?.data.data.makerStatus !== ('NM' || 'RA') ||
                              makerDetail?.data?.data.data.hp === '-' ? null : (
                                <button
                                  id="hp"
                                  type="button"
                                  className="btn btn-primary-outline w-100"
                                  onClick={confirmClickEvent}
                                >
                                  상세 조회
                                </button>
                              )} */}
                            </div>
                          </dd>
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">환불 계좌 정보</span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <div className="input-group">
                                <div className="inner-input-group">
                                  <input
                                    id="accDetail"
                                    type="text"
                                    className="tf-comm"
                                    value={`${
                                      makerDetail?.data?.data.data.refundBank === undefined ||
                                      makerDetail?.data?.data.data.makerStatus !== 'NM' ||
                                      makerDetail?.data?.data.data.makerStatus !== 'RA'
                                        ? '환불계좌 미등록'
                                        : makerDetail?.data?.data.data.refundBank
                                    } ${
                                      makerDetail?.data?.data.data.refundBank === undefined ||
                                      makerDetail?.data?.data.data.makerStatus !== 'NM'
                                        ? ''
                                        : makerDetail?.data?.data.data.refundAccNum
                                    }`}
                                    disabled
                                  ></input>
                                </div>
                                <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                              </div>
                              {/*ISMS2024-57 {makerDetail?.data?.data.data.refundBank === undefined ||
                              makerDetail?.data?.data.data.makerStatus !== ('NM' || 'RA') ? null : (
                                <button
                                  id="acc"
                                  type="button"
                                  className="btn btn-primary-outline w-100"
                                  onClick={confirmClickEvent}
                                >
                                  상세 조회
                                </button>
                              )} */}
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default MngMakerAccountDetail
