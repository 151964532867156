import { Modal, Button } from 'antd'
import { thousandsGrouping } from '@common/utils'
import styles from './ChargeCompleteModal.module.scss'

type ChargeCompleteModalFunction = {
  isOpen: boolean
  onClose: () => void
  amount: number
  userNo: string
  remain: number
}

export const ChargeCompleteModal: React.FC<ChargeCompleteModalFunction> = ({
  isOpen,
  onClose,
  amount,
  userNo,
  remain,
}) => {
  return (
    <Modal
      width={560}
      open={isOpen}
      onCancel={onClose}
      title={<h2 className={styles.title}>{'비즈머니 충전 완료'}</h2>}
      footer={[
        <Button type="primary" size="large" onClick={onClose} key={'확인'}>
          {'확인'}
        </Button>,
      ]}
    >
      <section className={styles.description}>
        <div className={styles.field}>
          <div>{'사업자번호'}</div>
          <div>{userNo}</div>
        </div>
        <div className={styles.field}>
          <div>{'충전 비즈머니'}</div>
          <div>{`${thousandsGrouping(amount)}원`}</div>
        </div>
        <div className={styles.field}>
          <div>{'비즈머니 잔여 금액'}</div>
          <strong>{`${thousandsGrouping(remain)}원`}</strong>
        </div>
      </section>
    </Modal>
  )
}
