import React, {
  Fragment, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { useForm } from 'react-hook-form';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import qs from 'qs';
import { DataFormat } from 'select2';
import BzmTab from './BzmTab';
import NoDataTemplate from '../common/NoDataTemplate';
import Pagination from '../common/Pagination';
import { ApiUtil2 } from '@biz/api'
import alertify from 'alertifyjs'
import { IMultiSelect, multiSelectHandler, toMultiSelect } from '../accAdjust/AccAdjustTargetUtil';
import BzmMngMakerCouponDialog from './dialog/BzmMngMakerCouponDialog';
import SingleSelect2 from '../accAdjust/SingleSelect2';
import DlDialog from "../mngMakerAccount/DlDialog";

interface IBzmMngMakerSearchForm {
    makerStatus:string | undefined;
    agreeYn:boolean;
    liveYn:boolean;
    srchType: string;
    keyword?: string;
}
interface IBzmMngMaker {
    data:any;
    makerId:number;
}
const makerStatusData = {
  all: { text: '전체', selected: true },
  NM: { text: '정상', selected: true },
  IA: { text: '휴면', selected: true },
  DO: { text: '탈퇴', selected: true },
};
const MakerStatusMap:any = {
  IA: '휴면',
  NM: '정상',
  RA: '정상',
  DO: '탈퇴',
};
// 렌더러 모음
const costRenderer = (props: ICellRendererParams) => {
  if (props.value === 0 || props.value === undefined || props.value === null || props.value === '') return '-';
  return Number(props.value).toLocaleString();
};
const makerStatusRenderer = (props: ICellRendererParams) => (MakerStatusMap[props.data.makerStatus]);
const agreeYnRenderer = (props: ICellRendererParams) => {
  if (props.data.agreeYn === null || props.data.agreeYn === undefined) {
    return '-';
  } if (props.data.agreeYn === true) {
    return '동의';
  }
  return '미동의';
};
const liveYnRenderer = (props: ICellRendererParams) => (props.data.liveYn === true ? '활성' : '비활성');
const userNoRenderer = (props: ICellRendererParams) => (props.data.userNo !== undefined ? props.data.userNo : '-');
const makerDetailRenderer = (props: ICellRendererParams) => {
  const { makerId } = props.data;
  let userNo = '';
  let projectId = 0;

  if (props.data.bizDiv === 'user') userNo = props.data.sidNo;
  else userNo = props.data.userNo;

  if (props.data.projectId !== undefined) projectId = props.data.projectId;
  const param = `${userNo}_${projectId}`;

  return <Link className="txt-link" to={`/bzmAdmin/mng/mngMaker/detail/${makerId}/${param}`}>{props.data.makerLoginId}</Link>;
};
const BzmMngMaker = () => {
  const setTitle = useOutletContext<any>();
  const childRef = useRef<any>(null);
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>();
  const [makerStatus, setMakerStatus] = useState<IMultiSelect>(toMultiSelect(makerStatusData));
  const [makerStatusLabel, setMakerStatusLabel] = useState<string>('전체');
  const [couponMakerIds, setCouponMakerIds] = useState<any[]>();
  const [couponTitle, setCouponTitle] = useState('');
  const [couponDialog, setCouponDialog] = useState<boolean>(false);
  const {
    register, handleSubmit, getValues, setValue,
  } = useForm<IBzmMngMakerSearchForm>();

  const [isDlDialogOpened, setIsDlDialogOpened] = useState<boolean>(false);
  const [dlParam, setDlParam] = useState<any>(null);

  const updateMakerStatusForm = (value: string) => setValue('makerStatus', value);
  const updateMakerStatusLabel = (label: string) => setMakerStatusLabel(label);
  const updateMakerStatus = (newState: Map<string, boolean>) => setMakerStatus({ ...makerStatus, selects: newState });

  // 다운로드
  const onBtnExport = useCallback(() => {
    setDlParam({
      ...getValues(),
    });
    setIsDlDialogOpened(true);
  }, []);

  const onGridReady = useCallback(() => {
    const data : IBzmMngMakerSearchForm = getValues();
    ApiUtil2.get<IBzmMngMaker>('/api/bzmAdmin/mng/bzmMngMaker/bzmMakerList', {
      params: {
        data: { ...data },
      },
    }).then((resp) => {
      setRowData(resp.data.data);
    });
  }, []);
    // 데이터그리드 관련
  const changePagination = () => {
        childRef.current!.onPaginationChanged();
  };
  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'makerLoginId',
      headerName: '메이커 ID',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellRenderer: makerDetailRenderer,
      minWidth: 300,
    },
    {
      field: 'makerNm',
      headerName: '메이커명',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'makerStatus',
      headerName: '계정 상태',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: makerStatusRenderer,
    },
    {
      field: 'agreeYn',
      headerName: '약관동의',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: agreeYnRenderer,
    },
    {
      field: 'userNo',
      headerName: '충전 사업자 번호',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: userNoRenderer,
    },
    {
      field: 'liveYn',
      headerName: '활성여부',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: liveYnRenderer,
    },
    {
      field: 'costPaid',
      headerName: '현재 보유 잔액 (유상)',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: costRenderer,
    },
    {
      field: 'costFree',
      headerName: '현재 보유 잔액 (쿠폰)',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: costRenderer,
    },
    {
      field: 'campBalancePaid',
      headerName: '예치금 (유상)',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: costRenderer,
    },
    {
      field: 'campBalanceFree',
      headerName: '예치금 (쿠폰)',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: costRenderer,
    },
    {
      field: 'returnableCostPaid',
      headerName: '환급 가능 금액 (유상)',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: costRenderer,
    },
    {
      field: 'returnableCostFree',
      headerName: '환급 가능 금액 (무상)',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: costRenderer,
    },
  ]);
  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: true,
  }), []);

  // 쿠폰 지급 관련
  const couponEvent = () => {
    const selectedRows = gridRef.current!.api.getSelectedRows();
    const checkedCount = selectedRows.length;
    if (checkedCount === 0) {
      alertify.error('쿠폰을 지급할 메이커ID를 선택 후 다시 시도해 주세요.');
      return;
    }
    const makerIds = selectedRows.map((checkedData:IBzmMngMaker) => checkedData.makerId);
    // 중복 제거
    const uniqueMakerIds = new Set(makerIds);
    const newMakerIds:any[] = [];
    uniqueMakerIds.forEach((uniqueMakerId) => newMakerIds.push(uniqueMakerId));
    setCouponMakerIds(newMakerIds);

    let couponDialogTitle = '';
    if (newMakerIds.length > 1) {
      couponDialogTitle = `${selectedRows[0].makerNm} 외 ${newMakerIds.length - 1} 개 계정`;
    } else {
      couponDialogTitle = selectedRows[0].makerNm;
    }
    setCouponTitle(couponDialogTitle);
    setCouponDialog(true);
  };
  const MakerStatusSelectBox = () => (
        <div className="dropdown-menu expand">
            <ul className="opt-selectbox" onClick={(e:any) => e.stopPropagation()} onKeyUp={(e:any) => e.handleKeyUp} role="presentation">
                {
                    Array.from(makerStatus.texts, ([key, value]) => ({ key, value }))
                      .map(({ key, value }) => {
                        const checkboxId = `ms-cb-${key}`;
                        return <li className="opt-menu" key={key}>
                                <div className="comp-checkbox small">
                                    <input name="makerStatus" type="checkbox"
                                           id={checkboxId}
                                           onChange={() => 0}
                                           onClick={(e: any) => { multiSelectHandler(key, e.target.checked, makerStatus, updateMakerStatus, updateMakerStatusLabel, updateMakerStatusForm); }}
                                           checked={makerStatus.selects.get(key) === true}/>
                                    <label htmlFor={checkboxId}>{value}</label>
                                </div>
                            </li>;
                      })
                }
            </ul>
        </div>
  );
  const agreeYnData:DataFormat[] = [{ id: 'all', text: '전체', selected: true }, { id: 'true', text: '동의' }, { id: 'false', text: '미동의' }];
  const updateAgreeYn = (value: any) => {
    setValue('agreeYn', value);
  };
    // 활성 여부
  const liveYnData:DataFormat[] = [{ id: 'all', text: '전체', selected: true }, { id: 'true', text: '활성' }, { id: 'false', text: '비활성' }];
  const updateLiveYn = (value: any) => {
    setValue('liveYn', value);
  };

  const srchTypeData:DataFormat[] = [{ id: 'MAKER_ID', text: '메이커 ID', selected: true }, { id: 'MAKER_NM', text: '메이커명' }, { id: 'USER_NO', text: '충전 사업자 번호' }];
  const updateSrchType = (value: any) => {
    setValue('srchType', value);
  };

  useEffect(() => {
    setTitle('비즈머니 관리');
    return () => {
      setTitle('');
    };
  }, []);

  useEffect(() => {
    const noDataGrid = (document.querySelector<HTMLElement>('.ag-root-wrapper-body.ag-focus-managed.ag-layout-auto-height')! as any);
    const noTreeDataGrid2 = (document.querySelector<HTMLElement>('.ag-root-wrapper.ag-ltr.ag-layout-auto-height')! as any);
    const noDataTempleteArea = (document.querySelector<HTMLElement>('.ag-overlay-wrapper.ag-layout-auto-height.ag-overlay-no-rows-wrapper')! as any);
    if (noDataGrid !== null) {
      noDataGrid.style.height = '548px';
    }
    if (noTreeDataGrid2 !== null) {
      noTreeDataGrid2.style.height = '548px';
    }
    if (noDataTempleteArea !== null) {
      noDataTempleteArea.style.height = '548px';
    }
  }, []);

  return (
        <Fragment>
            {
                couponDialog ? <BzmMngMakerCouponDialog setCouponDialog={setCouponDialog} couponMakerIds={couponMakerIds} couponTitle={couponTitle} afterEvent = {onGridReady}/> : null
            }
            <BzmTab/>
            <section className="wrap-section wrap-datagrid">
                <form onSubmit={handleSubmit(onGridReady)}>
                    <div className="wrap-filter">
                        <div className="inner-filter">
                            <div className="box-left">
                                <div className="item-filter">
                                    <div className="filter-tit">
                                        <p className="fz-12 fc-2">계정 상태</p>
                                    </div>
                                    <div className="box-filter">
                                        <div id="makerStatus" className="comp-dropdown select2 w-150 selected">
                                            <a href="#javascript" className="dropdown-toggle" data-toggle="dropdown">
                                                <div className="box-left">
                                                    <span className="fz-14">{makerStatusLabel}</span>
                                                </div>
                                                <div className="box-right">
                                                    <i className="ico ico-arrow"></i>
                                                </div>
                                            </a>
                                            <MakerStatusSelectBox/>
                                        </div>
                                    </div>
                                </div>
                                <div className="item-filter">
                                    <div className="filter-tit">
                                        <p className="fz-12 fc-2">약관 동의 여부</p>
                                    </div>
                                    <div className="box-filter">
                                        <SingleSelect2 data={agreeYnData} selectEvent={updateAgreeYn} />
                                    </div>
                                </div>
                                <div className="item-filter">
                                    <div className="filter-tit">
                                        <p className="fz-12 fc-2">활성 여부</p>
                                    </div>
                                    <div className="box-filter">
                                        <SingleSelect2 data={liveYnData} selectEvent={updateLiveYn} />
                                    </div>
                                </div>
                                <div className="item-filter">
                                    <div className="filter-tit">
                                        <p className="fz-12 fc-2">검색 구분</p>
                                    </div>
                                    <div className="box-filter">
                                        <SingleSelect2 data={srchTypeData} selectEvent={updateSrchType} />
                                    </div>
                                </div>
                                <div className="item-filter">
                                    <div className="filter-tit">
                                        <p className="fz-12 fc-2">검색어</p>
                                    </div>
                                    <div className="box-filter">
                                        <div className="input-group comp-search">
                                            <div className="inner-input-group">
                                                <input type="text" className="tf-comm" placeholder="검색어를 입력해 주세요." {...register('keyword')}></input>
                                                <i className="ico ico-search"></i>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-right">
                                <button type="submit" className="btn btn-tertiary-mint btn-ico"><i
                                    className="ico ico-filter"></i>필터 조회
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="box-header">
                    <div className="box-tit">
                        <button type="button" className="btn btn btn-primary" onClick={couponEvent}>쿠폰 지급</button>
                    </div>
                    <div className="box-option">
                        <button type="button" className="btn btn btn-secondary-outline btn-ico"onClick={onBtnExport}><i
                            className="ico ico-download"></i>다운로드
                        </button>
                    </div>
                </div>
                <div className="box-body">
                    <div className="ag-grid">
                        <div className="ag-grid-inner">
                            <AgGridReact
                                ref={gridRef}
                                rowData={rowData}
                                suppressRowClickSelection={true}
                                rowSelection={'multiple'}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                rowHeight={48}
                                pagination={true}
                                paginationPageSize={10}
                                suppressPaginationPanel={true}
                                onPaginationChanged={changePagination}
                                domLayout={'autoHeight'}
                                noRowsOverlayComponent={NoDataTemplate}
                            />
                            { rowData === undefined ? <NoDataTemplate/> : null}
                        </div>
                    </div>
                </div>
                <div className="box-footer">
                    <Pagination gridRef={gridRef} ref={childRef}/>
                </div>
            </section>
            {
              isDlDialogOpened ?
                <DlDialog setIsDlDialogOpened={setIsDlDialogOpened}
                          dlUrl={'/api/bzmAdmin/mng/bzmMngMaker/download'}
                          dlParam={dlParam}
                /> : null
            }
        </Fragment>

  );
};
export default BzmMngMaker;
