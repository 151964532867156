import { axios } from '../axios'

// https://wadiz.atlassian.net/wiki/spaces/TF/pages/16487514113/....+-+API
type RequestData = {
  // campId long 선택 캠페인 아이디
  campNm: string // string 필수 캠페인명 기본값: 임시_202408081222 (임시_yyyyMMddHHss)
  campGoalDiv: string // string 필수 캠페인 목표 기본값: CONVERSIONS  // NONE("NONE", "") / CONVERSIONS("전환", "OUTCOME_SALES") / LINK_CLICKS("트래픽", "OUTCOME_TRAFFIC")
  campStartTime: string //  string 필수 캠페인시작시간 기본값: 현재시간 yyyyMMddHHmm
  campEndTime: string //  string 필수 캠페인종료시간 기본값: 시작일로 부터 2일 후 yyyyMMddHHmm
  campBudgetTotal1: number //  string 필수 캠페인 예산 300000
  campBudgetTotal2: 0 // string 선택 기본값: 0
  mediaDiv4: 'FBITG' //  string 필수 고정값 FBITG
  projectId: string //  string 필수 프로젝트 아이디 (와디즈 펀딩/스토어 아이디) F##### or S#####
  adminCampYn: false // string 선택 어드민용 캠페인 여부 N
}

type CampaignMakeInfo = {
  abtEndYn: boolean // true
  abtStartDate: string // '20240920'
  abtUseYn: boolean // false
  accActYn: boolean // true
  actYn: boolean // true
  adminCampYn: boolean // false
  budgetDiv: string // 'camp'
  campBudgetTotal1: number // 300000
  campBudgetTotal2: number // 0
  campDepBudget: number // 0
  campDepBuff: number // 0
  campDepVat: number // 0
  campDepFees: number // 0
  campEndTime: string // '202409221551'
  campGoalDiv: string // 'CONVERSIONS'
  campInspStartYn: boolean // false
  campNm: string // '임시_202409201517'
  campStartTime: string // '202409201551'
  campViewStatus: string // 'temp'
  fixedBasicFees: number // 1000
  id: number // 251804304
  mediaDiv4: string // 'FBITG'
  projectFundingDiv: string // 'NON'
  snsActYn: boolean // true
  snsApiOkYn: boolean // true
  syncDirtyYn: boolean // false
  syncYn: boolean // false
  useYn: boolean // true
}

// type ResponseType = {
//   ok: boolean
//   data: Partial<CampaignMakeInfo>
// }

type FunctionType = (props: RequestData) => Promise<Partial<CampaignMakeInfo>>
export const fetchPostCampaign: FunctionType = (props) => axios.post('/api/maker/campaign', props)
