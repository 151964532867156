import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getAIInspectionHistory } from '@biz/api'

/**
 * AI 소재 검수 결과 조회
 * (메이커용은 legacy API 사용. 추후 이 API로 통합 필요)
 */
export const useAIInspectionHistoryQuery = (creationDataId: string | number, type: 'admin' | 'maker') => {
  const queryClient = useQueryClient()
  const queryKey = [`@biz/query/aiInspection/useAIInspectionHistory/${creationDataId}`]

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const response = await getAIInspectionHistory(creationDataId, type)

      if (response.ok === false) {
        throw new Error()
      }
      return response
    },
    enabled: !!creationDataId && type === 'admin',
    refetchOnMount: true,
    select: (response) => response.data,
  })

  const invalidateQuery = () => queryClient.invalidateQueries({ queryKey })

  return { ...query, invalidateQuery }
}
