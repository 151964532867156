import { loadTossPayments } from '@tosspayments/payment-sdk'
import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import alertify from 'alertifyjs'
import { fetchPostPaymentReady, fetchPostPaymentSuccess, fetchPostPaymentFail, type PaymentProps } from '@biz/api'

// CATION 이 쿼리는 반드시 로컬에서 사용할 것으로 판단하고, 일반적인 작명을 했다. 만약 이동하려면, 이름 변경이 필요하다.
type FunctionType = () => UseMutationResult<unknown, unknown, PaymentProps>
export const usePaymentMutation: FunctionType = () =>
  useMutation({
    mutationFn: async (props) => {
      try {
        const readyRes = await fetchPostPaymentReady(props)
        if (!readyRes?.ok || !readyRes?.data) {
          !!readyRes?.msg && alertify.error(readyRes?.msg)
          return Promise.reject(new Error(readyRes?.msg || 'Failed to retrieve the PG information'))
        }
        const amount = props.cost
        const { clientKey, orderId, orderName /*successUrl, failUrl*/ } = readyRes.data
        const tossPayments = await loadTossPayments(clientKey)
        const resultPayment = await tossPayments.requestPayment(props.txDiv === 'bi001' ? '카드' : '계좌이체', {
          amount,
          orderId,
          orderName,
          // successUrl: `${window.location.origin}/${successUrl}`,
          // failUrl: `${window.location.origin}/${failUrl}`,
        })
        const checkSuccess = await fetchPostPaymentSuccess(resultPayment)
        if (checkSuccess !== 'ok' && checkSuccess?.ok !== true) {
          throw new Error(checkSuccess?.data?.title || '알수없는 에러')
        }
      } catch (err) {
        console.error(err)
        await fetchPostPaymentFail()
        throw err
      }
    },
  })
