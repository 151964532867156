import axios from 'axios'

import { baseOptions } from '../axios/baseOptions'

const BlobApiUtil = axios.create({
  ...baseOptions,
  headers: { 'Content-Type': 'application/json' },
})
BlobApiUtil.interceptors.request.use(
  (config) => ({ ...config, responseType: 'blob' }),
  (error) => Promise.reject(error)
)
BlobApiUtil.interceptors.response.use(
  (response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    const fileName = decodeURI(
      response.headers['content-disposition'].split('fileName=')[1].replaceAll('"', '').replaceAll(';', '')
    )

    link.href = url
    link.setAttribute('download', fileName) // any other extension
    document.body.appendChild(link)
    link.click()

    return response
  },
  (error) => Promise.reject(error)
)

export { BlobApiUtil }
