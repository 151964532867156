import { useQuery, useQueryClient, type QueryClient } from '@tanstack/react-query'
import type { AuthorityType } from '@biz/api'

const queryKey = ['@biz/query/auth/Role']
export const setRole = (queryClient: QueryClient) => (role: AuthorityType) => queryClient.setQueryData(queryKey, role)
export const useRoleQuery = () => {
  const queryClient = useQueryClient()
  return {
    ...useQuery({ queryKey, queryFn: () => 'ROLE_USER_MAKER' }),
    update: setRole(queryClient),
  }
}
