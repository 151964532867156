import { useCallback, forwardRef, useImperativeHandle } from 'react'
import { Form, Input } from 'antd'
import { useCampaignInfoQuery, useCampaignMutation, useCampaignStatus } from '@biz/query'
import { CampaignCaption, targetFormRegistry } from '@biz/ui'
import styles from './CampaignName.module.scss'

type ItemMethod = { forceUpdate: () => void }
const regex = /^[가-힣a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`]+$/
const { CampaignNameOfName, ProjectSelectorName } = targetFormRegistry
export type CampaignNameType = { [CampaignNameOfName]: string }
export const CampaignName = forwardRef<ItemMethod>((props, ref) => {
  const { isFinished } = useCampaignStatus()
  const { data: campaignInfoData } = useCampaignInfoQuery()
  const { data: campaignInfo } = campaignInfoData || {}
  const makeCampaign = useCampaignMutation()
  const form = Form.useFormInstance()
  const doChangeSelect = useCallback(() => {
    const selectProjectId = form.getFieldValue(ProjectSelectorName)
    if (!!selectProjectId) {
      makeCampaign.mutateAsync({ ...form.getFieldsValue(), fieldName: 'campName' }).then((isSuccess) => {
        if (!isSuccess) {
          form.setFieldValue(CampaignNameOfName, campaignInfo?.campNm)
          form.validateFields()
          return
        }
      })
    }
  }, [makeCampaign, form])

  useImperativeHandle(
    ref,
    () => ({
      forceUpdate: () => {
        doChangeSelect()
      },
    }),
    [doChangeSelect]
  )
  return (
    <div className={styles.container}>
      <CampaignCaption>캠페인명</CampaignCaption>
      <dd>
        <Form.Item
          name={CampaignNameOfName}
          rules={[
            { required: true, message: '캠페인명을 입력해주세요' },
            {
              pattern: regex,
              message: '한글, 영문, 숫자 및 일반 특수문자만 입력 가능합니다.',
            },
            {
              min: 1,
              max: 100,
              message: '입력은 1자 이상 100글자 이하여야 합니다.',
            },
          ]}
        >
          <Input onBlur={doChangeSelect} disabled={isFinished?.()} />
        </Form.Item>
      </dd>
    </div>
  )
})
