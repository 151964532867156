import React from 'react'
import { ToolBox } from '../ToolBox'
import { GenderSelector } from './GenderSelector'
import { LocationSelector } from './LocationSelector'
import { AgeRangePicker } from './AgeRangePicker'
import { AdSwitchGroup } from './AdSwitchGroup'
import styles from './TargetInfoTab.module.scss'

type TargetInfoTabProps = {
  onPrev: () => void
  onNext: () => void
}
const TargetInfoTabImpl: React.FC<TargetInfoTabProps> = ({ onPrev, onNext }) => (
  <section className={styles.TargetInfoTab}>
    <AdSwitchGroup />
    <dl className={styles.dualColumn}>
      <GenderSelector />
      <LocationSelector />
    </dl>
    <dl className={styles.dualColumn}>
      <AgeRangePicker />
    </dl>
    <ToolBox onPrev={onPrev} onNext={onNext} />
  </section>
)

export const TargetInfoTab = React.memo(TargetInfoTabImpl)
