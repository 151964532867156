import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useOutletContext, useParams } from 'react-router'
import { useQuery } from '@tanstack/react-query' // try next react-query;
import BoardDivSelectBox from './BoardDivSelectBox'
import alertify from 'alertifyjs'
import { ApiUtil, ApiUtil2, ApiUtil3 } from '@biz/api'
import { INoticeDetail } from './NoticeForAdmin'
import CkEditor5 from '../../ckEditor5/CkEditor5'

export interface IPost {
  title: string | undefined
  text: string | undefined
  boardDiv: any
  sortOrder: string | undefined | number
  useYn: any
  popYn: boolean | undefined
  popEndDate: string | undefined
  afMeta: string | undefined
  filePath: string | undefined
}

const today = new Date()
const dd = String(today.getDate()).padStart(2, '0')
const mm = String(today.getMonth() + 1).padStart(2, '0')
const yyyy = today.getFullYear()
const NoticePost = () => {
  const setTitle = useOutletContext<any>()
  const sortOrderList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const [boardDivList, setBoardDivList] = useState<string[][]>([])
  const navigate = useNavigate()
  const { noticeId } = useParams()
  const { register, handleSubmit, setValue, watch } = useForm<IPost>()

  /** 파일 업로더 추가 23-01-12 */
  const [uploadId, setUploadId] = useState<number>()
  const [afMetaData, setAfMetaData] = useState<any>()
  const [afPath, setAfPath] = useState<string>()
  const [currentFileName, setCurrentFileName] = useState<string>()
  const [currentFileSize, setCurrentFileSize] = useState<number>()
  const [currentFilePath, setCurrentFilePath] = useState<any>()
  const [isFileExist, setIsFileExist] = useState<boolean>(false)

  const setText = (data: any) => {
    setValue('text', data)
  }
  const { title, text, boardDiv, sortOrder, useYn, popYn, popEndDate, filePath, afMeta } = watch()
  const formData = {
    title,
    text,
    boardDiv,
    sortOrder,
    useYn,
    popYn,
    popEndDate,
    filePath,
    afMeta,
  }
  const modifyFormData = {
    ...formData,
    noticeId,
  }

  const getPrevData = async () => {
    const { data } = await ApiUtil2.get<INoticeDetail>('/api/makerCommon/notice/detail', {
      params: { data: { noticeId } },
    })
    return data
  }
  const noticeDetailData = useQuery(['modify', noticeId], getPrevData, {
    enabled: false,
  })

  useEffect(() => {
    setTitle('공지사항 관리')
    return () => {
      setTitle('')
    }
  }, [])

  const setPrevData = () => {
    noticeDetailData.refetch().then((data: any) => {
      if (data.isSuccess) {
        const notice = data.data.data
        setValue('useYn', notice.useYn.toString())
        setValue('sortOrder', notice.sortOrder)
        $('#sortOrderSelect').val(notice.sortOrder).trigger('change')
        setValue('boardDiv', notice.boardDiv)
        $('#boardDivSelect').val(notice.boardDiv).trigger('change')
        setValue('popYn', notice.popYn)
        setValue('popEndDate', notice.popEndDate)
        const date = `${notice.popEndDate.substring(2, 4)}-${notice.popEndDate.substring(
          4,
          6
        )}-${notice.popEndDate.substring(6, 8)}`
        $('#popEndDatePicker').val(date)
        setValue('title', notice.title)
        setValue('text', notice.text)

        if (notice.fileName !== undefined) {
          setCurrentFileName(notice.fileName)
          setCurrentFileSize(notice.fileSize)
          setCurrentFilePath(notice.filePath)
          setIsFileExist(true)
          const flag = 'MAINTAIN'
          setAfMetaData({ flag })
        }
      }
    })
  }

  useEffect(() => {
    if (noticeId !== undefined) {
      setPrevData()
    } else {
      /** 초기값 설정 */
      setValue('useYn', 'true')
      setValue('sortOrder', 1)
      setValue('boardDiv', 'SYSTEM')
      setValue('popEndDate', `${yyyy}${mm}${dd}`)
      setValue('text', '')
    }

    $('#sortOrderSelect')
      .select2({ width: 'auto', minimumResultsForSearch: Infinity })
      .on('select2:select', (e) => setValue('sortOrder', e.params.data.id))

    const initDate = `${yyyy.toString().substring(2, 4)}-${mm}-${dd}`
    $('.datepicker-single')
      .daterangepicker({
        singleDatePicker: true,
        locale: {
          format: 'YYYY-MM-DD',
        },
      })
      .on('change', () => {
        const dateValue = $('#popEndDatePicker').val() as string
        const replacedDate = dateValue.replaceAll('-', '')
        const formatted = `${replacedDate.substring(2, 4)}-${replacedDate.substring(4, 6)}-${replacedDate.substring(
          6,
          8
        )}`
        setValue('popEndDate', dateValue.replace(/-/g, ''))
        $('#popEndDatePicker').val(formatted)
      })
      .val(initDate)

    $('#boardDivSelect')
      .select2({ width: 'auto', minimumResultsForSearch: Infinity })
      .on('select2:select', (e) => {
        setValue('boardDiv', e.params.data.id)
      })

    ApiUtil.get('/api/community/notice/getBoardDivs').then((resp) => {
      const options = Object.keys(resp.data.data).map((key) => [String(key), resp.data.data[key]])
      setBoardDivList(options)
    })

    return () => {
      // 달력관련 모든걸 remove 해줘야 한다 한페이지에서 동작하는거라 이거 안해주면 달력 관련 소스가 계속 늘어남
      $('.datepicker-single').remove()
    }
  }, [])

  const cancelHandler = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    navigate('/community/notice')
  }

  const onValid = (error: any) => {
    if (error.title !== undefined) {
      if (error.title.message !== '') {
        alertify.error(error.title.message)
      }
    }
  }

  const getUploadId = useCallback(() => {
    ApiUtil.get('/api/common/getUploadId').then((response) => {
      setUploadId(response.data.data)
    })
  }, [])

  useEffect(() => {
    /** 최초 채번 아이디 부여 */
    getUploadId()
  }, [])

  const noticeFileUploadHandler = (e: any) => {
    if (isFileExist) {
      alertify.error('파일 첨부는 하나만 가능 합니다. 기존 파일을 삭제 후 시도해주세요.')
      return
    }
    getUploadId()
    const file: File = e.target.files[0]
    const fileName: string = file.name
    const fileSize: number = file.size
    const fileDiv: string = file.type
    const maxSize = 20971520
    if (Math.floor(fileSize) > maxSize) {
      alertify.error('20MB 이하 파일만 첨부 가능합다.')
      return
    }
    /** 파일을 하나라도 첨부할 경우에 */
    if (e.target.files.length > 0) {
      $('#noticeFilePlaceHolder').val(file.name)
      setCurrentFileName(file.name)
      setCurrentFileSize(file.size)
      setIsFileExist(true)
    }

    const flag = noticeId !== undefined ? 'CHANGED' : 'POST'
    setAfMetaData({
      fileName,
      fileSize,
      fileDiv,
      flag,
    })
    const xhr = new XMLHttpRequest()
    const data = { uploadId, originFileName: file.name, myS3Upload: 'board' }

    ApiUtil3.post('/api/file/uploadReq', data).then((resp) => {
      const { presignUrl } = resp.data.data
      const { publicUrl } = resp.data.data

      xhr.responseType = 'json'
      xhr.open('PUT', presignUrl, true) // xhr url에다가 s3 url 반환해줘야 함.
      xhr.setRequestHeader('Access-Control-Allow-Headers', '*')
      xhr.send(file)
      setAfPath(publicUrl)
      setCurrentFilePath(publicUrl)
    })
  }

  const onSubmit = () => {
    if (noticeId !== undefined) {
      if (afMetaData !== undefined) {
        modifyFormData.filePath = afPath
        modifyFormData.afMeta = afMetaData
      }
      ApiUtil2.post('/api/community/notice/modify', modifyFormData).then(() => {
        navigate('/community/notice')
      })
    } else {
      if (afPath !== undefined) {
        formData.filePath = afPath
        formData.afMeta = afMetaData
      }
      ApiUtil2.post('/api/community/notice/post', formData).then(() => {
        navigate('/community/notice')
      })
    }
  }

  const noticeFileDeleteHandler = () => {
    setIsFileExist(false)
    $('#noticeFilePlaceHolder').val('파일을 선택해주세요.')
    const flag = 'DELETE'
    setAfMetaData({ flag })
    setAfPath('')
  }

  const noticeFileDownHandler = async (e: any) => {
    e.preventDefault()
    const response = await fetch(currentFilePath)
    const file = await response.blob()
    ApiUtil.get('/api/common/isLogin').then((resp) => {
      if (resp.data.data === true) {
        const downloadUrl = window.URL.createObjectURL(file) // 해당 file을 가리키는 url 생성
        const anchorElement = document.createElement('a')
        document.body.appendChild(anchorElement)
        anchorElement.download = currentFileName as any // a tag에 download 속성을 줘서 클릭할 때 다운로드가 일어날 수 있도록 하기
        anchorElement.href = downloadUrl // href에 url 달아주기
        anchorElement.click() // 코드 상으로 클릭을 해줘서 다운로드를 트리거
        document.body.removeChild(anchorElement) // cleanup - 쓰임을 다한 a 태그 삭제
      } else {
        alertify.error('와디즈 비즈센터 회원만 다운로드 가능합니다. ')
      }
    })
  }

  return (
    <>
      <section className="wrap-section wrap-tbl">
        <div className="box-header">
          <h2 className="fz-20 fc-1 fw-bold">{noticeId === undefined ? '공지사항 작성' : '공지사항 수정'}</h2>
        </div>
        <form onSubmit={handleSubmit(onSubmit, onValid)}>
          <div className="box-body">
            <div className="tbl">
              <dl className="column-two">
                <dt>
                  <div className="dt-inner">
                    <span className="fz-16 fc-1">공개여부</span>
                  </div>
                </dt>
                <dd>
                  <div className="form-group">
                    <div className="comp-radio">
                      <input
                        type="radio"
                        id="inp-radio-01"
                        checked={useYn === 'true'}
                        value="true"
                        {...register('useYn')}
                      />
                      <label htmlFor="inp-radio-01">공개</label>
                    </div>
                    <div className="comp-radio">
                      <input
                        type="radio"
                        id="inp-radio-02"
                        checked={useYn === 'false'}
                        value="false"
                        {...register('useYn')}
                      />
                      <label htmlFor="inp-radio-02">미공개</label>
                    </div>
                  </div>
                </dd>
                <dt>
                  <div className="dt-inner">
                    <span className="fz-16 fc-1">정렬순위</span>
                  </div>
                </dt>
                <dd>
                  <div className="form-group">
                    <select className="select2-single" id="sortOrderSelect" value={sortOrder}>
                      {sortOrderList.map((k) => (
                        <option key={k} value={k}>
                          {k}
                        </option>
                      ))}
                    </select>
                  </div>
                </dd>
              </dl>
              <dl className="column-two">
                <dt>
                  <div className="dt-inner">
                    <span className="fz-16 fc-1">구분</span>
                  </div>
                </dt>
                <dd>
                  <div className="form-group">
                    <BoardDivSelectBox options={boardDivList} defaultValue={boardDiv} />
                  </div>
                </dd>
                <dt>
                  <div className="dt-inner">
                    <span className="fz-16 fc-1">팝업</span>
                  </div>
                </dt>
                <dd>
                  <div className="form-group">
                    <div className="comp-checkbox margin-right-8">
                      <input type="checkbox" id="popYn" checked={popYn === true} {...register('popYn')} />
                      <label htmlFor="popYn">노출</label>
                    </div>
                    <span className="comp-txt">
                      <span className="table">
                        <span className="table-cell">
                          <b className="fz-14 fc-2">팝업 노출 종료일</b>
                        </span>
                      </span>
                    </span>
                    <div className="comp-datepicker">
                      <div className="inner-datepicker">
                        <i className="ico i-16 ico-calendar" />
                        <input
                          type="text"
                          className="tf-comm datepicker-single"
                          id="popEndDatePicker"
                          defaultValue={popEndDate}
                        />
                      </div>
                    </div>
                  </div>
                </dd>
              </dl>
              <dl>
                <dt>
                  <div className="dt-inner">
                    <span className="fz-16 fc-1">제목</span>
                  </div>
                </dt>
                <dd>
                  <div className="form-group">
                    <div className="input-group expand">
                      <div className="inner-input-group">
                        <input
                          className="tf-comm"
                          {...register('title', {
                            required: {
                              value: true,
                              message: '제목을 입력하세요.',
                            },
                          })}
                          placeholder="제목"
                        />
                      </div>
                    </div>
                  </div>
                </dd>
              </dl>
              <dl>
                <dt>
                  <div className="dt-inner">
                    <span className="fz-16 fc-1">내용</span>
                  </div>
                </dt>
                <dd>
                  <div className="form-group">
                    <CkEditor5 setText={setText} defaultValue={text} />
                  </div>
                </dd>
              </dl>
              <dl>
                <dt>
                  <div className="dt-inner">
                    <span className="fz-16 fc-1">첨부파일</span>
                  </div>
                </dt>
                <dd>
                  <div className="form-group">
                    <div className="comp-file-upload expand">
                      <input type="file" id="noticeFileUploader" multiple={false} onChange={noticeFileUploadHandler} />
                      <i className="ico ico-file"></i>
                      <input
                        type="text"
                        id="noticeFilePlaceHolder"
                        className="tf-comm"
                        placeholder="파일을 선택하세요."
                        readOnly={true}
                        disabled={isFileExist}
                      />
                      <label className="btn" htmlFor="noticeFileUploader">
                        파일 선택
                      </label>
                    </div>
                  </div>
                </dd>
              </dl>
              {isFileExist ? (
                <dl className="vertical">
                  <dt>
                    <div className="dt-inner">
                      <span className="fz-16 fc-1">파일 리스트</span>
                    </div>
                  </dt>
                  <dd>
                    <div className="form-group">
                      <div className="comp-file-list">
                        <div className="file-list-top">
                          <span className="fz-14 fc-2">
                            첨부 파일
                            {/* 파일첨부 정책상 최대 1개이므로 하드코딩 유지 */}
                            <span className="fc-5">1</span>개
                          </span>
                        </div>
                        <div className="file-list-bottom">
                          <div className="file-item">
                            <i className="ico ico-file-list"></i>
                            <span className="fz-12 fc-2">{currentFileName}</span>
                            <span className="fz-12 fc-3">({Math.floor(currentFileSize! / 1024)} KB)</span>
                            <button onClick={noticeFileDownHandler}>
                              <i className="ico ico-download"></i>
                            </button>
                            <button onClick={noticeFileDeleteHandler}>
                              <i className="ico ico-delete" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </dd>
                </dl>
              ) : null}
            </div>
          </div>
          <div className="box-footer">
            <div className="box-right">
              <button className="btn btn-secondary-outline" onClick={cancelHandler}>
                취소
              </button>
              <button type="submit" className="btn btn-primary">
                {noticeId === undefined ? '저장' : '수정'}
              </button>
            </div>
          </div>
        </form>
      </section>
    </>
  )
}

export default NoticePost
