import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import './override.css'

export const ToastWrapper: React.FC = () => (
  <ToastContainer
    position="bottom-right"
    autoClose={2000}
    hideProgressBar
    newestOnTop={false}
    closeOnClick
    closeButton={false}
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="dark"
  />
)
