import { useQuery } from '@tanstack/react-query'
import { CategoryCode, fetchCategoryByCode } from '@biz/api'

/**
 * 카테고리 코드 별 조회
 */
export const useCategoryByCodeQuery = (categoryCode?: CategoryCode) => {
  return useQuery({
    queryKey: [`@biz/query/category/useCategoryByCode/${categoryCode}`],
    queryFn: categoryCode ? () => fetchCategoryByCode(categoryCode) : undefined,
    enabled: false,
    select: (data: Awaited<ReturnType<typeof fetchCategoryByCode>>) => data.data,
  })
}
