import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import alertify from 'alertifyjs'

import { ApiUtil3 } from '@biz/api'

export interface IAEMRegReq {
  bizno: string
  comment: string
}

export const AllowedEntrepreneurRegDialog = (props: any) => {
  const { watch } = useForm<IAEMRegReq>()
  const { bizno, comment } = watch()

  const formData = {
    bizno,
    comment,
  }

  useEffect(() => {
    /** 다이얼로그 */
    $('#AEMRegDialog').dialog({
      title: '허용대상 등록',
      closeOnEscape: true,
      width: 900,
    })

    $(document).on('click', '#cancelBtn', () => {
      $('#AEMRegDialog').dialog('close')
      props.setIsDialogOpened(false)
      $('#AEMRegDialog').dialog('destroy')
    })

    // $('#bizno').on('keyup', () => {
    //     const value = $('#bizno').val() as string;
    //     if (value.length > 15) {
    //         $('#bizno').val(value.substring(0, 15));
    //     }
    // });

    $('#comment').on('keyup', () => {
      let value = $('#comment').val() as string
      const maxLengthInBytes = 500
      const encoder = new TextEncoder()
      const encodedValue = encoder.encode(value)
      const length = encodedValue.length

      if (length > maxLengthInBytes) {
        const slicedValue = encodedValue.slice(0, maxLengthInBytes)
        const decoder = new TextDecoder()
        value = decoder.decode(slicedValue)
        $('#comment').val(value)
      }
      $('#byteCount').text(length)
    })

    $('#reg').on('click', () => {
      formData.bizno = $('#bizno').val() as string
      formData.comment = $('#comment').val() as string

      if (formData.bizno === '') {
        alertify.error('허용 대상 사업자등록번호(주민등록번호)를 입력해 주세요.')
        return
      }
      if (formData.bizno.length !== 10 && formData.bizno.length !== 13) {
        alertify.error('사업자등록번호 10자리의 숫자 혹은 주민등록번호 13자리의 숫자를 입력해 주세요.')
        return
      }

      alertify
        .confirm('해당 사업자등록번호(주민등록번호)에 연결된 프로젝트에 대해 후불머니 사용을 허용합니다.', () => {
          ApiUtil3.post<IAEMRegReq>('/api/bzmAdmin/postpaidMoneyManagement/AEM/save', formData).then(() => {
            $('#AEMRegDialog').dialog('close')
            props.setIsDialogOpened(false)
            $('#AEMRegDialog').dialog('destroy')
            props.onGridReady()
          })
        })
        .set({ labels: { cancel: '취소', ok: '확인' } })
        .setHeader('')
    })
  }, [])

  return (
    <form>
      <div id="AEMRegDialog" className="dialog">
        <div className="dialog-body">
          <div className="wrap-section wrap-tbl">
            <div className="box-body">
              <div className="tbl">
                <dl>
                  <dt>
                    <div className="dt-inner">
                      <span className="fz-16 fc-1">사업자등록번호(주민등록번호)</span>
                    </div>
                  </dt>
                  <dd>
                    <div className="form-group">
                      <div className="input-group">
                        <div className="inner-input-group">
                          <input type="number" className="tf-comm" placeholder="숫자만 입력해 주세요." id="bizno" />
                        </div>
                      </div>
                    </div>
                  </dd>
                </dl>
                <dl>
                  <dt>
                    <div className="dt-inner">
                      <span className="fz-16 fc-1">허용 사유</span>
                    </div>
                  </dt>
                  <dd>
                    <div className="form-group">
                      <div className="input-group small">
                        <div className="inner-input-group">
                          <textarea
                            className="tf-textarea"
                            id="comment"
                            maxLength={500}
                            style={{ width: 500, minWidth: 500 }}
                          />
                        </div>
                        <p className="fz-12 fc-3">
                          <span className="fc-2" id="byteCount">
                            0
                          </span>{' '}
                          / 500
                        </p>
                      </div>
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div className="dialog-footer">
          <button type="button" className="btn btn-secondary-outline" id={'cancelBtn'}>
            취소
          </button>
          <button type="button" className="btn btn-primary" id="reg">
            확인
          </button>
        </div>
      </div>
    </form>
  )
}
