import type { AxiosResponse } from 'axios'
import { axios } from '../axios'
export type LoginCheckType = {
  id: string //'9892830'
  makerNm: string //'ad1'
  hp: string // '010-51**-04**'
  agreeYn: boolean // true
  agreePrivateVersion: string // '1.1.1'
  agreeTermsVersion: string // '1.1.1'
  agreeTime: string // '2024-04-30'
  campAlertYn: false
  promotionAlertYn: false
  bizmoneyAlertYn: false
  nightAlertYn: false
}
type ResponseType = {
  ok: boolean
  data: LoginCheckType | string
}
type FunctionType = () => Promise<ResponseType>
export const fetchLoginCheck: FunctionType = () => axios.get('/api/loginCheck')
