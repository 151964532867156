import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { fetchPostAdSetTurnOn } from '@biz/api'
import { useCurrentCampaignIdQuery } from '@biz/query'

type FunctionType = () => UseMutationResult<
  { ok?: boolean; data?: { campId?: number; useYn?: boolean } },
  unknown,
  { useYn: boolean; adSetId: string | number },
  unknown
>
export const useAdSetTurnOnMutation: FunctionType = () => {
  const { data: campaignId } = useCurrentCampaignIdQuery()

  return useMutation({
    mutationFn: ({ useYn, adSetId }) =>
      !!campaignId
        ? fetchPostAdSetTurnOn({
            adSetId,
            campId: campaignId,
            useYn,
          })
        : Promise.reject('ad set id'),
  })
}
