import alertify from 'alertifyjs'

/*
파일 관련 각종 밸리데이션
 */
const maxFileSize = 5242880
export const FileGgLogoValidator = (file: any) => {
  if (
    file.type === 'image/jpeg' ||
    file.type === 'image/jpg' ||
    file.type === 'image/png' ||
    file.type === 'image/gif'
  ) {
    // 성공
  } else {
    alertify.error('다음의 확장자만 허용합니다.<br/>JPEG, JPG, PNG, GIF')
    return false
  }
  if (file.size > maxFileSize) {
    alertify.error('업로드 가능한 용량을 초과하였습니다.<br/>이미지 파일의 최대 크기는 5,120KB 입니다.')
    return false
  }

  return true
}
const errMsg = () => {
  alertify.error(
    '이미지의 사이즈가 규격에 맞지 않습니다.<br/><br/>가로 모드 로고 (4:1)<br/>권장 크기: 1200x300<br/>최소 크기: 512x128<br/><br/>정사각형 로고(1:1)<br/>권장 크기: 1200x1200<br/>최소 크기: 128x128'
  )
}
export const FileGgSizeCheck = (file: any, callback: any) => {
  const image = new Image()

  const URL = window.URL || window.webkitURL
  const objectUrl = URL.createObjectURL(file)
  let width = 0
  let height = 0
  image.onload = function () {
    URL.revokeObjectURL(objectUrl)
    width = image.width
    height = image.height
    let imageDiv = ''
    if (width === height) {
      // 1:1 정사각형으로 판단
      if (width < 128 || height < 128) {
        errMsg()
        callback(false)
        return
      }
      if (width > 1200 || height > 1200) {
        errMsg()
        callback(false)
        return
      }
      imageDiv = 'SQUARE' // 정사각형
    } else if (width / 4 === height) {
      // 가로 모드
      if (width < 512 || height < 128) {
        errMsg()
        callback(false)
        return
      }
      if (width > 1200 || height > 300) {
        errMsg()
        callback(false)
        return
      }
      imageDiv = 'NOT_SQUARE' // 가로모드 로고
    } else {
      errMsg()
      callback(false)
      return
    }
    URL.revokeObjectURL(objectUrl)
    callback(true, imageDiv, width, height, file.size)
  }
  image.src = objectUrl
}
// const checked1 = true;
export const MultiFileValidationGg = (multiFile: any, callback: any) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const file of multiFile) {
    FileGgSizeCheck(file, (checkStep1: boolean, imageDiv: string, width: number, height: number, fileSize: number) => {
      if (!checkStep1) {
        callback(false)
        return
      }
      // checked1 = checkStep1;
      const checkStep2 = FileGgLogoValidator(file)
      if (!checkStep2) {
        callback(false)
      }
      callback(true, file, imageDiv, width, height, fileSize)
    })
  }
}
