import React, {
    Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import { useParams } from 'react-router';
import { ApiUtil, ApiUtil2 } from '@biz/api'
import { useAlertUnreadCountQuery } from '@biz/query'
import NoDataTemplate from '../../common/NoDataTemplate'
import Pagination from '../../common/Pagination'
import alertify from 'alertifyjs'
import BellAlertDetail from './BellAlertDetail'

interface IBellAlert {
  bellAlertId: number
  regTime: string
  viewYn: boolean
  text: string
}

interface IBellAlertRes {
  ok: boolean
  data: IBellAlert[]
}

const regTimeRenderer = (props: ICellRendererParams) => {
  const { date } = props.value
  const { time } = props.value
  return dayjs(new Date(date.year, date.month - 1, date.day, time.hour, time.minute)).format('YYYY-MM-DD HH:mm')
}

const BellAlert = () => {
  const { refetch, forceUpdate } = useAlertUnreadCountQuery()
  const [isClicked, setIsClicked] = useState(false)
  const [bellAlertId, setBellAlertId] = useState('')
  const [text, setText] = useState('')
  const childRef = useRef<any>(null)
  const gridRef = useRef<AgGridReact>(null)
  const [rowData, setRowData] = useState<any[]>()

  /* 홈에서 진입했을 경우 알림 바로 펼쳐지게 */
  const { id } = useParams()
  useEffect(() => {
    if (id !== undefined) {
      ApiUtil2.get('/api/makerCommon/bellAlert/detail', { params: { data: { id } } }).then((resp) => {
        setBellAlertId(resp.data.data.id)
        setIsClicked(true)
        setText(resp.data.data.text)
      })
    }
  }, [])

  const onGridReady = useCallback(() => {
    ApiUtil2.get<IBellAlertRes>('/api/makerCommon/bellAlert').then((resp) => {
      setRowData(resp.data.data)
    })
  }, [])

  const bellAlertTextRenderer = (e: any) => {
    const { data }: any = e
    return (
      <button
        className={data.viewYn === false ? 'fz-14 fc-2 txt-dot ico-unread' : 'fz-14 fc-2 txt-dot'}
        onClick={() => {
          setBellAlertId(data.id)
          setIsClicked(true)
          setText(data.text)
          ApiUtil.post('/api/makerCommon/bellAlert/read', { id: data.id }).then(() => {
            onGridReady()
            refetch()
          })
        }}
      >
        {data.text}
      </button>
    )
  }
  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'regTime',
      headerName: '날짜',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: regTimeRenderer,
      maxWidth: 300,
    },
    {
      field: 'text',
      headerName: '내용',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: bellAlertTextRenderer,
    },
  ])

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      resizable: false,
      suppressMovable: true,
      sortable: true,
    }),
    []
  )

  const readAllHandler = () => {
    ApiUtil.post('/api/makerCommon/bellAlert/readAll').then(() => {
      const msg = '모두 읽기 처리되었습니다.'
      alertify.success(msg, () => {
        console.log(msg)
      })
      onGridReady()
      forceUpdate(0)
    })
  }

  const changePagination = () => {
    childRef.current!.onPaginationChanged()
  }

  return (
    <Fragment>
      {isClicked === true ? (
        <BellAlertDetail bellAlertId={bellAlertId} setIsClicked={setIsClicked} text={text} />
      ) : null}
      <section className="wrap-section wrap-datagrid">
        <div className="box-header">
          <div className="box-tit">
            <h2 className="fz-20 fc-1 fw-bold">알림</h2>
          </div>
          <div className="box-option">
            <button type="button" className="btn btn-primary" onClick={readAllHandler}>
              모두 읽음
            </button>
          </div>
        </div>
        <div className="box-body">
          <div className="ag-grid">
            <div className="ag-grid-inner">
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                rowHeight={48}
                pagination={true}
                paginationPageSize={10}
                suppressPaginationPanel={true}
                onPaginationChanged={changePagination}
                domLayout={'autoHeight'}
                noRowsOverlayComponent={NoDataTemplate}
              ></AgGridReact>
            </div>
          </div>
        </div>
        <div className="box-footer">
          <Pagination gridRef={gridRef} ref={childRef} />
        </div>
      </section>
    </Fragment>
  )
}

export default React.memo(BellAlert);
