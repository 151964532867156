import { Fragment, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useNavigate, useOutletContext } from 'react-router'
import alertify from 'alertifyjs'
import { ApiUtil2, ApiUtil3 } from '@biz/api'
import AccAdjustDialogTaxbillHistory from './dialog/AccAdjustDialogTaxbillHistory'
import AccAdjustDialogTaxbill from './dialog/AccAdjustDialogTaxbill'

const AccAdjustTaxbillInfo = () => {
  const setTitle = useOutletContext<any>()
  const navigate = useNavigate()
  const location = useLocation()

  const [adjustTargetId, setAdjustTargetId] = useState<string>('')
  const [prevPage, setPrevPage] = useState<string>('')

  const [adjustTargetInfo, setAdjustTargetInfo] = useState<any>(undefined)

  // 세금계산서 히스토리 dialog
  const [historyDialog, setHistoryDialog] = useState<boolean>(false)
  // 세금계산서 신규 발행 dialog
  const [taxbillDialog, setTaxbillDialog] = useState<boolean>(false)

  const rerenderPage = (res: any) => {
    setAdjustTargetInfo({ ...adjustTargetInfo, ...res })
  }

  const btnAction = (confirmMsg: string, url: string, successMsg: string, cb?: any) => {
    alertify
      .confirm(confirmMsg, () => {
        ApiUtil3.post(url, { id: adjustTargetInfo?.taxbillId }).then((resp) => {
          if (resp.data.ok === true) {
            alertify.success(successMsg)
          } else {
            alertify.error(resp.data.data.msg)
          }
          cb(resp)
        })
      })
      .set({ labels: { cancel: '취소', ok: '확인' } })
      .setHeader('')
  }

  // 발행 예약 취소
  const cancelReserved = () => {
    btnAction(
      '발행 예정인 세금계산서의 발행 예약을 취소 하시겠습니까?',
      '/api/accAdjust/taxbill/cancelReserved',
      '정상적으로 발행 예약이 취소 되었습니다.',
      (resp: any) => {
        const res = resp.data.data
        rerenderPage(res)
      }
    )
  }

  // 발행 취소
  const cancelPublished = () => {
    btnAction(
      "발행 된 세금계산서를 취소 하시겠습니까?<br><br>취소의 경우 '-'의 수정세금계산서가 발행됩니다.",
      '/api/accAdjust/taxbill/cancelPublished',
      '정상적으로 수정 세금계산서가 발행 되었습니다.',
      (resp: any) => {
        const res = resp.data.data
        rerenderPage(res)
      }
    )
  }

  // 신규 발행
  const newSend = () => {
    setTaxbillDialog(true)
  }

  const history = () => {
    setHistoryDialog(true)
  }

  const loadTaxbillInfo = () => {
    ApiUtil2.get('/api/accAdjust/taxbill/info', {
      params: {
        id: adjustTargetId,
      },
    }).then((resp) => {
      const res = resp.data.data
      rerenderPage(res)
    })
  }

  useEffect(() => {
    setTitle('임의조정 세금계산서')
    const param = new URLSearchParams(location.search)
    const id = param.get('id')
    const prev = param.get('prev')
    setAdjustTargetId(id || '')
    setPrevPage(prev || '')
  }, [])

  useEffect(() => {
    if (adjustTargetId === '') {
      return
    }
    loadTaxbillInfo()
  }, [adjustTargetId, taxbillDialog])

  return (
    <Fragment>
      {/* Wrap-Tbl : Start */}
      <div className="wrap-section wrap-tbl">
        <div className="box-header">
          <div className="box-tit">
            <button type="button" className="btn btn-secondary-outline" onClick={() => navigate(-1)}>
              이전 {prevPage === 'taxbill' ? '목록' : '화면'}으로
            </button>
          </div>
          <div className="box-option">
            {adjustTargetInfo?.taxbillCancellable === false && adjustTargetInfo?.taxbillStatus === 'reserved' ? (
              <button type="button" className="btn btn-primary-outline" onClick={cancelReserved}>
                발행 예약 취소
              </button>
            ) : null}
            {adjustTargetInfo?.taxbillCancellable === true ? (
              <button type="button" className="btn btn-primary-outline" onClick={cancelPublished}>
                발행 취소
              </button>
            ) : null}
            {adjustTargetInfo?.taxbillCancellable === false && adjustTargetInfo?.taxbillStatus !== 'reserved' ? (
              <button type="button" className="btn btn-primary-outline" onClick={newSend}>
                신규 발행
              </button>
            ) : null}
            <button type="button" className="btn btn-info-outline" onClick={history}>
              히스토리
            </button>
          </div>
        </div>
        <div className="box-body">
          <div className="tbl">
            <dl className="vertical">
              <dd>
                <div className="form-group">
                  {/* Wrap-Datagrid : Start */}
                  <section className="wrap-section wrap-tablegrid">
                    <div className="box-header">
                      <div className="box-tit">
                        <h2 className="fz-20 fc-1 fw-bold">발급 대상 정보</h2>
                      </div>
                    </div>
                    <div className="box-body">
                      <div className="tbl">
                        <table>
                          <colgroup>
                            <col width="10%"></col>
                          </colgroup>
                          <thead>
                            <tr>
                              <th>메이커ID</th>
                              <th>사업자번호/주민번호</th>
                              <th>구분</th>
                              <th>상호</th>
                              <th>대표자명 / 개인 성명</th>
                              <th>이메일</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{adjustTargetInfo?.makerLoginId}</td>
                              <td>{adjustTargetInfo?.userNo}</td>
                              <td>{adjustTargetInfo?.bizDivDesc}</td>
                              <td>{adjustTargetInfo?.comName}</td>
                              <td>{adjustTargetInfo?.comRepNm}</td>
                              <td>{adjustTargetInfo?.comEmail}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </section>
                  {/* Wrap-Datagrid : End */}

                  {/* Wrap-Datagrid : Start */}
                  <section className="wrap-section wrap-tablegrid">
                    <div className="box-header">
                      <div className="box-tit">
                        <h2 className="fz-20 fc-1 fw-bold">발급 항목</h2>
                      </div>
                    </div>
                    <div className="box-body">
                      <div className="tbl">
                        <table>
                          <colgroup>
                            <col width="10%"></col>
                            <col width="400"></col>
                          </colgroup>
                          <thead>
                            <tr>
                              <th>공급일자</th>
                              <th>항목</th>
                              <th>공급가액</th>
                              <th>부가세</th>
                              <th>합계</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* fixme. 세금계산서가 두줄인지 한줄인지 체크 후 아래 로직 수정 */}
                            <tr>
                              <td>{adjustTargetInfo?.supDate}</td>
                              <td>{adjustTargetInfo?.taxbillComment}</td>
                              <td>{String(adjustTargetInfo?.cost).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}</td>
                              <td>{String(adjustTargetInfo?.vat).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}</td>
                              <td>{String(adjustTargetInfo?.total).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </section>
                  {/* Wrap-Datagrid : End */}

                  {/* Wrap-Tbl : Start */}
                  <div className="wrap-section wrap-tbl">
                    <div className="box-header">
                      <div className="box-tit">
                        <h2 className="fz-20 fc-1 fw-bold">세금계산서 일자</h2>
                      </div>
                    </div>
                    <div className="box-body">
                      <div className="tbl">
                        <dl className="column-two">
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">세금계산서 작성일자</span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              {/* Comp-Txt : Start */}
                              <span className="comp-txt">
                                <span className="table">
                                  <span className="table-cell">
                                    <b className="fz-14 fc-2">{adjustTargetInfo?.regDate}</b>
                                  </span>
                                </span>
                              </span>
                              {/* Comp-Txt : End */}
                            </div>
                          </dd>
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">세금계산서 발행일자</span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              {/* Comp-Txt : Start */}
                              <span className="comp-txt">
                                <span className="table">
                                  <span className="table-cell">
                                    <b className="fz-14 fc-2">{adjustTargetInfo?.sendDate}</b>
                                  </span>
                                </span>
                              </span>
                              {/* Comp-Txt : End */}
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  {/* Wrap-Tbl : End */}

                  {/* Wrap-Tbl : Start */}
                  <div className="wrap-section wrap-tbl">
                    <div className="box-header">
                      <div className="box-tit">
                        <h2 className="fz-20 fc-1 fw-bold">임의 조정 사유</h2>
                      </div>
                    </div>
                    <div className="box-body">
                      <div className="tbl">
                        <dl className="vertical">
                          <dd>
                            <div className="form-group">
                              {/* Input-Group : Start */}
                              <div className="input-group expand">
                                <div className="inner-input-group">
                                  <textarea
                                    className="tf-textarea"
                                    readOnly
                                    disabled
                                    value={adjustTargetInfo?.comment || ''}
                                  ></textarea>
                                </div>
                                <p className="fz-12">
                                  <i className="ico"></i>체크 / 에러 문구 내용 영역
                                </p>
                              </div>
                              {/* Input-Group : End */}
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  {/* Wrap-Tbl : End */}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      {historyDialog ? (
        <AccAdjustDialogTaxbillHistory
          historyDialog={historyDialog}
          setHistoryDialog={setHistoryDialog}
          taxbillId={adjustTargetInfo?.taxbillId}
        />
      ) : null}
      {taxbillDialog ? (
        <AccAdjustDialogTaxbill
          setTaxbillDialog={setTaxbillDialog}
          adjustTargetId={adjustTargetId}
          source={'taxbill'}
        />
      ) : null}
      {/* Wrap-Tbl : End */}
    </Fragment>
  )
}

export default AccAdjustTaxbillInfo
