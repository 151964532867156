import React, { Fragment, useEffect, useState } from 'react';

const AccAdjustInfoTransfer = (props: any) => {
  const [adjust, setAdjust] = useState<any>(undefined);
  const [minusTarget, setMinusTarget] = useState<any>(undefined);
  const [plusTarget, setPlusTarget] = useState<any>(undefined);

  useEffect(() => {
    // initialize
  }, []);

  useEffect(() => {
    setAdjust({ ...adjust, ...props.adjust });
  }, [props.adjust]);

  useEffect(() => {
    if (adjust === undefined) {
      return;
    }

    setMinusTarget({ ...minusTarget, ...adjust.minus });
    setPlusTarget({ ...plusTarget, ...adjust.plus });
  }, [adjust]);

  return (
    <Fragment>
      <dl className="vertical">
        <dd>
          <div className="form-group">
            {/* Wrap-Tbl : Start */}
            <section className="wrap-section wrap-tbl">
              <div className="box-header">
                <h2 className="fz-20 fc-1 fw-bold">감산 대상 정보</h2>
              </div>
              <div className="box-body">
                <div className="tbl">
                  <dl className="column-two">
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">메이커ID</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        {/* Input-Group : Start */}
                        <div className="input-group">
                          <div className="inner-input-group" onClick={props.openUserNoPopup}
                               onKeyUp={(e: any) => e.handleKeyUp}
                               role="presentation">
                            <input type="text" className="tf-comm" value={minusTarget?.makerLoginId || ''} disabled/>
                          </div>
                          <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                        </div>
                        {/* Input-Group : End */}
                      </div>
                    </dd>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">메이커명</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        {/* Input-Group : Start */}
                        <div className="input-group">
                          <div className="inner-input-group">
                            <input type="text" className="tf-comm" value={minusTarget?.makerName || ''} disabled/>
                          </div>
                          <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                        </div>
                        {/* Input-Group : End */}
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">충전 사업자 번호</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        {/* Input-Group : Start */}
                        <div className="input-group">
                          <div className="inner-input-group">
                            <input type="text" className="tf-comm" value={minusTarget?.userNo || ''} disabled/>
                          </div>
                          <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                        </div>
                        {/* Input-Group : End */}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </section>
            {/* Wrap-Tbl : End */}

            {/* Wrap-Tbl : Start */}
            <section className="wrap-section wrap-tbl">
              <div className="box-header">
                <h2 className="fz-20 fc-1 fw-bold">가산 대상 정보</h2>
              </div>
              <div className="box-body">
                <div className="tbl">
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">충전 사업자 번호</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        {/* Input-Group : Start */}
                        <div className="input-group">
                          <div className="inner-input-group">
                            <input type="text" className="tf-comm" value={plusTarget?.userNo || ''} disabled/>
                          </div>
                          <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                        </div>
                        {/* Input-Group : End */}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </section>
            {/* Wrap-Tbl : End */}
          </div>
        </dd>
      </dl>
    </Fragment>
  );
};
export default AccAdjustInfoTransfer;
