import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import alertify from 'alertifyjs'
import _ from 'lodash'
import { useNavigate } from 'react-router'
import { AgGridReact } from 'ag-grid-react'
import { ApiUtil2 } from '@biz/api'
import { PageTitle } from '@biz/ui'
import NoDataTemplate from '../../../views/common/NoDataTemplate'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import Pagination from '../../../views/common/Pagination'
import CreationCheckDialog from './CreationCheckDialog'
import qs from 'qs'

export interface catInspConfig {
  catCodeStr?: string
  catCode: string
  desc: string
  catInspDiv: string
  catProhKwd?: string
}

const CreationCheckMng = () => {
  const navigate = useNavigate()
  const [cateDiv, setCateDiv] = useState([])
  const [markCateDiv, setMakrCateDiv] = useState('COMMON')
  const [markCateDivDesc, setMakrCateDivDesc] = useState('공통')
  const [markCheckList, setMarkCheckList] = useState([])
  const [sortOrder, setSortOrder] = useState(1)
  const [content, setContent] = useState('')
  const [isCommon, setIsCommon] = useState(false)

  const localCateDivs = useRef<any>({})

  const [showCreationCheckDialog, setShowCreationCheckDialog] = useState<boolean>(false)
  const [isNew, setNew] = useState<boolean>(true)

  const markCateDivRef = useRef(markCateDiv)

  const onGridReady = useCallback(() => {
    ApiUtil2.get(`/api/mngAd/creation/creationCheckMng/getCateCheckList/${markCateDivRef.current}`).then(
      (checkListData) => {
        const { data } = checkListData.data
        /** json 가공하여 입력 **/
        if (_.isEmpty(data)) {
          setMarkCheckList([])
        } else if (!_.isEmpty(data.creationCheckData)) {
          setMarkCheckList(JSON.parse(data.creationCheckData))
          setRowData(parseCheckData(data.creationCheckData, data.catCodeDesc))
        }
        /** json 가공하여 입력 끝 **/
      }
    )
  }, [markCateDiv])

  const contentRenderer = (props: ICellRendererParams) =>
    props.data.catCode != 'COMMON' && isAllCommon(gridRef.current!.props.rowData) ? (
      decodeURIComponent(props.value)
    ) : (
      <a
        className="txt-link"
        onClick={() => {
          setNew(false)
          setSortOrder(props.node!!.rowIndex!! + 1)
          setContent(props.data.content)
          setIsCommon(props.data.isCommon)
          setShowCreationCheckDialog(true)
        }}
      >
        {decodeURIComponent(props.value)}
      </a>
    )

  const isAllCommon = (data: any) => (_.isEmpty(data) ? true : data.filter((v: any) => !v.isCommon).length == 0)

  const delRenderer = (props: ICellRendererParams) => {
    const deleteBtn = useCallback(() => {
      if (props.data.catCode != 'COMMON' && props.data.isCommon) {
        alertify.error('공통은 공통 카테고리에서 삭제 해 주세요.')
        return
      }

      const formData: any = {
        catCode: props.data.catCode,
        isCommon: props.data.isCommon,
        content: props.data.content,
      }

      alertify
        .confirm(
          '',
          '삭제하시겠습니까?',
          () => {
            ApiUtil2.post(`/api/mngAd/creation/creationCheckMng/checkListDel`, formData).then(() => {
              alertify.success('삭제되었습니다.', 1)
              onGridReady()
            })
          },
          {}
        )
        .set({ labels: { cancel: '취소', ok: '확인' } })
    }, [])
    return (
      <span>
        <button
          onClick={deleteBtn}
          className="btn btn-primary xsmall"
          disabled={props.data.catCode != 'COMMON' && props.data.isCommon}
        >
          삭제
        </button>
      </span>
    )
  }

  const childRef = useRef<any>(null)
  const gridRef = useRef<AgGridReact>(null)
  const [rowData, setRowData] = useState<any[]>()
  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: '노출순서',
      valueGetter: 'node.rowIndex + 1',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'catCodeDesc',
      headerName: '구분',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'content',
      headerName: '내용',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: contentRenderer,
    },
    {
      field: '삭제',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: delRenderer,
      width: 50,
    },
  ])
  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      resizable: false,
      suppressMovable: true,
      sortable: true,
    }),
    []
  )

  const changePagination = () => {
    childRef.current!.onPaginationChanged()
  }

  const { getValues, setValue, register, watch } = useForm<catInspConfig>()

  const getCate = async () => {
    // COMMON 은 공통 적용을 위한 분류로서, '공통'이라는 카테고리가 따로 존재하지는 않음
    const { data } = await ApiUtil2.get(`/api/mngAd/creation/creationCheckMng/getCateDiv`)
    return data
  }

  const getCateDiv = useQuery(['getCateDiv', 0], getCate, {
    enabled: false,
  })

  const cancelEvent = () => {
    window.location.href = '/mngAd/creation'
  }

  useEffect(() => {
    ApiUtil2.get(`/api/mngAd/creation/creationCheckMng/getCateCheckList/${markCateDiv}`).then((checkListData) => {
      const { data } = checkListData.data

      /** json 가공하여 입력 **/
      if (_.isEmpty(data) || _.isEmpty(data.creationCheckData)) {
        setMarkCheckList([])
        setRowData([])
      } else if (!_.isEmpty(data.creationCheckData)) {
        setMarkCheckList(JSON.parse(data.creationCheckData))
        setRowData(parseCheckData(data.creationCheckData, data.catCodeDesc))
      }
      /** json 가공하여 입력 끝 **/

      Object.entries(cateDiv).forEach((e, idx) => {
        if (markCateDiv == e[0]) {
          setValue('desc', e[1])
          setMakrCateDivDesc(e[1])
          return false
        }
      })
    })

    markCateDivRef.current = markCateDiv
  }, [cateDiv, markCateDiv])

  const parseCheckData = (data: string, desc: string) => {
    let parseData = JSON.parse(data)
    _.each(parseData, (v: any) => {
      v['catCode'] = markCateDivRef.current
      v['catCodeDesc'] = v['isCommon'] ? '공통' : desc
    })

    return parseData
  }

  useEffect(() => {
    getCateDiv.refetch().then((response) => {
      const { data } = response.data
      setCateDiv(data)
      onGridReady()
      setValue('desc', '공통')
    })
  }, [])

  return (
    <Fragment>
      <PageTitle>셀프 체크리스트 관리</PageTitle>
      <section className="wrap-section wrap-tbl">
        <div className="box-body">
          <div className="tbl">
            <dl className="vertical">
              <dd>
                <div className="form-group">
                  <div className="container-fluid">
                    <div className="row flex-container">
                      <div className="col col-4">
                        <div className="wrap-section wrap-tbl">
                          <div className="box-header">
                            <div className="box-tit">
                              <h2 className="fz-20 fc-1 fw-bold">카테고리 목록</h2>
                            </div>
                          </div>
                          <div className="box-body h-500 scroll-y">
                            <table className="tbl group-list">
                              <tbody>
                                {Object.entries(cateDiv).map((e, idx) => (
                                  <tr key={e[0]}>
                                    <td
                                      className={markCateDiv == e[0] ? 'selected' : ''}
                                      onClick={() => setMakrCateDiv(e[0])}
                                    >
                                      <span className="fz-16 fc-3">{e[1]}</span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="col col-8">
                        <div className="wrap-section wrap-tbl">
                          <div className="box-header">
                            <div className="box-tit">
                              <h2 className="fz-20 fc-1 fw-bold">{getValues('desc')} 카테고리 셀프 체크리스트</h2>
                            </div>
                          </div>
                          <div className="box-header">
                            <div className="box-tit">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={(e) => {
                                  setShowCreationCheckDialog(true)
                                  setContent('')
                                  setSortOrder(1)
                                  setNew(true)
                                }}
                              >
                                등록
                              </button>
                            </div>
                          </div>
                          <div className="box-body">
                            <div className="ag-grid">
                              <div className="ag-grid-inner">
                                <AgGridReact
                                  ref={gridRef}
                                  rowData={rowData}
                                  columnDefs={columnDefs}
                                  defaultColDef={defaultColDef}
                                  onGridReady={onGridReady}
                                  suppressRowClickSelection={true}
                                  rowSelection={'multiple'}
                                  rowHeight={48}
                                  pagination={true}
                                  paginationPageSize={10}
                                  suppressPaginationPanel={true}
                                  onPaginationChanged={changePagination}
                                  domLayout={'autoHeight'}
                                  noRowsOverlayComponent={NoDataTemplate}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="box-footer">
                            <Pagination gridRef={gridRef} ref={childRef} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div className="box-footer">
          <div className="box-right">
            <button type="button" className="btn btn btn-primary" onClick={cancelEvent}>
              확인
            </button>
          </div>
        </div>
      </section>
      {showCreationCheckDialog ? (
        <CreationCheckDialog
          setShowCreationCheckDialog={setShowCreationCheckDialog}
          isNew={isNew}
          catCode={markCateDiv}
          cateDivDesc={Object.entries(cateDiv).map((e, idx) => {
            if (markCateDiv == e[0]) return e[1]
          })}
          selectedOrder={sortOrder}
          selectedContent={content}
          isCommon={isCommon}
          markCheckList={markCheckList}
          onGridReady={onGridReady}
        />
      ) : null}
    </Fragment>
  )
}

export default CreationCheckMng
