import React, {
  Fragment, useCallback, useEffect, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { mediaGgForm } from './MngMdGg';
import MngMdGgAdSet, { ggAdSet } from './MngMdGgAdSet';
import { ApiUtil } from '@biz/api'

const MngMdGgAdTab = (props:any) => {
  const [tabOrder, setTabOrder] = useState(1);
  const [uploadId, setUploadId] = useState(props.uploadId);
  // const [initAdSet, setInitAdSet] = useState(false);
  const {
    setValue, getValues,
  } = useForm<mediaGgForm>({
    defaultValues: { tabOrder: 1 },
  });
  // setInitAdSet(props.initGgAdSet);
  // 탭 클릭시 상태값 변경 및 변수에 값 저장
  const changeAdSetOrder = (order:number|any) => {
    setTabOrder(order);
    setValue('tabOrder', order);

    const checkDatas = getValues('adSetDataList');
    if (checkDatas === undefined) {
      if (props.ggCustomerId !== '') { // 선택된 광고주가 있을 경우에만
        setValue('adSetDataList', [{
          comNm: '와디즈', uploadFiles: [], adSetOrder: 1, segment: [], uploadId,
        }, {
          comNm: '와디즈', uploadFiles: [], adSetOrder: 2, segment: [], uploadId,
        }]);
        props.ggAdSetDataFunction(getValues('adSetDataList'));
      }
    }
  };

  const adSetDataFunction = (data: ggAdSet) => {
    const checkDatas = getValues('adSetDataList');
    // 최초 세팅된 데이터가 없다면
    if (checkDatas === undefined) {
      setValue('adSetDataList', [data]);
    } else if (getValues('adSetDataList').length === 1) {
      if (data.adSetOrder === 1) {
        setValue('adSetDataList', [data]);
      } else {
        setValue('adSetDataList', [...getValues('adSetDataList'), data]);
      }
    } else {
      const changeDatas = getValues('adSetDataList').map((mapData) => {
        if (mapData.adSetOrder === tabOrder) {
          return data;
        }
        return mapData;
      });
      setValue('adSetDataList', changeDatas);
    }
    // 데이터 세팅해서 넘김
    props.ggAdSetDataFunction(getValues('adSetDataList'));
  };
  // 광고세트 설정
  const ChangeAdSet = useCallback(() => <MngMdGgAdSet uploadId={uploadId} changeOrder={tabOrder} datas={getValues('adSetDataList')} adSetDataFunction={adSetDataFunction} changeAdSetOrder={changeAdSetOrder} ggCustomerId={props.ggCustomerId}/>, [props.ggCustomerId, tabOrder, getValues('adSetDataList')]);

  // 탭 클릭시 class selected 변경처리
  useEffect(() => {
    $('[id^=ggAdSetOrder]').attr('class', 'tab');
    // 탭 selected class 변경처리
    $(`#ggAdSetOrder${tabOrder}`).addClass('selected');
  }, [tabOrder]);
  // 광고주 변경시 초기화 처리
  useEffect(() => {
    changeAdSetOrder(1);

    // 여기서 업로드 ID를 새로 생성
    ApiUtil.get('/api/common/getUploadId')
      .then((response) => {
        setUploadId(response.data.data);
        props.ggAdSetDataFunction(getValues('adSetDataList'), response.data.data);
        if (props.ggCustomerId !== '') {
          setValue('adSetDataList', [{
            comNm: '와디즈', uploadFiles: [], adSetOrder: 1, segment: [], uploadId: response.data.data,
          }, {
            comNm: '와디즈', uploadFiles: [], adSetOrder: 2, segment: [], uploadId: response.data.data,
          }]);
        } else {
          setValue('adSetDataList', [{
            comNm: '', uploadFiles: [], adSetOrder: 1, segment: [], uploadId: response.data.data,
          }, {
            comNm: '', uploadFiles: [], adSetOrder: 2, segment: [], uploadId: response.data.data,
          }]);
        }

        props.ggAdSetDataFunction(getValues('adSetDataList'));
      });
  }, [props.ggCustomerId]);
  return (
    <Fragment>
        <div className="col col-7">
            <section className="wrap-section wrap-tbl">
                <div className="box-header">
                    <div className="box-tit">
                        <h2 className="fz-20 fc-1 fw-bold">광고 세트 설정</h2>
                    </div>
                    <div className="box-option">
                    </div>
                </div>
                <div className="box-body">
                    <div className="comp-tab">
                        <Link to="#" id="ggAdSetOrder1" className="tab" onClick={() => changeAdSetOrder(1)}>
                            <span className="tab-item">메이커 세트</span>
                        </Link>
                        <Link to="#" id="ggAdSetOrder2" className="tab" onClick={() => changeAdSetOrder(2)}>
                            <span className="tab-item">와디즈 기본 세트</span>
                        </Link>
                    </div>
                </div>
                <ChangeAdSet/>
            </section>
        </div>
    </Fragment>
  );
};

export default MngMdGgAdTab;
