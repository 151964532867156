import { useQuery, useQueryClient, type QueryClient } from '@tanstack/react-query'
import { loginLocalStorage } from '@biz/api'

const queryKey = ['@biz/query/auth/LoginStatus']
export const setLoginStatus = (queryClient: QueryClient) => (status: boolean) => {
  return queryClient.setQueryData(queryKey, status)
}

export const getLoginStatus = (queryClient: QueryClient) => () => !queryClient.getQueryData(queryKey) as boolean

const makeQueryOption = () => ({
  queryKey,
  queryFn: () => Promise.resolve(loginLocalStorage.isExistUser()),
})

export const loadLoginStatus = (queryClient: QueryClient) => async () =>
  queryClient.getQueryData(queryKey) ?? (await queryClient.fetchQuery(makeQueryOption()))

export const useLoginStatusQuery = () => {
  const queryClient = useQueryClient()
  return {
    ...useQuery(makeQueryOption()),
    update: setLoginStatus(queryClient),
  }
}
