import React, {Fragment, useEffect} from 'react';
import {useForm} from "react-hook-form";
import alertify from 'alertifyjs'
import { ApiUtil3 } from '@biz/api'
import {IPLRegReq} from "../postpaidMoneyLimit/PomLimitManagementDialog";
import ALHistoryGrid from "../postpaidMoneyLimit/PLHistoryGrid";
import PomLimitAdjustment from "../postpaidMoneyLimit/PomLimitAdjustment";

const PomLimitAdjustmentInInquiryDialog = (props:any) => {
    const {watch} = useForm<IPLRegReq>();
    const {
        pomLimitValue, comment, projectConfigId
    } = watch();

    const formData = {
        pomLimitValue: pomLimitValue, comment, projectConfigId
    };

    useEffect(() => {
        /** 다이얼로그 */
        $('#PLAdjustInInquiryDialog').dialog({
            title: '한도 조정', closeOnEscape: true, width: 900,
        });

        $(document).on('click', '#cancelBtn', () => {
            $('#PLAdjustInInquiryDialog').dialog('close');
            props.setIsAdjustmentOfPomLimitDialogOpened(false);
            $('#PLAdjustInInquiryDialog').dialog('destroy');
        });

        $('#pomLimitValue').on('keyup', () => {
            let value = $('#pomLimitValue').val() as string;
            const regex = /^\d+$/;
            if (!regex.test(value)) {
                $('#pomLimitValue').val(value.replace(/[^\d]/g, ''));
            }
            if (value.length > 6) {
                $('#pomLimitValue').val(value.substring(0, 6));
            }
        });

        $('#comment_').on('keyup', () => {
            let value = $('#comment_').val() as string;
            const maxLengthInBytes = 500;
            const encoder = new TextEncoder();
            const encodedValue = encoder.encode(value);
            const length = encodedValue.length;

            if (length > maxLengthInBytes) {
                const slicedValue = encodedValue.slice(0, maxLengthInBytes);
                const decoder = new TextDecoder();
                value = decoder.decode(slicedValue);
                $('#comment_').val(value);
            }
            $('#byteCount_').text(length);
        })

        $('#reg').on('click', () => {
            formData.pomLimitValue = $('#pomLimitValue').val() as string;
            formData.comment = $('#comment_').val() as string;
            formData.projectConfigId = props.projectConfigId;

            if (formData.pomLimitValue === '') {
                alertify.error('한도액을 입력해주세요.');
                return;
            }

            let remain = props.pomPaySum / 10000;
            if (Number(formData.pomLimitValue) < remain) {
                alertify.error('현재 변경 가능한 최소 한도액은 ' +props.pomPaySum+ '원 입니다.\n 메이커의 사용으로 변동 될 수 있어 확인 후 다시 시도해 주세요. \n');
                return;
            }

            alertify.confirm('한도액을 ' + $('#pomLimitValue').val() + '만원으로 조정합니다.', () => {
                ApiUtil3.post<IPLRegReq>('/api/bzmAdmin/postpaidMoneyManagement/PL/modify', formData).then(() => {
                    $('#PLAdjustInInquiryDialog').dialog('close');
                    props.setIsAdjustmentOfPomLimitDialogOpened(false);
                    props.setCurrentPomLimitValue(Number(formData.pomLimitValue) * 10000);
                    props.getData();
                });
            }).set({labels: {cancel: '취소', ok: '확인'}}).setHeader('');
        });

        return () => {
            $('#PLAdjustInInquiryDialog').dialog('destroy');
        };
    }, []);

    return (
        <Fragment>
            <form>
                <div id='PLAdjustInInquiryDialog' className="dialog" style={{display: 'none'}}>
                    <div className="dialog-body">
                        <PomLimitAdjustment/>
                        <div className="wrap-section wrap-tbl">
                            <div className="box-header no-border">
                                <div className="box-tit">
                                    <span className="fz-16 fc-1">
                                        한도액
                                    </span>
                                </div>
                                <div className="box-option">
                                    <span className="fz-16 fc-1">{(props.currentPomLimitValue / 10000).toLocaleString()} 만원</span>
                                </div>
                            </div>
                            <div className="box-header no-border">
                                <div className="box-tit">
                                    <span className="fz-16 fc-1">
                                        한도 잔액
                                    </span>
                                </div>
                                <div className="box-option">
                                    <span className="fz-16 fc-1">{(props.pomRemain).toLocaleString()} 원</span>
                                </div>
                            </div>
                            <div className="box-header no-border">
                                <div className="box-tit">
                                    <span className="fz-16 fc-1">
                                        정산 예정 금액
                                    </span>
                                </div>
                                <div className="box-option">
                                    <span className="fz-16 fc-1">{(props.pomUseSum).toLocaleString()} 원</span>
                                </div>
                            </div>
                        </div>
                        <div className="wrap-section wrap-tbl">
                            <div className="box-header">
                                <div className="box-tit">
                                    <span className="fz-16 fc-1">
                                        히스토리
                                    </span>
                                </div>
                            </div>
                            <div className="box-body" style={{maxHeight: 500, overflow: 'auto'}}>
                                <div className="ag-grid">
                                    <div className="ag-grid-inner">
                                        <ALHistoryGrid projectConfigId={props.projectConfigId}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dialog-footer">
                        <button type="button" className="btn btn-secondary-outline" id={'cancelBtn'}>취소
                        </button>
                        <button type="button" className="btn btn-primary" id="reg">확인</button>
                    </div>
                </div>
            </form>
        </Fragment>
    );
};

export default PomLimitAdjustmentInInquiryDialog;
