import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { fetchCTAList } from '@biz/api'
import { useCurrentCampaignIdQuery } from '@biz/query'

type UseFunctionType = () => UseQueryResult<string[]>
export const useCTAListQuery: UseFunctionType = () => {
  const { data: campaignId } = useCurrentCampaignIdQuery()
  return useQuery({
    queryKey: ['@apps/biz-center/TargetCreatePage/CTAList', campaignId],
    queryFn: () =>
      !!campaignId ? fetchCTAList({ campId: campaignId }).then((res) => (res.ok ? res.data : [])) : Promise.resolve([]),
    enabled: Boolean(campaignId),
  })
}
