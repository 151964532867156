import { Tag } from 'antd'
import styles from './MetaInfo.module.scss'

type MetaInfoFunction = {
  meta?: {
    width: string // '1919'
    height: string // '1919'
    fileDiv?: string //  'IMG'
    ext?: string // 'png'
  }
}
export const MetaInfo: React.FC<MetaInfoFunction> = ({ meta }) => (
  <div className={styles.MetaInfo}>
    <Tag color={'#e9fdfd'}>
      <span style={{ color: '#00b2b2' }}>{meta?.fileDiv === 'VIDEO' ? '동영상' : '이미지'}</span>
    </Tag>
    <Tag color={'#eff2ff'}>
      <span style={{ color: '#557cf2' }}>{meta?.ext?.toUpperCase()}</span>
    </Tag>
  </div>
)
