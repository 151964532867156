import alertify from 'alertifyjs'
import { useCampaignStatus, useCreationsByAdSetQuery } from '@biz/query'
import { useAdSetInfoQuery, useAdSetTurnOnMutation } from './_queries'
import { AdSetSwitch } from './AdSetSwitch'
import styles from './AdSwitchGroup.module.scss'

const targetList = [
  { name: '논타겟 광고세트', tooltip: '사용자가 입력한 타겟 정보를 기반으로 생성되는 광고세트입니다.' },
  {
    name: '리타겟 광고세트',
    tooltip: '광고 효율 향상을 위해 와디즈에서 입력한 타겟 정보를 기반으로 생성되는 광고세트입니다.',
  },
  {
    name: '리타겟 광고세트(추가)',
    tooltip: '광고 효율 향상을 위해 와디즈의 데이터를 활용해 추가로 생성되는 광고세트입니다.',
  },
]
export const AdSwitchGroup: React.FC = () => {
  const { inOperation } = useCampaignStatus()
  const { invalidateQuery: invalidateCreationsByAdSetQuery } = useCreationsByAdSetQuery()
  const turnOn = useAdSetTurnOnMutation()
  const { data: adSetArray, refetch } = useAdSetInfoQuery()
  console.log(adSetArray, 'adSetArray')
  return (
    inOperation?.() && (
      <dl className={styles.containAdSwitchGroup}>
        {!!adSetArray &&
          adSetArray.map((adSet, index) => (
            <AdSetSwitch
              name={targetList[index].name}
              tooltip={targetList[index].tooltip}
              defaultValue={adSet.useYn}
              onChange={(onOff) => {
                turnOn
                  .mutateAsync({
                    useYn: onOff,
                    adSetId: adSet.id,
                  })
                  .then((res) => {
                    alertify.success(`"${targetList[index].name}"가 ${res?.data?.useYn ? 'ON' : 'OFF'} 되었습니다.`)
                    invalidateCreationsByAdSetQuery()
                    refetch()
                  })
              }}
            />
          ))}
      </dl>
    )
  )
}
