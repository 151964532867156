import { axios } from '../axios'
import { ApiUtil3 } from '../legacy'
import type { AttachedFileInfoType } from './fetchAttachedDocumentList'

type AttachParamType = Partial<{
  campAttachId: number
  project2Id: string
  campId: number
  afs: AttachedFileInfoType[]
}>

type ResponseType = {
  ok: boolean
  data: boolean
}

type FunctionType = (props: { param: AttachParamType; isModify: boolean }) => Promise<ResponseType>
export const fetchPostAttachedDocumentList: FunctionType = ({ param, isModify }) => {
  return ApiUtil3.post(isModify ? '/api/campAttach/modify' : '/api/campAttach', param).then((res) => res.data)
}
