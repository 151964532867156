import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import SingleDatePicker from '../SingleDatePicker';

const AccAdjustDialogTaxbillDetail = (props: any) => {
  const [adjustDiv] = useState<string>(props.adjustDiv);
  const [adjustDetail, setAdjustDetail] = useState<any>(undefined);

  const [regMinDate, setRegMinDate] = useState<Dayjs>(dayjs().startOf('day'));

  const updateSupDate = (start: any) => {
    setRegMinDate(start);
    props.setValue(`${adjustDiv}SupDate`, start.format('YYYYMMDD'));
  };

  const updateRegDate = (start: any) => {
    props.setValue(`${adjustDiv}RegDate`, start.format('YYYYMMDD'));
  };

  const updateSendDate = (start: any) => {
    props.setValue(`${adjustDiv}SendDate`, start.format('YYYYMMDD'));
  };

  useEffect(() => {
    props.moveToCenter();
  }, []);

  useEffect(() => {
    // 디버깅용 로그
    // window.console.log(`임의조정 ${adjustDiv === 'plus' ? '가산' : '감산'} 세금계산서 정보 변경됨(props)`);
    setAdjustDetail({ ...adjustDetail, ...props.adjustDetail });
  }, [props.adjustDetail]);

  useEffect(() => {
    // 디버깅용
    // window.console.log(`임의조정 ${adjustDiv === 'plus' ? '가산' : '감산'} 세금계산서 정보 변경됨(state)`);
    // window.console.table(adjustDetail);
  }, [adjustDetail]);

  return (
    <div className="wrap-section wrap-tbl" style={{ display: `${props.selectedTab === adjustDiv ? '' : 'none'}` }}>
      <div className="box-body">
        <div className="tbl">
          <dl className="vertical">
            <dd>
              <div className="form-group">
                {/* Wrap-Tablegrid : Start */}
                <section className="wrap-section wrap-tablegrid">
                  <div className="box-header">
                    <div className="box-tit">
                      <h2 className="fz-20 fc-1 fw-bold">발급 대상 정보</h2>
                    </div>
                  </div>
                  <div className="box-body">
                    <div className="tbl">
                      <table>
                        <colgroup>
                          <col width="10%"></col>
                        </colgroup>
                        <thead>
                        <tr>
                          <th>메이커ID</th>
                          <th>사업자번호/주민번호</th>
                          <th>구분</th>
                          <th>상호</th>
                          <th>대표자명 / 개인 성명</th>
                          <th>이메일</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td>{props.adjustDetail?.makerLoginId}</td>
                          <td>{props.adjustDetail?.userNo}</td>
                          <td>{props.adjustDetail?.bizDivDesc}</td>
                          <td>{props.adjustDetail?.comName}</td>
                          <td>{props.adjustDetail?.comRepNm}</td>
                          <td>{props.adjustDetail?.comEmail}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
                {/* Wrap-Tablegrid : Start */}

                {/* Wrap-Tablegrid : Start */}
                <section className="wrap-section wrap-tablegrid">
                  <div className="box-header">
                    <div className="box-tit">
                      <h2 className="fz-20 fc-1 fw-bold">발급 항목</h2>
                      <h3 className="fz-12 fc-3"><i className="fz-12 fc-5">*</i>공급일자와 발행일자 관계없이 임의조정이 진행 됩니다.</h3>
                    </div>
                  </div>
                  <div className="box-body">
                    <div className="tbl">
                      <table>
                        <colgroup>
                          <col width="10%"></col>
                          <col width="400"></col>
                        </colgroup>
                        <thead>
                        <tr>
                          <th>공급일자</th>
                          <th>항목</th>
                          <th>공급가액</th>
                          <th>부가세</th>
                          <th>합계</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td>
                            {/* Comp-Datepicker Single : Start */}
                            <SingleDatePicker changeEvent={updateSupDate} option={{ maxDate: dayjs().startOf('day') }} />
                            {/*
                            <div className="comp-datepicker">
                              <div className="inner-datepicker">
                                <i className="ico ico-calendar"></i>
                                <input type="text" className="tf-comm datepicker-single" id={`${adjustDiv}SupDate`} readOnly />
                              </div>
                            </div>
                            */}
                            {/* Comp-Datepicker Single : End */}
                          </td>
                          <td>
                            {/* Input-Group : Start */}
                            <div className="input-group expand">
                              <div className="inner-input-group">
                                <input type="text" className="tf-comm" maxLength={100} { ...props.register(`${adjustDiv}Comment`, { value: `임의조정 유상머니 ${adjustDiv === 'plus' ? '가산' : '감산'}` }) } />
                              </div>
                              <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                            </div>
                            {/* Input-Group : End */}
                          </td>
                          <td>{props.adjustDetail?.cost}</td>
                          <td>{props.adjustDetail?.vat}</td>
                          <td>{props.adjustDetail?.total}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
                {/* Wrap-Tbl : Start */}
                <div className="wrap-section wrap-tbl">
                  <div className="box-header">
                    <div className="box-tit">
                      <h2 className="fz-20 fc-1 fw-bold">세금계산서 일자 설정</h2>
                    </div>
                  </div>
                  <div className="box-body">
                    <div className="tbl">
                      <dl className="column-two">
                        <dt>
                          <div className="dt-inner">
                            <span className="fz-16 fc-1">작성일자 설정</span>
                          </div>
                        </dt>
                        <dd>
                          <div className="form-group">
                            {/* Comp-Datepicker Single : Start */}
                            <SingleDatePicker changeEvent={updateRegDate} option={{ minDate: regMinDate }} />
                            {/*
                            <div className="comp-datepicker">
                              <div className="inner-datepicker">
                                <i className="ico ico-calendar"></i>
                                <input type="text" className="tf-comm datepicker-single" id={`${adjustDiv}RegDate`} readOnly />
                              </div>
                            </div>
                            */}
                            {/* Comp-Datepicker Single : End */}
                          </div>
                        </dd>
                        <dt>
                          <div className="dt-inner">
                            <span className="fz-16 fc-1">발행일자 설정</span>
                          </div>
                        </dt>
                        <dd>
                          <div className="form-group">
                            {/* Comp-Datepicker Single : Start */}
                            <SingleDatePicker changeEvent={updateSendDate} option={{ minDate: dayjs().startOf('day') }} />
                            {/*
                            <div className="comp-datepicker">
                              <div className="inner-datepicker">
                                <i className="ico ico-calendar"></i>
                                <input type="text" className="tf-comm datepicker-single" id={`${adjustDiv}SendDate`} readOnly />
                              </div>
                            </div>
                            */}
                            {/* Comp-Datepicker Single : End */}
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
                {/* Wrap-Tbl : End */}
              </div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default AccAdjustDialogTaxbillDetail;
