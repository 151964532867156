import { Fragment, useEffect, useState } from 'react'
import AutoCharge from './AutoCharge'
import GeneralCharge from './GeneralCharge'

export interface PgParam {
  clientKey: string
  customerKey: string
  orderId: string
  orderName: string
  successUrl: string
  failUrl: string
}

const Charge = () => {
  const [adminMode, setAdminMode] = useState<boolean>(false)

  useEffect(() => {
    localStorage.setItem('chargeShowType', 'general')
    return () => {
      localStorage.removeItem('chargeShowType')
    }
  }, [])

  /** 관리자 전용으로 자동 충전 섹션 볼 수 있게 세팅 (ctrl + alt + shift + o) */
  const keys: boolean[] = []
  let keyDown = false
  const keysPressed = (e: any) => {
    if (keyDown) return
    keys[e.keyCode] = true
    if (keys[17] && keys[16] && keys[18] && keys[79]) {
      keyDown = true
      if (!adminMode) {
        setAdminMode(true)
      } else {
        setAdminMode(false)
      }
      keyDown = false
    }
  }
  const keysReleased = (e: any) => {
    keys[e.keyCode] = false
  }
  $(document).bind('keydown', (e) => {
    keysPressed(e)
  })
  $(document).bind('keyup', (e) => {
    keysReleased(e)
  })

  return (
    <Fragment>
      <GeneralCharge />
      {adminMode ? <AutoCharge /> : null}
    </Fragment>
  )
}

export default Charge
