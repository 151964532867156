import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import PomForAdminTab from "./PomForAdminTab";
import {ColDef} from "ag-grid-community";
import {useForm} from "react-hook-form";
import {useOutletContext} from "react-router";
import dayjs from "dayjs";
import alertify from 'alertifyjs'
import {AgGridReact} from "ag-grid-react";
import { ApiUtil2 } from '@biz/api'
import NoDataTemplate from "../../../common/NoDataTemplate";
import Pagination from "../../../common/Pagination";

interface IPomDailyUsageForAdmin {
    basicDate: string,
    convertedDate: string,
    usingPomMakerCnt:number,
    usingPomProjectCnt: number,
    usingPomServiceCnt: number,
    pom: number,
    returnBalance: number,
    exceedPom: number,
}
interface IPomDailyUsagesForAdminList {
    ok: boolean;
    data: IPomDailyUsageForAdmin[];
}

interface PomDailyUsageSrchForm {
    startDate: string,
    endDate: string,
    srchUnit: string,
}
const initTooltip = () => {
    $('#usingPomMakerCnt').tooltip({ content: '<p class="fz-12 fc-0 fw-medium">메이커 수</p><p class="fz-12 fc-0">해당 일자에 후불머니를 사용한 메이커의 수를 뜻합니다. (*중복 시, 1명의 메이커로 간주)</p>' });
    $('#usingPomProjectCnt').tooltip({ content: '<p class="fz-12 fc-0 fw-medium">후불머니 소진 프로젝트</p><p class="fz-12 fc-0">해당 일자에 후불머니를 사용한 프로젝트의 수를 뜻합니다. (*중복 시, 1개의 프로젝트로 간주)</p>' });
    $('#usingPomServiceCnt').tooltip({ content: '<p class="fz-12 fc-0 fw-medium">후불머니 소진 캠페인</p><p class="fz-12 fc-0">해당 일자에 후불머니를 사용한 캠페인의 수를 뜻합니다. (*중복 시, 1개의 캠페인으로 간주)</p>' });
    $('#pom').tooltip({ content: '<p class="fz-12 fc-0 fw-medium">후불머니 소진</p><p class="fz-12 fc-0">해당 일자에 발생한 후불머니 소진액을 뜻합니다.</p>' });
    $('#returnBalance').tooltip({ content: '<p class="fz-12 fc-0 fw-medium">후불머니 잔액반환</p><p class="fz-12 fc-0">해당 일자에 정산 후 사용되지 않은 캠페인의 후불머니가 사용 가능한 잔액으로 반환된 금액을 뜻합니다.</p>' });
    $('#exceedPom').tooltip({ content: '<p class="fz-12 fc-0 fw-medium">후불머니 초과</p><p class="fz-12 fc-0">해당 일자에 할당된 한도를 초과하여 발생된 금액을 뜻합니다.</p>' });
};
const PomDailyUsageForAdmin = () => {
    const setTitle = useOutletContext<any>();
    const childRef = useRef<any>(null);
    const [isDateValid, setIsDateValid] = useState<boolean>(true);
    const gridRef = useRef<AgGridReact>(null);
    const [rowData, setRowData] = useState<any[]>();
    const [columnDefs] = useState<ColDef[]>([
        {   field: 'basicDate',
            headerName: '날짜',
            cellClass: 'ag-center-aligned-cell',
            cellRenderer: (props:any) => {
                const year = props.data.basicDate.substring(0, 4);
                const month = props.data.basicDate.substring(4, 6);
                const day = props.data.basicDate.substring(6, 8);
                if (props.data.basicDate.length < 7) { /** 월간 조회 기준 */
                    return `${year}-${month}`;
                }
                if (props.data.convertedDate != null) {
                    return props.data.convertedDate;
                }
                return `${year}-${month}-${day}`;
            }
        },
        {
            field: 'usingPomMakerCnt',
            headerName: '후불머니 소진 메이커 수',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-right-aligned-cell',
            cellRenderer: (props:any) => (props.data.usingPomMakerCnt === 0 || props.data.usingPomMakerCnt === undefined ? '-' : props.data.usingPomMakerCnt.toLocaleString()),
            headerComponentParams: {
                template:
                    '<div class="ag-cell-label-container" role="presentation">'
                    + '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>'
                    + '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">'
                    + '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>'
                    + '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>'
                    + '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>'
                    + '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>'
                    + '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="usingPomMakerCnt" title=""></a>'
                    + '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>'
                    + '  </div>'
                    + '</div>',
            },
        },
        {
            field: 'usingPomProjectCnt',
            headerName: '후불머니 소진 프로젝트',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-right-aligned-cell',
            cellRenderer: (props:any) => (props.data.usingPomProjectCnt === 0 || props.data.usingPomProjectCnt === undefined ? '-' : props.data.usingPomProjectCnt.toLocaleString()),
            headerComponentParams: {
                template:
                    '<div class="ag-cell-label-container" role="presentation">'
                    + '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>'
                    + '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">'
                    + '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>'
                    + '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>'
                    + '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>'
                    + '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>'
                    + '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="usingPomProjectCnt" title=""></a>'
                    + '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>'
                    + '  </div>'
                    + '</div>',
            },
        },
        {
            field: 'usingPomServiceCnt',
            headerName: '후불머니 소진 캠페인',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-right-aligned-cell',
            cellRenderer: (props:any) => (props.data.usingPomServiceCnt === 0 || props.data.usingPomServiceCnt === undefined ? '-' : props.data.usingPomServiceCnt.toLocaleString()),
            headerComponentParams: {
                template:
                    '<div class="ag-cell-label-container" role="presentation">'
                    + '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>'
                    + '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">'
                    + '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>'
                    + '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>'
                    + '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>'
                    + '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>'
                    + '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="usingPomServiceCnt" title=""></a>'
                    + '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>'
                    + '  </div>'
                    + '</div>',
            },
        },
        {
            field: 'pom',
            headerName: '후불머니 소진',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-right-aligned-cell',
            cellRenderer: (props:any) => (props.data.pom === 0 || props.data.pom === undefined ? '-' : props.data.pom.toLocaleString()),
            headerComponentParams: {
                template:
                    '<div class="ag-cell-label-container" role="presentation">'
                    + '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>'
                    + '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">'
                    + '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>'
                    + '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>'
                    + '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>'
                    + '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>'
                    + '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="pom" title=""></a>'
                    + '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>'
                    + '  </div>'
                    + '</div>',
            },
        },
        {
            field: 'returnBalance',
            headerName: '후불머니 잔액반환',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-right-aligned-cell',
            cellRenderer: (props:any) => (props.data.returnBalance === 0 || props.data.returnBalance === undefined ? '-' : props.data.returnBalance.toLocaleString()),
            headerComponentParams: {
                template:
                    '<div class="ag-cell-label-container" role="presentation">'
                    + '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>'
                    + '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">'
                    + '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>'
                    + '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>'
                    + '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>'
                    + '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>'
                    + '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="returnBalance" title=""></a>'
                    + '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>'
                    + '  </div>'
                    + '</div>',
            },
        },
        {
            field: 'exceedPom',
            headerName: '후불머니 초과',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-right-aligned-cell',
            cellRenderer: (props:any) => (props.data.exceedPom === 0 || props.data.exceedPom === undefined ? '-' : props.data.exceedPom.toLocaleString()),
            headerComponentParams: {
                template:
                    '<div class="ag-cell-label-container" role="presentation">'
                    + '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>'
                    + '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">'
                    + '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>'
                    + '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>'
                    + '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>'
                    + '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>'
                    + '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="exceedPom" title=""></a>'
                    + '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>'
                    + '  </div>'
                    + '</div>',
            },
        },
    ]);
    const defaultColDef = useMemo<ColDef>(() => ({
        flex: 1,
        resizable: true,
        suppressMovable: true,
        sortable: true,
    }), []);

    const {
        handleSubmit, setValue, getValues, register
    } = useForm<PomDailyUsageSrchForm>();

    const onGridReady = useCallback(() => {
        const {
            startDate, endDate, srchUnit
        } = getValues();
        ApiUtil2.get<IPomDailyUsagesForAdminList>('/api/bzmAdmin/postpaidMoneyDetail/dailyUsage', {
            params: {
                data: {
                    startDate, endDate, srchUnit
                },
            },
        }).then((resp) => {
            initTooltip();
            setRowData(resp.data.data);
        });
    }, []);

    const downloadHandler = () => {
        window.location.assign(
            `/api/bzmAdmin/postpaidMoneyDetail/dailyUsage/download?startDate=${getValues('startDate')}&endDate=${getValues('endDate')}&srchUnit=${getValues('srchUnit')}`,
        );
    };
    const changePagination = () => {
        childRef.current!.onPaginationChanged();
    };
    useEffect(() => {
        setTitle('후불머니 이용내역');
        /** 초기 값 */
        setValue('startDate', dayjs().startOf('day').subtract(30, 'd').format('YYYYMMDD'));
        setValue('endDate', dayjs().startOf('day').format('YYYYMMDD'));
        const getDateDiff = (d1:any, d2:any) => {
            const date1 = new Date(d1);
            const date2 = new Date(d2);

            const diffDate = date1.getTime() - date2.getTime();

            return Math.abs(diffDate / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일
        };

        /** datepicker */
        $('#datepicker').daterangepicker({
            maxDate: dayjs().startOf('day'),
            startDate: dayjs().startOf('day').subtract(30, 'd'),
            endDate: dayjs().startOf('day'),
            opens: 'left',
            ranges: {
                '오늘 ': [dayjs().startOf('day'), dayjs().endOf('day')],
                '어제 ': [dayjs().startOf('day').subtract(1, 'days'), dayjs().endOf('day').subtract(1, 'days')],
                '이번 주': [dayjs().startOf('day').day(1), dayjs().day(1).endOf('day').subtract(-6, 'd')],
                '저번 주': [dayjs().startOf('day').subtract(1, 'week').day(1), dayjs().endOf('day').subtract(1, 'week').day(7)],
                '최근 7일': [dayjs().startOf('day').subtract(7, 'd'), dayjs().endOf('day').subtract(1, 'd')],
                '최근 30일': [dayjs().startOf('day').subtract(30, 'd'), dayjs().endOf('day').subtract(1, 'd')],
            },
        }, (start:any, end:any) => {
            const startDate = start.format('YYYYMMDD');
            const endDate = end.format('YYYYMMDD');
            if (getDateDiff(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')) > 92) {
                alertify.error('조회 기간은 최대 93일까지 설정할 수 있습니다.');
                setIsDateValid(false);
                return;
            }
            setIsDateValid(true);
            setValue('startDate', startDate);
            setValue('endDate', endDate);
        });

        $('#srchUnit').select2({
            width: '200',
            placeholder: '선택',
            minimumResultsForSearch: Infinity,
        }).on('select2:select', (e) => {
            setValue('srchUnit', e.params.data.id);
        });
        return () => {
            setTitle('');
        };
    }, []);

    return (
        <Fragment>
        <PomForAdminTab/>
            <div className="comp-tab-content selected">
                <section className="wrap-section wrap-datagrid">
                    <form onSubmit={handleSubmit(onGridReady)}>
                        <div className="wrap-filter">
                            <div className="inner-filter">
                                <div className="box-left">
                                    <div className="item-filter">
                                        <div className="filter-tit">
                                            <p className="fz-12 fc-2">조회기간</p>
                                        </div>
                                        <div className="box-filter">
                                            <div className="comp-datepicker">
                                                <div className="inner-datepicker">
                                                    <i className="ico ico-calendar"></i>
                                                    <input type="text" className="tf-comm datepicker-range" id='datepicker' readOnly/>
                                                    <span className="fz-12 fc-2">사용자 설정</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-filter">
                                        <div className="filter-tit">
                                            <p className="fz-12 fc-2">검색 구분</p>
                                        </div>
                                        <div className="box-filter">
                                            <select className="select2-single w-150"
                                                    id="srchUnit" {...register('srchUnit')}>
                                                <option value="DAY" defaultChecked={true}>일간</option>
                                                <option value="WEEK">주간</option>
                                                <option value="MONTH">월간</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="box-right">
                                    <button type="submit" className="btn btn-tertiary-mint btn-ico" disabled={!isDateValid}><i className="ico ico-filter"></i> 조회 </button>
                                </div>
                            </div>
                        </div>
                    </form>
                            <div className="box-header">
                                <div className="box-option">
                                    <button type="button" className="btn btn btn-secondary-outline btn-ico" onClick={downloadHandler}><i className="ico ico-download"></i>다운로드</button>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="ag-grid">
                                    <div className="ag-grid-inner">
                                        <AgGridReact
                                            ref={gridRef}
                                            rowData={rowData}
                                            columnDefs={columnDefs}
                                            defaultColDef={defaultColDef}
                                            rowHeight={48}
                                            pagination={true}
                                            paginationPageSize={10}
                                            suppressPaginationPanel={true}
                                            onPaginationChanged={changePagination}
                                            domLayout={'autoHeight'}
                                            noRowsOverlayComponent={NoDataTemplate}
                                        ></AgGridReact>
                                    </div>
                                </div>
                            </div>
                            { rowData === undefined ? <NoDataTemplate/> : null}
                            <div className="box-footer">
                                <Pagination gridRef={gridRef} ref={childRef}/>
                            </div>
                </section>
            </div>
        </Fragment>
    );
};

export default PomDailyUsageForAdmin;
