import { Fragment, useEffect, useRef, useState } from 'react'
import { bytesToSize } from '../fileUploader/MultiFileUploaderForConsulting'
import { Dialog } from 'rc-easyui'
import { fileDownHandler } from '../bizmoneyAdmin/postpaidMoney/pomInquiry/PomInquiryDetail'
import { Af } from './PomInquiryRegDialog'
import { ApiUtil2 } from '@biz/api'

export interface IPomInquiryDetailForMaker {
  projectNm: string
  text: string
  pomLimitValue: number
  afs: Af[]
  data: any
}

const PomInquiryDetailDialog = (props: any) => {
  const dialogRef = useRef<any>()
  const [fileCnt, setFileCnt] = useState<number>(0)
  const [projectNm, setProjectNm] = useState('')
  const [text, setText] = useState('')
  const [pomLimitValue, setPomLimitValue] = useState(0)
  const [afs, setAfs] = useState<Af[]>([])

  useEffect(() => {
    ApiUtil2.get<IPomInquiryDetailForMaker>('/api/pom/pomInquiry/detail?pomInquiryId=' + props.pomInquiryId).then(
      (resp) => {
        let result = resp.data.data
        setProjectNm(result.projectNm)
        setText(result.text)
        setPomLimitValue(result.pomLimitValue)
        setAfs(result.afs.map((v: Af) => new Af(v.fileName, v.fileSize, v.filePath)))
        setFileCnt(result.afs.length)
      }
    )
  }, [])

  useEffect(() => {
    $(document).on('click', '#cancelBtn', () => {
      props.setIsPomInquiryDetailDialogOpened(false)
    })
  }, [])

  return (
    <Fragment>
      <form>
        <Dialog title="한도관련 문의 내용" modal resizable shadow={false} ref={dialogRef} style={{ width: '900px' }}>
          <div className="dialog-body">
            <div className="wrap-section wrap-tbl">
              <div className="box-body">
                <div className="tbl">
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">선택 항목</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <p className="comp-txt">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-14 fc-4">{projectNm}</b>
                            </span>
                          </span>
                        </p>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">한도 신청 금액</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <p className="comp-txt">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-14 fc-4">{pomLimitValue} 만원</b>
                            </span>
                          </span>
                        </p>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">문의내용</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <div className="input-group small">
                          <div className="inner-input-group">
                            <textarea
                              className="tf-textarea"
                              id="text"
                              maxLength={500}
                              value={text}
                              disabled={true}
                              style={{ width: 500, minWidth: 500, minHeight: 300 }}
                            />
                          </div>
                        </div>
                      </div>
                    </dd>
                  </dl>
                  {afs.length > 0 ? (
                    <dl>
                      <dt></dt>
                      <dd>
                        <div className="form-group">
                          <div className="comp-file-list">
                            <div className="file-list-top">
                              <span className="fz-14 fc-2">
                                첨부 파일
                                <span className="fc-5">
                                  <span className="fc-2" id="fileCnt">
                                    {fileCnt}
                                  </span>
                                </span>
                                개
                              </span>
                            </div>
                            <div className="file-list-bottom">
                              {afs.map((af, key: number) => {
                                return (
                                  <div className="file-item" key={key} id={`file-${af.uploadId}`}>
                                    <i className="ico ico-file-list"></i>
                                    <span className="fz-12 fc-2">{af.fileName}</span>
                                    <span className="fz-12 fc-3">({bytesToSize(af.fileSize)})</span>
                                    <button onClick={() => fileDownHandler(af.filePath, af.fileName)}>
                                      <i className="ico ico-download"></i>
                                    </button>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      </dd>
                    </dl>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="dialog-footer">
            <button type="button" className="btn btn-primary" id={'cancelBtn'}>
              확인
            </button>
          </div>
        </Dialog>
      </form>
    </Fragment>
  )
}

export default PomInquiryDetailDialog
