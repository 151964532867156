import { axios } from '../axios'

type CreativeAssetDeleteParam = {
  campId: number
  id: number
}

type ReturnType = {
  ok: boolean
  data: string
}

type FunctionType = (data: Partial<CreativeAssetDeleteParam>) => Promise<ReturnType>
export const fetchPostCreativesDataDelete: FunctionType = (data) =>
  axios.post('/api/camp/make02/fbCreationDataDelete', data)
