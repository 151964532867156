import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getAIInspectionHistoryForMaker } from '@biz/api'

/**
 * 메이커용 AI 소재 검수 이력 조회
 * (legacy. 어드민용은 신규 API로 별도 조회)
 */
export const useAIInspectionHistoryForMakerQuery = (creationDataId: string | number, type: 'admin' | 'maker') => {
  const queryClient = useQueryClient()
  const queryKey = [`@biz/query/aiInspection/useAIInspectionHistoryForMaker/${creationDataId}`]

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const response = await getAIInspectionHistoryForMaker(creationDataId)

      if (response.ok === false) {
        throw new Error()
      }
      return response
    },
    enabled: !!creationDataId && type === 'maker',
    refetchOnMount: true,
    select: (response) => response.data,
  })

  const invalidateQuery = () => queryClient.invalidateQueries({ queryKey })

  return { ...query, invalidateQuery }
}
