import React, { useEffect, useRef } from 'react';
import { Options } from 'select2';

export const select2DefaultOption = {
  width: '150',
  minimumResultsForSearch: Infinity,
};

const SingleSelect2 = (props: any) => {
  const selectRef = useRef<any>();

  useEffect(() => {
    const options : Options = { ...select2DefaultOption, ...props.option, ...{ data: props.data } };
    $(selectRef.current).select2(options)
      .on('select2:select', (e: any) => {
        const value = e.params.data.id;
        if (value === '') props.selectEvent(undefined);
        else props.selectEvent(value);
      });
  }, []);

  return (
    <select id={props.id} className={`select2-single ${props.clss || 'w-150'}`} ref={selectRef}></select>
  );
};

export default SingleSelect2;
