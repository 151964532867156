import { useQuery, useQueryClient, type UseQueryResult, type QueryClient } from '@tanstack/react-query'
import { loginLocalStorage, fetchLoginCheckMakerLoginInfo, type MakerInfoType } from '@biz/api'
import { setLoginStatus } from '../useLoginStatusQuery'

const queryKey = ['@biz/query/auth/MakerInfo']
const makeQueryOption = (queryClient: QueryClient) => ({
  queryKey,
  queryFn: () =>
    fetchLoginCheckMakerLoginInfo().then((res) => {
      if (res?.data?.msg === undefined) {
        loginLocalStorage.setUserInfo(res.data)
        setLoginStatus(queryClient)(true)
      }
      return res
    }),
})

type UseFunctionType = () => UseQueryResult<{ result: MakerInfoType }>
export const useMakerInfoQuery: UseFunctionType = () => {
  const queryClient = useQueryClient()
  return useQuery(makeQueryOption(queryClient))
}

type LoadFunctionType = (queryClient: QueryClient) => () => Promise<MakerInfoType>
export const loadMakerInfo: LoadFunctionType = (queryClient) => async () => {
  return await queryClient.fetchQuery(makeQueryOption(queryClient))
}
