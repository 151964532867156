import { Form } from 'antd'
import { CampaignCaption, targetFormRegistry } from '@biz/ui'

import { useUpdateCampaign } from '../_hooks/useUpdateCampaign'
import { LocationInput } from './LocationInput'

export const LocationSelector: React.FC = () => {
  const doUpdate = useUpdateCampaign()
  return (
    <div>
      <CampaignCaption tooltip={'해당 지역에 거주하거나 위치한 사람들에게 광고를 표시합니다.'}>
        {'지역'}
      </CampaignCaption>
      <Form.Item name={targetFormRegistry.LocationSelectorName} required>
        <LocationInput onChange={doUpdate} />
      </Form.Item>
    </div>
  )
}
