import axios from 'axios'

export const uploadFileToS3 = async (presignUrl: string, file: File) => {
  try {
    const response = await axios.put(presignUrl, file, {
      headers: {
        'Content-Type': file.type,
      },
      responseType: 'json',
    })
  } catch (error) {
    console.error('Error uploading file:', error)
  }
}
