import { axios } from '../axios'
import { CategoryCode, CategoryInspectionConfig } from './category'

interface ResponseType {
  ok: boolean
  data: CategoryInspectionConfig
}

/**
 * 카테고리 코드 별 검수 정보 조회
 * @see https://wadiz.atlassian.net/l/cp/YsETfS1Z
 */
export const fetchCategoryByCode = (categoryCode: CategoryCode): Promise<ResponseType> =>
  axios.get(`/api/mngAd/creation/categoryInspMng/getCateInspConfig/${categoryCode}`)
