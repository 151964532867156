import { fetchDownloadFinanceReport } from '@biz/api'
export const useDownloadFile =
  () =>
  async ({ params, onLoading }: { params: string; onLoading: (loading: boolean) => void }) => {
    try {
      onLoading?.(true)
      const response = await fetchDownloadFinanceReport(params)
      const url = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = url

      const startDate = params.match(/startDate=(\d{8})/)?.[1] || ''
      const endDate = params.match(/endDate=(\d{8})/)?.[1] || ''
      link.setAttribute('download', `재무리포트-${startDate}~${endDate}.csv`)
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(url)
      link?.parentNode?.removeChild(link)
      onLoading?.(false)
    } catch (err) {
      console.error(err)
    }
  }
