import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {AgGridReact} from "ag-grid-react";
import { ApiUtil2 } from '@biz/api'
import {ColDef, GridSizeChangedEvent, ICellRendererParams} from "ag-grid-community";
import {useOutletContext} from "react-router-dom";
import {useForm} from "react-hook-form";
import NoDataTemplate from '../../../common/NoDataTemplate'
import Pagination from '../../../common/Pagination'
import PostpaidMoneyManagementTab from "../PostpaidMoneyManagementTab";
import RangeDatePicker from "../../../accAdjust/RangeDatePicker";
import dayjs, {Dayjs} from "dayjs";
import AODTManagementDialog from "./AODTManagementDialog";

interface IAODTSearchForm {
    startDate: string;
    endDate: string;
    aodtReqEnum: string;
    query?: string;
}

interface IAODT {
    data: any;
    serialId: string;
    makerNo: number;
    makerNm: string;
    projectDiv: string;
    projectId: number;
    serviceType: string;
    serviceId: number;
    serviceArea: string;
    basicFee: number;
    completeDate: string;
    amountFee: string;
    adjust: string;
    modifiedYn: boolean;
}
const AdjustmentOfDeductionTarget = () => {
    const setTitle = useOutletContext<any>();
    const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);
    const childRef = useRef<any>(null);
    const gridRef = useRef<AgGridReact>(null);
    const [serialId, setSerialId] = useState(null);
    const [rowData, setRowData] = useState<any[]>();
    const {
        register, handleSubmit, getValues, setValue,
    } = useForm<IAODTSearchForm>();
    const AODTAdjustRenderer = (e: any) => {
        const adjust = e.data.adjust === 0 && !e.data.modifiedYn ? '미조정' : e.data.adjust;
        return (
            <span>
          <a
              onClick={() => {
                  setIsDialogOpened(true);
                  setSerialId(e.data.serialId)
              }}
              style={{color: 'blue', textDecoration: 'underline'}}
              >
            {adjust.toLocaleString()}
          </a>
    </span>
        );
    };

    const onGridReady = useCallback(() => {
        const data: IAODTSearchForm = getValues();
        ApiUtil2.get<IAODT>('/api/bzmAdmin/postpaidMoneyManagement/AODT/list', {
            params: {
                data: {...data},
            },
        }).then((resp) => {
            setRowData(resp.data.data);
        });
    }, []);

    const onGridSizeChanged = (params: GridSizeChangedEvent) => {
        params.api.sizeColumnsToFit();
    };
    const changePagination = () => {
        childRef.current!.onPaginationChanged();
    };
    const costRenderer = (e: any) => (e.data.amountFee === 0 || e.data.amountFee === undefined ? '-' : e.data.amountFee.toLocaleString());
    const projectDivRenderer = (e: any) => (e.data.projectDiv === 'FUNDING' ? '펀딩' : '스토어');
    const serviceTypeRenderer = (e: any) => (e.data.serviceType === 'BIZ_AD' ? '광고' : '광고 대행');
    /** fixme 컨설팅 수수료 따로 안 정해져서 일단 0원 리턴함 */
    const basicFeeRenderer = (e: any) => (e.data.basicFee === 0 || e.data.basicFee === undefined ? '-' : (e.data.basicFee / 100) + '%');

    const [columnDefs] = useState<ColDef[]>([
        {
            field: 'serialId',
            headerName: '정산 Serial',
            cellClass: 'ag-left-aligned-cell',
        },
        {
            field: 'makerId',
            headerName: '메이커 번호',
            cellClass: 'ag-left-aligned-cell',
        },
        {
            field: 'makerNm',
            headerName: '메이커명',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
        },
        {
            field: 'projectDiv',
            headerName: '프로젝트 유형',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-center-aligned-cell',
            cellRenderer: projectDivRenderer,
        },
        {
            field: 'project2Id',
            headerName: '프로젝트 번호',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
        },
        {
            field: 'serviceType',
            headerName: '서비스 유형',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-center-aligned-cell',
            cellRenderer: serviceTypeRenderer,
        },
        {
            field: 'serviceId',
            headerName: '서비스 ID',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
        },
        {
            field: 'serviceArea',
            headerName: '서비스 영역',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-center-aligned-cell',
            cellRenderer: (props: ICellRendererParams) => {
                let serviceArea = props.data.serviceArea;
                if (serviceArea == 'FBITG') return '페이스북/인스타';
                else if (serviceArea == 'INTEGRATION_PLATFORM') return '통합광고 플랫폼';
                else if (serviceArea == 'FB') return '페이스북';
                else if (serviceArea == 'INSTAGRAM') return '인스타';
                else if (serviceArea == 'GG') return '구글DA';
                else if (serviceArea == 'BIZ_CENTER') return '비즈센터';
            }
        },
        {
            field: 'basicFee',
            headerName: '수수료율',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-center-aligned-cell',
            cellRenderer: basicFeeRenderer,
        },
        {
            field: 'completeDate',
            headerName: '정산 완료일',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-center-aligned-cell',
        },
        {
            field: 'amountFee',
            headerName: '최종 정산 금액',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-right-aligned-cell',
            cellRenderer: costRenderer,
        },
        {
            field: 'adjustedPayment',
            headerName: '조정금액',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-right-aligned-cell',
            cellRenderer: AODTAdjustRenderer
        },
    ]);

    const defaultColDef = useMemo<ColDef>(() => ({
        flex: 1,
        resizable: false,
        suppressMovable: true,
        sortable: true,
    }), []);

    useEffect(() => {
      setTitle('후불머니 관리')
      // onGridReady();
      return () => {
        setTitle('')
      }
    }, []);

    useEffect(() => {
        // 검색 구분
        $('#srchType').select2({
            width: '200',
            placeholder: '선택',
            minimumResultsForSearch: Infinity,
        }).on('select2:select', (e) => {
            setValue('aodtReqEnum', e.params.data.id);
        });
    }, []);

    const registerReqDate = (start:Dayjs, end:Dayjs) => {
        const startDate = start.format('YYYYMMDD');
        const endDate = end.format('YYYYMMDD');
        setValue('startDate', startDate);
        setValue('endDate', endDate);
    };

    return (
        <Fragment>
            {isDialogOpened ? <AODTManagementDialog setIsDialogOpened={setIsDialogOpened} onGridReady={onGridReady} serialId={serialId}/> : null}
            <PostpaidMoneyManagementTab/>
            <section className="wrap-section wrap-datagrid">
                <form onSubmit={handleSubmit(onGridReady)}>
                    <div className="wrap-filter">
                        <div className="inner-filter">
                            <div className="box-left">
                                <div className="item-filter">
                                    <div className="filter-tit">
                                        <p className="fz-12 fc-2">검색 구분</p>
                                    </div>
                                    <div className="box-filter">
                                        <select className="select2-single w-150"
                                                id="srchType" {...register('aodtReqEnum')}>
                                            <option value="PROJECT_ID" defaultChecked={true}>프로젝트 번호</option>
                                            <option value="SERIAL_ID">정산 Serial</option>
                                            <option value="MAKER_ID">메이커 번호</option>
                                            <option value="MAKER_NM">메이커명</option>
                                            <option value="SERVICE_ID">서비스ID</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="item-filter">
                                    <div className="filter-tit">
                                        <p className="fz-12 fc-2">조회 기간</p>
                                    </div>
                                    <div className="box-filter">
                                        <RangeDatePicker changeEvent={registerReqDate} option={{ startDate: dayjs().startOf('day').subtract(7, 'd') }} />
                                    </div>
                                </div>
                                <div className="item-filter">
                                    <div className="filter-tit">
                                        <p className="fz-12 fc-2">검색어</p>
                                    </div>
                                    <div className="box-filter">
                                        <div className="input-group comp-search">
                                            <div className="inner-input-group">
                                                <input type="text" className="tf-comm" id='query'
                                                       placeholder="검색어를 입력해 주세요." {...register('query')}></input>
                                                <i className="ico ico-search"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-right">
                                <button type="submit" className="btn btn-tertiary-mint btn-ico"><i
                                    className="ico ico-filter"></i>필터 조회
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="box-body">
                    <div className="ag-grid">
                        <div className="ag-grid-inner">
                            <AgGridReact
                                ref={gridRef}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                rowHeight={48}
                                pagination={true}
                                paginationPageSize={10}
                                suppressPaginationPanel={true}
                                onPaginationChanged={changePagination}
                                onGridSizeChanged={onGridSizeChanged}
                                domLayout={'autoHeight'}
                                noRowsOverlayComponent={NoDataTemplate}
                            />
                            { rowData === undefined ? <NoDataTemplate/> : null}
                        </div>
                    </div>
                </div>
                <div className="box-footer">
                    <Pagination gridRef={gridRef} ref={childRef}/>
                </div>
            </section>
        </Fragment>
    );
};

export default AdjustmentOfDeductionTarget;
