import { useEffect } from 'react'
import { Modal, Button } from 'antd'
import { usePomValidQuery } from './_queries'
import styles from './PayTypeSelectModal.module.scss'

type PayTypeSelectModalFunction = {
  isOpen: boolean
  onSelectType: (payType: 'POST_PAYMENT' | 'BIZ_MONEY') => void
}
export const PayTypeSelectModal: React.FC<PayTypeSelectModalFunction> = ({ isOpen, onSelectType }) => {
  const { data: pomInfo, isLoading } = usePomValidQuery(isOpen)
  useEffect(() => {
    if (isOpen && isLoading === false && !pomInfo?.data) {
      onSelectType('BIZ_MONEY')
    }
  }, [isOpen, onSelectType, pomInfo, isLoading])
  return (
    !isLoading &&
    !!pomInfo?.data && (
      <Modal
        width={450}
        open={isOpen}
        closable={false}
        title={
          <div className={styles.modalTitle}>
            <h3>광고비로 사용하실 머니 종류를 선택해주세요.</h3>
          </div>
        }
        footer={[
          <div className={styles.modalFooter} key={'footer'}>
            <Button
              type="default"
              block
              size="large"
              onClick={() => onSelectType('POST_PAYMENT')}
              style={{ width: 200 }}
            >
              {'후불머니'}
            </Button>
            <Button type="primary" block size="large" onClick={() => onSelectType('BIZ_MONEY')} style={{ width: 200 }}>
              {'비즈머니'}
            </Button>
          </div>,
        ]}
        className={styles.containPayTypeSelectModal}
      />
    )
  )
}
