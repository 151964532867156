import { Fragment, useEffect, useState } from 'react'
import qs from 'qs'
import { useNavigate } from 'react-router'
import { ApiUtil3 } from '@biz/api'
import PgSuccessDialog from './PgSuccessDialog'
import PgErrorDialog from './PgErrorDialog'
import alertify from 'alertifyjs'

const PgBillingAuthSuccess = () => {
  const [pgModalStatus, setPgModalStatus] = useState<any>(null)
  const [amount, setAmount] = useState<any>(null)
  const navigate = useNavigate()
  useEffect(() => {
    const params = qs.parse(window.location.search.replaceAll(/^\?/g, ''))
    if (params.code === 'INVALID_CARD_NUMBER' || params.customerKey === undefined || params.authKey === undefined) {
      console.log('잘못된 카드 번호')
      alertify.error('신용카드가 아니거나, 카드번호를 잘못 입력하셨습니다.')
      navigate('/bzm/charge')
    } else {
      const pgSuccess = {
        customerKey: params.customerKey,
        authKey: params.authKey,
      }

      setAmount(params.amount)
      /** 결제 로딩 다이얼로그 */
      $('#pgLoadingDialog').dialog({
        title: '결제 진행',
        width: 900,
        closeOnEscape: true,
      })
      ApiUtil3.post('/api/pg/billingAuth/success', pgSuccess).then((res) => {
        if (res.data === 'ok') {
          setTimeout(() => {
            setPgModalStatus('success')
          }, 1500)
        } else {
          setTimeout(() => {
            setPgModalStatus('error')
          }, 1500)
        }
      })
    }

    return () => {
      $('#pgLoadingDialog').dialog('destroy')
    }
  }, [])

  const AfterPgProcess = () => {
    if (pgModalStatus === 'success') {
      return <PgSuccessDialog setPgModalStatus={setPgModalStatus} chargeAmount={amount} />
    }
    if (pgModalStatus === 'error') {
      return <PgErrorDialog setPgModalStatus={setPgModalStatus} />
    }
    return null
  }

  return (
    <Fragment>
      <AfterPgProcess />
      <form>
        <div id="pgLoadingDialog" className="dialog" style={{ display: 'none' }}>
          <div className="dialog-body">
            <div className="wrap-section wrap-tbl">
              <div className="box-body">
                <div className="tbl">
                  <dl className="vertical">
                    <dd>
                      <div className="form-group">
                        <p className="comp-txt center">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-20 fc-1 fw-medium">결제가 진행 중 입니다.</b>
                              <b className="fz-20 fc-1 fw-medium">잠시만 기다려주세요.</b>
                            </span>
                          </span>
                        </p>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  )
}
export default PgBillingAuthSuccess
