import { useEffect } from 'react'

export const RegisterInquiryRedirect = () => {
  useEffect(() => {
    window.open(`${import.meta.env.VITE_WADIZ_HELP_CENTER}`)
    window.history.back()
  }, [])

  return null
}
