import React, {
  Fragment, forwardRef, useImperativeHandle, useEffect,
} from 'react';

const RptSrchFilter = (props: any, ref:any) => {
  const rptSrchFilterSrch = () => {
    const data:any = {};
    const projectDiv3Data:any = [];
    const campStatusData:any = [];
    const mediaDiv5Data:any = [];
    $('input[name="projectDiv3"]:checked').map((a:any, i:any) => projectDiv3Data.push($(i).val()));
    $('input[name="campStatus"]:checked').map((a:any, i:any) => {
      campStatusData.push($(i).val());
      if ($(i).val() === 'ing') {
        campStatusData.push('ing_update');
        campStatusData.push('ing_insp');
      } else if ($(i).val() === 'stop_creation_off') {
        campStatusData.push('stop_camp_off');
        campStatusData.push('stop_camp_range');
      } else {
        campStatusData.push('del_ready');
        campStatusData.push('del_complete');
      }
      return campStatusData;
    });
    $('input[name="mediaDiv5"]:checked').map((a:any, i:any) => mediaDiv5Data.push($(i).val()));
    data.projectDiv3 = projectDiv3Data;
    data.campViewStatus = campStatusData;
    data.mediaDiv5 = mediaDiv5Data;
    return data;
  };

  useImperativeHandle(ref, () => ({
    rptSrchFilterSrch,
  }));

  useEffect(() => {
    $('input[name="projectDiv3"]').on('click', () => {
      let checkCnt = $('input[name="projectDiv3"]:checked').length;
      if (checkCnt === 0) { $('input[name="projectDiv3"]').prop('checked', true); checkCnt = 3; }
      if (checkCnt !== 3) {
        $('#projectDiv3BadgeRed').removeClass('none');
        $('#srchFilterBadgeRed').removeClass('none');
      } else {
        $('#projectDiv3BadgeRed').addClass('none');
        if ($('input[name="campStatus"]:checked').length === 3 && $('input[name="mediaDiv5"]:checked').length === 6) $('#srchFilterBadgeRed').addClass('none');
      }
    });
    $('input[name="campStatus"]').on('click', () => {
      let checkCnt = $('input[name="campStatus"]:checked').length;
      if (checkCnt === 0) { $('input[name="campStatus"]').prop('checked', true); checkCnt = 3; }
      if (checkCnt !== 3) {
        $('#campStatusBadgeRed').removeClass('none');
        $('#srchFilterBadgeRed').removeClass('none');
      } else {
        $('#campStatusBadgeRed').addClass('none');
        if ($('input[name="projectDiv3"]:checked').length === 3 && $('input[name="mediaDiv5"]:checked').length === 6) $('#srchFilterBadgeRed').addClass('none');
      }
    });
    $('input[name="mediaDiv5"]').on('click', () => {
      let checkCnt = $('input[name="mediaDiv5"]:checked').length;
      if (checkCnt === 0) { $('input[name="mediaDiv5"]').prop('checked', true); checkCnt = 6; }
      if (checkCnt !== 6) {
        $('#mediaDiv5BadgeRed').removeClass('none');
        $('#srchFilterBadgeRed').removeClass('none');
      } else {
        $('#mediaDiv5BadgeRed').addClass('none');
        if ($('input[name="projectDiv3"]:checked').length === 3 && $('input[name="campStatus"]:checked').length === 3) $('#srchFilterBadgeRed').addClass('none');
      }
    });
  }, []);
  return (<Fragment>
            <div className="comp-dropdown">
                <a className="dropdown-toggle" data-toggle="dropdown" href="#javascript">
                    <div className="box-left">
                        <i className="ico ico-filter"></i>
                        <span className="fz-14 fc-2 fw-medium">조회 필터</span>
                        <span className="badge red none" id="srchFilterBadgeRed"></span>
                    </div>
                    <div className="box-right">
                        <i className="ico ico-arrow"></i>
                    </div>
                </a>
                <div className="dropdown-menu">
                    <ul className="opt-selectbox">
                        <li className="opt-menu selected" onClick={(e:any) => e.stopPropagation()} onKeyUp={(e:any) => e.handleKeyUp} role="presentation">
                            <p className="fc-1"><span className="badge red none" id="projectDiv3BadgeRed" ></span>프로젝트 유형</p>
                            <i className="ico ico-arrow"></i>
                            <ul className="opt-selectbox" >
                                <li className="opt-menu">
                                    <div className="comp-checkbox small">
                                        <input type="checkbox" id="projectDivFundingIng" name="projectDiv3" value="FUNDING_ING" defaultChecked/>
                                        <label htmlFor="projectDivFundingIng">펀딩 (펀딩 진행중)</label>
                                    </div>
                                </li>
                                <li className="opt-menu">
                                    <div className="comp-checkbox small">
                                        <input type="checkbox" id="projectDivFunding" name="projectDiv3" value="FUNDING" defaultChecked/>
                                        <label htmlFor="projectDivFunding">펀딩 (오픈 예정)</label>
                                    </div>
                                </li>
                                <li className="opt-menu">
                                    <div className="comp-checkbox small">
                                        <input type="checkbox" id="projectDivStore" name="projectDiv3" value="STORE" defaultChecked/>
                                        <label htmlFor="projectDivStore">스토어</label>
                                    </div>
                                </li>
                            </ul>
                        </li>
                        <li className="opt-menu" onClick={(e:any) => e.stopPropagation()} onKeyUp={(e:any) => e.handleKeyUp} role="presentation">
                            <p className="fc-1"><span className="badge red none" id="campStatusBadgeRed"></span>캠페인 상태</p>
                            <i className="ico ico-arrow"></i>
                            <ul className="opt-selectbox">
                                <li className="opt-menu">
                                    <div className="comp-checkbox small">
                                        <input type="checkbox" id="campStatusIng" name="campStatus" value="ing" defaultChecked/>
                                        <label htmlFor="campStatusIng">진행</label>
                                    </div>
                                </li>
                                <li className="opt-menu">
                                    <div className="comp-checkbox small">
                                        <input type="checkbox" id="campStatusStop" name="campStatus" value="stop_creation_off" defaultChecked />
                                        <label htmlFor="campStatusStop">중단</label>
                                    </div>
                                </li>
                                <li className="opt-menu">
                                    <div className="comp-checkbox small">
                                        <input type="checkbox" id="campStatusEnd" name="campStatus" value="complete" defaultChecked/>
                                        <label htmlFor="campStatusEnd">종료</label>
                                    </div>
                                </li>
                            </ul>
                        </li>
                        <li className="opt-menu" onClick={(e:any) => e.stopPropagation()} onKeyUp={(e:any) => e.handleKeyUp} role="presentation">
                            <p className="fc-1"><span className="badge red none" id="mediaDiv5BadgeRed"></span>노출 매체</p>
                            <i className="ico ico-arrow"></i>
                            <ul className="opt-selectbox">
                                <li className="opt-menu">
                                    <div className="comp-checkbox small">
                                        <input type="checkbox" id="mediaDivFb" name="mediaDiv5" value="facebook" defaultChecked/>
                                        <label htmlFor="mediaDivFb">페이스북</label>
                                    </div>
                                </li>
                                <li className="opt-menu">
                                    <div className="comp-checkbox small">
                                        <input type="checkbox" id="mediaDivItg" name="mediaDiv5" value="instagram" defaultChecked/>
                                        <label htmlFor="mediaDivItg">인스타그램</label>
                                    </div>
                                </li>
                                <li className="opt-menu">
                                    <div className="comp-checkbox small">
                                        <input type="checkbox" id="mediaDivAn" name="mediaDiv5" value="audience_network" defaultChecked/>
                                        <label htmlFor="mediaDivAn">오디언스 네트워크</label>
                                    </div>
                                </li>
                                <li className="opt-menu">
                                    <div className="comp-checkbox small">
                                        <input type="checkbox" id="mediaDivMsg" name="mediaDiv5" value="messenger" defaultChecked/>
                                        <label htmlFor="mediaDivMsg">메신저</label>
                                    </div>
                                </li>
                                <li className="opt-menu">
                                    <div className="comp-checkbox small">
                                        <input type="checkbox" id="mediaDivUnk" name="mediaDiv5" value="unknown" defaultChecked/>
                                        <label htmlFor="mediaDivUnk">알 수 없음</label>
                                    </div>
                                </li>
                                <li className="opt-menu">
                                    <div className="comp-checkbox small">
                                        <input type="checkbox" id="mediaDivGdn" name="mediaDiv5" value="gdn" defaultChecked/>
                                        <label htmlFor="mediaDivGdn">구글</label>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </Fragment>
  );
};

export default forwardRef(RptSrchFilter);
