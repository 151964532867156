import { ReactComponent as AttachmentIcon } from '@common/svg/attachment.svg'
import { ReactComponent as CloseIcon } from '@common/svg/close.svg'
import styles from './FileDescriptor.module.scss'

type FileDescriptorFunction = {
  name: string
  size: string
  onDownload: () => void
  onDelete: () => void
}
export const FileDescriptor: React.FC<FileDescriptorFunction> = ({ name, size, onDownload, onDelete }) => (
  <div className={styles.FileDescriptor}>
    <div className={styles.fileInfo} onClick={onDownload}>
      <div>
        <AttachmentIcon style={{ height: 12, fill: '#ADB5BD' }} />
      </div>
      <div className={styles.name}>{name}</div>
      <div className={styles.size}>{size}</div>
    </div>
    <button onClick={onDelete}>
      <CloseIcon />
    </button>
  </div>
)
