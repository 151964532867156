import { axios } from '../axios'

export type PomValidationResult = {
  campBudgetValid: boolean
  campPeriodValid: boolean
  creationCnt: number //  1
  creationCorrectCnt: number // 1
  minCampBudgetTotal1: number // 15000
  projectValid: boolean
}

type ReturnType = {
  ok: boolean
  data: PomValidationResult | boolean
}

// 2024.10.22 - 원래 creationDataId도 파라미터로 보내게 되어 있는데 이 파라미터는 의미 없는 것으로 판단되어 제거함
type FunctionType = (campId: string | number) => Promise<ReturnType>
export const fetchPomValidation: FunctionType = (campId) => axios.get(`/api/camp/make02/pomValid?campId=${campId}`)
