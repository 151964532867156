import styles from './Tip.module.scss'

type TipFunction = {
  children: React.ReactNode
}
export const Tip: React.FC<TipFunction> = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.tip}>{'TIP'}</div>
      <div className={styles.content}>{children}</div>
    </div>
  )
}
