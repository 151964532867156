import { useRef } from 'react'
import { Switch } from 'antd'
import alertify from 'alertifyjs'
import { TooltipHelp } from '@biz/ui'
import styles from './AdSetSwitch.module.scss'

type AdSetSwitchFunction = {
  name: string
  tooltip: string
  defaultValue: boolean
  onChange: (onOff: boolean) => void
}
export const AdSetSwitch: React.FC<AdSetSwitchFunction> = ({ name, tooltip, defaultValue, onChange }) => {
  const switchRef = useRef(defaultValue ?? true)
  return (
    <dd className={styles.containCampaignStatus}>
      <div className={styles.statusCell}>
        <div>{name}</div>
        <TooltipHelp tooltip={tooltip} />
        <div className={styles.status}>
          <Switch
            value={switchRef.current}
            defaultValue={defaultValue}
            onChange={(onOff) => {
              switchRef.current = onOff
              onChange(onOff)
            }}
          />
        </div>
      </div>
    </dd>
  )
}
