import { Tooltip } from 'antd'
import { ReactComponent as HelpIcon } from '@common/svg/helpOutline.svg'
import styles from './TooltipHelp.module.scss'

type TooltipHelpFunction = {
  tooltip: React.ReactNode
}
export const TooltipHelp: React.FC<TooltipHelpFunction> = ({ tooltip }) => (
  <div className={styles.TooltipHelp}>
    <Tooltip title={tooltip} arrow={false} placement="bottomLeft" trigger="hover">
      <div className={styles.icon}>
        <HelpIcon />
      </div>
    </Tooltip>
  </div>
)
