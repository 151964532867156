import { useQuery, type UseQueryResult, type QueryClient } from '@tanstack/react-query'
import { fetchAdditionalCampaignInfo, type AdditionalCampaignInfo } from '@biz/api'
import { useCurrentCampaignIdQuery, loadCurrentCampaignId } from '../useCurrentCampaignIdQuery'

const makeQueryKey = (campaignId?: number) => ['@biz/query/createTargetAd/useAdditionalCampaignInfo', campaignId || 0]
const makeQueryOption = (campaignId?: number) => ({
  queryKey: makeQueryKey(campaignId),
  queryFn: () => (!!campaignId ? fetchAdditionalCampaignInfo(campaignId).then((res) => res.data) : Promise.reject()),
  enabled: Boolean(campaignId),
})

type UseFunctionType = () => UseQueryResult<AdditionalCampaignInfo>
export const useAdditionalCampaignInfoQuery: UseFunctionType = () => {
  const { data: campaignId } = useCurrentCampaignIdQuery()
  return useQuery(makeQueryOption(campaignId || undefined))
}

type LoadFunctionType = (queryClient: QueryClient) => () => Promise<AdditionalCampaignInfo>
export const loadAdditionalCampaignInfo: LoadFunctionType = (queryClient) => async () => {
  const campaignId = await loadCurrentCampaignId(queryClient)()
  return await queryClient.fetchQuery(makeQueryOption(campaignId || undefined))
}
