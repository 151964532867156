import { useCallback } from 'react'
import { type UseMutationResult } from '@tanstack/react-query'
import { Upload, Button } from 'antd'
import { ReactComponent as StartIcon } from '@common/svg/star.svg'
import { bytesToSize } from '@common/utils'
import { type AttachedDocumentType } from '../_queries/useAttachDocumentQuery'
import { FileDescriptor } from './FileDescriptor'
import { fileDownload, generateBeforeUpload } from './_util'

import styles from './DocumentUploader.module.scss'

type DocumentUploaderProp = {
  value: AttachedDocumentType[]
  onChange: (v: AttachedDocumentType[]) => void
  uploader: UseMutationResult<unknown, unknown, { file: File }, unknown>
}
export const DocumentUploader: React.FC<DocumentUploaderProp> = ({ value: fileList, onChange, uploader }) => {
  const doDelete = useCallback(
    (file: AttachedDocumentType) => {
      onChange((fileList || []).filter((n) => n.filePath !== file.filePath))
    },
    [fileList]
  )
  return (
    <div className={styles.DocumentUploader}>
      <div className={styles.titleArea}>
        <h3>
          {'첨부 파일'}
          <StartIcon style={{ marginLeft: 4 }} />
        </h3>
      </div>
      <div className={styles.comment}>
        {'광고를 진행하는 소재의 정보 진위를 확인할 수 있는 서류를 올려주세요. (ex.상표권, 상품 판매 인증서류 등)'}
      </div>
      <div className={styles.uploadList}>
        {(!fileList || fileList.length < 1) && <div className={styles.placeholder}>{'첨부 파일 업로드'}</div>}
        {!!fileList && fileList?.length > 0 && (
          <div className={styles.downloadFileList}>
            {fileList.map((n, index) => {
              const name = n.file?.name || n.fileName || '' /* type guard */
              const size = n.file?.size || n.fileSize
              const path = n.publicUrl || n.filePath
              const modified = n.lastModified
              return (
                <FileDescriptor
                  key={`${name}${index}`}
                  name={name}
                  size={`(${bytesToSize(Number(size))}) ${!modified ? '' : '업로드 일시 ' + modified}`}
                  onDownload={() => fileDownload(path, name)}
                  onDelete={() => doDelete(n)}
                />
              )
            })}
          </div>
        )}
        <Upload
          accept="image/jpeg, image/jpg, image/png, application/pdf"
          beforeUpload={generateBeforeUpload(fileList || [])}
          customRequest={({ file }) => uploader.mutateAsync({ file: file as File })}
          multiple
          maxCount={10}
          showUploadList={false}
        >
          <Button type="text" className={styles.button}>
            {'파일 첨부'}
          </Button>
        </Upload>
      </div>
    </div>
  )
}
