import { axios } from '../axios'
import { ApiUtil3 } from '../legacy'

type RequestData = {
  uploadId: number
  originFileName: string
  myS3Upload: 'camp_attach' | 'creationFb'
}

type UploadInfo = {
  fileId: number
  presignUrl: string
  publicUrl: string
}

type ResponseType = {
  ok: boolean
  data: Partial<UploadInfo>
}

type FunctionType = (props: RequestData) => Promise<Partial<ResponseType>>
export const fetchPostUpload: FunctionType = (props) =>
  ApiUtil3.post('/api/file/uploadReq', props).then((res) => res.data)
