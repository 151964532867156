import React from 'react'
import { LabelBadge } from '@platform/share/ui'
import styles from './CreativeStatusLabels.module.scss'

interface CreativeStatusLabelsProps {
  isAIAutoInspectionCategory: boolean // AI 자동 검수 카테고리 여부
  inspNeedStatus: string // 검수 필요 상태
  wabizInspStatus: string // 검수 상태
  catCodeDesc: string // 카테고리명
  creationViewStatus: string // 소재 상태
}

/**
 * 소재 상태 라벨 목록
 */
export const CreativeStatusLabels = ({
  inspNeedStatus,
  catCodeDesc,
  isAIAutoInspectionCategory,
  wabizInspStatus,
  creationViewStatus,
}: CreativeStatusLabelsProps) => {
  // 검수 필요 상태 라벨
  const NeedInspectionStatus = () => {
    const needInspection =
      inspNeedStatus === 'invalid_must' || inspNeedStatus === 'invalid_store' || inspNeedStatus === 'invalid_proh_kwd'

    const text = isAIAutoInspectionCategory
      ? 'AI 자동 검수'
      : needInspection
      ? '검수 필요'
      : inspNeedStatus === 'valid'
      ? '일반'
      : ''

    return (
      <LabelBadge
        size="md"
        value={text}
        color={isAIAutoInspectionCategory ? 'gray' : needInspection ? 'red' : 'gray'}
        variant="tint"
      />
    )
  }

  // 카테고리명
  const CategoryName = () => <LabelBadge size="md" value={catCodeDesc} color="gray" variant="tint" />

  // 와디즈 검수 상태 라벨
  const InspectionResult = () => {
    if (!wabizInspStatus || wabizInspStatus === 'ing' || wabizInspStatus === 'none') return null

    const text = creationViewStatus === 'stop_by_admin' ? '중단' : wabizInspStatus === 'ok' ? '승인' : '반려'
    return <LabelBadge size="md" value={text} color={wabizInspStatus === 'ok' ? 'mint' : 'red'} variant="tint" />
  }

  return (
    <div className={`${styles.container}`}>
      <NeedInspectionStatus />
      <CategoryName />
      <InspectionResult />
    </div>
  )
}
