import { Link } from 'react-router-dom'
import styles from './LogoHeader.module.scss'

export const LogoHeader: React.FC = () => (
  <header className={styles.LogoHeader}>
    <h1 className={styles.logo}>
      <Link to="#" />
    </h1>
  </header>
)
