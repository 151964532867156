import dayjs from 'dayjs'
import { Tooltip } from 'antd'
import { thousandsGrouping } from '@common/utils'
import { ReactComponent as HelpIcon } from '@common/svg/help.svg'
import { type PaymentInfoType } from '@biz/api'
import { StarComment } from '../../StarComment'
import styles from './PaymentInfo.module.scss'

const DepositTooltip = () => (
  <Tooltip
    title={
      '캠페인 보증금은 예산을 초과하여 광고가 집행되는 경우를 위한 예비 금액입니다. 광고비가 초과 발생하지 않을 경우 캠페인 종료 이후 반환됩니다.'
    }
    arrow={false}
    placement="bottomLeft"
    trigger="hover"
  >
    <div className={styles.icon}>
      <HelpIcon />
    </div>
  </Tooltip>
)
type PaymentInfoFunction = {
  info?: PaymentInfoType
}
export const PaymentInfo: React.FC<PaymentInfoFunction> = ({ info }) => {
  const {
    campBudgetTotal1,
    campBudgetTotal2,
    campBudgetTotalExp,
    campBudgetTotalVat,
    campBuff,
    campEndTime,
    campId,
    campPeriod,
    campStartTime,
    project2Id,
    wadizFee,
  } = info || {}
  return (
    <div className={styles.PaymentInfo}>
      <dl>
        <dt>{'캠페인 기간'}</dt>
        <dd>
          {`${campPeriod}일 (${!campStartTime ? '미정' : dayjs(campStartTime).format('YYYY-MM-DD')} ~ ${
            !campEndTime ? '미정' : dayjs(campEndTime).format('YYYY-MM-DD')
          })`}
        </dd>
      </dl>
      <dl>
        <dt>{'캠페인 총 예산'}</dt>
        <dd>{`${thousandsGrouping(campBudgetTotal1 || 0)} 원`}</dd>
      </dl>
      <dl>
        <dt>{'와디즈 수수료'}</dt>
        <dd>{`${thousandsGrouping(wadizFee || 0)} 원`}</dd>
        <dt>
          <span>
            {'캠페인 보증금'}
            <DepositTooltip />
          </span>
        </dt>
        <dd style={{ width: 160 }}>{`${thousandsGrouping(campBuff || 0)} 원`}</dd>
      </dl>
      <dl>
        <dt>{'예상 총 비용'}</dt>
        <dd>{`${thousandsGrouping(campBudgetTotalExp || 0)} 원`}</dd>
      </dl>
      <dl>
        <dt />
        <dd>
          <StarComment>{'실제 비용은 SNS 매체에서 발생한 금액에 따라 변경됩니다.'}</StarComment>
          <StarComment>{'잔액은 캠페인 종료 후 반환됩니다.'}</StarComment>
        </dd>
      </dl>
    </div>
  )
}
