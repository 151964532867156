import React, { forwardRef } from 'react'
import cx from 'classnames'
import styles from './LabelBadge.module.scss'

type Size = 'sm' | 'md' | 'lg'
type Color = 'mint' | 'gray' | 'blue' | 'red' | 'yellow'
type Variant = 'solid' | 'tint' | 'outlined'
export interface LabelBadgeProps {
  size?: Size
  value: string | number
  color?: Color
  circular?: boolean
  variant?: Variant
  className?: string
}

//TODO: waffle 코드 복붙해옴 antd tag -> label로 변경 필요
export const LabelBadge = forwardRef<HTMLSpanElement, LabelBadgeProps>(
  ({ size = 'sm', color = 'mint', circular = false, variant = 'solid', value, className }, ref) => {
    const badgeClasses = cx(
      styles.badge,
      size && {
        [styles.sm]: size === 'sm',
        [styles.md]: size === 'md',
        [styles.lg]: size === 'lg',
      },
      color && {
        [styles.mint]: color === 'mint',
        [styles.gray]: color === 'gray',
        [styles.blue]: color === 'blue',
        [styles.red]: color === 'red',
        [styles.yellow]: color === 'yellow',
      },
      variant && {
        [styles.solid]: variant === 'solid',
        [styles.tint]: variant === 'tint',
        [styles.outlined]: variant === 'outlined',
      },
      { [styles.circular]: circular },
      className
    )

    return (
      <span ref={ref} className={badgeClasses}>
        {value}
      </span>
    )
  }
)
