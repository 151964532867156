import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { fetchPostCampaignTurnOn } from '@biz/api'
import { useCurrentCampaignIdQuery } from '@biz/query'

type FunctionType = () => UseMutationResult<
  { ok?: boolean; data?: { campId?: number; useYn?: boolean } },
  unknown,
  boolean,
  unknown
>
export const useCampaignTurnOnMutation: FunctionType = () => {
  const { data: campaignId } = useCurrentCampaignIdQuery()
  return useMutation({
    mutationFn: (turnOn) =>
      !!campaignId ? fetchPostCampaignTurnOn({ campId: campaignId, useYn: turnOn }) : Promise.reject('campaign id'),
  })
}
