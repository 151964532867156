import React, { Fragment } from 'react';
import { useNavigate } from 'react-router';
import NoticeForHome from '../views/maker/home/NoticeForHome';
import BellAlertForHome from '../views/maker/home/BellAlertForHome';
import InquiryForHome from '../views/maker/home/InquiryForHome';

const HomeForMaker = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      {/* <div className="box-middle"> */}
      {/*    <div className="box-left"> */}
      {/*        <div className="txt"> */}
      {/*            <img */}
      {/*                src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/homeBanner/Home-Banner-Text-01.png" */}
      {/*                alt={'homeBanner1'}/> */}
      {/*        </div> */}
      {/*        <button type="button" className="btn btn-primary-txt btn-ico large" */}
      {/*                onClick={() => navigate('/camp/make02')}>캠페인 만들기 */}
      {/*            <i className="ico ico-arrow"></i></button> */}
      {/*    </div> */}
      {/*    <div className="box-right"> */}
      {/*        <div className="txt"> */}
      {/*            <img */}
      {/*                src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/homeBanner/Home-Banner-Text-02.png" */}
      {/*                alt={'homeBanner2'}/> */}
      {/*        </div> */}
      {/*        <button type="button" className="btn btn-primary-txt btn-ico large" */}
      {/*                onClick={() => navigate('/camp/make01/req')}>광고 대행 받기 */}
      {/*            <i className="ico ico-arrow"></i></button> */}
      {/*    </div> */}
      {/* </div> */}
      <div className="box-middle type1">
        <div className="box-left">
          <div className="txt">
            <img
              src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/homeBanner/Home-Banner-Text-01.png"
              alt={'homeBanner'}
            />
          </div>
          <button type="button" className="btn btn-primary-txt btn-ico large" onClick={() => navigate('/camp/make02')}>
            캠페인 만들기<i className="ico ico-arrow"></i>
          </button>
        </div>
      </div>
      <div className="box-bottom">
        <div className="container-fluid">
          <NoticeForHome />
        </div>
        <div className="row">
          <div className="col col-8">
            <BellAlertForHome />
          </div>
          <InquiryForHome />
        </div>
      </div>
    </Fragment>
  )
};
export default React.memo(HomeForMaker);
