import { Checkbox, Radio } from 'antd';

type CheckAllFunction<T> = {
  style?: React.CSSProperties;
  value?: T;
  options: { label: string; value: string }[];
  onChange?: (value: T) => void;
};
export const CheckAll: React.FC<CheckAllFunction<string>> = ({
  style,
  value,
  options,
  onChange,
}) => (
  <Radio.Group
    options={options}
    onChange={(v) => onChange?.(v.target.value)}
    value={value}
    style={style}
  />
);

//   {
//   const isAll = value?.length === options.length;
//   return (
//     <>
//       <Checkbox
//         checked={isAll}
//         style={{ margin: 4, paddingBottom: 6 }}
//         onChange={() => {
//           onChange?.(isAll ? [] : options.map((item) => item.value));
//         }}
//       >
//         {'전체'}
//       </Checkbox>
//       <Checkbox.Group
//         defaultValue={options.map((item) => item.value)}
//         value={value}
//         style={style}
//         options={options}
//         onChange={(v) => {
//           onChange?.(v);
//         }}
//       />
//     </>
//   );
// };
