import type { RouteObject } from 'react-router-dom'
import type { QueryClient } from '@tanstack/react-query'

import { loadLoginCheck } from '@biz/query'

import { DefaultLayout } from '../DefaultLayout'

import { Creation } from './CreativeManagerList'
import { CategoryInspMng } from './CreativeManagerList/CategoryManager'
import { CreationCheckMng } from './CreativeManagerList/SelfCheckListManager'
import { CreationDetailEmpty, CreationDetailFbAll } from './CreativeManagerDetail'

import { MngAdCampList } from './CampaignList'
import { MngAdCampListDetail } from './CampaignDetail'

import { AIInspectionHistory } from './AIInspectionHistory'

import { Consulting, ConsultingDetail, ConsultingSetting } from './AdConsulting'

type FunctionType = (queryClient: QueryClient) => RouteObject

// 관리자 > 광고 관리 Route
export const getAdminAdManagerRoute: FunctionType = (queryClient: QueryClient) => ({
  element: <DefaultLayout />,
  children: [
    { path: '/mngAd/creation', element: <Creation /> }, // 소재 관리
    { path: '/mngAd/creation/categoryInspMng', element: <CategoryInspMng /> }, // 소재 관리 > 카테고리 관리
    { path: '/mngAd/creation/creationCheckMng', element: <CreationCheckMng /> }, // 소재 관리 > 셀프 체크리스트 관리
    { path: '/mngAd/creationDetail/all', element: <CreationDetailFbAll /> }, // 소재 관리 > 일괄 검수
    { path: '/mngAd/creationDetail/:mediaDiv4/:campId', element: <CreationDetailEmpty /> }, // 소재 관리 > 상세

    { path: '/mngAd/camp', element: <MngAdCampList /> }, // 캠페인 조회
    { path: '/mngAd/camp/detail/:campId/:rptStartDate/:rptEndDate', element: <MngAdCampListDetail /> }, // 캠페인 조회 > 상세

    { path: '/mngAd/gptHis', element: <AIInspectionHistory /> }, // AI 소재 검수 현황

    { path: '/mngAd/consulting', element: <Consulting /> }, // 광고 대행 관리
    { path: '/mngAd/consulting/detail/:consultingId', element: <ConsultingDetail /> }, // 광고 대행 관리 상세
    { path: '/mngAd/consulting/setting', element: <ConsultingSetting /> },
  ],
})
