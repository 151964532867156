import { Typography, Divider, Flex } from 'antd';
import { Breadcrumb } from '@platform/share/ui'
import { PageTitle } from '@biz/ui'
import { PeriodPicker } from './PeriodPicker'

const { Title } = Typography

export const FinanceDpPush = () => (
  <section style={{ width: 1140 }}>
    <Breadcrumb path={['리포트 관리', 'DP/PUSH : 재무 리포트']} />
    <Flex gap={40} vertical style={{ marginTop: 24 }}>
      <PageTitle>{'DP/PUSH : 재무 리포트'}</PageTitle>
      <Flex vertical>
        <Title level={3}>{'만들기'}</Title>
        <Divider style={{ margin: 0 }} />
      </Flex>
      <PeriodPicker />
    </Flex>
  </section>
)
