import { useState, useEffect, useRef } from 'react'
import { Tooltip, Switch } from 'antd'
import alertify from 'alertifyjs'

import { ReactComponent as HelpIcon } from '@common/svg/help.svg'
import {
  useAdditionalCampaignInfoQuery,
  useCampaignInfoQuery,
  useCreativeDataQuery,
  useCreationsByAdSetQuery,
  useCurrentCampaignIdQuery,
  useAdSetCreationOnOffMutation,
} from '@biz/query'
import { creationViewStatusTable, fetchPostAdSetCreationOnOff } from '@biz/api'
import type { CreationViewStatusType, CreationByAdSet } from '@biz/api'

import { ReactComponent as CopyIcon } from '../../../../../../../assets/images/Btn-Ico-Copy-16x16.svg'

import styles from './AssetStatusBadge.module.scss'

type DotColorType = (typeof creationViewStatusTable)[keyof typeof creationViewStatusTable]['dot']
const colors: { [key in DotColorType]: string } = {
  red: '#e54545',
  orange: '#f69301',
  green: '#00a2a2',
}

const FbAdSetCreatinoStatusMap = {
  temp: '등록대기',
  insp_none: '검수전',
  insp_ing: '검수중',
  reg: '등록완료 프로젝트',
  reg_insp: '재검수중',
  reject: '반려',
  stop_creation_off: '중단(소재OFF)',
  stop_ad_set_off: '중단(광고세트OFF)',
  stop_camp_off: '중단(캠페인OFF)',
  stop_camp_range: '중단(캠페인기간)',
}
const FbAdSetCreatinoStatusTooltipMap: any = {
  insp_none: '아직 와디즈에 검수를 요청하지 않은 소재입니다. 우측 상단의 ‘검수요청‘ 버튼을 눌러 검수를 시작하세요',
  insp_ing: '와디즈에서 검수중입니다.',
  reg: '검수를 통과하였습니다.',
  reg_insp: '변경 소재를 와디즈에서 검수중입니다.',
  reject:
    '소재가 반려되었습니다. 소재 변경 후 재검수를 요청하세요. \n * SNS 매체에서 반려되어 재검수 신청 후에도 장시간 반려상태가 유지되면 관리자에게 문의하세요.',
  stop_creation_off: '소재가 OFF 되었습니다.',
  stop_ad_set_off: '광고세트가 OFF 되었습니다.',
  stop_camp_off: '캠페인이 OFF 되었습니다.',
  stop_camp_range: '프로젝트 기간이 변경되었습니다. 캠페인 기간을 재설정해주세요.',
  stop_by_admin: '관리자가 노출 중단한 소재입니다. \n 소재 재검수를 진행해 주세요.',
}

type AssetStatusBadgeFunction = {
  status: CreationViewStatusType
  creationDataId?: number
}

/**
 * 소재 상태 배지 (광고 세트 타겟 설정 ON/OFF)
 */
export const AssetStatusBadge: React.FC<AssetStatusBadgeFunction> = ({ status, creationDataId }) => {
  const { data: creationsByAdSet, invalidateQuery: invalidateCreationsByAdSetQuery } = useCreationsByAdSetQuery()
  const { data: campaignId } = useCurrentCampaignIdQuery()
  const adSetOnOffMutation = useAdSetCreationOnOffMutation()

  const statusLabelRef = useRef<HTMLButtonElement>(null)
  const adSettingsRef = useRef<HTMLUListElement>(null)
  const nonTargetSnsAdIdRef = useRef<HTMLSpanElement>(null)
  const reTargetSnsAdIdRef = useRef<HTMLSpanElement>(null)
  const [isAdSettingsVisible, setIsAdSettingsVisible] = useState(false)

  // 현재 소재의 광고세트 리스트 가져오기
  const adSetList = creationsByAdSet?.filter((creation) => creation.creationDataId === String(creationDataId))

  const handleToggleChange = (adSetOrder: CreationByAdSet['adSetOrder'], checked: boolean) => {
    // 토글한 소재 정보
    const selectedAdSetCreation = adSetList?.find((creation) => {
      return creation.adSetOrder === adSetOrder
    })

    if (selectedAdSetCreation === undefined) {
      // 일치하는 값이 없음
      return
    }

    const payload = {
      campId: campaignId,
      creationLinkId: selectedAdSetCreation?.id,
      useYn: checked,
    }

    // @ts-ignore
    adSetOnOffMutation.mutate(payload, {
      onSuccess: (data) => {
        alertify.success(
          `${adSetOrder === 1 ? '논타겟' : '리타겟'} 광고세트 소재가 ${checked ? 'ON' : 'OFF'} 되었습니다.`
        )
      },
      onError: (error) => {
        alertify.error('광고세트 소재 변경에 실패했습니다. 관리자에게 문의해 주세요.')
      },
      onSettled: () => {
        // 토글 변경 후 광고세트 데이터 다시 조회
        invalidateCreationsByAdSetQuery()
      },
    })
  }

  // snsAdId 클립보드 복사
  const handleCopy = (adSetOrder: number) => {
    const textRef = adSetOrder === 1 ? nonTargetSnsAdIdRef : reTargetSnsAdIdRef
    const text = textRef.current?.innerText

    if (text) {
      navigator.clipboard.writeText(text) // 클립보드에 복사
      // .then(() => alert('복사 완료!')) // TODO: 복사 완료 알림 띄우기
      // .catch(() => alert('복사 실패'))
    }
  }

  // 광고세트 팝오버가 열렸을 때 팝오버 바깥쪽을 클릭하면 팝오버 닫기
  const handleClickOutside = (event: MouseEvent) => {
    const isClickedInsideAdSettings = adSettingsRef.current?.contains(event.target as Node)
    const isClickedStatusLabel = statusLabelRef.current?.contains(event.target as Node) // 다른 소재 카드의 소재상태 버튼 클릭 여부 (TODO:)

    if (adSettingsRef.current && !isClickedInsideAdSettings) {
      setIsAdSettingsVisible(false)
    }
  }

  // 광고세트 팝오버 바깥쪽 클릭 감지 useEffect
  useEffect(() => {
    if (isAdSettingsVisible) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isAdSettingsVisible])

  return (
    <div className={styles.container}>
      <div className={styles.containAssetStatusBadge}>
        <button
          type="button"
          className={styles.caption}
          onClick={() => setIsAdSettingsVisible(true)}
          ref={statusLabelRef}
        >
          {'소재상태'}
        </button>
        <div
          className={styles.dot}
          style={{ backgroundColor: colors[creationViewStatusTable[status]?.dot || 'green'] }}
        />
      </div>

      {isAdSettingsVisible && (
        <ul className={styles.adSettings} ref={adSettingsRef}>
          {adSetList?.map((adSet) => {
            // (adSetOrder - 1: 논타겟 광고세트 / 2: 리타겟 광고세트)
            const { id, creationViewStatus, creationViewStatusDesc, adSetOrder, useYn } = adSet

            return (
              <li className={styles.adSet} key={id}>
                <span className={styles.title}>{`${adSetOrder === 1 ? '논타겟' : '리타겟'} 광고세트`}</span>
                <div className={styles.content}>
                  <div className={styles.description}>
                    <div
                      className={styles.dot}
                      style={{ backgroundColor: colors[creationViewStatusTable[creationViewStatus]?.dot || 'green'] }}
                    />
                    {creationViewStatusDesc}
                    <Tooltip title={FbAdSetCreatinoStatusTooltipMap[creationViewStatus]} placement="bottomLeft">
                      <HelpIcon />
                    </Tooltip>

                    <span className={styles.snsAdId}>
                      SNS 소재 ID
                      <span className={styles.value} ref={adSetOrder === 1 ? nonTargetSnsAdIdRef : reTargetSnsAdIdRef}>
                        {adSet.snsAdId ?? '-'}
                      </span>
                      {adSet.snsAdId && (
                        <button type="button" className={styles.button} onClick={() => handleCopy(adSetOrder)}>
                          <CopyIcon />
                        </button>
                      )}
                    </span>
                  </div>
                  <Switch
                    checked={useYn}
                    disabled={creationViewStatus === 'stop_by_admin' || adSetOnOffMutation.isLoading} // 관리자 강제중단인 경우 켤 수 없음
                    className={styles.toggle}
                    onChange={(checked, event) => handleToggleChange(adSetOrder, checked)}
                  />
                </div>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}
