import React from 'react';

const CategorySelectBox = (props:any) => (
        <select className=".select2-single" id="categorySelect" value={props.defaultValue}>
            {props.options.filter((option:any) => option[0] !== 'ALL').map((option:any) => (
                <option
                    key={option[0]}
                    value={option[0]}
                    label={option[0]}
                >
                  {option[1]}
                </option>
            ))}
        </select>
);

export default React.memo(CategorySelectBox);
