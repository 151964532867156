import React, { Fragment } from 'react';

const IpUseNTemplate = () => {
  const style = {
    backgroundColor: '#000000',
    left: 0,
    top: 0,
  };
  return (
    <Fragment>
        <div className="comp-no-data dark" style={style}>
            <div className="box-group">
                <p className="fz-18 fc-2" style={{ color: 'white' }}>
                    현재 관리자 접속 IP 허용 기능을 사용하지 않고 있습니다.
                    <br/>
                    모든 IP에서 접속이 가능합니다.
                </p>
            </div>
        </div>
    </Fragment>
  );
};

export default IpUseNTemplate;
