import React, {
  Fragment, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import dayjs from 'dayjs';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridSizeChangedEvent, ICellRendererParams } from 'ag-grid-community';
import { useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router';
import Pagination from '../common/Pagination';
import CustomTooltip from '../example/CustomTooltip';
import { ApiUtil2 } from '@biz/api'
import NoDataTemplate from '../common/NoDataTemplate';
import DlDialog from "../mngMakerAccount/DlDialog";

interface RptChartReq01 {
    startDate: string;
    endDate: string;
    chartData01: 'adIngMaker'|'adIngCamp'|'adspend'|'cms'|'consulting'|'dept'|'deptPaid'|'deptFree'|'paidExhaust'|'freeExhaust'|'pom';
    chartData02: 'adIngMaker'|'adIngCamp'|'adspend'|'cms'|'consulting'|'dept'|'deptPaid'|'deptFree'|'paidExhaust'|'freeExhaust'|'pom';
}

interface RptChartData {
    basicDate: string;
    adIngMaker: number;
    adIngCamp: number;
    adspend: number;
    cms: number;
    consulting: number;
    dept: number;
    deptPaid: number;
    deptFree: number;
    /** 후불 리포트 항목추가 20230706 */
    overPaymentOfPom: number;
    paidExhaust: number;
    freeExhaust: number;
    /** 후불 리포트 항목추가 20230614 */
    pom: number;
}

interface RptChartDataList {
    ok: boolean;
    data: RptChartData[];
}

const Sales = () => {
  /** 설정 부분 */
  const setTitle = useOutletContext<any>();
  const gridRef = useRef<AgGridReact>(null);
  const childRef = useRef<any>(null);
  const [chartOptions, setChartOptions] = useState({});
  const [rowData, setRowData] = useState<any[]>();
  const [isDlDialogOpened, setIsDlDialogOpened] = useState<boolean>(false);
  const [dlParam, setDlParam] = useState<any>(null);

  const [chartData, setChartData] = useState<RptChartReq01>({
    startDate: dayjs().subtract(6, 'd').format('YYYYMMDD'),
    endDate: dayjs().startOf('day').format('YYYYMMDD'),
    chartData01: 'paidExhaust',
    chartData02: 'freeExhaust',
  });
  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'basicDate',
      headerName: '일자',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      width: 10,
    },
    {
      field: 'adIngMaker',
      headerName: '광고 진행 메이커',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: (props: ICellRendererParams) => (String(props!.data.adIngMaker).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')),
      width: 10,
    },
    {
      field: 'adIngCamp',
      headerName: '광고 진행 캠페인',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: (props: ICellRendererParams) => (String(props!.data.adIngCamp).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')),
      width: 10,
    },
    {
      field: 'adspend',
      headerName: '광고비',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: (props: ICellRendererParams) => (String(props!.data.adspend).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')),
      width: 10,
    },
    {
      field: 'cms',
      headerName: '수수료',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: (props: ICellRendererParams) => (String(props!.data.cms).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')),
      width: 10,
    },
    {
      field: 'consulting',
      headerName: '광고 대행',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: (props: ICellRendererParams) => (String(props!.data.consulting).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')),
      width: 10,
    },
    {
      field: 'dept',
      headerName: '부채',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: (props: ICellRendererParams) => (String(props!.data.dept).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')),
      width: 10,
    },
    {
      field: 'deptPaid',
      headerName: '부채 유상상환',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: (props: ICellRendererParams) => (String(props!.data.deptPaid).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')),
      width: 10,
    },
    {
      field: 'deptFree',
      headerName: '부채 무상상환',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: (props: ICellRendererParams) => (String(props!.data.deptFree).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')),
      width: 10,
    },
      {
          field: 'overPaymentOfPom',
          headerName: '후불 한도 초과',
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-right-aligned-cell',
          cellRenderer: (props: ICellRendererParams) => (String(props!.data.overPaymentOfPom).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')),
          width: 10,
      },
    {
      field: 'paidExhaust',
      headerName: '유상 소진',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: (props: ICellRendererParams) => (String(props!.data.paidExhaust).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')),
      width: 10,
    },
    {
      field: 'freeExhaust',
      headerName: '무상 소진',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: (props: ICellRendererParams) => (String(props!.data.freeExhaust).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')),
      width: 10,
    },
      {
      field: 'pom',
      headerName: '후불 머니',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: (props: ICellRendererParams) => (String(props!.data.pom).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')),
      width: 10,
    },
  ]);
  const { setValue, getValues } = useForm<any>();
  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: true,
    suppressMovable: true,
    sortable: true,
    tooltipComponent: CustomTooltip,
  }), []);
  const onGridReady = useCallback(() => {
    const {
      startDate, endDate,
    } = getValues();
    ApiUtil2.post('/api/mngRpt/sales/list', {
      startDate, endDate,
    }).then((resp) => {
      setRowData(resp.data.data);
    });
  }, []);
  const ChartTemplate = () => <Fragment><HighchartsReact highcharts={Highcharts} options={chartOptions} /></Fragment>;
  /** 설정 부분 */
  /** 이벤트 함수 부분 */
  const datePickerNextEvent = () => {
    $('#datepicker').data('daterangepicker').addInterval();
    const startDate = $('#datepicker').data('daterangepicker').startDate.format('YYYYMMDD');
    const endDate = $('#datepicker').data('daterangepicker').endDate.format('YYYYMMDD');
    setChartData((current:RptChartReq01) => {
      const newData = { ...current };
      newData.startDate = startDate;
      newData.endDate = endDate;
      return newData;
    });
    setValue('startDate', startDate);
    setValue('endDate', endDate);
    onGridReady();
  };
  const datePickerPrevEvent = () => {
    $('#datepicker').data('daterangepicker').subtractInterval();
    const startDate = $('#datepicker').data('daterangepicker').startDate.format('YYYYMMDD');
    const endDate = $('#datepicker').data('daterangepicker').endDate.format('YYYYMMDD');
    setChartData((current:RptChartReq01) => {
      const newData = { ...current };
      newData.startDate = startDate;
      newData.endDate = endDate;
      return newData;
    });
    setValue('startDate', startDate);
    setValue('endDate', endDate);
    onGridReady();
  };
  const onGridSizeChanged = (params: GridSizeChangedEvent) => {
    params.api.sizeColumnsToFit();
  };
  const changePagination = () => {
        childRef.current!.onPaginationChanged();
  };

  const onBtnExport = useCallback(() => {
    // api 불러서 다운로드하는걸루
    // gridRef.current.api.exportDataAsCsv();
    setDlParam({
      startDate: getValues('startDate'),
      endDate: getValues('endDate'),
    });
    setIsDlDialogOpened(true);

    // window.location.assign(`/api/mngRpt/sales/download?startDate=${startDate}&endDate=${endDate}`);
  }, []);
    /** 이벤트 함수 부분 */
    /** 훅 부분 */
  useEffect(() => {
    setTitle('매출 리포트');
    const datePickerOptionConfig = {
      opens: 'left',
      startDate: dayjs().subtract(6, 'd'),
      endDate: dayjs().startOf('day'),
      ranges: {
        '오늘 ': [dayjs().startOf('day'), dayjs().endOf('day').subtract(59, 'm')],
        '어제 ': [dayjs().startOf('day').subtract(1, 'days'), dayjs().endOf('day').subtract(59, 'm').subtract(1, 'days')],
        '이번 주': [dayjs().startOf('day').day(0), dayjs().day(1).endOf('day').subtract(-7, 'd')
          .subtract(59, 'm')],
        '저번 주': [dayjs().startOf('day').subtract(1, 'week').day(0), dayjs().endOf('day').subtract(1, 'week').day(6)
          .subtract(59, 'm')],
        '최근 7일': [dayjs().startOf('day').subtract(7, 'd'), dayjs().endOf('day').subtract(1, 'd').subtract(59, 'm')],
        '최근 30일': [dayjs().startOf('day').subtract(30, 'd'), dayjs().endOf('day').subtract(1, 'd').subtract(59, 'm')],
      },
    };
    $('#datepicker').daterangepicker(datePickerOptionConfig, (start:any, end:any) => {
      $('#daterangepicker_span').text(($('#datepicker').data('daterangepicker').chosenLabel));
      setChartData((current:RptChartReq01) => {
        const newData = { ...current };
        newData.startDate = start.format('YYYYMMDD');
        newData.endDate = end.format('YYYYMMDD');
        return newData;
      });
      setValue('startDate', start.format('YYYYMMDD'));
      setValue('endDate', end.format('YYYYMMDD'));
      onGridReady();
    });
    setValue('startDate', $('#datepicker').data('daterangepicker').startDate.format('YYYYMMDD'));
    setValue('endDate', $('#datepicker').data('daterangepicker').endDate.format('YYYYMMDD'));
    $('.select2-single').select2({ width: 'auto', minimumResultsForSearch: Infinity });
    $('#chartData01Select').on('select2:select', (e:any) => {
      setChartData((current:RptChartReq01) => {
        const newData = { ...current };
        newData.chartData01 = e.target.value;
        return newData;
      });
    });
    $('#chartData02Select').on('select2:select', (e:any) => {
      setChartData((current:RptChartReq01) => {
        const newData = { ...current };
        newData.chartData02 = e.target.value;
        return newData;
      });
    });
    /** 이거 타자 두번 이상 치면 이상해짐 확인 해봐야함 */
    $('.select2-tags.w-680').select2({
      tags: true,
      width: '680px',
    });
    return () => {
      $('.daterangepicker').remove();
    };
  }, []);
  useEffect(() => {
    ApiUtil2.post<RptChartDataList>('/api/mngRpt/sales/chart', chartData).then((resp) => {
      const chartData01Txt: any = $('#chartData01Select option:selected').text();
      const chartData01Val: any = $('#chartData01Select option:selected').val();
      const chartData02Txt = $('#chartData02Select option:selected').text();
      const chartData02Val = $('#chartData02Select option:selected').val();
      const categoriesData: string[] = [];
      const chartSeries: any = [];
      const dataArr01: any = [];
      const dataArr02: any = [];
      resp.data.data.map((a:any) => {
        categoriesData.push(`${a.basicDate}`);
        dataArr01.push(a[`${chartData01Val}`]);
        dataArr02.push(a[`${chartData02Val}`]);
        return null;
      });
      const json01 = { name: chartData01Txt, data: dataArr01 };
      const json02 = { name: chartData02Txt, data: dataArr02 };
      chartSeries.push(json01);
      chartSeries.push(json02);
      setChartOptions(
        {
          chart: { type: 'line' },
          title: {
            text: '',
          },
          xAxis: {
            categories: categoriesData,
          },
          yAxis: [{ title: { text: $('#chartData01Select option:selected').text() } }, { title: { text: $('#chartData02Select option:selected').text() }, opposite: true }],
          plotOptions: { line: { dataLabels: { enabled: true }, enableMouseTracking: false } },
          series: chartSeries,
        },
      );
    });
  }, [chartData]);
  /** 훅 부분 */
  return (
    <Fragment>
      <section className="wrap-section wrap-graph">
        <div className="box-header">
          <div className="box-tit">
            <h2 className="fz-20 fc-1 fw-bold">매출 리포트</h2>
          </div>
          <div className="box-option">
            <span className="fz-14 fc-2">조회기간</span>
            <div className="comp-datepicker">
              <div className="inner-datepicker">
                <i className="ico ico-calendar"></i>
                <input type="text" className="tf-comm datepicker-range" id="datepicker" readOnly />
                <span className="fz-12 fc-2 daterangepicker_span" id="datepickerSpan">
                  사용자 설정
                </span>
              </div>
              <button type="button" className="btn btn-prev" onClick={datePickerPrevEvent}></button>
              <button type="button" className="btn btn-next" onClick={datePickerNextEvent}></button>
            </div>
            <button type="button" className="btn btn-primary large" onClick={onGridReady}>
              조회
            </button>
          </div>
        </div>
        <div className="box-body">
          <div className="panel-group comp-accordion" id="comp-accordion-01" role="tablist" aria-multiselectable="true">
            <div className="panel panel-default">
              <div
                role="tabpanel"
                className="panel-collapse collapse in"
                id="collapse-01-01"
                aria-labelledby="heading-01-01"
              >
                <div className="panel-body">
                  <div className="wrap-filter">
                    <div className="inner-filter">
                      <div className="box-left">
                        <div className="item-filter">
                          <div className="filter-tit">
                            <p className="fz-12 fc-2">광고성과1</p>
                          </div>
                          <div className="box-filter">
                            <select className="select2-single w-150" id="chartData01Select" defaultValue="paidExhaust">
                              <option value="adIngMaker">광고 진행 메이커</option>
                              <option value="adIngCamp">광고 진행 캠페인</option>
                              <option value="adspend">광고비</option>
                              <option value="cms">수수료</option>
                              <option value="consulting">광고 대행</option>
                              <option value="dept">부채</option>
                              <option value="deptPaid">부채 유상상환</option>
                              <option value="deptFree">부채 무상상환</option>
                              <option value="paidExhaust">유상 소진</option>
                              <option value="freeExhaust">쿠폰 소진</option>
                              <option value="pom">후불머니</option>
                            </select>
                          </div>
                        </div>
                        <div className="item-filter">
                          <div className="filter-tit">
                            <p className="fz-12 fc-2">광고성과2</p>
                          </div>
                          <div className="box-filter">
                            <select className="select2-single w-150" id="chartData02Select" defaultValue="freeExhaust">
                              <option value="adIngMaker">광고 진행 메이커</option>
                              <option value="adIngCamp">광고 진행 캠페인</option>
                              <option value="adspend">광고비</option>
                              <option value="cms">수수료</option>
                              <option value="consulting">광고 대행</option>
                              <option value="dept">부채</option>
                              <option value="deptPaid">부채 유상상환</option>
                              <option value="deptFree">부채 무상상환</option>
                              <option value="paidExhaust">유상 소진</option>
                              <option value="freeExhaust">쿠폰 소진</option>
                              <option value="pom">후불머니</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ChartTemplate />
                </div>
              </div>
              <div className="panel-heading" role="tab" id="heading-01-01">
                <h4 className="panel-title">
                  <a
                    role="button"
                    data-toggle="collapse"
                    data-parent="#comp-accordion-01"
                    id="chartDom"
                    href="#collapse-01-01"
                    aria-controls="collapse-01-01"
                    aria-expanded="true"
                  >
                    <button type="button" className="btn btn btn-primary btn-ico small w-150">
                      <i className="ico ico-arrow"></i>
                    </button>
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wrap-section wrap-datagrid">
        <div className="box-header h-auto">
          <div className="box-tit">
            <h3 className="fz-12 fc-3">모든 금액은 VAT를 포함한 금액 입니다.</h3>
          </div>
          <div className="box-option">
            <button type="button" className="btn btn btn-secondary-outline btn-ico" onClick={onBtnExport}>
              <i className="ico ico-download"></i>다운로드
            </button>
          </div>
        </div>
        <div className="box-body">
          <div className="ag-grid">
            <div className="ag-grid-inner">
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                // onGridReady={onGridReady}
                // onFirstDataRendered={onFirstDataRendered}
                onGridSizeChanged={onGridSizeChanged}
                rowHeight={48}
                domLayout={'autoHeight'}
                pagination={true}
                paginationPageSize={10}
                suppressPaginationPanel={true}
                onPaginationChanged={changePagination}
                noRowsOverlayComponent={NoDataTemplate}
              ></AgGridReact>
            </div>
          </div>
        </div>
        <div className="box-footer">
          <Pagination gridRef={gridRef} ref={childRef} />
        </div>
      </section>
      {isDlDialogOpened ? (
        <DlDialog setIsDlDialogOpened={setIsDlDialogOpened} dlUrl={'/api/mngRpt/sales/download'} dlParam={dlParam} />
      ) : null}
    </Fragment>
  )
};

export default Sales;
