import type { RouteObject } from 'react-router-dom'
import type { QueryClient } from '@tanstack/react-query'

import { ErrorLayout } from './ErrorLayout'
import { ErrorPage } from './ErrorPage'

type FunctionType = (queryClient: QueryClient) => RouteObject
export const getErrorRoute: FunctionType = (queryClient) => ({
  element: <ErrorLayout />,
  children: [
    { path: '/common/pageNotFound', element: <ErrorPage /> },
    { path: '*', element: <ErrorPage /> },
  ],
})
