import React, {
  Fragment, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import NoDataTemplate from '../common/NoDataTemplate';
import Pagination from '../common/Pagination';
import { ApiUtil2 } from '@biz/api'

const MngTaxbillDetailHis = (props:any) => {
  const gridRef = useRef<AgGridReact>(null);
  const childRef = useRef<any>(null);
  const [rowData, setRowData] = useState<any[]>();
  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: '발행상태',
      field: 'taxbillStatus',
    },
    {
      headerName: '변경일시',
      field: 'updateTime',
    },
    {
      headerName: '변경자',
      field: 'updateId',
    },
  ]);
  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: true,
  }), []);

  const onGridReady = useCallback(() => {
    const {
      taxbillId,
    } = props;
    ApiUtil2.post('/api/mngTaxbill/mngTaxbill/detail/his', {
      taxbillId,
    }).then((resp) => {
      $('#taxbillHisDialog').dialog({ width: 900, title: '세금계산서 발행 히스토리', closeOnEscape: true });
      setRowData(resp.data.data);
    });
  }, []);
  const changePagination = () => {
        childRef.current!.onPaginationChanged();
  };

useEffect(() => {
    /** 다이얼로그 닫기 */
    $(document).on('click', '#taxbillHisDialogClose', () => {
        $('#taxbillHisDialog').dialog('close');
        props.setIsTaxbillHisOpenedEvent();
    });
    return () => {
        props.setIsTaxbillHisOpenedEvent();
        $('#taxbillHisDialog').dialog('destroy');
    };
}, []);

  return (<Fragment>
              <form id="dialog">
                  <div id="taxbillHisDialog" className="dialog" style={{ display: 'none' }}>
                      <div className="dialog-body">
                          <section className="wrap-section wrap-datagrid">
                              <div className="box-body">
                                  <div className="ag-grid">
                                      <div className="ag-grid-inner">
                                          <AgGridReact
                                              ref={gridRef}
                                              rowData={rowData}
                                              columnDefs={columnDefs}
                                              defaultColDef={defaultColDef}
                                              onGridReady={onGridReady}
                                              rowHeight={48}
                                              domLayout={'autoHeight'}
                                              pagination={true}
                                              paginationPageSize={10}
                                              suppressPaginationPanel={true}
                                              onPaginationChanged={changePagination}
                                              noRowsOverlayComponent={NoDataTemplate}
                                          ></AgGridReact>
                                      </div>
                                  </div>
                              </div>
                              <div className="box-footer">
                                  <Pagination gridRef={gridRef} ref={childRef}/>
                              </div>
                          </section>
                      </div>
                      <div className="dialog-footer">
                          <button type="button" className="btn btn-secondary-outline large" id="taxbillHisDialogClose">확인</button>
                      </div>
                  </div>
              </form>
        </Fragment>
  );
};

export default MngTaxbillDetailHis;
