import { axios } from '../axios'

export type AdSetInfo = {
  adSetExtYn: boolean // false
  adSetOrder: number // 1
  adminCampYn: boolean // false
  age: string //  '18,65+'
  campId: number // 1815325649
  campNm: string // '##테스트_아하'
  creationLinks: []
  genderDiv: string // 'ALL'
  id: number // 1815325650
  location: string // '[{"key":"KR","name":"대한민국","type":"country","countryCode":"KR","countryName":"대한민국","region":null,"regionId":null,"supportsRegion":true,"supportsCity":true}]'
  orgHierarchy: [] // []
  snsApiOkYn: boolean // false
  syncYn: boolean // false
  useYn: boolean // true
}

type ReturnType = {
  ok: boolean
  data: AdSetInfo[]
}

type FunctionType = (campId: number) => Promise<ReturnType>
export const fetchAdSetInfo: FunctionType = (campId) =>
  axios.get(`/api/camp/make02/fbAdSetDataSearch2?campId=${campId}`)
