import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState,} from 'react';
import {useForm} from 'react-hook-form';
import {AgGridReact} from 'ag-grid-react';
import {ColDef, ICellRendererParams} from 'ag-grid-community';
import {Link, useLocation, useOutletContext} from 'react-router-dom';
import NoDataTemplate from '../common/NoDataTemplate';
import Pagination from '../common/Pagination';
import { ApiUtil } from '@biz/api'
import { ApiUtil2 } from '@biz/api'
import MngMakerAccountDialog from './MngMakerAccountDialog';
import MngMakerAccountPersonalDialog from './MngMakerAccountPersonalDialog';
import MngMakerAccountBasicDialog from './MngMakerAccountBasicDialog';
import {IMultiSelect, multiSelectHandler, toMultiSelect, toMultiSelectByMap,} from '../accAdjust/AccAdjustTargetUtil';
import MngMakerAccountBasicDepDialog from './MngMakerAccountBasicDepDialog';
import DlDialog from "./DlDialog";

export interface IMngMakerAccountSrchForm {
    makerStatus: string,
    startFees: string,
    endFees: string,
    refundYn: string,
    privateVer: string,
    termsVer: string,
    srchType: string,
    keyword: string,
    makerId: string,
    beforeBasicFees: number,
    afterBasicFees: number,
    beforeDepRates: number,
    afterDepRates: number,
    wadizUserId: string,
    pwd: string;
    msg: string;
    // makerIds:IWadizUser[],
}

interface IMngMakerAccount {
    data: any;
    makerId: string,
    makerNm: string,
    userNo: string,
    makerStatus: string,
    makerStatusDesc: string,
    basicFees: string,
    refundYn: boolean,
    privateVer: string
    termsVer: string,
    lastLoginTime: string
    wadizUserId: string
}
const makerStatusData = {
  all: { text: '전체', selected: true },
  NM: { text: '정상', selected: true },
  IA: { text: '휴면', selected: true },
  DO: { text: '탈퇴', selected: true },
};
const refundYnRenderer = (props: ICellRendererParams) => (props.data.refundYn === true ? '등록' : '미등록');
const userNoRenderer = (props: ICellRendererParams) => (props.data.userNo === '' ? '-' : props.data.userNo);
const privateVerRenderer = (props: ICellRendererParams) => (props.data.privateVer === undefined ? '-' : props.data.privateVer);
const termsVerRenderer = (props: ICellRendererParams) => (props.data.termsVer === undefined ? '-' : props.data.termsVer);
const makerDetailRenderer = (props: ICellRendererParams) => {
  const { makerId } = props.data;
  return <Link className="txt-link" to={`/mngAccount/maker/detail/${makerId}`}>{props.data.makerLoginId}</Link>;
};

const MngMakerAccount = () => {
  const url = useLocation();
  const setTitle = useOutletContext<any>();
  const childRef = useRef<any>(null);
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>();
  const [row, setRow] = useState<any[]>();
  const percent = [...new Array(101)].map((each, index) => ({ label: index, value: index }));
  const [isMakerSelectDialogOpened, setIsMakerSelectDialogOpened] = useState<boolean>(false);
  const [isPersonalDialogOpened, setIsPersonalDialogOpened] = useState<boolean>(false);
  const [isDepDialogOpened, setIsDepDialogOpened] = useState<boolean>(false);
  const [isBasicDialogOpened, setIsBasicDialogOpened] = useState<boolean>(false);
  const [isDlDialogOpened, setIsDlDialogOpened] = useState<boolean>(false);
  const [startDisable, setStartDisable] = useState(false);
  const [endDisable, setEndDisable] = useState(false);

  const {
    register, handleSubmit, getValues, setValue,
  } = useForm<IMngMakerAccountSrchForm>();

  /** 계정상태 */
  const [makerStatus, setMakerStatus] = useState<IMultiSelect>(toMultiSelect(makerStatusData));
  const [makerStatusLabel, setMakerStatusLabel] = useState<string>('전체');
  const updateMakerStatusForm = (value: string) => setValue('makerStatus', value);
  const updateMakerStatusLabel = (label: string) => setMakerStatusLabel(label);
  const updateMakerStatus = (newState: Map<string, boolean>) => setMakerStatus({ ...makerStatus, selects: newState });

  /** 개인정보 */
  const [privateVer, setPrivateVer] = useState<IMultiSelect>(toMultiSelect({ all: { text: '전체', selected: true } }));
  const [privateVerLabel, setPrivateVerLabel] = useState<string>('전체');
  const updatePrivateVerForm = (value: string) => setValue('privateVer', value);
  const updatePrivateVerLabel = (label: string) => setPrivateVerLabel(label);
  const updatePrivateVer = (newState: Map<string, boolean>) => setPrivateVer({ ...privateVer, selects: newState });

  /** 광고약관 */
  const [termsVer, setTermsVer] = useState<IMultiSelect>(toMultiSelect({ all: { text: '전체', selected: true } }));
  const [termsVerLabel, setTermsVerLabel] = useState<string>('전체');
  const updateTermsVerForm = (value: string) => setValue('termsVer', value);
  const updateTermsVerLabel = (label: string) => setTermsVerLabel(label);
  const updateTermsVer = (newState: Map<string, boolean>) => setTermsVer({ ...termsVer, selects: newState });

  /** 메이커 계정조회 */
  // eslint-disable-next-line consistent-return
  const onCellClicked = (params: any) => {
    if (params.event.altKey === true) { // alt키
      window.open(`/wabiz/project/${params.data.wadizUserId}`);
    }
    if (params.event.ctrlKey === true) { // ctrl키
      window.open(`/wabiz/maker/balance/${params.data.makerId}`);
    }
  };

  const onGridReady = useCallback(() => {
    // console.log(`makerStatus  : ${Object.entries(makerStatus.selects).find((s: any) => s.selected === true)}`);
      gridRef.current!.api.sizeColumnsToFit();

      ApiUtil2.post<IMngMakerAccount>('/api/mngAccount/maker/makerAccountList', {
          makerStatus: getValues('makerStatus'),
          startFees: $('#startFees').val(),
          endFees: $('#endFees').val(),
          refundYn: $('#refundYn').val(),
          privateVer: getValues('privateVer'),
          termsVer: getValues('termsVer'),
          srchType: $('#srchType').val(),
          keyword: $('#keyword').val(),
      }).then((resp) => {
        setRowData(resp.data.data);
      });
  }, []);

  // 그리드에서 페이징 변경시 pagination 에 있는 함수 호출
  const changePagination = () => {
        childRef.current!.onPaginationChanged();
  };

  // eslint-disable-next-line react/prop-types
  const basicFeesRenderer = (props: ICellRendererParams) => <a href="#javascript" className="txt-link" onClick={() => {
    setIsPersonalDialogOpened(true);
    // eslint-disable-next-line react/prop-types
    setRow(props.data);
    // eslint-disable-next-line react/prop-types
  }}>{props.data.basicFees === undefined ? '-' : `${props.data.basicFees} %`}</a>;

  const [columnDefs] = useState<ColDef[]>([
    {
      minWidth: 300,
      field: 'makerLoginId',
      headerName: '메이커ID',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: makerDetailRenderer,
    },
    {
      field: 'makerNm',
      headerName: '메이커명',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'userNo',
      headerName: '충전 사업자 번호',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: userNoRenderer,
    },
    {
      field: 'makerStatusDesc',
      headerName: '상태',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'basicFees',
      headerName: '기본 수수료',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: basicFeesRenderer,
    },
    {
      field: 'refundYn',
      headerName: '환불계좌',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: refundYnRenderer,
    },
    {
      field: 'privateVer',
      headerName: '개인 정보',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: privateVerRenderer,
    },
    {
      field: 'termsVer',
      headerName: '광고 약관',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: termsVerRenderer,
    },
    {
      field: 'lastLoginTime',
      headerName: '최근 로그인 일시',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
  ]);

  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: true,
  }), []);

  /** 셀렉박스모음 */
  const MakerStatusSelectBox = () => (
        <div className="dropdown-menu expand">
            <ul className="opt-selectbox" onClick={(e:any) => e.stopPropagation()} onKeyUp={(e:any) => e.handleKeyUp} role="presentation">
                {
                    Array.from(makerStatus.texts, ([key, value]) => ({ key, value }))
                      .map(({ key, value }) => {
                        const checkboxId = `ms-cb-${key}`;
                        return <li className="opt-menu" key={key}>
                                <div className="comp-checkbox small">
                                    <input name="makerStatus" type="checkbox"
                                           id={checkboxId}
                                           onChange={() => 0}
                                           onClick={(e: any) => { multiSelectHandler(key, e.target.checked, makerStatus, updateMakerStatus, updateMakerStatusLabel, updateMakerStatusForm); }}
                                           checked={makerStatus.selects.get(key) === true}/>
                                    <label htmlFor={checkboxId}>{value}</label>
                                </div>
                            </li>;
                      })
                }
            </ul>
        </div>
  );

  const PrivateSelectBox = () => (
        <div className="dropdown-menu expand">
            <ul className="opt-selectbox" onClick={(e:any) => e.stopPropagation()} onKeyUp={(e:any) => e.handleKeyUp} role="presentation">
                {
                    Array.from(privateVer.texts, ([key, value]) => ({ key, value }))
                      .map(({ key, value }) => {
                        const checkboxId = `pr-cb-${key}`;
                        return <li className="opt-menu" key={key}>
                                <div className="comp-checkbox small">
                                    <input name="privateVer" type="checkbox"
                                           id={checkboxId}
                                           onChange={() => 0}
                                           onClick={(e: any) => { multiSelectHandler(key, e.target.checked, privateVer, updatePrivateVer, updatePrivateVerLabel, updatePrivateVerForm); }}
                                           checked={privateVer.selects.get(key) === true}/>
                                    <label htmlFor={checkboxId}>{value}</label>
                                </div>
                            </li>;
                      })
                }
            </ul>
        </div>
  );

  const TermsSelectBox = () => (
        <div className="dropdown-menu expand">
            <ul className="opt-selectbox" onClick={(e:any) => e.stopPropagation()} onKeyUp={(e:any) => e.handleKeyUp} role="presentation">
                {
                    Array.from(termsVer.texts, ([key, value]) => ({ key, value }))
                      .map(({ key, value }) => {
                        const checkboxId = `ts-cb-${key}`;
                        return <li className="opt-menu" key={key}>
                                <div className="comp-checkbox small">
                                    <input name="termsVer" type="checkbox"
                                           id={checkboxId}
                                           onChange={() => 0}
                                           onClick={(e: any) => { multiSelectHandler(key, e.target.checked, termsVer, updateTermsVer, updateTermsVerLabel, updateTermsVerForm); }}
                                           checked={termsVer.selects.get(key) === true}/>
                                    <label htmlFor={checkboxId}>{value}</label>
                                </div>
                            </li>;
                      })
                }
            </ul>
        </div>
  );

  useEffect(() => {
    setTitle('메이커 계정관리');
    return () => {
      setTitle('');
    };
  }, []);

  useEffect(() => {
    /** 기본수수료 범위 -- 시작 */
    $('#startFees').select2({
      width: '150',
      minimumResultsForSearch: Infinity,
    }).on('select2:select', (e) => {
      const number = e.params.data.id;
      $(`#start${e.params.data.id}`).prop('selected', true);
      setValue('startFees', number)
      if (number === '100') {
        $('#endFees').val('').select2();
        setEndDisable(true);
      }
      setEndDisable(false);
      /** disabled를 풀어야 */
      for (let i = Number(number); i > 0; i -= 1) {
        $(`#end${i}`).prop('disabled', false);
      }
      /** disabled를 걸었으면 */
      for (let i = 0; i < Number(number); i += 1) {
        $(`#end${i}`).prop('disabled', true);
      }
      $('#endFees').select2();
    });

    /** 기본수수료 범위 -- 끝 */
    $('#endFees').select2({
      width: '150',
      minimumResultsForSearch: Infinity,
    }).on('select2:select', (e) => {
      const number = e.params.data.id;
        setValue('endFees', number)
      $('#endFees').val(e.params.data.id).trigger('select');
      if (number === '0') {
        $('#startFees').val('').select2();
        setStartDisable(true);
      }
      setStartDisable(false);
      /** disabled를 풀어야 */
      for (let i = Number(number); i > 0; i -= 1) {
        $(`#start${i}`).prop('disabled', false);
      }
      /** disabled를 걸었다 */
      for (let i = Number(number) + 1; i <= 100; i += 1) {
        $(`#start${i}`).prop('disabled', true);
      }
      $('#startFees').select2();
    });

    /** 환불 계좌 */
    $('#refundYn').select2({
      width: '150',
      minimumResultsForSearch: Infinity,
    }).on('select2:select', (e)=>{
        setValue('refundYn', e.params.data.id)
    });

    /** 검색 구분 */
    $('#srchType').select2({
      width: '150',
      placeholder: '선택',
      minimumResultsForSearch: Infinity,
    }).on('select2:select', (e)=>{
        setValue('srchType', e.params.data.id)
    });

    /** 개인정보 버전리스트 */
    ApiUtil.get('/api/mngAccount/maker/privateList').then((resp) => {
      const options = resp.data.data;
      const texts = new Map<string, string>();
      const selected = new Map<string, boolean>();
      texts.set('all', '전체');
      selected.set('all', true);
      options.forEach((v:any) => {
        const key = v.version.toString();
        texts.set(key, v.version);
        selected.set(key, true);
      });
      setPrivateVer(toMultiSelectByMap(texts, selected));
    });

    /** 광고약관 버전리스트 */
    ApiUtil.get('/api/mngAccount/maker/termsList').then((resp) => {
      const options = resp.data.data;
      const texts = new Map<string, string>();
      const selected = new Map<string, boolean>();
      texts.set('all', '전체');
      selected.set('all', true);
      options.forEach((v:any) => {
        const key = v.version.toString();
        texts.set(key, v.version);
        selected.set(key, true);
      });
      setTermsVer(toMultiSelectByMap(texts, selected));
    });

    /** 엔터키 이벤트 */
    $(document).on('keyup', '#keyword', (e:any) => {
      if (e.keyCode === 13) {
        onGridReady();
        return false;
      }
      return true;
    });

    setValue('makerStatus', 'all');
    setValue('privateVer', 'all');
    setValue('termsVer', 'all');
  }, []);

  useEffect(() => {
    const noDataGrid = (document.querySelector<HTMLElement>('.ag-root-wrapper-body.ag-focus-managed.ag-layout-auto-height')! as any);
    const noTreeDataGrid2 = (document.querySelector<HTMLElement>('.ag-root-wrapper.ag-ltr.ag-layout-auto-height')! as any);
    const noDataTempleteArea = (document.querySelector<HTMLElement>('.ag-overlay-wrapper.ag-layout-auto-height.ag-overlay-no-rows-wrapper')! as any);
    if (noDataGrid !== null) {
      noDataGrid.style.height = '548px';
    }
    if (noTreeDataGrid2 !== null) {
      noTreeDataGrid2.style.height = '548px';
    }
    if (noDataTempleteArea !== null) {
      noDataTempleteArea.style.height = '548px';
    }
  }, []);

  return (
      <>
          <Fragment>
              {
                  isMakerSelectDialogOpened ? <MngMakerAccountDialog setIsMakerSelectDialogOpened={setIsMakerSelectDialogOpened} onGridReady={onGridReady}/> : null
              }
              {
                  isPersonalDialogOpened ? <MngMakerAccountPersonalDialog setIsPersonalDialogOpened={setIsPersonalDialogOpened} onGridReady={onGridReady} row={row}/> : null
              }
              {
                  isBasicDialogOpened ? <MngMakerAccountBasicDialog setIsBasicDialogOpened={setIsBasicDialogOpened} onGridReady={onGridReady}/> : null
              }
              {
                  isDepDialogOpened ? <MngMakerAccountBasicDepDialog setIsDepDialogOpened={setIsDepDialogOpened}/> : null
              }
              {
                  //어쩧수 없다 노가다 on..
                  isDlDialogOpened ? <DlDialog url={url.pathname} setIsDlDialogOpened={setIsDlDialogOpened}
                                               keyword={$('#keyword').val()} makerStatus={getValues('makerStatus')} startFees={$('#startFees').val()} endFees={$('#endFees').val()}
                                               refundYn={$('#refundYn').val()} privateVer={getValues('privateVer')} termsVer={getValues('termsVer')} srchType={getValues('srchType')}/> : null
              }
              <section className="wrap-section wrap-datagrid">
                  <form onSubmit={handleSubmit(onGridReady)}>
                      <div className="wrap-filter">
                          <div className="inner-filter">
                              <div className="box-left">
                                  <div className="item-filter">
                                      <div className="filter-tit">
                                          <p className="fz-12 fc-2">계정 상태</p>
                                      </div>
                                      <div className="box-filter">
                                          <div id="makerStatus" className="comp-dropdown select2 w-150 selected">
                                              <a href="#javascript" className="dropdown-toggle" data-toggle="dropdown">
                                                  <div className="box-left">
                                                      <span className="fz-14">{makerStatusLabel}{/* {all1 ? `전체 ${statusChecked.length - 1 === 0 ? '' : ` 외 ${statusChecked.length - 1}`}` : `${$(`label[for="${$('input:checkbox[name="makerStatus"]:checked').eq(0).val()}"]`).html()}${statusChecked.length - 1 === 0 ? '' : ` 외 ${statusChecked.length - 1}`}`} */}</span>
                                                  </div>
                                                  <div className="box-right">
                                                      <i className="ico ico-arrow"></i>
                                                  </div>
                                              </a>
                                                <MakerStatusSelectBox/>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="item-filter">
                                      <div className="filter-tit">
                                          <p className="fz-12 fc-2">기본 수수료</p>
                                      </div>
                                      <div className="box-filter">
                                          <select id="startFees" className="select2-single w-150"
                                                  defaultValue={0} disabled={startDisable} >
                                              {
                                                  percent.map((a: any, k: any) => (
                                                      <option id={`start${a.value}`} key={k}
                                                              value={a.value}>{a.label}%</option>
                                                  ))
                                              }
                                          </select>
                                      </div>
                                      <div className="box-filter">
                                    <span className="comp-txt">
                                        <span className="table">
                                            <span className="table-cell">
                                                <b className="fz-12 fc-1">~</b>
                                            </span>
                                        </span>
                                    </span>
                                      </div>
                                      <div className="box-filter">
                                          <select id="endFees" className="select2-single w-150"
                                                  defaultValue={100} disabled={endDisable} >
                                              {
                                                  percent.map((a: any, k: any) => (
                                                      <option id={`end${a.value}`} key={k}
                                                              value={a.value}>{a.label}%</option>
                                                  ))
                                              }
                                          </select>
                                      </div>
                                  </div>
                                  <div className="item-filter">
                                      <div className="filter-tit">
                                          <p className="fz-12 fc-2">환불계좌</p>
                                      </div>
                                      <div className="box-filter">
                                          <select className="select2-single w-150"
                                                  id="refundYn" {...register('refundYn')}>
                                              <option value="all" defaultChecked={true}>전체</option>
                                              <option value="y">등록</option>
                                              <option value="n">미등록</option>
                                          </select>
                                      </div>
                                  </div>
                                  <div className="item-filter">
                                      <div className="filter-tit">
                                          <p className="fz-12 fc-2">개인 정보</p>
                                      </div>
                                      <div className="box-filter">
                                          <div id="privateVer"
                                               className="comp-dropdown select2 w-150 selected">
                                              <a href="#javascript" className="dropdown-toggle" data-toggle="dropdown">
                                                  <div className="box-left">
                                                      <span className="fz-14">{privateVerLabel}</span>
                                                  </div>
                                                  <div className="box-right">
                                                      <i className="ico ico-arrow"></i>
                                                  </div>
                                              </a>
                                              <PrivateSelectBox/>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="item-filter">
                                      <div className="filter-tit">
                                          <p className="fz-12 fc-2">광고 약관</p>
                                      </div>
                                      <div className="box-filter">
                                          <div id="termsVer"
                                               className="comp-dropdown select2 w-150 selected">
                                              <a href="#javascript" className="dropdown-toggle" data-toggle="dropdown">
                                                  <div className="box-left">
                                                      <span className="fz-14">{termsVerLabel}</span>
                                                  </div>
                                                  <div className="box-right">
                                                      <i className="ico ico-arrow"></i>
                                                  </div>
                                              </a>
                                              <TermsSelectBox/>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="item-filter">
                                      <div className="filter-tit">
                                          <p className="fz-12 fc-2">검색 구분</p>
                                      </div>
                                      <div className="box-filter">
                                          <select id="srchType"
                                                  className="select2-single w-150" {...register('srchType')}>
                                              <option value="makerLoginId">메이커ID</option>
                                              <option value="makerNm">메이커명</option>
                                              <option value="userNo">충전 사업자 번호</option>
                                          </select>
                                      </div>
                                  </div>
                                  <div className="item-filter">
                                      <div className="filter-tit">
                                          <p className="fz-12 fc-2">검색어</p>
                                      </div>
                                      <div className="box-filter">
                                          <div className="input-group comp-search">
                                              <div className="inner-input-group">
                                                  <input id="keyword" type="text" className="tf-comm"
                                                         placeholder="검색어를 입력해 주세요." {...register('keyword')}></input>
                                                  <i className="ico ico-search"></i>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="box-right">
                                  <button type="submit" className="btn btn-tertiary-mint btn-ico"><i
                                      className="ico ico-filter"></i>필터 조회
                                  </button>
                              </div>
                          </div>
                      </div>
                  </form>
                  <div className="box-header">
                      <div className="box-tit">
                          <button type="button" className="btn btn btn-primary" onClick={() => {
                            setIsBasicDialogOpened(true);
                          }}>표준 수수료 설정</button>
                          <button type="button" className="btn btn-primary-outline" onClick={() => {
                            setIsMakerSelectDialogOpened(true);
                          }}>미연동 계정 조회</button>
                          <button type="button" className="btn btn btn-primary" onClick={() => {
                            setIsDepDialogOpened(true);
                          }}>보증금 설정</button>
                      </div>
                      <div className="box-option">
                          <button type="button" className="btn btn btn-secondary-outline btn-ico" onClick={() => {
                              setIsDlDialogOpened(true)
                          }}><i className="ico ico-download"></i>다운로드
                          </button>
                      </div>
                  </div>
                  <div className="box-body">
                      <div className="ag-grid">
                          <div className="ag-grid-inner">
                              <AgGridReact
                                  ref={gridRef}
                                  rowData={rowData}
                                  columnDefs={columnDefs}
                                  defaultColDef={defaultColDef}
                                  rowHeight={48}
                                  pagination={true}
                                  paginationPageSize={10}
                                  suppressPaginationPanel={true}
                                  onPaginationChanged={changePagination}
                                  domLayout={'autoHeight'}
                                  noRowsOverlayComponent={NoDataTemplate}
                                  onCellClicked={onCellClicked}
                              />
                              { rowData === undefined ? <NoDataTemplate/> : null}
                          </div>
                      </div>
                  </div>
                  <div className="box-footer">
                      <Pagination gridRef={gridRef} ref={childRef}/>
                  </div>
              </section>
          </Fragment>
      </>
  );
};

export default MngMakerAccount;
