import { useState, useCallback } from 'react';
import dayjs, { type Dayjs } from 'dayjs';
import { SalesReportDownload } from '../SalesReportDownload';
import { DailyTable } from '../Tables';
import { SearchToolBox } from './SearchToolBox';

type RangeFilter = [Dayjs | null, Dayjs | null];
const currentRange = () =>
  [dayjs().subtract(1, 'week'), dayjs()] as RangeFilter;
export const DailyListTab: React.FC = () => {
  const [range, setRange] = useState<RangeFilter>(/*currentRange() ISMS*/)
  const doChange = useCallback((param?: RangeFilter | null) => {
    setRange(param ?? currentRange())
  }, [])
  const params = `startDate=${range?.[0]?.format('YYYYMMDD') || ''}&endDate=${range?.[1]?.format('YYYYMMDD') || ''}`
  return (
    <>
      <SearchToolBox
        downloader={<SalesReportDownload params={params} targetName={'daily'} />}
        value={range ?? currentRange()}
        onChange={doChange}
      />
      <DailyTable startDate={range?.[0]?.format('YYYYMMDD') || ''} endDate={range?.[1]?.format('YYYYMMDD') || ''} />
    </>
  )
};
