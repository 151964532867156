import React, { Fragment, useEffect, useState } from 'react';

const AccAdjustInfoSingle = (props: any) => {
  const [adjust, setAdjust] = useState<any>(undefined);
  const [adjustTarget, setAdjustTarget] = useState<any>(undefined);

  useEffect(() => {
    // initialize
  }, []);

  useEffect(() => {
    setAdjust({ ...adjust, ...props.adjust });
  }, [props.adjust]);

  useEffect(() => {
    if (adjust === undefined) {
      // nothing to do.
    } else if (adjust.plus != null) {
      setAdjustTarget({ ...adjustTarget, ...adjust.plus });
    } else if (adjust.minus != null) {
      setAdjustTarget({ ...adjustTarget, ...adjust.minus });
    }
  }, [adjust]);

  return (
    <Fragment>
      <dl className="column-two">
        <dt>
          <div className="dt-inner">
            <span className="fz-16 fc-1">메이커ID</span>
          </div>
        </dt>
        <dd>
          <div className="form-group">
            {/* Input-Group : Start */}
            <div className="input-group">
              <div className="inner-input-group">
                <input type="text" className="tf-comm" disabled value={adjustTarget?.makerLoginId || ''} />
              </div>
              <p className="fz-12">체크 / 에러 문구 내용 영역</p>
            </div>
            {/* Input-Group : End */}
          </div>
        </dd>
        <dt>
          <div className="dt-inner">
            <span className="fz-16 fc-1">메이커명</span>
          </div>
        </dt>
        <dd>
          <div className="form-group">
            {/* Input-Group : Start */}
            <div className="input-group">
              <div className="inner-input-group">
                <input type="text" className="tf-comm" value={adjustTarget?.makerName || ''} disabled/>
              </div>
              <p className="fz-12">체크 / 에러 문구 내용 영역</p>
            </div>
            {/* Input-Group : End */}
          </div>
        </dd>
      </dl>
      <dl>
        <dt>
          <div className="dt-inner">
            <span className="fz-16 fc-1">충전 사업자 번호</span>
          </div>
        </dt>
        <dd>
          <div className="form-group">
            {/* Input-Group : Start */}
            <div className="input-group">
              <div className="inner-input-group">
                <input type="text" className="tf-comm" value={adjustTarget?.userNo || ''} disabled/>
              </div>
              <p className="fz-12">체크 / 에러 문구 내용 영역</p>
            </div>
            {/* Input-Group : End */}
          </div>
        </dd>
      </dl>
    </Fragment>
  );
};
export default AccAdjustInfoSingle;
