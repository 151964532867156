import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { fetchPostCreationStatus } from '@biz/api'
import { useCurrentCampaignIdQuery } from '@biz/query'

type FunctionType = () => UseMutationResult<unknown, unknown, { id: number | string }, unknown>
export const useCreationStatusMutation: FunctionType = () => {
  const { data: campaignId } = useCurrentCampaignIdQuery()
  return useMutation({
    mutationFn: (data) =>
      !!campaignId ? fetchPostCreationStatus({ ...data, campId: campaignId }) : Promise.reject('캠페인이 없음'),
  })
}
