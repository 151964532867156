import { useCallback } from 'react'
import { useNavigate } from 'react-router'
import { Button } from 'antd'
import styles from './ErrorContainer.module.scss'

type ErrorContainerFunction = {
  children: React.ReactNode
}
export const ErrorContainer: React.FC<ErrorContainerFunction> = ({ children }) => {
  const navigate = useNavigate()
  const goMain = useCallback(() => {
    navigate('/main')
  }, [])
  setTimeout(goMain, 5000)
  return (
    <div className={styles.ErrorContainer}>
      <div className={styles.ErrorBox}>
        {children}
        <Button type="primary" size="large" onClick={goMain}>
          {'홈 화면으로 이동'}
        </Button>
      </div>
    </div>
  )
}
