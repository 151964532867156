import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {AgGridReact} from "ag-grid-react";
import {ColDef} from "ag-grid-community";
import NoDataTemplate from "../../../common/NoDataTemplate";
import { ApiUtil2 } from '@biz/api'

export interface IAODTTargetInfo {
    data: any;
    updateDate: string;
    executor: string;
    adjust: string;
    cause: string;
    modifiedYn: boolean;
}
const AODTHistoryGrid = (props: any) => {
    const gridRef = useRef<AgGridReact>(null);
    const adjustRenderer = (e: any) => ((e.data.adjust === 0 || e.data.adjust === undefined) && !e.data.modifiedYn ? '-' : e.data.adjust.toLocaleString());

    const [rowData, setRowData] = useState([]);
    const [columnDefs] = useState<ColDef[]>([
        {
            field: 'updateDate',
            headerName: '날짜',
            cellClass: 'ag-left-aligned-cell',
            width: 50,
        },
        {
            field: 'executor',
            headerName: '조정자',
            cellClass: 'ag-left-aligned-cell',
            width: 50
        },
        {
            field: 'adjust',
            headerName: '조정금액',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
            width: 75,
            cellRenderer: adjustRenderer
        },
        {
            field: 'cause',
            headerName: '조정사유',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
            width: 200
        }
    ]);

    const onGridReady = () => {
        ApiUtil2.get<IAODTTargetInfo>('/api/bzmAdmin/postpaidMoneyManagement/AODT/targetInfo?serialId='+props.serialId
        ).then((resp) => {
            setRowData(resp.data.data);
        });
    };

    const defaultColDef = useMemo<ColDef>(() => ({
        flex: 1,
        resizable: false,
        suppressMovable: true,
        sortable: true,
    }), []);

    useEffect(()=>{
        onGridReady()
    }, [])

    return (
        <Fragment>
            <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowHeight={48}
                domLayout={'autoHeight'}
                noRowsOverlayComponent={NoDataTemplate}
            />
            { rowData === undefined ? <NoDataTemplate/> : null}
        </Fragment>
    );
};

export default AODTHistoryGrid;
