import { Badge } from 'antd'
import { ReactComponent as AttachmentIcon } from '@common/svg/attachment.svg'
import { ReactComponent as ShareIcon } from '@common/svg/shareOutline.svg'
import styles from './MediaFileDescriptor.module.scss'

const replacePathToEllipsis = (urlString: string = '') => {
  if (!urlString.startsWith('http')) {
    return urlString
  }
  try {
    // URL을 사용하기 위함
    const url = new URL(urlString)
    const paths = url.pathname.split('/')
    const fileName = paths.pop()
    return `${url.origin}/.../...${fileName ? '/' + fileName : ''}`
  } catch (error) {
    return urlString
  }
}
type MediaFileDescriptorFunction = {
  name: string
  size: string
  state?: string
}
export const MediaFileDescriptor: React.FC<MediaFileDescriptorFunction> = ({ name, size, state }) => (
  <div className={styles.MediaFileDescriptor}>
    <div className={styles.fileInfo}>
      <div>
        {(name || '').startsWith('http') ? (
          <ShareIcon style={{ height: 12, fill: '#ADB5BD' }} />
        ) : (
          <AttachmentIcon style={{ height: 12, fill: '#ADB5BD' }} />
        )}
      </div>
      <div className={styles.name}>{replacePathToEllipsis(name)}</div>
      <div className={styles.size}>{size}</div>
    </div>
    {state !== undefined && (
      <div className={styles.ribbon}>
        <Badge.Ribbon text={state} color="#e9ecef" />
      </div>
    )}
  </div>
)
