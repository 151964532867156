import styles from './CampaignRange.module.scss'
export const TitleCaption = () => (
  <div className={styles.content}>
    <div className={styles.titleCaption}>
      <p>
        {
          '심의 완료 시점으로부터 캠페인 종료 시점까지 24시간 미만인 경우 메타 정책에 의해 캠페인이 등록되지 않을 수 있어요.'
        }
      </p>
      <p>
        {
          '주말/공휴일 등으로 와디즈 비즈센터 심의가 지연될 수 있으니 내일이 영업일이 아니라면 일정을 여유 있게 잡아주세요.'
        }
      </p>
    </div>
  </div>
)
