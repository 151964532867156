import { Fragment, useEffect, useState } from 'react'
import { ApiUtil3 } from '@biz/api'
import PgSuccessDialog from './PgSuccessDialog'
import PgErrorDialog from './PgErrorDialog'
import alertify from 'alertifyjs'

const PgPaymentSuccess = () => {
  const [pgModalStatus, setPgModalStatus] = useState<any>(null)
  const [amount, setAmount] = useState<any>(null)
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const pgSuccess = {
      orderId: params.get('orderId'),
      paymentKey: params.get('paymentKey'),
      amount: params.get('amount'),
      customerName: params.get('currUserNo'),
    }
    setAmount(pgSuccess.amount)
    /** 결제 로딩 다이얼로그 */
    $('#pgLoadingDialog').dialog({
      title: '결제 진행',
      width: 900,
      closeOnEscape: true,
    })
    ApiUtil3.post('/api/pg/payment/success', pgSuccess)
      .then((res) => {
        if (res.data === 'ok') {
          setTimeout(() => {
            setPgModalStatus('success')
          }, 1500)
        } else {
          setTimeout(() => {
            setPgModalStatus('error')
          }, 1500)
        }
      })
      .catch(function (error) {
        function goBack() {
          location.href = '/bzm/charge'
        }
        if (error.data.ok === false) {
          alertify.error('결제가 실패했습니다. \n 잠시 후 페이지가 이동 됩니다. \n 다시 시도해주세요.')
          setTimeout(goBack, 5000)
        }
      })

    return () => {
      $('#pgLoadingDialog').dialog('destroy')
    }
  }, [])

  const AfterPgProcess = () => {
    if (pgModalStatus === 'success') {
      localStorage.removeItem('chargeAmount')
      localStorage.setItem('chargeAmount', amount)
      return <PgSuccessDialog setPgModalStatus={setPgModalStatus} />
    }
    if (pgModalStatus === 'error') {
      return <PgErrorDialog setPgModalStatus={setPgModalStatus} />
    }
    return null
  }

  return (
    <Fragment>
      <AfterPgProcess />
      <form>
        <div id="pgLoadingDialog" className="dialog" style={{ display: 'none' }}>
          <div className="dialog-body">
            <div className="wrap-section wrap-tbl">
              <div className="box-body">
                <div className="tbl">
                  <dl className="vertical">
                    <dd>
                      <div className="form-group">
                        <p className="comp-txt center">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-20 fc-1 fw-medium">결제가 진행 중 입니다.</b>
                              <b className="fz-20 fc-1 fw-medium">잠시만 기다려주세요.</b>
                            </span>
                          </span>
                        </p>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  )
}
export default PgPaymentSuccess
