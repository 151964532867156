import React, { Fragment, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router';
import { ApiUtil2 } from '@biz/api'
import alertify from 'alertifyjs'
import AuthPopUp from '../mngAdminAccount/AuthPopUp';

const Setting = () => {
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [adminInfo, setAdminInfo] = useState<any>([]);
  const [isOpenedPopUp, setIsOpenedPopUp] = useState<boolean>(false);
  const [hp, setHp] = useState<any>('');
  const setTitle = useOutletContext<any>();

  useEffect(() => {
    setTitle('설정');
    ApiUtil2.get('/api/admin/setting/detail').then((resp) => {
      setAdminInfo(resp.data.data);
      setHp(resp.data.data.hp);
    });

    /** 담당자명 */
    $('#memberName').on('keyup', () => {
      const value = $('#memberName').val() as string;
      if (value.length > 10) {
        $('#memberName').val(value.substring(0, 10));
      }
    });
    return () => {
      setTitle('');
    };
  }, []);

  /** 휴대 전화번호 */
  $('#hp').on('keyup', () => {
    const value = $('#hp').val() as string;
    if (/[^0-9]/g.test(value)) {
      $('#hp').val(value.substring(0, value.length - 1));
    }
    if (value.length > 11) {
      $('#hp').val(value.substring(0, 11));
    }
    setHp(value);
  });

  const memberNameHandler = () => {
    const memberName = $('#memberName').val() as string;
    if (memberName === '') {
      alertify.error('이름을 입력해주세요');
    } else {
      ApiUtil2.post('/api/admin/setting/modify/memberName', { memberName }).then(() => {
        alertify.success('변경사항이 저장되었습니다.');
      });
    }
  };

  const hpHandler = () => {
    setHp($('#hp').val() as string);
    if (hp === '' || hp.length < 10) {
      alertify.error('휴대전화번호를 확인해주세요');
    } else {
      ApiUtil2.post('/api/admin/setting/generateAuthCode', { hp }).then(() => {
        setIsOpenedPopUp(true);
      });
    }
  };

  const pwdChangeHandler = () => {
    setIsClicked(!isClicked);
  };

  const pwdSendHandler = () => {
    const originPwd = $('#originPwd').val() as string;
    const newPwd = $('#newPwd').val() as string;
    const newPwdChk = $('#newPwdChk').val() as string;

    if (originPwd === '' || newPwd === '' || newPwdChk === '') {
      alertify.error('비밀번호를 입력해주세요.');
    } else {
      ApiUtil2.post('/api/admin/setting/modify/pwd', { originPwd, newPwd, newPwdChk }).then(() => {
        alertify.success('변경사항이 저장되었습니다.');
      });
    }
  };
  return (
        <Fragment>
            {isOpenedPopUp ? <AuthPopUp setIsOpenedPopUp={setIsOpenedPopUp} hp={hp} authType={'hpAuth'}/> : null }
            <div id="dialog21" className="form-group">
                <div className="form-group">
                    <div className="wrap-section wrap-tbl">
                        <div className="box-body">
                            <div className="tbl">
                                <dl>
                                    <dt>
                                        <div className="dt-inner">
                                            <span className="fz-16 fc-1">
                                                ID
                                            </span>
                                        </div>
                                    </dt>
                                    <dd>
                                        <div className="form-group">
                                            <div className="input-group expand">
                                                <div className="inner-input-group">
                                                    <input type="text" className="tf-comm" defaultValue={adminInfo.loginId || ''} disabled/>
                                                </div>
                                                <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                            </div>
                                        </div>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>
                                        <div className="dt-inner">
                                            <span className="fz-16 fc-1">
                                                이름
                                            </span>
                                        </div>
                                    </dt>
                                    <dd>
                                        <div className="form-group">
                                            <div className="input-group expand w-100">
                                                <div className="inner-input-group">
                                                    <input type="text" className="tf-comm" id='memberName' defaultValue={adminInfo.memberName || ''}/>
                                                </div>
                                                <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                            </div>
                                            <button type="button" className="btn btn-primary w-100" onClick={memberNameHandler}>저장</button>
                                        </div>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>
                                        <div className="dt-inner">
                                            <span className="fz-16 fc-1">
                                                휴대전화번호
                                            </span>
                                        </div>
                                    </dt>
                                    <dd>
                                        <div className="form-group">
                                            <div className="input-group expand w-100">
                                                <div className="inner-input-group">
                                                    <input type="text" className="tf-comm" id='hp' defaultValue={adminInfo.hp || ''}/>
                                                </div>
                                                <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                            </div>
                                            <button type="button" className="btn btn-primary w-100" onClick={hpHandler}>저장</button>
                                        </div>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>
                                        <div className="dt-inner">
                                            <span className="fz-16 fc-1">
                                                비밀번호
                                            </span>
                                        </div>
                                    </dt>
                                    <dd>
                                        <div className="form-group">
                                            <button type="button" className="btn btn-secondary-outline w-auto" onClick={pwdChangeHandler}>비밀번호 변경
                                            </button>
                                        </div>
                                        { isClicked
                                          ? <div className="form-group">
                                            <div className="wrap-section wrap-tbl">
                                                <div className="box-body">
                                                    <div className="tbl">
                                                        <dl>
                                                            <dt>
                                                                <div className="dt-inner">
                                                                    <span className="fz-16 fc-1">
                                                                        현재 비밀번호
                                                                    </span>
                                                                </div>
                                                            </dt>
                                                            <dd>
                                                                <div className="form-group">
                                                                    <div className="input-group expand">
                                                                        <div className="inner-input-group">
                                                                            <input type="password" className="tf-comm" id='originPwd'
                                                                                   placeholder="비밀번호를 입력해 주세요."/>
                                                                        </div>
                                                                        <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                                    </div>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                        <dl>
                                                            <dt>
                                                                <div className="dt-inner">
                                                                    <span className="fz-16 fc-1">
                                                                        변경 비밀번호
                                                                    </span>
                                                                </div>
                                                            </dt>
                                                            <dd>
                                                                <div className="form-group">
                                                                    <div className="input-group expand">
                                                                        <div className="inner-input-group">
                                                                            <input type="password" className="tf-comm" id='newPwd'
                                                                                   placeholder="비밀번호를 입력해 주세요."/>
                                                                        </div>
                                                                        <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                                    </div>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                        <dl>
                                                            <dt>
                                                                <div className="dt-inner">
                                                                    <span className="fz-16 fc-1">
                                                                        재입력
                                                                    </span>
                                                                </div>
                                                            </dt>
                                                            <dd>
                                                                <div className="form-group">
                                                                    <div className="input-group expand">
                                                                        <div className="inner-input-group">
                                                                            <input type="password" className="tf-comm" id='newPwdChk'
                                                                                   placeholder="비밀번호를 입력해 주세요."/>
                                                                        </div>
                                                                        <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                                    </div>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                    </div>

                                                </div>
                                                <div className="box-footer">
                                                    <div className="box-right">
                                                        <button type="button" className="btn btn-primary w-100" onClick={pwdSendHandler}>저장
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                          : null }
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
  );
};

export default React.memo(Setting);
