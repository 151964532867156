import { ApiUtil3 } from '../legacy'
import { axios } from '../axios'

type UploadFileType = Partial<{
  afName: string
  fileDiv: 'img' | 'vod' | 'IMAGE' | 'VIDEO'
  fileId: number // 293286412
  fileName: string // '1024x1024.jpeg'
  fileSize: number // 369899
  gifDiv: 'vod' | 'img'
  height: number //1024
  publicUrl: string // 'https://rc2-bizstatic.wadiz.kr/static/creationFb/20241017/9892830/293286158/293286412.jpeg'
  uploadId: number // 293286158
  width: number // 1024
}>
export type FileImageAssetType = {
  campId: number
  creationDataId: number | string
  uploadFile: UploadFileType
}

type ResponseType = {
  ok: boolean
  data: boolean
}
type FunctionType = (data: FileImageAssetType) => Promise<ResponseType>
export const fetchPostFileImage: FunctionType = async (data) => {
  const { uploadFile } = data
  const { fileId, uploadId } = uploadFile
  const res = await ApiUtil3.post('/api/file/success', { fileId, uploadId })
  if (res.status !== 200) {
    return Promise.reject({ message: '실패' })
  }
  return axios.post('/api/camp/make02/fbCreationDataFileInsert', data)
}
