import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import alertify from 'alertifyjs'
import { fetchPostAttachedDocumentList } from '@biz/api'
import { useCurrentCampaignIdQuery } from '@biz/query'
import { useAttachDocumentQuery } from '../useAttachDocumentQuery'

// projectId
// 첫번째 탭에서 사용자가 선택하는 projectId는 생성시에는 Form.Item을 거쳐서 이리로 들어오는게 맞다.
// 하지만 수정시에는 어떻게 할 것인가? 원래 코드에서는 수정시 projectId를 API에서 받아서 세팅하게 하는데,
// 그것보다 어차피 Form.Item에 default 값으로 들어갈 것이므로 그걸 그대로 이용하는 편이 바람직하다.

type FunctionType = (props: { projectId: string }) => UseMutationResult
export const useAttachDocumentMutation: FunctionType = ({ projectId }) => {
  const { data: campaignId } = useCurrentCampaignIdQuery()

  const { data } = useAttachDocumentQuery()
  const { attachedDocuments, modifyHeader } = data || {}

  return useMutation({
    mutationFn: () => {
      const isModify = !!modifyHeader?.id
      return fetchPostAttachedDocumentList({
        param: isModify
          ? {
              campAttachId: modifyHeader?.id,
              project2Id: modifyHeader?.project2Id,
              campId: campaignId || -1,
              afs: attachedDocuments,
            }
          : {
              project2Id: projectId,
              campId: campaignId || -1,
              afs: attachedDocuments,
            },
        isModify,
      }).then((res) => {
        if (res.data === true) {
          alertify.success(!isModify ? '제출이 완료되었습니다.' : '수정이 완료되었습니다.')
        }
        return res
      })
    },
  })
}
