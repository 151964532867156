import React, { Fragment, useState } from 'react';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import { Button } from 'react-bootstrap';
import { ApiUtil3 } from '@biz/api'

interface PgParam {
  clientKey: string;
  customerKey: string;
  orderId: string;
  orderName: string;
  successUrl: string;
  failUrl: string;
}

const ExamplePg = () => {
  const [amount, setAmount] = useState(0);
  const changeAmount = (e: any) => {
    const { value } = e.target;
    setAmount(value);
  };

  const userNo = { projectId: '91' };

  // 카드 결제창 띄우기
  const cardPayment = () =>
    ApiUtil3.post('/api/pg/payment/ready', { ...userNo, cost: amount, txDiv: 'bi001' }).then((res) => {
      const pgParam: PgParam = { ...res.data.data }
      loadTossPayments(pgParam.clientKey).then((tossPayments) => {
        tossPayments
          .requestPayment('카드', {
            amount,
            orderId: pgParam.orderId,
            orderName: pgParam.orderName,
            successUrl: `${window.location.origin}/${pgParam.successUrl}`,
            failUrl: `${window.location.origin}/${pgParam.failUrl}`,
          })
          .then()
      })
    })

  // 계쫘 결제창 띄우기
  const accountPayment = () =>
    ApiUtil3.post('/api/pg/payment/ready', { ...userNo, cost: amount, txDiv: 'bi003' }).then((res) => {
      const pgParam: PgParam = { ...res.data.data }
      loadTossPayments(pgParam.clientKey).then((tossPayments) => {
        tossPayments
          .requestPayment('계좌이체', {
            amount,
            orderId: pgParam.orderId,
            orderName: pgParam.orderName,
            successUrl: `${window.location.origin}/${pgParam.successUrl}`,
            failUrl: `${window.location.origin}/${pgParam.failUrl}`,
          })
          .then()
      })
    })

  // 카드 자동 결제 등록 창 띄우기
  const cardAutoBill = () =>
    ApiUtil3.post('/api/pg/billingAuth/ready', { ...userNo, bzmoney: 10000, minBalance: 10000 }).then((res) => {
      const pgParam: PgParam = { ...res.data.data }
      loadTossPayments(pgParam.clientKey).then((tossPayments) => {
        tossPayments
          .requestBillingAuth('카드', {
            customerKey: pgParam.customerKey,
            successUrl: `${window.location.origin}/${pgParam.successUrl}`,
            failUrl: `${window.location.origin}/${pgParam.failUrl}`,
          })
          .then()
      })
    })

  return (
    <Fragment>
      <div>
        <ul>
          <li><input type='text' placeholder='금액 내놔' value={amount} onChange={changeAmount}/></li>
          <li><Button onClick={cardPayment}>카드 결제</Button></li>
          <li><Button onClick={cardAutoBill}>카드 자동결제</Button></li>
          <li><Button onClick={accountPayment}>실시간 계좌이체</Button></li>
        </ul>
      </div>
    </Fragment>
  );
};

export default ExamplePg;
