import { TermButton } from '@biz/ui'

const Footer = () => (
  <>
    <div className="box-left">
      <ul className="company-info">
        <li>
          <span className="fz-13">와디즈㈜</span>
        </li>
        <li>
          <span className="fz-13">대표이사 : 신혜성</span>
        </li>
        <li>
          <span className="fz-13">사업자등록번호 : 258-87-01370</span>
        </li>
        <li>
          <span className="fz-13">주소 : 경기 성남시 분당구 판교로 242 PDC A동 402호</span>
        </li>
      </ul>
    </div>
    <div className="box-right f-right">
      <ul className="footer-menu">
        <li>
          <TermButton selector="TERMS" />
        </li>
        <li>
          <TermButton selector="PRIVATE_DATA" />
        </li>
      </ul>
    </div>
  </>
)

export default Footer
