import { useEffect, useRef, useCallback } from 'react'
import { Form, Input } from 'antd'
import { toast } from 'react-toastify'
import { useProjectListQuery } from '@biz/query'
import { targetFormRegistry } from '@biz/ui'
import { checkDayLimit } from '../checkDayLimit'
import { FundingType } from './fundingType'
import { SelectorModal } from './SelectorModal'

type FundingTypeItemFunction = {
  selectedProjectId?: string
  onSelectedAction: () => void
}
const { FundingTypeItemName } = targetFormRegistry
export const FundingTypeItem: React.FC<FundingTypeItemFunction> = ({ selectedProjectId, onSelectedAction }) => {
  const preSelect = useRef('')

  const form = Form.useFormInstance()

  const { data: projectList } = useProjectListQuery()
  const project = (projectList || []).find(({ id }) => selectedProjectId === id)
  const isFundingProject = project && project.projectDiv === 'FUNDING'
  const availableGoingOnFunding = isFundingProject && checkDayLimit(project, 'FUNDING_ING')
  const availableComingSoonFunding = isFundingProject && checkDayLimit(project, 'FUNDING_OPEN')
  const isOpen = (preSelect.current !== project?.id && availableGoingOnFunding && availableComingSoonFunding) || false
  const setType = useCallback(
    (fType: FundingType) => {
      form.setFieldsValue({ [FundingTypeItemName]: fType })
      if (fType === 'FUNDING_ING' && !availableComingSoonFunding) {
        toast.info('펀딩 진행중 선택')
      }
      if (fType === 'FUNDING_OPEN' && !availableGoingOnFunding) {
        toast.info('오픈 예정 선택')
      }
      preSelect.current = project?.id || ''
      onSelectedAction()
    },
    [onSelectedAction, preSelect, form, availableGoingOnFunding, availableComingSoonFunding]
  )
  useEffect(() => {
    const onlyOneAvailable =
      (availableGoingOnFunding || availableComingSoonFunding) &&
      !(availableGoingOnFunding && availableComingSoonFunding)
    if (!isOpen && !!selectedProjectId && onlyOneAvailable) {
      setType(availableGoingOnFunding ? 'FUNDING_ING' : 'FUNDING_OPEN')
    }
  }, [selectedProjectId, isOpen, availableGoingOnFunding, availableComingSoonFunding])

  // 여기에 Form.Item-dependencies를 사용하지 않은 건, 순차적으로 프로젝트의 type을 선택해야 하기 때문이다.
  // dependencies를 사용하면, 순차적으로 사용자의 입력을 받을 수는 없다.
  return (
    <>
      <SelectorModal isOpen={isOpen} onResult={setType} />
      {/* funding type을 form에 태우기 위해 hidden 필드를 추가했음. */}
      <Form.Item name={[FundingTypeItemName]} style={{ padding: 0, margin: 0, height: 0 }}>
        <Input type="hidden" />
      </Form.Item>
    </>
  )
}
