import WadizIcon from './Ico-logo-wadiz-48x48.png'
import facebookHeader from './facebook-header.png'
import likeImage from './like.png'
import facebookFooter from './facebook-footer.png'
import styles from './FacebookFrame.module.scss'

type FacebookFrameFunction = {
  children?: React.ReactNode
  isPreview?: boolean
  onClick?: (e: React.MouseEvent) => void
}
export const FacebookFrame: React.FC<FacebookFrameFunction> = ({ children, isPreview, onClick }) => (
  <section>
    <div className={styles.headerContainer} style={{ opacity: isPreview ? 1 : 0.2 }} onClick={onClick}>
      <img src={facebookHeader} alt="" style={{ width: 447 }} />
      <div className={styles.wadizHead} style={{ opacity: isPreview ? 1 : 0, display: isPreview ? 'auto' : 'none' }}>
        <img src={WadizIcon} className={styles.wadizIcon} />
        <div>
          <div>와디즈</div>
          <div>광고</div>
        </div>
      </div>
    </div>
    {children}
    <div className={styles.bottomImage} onClick={onClick}>
      <img src={likeImage} alt="" style={{ width: 447, display: isPreview ? 'auto' : 'none' }} />
      <img src={facebookFooter} alt="" style={{ width: 447, opacity: isPreview ? 1 : 0.2 }} />
    </div>
  </section>
)
