import { axios } from '../axios'

export type DeleteAssetType = Partial<{
  afId: number | string // creationData.afList[].id
  campId: number //
  creationDataId: number // creationData.id
}>

type ResponseType = {
  ok: boolean
  data: boolean
}

type FunctionType = (props: DeleteAssetType) => Promise<ResponseType>
export const fetchPostCreativeImageDelete: FunctionType = (param) => {
  return axios.post('/api/camp/make02/fbCreationFileDelete', param)
}
