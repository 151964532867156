import React, { Fragment, useEffect, useRef } from 'react'
import { bytesToSize } from '../../views/fileUploader/MultiFileUploaderForConsulting'
import { Dialog } from 'rc-easyui'
import { fileDownHandler } from '../../views/bizmoneyAdmin/postpaidMoney/pomInquiry/PomInquiryDetail'
export class Af {
  fileName: string | undefined
  fileSize: number = 0
  filePath: string | undefined
  uploadId: any
  afId: any
  lastModified: string | undefined

  constructor(
    fileName: string | undefined,
    fileSize: number,
    filePath: string | undefined,
    uploadId?: number | undefined,
    lastModified?: string | undefined
  ) {
    this.fileName = fileName
    this.fileSize = fileSize
    this.filePath = filePath
    this.uploadId = uploadId
    this.lastModified = lastModified
  }
}

const CampAttachDialogForAdmin = (props: any) => {
  const dialogRef = useRef<any>()

  useEffect(() => {
    $(document).on('click', '#cancelBtn', () => {
      props.setIsOpenedCampAttachDialog(false)
      $('#campAttachDialog').dialog('close')
      return () => {
        // 페이지를 벗어나는 이동 시 destory 해줘야 한다. 다른 방법이 있는지 모름
        $('#campAttachDialog').dialog('destroy')
      }
    })
  })

  return (
    <Fragment>
      <form>
        <Dialog
          id="campAttachDialog"
          modal
          resizable
          shadow={false}
          ref={dialogRef}
          style={{ width: '1000px' }}
          title="첨부 서류 확인"
        >
          <div className="dialog-body">
            <div className="wrap-section wrap-tbl">
              <div className="box-body">
                <div className="tbl">
                  <div className="box-header">
                    <div className="box-tit">
                      <h2 className="fz-20 fc-1 fw-bold">캠페인 및 소재 정보</h2>
                    </div>
                  </div>
                  <dl className="column-two">
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">캠페인ID</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <div className="input-group expand">
                          <div className="inner-input-group">
                            <input type="text" className="tf-comm" disabled value={props.campId} />
                          </div>
                          <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                        </div>
                      </div>
                    </dd>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">소재ID</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <div className="input-group expand">
                          <div className="inner-input-group">
                            <input type="text" className="tf-comm" disabled value={props.creationDataId} />
                          </div>
                          <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                        </div>
                      </div>
                    </dd>
                  </dl>
                  <div className="box-header">
                    <div className="box-tit">
                      <h2 className="fz-20 fc-1 fw-bold">첨부 파일</h2>
                    </div>
                  </div>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">*파일 첨부(필수)</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        {props.afs.current.length > 0 ? (
                          <div className="form-group">
                            <div className="comp-file-list">
                              <div className="file-list-top">
                                <span className="fz-14 fc-2">
                                  첨부 파일
                                  <span className="fc-5">
                                    <span className="fc-2" id="fileCnt">
                                      {props.afs.current.length}
                                    </span>
                                  </span>
                                  개
                                </span>
                              </div>
                              <div className="file-list-bottom">
                                {props.afs.current.map((af: Af, key: number) => {
                                  return (
                                    <div className="file-item" key={key} id={`file-${af.uploadId}`}>
                                      <i className="ico ico-file-list"></i>
                                      <span className="fz-12 fc-2">{af.fileName}</span>
                                      <span className="fz-12 fc-3">({bytesToSize(af.fileSize)})</span>
                                      <i
                                        className="ico ico-download"
                                        onClick={() => {
                                          fileDownHandler(af.filePath, af.fileName)
                                        }}
                                      />
                                      <span className="fz-12 fc-3">
                                        &nbsp;&nbsp;&nbsp;&nbsp;업로드 일시 {af.lastModified}
                                      </span>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div className="dialog-footer">
            <button type="button" className="btn btn-primary-outline" id={'cancelBtn'}>
              확인
            </button>
          </div>
        </Dialog>
      </form>
    </Fragment>
  )
}

export default CampAttachDialogForAdmin
