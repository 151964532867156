// TODO: react-query가 과거 버전이다. 업그레이드 해야 한다.
import { useQuery } from '@tanstack/react-query' // try next react-query;
import { fetchAdList, type ParamType } from '@biz/api'

export const useAdListQuery = ({ params }: { params: ParamType }) =>
  useQuery({
    queryKey: ['@bizCenter/SalesReport/DpPush/AdList', JSON.stringify(params)],
    queryFn: () => fetchAdList(params),
    enabled: Boolean(params),
  });
