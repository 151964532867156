import { useState, useEffect } from 'react'
import { type ImageMetaDataType } from '@biz/query'
import { EmptyPreview } from '@biz/ui'
import styles from './PreviewPanel.module.scss'

type PreviewDataType = Partial<{
  isLoading: boolean
  src: string
  mediaType: 'VIDEO' | 'IMAGE'
  fileType: string
  name: string
}>
type PreviewPanelFunction = {
  mediaData: ImageMetaDataType | null
}
export const PreviewPanel: React.FC<PreviewPanelFunction> = ({ mediaData }) => {
  const [previewData, setPreviewData] = useState<PreviewDataType>({})
  useEffect(() => {
    if (!mediaData?.isFetching) {
      const ext = mediaData?.afMeta?.fileDiv?.toLocaleLowerCase()
      const mediaType = (ext === 'vod' || ext === 'video') && mediaData?.afMeta?.ext !== 'gif' ? 'VIDEO' : 'IMAGE'
      setPreviewData({
        isLoading: false,
        src: mediaData?.publicUrl || mediaData?.fileName,
        mediaType,
        fileType: mediaData?.afMeta?.ext,
        name: mediaData?.afName,
      })
    } else {
      setPreviewData({ isLoading: true })
      const file = mediaData?.localFile
      const reader = new FileReader()
      reader.onload = function (e) {
        const fileType = file?.type || ''
        const mediaType = fileType.startsWith('video')
          ? 'VIDEO'
          : fileType.startsWith('image')
          ? fileType === 'image/gif'
            ? 'VIDEO'
            : 'IMAGE'
          : 'IMAGE'

        setPreviewData({
          isLoading: false,
          src: e?.target?.result as string,
          mediaType,
          name: file?.name,
          fileType: fileType.split('/')[1],
        })
      }
      if (!!file) {
        reader.readAsDataURL(file)
      } else {
        setTimeout(() => {
          setPreviewData({ isLoading: false })
        }, 5000)
      }
    }
  }, [mediaData])
  const { src, mediaType, name, fileType } = previewData
  return (
    <div className={styles.PreviewPanel}>
      {!!mediaData ? (
        mediaType === 'VIDEO' && fileType !== 'gif' ? (
          <video autoPlay controls muted loop src={src} />
        ) : (
          <img src={src} alt={name} />
        )
      ) : (
        <EmptyPreview />
      )}
    </div>
  )
}
