import { type QueryClient } from '@tanstack/react-query'
import { type CampaignInfoType, type ProjectInfoType, type CampaignStatusType } from '@biz/api'
import { useCampaignInfoQuery, loadCampaignInfo } from '../useCampaignInfoQuery'
import { loadProjectList, useProjectListQuery } from '../useProjectListQuery'
import { resetCampaignInfo } from '../useCampaignInfoQuery'

/* 캠페인 운영을 위한 코드 */
const finishedSet: CampaignStatusType[] = ['complete', 'del_ready', 'del_complete'] // 종료 상태 집합
const stoppedSet: CampaignStatusType[] = ['stop_creation_off', 'stop_camp_off', 'stop_camp_range'] //멈춤 상태 집합
const beforeAIInspectionSet: CampaignStatusType[] = ['temp'] // AI 검사 전 상태 집합
const inInspectionSet: CampaignStatusType[] = ['ing_insp'] // 검수중 상태

export const resetCampaignStatus = (queryClient: QueryClient) => resetCampaignInfo(queryClient)
export const useCampaignStatus = () => {
  const { data: projectList } = useProjectListQuery()
  const { data: info } = useCampaignInfoQuery()
  const { data } = (info || {}) as { ok: boolean; data: CampaignInfoType }
  const { id: campaignId, campViewStatus, adminCampYn } = data || {}

  // temp는 임시 상태로 검수 전 상태, 상태가 없어도 검수전 상태
  const isBeforeInspection = () => !campViewStatus || campViewStatus === 'temp'
  const inInspection = () => !!campViewStatus && inInspectionSet.includes(campViewStatus)

  // https://wadiz.atlassian.net/wiki/spaces/TF/pages/16337405690/-+-+AI
  // 소재가 검수중(insp_ing), 재검수중(reg_insp) 상태값을 가질때
  // insp_ing, reg_insp 전 단계라면, temp, insp_none, reg 인 경우로 일단 해보자.

  // 제외 조건 3가지
  // 조건 1. 광고 소재가 [재검수중] 상태값이고
  // 조건 2. 해당 소재가 관리자의 수동 검수 이력이 있으며
  // 조건 3. 이 때, 관리자의 소재 검수 결과가 AI 소재 검수 결과와 반대될 때
  // && !(
  //   ['reg', 'reject'].includes(campViewStatus) && 수동 검수 이력 && AI 소재 검수 결과와 반대 관리자 소재검수 결과
  // )
  const isAIInspection = (projectId?: string) => {
    const selectedProject = projectList?.find((n) => n.id === projectId)
    return (
      !!campViewStatus &&
      beforeAIInspectionSet.includes(campViewStatus) &&
      !!selectedProject &&
      selectedProject.isAIApprove &&
      !selectedProject.isAdultContent
    )
  }

  const isFinished = () => !!campViewStatus && finishedSet.includes(campViewStatus)
  const isAdmin = () => Boolean(adminCampYn)
  return {
    isBeforeInspection, // 검수전
    inOperation: () => !isBeforeInspection() && !isFinished(),
    isFinished, // 캠페인 종료
    inInspection, // 검수중
    isAIInspection, // AI검수전
    isOutOfOperation: () => isBeforeInspection() || isFinished(), // 운영 중이 아님
    isAdmin, // 어드민 캠페인
    isOnGoing: () => campViewStatus === 'ing',
    isWait: () => campViewStatus === 'wait',
  }
}
