import { useCallback, useState } from 'react'
import { Select, Form } from 'antd'
import { useProjectListQuery, useCampaignStatus } from '@biz/query'
import { CampaignCaption, targetFormRegistry, UpdateConfirmModal } from '@biz/ui'
import { useUpdateCampaign } from '../_hooks/useUpdateCampaign'
import styles from './AgeRangePicker.module.scss'

const makeAgeArray = (start: number, end: number) =>
  Array.from({ length: end - start }).map((_, n) => {
    const item = n + start // 18
    return { label: item + '', value: item }
  })

type AgeRangeProps = {
  value?: [number, number]
  onChange?: (v: [number, number]) => void
}
const AgeRange: React.FC<AgeRangeProps> = ({ value, onChange }) => {
  const { isFinished, isAdmin, inOperation } = useCampaignStatus()
  const [changeConfirmData, setChangeConfirmData] = useState<{
    oldContent: string
    newContent: string
    updateCallback: () => void
  } | null>(null)

  const [minAge, maxAge] = value || [18, 65]
  const minOption = makeAgeArray(18, maxAge)
  const maxOption = makeAgeArray(minAge + 1, 65).concat([{ label: '65+', value: 65 }])
  const doChangeMin = useCallback(
    (min: number) => {
      if (!inOperation?.()) {
        onChange?.([min, maxAge])
        return
      }
      setChangeConfirmData({
        oldContent: `나이: ${minAge} ~ ${maxAge}${maxAge === 65 ? '+' : ''}`,
        newContent: `나이: ${min} ~ ${maxAge}${maxAge === 65 ? '+' : ''}`,
        updateCallback: () => onChange?.([min, maxAge]),
      })
    },
    [minAge, maxAge, onChange]
  )
  const doChangeMax = useCallback(
    (max: number) => {
      if (!inOperation?.()) {
        onChange?.([minAge, max])
        return
      }
      setChangeConfirmData({
        oldContent: `나이: ${minAge} ~ ${maxAge}${maxAge === 65 ? '+' : ''}`,
        newContent: `나이: ${minAge} ~ ${max}${max === 65 ? '+' : ''}`,
        updateCallback: () => onChange?.([minAge, max]),
      })
    },
    [minAge, maxAge, onChange]
  )
  return (
    <div className={styles.AgeRangePicker}>
      <Select
        options={minOption}
        style={{ width: 150 }}
        value={minAge}
        onChange={doChangeMin}
        disabled={isFinished?.() || isAdmin?.()}
      />
      <div style={{ height: 40, lineHeight: '40px' }}>{'~'}</div>
      <Select
        options={maxOption}
        style={{ width: 150 }}
        value={maxAge}
        onChange={doChangeMax}
        disabled={isFinished?.() || isAdmin?.()}
      />
      <UpdateConfirmModal
        isOpen={changeConfirmData !== null}
        title={'캠페인타겟을 수정 하시겠습니까?'}
        onClose={() => setChangeConfirmData(null)}
        onConfirm={() => {
          changeConfirmData?.updateCallback()
          setChangeConfirmData(null)
        }}
        oldContent={changeConfirmData?.oldContent || ''}
        newContent={changeConfirmData?.newContent || ''}
      />
    </div>
  )
}

const { AgeRangePickerName, ProjectSelectorName } = targetFormRegistry
export const AgeRangePicker: React.FC = () => {
  const doUpdate = useUpdateCampaign()
  const { data: projectList } = useProjectListQuery()
  return (
    <div>
      <CampaignCaption
        tooltip={'성인 인증이 필요한 프로젝트의 광고 캠페인은 최소 타겟 나이를 20세 이상으로만 설정 가능합니다.'}
      >
        {'나이'}
      </CampaignCaption>
      <Form.Item dependencies={[ProjectSelectorName]} style={{ padding: 0, margin: 0, height: 0 }}>
        {({ getFieldValue, setFieldValue }) => {
          const selectedProjectName = getFieldValue(ProjectSelectorName)
          const project = projectList?.find((n) => n.id === selectedProjectName)
          if (!!project && project.isAdultContent === true) {
            const [min, max] = getFieldValue(AgeRangePickerName)
            setFieldValue(AgeRangePickerName, [Math.max(20, min), max]) // 성인용 프로젝트는 최소 나이를 20세로 고정
          }
          return <></>
        }}
      </Form.Item>
      <Form.Item name={AgeRangePickerName}>
        <AgeRange onChange={doUpdate} />
      </Form.Item>
    </div>
  )
}
