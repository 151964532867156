import React, { useRef } from 'react'
import { useCampaignStatus } from '@biz/query'
import { ToolBox } from '../ToolBox'
import { CampaignStatus } from './CampaignStatus'
import { CampaignOwnNotice } from './CampaignOwnNotice'
import { CampaignName } from './CampaignName'
import { CampaignObjective } from './CampaignObjective'
import { ProjectSelector } from './ProjectSelector'
import { CampaignRange } from './CampaignRange'
import { CampaignMedia } from './CampaignMedia'
import { CampaignTotal } from './CampaignTotal'
import styles from './CampaignInfoTab.module.scss'

export const CampaignInfoTab: React.FC<{ onNext: () => void }> = ({ onNext }) => {
  const { inOperation, isAdmin } = useCampaignStatus()
  const nameFieldRef = useRef<{ forceUpdate: () => void }>(null)
  return (
    <section className={styles.containCampaignInfoTab}>
      {isAdmin?.() && <CampaignOwnNotice />}
      {inOperation?.() && !isAdmin?.() && <CampaignStatus />}
      <dl className={styles.dualColumn}>
        <CampaignName ref={nameFieldRef} />
        <CampaignObjective />
      </dl>
      <dl className={styles.dualColumn}>
        <ProjectSelector onUpdateCampaignName={() => nameFieldRef?.current?.forceUpdate()} />
        <CampaignRange />
      </dl>
      <dl className={styles.dualColumn} style={{ borderBottom: 0 }}>
        <CampaignMedia />
        <CampaignTotal />
      </dl>

      <ToolBox onNext={onNext} />
    </section>
  )
}

// export const CampaignInfoTab = React.memo(CampaignInfoTabImpl)
