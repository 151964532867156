import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { fetchImageList, type ImageInfoType } from '@biz/api'

export type ImageMetaDataType = Partial<
  ImageInfoType & {
    localFile: File
    isFetching: boolean
    gifStyle: 'vod' | 'img'
    uploadId: number
    fileId: number
    publicUrl?: string
  }
>

type UseFunctionType = () => UseQueryResult<ImageInfoType[]>
export const useImageListQuery: UseFunctionType = () =>
  useQuery({
    queryKey: ['@biz/query/createTargetAd/ImageListQuery'],
    queryFn: () =>
      fetchImageList().then((res) => (res?.ok !== true ? [] : res?.data?.reduce((acc, item) => acc.concat(item), []))),
  })
