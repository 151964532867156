import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { AgGridReact } from 'ag-grid-react'
import { ColDef } from 'ag-grid-community'
import { ApiUtil2 } from '@biz/api'
import alertify from 'alertifyjs'
import { ApiUtil } from '@biz/api'
import { TermButton } from '@biz/ui'
import MakerSettingDialog from './MakerSettingDialog'
import NoDataTemplate from '../../common/NoDataTemplate'

export interface IMakerConfig {
  data: any
  id: number | undefined
  makerNm: string | undefined
  hp: number | undefined
  agreePrivateVersion: string | undefined
  agreeTermsVersion: string | undefined
  agreeTime: string | undefined
  userNos: string[] | undefined
  changeable: boolean
  accId: number | undefined
  userNo: string | undefined
  alertYn: boolean | undefined
  campAlertYn: boolean | undefined
  promotionAlertYn: boolean | undefined
  bizmoneyAlertYn: boolean | undefined
  nightAlertYn: boolean | undefined
  paidCost: string | number | undefined
  refundBank: string | undefined
  refundAccName: string | undefined
  refundAccNum: string | undefined
  refundable: boolean | undefined
}

const MakerSetting = () => {
  const setTitle = useOutletContext<any>()
  // eslint-disable-next-line no-unused-vars
  const [makerId, setMakerId] = useState(0)
  const [isRefundable, setIsRefundable] = useState(false)
  const [isAccClicked, setIsAccClicked] = useState(false)
  const [userNo, setUserNo] = useState<string>('')
  const [paidCost, setPaidCost] = useState<number>(0)
  const [alertYn, setAlertYn] = useState(true)
  const [userNoList, setUserNoList] = useState<string[][]>([])

  // 다이얼로그
  const gridRefList = useRef<AgGridReact>(null)
  const projectIdRef = useRef<any>(null)
  const [dialogData, setDialogData] = useState<any[]>()
  const [columnDialogDefs] = useState<ColDef[]>([
    {
      field: 'regDate',
      headerName: '환불 신청일',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'refundCost',
      headerName: '환불 대상 금액',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'refundTypeDesc',
      headerName: '환불방식',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'refundStatusDesc',
      headerName: '상태',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
  ])

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      resizable: false,
      suppressMovable: true,
      sortable: true,
    }),
    []
  )

  const { setValue, watch, getValues } = useForm<IMakerConfig>()

  const { campAlertYn, promotionAlertYn, bizmoneyAlertYn, nightAlertYn } = watch()

  const getPrevData = async () => {
    ApiUtil2.get<IMakerConfig>('/api/maker/setting').then((resp) => {
      const s = resp.data.data
      setMakerId(Number(s.id))
      setValue('id', Number(s.id))
      setValue('makerNm', s.makerNm)
      setValue('hp', s.hp)
      setValue('agreePrivateVersion', s.agreePrivateVersion)
      setValue('agreeTermsVersion', s.agreeTermsVersion)
      setValue('agreeTime', s.agreeTime)
      setValue('userNos', s.userNos)
      setValue('changeable', s.changeable)
      setUserNoList(s.userNos)
      setValue('accId', s.accId)
      setValue('userNo', s.userNo)
      setValue('campAlertYn', s.campAlertYn)
      setValue('promotionAlertYn', s.promotionAlertYn)
      setValue('bizmoneyAlertYn', s.bizmoneyAlertYn)
      setValue('nightAlertYn', s.nightAlertYn)
      setValue('paidCost', s.paidCost)
      if (s.paidCost !== undefined) setPaidCost(s.paidCost)
      setValue('refundBank', s.refundBank)
      setValue('refundAccName', s.refundAccName)
      setValue('refundAccNum', s.refundAccNum)
      setValue('refundable', s.refundable)
      setIsRefundable(s.refundable)
      setAlertYn(
        !(
          s.campAlertYn === false &&
          s.promotionAlertYn === false &&
          s.bizmoneyAlertYn === false &&
          s.nightAlertYn === false
        )
      )
    })
  }

  const errorEvent = (msg: string) => {
    alertify.error(msg, 3)
  }

  const confirmError = (msg: string) => {
    errorEvent(msg)
  }

  const successEvent = (msg: string) => {
    alertify.success(msg, 1)
  }

  const confirmSuccess = (msg: string) => {
    successEvent(msg)
  }

  const onAlert = (checked: boolean) => {
    if (checked === false) {
      setValue('campAlertYn', false)
      setValue('promotionAlertYn', false)
      setValue('bizmoneyAlertYn', false)
      setValue('nightAlertYn', false)
    }
    if (checked === true) {
      setValue('campAlertYn', true)
      setValue('promotionAlertYn', true)
      setValue('bizmoneyAlertYn', true)
      setValue('nightAlertYn', false)
    }
    ApiUtil.post<IMakerConfig>('/api/maker/setting/alert', {
      alertYn: $('#alertYn').is(':checked'),
      campAlertYn: getValues('campAlertYn'),
      promotionAlertYn: getValues('promotionAlertYn'),
      bizmoneyAlertYn: getValues('bizmoneyAlertYn'),
      nightAlertYn: getValues('nightAlertYn'),
    }).then(() => {
      confirmSuccess('변경사항이 저장되었습니다.')
    })
  }

  const onAlertDetail = () => {
    ApiUtil.post<IMakerConfig>('/api/maker/setting/alert', {
      alertYn: $('#alertYn').is(':checked'),
      campAlertYn: getValues('campAlertYn'),
      promotionAlertYn: getValues('promotionAlertYn'),
      bizmoneyAlertYn: getValues('bizmoneyAlertYn'),
      nightAlertYn: getValues('nightAlertYn'),
    }).then(() => {
      confirmSuccess('변경사항이 저장되었습니다.')
    })
  }

  const onRefundReq = () => {
    ApiUtil.get<IMakerConfig>('/api/maker/setting/refundAble', { params: { data: { makerId } } }).then((resp) => {
      console.log(resp.data.data)
      if (resp.data.data.isRefundReqAvail === false) confirmError(resp.data.data.msg)
      if (resp.data.data.isRefundReqAvail) {
        alertify
          .confirm(
            `유상비즈머니 ${
              getValues('paidCost') !== undefined
                ? paidCost.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
                : null
            }원에 대한 환불을 신청합니다. <br/>신청 후 현황은 환불내역에서 확인이 가능합니다.`,
            () => {
              ApiUtil.post('/api/maker/setting/refundReq', { makerId }).then(() => {
                confirmSuccess('환불신청이 완료되었습니다.')
                setIsRefundable(true)
              })
            },
            {}
          )
          .set({ labels: { cancel: '취소', ok: '확인' } })
          .setHeader('환불신청')
      }
    })
  }

  const onDialogReady = useCallback(() => {
    ApiUtil2.get<IMakerConfig>('/api/maker/setting/refundList').then((resp) => {
      setDialogData(resp.data.data)
    })
  }, [])

  const dialogOpen = () => {
    $('#refundListDialog').dialog({ width: 1200, title: '환불내역', closeOnEscape: true })
    $('#refundListDialog').dialog('open')
    onDialogReady()
  }

  const updateUserNoHandler = () => {
    console.log(projectIdRef.current.value)
    if ($('#userno').val() !== null) {
      ApiUtil.post<IMakerConfig>('/api/maker/setting/updateUserNo', {
        makerId,
        projectId: projectIdRef.current.value,
      }).then(() => {
        confirmSuccess('변경사항이 저장되었습니다.')
      })
    } else {
      confirmError('충전사업자번호를 선택하세요')
    }
  }

  useEffect(() => {
    setTitle('설정')

    // select2
    $('#userno')
      .select2({
        width: '300',
        minimumResultsForSearch: Infinity,
        placeholder: getValues('userNos') === undefined ? '등록된 프로젝트가 없습니다.' : '선택하세요',
      })
      .on('select2:select', (e) => {
        setUserNo(e.params.data.text)
      })

    // 툴팁
    $('#phoneNum').tooltip({
      content: '<p class="fz-12 fc-0">휴대전화 번호는 와디즈 사이트에서 변경하면 <br/> 24시간 이내에 적용됩니다.</p>',
    })

    $('#userNo').tooltip({
      content:
        '<p class="fz-12 fc-0 fw-medium">다음의 조건이 충족되야 변경이 가능합니다.</p>' +
        '<p class="fz-12 fc-0"> 1. 자동충전 비활성화되어야 합니다.</br>' +
        '2. 쿠폰을 포함하여 비즈머니 잔액이 0원이 되야 합니다.</br>' +
        '3. 무통장 입금 대기건이 없어야 합니다.</br>' +
        '4. 진행중이거나 정산이 완료되지 않은 캠페인이 없어야 합니다.</br>' +
        '5. 진행중인 광고대행이 없어야 합니다.</br>' +
        '6. 진행중인 환불 요청이 없어야 합니다.</br>' +
        '</p>',
    })
    getPrevData()

    return () => {
      setTitle('')
    }
  }, [])

  useEffect(() => {
    $('#close').click(() => {
      $('#refundListDialog').dialog('close')
    })
    return () => {
      // 페이지를 벗어나는 이동 시 destory 해줘야 한다. 다른 방법이 있는지 모름
      if ($('#refundListDialog').length > 0 && $.data($('#refundListDialog')[0], 'dialog')) {
        $('#refundListDialog').dialog('destroy')
      }
    }
  }, [])

  return (
    <>
      <Fragment>
        <form>
          {isAccClicked ? (
            <MakerSettingDialog setIsAccClicked={setIsAccClicked} makerId={makerId} getPrevData={getPrevData} />
          ) : null}
          <div className="container-fluid">
            <div className="row">
              <div className="col col-4">
                <div className="wrap-section wrap-tbl wrap-dashboard">
                  <div className="box-body">
                    <div className="tbl">
                      <dl className="vertical">
                        <dt>
                          <div className="dt-inner">
                            <span className="fz-14 fc-4">메이커명</span>
                          </div>
                        </dt>
                        <dd>
                          <div className="form-group">
                            <span className="comp-txt">
                              <span className="table">
                                <span className="table-cell">
                                  <b className="fz-20 fc-2 fw-medium">{getValues('makerNm')}</b>
                                </span>
                              </span>
                            </span>
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-4">
                <div className="wrap-section wrap-tbl wrap-dashboard">
                  <div className="box-body">
                    <div className="tbl">
                      <dl className="vertical">
                        <dt>
                          <div className="dt-inner">
                            <span className="fz-14 fc-4">
                              휴대폰 번호
                              {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                              <a href="#javascript" id="phoneNum" className="ico-tooltip" title=""></a>
                            </span>
                          </div>
                        </dt>
                        <dd>
                          <div className="form-group">
                            <span className="comp-txt">
                              <span className="table">
                                <span className="table-cell">
                                  <b className="fz-20 fc-2 fw-medium">{getValues('hp')}</b>
                                </span>
                              </span>
                            </span>
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-4">
                <div className="wrap-section wrap-tbl wrap-dashboard">
                  <div className="box-body">
                    <div className="tbl">
                      <dl className="vertical">
                        <dt>
                          <div className="dt-inner">
                            <span className="fz-14 fc-4">Wa-biz 약관 동의일</span>
                          </div>
                        </dt>
                        <dd>
                          <div className="form-group flex-container">
                            <div className="box-left">
                              <span className="comp-txt">
                                <span className="table">
                                  <span className="table-cell">
                                    <b className="fz-20 fc-2 fw-medium">{getValues('agreeTime')}</b>
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div className="box-right">
                              <span className="comp-txt">
                                <span className="table">
                                  <span className="table-cell">
                                    <b className="fz-12 fc-6">
                                      <TermButton selector="PRIVATE_DATA" />
                                    </b>
                                    <b className="fz-12 fc-6">
                                      <TermButton selector="TERMS" />
                                    </b>
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col col-12">
                <div className="wrap-section wrap-tbl">
                  <div className="box-body">
                    <div className="tbl">
                      <dl className="colum-two">
                        <dt>
                          <div className="dt-inner">
                            <span className="fz-16 fc-1">충전사업자번호</span>
                          </div>
                        </dt>
                        <dd>
                          <div className="form-group">
                            <select
                              className="select2-single w-300"
                              id="userno"
                              defaultValue={userNo}
                              disabled={!getValues('changeable')}
                              ref={projectIdRef}
                            >
                              {userNoList === undefined
                                ? ''
                                : userNoList.map((a: any, k: any) => (
                                    <option key={k} value={a.projectId}>
                                      {a.userNo}
                                    </option>
                                  ))}
                            </select>
                            {getValues('changeable') ? (
                              <button
                                type="button"
                                id="usernoBtn"
                                onClick={updateUserNoHandler}
                                className="btn btn-primary"
                              >
                                저장
                              </button>
                            ) : null}
                          </div>
                          <div className="form-group">
                            <p className="comp-txt">
                              <span className="table">
                                <span className="table-cell">
                                  <b className="fz-12 fc-3 lh20">
                                    <i className="fz-12 fc-5">*</i>선택한 사업자번호를 기준으로 비즈머니가 관리됩니다.
                                  </b>
                                  {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                                  <b className="fz-12 fc-3 lh20">
                                    <i className="fz-12 fc-5">*</i>사업자번호 변경조건
                                    <a id="userNo" className="ico-tooltip" title="" href="#javascript"></a>
                                  </b>
                                </span>
                              </span>
                            </p>
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row flex-container">
              <div className="col col-6">
                <div className="wrap-section wrap-tbl">
                  <div className="box-header">
                    <div className="box-tit">
                      <h2 className="fz-20 fc-1 fw-bold">알림설정</h2>
                    </div>
                  </div>
                  <div className="box-header">
                    <div className="box-tit">
                      <h2 className="fz-18 fc-1">알림 수신동의</h2>
                    </div>
                    <div className="box-option">
                      <div className="comp-switch">
                        <input
                          type="checkbox"
                          id="alertYn"
                          checked={alertYn}
                          onChange={(event) => {
                            setAlertYn(event.target.checked)
                            onAlert(event.target.checked)
                          }}
                        />
                        <label htmlFor="alertYn">
                          <i className="ico"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="box-body">
                    <div className="tbl">
                      <dl className="vertical">
                        <dd>
                          <div className="form-group">
                            <div className="comp-checkbox block">
                              <input
                                type="checkbox"
                                id="campAlertYn"
                                checked={campAlertYn === true}
                                disabled={alertYn === false}
                                onChange={(event) => {
                                  setValue('campAlertYn', event.target.checked)
                                  onAlertDetail()
                                }}
                              ></input>
                              <label htmlFor="campAlertYn">캠페인 알림 수신</label>
                            </div>
                            <div className="comp-checkbox block">
                              <input
                                type="checkbox"
                                id="promotionAlertYn"
                                checked={promotionAlertYn === true}
                                disabled={alertYn === false}
                                onChange={(event) => {
                                  setValue('promotionAlertYn', event.target.checked)
                                  onAlertDetail()
                                }}
                              ></input>
                              <label htmlFor="promotionAlertYn">프로모션 알림 수신</label>
                            </div>
                            <div className="comp-checkbox block">
                              <input
                                type="checkbox"
                                id="bizmoneyAlertYn"
                                checked={bizmoneyAlertYn === true}
                                disabled={alertYn === false}
                                onChange={(event) => {
                                  setValue('bizmoneyAlertYn', event.target.checked)
                                  onAlertDetail()
                                }}
                              ></input>
                              <label htmlFor="bizmoneyAlertYn">비즈머니 충전 알림 수신</label>
                            </div>
                            <div className="comp-checkbox block">
                              <input
                                type="checkbox"
                                id="nightAlertYn"
                                checked={nightAlertYn === true}
                                disabled={alertYn === false}
                                onChange={(event) => {
                                  setValue('nightAlertYn', event.target.checked)
                                  onAlertDetail()
                                }}
                              ></input>
                              <label htmlFor="nightAlertYn">야간 알림 수신 (21시 ~ 익일 8시)</label>
                            </div>
                          </div>
                          <div className="form-group">
                            <span className="comp-txt">
                              <span className="table">
                                <span className="table-cell">
                                  <b className="fz-12 fc-3 lh20">
                                    <i className="fz-12 fc-5">*</i>의무적으로 안내되어야 하는 정보성 내용은 수신동의
                                    여부와 무관하게 제공합니다.
                                  </b>
                                </span>
                              </span>
                            </span>
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-6">
                <div className="wrap-section wrap-tbl">
                  <div className="box-header">
                    <div className="box-tit">
                      <h2 className="fz-20 fc-1 fw-bold">환불신청</h2>
                    </div>
                  </div>
                  <div className="box-header">
                    <div className="box-tit">
                      <h2 className="fz-18 fc-1">
                        {getValues('refundBank') === undefined || ''
                          ? '등록된 환불계좌가 없습니다.'
                          : getValues('refundBank')}
                      </h2>
                      <span className="fz-18 fc-1">
                        {getValues('refundAccNum') === undefined || '' ? '' : getValues('refundAccNum')}
                      </span>
                    </div>
                    <div className="box-option">
                      <button
                        type="button"
                        className="btn btn btn-primary"
                        onClick={() => {
                          setIsAccClicked(true)
                        }}
                      >
                        {getValues('refundAccNum') === undefined || '' ? '등록' : '변경'}
                      </button>
                    </div>
                  </div>
                  <div className="box-body">
                    <div className="tbl">
                      <dl className="vertical">
                        <dd>
                          <div className="form-group">
                            <div className="comp-help">
                              <ul className="help-list">
                                <li className="list">
                                  <span className="fz-14 fc-4 bullet">
                                    비즈머니 환불은 비즈머니 충전과 동일한 결제수단(카드/계좌)으로 환불이 진행됩니다.
                                  </span>
                                </li>
                                <li className="list">
                                  <span className="fz-14 fc-4 bullet">
                                    환불 진행은 은행/카드사/PG사의 정책에 따라 일정 기간 이후 환불이 불가할 수 있음을
                                    안내 드립니다. (환불 가능 기간 확인 필수)
                                  </span>
                                </li>
                              </ul>
                              <ul className="help-list">
                                <li className="list">
                                  <span className="fz-14 fc-4 bullet">카드 환불</span>
                                  <ul style={{ marginLeft: '20px' }}>
                                    <li>
                                      <span className="fz-14 fc-4">
                                        충전일부터 1년이 지나면 카드사 정책에 따라 환불이 불가할 수 있습니다.
                                      </span>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                              <ul className="help-list">
                                <li className="list">
                                  <span className="fz-14 fc-4 bullet">계좌이체 환불</span>
                                  <ul style={{ marginLeft: '20px' }}>
                                    <li className="list">
                                      <span className="fz-14 fc-4 ">충전일부터 180일 이내에 환불 신청 가능합니다.</span>
                                    </li>
                                    <li className="list">
                                      <span className="fz-14 fc-4 ">
                                        단, 비즈머니를 충전한 계좌로 환불되기 때문에, 충전 계좌가 출금 전용 계좌가
                                        아닌지 확인 후 충전 부탁드립니다.
                                      </span>
                                    </li>
                                    <li className="list">
                                      <span className="fz-14 fc-4 ">
                                        출금 전용 계좌(특수 용도 외 입금이 불가한 계좌 등)의 경우, 환불 처리시 영업일
                                        기준 30일 이상 소요됩니다.
                                      </span>
                                    </li>
                                    <li className="list">
                                      <span className="fz-14 fc-4 ">
                                        환불 실패로 인한 개별 입금 처리는 PG사 수수료가 청구됩니다.
                                      </span>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                  <div className="box-body">
                    <div className="tbl">
                      <dl className="vertical">
                        <dd>
                          <div className="form-group">
                            <div className="comp-help">
                              <ul className="help-list">
                                <li className="list">
                                  <i className="ico ico-help"></i>
                                  <span className="fz-14 fc-2">환불 주의사항</span>
                                </li>
                                <li className="list">
                                  <span className="fz-14 fc-4 bullet">
                                    진행중인 캠페인이 있으면 환불 신청이 불가능합니다.
                                  </span>
                                </li>
                                <li className="list">
                                  <span className="fz-14 fc-4 bullet">
                                    캠페인 정산이 모두 완료되어야 환불 신청이 가능합니다.
                                  </span>
                                </li>
                                <li className="list">
                                  <span className="fz-14 fc-4 bullet">
                                    환불을 신청하면 유상비즈머니 잔액이 모두 환불됩니다.
                                  </span>
                                </li>
                                <li className="list">
                                  <span className="fz-14 fc-4 bullet">
                                    유상비즈머니를 충전한 방식 그대로 환불됩니다.
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                  <div className="box-footer">
                    <div className="box-right">
                      <button type="button" className="btn btn-primary-outline" onClick={dialogOpen}>
                        환불내역
                      </button>
                      <button
                        type="button"
                        className="btn btn btn-danger-outline"
                        disabled={isRefundable}
                        onClick={onRefundReq}
                      >
                        환불신청
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="refundListDialog" className="dialog" style={{ display: 'none' }}>
            <div className="dialog-body">
              <div className="container-fluid">
                <div className="row">
                  <div className="col col-12">
                    <div className="wrap-section wrap-datagrid">
                      <div className="box-body">
                        <div className="ag-grid">
                          <div className="ag-grid-inner">
                            <AgGridReact
                              ref={gridRefList}
                              rowData={dialogData}
                              columnDefs={columnDialogDefs}
                              defaultColDef={defaultColDef}
                              rowHeight={48}
                              pagination={false}
                              paginationPageSize={10}
                              suppressPaginationPanel={true}
                              domLayout={'autoHeight'}
                              noRowsOverlayComponent={NoDataTemplate}
                            ></AgGridReact>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dialog-footer">
              <button id="close" type="button" className="btn btn-secondary-outline large">
                닫기
              </button>
            </div>
          </div>
        </form>
      </Fragment>
    </>
  )
}

export default MakerSetting
