import { Modal, Button } from 'antd'
import styles from './CreationEditingNotifyModal.module.scss'

type CreationEditingNotifyModalFunction = {
  isOpen: boolean
  onClose: () => void
}
export const CreationEditingNotifyModal: React.FC<CreationEditingNotifyModalFunction> = ({ isOpen, onClose }) => {
  return (
    <Modal
      width={760}
      open={isOpen}
      closable={false}
      title={<div className={styles.modalTitle}>{'수정 중인 소재가 있습니다.'}</div>}
      footer={[
        <div className={styles.modalFooter} key={'git-type-select-footer'}>
          <Button type="default" block size="large" onClick={onClose} style={{ width: 200 }}>
            {'확인'}
          </Button>
        </div>,
      ]}
      className={styles.containCreationEditingNotifyModal}
    >
      <ul className={styles.customBody}>
        <li>이전 수정 후 ‘변경요청‘ 혹은 ‘검수요청‘을 진행하지 않은 소재가 있습니다.</li>
        <li>
          ‘변경요청’ 및 ‘검수요청’을 진행하지 않은 경우 변경하시거나 등록하신 소재 정보가 정상적으로 반영되지 않습니다.
        </li>
        <li>수정된 소재 정보를 반영하시길 희망하신다면 ‘변경요청‘ 혹은 ‘검수요청‘을 진행해 주시기 바랍니다.</li>
        <li className={styles.strong}>
          * 버튼을 누르지 않은 소재는 테두리의 색이 빨간색으로 표시되오니 확인하시고 '변경요청' 혹은 '검수요청' 버튼을
          눌러주세요.
        </li>
      </ul>
    </Modal>
  )
}
