import { axios } from '../axios'
import type { CreativeDataType } from './fetchCreativesManageData'

type ReturnType = {
  ok: boolean
  data: string
}
export type CreativeAssetUpdateParam = CreativeDataType
type FunctionType = (data: Partial<CreativeDataType>) => Promise<ReturnType>
export const fetchPostCreativesDataUpdate: FunctionType = (data) =>
  axios.post('/api/camp/make02/fbCreationDataUpdate', data)
