import { useEffect, useCallback, useState } from 'react'
import { Button, Spin, Form } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import alertify from 'alertifyjs'
import debounce from 'lodash.debounce'
import { ReactComponent as PlusIcon } from '@common/svg/plus.svg'
import { useUsableCostQuery, useCreativeDataQuery, useCampaignStatus, useCampaignInfoQuery } from '@biz/query'
import { targetFormRegistry, makeCreativeAssetField, AssetFieldToCreativeData } from '@biz/ui'
import { useContentOperation } from './_hooks/useContentOperation'
import { useAvailableCreativeUpdate } from './_queries/useAvailableCreativeUpdateQuery'
import { CreativeAssetsCollector } from './CreativeAssetsCollector'
import styles from './CreativeAssetsContainer.module.scss'

const insertComma = (value: number) => `${value || 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
const { CreativeAssetsName, CampaignTotalName } = targetFormRegistry
export const CreativeAssetsContainer: React.FC = () => {
  const {
    data: usableCost,
    update: setUsableCostChange,
    invalidateWithBizMoney: invalidateUsableCost,
  } = useUsableCostQuery()
  const { data: campaignInfo } = useCampaignInfoQuery()
  const { payType } = campaignInfo?.data || {}
  const { isFinished, inOperation } = useCampaignStatus()
  const { data, isLoading } = useCreativeDataQuery()
  const { insert, update, remove: deleteOp } = useContentOperation()
  const directFetch = useAvailableCreativeUpdate()
  const form = Form.useFormInstance()
  useEffect(() => {
    // 소재 데이터를 서버에서 받아올때, Form.list가 패널을 추가한 다음 데이터가 도착하면,
    // 그 사이에 메이커가 데이터를 입력할 수 있다.
    if (!!data) {
      form.setFieldsValue({ [CreativeAssetsName]: data.map(makeCreativeAssetField) })
      // if (!form.isFieldsTouched([CreativeAssetsName]) || !data.find((d) => d.creationViewStatus === 'temp')) {
      //   form.setFieldsValue({ [CreativeAssetsName]: data.map(makeCreativeAssetField) })
      // } else {
      //   const oldAssets = form.getFieldValue([CreativeAssetsName])
      //   form.setFieldsValue({
      //     [CreativeAssetsName]: data.map((d, n) =>
      //       makeCreativeAssetField({ ...d, ...(d.creationViewStatus === 'temp' ? oldAssets[n] : {}) })
      //     ),
      //   })
      // }
    }
  }, [data])
  const checkIncreaseBudget = useCallback(
    () =>
      new Promise<{ isCampBudgetChange?: boolean }>(async (resolve) => {
        if (!inOperation?.()) {
          resolve({})
          return
        }
        directFetch().then((res) => {
          const { additionalCharge, changeCampBudgetTotal1, cost } = res.data || {}
          if (!additionalCharge) {
            resolve({})
            return
          }
          alertify
            .confirm(
              `소재당 1일 최소 5천원의 예산이 필요합니다. 캠페인 총 예산을${insertComma(
                changeCampBudgetTotal1 || 0
              )}원으로 변경하고 소재를 추가 하시겠습니까?<br/><br/>1일 캠페인 예산 변경으로 ${
                payType === 'POST_PAYMENT' ? '후불머니' : '비즈머니'
              } 에서 ${insertComma(cost || 0)} 원 (VAT포함)이 차감됩니다.`,
              () => {
                resolve({ isCampBudgetChange: true })
                form.setFieldValue(CampaignTotalName, changeCampBudgetTotal1)
                if (payType === 'BIZ_MONEY' && !!usableCost) {
                  setUsableCostChange(`${usableCost - cost}`)
                  invalidateUsableCost()
                }
              }
            )
            .set({ labels: { cancel: '취소', ok: '확인' } })
            .setHeader('')
        })
      }),
    [campaignInfo, payType, form]
  )

  const checkAppendAsset = useCallback(
    (res: { ok: boolean; data: { msg: string; title: string } }) => {
      if (res?.ok !== false) {
        return
      }
      const { msg, title } = res.data
      alertify.error(msg || '추가하는 중 오류가 발생했습니다. 잠시후 시도해주세요.', { title })
      const oldAssets = form.getFieldValue([CreativeAssetsName])
      form.setFieldsValue({ [CreativeAssetsName]: oldAssets.slice(0, -1) })
    },
    [form]
  )
  const [inProcess, setProcess] = useState(false)
  return (
    <>
      <Form.List name={CreativeAssetsName}>
        {(fields, { add, remove }) => {
          const addAssets = async () => {
            setProcess(true)
            checkIncreaseBudget().then((res) => {
              add()
              insert({
                clickBtnDiv: 'LEARN_MORE_FB',
                sortOrder: fields.length + 1,
                ...res,
              })
                .then(checkAppendAsset)
                .finally(() => setProcess(false))
            })
          }
          const duplicationAssets = async (cloneData: any) => {
            checkIncreaseBudget().then((res) => {
              add(cloneData)
              insert({
                ...AssetFieldToCreativeData(cloneData),
                clickBtnDiv: 'LEARN_MORE_FB',
                sortOrder: fields.length + 1,
                ...res,
              }).then(checkAppendAsset)
            })
          }
          const removeAssets = (values: { id: number }, name: number) => {
            if (fields.length <= 1) {
              toast.error('최소 1개의 소재가 있어야 합니다.')
              return
            }
            alertify
              .confirm('해당 광고를 삭제하시겠습니까?', () => {
                deleteOp(values.id)
                remove(name)
              })
              .set({ labels: { cancel: '취소', ok: '삭제' } })
              .setHeader('')
          }

          const addDisabled = fields.length >= 10
          return (
            <div className={styles.containCreativeAssetsContainer}>
              <div className={styles.headOperator}>
                <h4>
                  <b>{`${fields.length}개`}</b>
                  {` /10개`}
                </h4>
                {!isFinished?.() && (
                  <Button
                    size="large"
                    icon={<PlusIcon className={addDisabled ? styles.svgDisabled : styles.svg} />}
                    onClick={addAssets}
                    disabled={addDisabled || inProcess}
                  >
                    {'소재추가'}
                  </Button>
                )}
              </div>
              <div className={styles.scrollFrame}>
                <div className={styles.listFrame}>
                  {isLoading && (
                    <div className={styles.spinContainer}>
                      <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
                    </div>
                  )}
                  {!isLoading &&
                    fields.map(({ key, name }, index) => (
                      <CreativeAssetsCollector
                        name={name}
                        key={key}
                        index={index}
                        length={fields.length}
                        onRemove={
                          index === 0 && fields.length <= 1
                            ? undefined
                            : () => removeAssets(form.getFieldValue([CreativeAssetsName, name]), name)
                        }
                        onDuplicate={
                          addDisabled
                            ? undefined
                            : () => duplicationAssets(form.getFieldValue([CreativeAssetsName, name]))
                        }
                        onContentUpdate={() =>
                          update(AssetFieldToCreativeData(form.getFieldValue([CreativeAssetsName, name])), (v) => {
                            //TODO: reset 영역이다. 만약 서버에 반영되지 않았다면 여기에 toast 팝업을 띄우고 다음과 같이 리셋한다.
                            // form.setFieldValue([CreativeAssetsName, name], v)
                          })
                        }
                      />
                    ))}
                </div>
              </div>
            </div>
          )
        }}
      </Form.List>
    </>
  )
}
