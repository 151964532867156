import React, {
    Fragment, useEffect, useRef, useState,
} from 'react';
import {useForm} from 'react-hook-form';
import alertify from 'alertifyjs'
import { ApiUtil3 } from '@biz/api'
import PLProjectSearchDialog from "./PLProjectSearchDialog";

interface IPLRegReq {
    project2Id: string;
    projectDiv: string;
    pomLimitValue: string;
    comment: string;
}
const PomLimitRegDialog = (props: any) => {
    const {watch} = useForm<IPLRegReq>();
    const [isProjectSearchDialogOpened, setIsProjectSearchDialogOpened] = useState(false);
    const setProject2Id = useRef('');
    const setProjectDiv = useRef('');
    const {
        project2Id, projectDiv, pomLimitValue, comment
    } = watch();

    const formData = {
        project2Id: project2Id, projectDiv, pomLimitValue, comment
    };
    useEffect(() => {
        $('#projectSearchDialogBtn').on('click', () => {
           setIsProjectSearchDialogOpened(true);
        });
        /** 다이얼로그 */
        $('#PLRegDialog').dialog({
            title: '한도액 등록', closeOnEscape: true, width: 900,
        });

        $(document).on('click', '#cancelBtn', () => {
            $('#PLRegDialog').dialog('close');
            props.setIsAlRegDialogOpened(false);
            $('#PLRegDialog').dialog('destroy');
        });

        $('#pomLimitValue').on('keyup', () => {
            let value = $('#pomLimitValue').val() as string;
            const regex = /^\d+$/;
            if (!regex.test(value)) {
                $('#pomLimitValue').val(value.replace(/[^\d]/g, ''));
            }
            if (value.length > 6) {
                $('#pomLimitValue').val(value.substring(0, 6));
            }
        });

        $('#comment').on('keyup', () => {
            let value = $('#comment').val() as string;
            const maxLengthInBytes = 500;
            const encoder = new TextEncoder();
            const encodedValue = encoder.encode(value);
            const length = encodedValue.length;

            if (length > maxLengthInBytes) {
                const slicedValue = encodedValue.slice(0, maxLengthInBytes);
                const decoder = new TextDecoder();
                value = decoder.decode(slicedValue);
                $('#comment').val(value);
            }
            $('#byteCount').text(length);
        })

        $('#reg').on('click', () => {
            formData.pomLimitValue = $('#pomLimitValue').val() as string;
            formData.comment = $('#comment').val() as string;
            formData.projectDiv = $('#projectDiv').val() as string;
            formData.project2Id = $('#project2Id').val() as string;

            if (formData.project2Id === '') {
                alertify.error('프로젝트ID를 선택해주세요.');
                return;
            }

            if (formData.pomLimitValue === '') {
                alertify.error('한도액을 입력해주세요.');
                return;
            }
            alertify.confirm('한도액을 ' + $('#pomLimitValue').val() + '만원으로 조정합니다.', () => {
                ApiUtil3.post<IPLRegReq>('/api/bzmAdmin/postpaidMoneyManagement/PL/save', formData).then(() => {
                    $('#PLRegDialog').dialog('close');
                    props.setIsAlRegDialogOpened(false);
                    props.onGridReady();
                });
            }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
        });

        return () => {
            $('#PLRegDialog').dialog('destroy');
        };
    }, []);

    return (
        <Fragment>
            {isProjectSearchDialogOpened ? <PLProjectSearchDialog setIsProjectSearchDialogOpened={setIsProjectSearchDialogOpened} setProject2Id={setProject2Id} setProjectDiv={setProjectDiv}/> : null}
            <form>
                <div id='PLRegDialog' className="dialog" style={{display: 'none'}}>
                    <div className="dialog-body">
                        <div className="wrap-section wrap-tbl">
                            <div className="box-body">
                                <div className="tbl">
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    프로젝트 ID
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="inner-input-group">
                                                        <input type="input" className="tf-comm" value={setProject2Id.current}
                                                               id="project2Id" disabled={true}/>
                                                    </div>
                                                </div>
                                                <button type="button" className="btn btn-primary medium" id="projectSearchDialogBtn">ID검색</button>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    유형
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="inner-input-group">
                                                        <input type="text" className="tf-comm" value={setProjectDiv.current}
                                                               id="projectDiv" disabled={true}/>
                                                    </div>
                                                </div>
                                            </div>

                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    한도액
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="inner-input-group">
                                                        <input type="number" className="tf-comm"
                                                               placeholder="숫자만 입력해 주세요."
                                                               id="pomLimitValue"/> 만원
                                                    </div>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    허용사유
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group small">
                                                    <div className="inner-input-group">
                                                        <textarea className="tf-textarea" id="comment" maxLength={500}
                                                                  style={{width : 500, minWidth: 500}}
                                                                  />
                                                    </div>
                                                    <p className="fz-12 fc-3"><span className="fc-2" id='byteCount'>0</span> / 500</p>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dialog-footer">
                        <button type="button" className="btn btn-secondary-outline" id={'cancelBtn'}>취소
                        </button>
                        <button type="button" className="btn btn-primary" id="reg">확인</button>
                    </div>
                </div>
            </form>
        </Fragment>
    );
};
export default React.memo(PomLimitRegDialog);
