import { ChangeEvent, useCallback, useRef, useState, useEffect } from 'react'
import alertify from 'alertifyjs'
import { ApiUtil, ApiUtil3, uploadUrl } from '@biz/api'

export interface IFileTypes {
  id: number
  object: File
}

let uploadId: number
let fileIdForPresign: string
let presignUrl: string
let tempPrevFile: any[] = []
let tempFiles: any[] = []
export const bytesToSize = (bytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return 'n/a'
  const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))), 10)
  if (i === 0) return `${bytes} ${sizes[i]}`
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
}
const MultiFileUploaderForConsulting = (props: any) => {
  /** 파일의 유효성을 검사해준다. */
  /** 이미지 혹은 비디오의 해상도를 측정하기 위해선 웹상에 띄워 측정 후 다시 비워주는 방식으로 해야 한다. */
  const fileValidationChecker = (file: any, callback: any) => {
    let width: number
    let height: number
    // let duration: number; /** 아직 영상 길이 밸리 요청 없어서 주석*/
    let fileDiv = ''
    let video: any | undefined = document.getElementById('video')
    if (file.size >= 314572800) {
      /** 인자로 받은 파일 메타데이터를 읽어 사이즈가 300 메가 바이트를 넘을시 에러 메시지, 파일 정보를 읽어들이는데 시간이 오래 소요되기에 로딩 화면을 비추는데 해당 화면을 다시 없앤다. */
      alertify.error('파일의 사이즈는 최대 300MB 까지 가능합니다.')
      const commonLoader = document.querySelector('.loader.file')
      if (commonLoader !== null) commonLoader.classList.add('none')
      callback(false)
    }
    /** 파일 정보를 읽어 파일 타입에 따라 아래 두 가지로 나눈다, JS 함수의 순서와 비동기 통신 (AXIOS)의 순서가 보장되지 않아 콜백 함수에 플래그를 활용한다. */
    const reader = new FileReader()
    reader.readAsDataURL(file)
    if (file.type.startsWith('image')) {
      const image = new Image()
      const URL = window.URL || window.webkitURL
      const objectUrl = URL.createObjectURL(file)
      image.onload = function () {
        width = 0
        height = 0
        URL.revokeObjectURL(objectUrl)
        width = image.width
        height = image.height
        image.src = ''
        fileDiv = 'IMG'
        callback(true, fileDiv, width, height, file.size)
      }
      image.src = objectUrl
    } else if (file.type.startsWith('video')) {
      reader.onload = function (e: any) {
        if (video != null) {
          video.remove()
        }
        /** 비디오는 단순 src 값을 바꾼다 해서 웹에 노출되지 않기에 video 엘리먼트를 만들어 사이즈 측정 후 해당 엘리먼트를 삭제 해준다. */
        const v = document.createElement('video')
        v.setAttribute('id', 'video')
        v.setAttribute('src', e.target.result)
        video = v
        document.getElementById('videoDiv')?.append(v)
        // eslint-disable-next-line consistent-return
        video.onloadeddata = function () {
          width = video.clientWidth
          height = video.clientHeight
          // const time = video.duration;
          // duration = Math.floor(time);
          video.src = ''
          fileDiv = 'VOD'
          document.getElementById('video')?.remove()
          callback(true, fileDiv, width, height, file.size)
        }
      }
    } else {
      fileDiv = file.type
      callback(true, fileDiv, null, null, file.size)
    }
  }

  /** 드래그 앤 드롭 기능 미개발, SB 상에 별도로 요구사항 없어서 패스 */
  // const [isDragging, setIsDragging] = useState<boolean>(false);
  const [files, setFiles] = useState<IFileTypes[]>([])
  const dragRef = useRef<any>(null)
  const sessionInfo = sessionStorage.getItem('make01ReqInfo')
  /** 토스 결제 모듈 호출로 인해 새로고침이 되었을 때, 기존 정보를 불러온 후 세팅해준다. */
  useEffect(() => {
    if (sessionInfo !== null) {
      const data = JSON.parse(sessionInfo)
      /** 클라이언트가 보는 첨부 되어진 파일 리스트를 보여준다. 이 변수를 통해 파일리스트를 맵으로 뿌리고 있다. */
      setFiles(data.fileInfo)
      /** 매 새로고침마다 새로운 업로드 아이디를 채번하고 있기에, 기존 정보와 일치시키기 위해 세션에 있는 업로드 아이디로 맞춰준다. */
      uploadId = data.uploadId
      /** props로 보내는 이유는 최종 컨설팅 신청 시 서버로 보내는 인자의 값을 일치시키기 위함 이다. */
      props.setUploadId(data.uploadId)
      props.setFileInfo(data.fileInfo)
      /** tempFiles는 setFiles에 담길 데이터이다 */
      tempFiles = data.fileInfo
      /** tempFiles의 복제본으로 세션에 담길 데이터이다, 이렇게 하는 이유는 파일의 메타데이터가 세션에 담기지 않는 이슈가 있어 파일 메타데이터와 유사하게 템플릿을 구성 후 세션을 통해 구분하기 위함이다. */
      tempPrevFile = tempFiles
    } else {
      /** 위 세션을 불러오는 경우를 제외하고는 페이지 진입 시에 새로운 업로드 아이디를 채번 한다. */
      ApiUtil.get('/api/camp/make01/getUploadId').then((resp) => {
        uploadId = resp.data.data
        props.setUploadId(uploadId)
      })
    }
  }, [])

  const onChangeFiles = useCallback(
    (e: ChangeEvent<HTMLInputElement> | any): void => {
      const attaches = e.target.files

      if (attaches.length > 20) {
        alertify.error('최대 20개까지만 등록이 가능합니다.')
        return
      }

      /** 세션정보 있으면 기존 데이터 세팅 */
      if (sessionInfo == null) {
        tempFiles = files
      }

      /** 파일 정보를 읽어들이는 시간 동안 클라이언트가 액션을 취하면 데이터에 이슈가 생길 수 있어 로딩바를 보여준다. */
      const commonLoader = document.querySelector('.loader.file')

      /** 첨부된 파일을 세션을 위해 파일 메타데이터와 유사한 템플릿으로 세이브한다. */
      const setFileSession = (fileInfos: any[], totalCnt: number) => {
        tempPrevFile = []
        fileInfos.forEach((v: any) => {
          tempPrevFile.push({
            id: v.id,
            fileId: v.fileId,
            fileDivInfo: { fileDiv: v.fileDivInfo.fileDiv, width: v.fileDivInfo.width, height: v.fileDivInfo.height },
            fileMeta: { name: v.fileMeta.name, size: v.fileMeta.size },
          })
        })

        /** js가 비동기 통신에 대한 순서를 보장하지 않기에 맨 마지막에 로딩바를 제거하기 위한 조건식이다. */
        if (tempPrevFile.length === totalCnt) {
          if (commonLoader !== null) commonLoader.classList.add('none')
          props.setFileInfo(tempPrevFile)
        }
      }

      const setSelectedFiles = async (
        fileId: any,
        fileName: string,
        fileDiv: string,
        fileSize: number,
        width: number,
        height: number,
        totalCnt: number,
        attachesInfo: any,
        currentCnt: number,
        currentTotalCnt: number
      ) => {
        tempFiles = [
          ...tempFiles,
          {
            id: `${fileId}-${Math.floor(Math.random() * 100)}`,
            fileId,
            fileDivInfo: { fileDiv, width, height },
            fileMeta: attachesInfo,
          },
        ]

        /** 파일을 한 번에 여러개 혹은 한 번에 하나씩 첨부하는 경우에 대비해서 총 첨부된 개수를 비교 */
        /** currentTotalCnt는 총 첨부된 파일 개수, currentCnt는 새로 첨부될 파일 개수 */
        if (currentCnt === currentTotalCnt) {
          setFiles(tempFiles)
          setFileSession(tempFiles, totalCnt)
        }
      }

      const getPresignIds = async () => {
        if (commonLoader !== null) commonLoader.classList.remove('none')
        let currentLength = 0
        const fileVal = $('#multiFileUploader').val() as any
        if (fileVal!.length === 0) {
          if (commonLoader !== null) commonLoader.classList.add('none')
        }

        if (tempPrevFile.length + attaches.length > 20) {
          alertify.error('파일은 최대 20개 까지 첨부 가능 합니다.')
          if (commonLoader !== null) commonLoader.classList.add('none')
          return
        }

        for (let i = 0; i < attaches.length; i += 1) {
          /** 파일 유효성 검사, 파일 정보 읽어들이는 시간 소요에 따라 순서를 보장하지 않기에 콜백 함수를 통해 플래그를 통과하면 다음 스텝으로 넘어간다. */
          // eslint-disable-next-line no-loop-func
          fileValidationChecker(
            attaches[i],
            (check: boolean, fileDiv: string, width: number, height: number, fileSize: number) => {
              if (check) {
                // 파일 용량 초과
                ApiUtil3.post('/api/file/uploadReq', {
                  uploadId,
                  originFileName: attaches[i].name,
                  myS3Upload: 'consulting',
                }).then((resp) => {
                  // 1
                  if (resp.status === 200) {
                    presignUrl = resp.data.data.presignUrl
                    fileIdForPresign = resp.data.data.fileId
                    uploadUrl(presignUrl, attaches[i])
                    currentLength += 1
                    setSelectedFiles(
                      fileIdForPresign,
                      attaches[i].name,
                      fileDiv,
                      fileSize,
                      width,
                      height,
                      tempPrevFile.length + attaches.length,
                      attaches[i],
                      currentLength,
                      attaches.length
                    )
                  }
                })
              }
            }
          )
        }
      }

      getPresignIds()
    },
    [files]
  )

  const handleFilterFile = (e: any): void => {
    $('#multiFileUploader').val('')
    setFiles(files.filter((file: any) => `${file.fileId}` !== `${e.target.dataset.id}`))
    /** 세션 데이터 세팅 */
    tempPrevFile = tempPrevFile.filter((v: any) => `${v.fileId}` !== `${e.target.dataset.id}`)
    tempFiles = tempFiles.filter((v: any) => `${v.fileId}` !== `${e.target.dataset.id}`)
    props.setFileInfo(tempPrevFile)
  }

  /** 드래그 앤 드롭 기능 미개발, SB 상에 별도로 요구사항 없어서 패스 */
  // const handleDragIn = useCallback((e: DragEvent): void => {
  //   e.preventDefault();
  //   e.stopPropagation();
  // }, []);
  //
  // const handleDragOut = useCallback((e: DragEvent): void => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //
  //   setIsDragging(false);
  // }, []);
  //
  // const handleDragOver = useCallback((e: DragEvent): void => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //
  //   if (e.dataTransfer!.files) {
  //     setIsDragging(true);
  //   }
  // }, []);
  //
  // const handleDrop = useCallback(
  //   (e: DragEvent): void => {
  //     e.preventDefault();
  //     e.stopPropagation();
  //
  //     onChangeFiles(e);
  //     setIsDragging(false);
  //   },
  //   [onChangeFiles],
  // );
  //
  // const initDragEvents = useCallback((): void => {
  //   if (dragRef.current !== null) {
  //     dragRef.current.addEventListener('dragenter', handleDragIn);
  //     dragRef.current.addEventListener('dragleave', handleDragOut);
  //     dragRef.current.addEventListener('dragover', handleDragOver);
  //     dragRef.current.addEventListener('drop', handleDrop);
  //   }
  // }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);
  //
  // const resetDragEvents = useCallback((): void => {
  //   if (dragRef.current !== null) {
  //     dragRef.current.removeEventListener('dragenter', handleDragIn);
  //     dragRef.current.removeEventListener('dragleave', handleDragOut);
  //     dragRef.current.removeEventListener('dragover', handleDragOver);
  //     dragRef.current.removeEventListener('drop', handleDrop);
  //   }
  // }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);
  //
  // useEffect(() => {
  //   initDragEvents();
  //   if (files.length > 0) {
  //     $('.comp-file-list').css('display', 'block');
  //   }
  //   return () => resetDragEvents();
  // }, [initDragEvents, resetDragEvents]);

  useEffect(() => {
    if (files.length > 0) {
      $('.comp-file-list').css('display', 'block')
    }
  }, [files])

  return (
    <div className="DragDrop">
      <div className="form-group">
        <div className="comp-file-upload multiple">
          <div className="box-group multipleFile1">
            <input type="file" id="multiFileUploader" multiple={true} onChange={onChangeFiles} ref={dragRef} />
            <i className="ico ico-file"></i>
            <input
              type="text"
              id="multiFileUploaderText"
              className="tf-comm"
              placeholder="클릭 & 파일 드래그"
              readOnly={true}
            ></input>
            <span className="fz-12 fc-4">300MB 이하 / 20개 이하</span>
          </div>
          {files.length > 0 ? (
            <div className="comp-file-list">
              <div className="file-list-top">
                <span className="fz-14 fc-2">
                  첨부 파일 총 <span className="fc-5">{files.length}</span>개
                </span>
              </div>
              <div className="file-list-bottom">
                {files.length > 0 &&
                  files.map((file: any) => {
                    const {
                      id,
                      fileId,
                      fileMeta: { name, size },
                    } = file
                    return (
                      <div className="file-item" key={fileId}>
                        <i className="ico ico-file-list"></i>
                        <span className="fz-12 fc-2">{name}</span>
                        <span className="fz-12 fc-3">({bytesToSize(Number(size))})</span>
                        <button onClick={handleFilterFile} type="button" id="fileListDelBtn">
                          <i className="ico ico-delete" data-id={fileId} data-nm={name} data-size={size}></i>
                        </button>
                      </div>
                    )
                  })}
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {/* <img id="img" alt="#" style={{ display: 'none' }}/>
        <div id='videoDiv'>*/}
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      {/*<video id="video" style={{ display: 'none' }}/>
        </div>*/}
    </div>
  )
}

export default MultiFileUploaderForConsulting
