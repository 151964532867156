import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import alertify from 'alertifyjs'
import { Dialog } from 'rc-easyui'
import { AgGridReact } from 'ag-grid-react'
import { ColDef, GridSizeChangedEvent, ICellRendererParams } from 'ag-grid-community'
import { ApiUtil2 } from '@biz/api'
import NoData from '../../views/common/NoData'
import Pagination from '../../views/common/Pagination'
import { FilterOptions } from './Creation'
import { fileDownHandler } from '../../views/bizmoneyAdmin/postpaidMoney/pomInquiry/PomInquiryDetail'
import DlDialog from '../../views/mngMakerAccount/DlDialog'

/**
 * 소재검수 검수이력 다운로드
 */
const CreationDataInspHisDlDialog = (props: any) => {
  const dialogRef = useRef<any>()
  const gridRef = useRef<AgGridReact>(null)
  const childRef = useRef<any>(null)
  const datepicker = useRef<any>()
  const [rowData, setRowData] = useState<any[]>([])
  const { getValues, register, handleSubmit, setValue } = useForm<FilterOptions>()
  const url = useLocation()
  const [isDlDialogOpened, setIsDlDialogOpened] = useState<boolean>(false)

  const inspHisDlBtnRenderer = (props: ICellRendererParams) => {
    const { fileName } = props.data
    const { filePath } = props.data
    const { inspDlReqTime } = props.data
    //10일지나면 다운로드 안되게 비활성화
    let today = dayjs()
    let next10Days = dayjs(inspDlReqTime).add(10, 'd')
    return (
      <button
        className="btn btn-primary xsmall"
        disabled={filePath === undefined || today > next10Days}
        onClick={() => {
          fileDownHandler(filePath, fileName)
        }}
      >
        다운로드
      </button>
    )
  }

  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'srchPerd',
      headerName: '조회기간',
      cellRenderer: '',
    },
    {
      field: 'condition',
      headerName: '조건',
      cellRenderer: '',
    },
    {
      field: 'inspDlReqTime',
      headerName: '요청일시',
      cellRenderer: '',
    },
    {
      field: 'loginId',
      headerName: '요청자',
      cellRenderer: '',
    },
    {
      field: 'btn',
      headerName: '파일',
      cellRenderer: inspHisDlBtnRenderer,
    },
  ])
  let startDate = useState<string>(dayjs().startOf('day').subtract(7, 'd').format('YYYYMMDD'))
  let endDate = useState<string>(dayjs().endOf('day').subtract(1, 'd').subtract(59, 'm').format('YYYYMMDD'))

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      resizable: false,
      suppressMovable: true,
      sortable: false,
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    }),
    []
  )

  const onGridSizeChanged = (params: GridSizeChangedEvent) => {
    params.api.sizeColumnsToFit()
  }

  const changePagination = () => {
    childRef.current!.onPaginationChanged()
  }

  const errorEvent = (msg: string) => {
    alertify.error(msg, 1)
  }

  const confirmError = (msg: string) => {
    errorEvent(msg)
  }

  const successEvent = (msg: string) => {
    alertify.success(msg, 1)
  }

  const confirmSuccess = (msg: string) => {
    successEvent(msg)
  }

  const registerDate = (start: any, end: any) => {
    setValue('startDate', start[0])
    setValue('endDate', end[0])
  }

  const onGridReady = () => {
    ApiUtil2.get<any>('/api/mngAd/creationInspHisDl/list').then((resp) => {
      let result = resp.data.data
      setRowData(result)
    })
  }

  const onRegClick = () => {
    ApiUtil2.post<FilterOptions>('/api/mngAd/creationInspHisDl/insert', {
      startDate: getValues('startDate'),
      endDate: getValues('endDate'),
      srchType: getValues('srchType'),
      keyword: getValues('keyword'),
    }).then((resp) => {
      onGridReady()
    })
  }

  useEffect(() => {
    $('#datepickerDialog').daterangepicker(
      {
        opens: 'left',
        ranges: {
          오늘: [dayjs().startOf('day'), dayjs().endOf('day').subtract(59, 'm')],
          어제: [
            dayjs().startOf('day').subtract(1, 'days'),
            dayjs().endOf('day').subtract(59, 'm').subtract(1, 'days'),
          ],
          '이번 주': [dayjs().startOf('day').day(0), dayjs().day(1).endOf('day').subtract(-7, 'd').subtract(59, 'm')],
          '저번 주': [
            dayjs().startOf('day').subtract(1, 'week').day(0),
            dayjs().endOf('day').subtract(1, 'week').day(6).subtract(59, 'm'),
          ],
          '최근 7일': [
            dayjs().startOf('day').subtract(7, 'd'),
            dayjs().endOf('day').subtract(1, 'd').subtract(59, 'm'),
          ],
          '최근 30일': [
            dayjs().startOf('day').subtract(30, 'd'),
            dayjs().endOf('day').subtract(1, 'd').subtract(59, 'm'),
          ],
        },
        maxDate: dayjs(),
        // minDate: dayjs().startOf('month').subtract(1, 'month'),
        startDate: dayjs().startOf('day').subtract(7, 'd'),
        endDate: dayjs().endOf('day').subtract(1, 'd').subtract(59, 'm'),
      },
      (start: any, end: any) => {
        $('#datepickerDialogSpan').text($('#datepickerDialog').data('daterangepicker').chosenLabel)
        setValue('startDate', start.format('YYYYMMDD'))
        setValue('endDate', end.format('YYYYMMDD'))
      }
    )
    return () => {
      // 달력관련 모든걸 remove 해줘야 한다 한페이지에서 동작하는거라 이거 안해주면 달력 관련 소스가 계속 늘어남
      const daterangepickerCheck = document.querySelector<HTMLElement>('.daterangepicker')! as any
      if (daterangepickerCheck !== null) {
        daterangepickerCheck.remove()
      }
    }
  }, [])
  useEffect(() => {
    $('#srchTypeForDialog')
      .select2({
        width: '150',
        minimumResultsForSearch: Infinity,
        placeholder: '선택하세요',
      })
      .on('select2:select', (e) => {
        setValue('srchType', e.params.data.id)
      })
  }, [])

  useEffect(() => {
    registerDate(startDate, endDate)
    onGridReady()

    $(document).on('click', '#insertBtn', () => {
      ApiUtil2.post<FilterOptions>('/api/mngAd/creationInspHisDl/insert', {
        startDate: getValues('startDate'),
        endDate: getValues('endDate'),
        srchType: getValues('srchType'),
        keyword: getValues('keyword'),
      }).then((resp) => {
        onGridReady()
      })
    })

    $(document).on('click', '#cancelBtn', () => {
      props.setIsInspDlDialogOpened(false)
      $('#creationDataInspHisDlDialog').dialog('close')

      return () => {
        const dialog = $('#creationDataInspHisDlDialog')

        // dialog 인스턴스가 존재할 때만 destroy
        if (dialog.data('ui-dialog')) {
          dialog.dialog('destroy')
        }
      }
    })
  }, [])

  return (
    <Fragment>
      {isDlDialogOpened ? (
        <DlDialog
          url={`/api/mngAd/creationInspHisDl/download`}
          setIsDlDialogOpened={setIsDlDialogOpened}
          method={'post'}
          startDate={startDate[0]}
          endDate={endDate[0]}
          keyword={getValues('keyword')}
          srchType={getValues('srchType')}
        />
      ) : null}
      <form>
        <Dialog
          id="creationDataInspHisDlDialog"
          modal
          resizable
          shadow={false}
          ref={dialogRef}
          style={{ width: '1200px' }}
          title="검수이력 다운로드"
        >
          <div className="dialog">
            <div className="dialog-body">
              <section className="wrap-section wrap-datagrid">
                <div className="wrap-filter">
                  <div className="inner-filter">
                    <div className="box-left">
                      <div className="item-filter">
                        <div className="filter-tit">
                          <p className="fz-12 fc-2">조회기간</p>
                        </div>
                        <div className="box-filter">
                          <div className="comp-datepicker">
                            <div className="inner-datepicker">
                              <i className="ico ico-calendar"></i>
                              <input
                                type="text"
                                className="tf-comm datepicker-range right"
                                id="datepickerDialog"
                                readOnly
                                ref={datepicker}
                              />
                              <span className="fz-12 fc-2" id="datepickerDialogSpan">
                                최근 7일
                              </span>
                            </div>
                            <button type="button" className="btn btn-prev"></button>
                            <button type="button" className="btn btn-next"></button>
                          </div>
                        </div>
                      </div>
                      <div className="item-filter">
                        <div className="filter-tit">
                          <p className="fz-12 fc-2">조건</p>
                        </div>
                        <div className="box-filter">
                          <select id="srchTypeForDialog" className="select2-single" {...register('srchType')}>
                            <option value="makerNm">메이커명</option>
                            <option value="projectId">프로젝트ID</option>
                            <option value="campId">캠페인ID</option>
                            <option value="campNm">캠페인명</option>
                          </select>
                        </div>
                        <div className="box-filter">
                          <div className="input-group">
                            <div className="inner-input-group">
                              <input
                                id="keyword"
                                type="text"
                                className="tf-comm w-300"
                                placeholder="조건키워드를 입력해 주세요."
                                {...register('keyword')}
                              ></input>
                            </div>
                            <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="box-right">
                      <button
                        type="button"
                        className="btn btn-tertiary-mint btn-ico"
                        onClick={() => {
                          setIsDlDialogOpened(true)
                        }}
                      >
                        요청
                      </button>
                    </div>
                  </div>
                </div>
                <div className="box-header">
                  <div className="box-tit">
                    <h2 className="fz-20 fc-1 fw-bold">요청내역</h2>
                    <h3 className="fz-12 fc-3">
                      <i className="fz-12 fc-5">*</i>요청일을 기준으로 10일이 지난 경우 파일이 삭제 됩니다.
                    </h3>
                  </div>
                </div>
                <div className="box-body h-500 scroll-y">
                  <div className="ag-grid">
                    <div className="ag-grid-inner">
                      <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowSelection={'multiple'}
                        onGridReady={onGridReady}
                        onGridSizeChanged={onGridSizeChanged}
                        rowHeight={48}
                        // getRowHeight={getRowHeight}
                        pagination={true}
                        paginationPageSize={10}
                        suppressPaginationPanel={true}
                        suppressExcelExport={true}
                        suppressScrollOnNewData={true}
                        onPaginationChanged={changePagination}
                        noRowsOverlayComponent={NoData}
                        alwaysShowHorizontalScroll={true}
                        suppressRowClickSelection={true}
                        suppressContextMenu={true}
                        enableCellTextSelection={true}
                      ></AgGridReact>
                    </div>
                  </div>
                </div>
                <div className="box-footer">
                  <Pagination gridRef={gridRef} ref={childRef} />
                </div>
              </section>
            </div>
            <div className="dialog-footer">
              <div className="box-right">
                <button type="button" className="btn btn-primary" id={'cancelBtn'}>
                  확인
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </form>
    </Fragment>
  )
}

export default CreationDataInspHisDlDialog
