import { Fragment, useEffect, useState } from 'react'
import { DataFormat } from 'select2'
import { ApiUtil2 } from '@biz/api'

interface IPlusTarget extends DataFormat {
  freeCost: undefined
  paidCost: undefined
}

const AccAdjustRegTransfer = (props: any) => {
  const [plusTarget, setPlusTarget] = useState<IPlusTarget>({
    id: '',
    text: '',
    freeCost: undefined,
    paidCost: undefined,
  })

  const selectPlusUserNo = (value: any) => {
    setPlusTarget({ ...plusTarget, ...value })
    props.setValue('plusProjectId', value?.id)
  }

  const comma = (str: any) => {
    if (str === undefined) return ''
    const str2 = String(str)
    return str2.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
  }

  const uncomma = (str: any) => {
    if (str === undefined) return ''
    const str2 = String(str)
    return str2.replace(/[^\d]+/g, '')
  }

  useEffect(() => {
    // initialize
    $('#plusUserNos')
      .select2({
        width: '300',
        minimumResultsForSearch: Infinity,
        placeholder: '감산 대상을 선택해 주세요.',
        disabled: true,
      })
      .on('select2:select', (e) => {
        selectPlusUserNo(e.params.data)
      })
    return () => {
      props.setValue('plusProjectId', undefined)
    }
  }, [])

  const disablePlusUserNo = () => {
    $('#plusUserNos')
      .empty()
      .select2({
        disabled: true,
        minimumResultsForSearch: Infinity,
        placeholder: '선택 가능한 충전 사업자 번호가 없습니다.',
      })
      .trigger('change')

    const emptyData = Object.fromEntries(
      Object.entries(plusTarget).map(([k]) => [k, k === 'id' || k === 'text' ? '' : undefined])
    )
    selectPlusUserNo(emptyData)
  }

  const loadUserNos = () => {
    ApiUtil2.get('/api/accAdjust/reg/userNoList', {
      params: {
        makerId: props.adjustTarget.makerId,
      },
    }).then((resp) => {
      const plusUserNos: DataFormat[] = resp.data.data?.all
        .map((data: any) => {
          if (data.userNo === props.adjustTarget.userNo) return null
          return {
            id: data.projectId,
            text: data.userNo,
            freeCost: data.free,
            paidCost: data.paid,
          }
        })
        .filter((data: any) => data !== null)

      if (plusUserNos.length < 1) {
        disablePlusUserNo()
      } else {
        $('#plusUserNos')
          .empty()
          .select2({ data: plusUserNos, disabled: false, minimumResultsForSearch: Infinity })
          .trigger('change')
        selectPlusUserNo(plusUserNos[0])
      }
    })
  }

  useEffect(() => {
    if (props.adjustTarget !== undefined) {
      loadUserNos()
    }
  }, [props.adjustTarget])

  return (
    <Fragment>
      <dl className="vertical">
        <dd>
          <div className="form-group">
            {/* Wrap-Tbl : Start */}
            <section className="wrap-section wrap-tbl">
              <div className="box-header">
                <h2 className="fz-20 fc-1 fw-bold">감산 대상 정보</h2>
              </div>
              <div className="box-body">
                <div className="tbl">
                  <dl className="column-two">
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">메이커ID</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        {/* Input-Group : Start */}
                        <div className="input-group">
                          <div
                            className="inner-input-group"
                            onClick={props.openUserNoPopup}
                            onKeyUp={(e: any) => e.handleKeyUp}
                            role="presentation"
                          >
                            <input
                              type="text"
                              className="tf-comm"
                              placeholder="임의조정 대상을 선택해 주세요."
                              value={props.adjustTarget?.makerLoginId || ''}
                              disabled
                            />
                          </div>
                          <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                        </div>
                        {/* Input-Group : End */}
                      </div>
                      <div className="form-group">
                        <button type="button" className="btn btn-primary-outline" onClick={props.openUserNoPopup}>
                          임의조정 대상 조회_메이커
                        </button>
                      </div>
                    </dd>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">메이커명</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        {/* Input-Group : Start */}
                        <div className="input-group">
                          <div className="inner-input-group">
                            <input
                              type="text"
                              className="tf-comm"
                              value={props.adjustTarget?.makerName || ''}
                              disabled
                            />
                          </div>
                          <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                        </div>
                        {/* Input-Group : End */}
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">충전 사업자 번호</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        {/* Input-Group : Start */}
                        <div className="input-group">
                          <div className="inner-input-group">
                            <input type="text" className="tf-comm" value={props.adjustTarget?.userNo || ''} disabled />
                          </div>
                          <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                        </div>
                        {/* Input-Group : End */}
                      </div>
                    </dd>
                  </dl>
                  <dl className="column-two">
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">유상 비즈머니 잔액</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        {/* Input-Group : Start */}
                        <div className="input-group">
                          <div className="inner-input-group">
                            <input
                              type="text"
                              className="tf-comm tf-unit"
                              value={comma(uncomma(props.adjustTarget?.paidCost)) || ''}
                              disabled
                            />
                            <span className="fz-12 fc-1">원</span>
                          </div>
                          <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                        </div>
                        {/* Input-Group : End */}
                      </div>
                    </dd>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">쿠폰 잔액</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        {/* Input-Group : Start */}
                        <div className="input-group">
                          <div className="inner-input-group">
                            <input
                              type="text"
                              className="tf-comm tf-unit"
                              value={comma(uncomma(props.adjustTarget?.freeCost)) || ''}
                              disabled
                            />
                            <span className="fz-12 fc-1">원</span>
                          </div>
                          <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                        </div>
                        {/* Input-Group : End */}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </section>
            {/* Wrap-Tbl : End */}

            {/* Wrap-Tbl : Start */}
            <section className="wrap-section wrap-tbl">
              <div className="box-header">
                <h2 className="fz-20 fc-1 fw-bold">가산 대상 정보</h2>
              </div>
              <div className="box-body">
                <div className="tbl">
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">충전 사업자 번호</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        {/* Select2 : Start */}
                        <select className="select2-single w-300" id="plusUserNos"></select>
                        {/* Select2 : End */}
                      </div>
                    </dd>
                  </dl>
                  <dl className="column-two">
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">유상 비즈머니 잔액</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        {/* Input-Group : Start */}
                        <div className="input-group">
                          <div className="inner-input-group">
                            <input
                              type="text"
                              className="tf-comm tf-unit"
                              value={comma(uncomma(plusTarget?.paidCost)) || ''}
                              disabled
                            />
                            <span className="fz-12 fc-1">원</span>
                          </div>
                          <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                        </div>
                        {/* Input-Group : End */}
                      </div>
                    </dd>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">쿠폰 잔액</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        {/* Input-Group : Start */}
                        <div className="input-group">
                          <div className="inner-input-group">
                            <input
                              type="text"
                              className="tf-comm tf-unit"
                              value={comma(uncomma(plusTarget?.freeCost)) || ''}
                              disabled
                            />
                            <span className="fz-12 fc-1">원</span>
                          </div>
                          <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                        </div>
                        {/* Input-Group : End */}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </section>
            {/* Wrap-Tbl : End */}
          </div>
        </dd>
      </dl>
    </Fragment>
  )
}
export default AccAdjustRegTransfer
