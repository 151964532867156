import React, {Fragment, useRef} from 'react';
import {Dialog} from "rc-easyui";

function getInitialGuide() {
    return (
      <span className="fz-16 fc-2">
        📢후불머니 기능에 대해 알려드려요
        <br />
        <br />
        ● 사용 기한 : 지급일 ~ 펀딩 종료일 (프로젝트 기간 종료 후 사용 불가 / 스토어 사용 불가)
        <br />
        <br />
        💰요금제별 후불머니 기본 한도
        <br />
        <br />
        ● Basic, Pro 요금제 : 프로젝트 기준 최대 5백만 원
        <br />
        ● Expert 요금제 : 프로젝트 기준 최대 1천만 원
        <br />
        <br />
        해당 메뉴를 통해서는 한도 증액 신청 및 서류접수만 가능하며 일반 문의에 대해서는 biz-ad@wadiz.kr로 문의
        부탁드립니다.
      </span>
    )
}

function getSecondGuide() {
    return <span className="fz-16 fc-2">
                            📝후불머니 한도를 증액하기 위한 서류를 확인해 주세요.
                            <br/><br/>
                            ●   지급 보증보험 혹은 Nice 기업신용평가 등급 B 이상 서류 제출이 필요합니다.
                            <br/>
                            ●   지급 보증보험 : 기존 지급된 한도 금액을 제외하고 증액을 원하는 금액만큼 체결해 주세요.
                            <br/>
                            ●   기업신용평가 등급 : 개인 신용평가등급 혹은 타 서류로 대체가 불가합니다.
                            <br/><br/>
                            💰후불머니는 이렇게 활용할 수 있습니다.
                            <br/><br/>
                            ●   후불머니 한도액 안에서 캠페인 운영 시 사용 가능하며, 한 캠페인 안에서 후불머니와 유상비즈머니를 혼용하여 사용할 수 없습니다.
                            <br/>
                            ●   사용하신 후불머니는 메이커님의 프로젝트가 종료되고 +5일 이후 최종 정산 내역서로 확인이 가능합니다.
                            <br/>
                                자세한 이용 내역은 비즈센터 {'>'} 후불머니 이용내역으로 확인할 수 있습니다.
                            <br/>
                            ●   메이커 스튜디오 {'>'} 최종 정산시 정산금액에서 후불머니의 비용이 차감된 후 정산이 완료됩니다.
                        </span>;
}

const PomLimitIncreasementApplicationGuide = (props:any) => {
    const dialogRef = useRef<any>();
    $(document).on('click', '#cancelBtn', () => {
        props.setIsPomLimitIncreasementApplicationGuideDialogOpened(false);
    });
    $(document).on('click', '#regBtn', () => {
        props.setIsPomLimitIncreasementApplicationGuideDialogOpened(false);
        props.setIsPomInquiryRegDialogOpened(true);
    });

    return (
        <Fragment>
            <form>
                <Dialog title={'한도 증액 신청 작성 가이드'} modal resizable shadow={false} ref={dialogRef}
                        style={{width: '900px'}}>
                    <div className="dialog-body">
                        {props.isInitialGuide ? getInitialGuide() : getSecondGuide()}
                    </div>
                    <div className="dialog-footer">
                        {props.isInitialGuide ? <button type="button" className="btn btn-primary-outline"
                                                        id={'cancelBtn'}>취소</button> : null}
                        <button type="button" className="btn btn-primary" id={'regBtn'}>확인</button>
                    </div>
                </Dialog>
            </form>
        </Fragment>
    );
};

export default PomLimitIncreasementApplicationGuide;
