import { ReactComponent as StartIcon } from '@common/svg/star.svg'
import styles from './StarComment.module.scss'

type StarCommentFunction = {
  children?: React.ReactNode
}
export const StarComment: React.FC<StarCommentFunction> = ({ children }) => {
  return (
    <div className={styles.StarComment}>
      <StartIcon />
      {children}
    </div>
  )
}
