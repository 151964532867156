import { useQuery } from '@tanstack/react-query'
import { fetchCategoryAll } from '@biz/api'

const queryKey = ['@biz/query/category/useCategoryAll']

/**
 * 전체 카테고리 조회
 */
export const useCategoryAllQuery = () => {
  return useQuery({
    queryKey,
    queryFn: fetchCategoryAll,
    refetchOnMount: true,
    select: (response) => response.data,
  })
}
