import { useCallback, useState } from 'react'
import { Select, Form } from 'antd'
import { ProjectInfoType } from '@biz/api'
import { useCampaignStatus, useProjectListQuery, useCampaignMutation } from '@biz/query'
import { CampaignCaption, targetFormRegistry } from '@biz/ui'
import { ReactComponent as FundingIcon } from './Ico-Select-Funding-16x16.svg'
import { ReactComponent as StoreIcon } from './Ico-Select-Store-16x16.svg'
import { FundingTypeItem } from './FundingTypeItem'
import { ProjectBriefItem } from './ProjectBriefItem'
import { AdultProjectNoticeFormItem } from './AdultProjectNoticeFormItem'
import { checkDayLimit } from './checkDayLimit'
import styles from './ProjectSelector.module.scss'

const makeOption = (n: ProjectInfoType) => {
  const disabled = n.projectDiv === 'FUNDING' && !checkDayLimit(n, 'FUNDING_ING') && !checkDayLimit(n, 'FUNDING_OPEN')
  // if (disabled) {
  //   console.info(
  //     `${n.projectNm} - ${n.projectDiv}프로젝트, 날짜(${n.rcsPostTime}~${n.rcsEndTime}, 또는 ${n.adPossStartTime}~${n.adPossEndTime} )`
  //   )
  // }
  return {
    value: n.id,
    label:
      n.projectDiv === 'STORE' ? (
        <div>
          <StoreIcon /> <span className={styles.store}>{'스토어'}</span>
          <span>{n.projectNm}</span>
        </div>
      ) : (
        <div style={{ opacity: disabled ? 0.4 : 1 }}>
          <FundingIcon /> <span className={styles.funding}>{'펀딩'}</span>
          <span>{n.projectNm}</span>
        </div>
      ),
    disabled: disabled,
  }
}

const { ProjectSelectorName, FundingTypeItemName } = targetFormRegistry
export type ProjectSelectorType = { [ProjectSelectorName]: string }
export const ProjectSelector: React.FC<{ onUpdateCampaignName: () => void }> = ({ onUpdateCampaignName }) => {
  const { inOperation, isFinished } = useCampaignStatus()
  const form = Form.useFormInstance()

  const [selectedProjectId, setProjectId] = useState('')
  const makeCampaign = useCampaignMutation()
  const doChangeSelect = useCallback(
    () => makeCampaign.mutateAsync({ ...form.getFieldsValue(), fieldName: 'project' }).then(onUpdateCampaignName),
    [makeCampaign, form]
  )
  const { data: projectList, isLoading, refetch: projectListRefetch } = useProjectListQuery()
  const doChange = useCallback(
    (selectedProjectId: string) => {
      const project = (projectList || []).find(({ id }) => selectedProjectId === id)
      const isNotFundingProject = project && project.projectDiv !== 'FUNDING'
      if (isNotFundingProject) {
        form.setFieldValue(FundingTypeItemName, 'NON')
        doChangeSelect()
      } else {
        setProjectId(selectedProjectId) // FUNDING
      }
    },
    [projectList]
  )
  return (
    <div>
      <CampaignCaption>와디즈 프로젝트 선택</CampaignCaption>
      <dd style={{ paddingTop: 0 }}>
        <div className={styles.content}>
          <div className={styles.titleCaption}>
            <p>{'프로젝트를 선택하면 캠페인이 자동으로 저장되어, 캠페인 관리(광고 관리)에서 확인할 수 있어요'}</p>
            <p>{'캠페인이 생성되면 이후부터 작성하시는 내용들은 따로 저장을 하지 않아도 자동으로 저장돼요'}</p>
          </div>
          {!isLoading && (
            <Form.Item name={ProjectSelectorName} required>
              <Select
                options={(projectList || []).map(makeOption)}
                optionRender={({ label }) => <span className={styles.listItem}>{label}</span>}
                labelRender={({ label }) => <div className={styles.listItem}>{label}</div>}
                onChange={doChange}
                placeholder={'프로젝트를 선택해주세요.'}
                onDropdownVisibleChange={(open) => {
                  if (open && (!projectList || projectList.length < 1)) {
                    projectListRefetch()
                  }
                }}
                disabled={inOperation?.() || isFinished?.()}
              />
            </Form.Item>
          )}
        </div>
        <FundingTypeItem selectedProjectId={selectedProjectId} onSelectedAction={doChangeSelect} />
        <ProjectBriefItem />
        <AdultProjectNoticeFormItem />
      </dd>
    </div>
  )
}
