import { forwardRef } from 'react'
import { ReactComponent as LittleStar } from '@common/svg/littleStart.svg'

import styles from './FieldTitle.module.scss'

type FieldTitleFunction = {
  children: React.ReactNode
  icon?: React.ReactNode | undefined
  required?: boolean
  style?: React.CSSProperties
}

export const FieldTitle: React.FC<FieldTitleFunction> = forwardRef(
  ({ children, icon, style, required = false }, ref: React.Ref<HTMLDivElement>) => (
    <div className={styles.head} ref={ref} style={style}>
      <span>{children} </span>
      {required && <LittleStar className={styles.emphasis} />}
      {!!icon && icon}
    </div>
  )
)
