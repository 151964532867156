import React, { Fragment, useEffect } from 'react';
import { ApiUtil2 } from '@biz/api'
import alertify from 'alertifyjs'

const AdminRoleRegModal = (props:any) => {
  useEffect(() => {
    $('#adminRoleRegModal').dialog({
      width: 700, title: '신규 권한 등록', closeOnEscape: true,
    });
    $('#adminRoleNm').on('keyup', () => {
      const value = $('#adminRoleNm').val() as string;
      if (value.length > 15) {
        $('#adminRoleNm').val(value.substring(0, value.length - 1));
      }
    });

    /** 다이얼로그 닫기 */
    $(document).on('click', '#closeBtn', () => {
      $('#adminRoleRegModal').dialog('close');
      props.setIsModalOpened(false);
      $('#adminRoleRegModal').dialog('destroy');
    });

    /** 등록 */
    $('#regAdminRoleBtn').on('click', (e:any) => {
      const adminRoleNm = $('#adminRoleNm').val() as string;
      if (adminRoleNm.length < 2 || adminRoleNm.length > 15) {
        alertify.error('권한명은 2~15자까지 입력해 주세요.');
        return;
      }
      ApiUtil2.post('/api/mngAccount/admin/adminRoles/regAdminRoleNm', { adminRoleNm }).then(() => {
        props.adminRoleInfoReady();
        props.onGridReady();
        alertify.success('정상적으로 등록 되었습니다.');
        $('#adminRoleRegModal').dialog('close');
        props.setIsModalOpened(false);
        $('#adminRoleRegModal').dialog('destroy');
      });
      e.preventDefault();
    });

    return () => {
      $('#adminRoleRegModal').dialog('close');
      $('#adminRoleRegModal').dialog('destroy');
      props.setIsModalOpened(false);
    };
  }, []);
  return (
        <Fragment>
            <form>
                <div id="adminRoleRegModal" className="dialog" style={{ display: 'none' }}>
                    <div className="dialog-body">
                        <div className="wrap-section wrap-tbl">
                            <div className="box-body">
                                <div className="tbl">
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                            <span className="fz-16 fc-1">
                                                권한명
                                            </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="inner-input-group">
                                                        <input type="text" className="tf-comm" id='adminRoleNm'/>
                                                    </div>
                                                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                </div>
                                                <span className="comp-txt">
                                                <span className="table">
                                                    <span className="table-cell v-bottom">
                                                        <b className="fz-12 fc-1">.</b>
                                                    </span>
                                                </span>
                                            </span>
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dialog-footer">
                        <button type="button" className="btn btn-secondary-outline large" id={'closeBtn'}>취소
                        </button>
                        <button type="button" className="btn btn-primary large" id={'regAdminRoleBtn'}>등록</button>
                    </div>
                </div>
            </form>
        </Fragment>
  );
};

export default AdminRoleRegModal;
