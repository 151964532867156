import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { AgGridReact } from 'ag-grid-react'
import NoDataTemplate from '../common/NoDataTemplate'
import { ColDef, GridSizeChangedEvent, ICellRendererParams } from 'ag-grid-community'
import { ApiUtil2 } from '@biz/api'
import LogTooltip from './LogTooltip'
import alertify from 'alertifyjs'
import Pagination2 from '../common/Pagination2'
import { fileStatusEnum } from '../mngRpt/Finance'

const downloadRenderer = (props: ICellRendererParams) => {
  $('#downBtn').on('click', () => {
    window.location.assign(`/api/integration/log/isms/download?id=${props.data.id}`)
  })
  return (
    <span>
      <button id={'downBtn'} className="btn btn-primary xsmall" disabled={props.data.fileStatus !== 'SUCCEEDED'}>
        {fileStatusEnum[`${props.data.fileStatus}`]}
      </button>
    </span>
  )
}
const IsmsSelectLogDialog = (props: any) => {
  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth() + 1
  const year = useRef<any>(currentYear)
  const month = useRef<any>(currentMonth)
  const expireDate = useRef<any>()

  const gridRef = useRef<AgGridReact>(null)
  const childRef = useRef<any>(null)
  const changePagination = () => {
    childRef.current!.onPaginationChanged()
  }
  const onGridSizeChanged = (params: GridSizeChangedEvent) => {
    params.api.sizeColumnsToFit()
  }
  const [rowData, setRowData] = useState<any[]>()
  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'requestDate',
      headerName: '요청 년/월',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'requestTime',
      headerName: '요청일시',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'requester',
      headerName: '요청자',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      headerName: '다운로드',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: downloadRenderer,
    },
  ])

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      resizable: false,
      suppressMovable: true,
      sortable: true,
      tooltipComponent: LogTooltip,
    }),
    []
  )

  const onGridReady = useCallback(() => {
    ApiUtil2.get('/api/integration/log/isms').then((resp) => {
      setRowData(resp.data)
    })
  }, [])

  useEffect(() => {
    /** 다이얼로그 오픈 */
    $('#ismsDialog').dialog({ width: 900, title: '접속로그 확인', closeOnEscape: true })
    $('#ismsDialog').dialog('open')

    /** 다이얼로그 닫기 */
    $(document).on('click', '#confirmBtn', () => {
      $('#ismsDialog').dialog('close')
      props.setIsIsmsDialogOpened(false)
    })

    $('#pwd').on('keyup', () => {
      const value = $('#pwd').val() as string
      if (value.length > 15) {
        $('#pwd').val(value.substring(0, 15))
      }
    })

    $('#msg').on('keyup', () => {
      const value = $('#msg').val() as string
      if (value.length > 100) {
        $('#msg').val(value.substring(0, 100))
      }
    })

    for (let year = currentYear; year >= currentYear - 10; year--) {
      $('#year').append(new Option((year + '년') as any, year as any))
    }

    /** select2 */
    $('#year')
      .select2({
        width: '150',
        minimumResultsForSearch: Infinity,
      })
      .on('select2:select', (e) => {
        year.current = e.params.data.id
      })

    for (let month = 1; month <= 12; month++) {
      $('#month').append(new Option((month + '월') as any, month as any))
    }

    $('#month')
      .select2({
        width: '150',
        minimumResultsForSearch: Infinity,
      })
      .on('select2:select', (e) => {
        month.current = e.params.data.id
      })

    $('#applyBtn').on('click', () => {
      const data = {
        year: year.current,
        month: month.current,
        pwd: $('#pwd').val(),
        msg: $('#msg').val(),
        expireDate: expireDate.current,
      }
      ApiUtil2.post('/api/integration/log/isms', data).then((resp) => {
        alertify.success('정상적으로 요청되었습니다.', 1)
      })
    })

    return () => {
      props.setIsIsmsDialogOpened(false)
      $('#ismsDialog').dialog('destroy')
    }
  }, [])

  useEffect(() => {
    $('#datepickerSingle').daterangepicker(
      {
        singleDatePicker: true,
        opens: 'left',
        minDate: dayjs().startOf('day').add(7, 'd').format('YYYYMMDD'),
      },
      (date: any) => {
        let formattedDate = date.format('YYYYMMDD')
        expireDate.current = formattedDate
      }
    )
    /** default date config */
    expireDate.current = $('#datepickerSingle').data('daterangepicker').startDate.format('YYYYMMDD')
    return () => {
      const daterangepickerCheck = document.querySelector<HTMLElement>('.daterangepicker')! as any
      if (daterangepickerCheck !== null) {
        daterangepickerCheck.remove()
      }
    }
  }, [])

  useEffect(() => {
    onGridReady()
  }, [])

  return (
    <Fragment>
      <form id="dialog">
        <div id="ismsDialog" className="dialog" style={{ display: 'none' }}>
          <div className="dialog-body">
            <div className="wrap-section wrap-tbl">
              <div className="box-header">
                <div className="box-tit">
                  <h2 className="fz-20 fc-1 fw-bold">접속로그 요청 사항</h2>
                </div>
              </div>
              <div className="box-body">
                <div className="tbl">
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">요청 년/월</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <select id="year" className="select2-single"></select>
                        <select id="month" className="select2-single"></select>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">비밀번호</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <div className="input-group expand">
                          <div className="inner-input-group">
                            <input
                              id="pwd"
                              type="password"
                              className="tf-comm"
                              placeholder="비밀번호를 입력해 주세요."
                            />
                          </div>
                          <p className="fz-12 fc-4">영문, 숫자, 특수기호를 모두 조합하여 9~15자까지 입력해 주세요.</p>
                          <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                        </div>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">사유</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <div className="input-group small expand">
                          <div className="inner-input-group">
                            <textarea id="msg" className="tf-textarea"></textarea>
                          </div>
                        </div>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">파일 사용기한</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <div className="comp-datepicker">
                          <div className="inner-datepicker">
                            <i className="ico ico-calendar"></i>
                            <input
                              id="datepickerSingle"
                              type="text"
                              className="tf-comm datepicker-single"
                              readOnly={true}
                            />
                          </div>
                        </div>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
              <div className="box-footer">
                <div className="box-right">
                  <button type="button" className="btn btn-primary" id={'applyBtn'}>
                    요청
                  </button>
                </div>
              </div>

              {/* 접속로그 요청 내역 그리드 */}
              <div className={'wrap-section wrap-datagrid'}>
                <div className="box-header">
                  <div className="box-tit">
                    <h2 className="fz-20 fc-1 fw-bold">접속로그 요청 내역</h2>
                  </div>
                </div>
                <div className="box-body">
                  <div className="ag-grid">
                    <div className="ag-grid-inner">
                      <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowHeight={48}
                        onGridSizeChanged={onGridSizeChanged}
                        pagination={true}
                        paginationPageSize={10}
                        suppressPaginationPanel={true}
                        onPaginationChanged={changePagination}
                        domLayout={'autoHeight'}
                        noRowsOverlayComponent={NoDataTemplate}
                      />
                      {rowData === undefined ? <NoDataTemplate /> : null}
                    </div>
                  </div>
                </div>
                <div className="box-footer">
                  <Pagination2 gridRef={gridRef} ref={childRef} />
                </div>
              </div>
            </div>
          </div>
          <div className="dialog-footer">
            <button id="confirmBtn" type="button" className="btn btn-primary large">
              확인
            </button>
          </div>
        </div>
      </form>
    </Fragment>
  )
}

export default IsmsSelectLogDialog
