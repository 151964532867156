import React, {
  Fragment, useCallback, useMemo, useRef, useState,
} from 'react';
import { Dialog } from 'rc-easyui';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import { ApiUtil2 } from '@biz/api'

interface ICardAtrtDet{
    ok:boolean;
    data:any;
    refundDetStatus:string;
    pgNum:string;
    refundCost:number;
    updateTime:string;
    updateId:string;
    costDiv:string;
    txDiv:string;
}
const costRenderer = (props: ICellRendererParams) => props.value.toLocaleString();
const pgNumRenderer = (props: ICellRendererParams) => (props.value === undefined ? '-' : props.value);
const updateTimeRenderer = (props: ICellRendererParams) => (dayjs(props.value).format('YYYY-MM-DD HH:mm'));
const BzmMngAtamDetPopUp = (props:any) => {
  const dialogRef = useRef<any>();
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>([]);
  const [colDefs] = useState<ColDef[]>([
    {
      field: 'txDiv',
      headerName: '구분',
    },
    {
      field: 'refundDetStatus',
      headerName: '상태',
    },
    {
      field: 'pgNum',
      headerName: '거래번호',
      cellRenderer: pgNumRenderer,
    },
    {
      field: 'refundCost',
      headerName: '환불 금액',
      cellRenderer: costRenderer,
    },
    {
      field: 'updateTime',
      headerName: '진행 일시',
      cellRenderer: updateTimeRenderer,
    },
    {
      field: 'updateId',
      headerName: '진행자',
    },
  ]);

  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: false,
    headerClass: 'ag-center-aligned-header',
    cellClass: 'ag-center-aligned-cell',
  }), []);

  const onGridReady = useCallback(() => {
    ApiUtil2.get<ICardAtrtDet>('/api/bzmAdmin/mng/bzmMngAtamDetList', { params: { data: { accRefundId: props.accRefundId, costDiv: props.costDiv } } }).then((resp) => {
      setRowData(resp.data.data);
    });
  }, []);

  const closeEvent = () => {
    props.setDetAdminDialog(false);
  };
  return (
        <Fragment>
            <form>
                <Dialog title="관리자 이체 환불 상세" modal resizable shadow={false} ref={dialogRef} style={{ width: '1200px' }}>
                    <div className="dialog">
                        <div className="dialog-body">
                                <section className="wrap-section wrap-datagrid">
                                    <div className="box-body">
                                        <div className="ag-grid">
                                            <div className="ag-grid-inner">
                                                <AgGridReact
                                                    ref={gridRef}
                                                    rowData={rowData}
                                                    columnDefs={colDefs}
                                                    defaultColDef={defaultColDef}
                                                    rowHeight={48}
                                                    domLayout={'autoHeight'}
                                                    onGridReady={onGridReady}
                                                ></AgGridReact>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                {/* Wrap-Datagrid : End */}
                        </div>
                        <div className="dialog-footer">
                            <button type="button" className="btn btn-primary large" onClick={closeEvent}>확인</button>
                        </div>
                    </div>
                </Dialog>
            </form>
        </Fragment>
  );
};

export default BzmMngAtamDetPopUp;
