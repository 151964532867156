import { ReactComponent as CameraIcon } from '@common/svg/cameraOutline.svg'
import { MediaSpecTip } from './MediaSpecTip'
import styles from './EmptyPreview.module.scss'

type EmptyPreviewFunction = {
  children?: React.ReactNode
}
export const EmptyPreview: React.FC<EmptyPreviewFunction> = ({}) => (
  <div className={styles.EmptyPreview}>
    <CameraIcon style={{ width: 72 }} />
    <div className={styles.emptyNotice}>
      <div>{'이미지 혹은 영상을 선택해주세요.'}</div>
      <MediaSpecTip />
    </div>
  </div>
)
