import React, {
    Fragment, useEffect
} from 'react';
import {useForm} from 'react-hook-form';
import PLTargetGrid from "./PLTargetGrid";
import ALHistoryGrid from "./PLHistoryGrid";
import alertify from 'alertifyjs'
import { ApiUtil3 } from '@biz/api'
import PomLimitAdjustment from "./PomLimitAdjustment";
export interface IPLRegReq {
    pomLimitValue: string;
    comment: string;
    projectConfigId: number;
}

export interface IPLTargetInfo {
    data:any
    projectConfigHisId: number;
    projectDiv: string;
    project2Id: string;
    updateDate: string;
    modifier: string;
    pomLimitValue: number;
    comment: string;
}
const PomLimitManagementDialog = (props: any) => {
    const {watch} = useForm<IPLRegReq>();
    const {
        pomLimitValue, comment, projectConfigId
    } = watch();

    const formData = {
        pomLimitValue: pomLimitValue, comment, projectConfigId
    };

    useEffect(() => {
        /** 다이얼로그 */
        $('#PLMngDialog').dialog({
            title: '관리', closeOnEscape: true, width: 900,
        });

        $(document).on('click', '#cancelBtn', () => {
            $('#PLMngDialog').dialog('close');
            props.setIsManagementDialogOpened(false);
            $('#PLMngDialog').dialog('destroy');
        });

        $('#pomLimitValue').on('keyup', () => {
            let value = $('#pomLimitValue').val() as string;
            const regex = /^\d+$/;
            if (!regex.test(value)) {
                $('#pomLimitValue').val(value.replace(/[^\d]/g, ''));
            }
            if (value.length > 6) {
                $('#pomLimitValue').val(value.substring(0, 6));
            }
        });

        $('#comment').on('keyup', () => {
            let value = $('#comment').val() as string;
            const maxLengthInBytes = 500;
            const encoder = new TextEncoder();
            const encodedValue = encoder.encode(value);
            const length = encodedValue.length;

            if (length > maxLengthInBytes) {
                const slicedValue = encodedValue.slice(0, maxLengthInBytes);
                const decoder = new TextDecoder();
                value = decoder.decode(slicedValue);
                $('#comment').val(value);
            }
            $('#byteCount').text(length);
        })

        $('#reg').on('click', () => {
           formData.pomLimitValue = $('#pomLimitValue').val() as string;
           formData.comment = $('#comment_').val() as string;
           formData.projectConfigId = props.projectConfigId;

           if (formData.pomLimitValue === '') {
               alertify.error('한도액을 입력해주세요.');
               return;
           }
            alertify.confirm('한도액을 ' + $('#pomLimitValue').val() + '만원으로 조정합니다.', () => {
                ApiUtil3.post<IPLRegReq>('/api/bzmAdmin/postpaidMoneyManagement/PL/modify', formData).then(() => {
                    $('#PLMngDialog').dialog('close');
                    props.setIsManagementDialogOpened(false);
                    props.onGridReady();
                });
            }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
        });

        return () => {
            $('#PLMngDialog').dialog('destroy');
        };
    }, []);

    return (
        <Fragment>
            <form>
                <div id='PLMngDialog' className="dialog" style={{display: 'none'}}>
                    <div className="dialog-body">
                        <div className="wrap-section wrap-tbl">
                            <div className="box-header">
                                <div className="box-tit">
                                    <span className="fz-16 fc-1">
                                        대상 정보
                                    </span>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="ag-grid">
                                    <div className="ag-grid-inner">
                                        <PLTargetGrid projectConfigId={props.projectConfigId}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wrap-section wrap-tbl">
                            <div className="box-header">
                                <div className="box-tit">
                                    <span className="fz-16 fc-1">
                                        히스토리
                                    </span>
                                </div>
                            </div>
                            <div className="box-body" style={{maxHeight: 500, overflow: 'auto'}}>
                                <div className="ag-grid">
                                    <div className="ag-grid-inner">
                                        <ALHistoryGrid projectConfigId={props.projectConfigId}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <PomLimitAdjustment/>
                    </div>
                    <div className="dialog-footer">
                        <button type="button" className="btn btn-secondary-outline" id={'cancelBtn'}>취소
                        </button>
                        <button type="button" className="btn btn-primary" id="reg">확인</button>
                    </div>
                </div>
            </form>
        </Fragment>
    );
};
export default React.memo(PomLimitManagementDialog);
