import type { RouteObject } from 'react-router-dom'
import type { QueryClient } from '@tanstack/react-query'
import { AdminLoginPage } from './AdminLoginPage'

type FunctionType = (queryClient: QueryClient) => RouteObject
export const getAdminLoginRoute: FunctionType = (queryClient: QueryClient) => ({
  path: '/common/login',
  loader: () => {
    //TODO 만약 로그인 된 상황이라면 /main으로 가야 함.
    return null
  },
  element: <AdminLoginPage />,
})
