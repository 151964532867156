import { Modal, Button } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { ReactComponent as StartIcon } from '@common/svg/star.svg'
import styles from './InspectionBypassModal.module.scss'

type InspectionBypassModalFunction = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  category: string
  keywords: string[]
}
export const InspectionBypassModal: React.FC<InspectionBypassModalFunction> = ({
  isOpen,
  onClose,
  onConfirm,
  category,
  keywords,
}) => {
  return (
    <Modal
      width={600}
      open={isOpen}
      closable={false}
      title={
        <div className={styles.modalTitle}>
          <div className={styles.titleHead}>{'작성하신 소재에 수정이 필요한 문구가 있습니다.'}</div>
          <div className={styles.titleComment}>
            <StartIcon /> {`[${category}] 카테고리는 `}
            <strong>{'아래 기재된 단어 사용 시, 반려 사유'}</strong>
            {'가 될 수 있습니다.'}
          </div>
        </div>
      }
      footer={[
        <div className={styles.modalFooter} key={'footer'}>
          <Button type="default" block size="large" onClick={onClose} style={{ width: 200 }}>
            {'수정하기'}
          </Button>
          <Button type="primary" block size="large" onClick={onConfirm} style={{ width: 200 }}>
            {'무시하고 검수요청'}
          </Button>
        </div>,
      ]}
      className={styles.containInspectionBypassModal}
    >
      <div className={styles.customBody}>
        <ExclamationCircleOutlined />
        <div>{keywords.join(',')}</div>
      </div>
    </Modal>
  )
}
