import React, {
  Fragment, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import {ColDef, GridSizeChangedEvent, ICellRendererParams} from 'ag-grid-community';
import Pagination from '../../common/Pagination';
import NoDataTemplate from '../../common/NoDataTemplate';
import CustomTooltip from '../../example/CustomTooltip';
import { ApiUtil2 } from '@biz/api'
import DlDialog from "../../mngMakerAccount/DlDialog";




const RptTotalDown = (props:any) => {
  const gridRef = useRef<AgGridReact>(null);
  const childRef = useRef<any>(null);
  const [rowData, setRowData] = useState<any[]>();

  const [isDlDialogOpened, setIsDlDialogOpened] = useState<boolean>(false);
  const dlParamRef = useRef({});

  const { isMaker } = props;

  const fileStatusRenderer = (props: ICellRendererParams) => {
    if (props!.data.fileStatus === 'SUCCEEDED') return '완료';
    if (props!.data.fileStatus === 'FAILED') return '실패';
    return '요청';
  };

  const downloadRenderer = (props: ICellRendererParams) => {
    $(`#totalDown${props.data.id}`).on('click', () => {
      if (isMaker) {
        window.location.assign(`/api/rptCommon/totalRpt/down?id=${props.data.id}`);
      } else {
        dlParamRef.current = { id : props.data.id };
        setIsDlDialogOpened(true);
      }
    });
    return (
      <span>
          <button id={`totalDown${props.data.id}`} className="btn btn-primary xsmall" disabled={props.data.fileStatus !== 'SUCCEEDED'}>파일 다운로드</button>
        </span>
    );
  };

  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: '요청일시',
      field: 'reqTime',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      width: 25,
    },
    {
      headerName: '조회기간',
      field: 'searchDate',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      width: 25,
    },
    {
      headerName: '상태',
      field: 'fileStatus',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: fileStatusRenderer,
      width: 25,
    },
    {
      field: '다운로드',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: downloadRenderer,
      width: 25,
    },
  ]);
  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: true,
    tooltipComponent: CustomTooltip,
  }), []);

  const onGridReady = useCallback(() => {
    ApiUtil2.post('/api/rptCommon/totalRpt/list', {
    }).then((resp) => {
      $('#rptTotalDownDialog').dialog({ width: 900, title: '전체 내역 다운로드', closeOnEscape: true });
      setRowData(resp.data.data);
    });
  }, []);
  const changePagination = () => {
        childRef.current!.onPaginationChanged();
  };
  const onGridSizeChanged = (params: GridSizeChangedEvent) => {
    params.api.sizeColumnsToFit();
  };

  useEffect(() => {
    $(document).on('click', '#rptTotalDownDialogClose', () => {
      $('#rptTotalDownDialog').dialog('close');
      props.setIsRptDownloadOpened(false);
    });
    $('#rptResetBtn').on('click', () => {
      onGridReady();
    });
    $('#rptTotalReq').on('click', () => {
      const {
        startDate, endDate, isMaker,
      } = props;
      ApiUtil2.post('/api/rptCommon/totalRpt/insert', {
        startDate, endDate, isMaker,
      }).then(() => onGridReady());
    });
    document.addEventListener('keydown', (e) => {
      if (e.keyCode === 192) onGridReady();
    });
    return () => {
      props.setIsRptDownloadOpened(false);
      $('#rptTotalDownDialog').dialog('destroy');
    };
  }, []);
  return (<Fragment>
            <form>
                <div id="rptTotalDownDialog" className="dialog" style={{ display: 'none' }}>
                    <div className="dialog-body">
                        <section className="wrap-section wrap-datagrid">
                            <div className="box-header">
                                <div className="box-tit">
                                    <h3 className="fz-12 fc-3"><i className="fz-12 fc-5">*</i>요청 후 30일 후 해당 내역은 삭제 됩니다.</h3>
                                </div>
                                <div className="box-option">
                                    <button type="button" className="btn btn btn-secondary-outline btn-ico w-auto" id="rptResetBtn" >
                                        <i className="ico ico-refresh"></i>새로고침
                                    </button>
                                    <button type="button" className="btn btn-primary" id="rptTotalReq">리포트 요청</button>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="ag-grid">
                                    <div className="ag-grid-inner">
                                        <AgGridReact
                                            ref={gridRef}
                                            rowData={rowData}
                                            columnDefs={columnDefs}
                                            defaultColDef={defaultColDef}
                                            onGridReady={onGridReady}
                                            onGridSizeChanged={onGridSizeChanged}
                                            rowHeight={48}
                                            domLayout={'autoHeight'}
                                            pagination={true}
                                            paginationPageSize={10}
                                            suppressPaginationPanel={true}
                                            onPaginationChanged={changePagination}
                                            noRowsOverlayComponent={NoDataTemplate}
                                        ></AgGridReact>
                                    </div>
                                </div>
                            </div>
                            <div className="box-footer">
                                <Pagination gridRef={gridRef} ref={childRef}/>
                            </div>
                        </section>
                    </div>
                    <div className="dialog-footer">
                        <button type="button" className="btn btn-secondary-outline large" id="rptTotalDownDialogClose">확인</button>
                    </div>
                </div>
            </form>
            {
              isDlDialogOpened ?
                <DlDialog setIsDlDialogOpened={setIsDlDialogOpened}
                          dlUrl={'/api/rptCommon/totalRpt/down'}
                          dlParam={dlParamRef.current}
                /> : null
            }
        </Fragment>
  );
};

export default RptTotalDown;
