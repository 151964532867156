import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import AccAdjustRegTaxbill from './AccAdjustRegTaxbill';
import AccAdjustRegDet from './AccAdjustRegDet';
import AccAdjustRegTransfer from './AccAdjustRegTransfer';
import AccAdjustRegSingle from './AccAdjustRegSingle';
import AccAdjustDialogSearchUserNo from './dialog/AccAdjustDialogSearchUserNo';
import AccAdjustDialogTaxbill from './dialog/AccAdjustDialogTaxbill';
import alertify from 'alertifyjs'
import { ApiUtil3 } from '@biz/api'

interface IAdjustRegistForm {
  adjustDiv: string;
  adjustDiv2: string;
  projectId: string | undefined;
  plusProjectId: string | undefined;
  paidCost: string;
  paidExpireTime: string;
  freeCost: string;
  freeExpireTime: string;
  comment: string;
  taxbillYn: string;
}

const AccAdjustReg = () => {
  const navigate = useNavigate();
  // 임의조정 종류(단건/금액이동)
  const [adjustDiv, setAdjustDiv] = useState<string>('single');
  // dialog 상태값. 단순히 dialog를 생성/삭제하면 안됨. 이 상태값으로 해당 컴포넌트를 보여주고 숨기는 기능이 들어가야함
  const [userNoDialog, setUserNoDialog] = useState<boolean>(false);
  const [taxbillDialog, setTaxbillDialog] = useState<boolean>(false);
  // 임의조정 대상
  const [adjustTarget, setAdjustTarget] = useState<any>(undefined);
  // 임의조정 만료일(3개월)
  // const defaultExpireTime = dayjs().startOf('day').subtract(-3, 'month').format('YY-MM-DD');

  const [adjustId, setAdjustId] = useState<number | undefined>(undefined);

  const {
    register, handleSubmit, getValues, setValue,
  } = useForm<IAdjustRegistForm>({
    defaultValues: {
      adjustDiv: 'single',
      adjustDiv2: 'plus',
      taxbillYn: 'false',
      // paidExpireTime: defaultExpireTime,
      // freeExpireTime: defaultExpireTime,
    },
  });

  const handleAdjustDiv = (e: any) => {
    const v = e.target.value;
    setAdjustDiv(v);
    // if (v === 'single') {
    //   setValue('adjustDiv2', 'plus');
    // } else {
    //   setValue('adjustDiv2', 'at');
    // }
  };

  useEffect(() => {
    // do something
  }, []);

  const uncomma = (str: string) => {
    const str2 = String(str);
    return str2.replace(/[^\d]+/g, '');
  };

  // 임의조정 대상 조회 팝업 열기
  const openUserNoPopup = () => {
    setUserNoDialog(true);
  };

  const validate = (data: IAdjustRegistForm) => {
    if (data.adjustDiv === 'single' && data.projectId === undefined) {
      if (data.adjustDiv2 === 'plus') {
        throw new Error('임의조정 금액을 가산할 충전 사업자 번호를 선택해 주시기 바랍니다.');
      } else {
        throw new Error('임의조정 금액을 감산할 대상을 선택해 주시기 바랍니다.');
      }
    } else if (data.adjustDiv === 'at') {
      if (data.projectId === undefined) {
        throw new Error('임의조정 금액을 감산할 대상을 선택해 주시기 바랍니다.');
      }
      if (data.plusProjectId === undefined) {
        throw new Error('임의조정 금액을 가산할 충전 사업자 번호를 선택해 주시기 바랍니다.');
      }
    }
    return data;
  };

  const reg = (data: IAdjustRegistForm) => {
    ApiUtil3.post('/api/accAdjust/reg', data).then((res) => {
      if (res.data.ok === true) {
        setAdjustId(res.data.data);
      } else {
        alertify.error(res.data.data.msg);
      }
    });
  };

  // 등록 진행
  const onRegist = () => {
    const data = { ...getValues() };
    try {
      validate(data);
    } catch (ex: any) {
      // window.console.table(ex);
      alertify.error(ex.message);
      return;
    }

    if (data.adjustDiv === 'at' || data.adjustDiv2 === 'minus') {
      const totalBzm = (adjustTarget?.paidCost || 0) + (adjustTarget?.freeCost || 0);
      const totalCost = Number(uncomma(data.paidCost)) + Number(uncomma(data.freeCost));
      // 디버깅용
      // window.console.table({
      //   bzmPaid: adjustTarget?.paidCost || 0, bzmFree: adjustTarget?.freeCost || 0, bzmTotal: totalBzm, paidCost: data.paidCost, freeCost: data.freeCost, totalCost,
      // });
      if (totalBzm < totalCost) {
        alertify.confirm('임의조정 진행 시 비즈머니 잔액이 마이너스가 되는 충전 사업자 번호가 존재합니다.<br><br>'
          + '*. 마이너스 잔액은 비즈머니 혹은 쿠폰을 충전하는 순간 0원을 만들기 위해 자동 소진 됩니다.<br>'
          + '*. 유상 비즈머니로 마이너스 잔액을 0원으로 만들기 위해 사용된 금액에 대해서는 월 정산 시 세금계산서가 발행 대상이 됩니다.<br><br>'
          + '임의조정을 진행 하시겠습니까?', () => {
          reg(data);
        }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
        return;
      }
    }
    alertify.confirm('임의조정 진행 등록 후 취소는 불가 합니다.<br><br>임의조정을 진행 하시겠습니까?', () => {
      reg(data);
    }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
  };

  // 임의조정 대상 조회 후 선택했을 때
  useEffect(() => {
    setValue('projectId', adjustTarget?.projectId);
  }, [adjustTarget]);

  // 임의조정 등록 완료 후 임의조정 상세 페이지나, 세금계산서 등록 팝업을 처리함
  useEffect(() => {
    // window.console.log(`변경된 ADJUST_ID : ${adjustId}`);
    if (adjustId === undefined) return;

    const commonLoader = document.querySelector('.loader');
    if (commonLoader !== null) commonLoader.classList.remove('none');
    alertify.success('정상적으로 임의조정이 진행되었습니다.', 3, () => {
      if (commonLoader !== null) commonLoader.classList.add('none');
      if (getValues('taxbillYn') === 'true') {
        setTaxbillDialog(true);
      } else {
        navigate(`/accAdjust/mng/info?id=${adjustId}`);
      }
    });
  }, [adjustId]);

  return (
    <Fragment>
      {/* Wrap-Tbl : Start */}
      <form onSubmit={handleSubmit(onRegist)}>

        <section className="wrap-section wrap-tbl">
          <div className="box-header">
            <h2 className="fz-20 fc-1 fw-bold">임의조정 대상 정보</h2>
          </div>
          <div className="box-body">
            <div className="tbl">
              <dl>
                <dt>
                  <div className="dt-inner">
                    <span className="fz-16 fc-1">임의조정 방식</span>
                  </div>
                </dt>
                <dd>
                  <div className="form-group">
                    {/* Comp-Radio : Start */}
                    <div className="comp-radio">
                      <input type="radio" id="inp-radio-07"
                             value='single' {...register('adjustDiv', { onChange: handleAdjustDiv })} />
                      <label htmlFor="inp-radio-07">단일 임의조정</label>
                    </div>
                    {/* Comp-Radio : End */}
                    {/* Comp-Radio : Start */}
                    <div className="comp-radio">
                      <input type="radio" id="inp-radio-08"
                             value='at' {...register('adjustDiv', { onChange: handleAdjustDiv })} />
                      <label htmlFor="inp-radio-08">임의조정 금액이동</label>
                    </div>
                    {/* Comp-Radio : End */}
                  </div>
                </dd>
              </dl>
              {
                adjustDiv === 'single' ? <AccAdjustRegSingle openUserNoPopup={openUserNoPopup} adjustTarget={adjustTarget} register={register}/> : <AccAdjustRegTransfer openUserNoPopup={openUserNoPopup} adjustTarget={adjustTarget} setValue={setValue}/>
              }
            </div>
          </div>
        </section>
        {/* Wrap-Tbl : End */}

        <AccAdjustRegDet adjustDiv={adjustDiv} getValues={getValues} setValue={setValue} register={register} />

        <AccAdjustRegTaxbill register={register} getValues={getValues} setValue={setValue}/>

        {/* Wrap-Btn-Group : Start */}
        <div className="wrap-btn-group">
          <div className="box-center">
            <button type="button" className="btn btn-secondary-outline large" onClick={() => navigate('/accAdjust/mng')}>취소</button>
            <button type="submit" className="btn btn-primary large">등록</button>
          </div>
        </div>
        {/* Wrap-Btn-Group : End */}
      </form>
      {
        userNoDialog ? <AccAdjustDialogSearchUserNo setUserNoDialog={setUserNoDialog} setAdjustTarget={setAdjustTarget}/> : null
      }
      {
        taxbillDialog ? <AccAdjustDialogTaxbill setTaxbillDialog={setTaxbillDialog} accAdjustId={adjustId} source={'regAdjust'} /> : null
      }
    </Fragment>
  );
};
export default AccAdjustReg;
