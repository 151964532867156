import { useCallback, useState } from 'react'
import { Form, InputNumber } from 'antd'
import { useCampaignMutation, useCampaignInfoQuery, useCampaignStatus, useUsableCostQuery } from '@biz/query'
import { CampaignCaption, targetFormRegistry } from '@biz/ui'
import { useCampaignUpdateCheck } from './_queries'
import { BudgetUpdateNoticeModal, type BudgetUpdateInfoData } from './BudgetUpdateNoticeModal'

const { CampaignTotalName, CampaignRangeName, ProjectSelectorName } = targetFormRegistry
export type CampaignTotalType = { [CampaignTotalName]: number }
export const CampaignTotal: React.FC = () => {
  const {
    data: usableCost,
    update: setUsableCostChange,
    invalidateWithBizMoney: invalidateUsableCost,
  } = useUsableCostQuery()
  const { isFinished, inOperation } = useCampaignStatus()
  const { data: campaignInfoData, refetch } = useCampaignInfoQuery()
  const { data: campaignInfo } = campaignInfoData || {}
  const makeCampaign = useCampaignMutation()

  const form = Form.useFormInstance()
  const updateCheck = useCampaignUpdateCheck()
  const doChangeSelect = useCallback(
    (campBudgetChange?: boolean, cost?: number) => {
      // 화면에 먼저 세팅
      if (campaignInfo?.payType === 'BIZ_MONEY' && !!cost && cost > 0 && !!usableCost) {
        setUsableCostChange(`${usableCost - cost}`)
      }

      makeCampaign
        .mutateAsync({
          ...form.getFieldsValue(),
          fieldName: 'campBudget',
          // projectFundingDiv: campaignInfo?.projectFundingDiv,
          campBudgetChange,
          cost,
        })
        .then((isSuccess) => {
          if (!isSuccess && !!campaignInfo) {
            form.setFieldValue(CampaignTotalName, campaignInfo.campBudgetTotal1)
            form.validateFields()
            return
          }

          // API 패치
          refetch().then((res) => {
            const campaignTotal = form.getFieldValue([CampaignTotalName])
            if (res?.data?.data?.campBudgetTotal1 === campaignTotal) {
              if (campaignInfo?.payType === 'BIZ_MONEY' && !!cost && cost > 0 && !!usableCost) {
                invalidateUsableCost()
              }
            }
          })
        })
    },
    [makeCampaign, form, campaignInfo, invalidateUsableCost]
  )

  const [noticeInfo, setNoticeInfo] = useState<BudgetUpdateInfoData | null>(null)
  const doUpdate = useCallback(async () => {
    const preBudget = campaignInfo?.campBudgetTotal1 || 0
    const currentBudget = form.getFieldValue([CampaignTotalName])
    if (preBudget === currentBudget) {
      return
    }

    if (!inOperation?.()) {
      doChangeSelect()
      return
    }

    const { cmd, checkData, cost } = await updateCheck(preBudget, currentBudget) // cmd: 'update' | 'restore' | 'reCheck' checkData?: {
    if (cmd === 'restore') {
      form.setFieldValue(CampaignTotalName, campaignInfo?.campBudgetTotal1)
      form.validateFields()
      return
    }

    if (cmd === 'update') {
      doChangeSelect(true, cost)
      return
    }

    if (cmd === 'reCheck' && !!checkData) {
      setNoticeInfo({ ...checkData, campaignName: campaignInfo?.campNm || '' })
    }
  }, [inOperation, form, campaignInfo])
  return (
    <div>
      <CampaignCaption
        tooltip={
          <>
            <p className="fz-12 fc-0 fw-medium">{'캠페인 기간 동안 사용하기 희망하시는 예산을 입력합니다.'}</p>
            <p className="fz-12 fc-0">
              {'기입하시는 금액을 기반으로 캠페인 전체 기간 동안 효율적으로 광고비를 사용하게 됩니다.'}
            </p>
            <p className="fz-12 fc-0">{'소재 1개당 1일 최소 5천원 이상의 예산이 필요합니다.'}</p>
          </>
        }
      >
        캠페인 총 예산
      </CampaignCaption>
      <dd>
        <Form.Item dependencies={[ProjectSelectorName]}>
          {({ getFieldValue }) => {
            const projectId = getFieldValue(ProjectSelectorName)
            return (
              <Form.Item
                name={CampaignTotalName}
                dependencies={[CampaignRangeName]}
                rules={[
                  { required: true, message: '캠페인 총 예산은 꼭 필요합니다.' },
                  { type: 'number', message: '예산은 숫자로 입력하셔야 해요' },
                  ({ getFieldValue }) => ({
                    validator: (_, value) => {
                      const [start, end] = getFieldValue(CampaignRangeName)
                      const period = end.diff(start, 'day', false) + 1
                      const creativeCnt = campaignInfo?.creationDataCnt || 1
                      const minPrice = creativeCnt * 5000 * period
                      return !value || value >= minPrice
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              `예산은 '소재x일자'당 5000원( ${creativeCnt}개x${period}일 = ${minPrice}원 ) 이하로 책정 할 수 없어요.`
                            )
                          )
                    },
                  }),
                ]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  addonAfter="원"
                  controls={false}
                  precision={0}
                  min={0}
                  formatter={(value: number | undefined) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  disabled={!projectId || isFinished?.()}
                  onBlur={doUpdate}
                />
              </Form.Item>
            )
          }}
        </Form.Item>
      </dd>
      <BudgetUpdateNoticeModal
        isOpen={!!noticeInfo}
        onClose={() => setNoticeInfo(null)}
        info={noticeInfo}
        onConfirm={() => {
          setNoticeInfo(null)
          doChangeSelect(true, noticeInfo?.cost)
        }}
      />
    </div>
  )
}
