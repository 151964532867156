import React, {
  Fragment, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridSizeChangedEvent, ICellRendererParams } from 'ag-grid-community';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useOutletContext } from 'react-router';
import NoDataTemplate from '../../common/NoDataTemplate';
import Pagination from '../../common/Pagination';
import { ApiUtil2 } from '@biz/api'
import { INoticeList } from '../../community/notice/NoticeForAdmin';

const NoticeDetailRenderer = (props: ICellRendererParams) => {
  const noticeId = props.data.boardId;
  return <Link to={`/makerCommon/notice/detail/${noticeId}`}>{props.value}</Link>;
};
const dateFormatRenderer = (props: ICellRendererParams) => {
  const { date } = props.value;
  const { time } = props.value;
  return dayjs(new Date(date.year, date.month - 1, date.day, time.hour, time.minute)).format('YYYY-MM-DD HH:mm');
};

const boardDivRenderer = (props: ICellRendererParams) => {
  let item;
  if (props.data.boardDiv === '시스템') {
    item = <div className="txt-state red">시스템</div>;
  } else if (props.data.boardDiv === '이벤트') {
    item = <div className="txt-state mint">이벤트</div>;
  } else if (props.data.boardDiv === '가이드') {
    item = <div className="txt-state blue">가이드</div>;
  } else if (props.data.boardDiv === '일반') {
    item = <div className="txt-state black">일반</div>;
  }
  return item;
};

const NoticeForCommon = () => {
  const setTitle = useOutletContext<any>();
  const childRef = useRef<any>(null);
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>();
  const [boardDiv, setBoardDiv] = useState<string>('ALL');
  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'regTime',
      headerName: '날짜',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: dateFormatRenderer,
      width: 60,
    },
    {
      field: 'title',
      headerName: '제목',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
      cellRenderer: NoticeDetailRenderer,
      width: 200,
    },
    {
      field: 'boardDiv',
      headerName: '분류',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: boardDivRenderer,
      width: 60,
    },
  ]);

  useEffect(() => {
    setTitle('공지사항');
    return () => {
      setTitle('');
    };
  }, []);

  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: true,
  }), []);

  // onGridSizeChanged will get called when grid is ready and every time the grid's width changes
  const onGridSizeChanged = (params: GridSizeChangedEvent) => {
    params.api.sizeColumnsToFit();
  };

  const onGridReady = useCallback(() => {
    ApiUtil2.get<INoticeList>('/api/makerCommon/notice/list', {
      params: { data: { boardDiv } },
    }).then((resp) => {
      setRowData(resp.data.data);
    });
  }, []);

  const changePagination = () => {
        childRef.current!.onPaginationChanged();
  };

  const boardDivTabHandler = (e: any) => {
    setBoardDiv(e.target.id);

    if (e.target.id !== '') {
      ApiUtil2.get<INoticeList>('/api/makerCommon/notice/list', {
        params: { data: { boardDiv: e.target.id } },
      }).then((resp) => {
        setRowData(resp.data.data);
      });
    }
  };

  return (
        <Fragment>
            <div className="comp-tab">
                <button className={boardDiv === 'ALL' ? 'tab selected' : 'tab'} onClick={boardDivTabHandler}>
                    <span className="tab-item" id={'ALL'}>전체</span>
                </button>
                <button className={boardDiv === 'NORMAL' ? 'tab selected' : 'tab'} onClick={boardDivTabHandler}>
                    <span className="tab-item" id={'NORMAL'}>일반</span>
                </button>
                <button className={boardDiv === 'EVENT' ? 'tab selected' : 'tab'} onClick={boardDivTabHandler}>
                    <span className="tab-item" id={'EVENT'}>이벤트</span>
                </button>
                <button className={boardDiv === 'SYSTEM' ? 'tab selected' : 'tab'} onClick={boardDivTabHandler}>
                    <span className="tab-item" id={'SYSTEM'}>시스템</span>
                </button>
                <button className={boardDiv === 'GUIDE' ? 'tab selected' : 'tab'} onClick={boardDivTabHandler}>
                    <span className="tab-item" id={'GUIDE'}>가이드</span>
                </button>
            </div>
            <div className="comp-tab-content selected">
                <section className="wrap-section wrap-datagrid">
                    <div className="box-body">
                        <div className="ag-grid">
                            <div className="ag-grid-inner">
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={rowData}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    onGridReady={onGridReady}
                                    rowHeight={48}
                                    pagination={true}
                                    paginationPageSize={20}
                                    suppressPaginationPanel={true}
                                    onGridSizeChanged={onGridSizeChanged}
                                    onPaginationChanged={changePagination}
                                    domLayout={'autoHeight'}
                                    noRowsOverlayComponent={NoDataTemplate}
                                ></AgGridReact>
                            </div>
                        </div>
                    </div>
                    <div className="box-footer">
                        <Pagination gridRef={gridRef} ref={childRef}/>
                    </div>
                </section>
            </div>
        </Fragment>
  );
};

export default React.memo(NoticeForCommon);
