import { useState, useRef } from 'react'
import { useNavigate } from 'react-router'
import { useQuery, useQueryClient, type QueryClient } from '@tanstack/react-query'
import alertify from 'alertifyjs'
import { useCampaignInfoQuery, useCreativeDataQuery, useCampaignStatus } from '@biz/query'
import { useAIInspectionHistoryForMakerQuery, useAIInspectionHistoryQuery } from '@biz/query'

import { type CampaignInfoTabFieldsType } from '../../CampaignInfoTab'
import { KeywordValidationStep } from './KeywordValidationStep'
import { CreativeValidationStep } from './CreativeValidationStep'
import { PayTypeSelectModal } from './PayTypeSelectModal'
import { PaymentStep } from './PaymentStep'
import { AIInspectionInModal } from './AIInspectionInModal'

export const useConfirm = (initData: CampaignInfoTabFieldsType) => {
  const { isAIInspection } = useCampaignStatus()
  const navigate = useNavigate()
  const [stepControl, setStep] = useState<
    '' | 'keywordValidation' | 'creative' | 'paymentSelect' | 'payment' | 'aiInspection'
  >('')
  const paymentResult = useRef<{ isSuccess: boolean; msg: string } | null>(null)
  const [payType, setPayType] = useState<'POST_PAYMENT' | 'BIZ_MONEY'>()
  return {
    doConfirm: (values: any) => {
      setStep('keywordValidation')
    },
    // 주의할 점이 있습니다. 아래 컴포넌트는 반드시 <Form> 안에 있어야 합니다. useFormInstance를 사용해서 Form입력 결과를 쓰거든요.
    ConfirmComponent: (rootProps: { projectId: string }) => (
      <>
        <KeywordValidationStep
          isProcess={stepControl === 'keywordValidation'}
          onClose={() => setStep('')}
          onNextProcess={() => setStep('creative')}
          initData={initData}
        />
        <CreativeValidationStep
          isProcess={stepControl === 'creative'}
          onCancel={() => setStep('')}
          onNextProcess={() => setStep('paymentSelect')}
        />
        <PayTypeSelectModal
          isOpen={stepControl === 'paymentSelect'}
          onSelectType={(p) => {
            setPayType(p)
            setStep('payment')
          }}
        />
        {!!payType && (
          <PaymentStep
            payType={payType}
            isProcess={stepControl === 'payment'}
            needAIInspection={() => {
              if (!isAIInspection) {
                return false
              }
              return isAIInspection?.(rootProps.projectId)
            }}
            onCancel={() => setStep('')}
            onNextProcess={(props: { isSuccess: boolean; msg: string }) => {
              if (!!isAIInspection && isAIInspection(rootProps.projectId)) {
                paymentResult.current = props
                setStep('aiInspection')
              } else {
                alertify[props.isSuccess ? 'success' : 'error'](props.msg || '결제 실패')
                navigate('/mngCamp/mngCamp')
                setStep('')
              }
            }}
          />
        )}
        <AIInspectionInModal
          isOpen={stepControl === 'aiInspection'}
          onClose={() => {
            const props = paymentResult.current as { isSuccess: boolean; msg: string }
            alertify[props.isSuccess ? 'success' : 'error'](props.msg || '결제 실패')
            paymentResult.current = null

            setStep('')

            navigate('/mngCamp/mngCamp')
          }}
        />
      </>
    ),
  }
}
