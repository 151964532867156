import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
/*
daterangepicker single 버전 리액트 컴포넌트로 대충 만듦
AccAdjustDialogTaxbillDetail.tsx 참고
minDate, maxDate, startDate같은 옵션 바꿀 수 있음
단지 리렌더링 되면서 현재 선택된 날짜가 날아가는 문제가 있긴하지만... 신경안씀.
*/
export const datesinglepickerDefaultOption = {
  singleDatePicker: true,
  autoUpdateInput: true,
  locale: {
    format: 'YY-MM-DD',
  },
};

const SingleDatePicker = (props: any) => {
  const datepicker = useRef<any>();
  const [datepickerOption, setDatepickerOption] = useState<any>();

  const makeDatePickerOption = () => ({
    ...datesinglepickerDefaultOption, ...{ startDate: dayjs().startOf('day') }, ...datepickerOption,
  });

  const renderDatePicker = (option: any) => {
    $(datepicker.current).daterangepicker(option, (start: any) => {
      props.changeEvent(start);
    });
  };

  useEffect(() => {
    window.console.debug('date range picker');
    // setDatepickerOption(props.option);
    //
    // const option = makeDatePickerOption();
    // renderDatePicker(option);
    //
    // props.changeEvent(option.startDate);
  }, []);

  useEffect(() => {
    const newOption = props.option;
    const prevOption = datepickerOption;

    if (!_.isEqual(newOption, prevOption)) {
      setDatepickerOption(props.option);
    }
  }, [props.option]);

  useEffect(() => {
    const option = makeDatePickerOption();
    renderDatePicker(option);
    props.changeEvent(option.startDate);
  }, [datepickerOption]);

  return (
    <div className="comp-datepicker">
      <div className="inner-datepicker">
        <i className="ico ico-calendar"></i>
        <input type="text" className="tf-comm datepicker-single" readOnly ref={datepicker} />
      </div>
    </div>
  );
};

export default SingleDatePicker;
