import { LabelBadge } from '@platform/share/ui'
import type { CreationDetail } from '@biz/api'
import { CreativeStatusLabels } from './CreativeStatusLabels'

import styles from './CreationDetailCard.module.scss'

interface CreationDetailCardProps {
  type: 'single' | 'all'
  data: CreationDetail
  index: number
  handleReject: (campId: any, id: any, isStopCreation?: boolean) => void
  handleConfirm: (campId: any, id: any) => void
  setChatGptDialogParams: (creationDataId: string, campId: number) => void
  setDialogParams: (campId: number, creationDataId: string, campAttach: any) => void
  setCreationDataInspHisParams: (creationDataId: string, index: number) => void
  setDialogMemoParams: (campId: number, creationDataId: string) => void
  event: (id: any) => void
  handleDeleteItem?: (id: any) => void
}

/**
 * (관리자) 소재 검수 카드
 */
export const CreationDetailCard = ({
  type = 'single',
  data,
  index,
  handleReject,
  handleConfirm,
  setChatGptDialogParams,
  setDialogParams,
  setCreationDataInspHisParams,
  setDialogMemoParams,
  event,
  handleDeleteItem,
}: CreationDetailCardProps) => {
  const inspStatus: any = {
    ing: 'AI – 검수중',
    error: 'AI – 실패',
    ok: 'AI – 승인',
    fail: 'AI – 반려',
  }

  const inspStatusColor: any = {
    ing: styles.info, // 'btn-info',
    error: styles.secondary, // 'btn-secondary',
    ok: styles.primary, // 'btn-primary',
    fail: styles.danger, // 'btn-danger',
  }
  let {
    adDesc,
    af,
    adTitle01,
    adTitle02,
    campViewStatus,
    campAttchAf,
    catCode,
    catCodeDesc,
    catInspConfig,
    catInspDiv,
    clickBtnDiv,
    creationData,
    gptInspStatus,
    inspNeedStatus,
    creationViewStatus,
    snsInspStatus, // 메타 소재 승인 상태
    syncYn, // 메타와 싱크 여부
    campPeriodValid, // 캠페인 기간 유효성
  } = data

  const isCampaignFinished =
    campViewStatus === 'complete' || campViewStatus === 'del_ready' || campViewStatus === 'del_complete' // 캠페인 종료 여부
  const isCampaignActive = campViewStatus === 'ing' || campViewStatus === 'ing_insp' || campViewStatus === 'ing_update' // 캠페인 진행 중 여부 (진행 | 진행(검수중) | 진행(수정필요))
  const isAIAutoInspectionCategory = catInspConfig?.catInspDiv === 'aiApprove' // AI 자동 검수 카테고리 여부
  const isMetaActive = snsInspStatus === 'ok' && syncYn === true && campPeriodValid === true // 메타에 캠페인 실행 중인지 여부

  const HighlightText = ({ inputText, className }: { inputText: string; className?: string }) => {
    const prohibitedKeywordRegex = new RegExp(`(${catInspConfig?.catProhKwd})`, 'gi')
    const prohibitedKeywords = catInspConfig?.catProhKwd?.split('|')
    const hasProhibitedKeywords = inputText.match(prohibitedKeywordRegex)

    const parts = inputText.split(prohibitedKeywordRegex) // 키워드를 기준으로 문자열을 분리

    return (
      <p className={`${styles.highlightText} ${className} ${hasProhibitedKeywords ? styles.error : undefined}`}>
        {parts.map((part, index) =>
          prohibitedKeywords.some((keyword) => part.toLowerCase() === keyword.toLowerCase()) ? (
            <span key={index} className={styles.keyword}>
              {part}
            </span>
          ) : (
            part // 키워드가 아닌 부분은 그대로 출력
          )
        )}
      </p>
    )
  }

  return (
    <div className={`${styles.container}`} key={index}>
      <div className="wrap-preview">
        <div className={`${styles.header}`}>
          <CreativeStatusLabels
            inspNeedStatus={inspNeedStatus}
            catCodeDesc={catCodeDesc}
            isAIAutoInspectionCategory={isAIAutoInspectionCategory}
            wabizInspStatus={creationData.wabizInspStatus}
            creationViewStatus={creationViewStatus}
          />
          <div className={`box-option ${styles.confirmArea}`} id={`option${creationData.id}`}>
            {/* AI 자동 검수 카테고리 X + 캠페인 종료 X + 검수 진행 중 => 승인 또는 반려하기 */}
            {/* AI 자동 검수 카테고리 O + 캠페인 종료 X + AI 검수 통과 또는 반려 + 관리자 검수 필요 => 승인 또는 반려하기 */}
            {((!isAIAutoInspectionCategory && !isCampaignFinished && creationData.wabizInspStatus === 'ing') ||
              (!isCampaignFinished && creationData.wabizInspStatus === 'ing_ai_ok') ||
              (!isCampaignFinished && creationData.wabizInspStatus === 'ing_ai_fail')) && (
              <>
                <button
                  type="button"
                  className={`${styles.button} ${styles.red}`}
                  onClick={() => {
                    handleReject(data.campId, creationData.id)
                  }}
                >
                  반려하기
                </button>
                <button
                  type="button"
                  className={`${styles.button} ${styles.mint}`}
                  onClick={() => {
                    handleConfirm(data.campId, creationData.id)
                  }}
                >
                  승인하기
                </button>
              </>
            )}

            {/* (일반) 종료되지 않은 캠페인 & 검수 반려 => 승인하기 */}
            {/* (일반 + AI) 종료되지 않은 캠페인 & 소재 강제 중단 => 승인하기 */}
            {((!isCampaignFinished && creationData.wabizInspStatus === 'fail') ||
              (!isCampaignFinished && creationData.wabizInspStatus === 'stop_by_admin')) && (
              <button type="button">
                <button
                  className={`${styles.button} ${styles.outline}`}
                  onClick={(event) => {
                    handleConfirm(data.campId, creationData.id)
                  }}
                >
                  승인하기
                </button>
              </button>
            )}

            {/* (일반) 종료되지 않은 캠페인 & 검수 승인 => 반려하기 */}
            {/* (AI) 종료되지 않은 캠페인 & AI 검수 통과 => 반려하기 */}
            {((!isCampaignFinished && creationData.wabizInspStatus === 'ok') ||
              (!isCampaignFinished && creationData.wabizInspStatus === 'ok_ai')) && (
              <button
                className={`${styles.button} ${styles.outline}`}
                onClick={(event) => {
                  handleReject(data.campId, creationData.id, isCampaignActive && isMetaActive)
                }}
              >
                {/* 캠페인이 시작했으면 중단하기로 바뀌어야 함 */}
                {(isCampaignActive && isMetaActive) ? '소재 중단하기' : '반려하기'}
              </button>
            )}

            {/* 일괄 검수의 경우 제외 버튼 */}
            {type === 'all' && (
              <button
                type="button"
                className={`${styles.button} ${styles.outline}`}
                onClick={() => {
                  handleDeleteItem?.(creationData.id)
                }}
              >
                제외
              </button>
            )}
          </div>
        </div>

        <div className={`${styles.header}`}>
          <div>
            {gptInspStatus !== undefined ? (
              <button
                type="button"
                className={`${inspStatusColor[gptInspStatus]} ${styles.button}`}
                onClick={() => setChatGptDialogParams(data.creationDataId, data.campId)}
              >
                {inspStatus[gptInspStatus]}
              </button>
            ) : (
              <button
                type="button"
                className={`${styles.button} ${styles.info}`}
                onClick={() => setChatGptDialogParams(data.creationDataId, data.campId)}
              >
                AI – 검수중
              </button>
            )}
          </div>
          <div>
            {campAttchAf.afs.length != 0 ? (
              <button
                id={'temp_' + data.creationDataId}
                onClick={() => setDialogParams(data.campId, data.creationDataId, campAttchAf)}
                type="button"
                className={`${styles.button} ${styles.infoOutline}`}
              >
                첨부서류
              </button>
            ) : null}
            <button
              type="button"
              className={`${styles.button} ${styles.outline}`}
              onClick={() => setCreationDataInspHisParams(data.creationDataId, index)}
            >
              검수 이력
            </button>
            <button
              type="button"
              className={`${styles.button} ${styles.outline}`}
              onClick={() => setDialogMemoParams(data.campId, data.creationDataId)}
            >
              메모
            </button>
          </div>
        </div>
        <div className="box-header">
          <div className="box-tit">
            <span className="fz-14 fc-1">캠페인ID: {data.campId}</span>
            <span className="fz-14 fc-1">소재ID: {data.creationData.id}</span>
          </div>
        </div>
        <div className="box-body">
          <div className="box-group flex-container">
            <div className="box-left">
              <img
                src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Facebook-01.png"
                alt="img1"
              />
            </div>
            <div className="box-right">
              <img
                src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Facebook-02.png"
                alt="img1"
              />
            </div>
          </div>
          <div className="box-group">
            <div className="box-left">
              <img
                src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Ico-logo-wadiz-48x48.png"
                className="logo"
                alt="img1"
              />
            </div>
            <div className="box-right">
              <p className="fz-14 fc-1 fw-medium lh15">와디즈</p>
              <p className="fz-12 fc-3 lh15">광고</p>
            </div>
          </div>
          <div className="box-group">
            {catCode !== undefined &&
              catInspConfig.catProhKwd !== undefined &&
              catInspConfig.catProhKwd.split('|').some((kwd: any) => {
                adDesc.includes(kwd)
                  ? (adDesc = adDesc.replaceAll(kwd, `<span class="fc-7 fw-bold txt-underline">${kwd}</span>`))
                  : adDesc
              })}
            <p
              id="gdnAdDesc"
              dangerouslySetInnerHTML={{ __html: adDesc.replace(/\n/g, '<br/>') }}
              className={`fz-14 fc-2 lh15 ${
                adDesc !== '' &&
                catCode !== undefined &&
                catInspConfig.catProhKwd !== undefined &&
                catInspConfig.catProhKwd !== '' &&
                catInspConfig.catProhKwd.split('|').some((kwd: any) => adDesc.includes(kwd))
                  ? 'error'
                  : ''
              }`}
            ></p>
          </div>
          <div className="box-group">
            {af.length === 0 ? (
              <div className="comp-no-data">
                <div className="box-group">
                  <i className="ico ico-no-img"></i>
                  <p className="fz-14 fc-2">이미지 혹은 영상을 선택해주세요.</p>
                </div>
              </div>
            ) : (
              <div className={`comp-thumb ${styles.thumbnail}`} style={{}}>
                <button type="button" onClick={() => event(creationData.id)}>
                  <LabelBadge
                    value={af[0].afMeta.fileDiv === 'VIDEO' ? '동영상' : '이미지'}
                    size="sm"
                    variant="tint"
                    className={styles.assetType}
                  />

                  <LabelBadge
                    value={af[0].afMeta.ext.toUpperCase()}
                    size="sm"
                    color="blue"
                    variant="tint"
                    className={styles.assetExtension}
                  />

                  {af[0].afMeta.fileDiv === 'VIDEO' && af[0].afMeta.ext !== 'gif' ? (
                    // eslint-disable-next-line jsx-a11y/media-has-caption
                    <video
                      autoPlay
                      controls
                      muted
                      loop
                      /*style={{ width: '100%' }}*/
                    >
                      <source src={`${af[0].fileName}`} />
                    </video>
                  ) : (
                    <img
                      /* className={`${aa.af[0].width/aa.af[0].height === 4/5 ? 'landscape' : null}`}*/
                      src={`${af[0].fileName}`}
                      /* style={{ width: '100%' }}*/
                      alt="img22"
                    />
                  )}
                </button>
              </div>
            )}
          </div>
          <div className="box-group">
            <div className="box-row">
              <span className="fz-12 fc-3 lh15">wadiz.kr</span>
            </div>
            <div className="box-row flex-container">
              <div className="box-left">
                <HighlightText inputText={adTitle01} className={styles.title} />
              </div>
              <div className="box-right">
                <button type="button" className="btn btn-secondary-outline xsmall w-auto">
                  {clickBtnDiv}
                </button>
              </div>
            </div>
            <div className="box-row">
              <HighlightText inputText={adTitle02} className={styles.description} />
            </div>
          </div>
        </div>
        <div className="box-footer">
          <div className="box-group">
            <div className="border-top">
              <img
                src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Facebook-03.png"
                alt="img1"
              />
            </div>
          </div>
          <div className="box-group">
            <img
              src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Facebook-04.png"
              alt="img1"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
