const NoData = () => (
  <div className="comp-no-data">
    <div className="box-group">
      <i className="ico ico-no-data"></i>
      <p className="fz-18 fc-2">데이터가 존재하지 않습니다.</p>
    </div>
  </div>
)

export default NoData
