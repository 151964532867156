import { axios } from '../axios'

export type UpdateCampaignDataType = Partial<{
  adminCampYn: boolean
  campBudgetTotal1: number // 300000
  campEndTime: string // '202410201538'
  campGoalDiv: string // 'CONVERSIONS'
  campId: number // 1770597143
  campNm: string // '임시_202410181527'
  campStartTime: string // '202410181538'
  field: string // 'project'
  mediaDiv4: string // 'FBITG'
  projectFundingDiv: string // 'NON'
  projectId: string // 'S535'
  useYn: true
  campBudgetChange: boolean
  cost: number
}>

type ResponseType = {
  ok: boolean
  data: boolean
}
type FunctionType = (data: UpdateCampaignDataType) => Promise<ResponseType>
export const fetchPostUpdateCampaign: FunctionType = (data) => axios.post('/api/camp/make02/campUpdate', data)
