import React, {
  forwardRef,
  Fragment, useEffect, useImperativeHandle, useState,
} from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import MngMdFbPageDialog from './MngMdFbPageDialog';

export interface categoryAudience{
  category? : string
  customAudience? : string
  exCustomAudience? : string
}

export interface fbAdSet{
  instagramActorId? : string // 광고 노출 인스타그램 계정
  destinationType : string // 전환 이벤트 위치
  customEventType : string // 전환 이벤트
  convDomain : string // 추적 도메인
  attributionSpec : string // 기여 설정
  customAudience? : string
  exCustomAudience? : string
}

export interface mediaFbPage{
  mediaFbPageId? : bigint,
  adDpPageId : string // 페이지 ID
  name : string // 이름
  useYn : string // 사용 여부
  sortOrder : number // 광고세트 순서
  regTime : Date,
  regName : string,
}

export interface fbAdSetState{
  isTokenReg: any
  isAdAccountSelect: any
  isPixelSelect: any
  adPageList: any
  adsVolumes: any
  instagramAccountList: any
  customAudienceList: any
  customConversionList: any
  makerSetNum: number,
  fbAdSets: fbAdSet[],
  setFbAdSets: React.Dispatch<React.SetStateAction<fbAdSet[]>>,
  fbAdCategory: categoryAudience[],
  setFbCategory: React.Dispatch<React.SetStateAction<categoryAudience[]>>,
  fbAdSetErrorChk: any,
  isFbAdSetError: any,
  isModify: any,
  modifyCnt: number,
  setShowFbPageDialog: any,
  mediaFbPageList: mediaFbPage[],
}

const SetForm = (props : fbAdSetState) => {
  const [pageName, setPageName] = useState('');
  const {
    fbAdSets, setFbAdSets, fbAdCategory, setFbCategory,
  } = props;
  const {
    register, getValues, setValue, watch,
  } = useForm<fbAdSet>({
  });
  const insertFbSet = () => {
    setFbAdSets((prevState) => {
      const updateState = [...prevState];
      updateState[props.makerSetNum - 1] = getValues();
      return updateState;
    });
  };
  useEffect(() => { // 첫 출현 시
    $('#destinationTypeTooltip').tooltip({
      content: '<p class="fz-12 fc-0">웹사이트/앱은 캠페인 목표가 ‘전환’인 경우만 선택되며, 캠페인 목표가 ‘트래픽‘인 경우 ‘웹사이트‘가 선택 됩니다</p>',
    });
    $('#customEventTypeTooltip').tooltip({
      content: '<p class="fz-12 fc-0">캠페인 목표가 ‘전환’이며, 전환 이벤트 위치가<br/>‘웹 사이트’인 경우만 사용합니다.</p>',
    });
    $('#customEventTypeAppTooltip').tooltip({
      content: '<p class="fz-12 fc-0">캠페인 목표가 ‘전환’이며, 전환 이벤트 위치가<br/>‘웹 사이트/앱’인 경우 사용합니다.<br/>이벤트는 메타 광고주센터에서 등록한 내역을<br/>이벤트 내역 설정에 수동 등록하여 사용하실수 있습니다.</p>',
    });
    $('#attributionSpecTooltip').tooltip({
      content: '<p class="fz-12 fc-0">해당 값은 캠페인 목표가 ‘전환’이고 전환 이벤트가<br/>‘웹사이트’ 또는 ‘웹사이트/앱’이 선택된 경우<br/>캠페인 생성 시 사용됩니다.</p>',
    });
    $('#convDomainTooltip').tooltip({
      content: '<p class="fz-12 fc-0">추적을 위한 전환 이벤트의 도메인으로 사용됩니다.<br/>메타 광고주센터에서 픽셀에 연동된 전환 이벤트 중<br/>광고 시 추적할 도메인을 확인하여<br/>입력해 주시기 바랍니다.</p>',
    });
  }, []);
  useEffect(() => { // 첫 출현 시
    $('#categorySelect').off('select2:select');
    $('#categorySelect').on('select2:select', (e) => {
      const category = e.params.data.id;
      for (let i = 0; i < fbAdCategory.length; i += 1) {
        if (fbAdCategory[i].category === category) {
          setValue('customAudience', fbAdCategory[i].customAudience);
          setValue('exCustomAudience', fbAdCategory[i].exCustomAudience);
          $('#customAudienceSelect').trigger('change');
          $('#exCustomAudienceSelect').trigger('change');
          return;
        }
      }
      setValue('customAudience', '');
      setValue('exCustomAudience', '');
      $('#customAudienceSelect').trigger('change');
      $('#exCustomAudienceSelect').trigger('change');
    });
  }, [fbAdCategory]);
  useEffect(() => { // 광고 노출 페이지, 광고 노출 인스타그램 계정 목록 변경 되면 초기화
    setValue('instagramActorId', '');
    setFbAdSets((prevState) => {
      const updateState = [...prevState];
      for (let i = 0; i < 3; i += 1) {
        updateState[i].instagramActorId = '';
      }
      return updateState;
    });
    $('#adInstagramSelect').trigger('change');
  }, [props.adPageList]);
  useEffect(() => { // 맞춤 타겟 목록 변경 되면 초기화
    setFbCategory([]);
    setValue('customAudience', '');
    setValue('exCustomAudience', '');
    setFbAdSets((prevState) => {
      const updateState = [...prevState];
      for (let i = 0; i < 3; i += 1) {
        updateState[i].customAudience = '';
        updateState[i].exCustomAudience = '';
      }
      return updateState;
    });
  }, [props.customAudienceList]);

  useEffect(() => { // 맞춤 전환 목록 변경 되면 초기화
    setValue('customEventType', 'SEARCH');
    setFbAdSets((prevState) => {
      const updateState = [...prevState];
      for (let i = 0; i < 3; i += 1) {
        updateState[i].customEventType = 'SEARCH';
      }
      return updateState;
    });
  }, [props.customConversionList]);
  useEffect(() => { // 추적 도메인 변경 시 광고세트 저장
    insertFbSet();
  }, [watch('convDomain')]);
  useEffect(() => {
    const mediaFbPage = props.mediaFbPageList.filter((v : mediaFbPage) => v.sortOrder == props.makerSetNum && v.useYn);
    setPageName(mediaFbPage.length > 0 ? mediaFbPage[0].name : '');

    if (typeof fbAdSets[props.makerSetNum - 1] !== 'undefined' && typeof fbAdSets[props.makerSetNum - 1].instagramActorId !== 'undefined') {
      setValue('instagramActorId', fbAdSets[props.makerSetNum - 1].instagramActorId);
      $('#adInstagramSelect').trigger('change');
    }
    if (typeof fbAdSets[props.makerSetNum - 1] !== 'undefined' && typeof fbAdSets[props.makerSetNum - 1].destinationType !== 'undefined') {
      setValue('destinationType', fbAdSets[props.makerSetNum - 1].destinationType);
      $('#destinationTypeSelect').trigger('change');
    }
    if (typeof fbAdSets[props.makerSetNum - 1] !== 'undefined' && typeof fbAdSets[props.makerSetNum - 1].customEventType !== 'undefined') setValue('customEventType', fbAdSets[props.makerSetNum - 1].customEventType);
    $('#customEventTypeSelect').trigger('change');

    setValue('attributionSpec', fbAdSets[props.makerSetNum - 1].attributionSpec);
    $('#attributionSpecSelect').trigger('change');

    if (props.makerSetNum === 2) {
      setValue('customAudience', fbAdSets[props.makerSetNum - 1].customAudience);
      $('#customAudienceSelect').trigger('change');

      setValue('exCustomAudience', fbAdSets[props.makerSetNum - 1].exCustomAudience);
      $('#exCustomAudienceSelect').trigger('change');
    }

    setValue('convDomain', fbAdSets[props.makerSetNum - 1].convDomain);

    if (props.makerSetNum === 3) {
      setValue('customAudience', '');
      $('#customAudienceSelect').trigger('change');

      setValue('exCustomAudience', '');
      $('#exCustomAudienceSelect').trigger('change');
    }

    $('#adInstagramSelect, #destinationTypeSelect, #customEventTypeSelect, #attributionSpecSelect, #categorySelect, #customAudienceSelect, #exCustomAudienceSelect').off('select2:select');
    $('#customAudienceSelect, #exCustomAudienceSelect').off('select2:unselect');

    $('#adInstagramSelect').select2({
      width: '330px',
      placeholder: '광고를 노출할 인스타그램 계정을 선택해 주세요.',
    }).on('select2:select', (e) => {
      setValue('instagramActorId', e.params.data.id);
      insertFbSet();
    });
    $('#destinationTypeSelect').select2({
      width: '330px',
      minimumResultsForSearch: Infinity,
    }).on('select2:select', (e) => {
      setValue('destinationType', e.params.data.id);
      insertFbSet();
    });

    $('#customEventTypeSelect').select2({
      width: '330px',
      placeholder: '측정할 전환 이벤트를 선택해 주세요.',
      minimumResultsForSearch: Infinity,
    }).on('select2:select', (e) => {
      setValue('customEventType', e.params.data.id);
      insertFbSet();
    });
    $('#attributionSpecSelect').select2({
      width: '330px',
      minimumResultsForSearch: Infinity,
    }).on('select2:select', (e) => {
      setValue('attributionSpec', e.params.data.id);
      insertFbSet();
    });

    if (props.makerSetNum === 3) {
      $('#categorySelect').select2({
        width: '330px',
        placeholder: '카테고리를 선택해 주세요.',
      }).on('select2:select', (e) => {
        const category = e.params.data.id;
        for (let i = 0; i < fbAdCategory.length; i += 1) {
          if (fbAdCategory[i].category === category) {
            setValue('customAudience', fbAdCategory[i].customAudience);
            setValue('exCustomAudience', fbAdCategory[i].exCustomAudience);
            $('#customAudienceSelect').trigger('change');
            $('#exCustomAudienceSelect').trigger('change');
            return;
          }
        }
        setValue('customAudience', '');
        setValue('exCustomAudience', '');
        $('#customAudienceSelect').trigger('change');
        $('#exCustomAudienceSelect').trigger('change');
      });
    }

    if (props.makerSetNum === 2 || props.makerSetNum === 3) {
      $('#customAudienceSelect').select2({
        width: '330px',
        placeholder: '포함할 타겟을 선택해 주세요.',
        tags: true,
        createTag: () => (null),
      }).on('select2:select', () => {
        const arr = $('#customAudienceSelect').val() as string[];
        const exArr = $('#exCustomAudienceSelect').val() as string[];
        const resultArr = exArr.filter((e) => {
          for (let i = 0; i < arr.length; i += 1) {
            if (arr[i] === e) return false;
          }
          return true;
        });
        setValue('customAudience', arr.join());
        setValue('exCustomAudience', resultArr.join());
        $('#exCustomAudienceSelect').trigger('change');
        insertFbSet();

        // 와디즈 확장 세트일 경우 카테고리에도 저장 해 줘야 함.
        if (props.makerSetNum === 3 && $('#categorySelect').val() !== '') {
          const audience: categoryAudience = { category: $('#categorySelect').val() as string, customAudience: arr.join(), exCustomAudience: resultArr.join() };
          setFbCategory((prevState) => {
            const updateState = [...prevState];
            for (let i = 0; i < updateState.length; i += 1) {
              if (updateState[i].category === audience.category) {
                updateState[i].customAudience = audience.customAudience;
                updateState[i].exCustomAudience = audience.exCustomAudience;
                return updateState;
              }
            }
            return [...updateState, audience];
          });
        }
      }).on('select2:unselect', () => {
        const arr = $('#customAudienceSelect').val() as string[];
        setValue('customAudience', arr.join());
        insertFbSet();

        // 와디즈 확장 세트일 경우 카테고리에도 저장 해 줘야 함.
        if (props.makerSetNum === 3 && $('#categorySelect').val() !== '') {
          const audience: categoryAudience = { category: $('#categorySelect').val() as string, customAudience: arr.join() };
          setFbCategory((prevState) => {
            const updateState = [...prevState];
            for (let i = 0; i < updateState.length; i += 1) {
              if (updateState[i].category === audience.category) {
                updateState[i].customAudience = audience.customAudience;
                return updateState;
              }
            }
            return [...updateState, audience];
          });
        }
      });

      $('#exCustomAudienceSelect').select2({
        width: '330px',
        placeholder: '제외할 타겟을 페이지를 선택해 주세요.',
        tags: true,
      }).on('select2:select', () => {
        const arr = $('#customAudienceSelect').val() as string[];
        const exArr = $('#exCustomAudienceSelect').val() as string[];
        const resultArr = arr.filter((e) => {
          for (let i = 0; i < exArr.length; i += 1) {
            if (exArr[i] === e) return false;
          }
          return true;
        });

        setValue('exCustomAudience', exArr.join());
        setValue('customAudience', resultArr.join());
        $('#customAudienceSelect').trigger('change');
        insertFbSet();

        // 와디즈 확장 세트일 경우 카테고리에도 저장 해 줘야 함.
        if (props.makerSetNum === 3 && $('#categorySelect').val() !== '') {
          const audience: categoryAudience = { category: $('#categorySelect').val() as string, customAudience: resultArr.join(), exCustomAudience: exArr.join() };
          setFbCategory((prevState) => {
            const updateState = [...prevState];
            for (let i = 0; i < updateState.length; i += 1) {
              if (updateState[i].category === audience.category) {
                updateState[i].customAudience = audience.customAudience;
                updateState[i].exCustomAudience = audience.exCustomAudience;
                return updateState;
              }
            }
            return [...updateState, audience];
          });
        }
      }).on('select2:unselect', () => {
        const exArr = $('#exCustomAudienceSelect').val() as string[];
        setValue('exCustomAudience', exArr.join());
        insertFbSet();

        // 와디즈 확장 세트일 경우 카테고리에도 저장 해 줘야 함.
        if (props.makerSetNum === 3 && $('#categorySelect').val() !== '') {
          const audience: categoryAudience = { category: $('#categorySelect').val() as string, exCustomAudience: exArr.join() };
          setFbCategory((prevState) => {
            const updateState = [...prevState];
            for (let i = 0; i < updateState.length; i += 1) {
              if (updateState[i].category === audience.category) {
                updateState[i].exCustomAudience = audience.exCustomAudience;
                return updateState;
              }
            }
            return [...updateState, audience];
          });
        }
      });
    }
  }, [props.makerSetNum, props.isModify, props.modifyCnt]);
  useEffect(() => {
    const mediaFbPage = props.mediaFbPageList.filter((v : mediaFbPage) => v.sortOrder == props.makerSetNum && v.useYn);
    setPageName(mediaFbPage.length > 0 ? mediaFbPage[0].name : '');
  }, [props.mediaFbPageList]);
  useEffect(() => { // 토큰 등록 해지 되면 모든 값 초기화
    if (!props.isTokenReg) {
      setFbCategory([]);
      setFbAdSets([
        {
          instagramActorId: '', destinationType: 'WEBSITE', customEventType: 'SEARCH', convDomain: '', attributionSpec: 'CLICK_ONE_VIEW_ONE', customAudience: '', exCustomAudience: '',
        },
        {
          instagramActorId: '', destinationType: 'WEBSITE', customEventType: 'SEARCH', convDomain: '', attributionSpec: 'CLICK_ONE_VIEW_ONE', customAudience: '', exCustomAudience: '',
        },
        {
          instagramActorId: '', destinationType: 'WEBSITE', customEventType: 'SEARCH', convDomain: '', attributionSpec: 'CLICK_ONE_VIEW_ONE', customAudience: '', exCustomAudience: '',
        },
      ]);
      props.isFbAdSetError([
        {
          pageId: false, convDomain: false,
        },
        {
          pageId: false, convDomain: false,
        },
        {
          pageId: false, convDomain: false,
        },
      ]);
      $('#categorySelect').val('').trigger('change');
    }
  }, [props.isTokenReg]);
  useEffect(() => {
    $('#adPageSelect, #adInstagramSelect').select2('destroy');
    $('#adPageSelect').select2({
      width: '330px',
      placeholder: '광고를 노출할 페이지를 선택해 주세요.',
    });

    $('#adInstagramSelect').select2({
      width: '330px',
      placeholder: '광고를 노출할 인스타그램 계정을 선택해 주세요.',
    });
  }, [props.fbAdSetErrorChk]);
  // useEffect(() => {
  //   const adPages:any = [];
  //   props.adPageList.forEach((item:any) => {
  //     const filterData:any = props.adsVolumes.filter((f:any) => f.actorId === item.id);
  //     if (filterData.length !== 0) {
  //       adPages.push({ name: `${item.name} (광고 등록 가능 : ${filterData[0].actorName} ${filterData[0].limitOnAdsRunningOrInReview - filterData[0].adsRunningOrInReviewCount})`, id: item.id });
  //     } else {
  //       adPages.push({ name: `${item.name}`, id: item.id });
  //     }
  //   });
  //
  //   setFbPageDatas(adPages);
  // }, [props.adsVolumes]);
  return (
    <Fragment>
      <div className="comp-tab-content selected">
        <div className="tbl">
          <dl className="vertical">
            <dt>
              <div className="dt-inner">
                <span className="fz-16 fc-1">
                  광고 노출 페이지
                  <i className="fz-12 fc-5">*</i>
                </span>
                <button type="button" className="btn btn-primary-outline"onClick={(e) => props.setShowFbPageDialog(true)} disabled={!props.isAdAccountSelect}>페이지 관리</button>
              </div>
            </dt>
            <dd>
              <div className="form-group">
                <div className={`input-group expand ${props.fbAdSetErrorChk[props.makerSetNum - 1].pageId ? 'negative' : ''}`}>
                  <div className="inner-input-group">
                    <input type="text" value={pageName} className="tf-comm" placeholder="페이지 관리 버튼을 통해 선택해 주세요." readOnly={true} disabled={!props.isAdAccountSelect}/>
                  </div>
                </div>
              </div>
            </dd>
          </dl>
          <dl className="vertical">
            <dt>
              <div className="dt-inner">
                <span className="fz-16 fc-1">
                    광고 노출 인스타그램  계정
                </span>
              </div>
            </dt>
            <dd>
              <div className="form-group">
                <select id="adInstagramSelect" {...register('instagramActorId')} className="select2-single-search expand" disabled={!props.isAdAccountSelect}>
                  <option key="0" value="0">선택안함</option>
                  {props.instagramAccountList.map((item : any) => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))}
                </select>
              </div>
            </dd>
          </dl>
          <dl className="vertical">
            <dt>
              <div className="dt-inner">
                <span className="fz-16 fc-1">
                  전환 이벤트 위치
                  <Link to="#" id="destinationTypeTooltip" className="ico-tooltip" title=""></Link>
                  <i className="fz-12 fc-5">*</i>
                </span>
              </div>
            </dt>
            <dd>
              <div className="form-group">
                <select id="destinationTypeSelect" {...register('destinationType')} className={`select2-single expand ${true ? '' : ''}`} disabled={!props.isTokenReg}>
                  <option value="WEBSITE" selected>웹사이트</option>
                  <option value="WEBSITE_APP">웹사이트/앱</option>
                </select>
              </div>
            </dd>
          </dl>
          <dl className="vertical">
            <dt>
              <div className="dt-inner">
                <span className="fz-16 fc-1">
                  전환 이벤트
                  <Link to="#" id="customEventTypeTooltip" className="ico-tooltip" title=""></Link>
                  <i className="fz-12 fc-5">*</i>
                </span>
              </div>
            </dt>
            <dd>
              <div className="form-group">
                <select id="customEventTypeSelect" {...register('customEventType')} className="select2-single expand" disabled={!props.isPixelSelect}>
                  <option value="SEARCH">검색</option>
                  <option value="INITIATED_CHECKOUT">결제 시작</option>
                  <option value="ADD_PAYMENT_INFO">결제 정보 추가</option>
                  <option value="SUBSCRIBE">구독</option>
                  <option value="PURCHASE">구매</option>
                  <option value="DONATE">기부</option>
                  <option value="COMPLETE_REGISTRATION">등록 완료</option>
                  <option value="CONTACT">문의</option>
                  <option value="SCHEDULE">예약</option>
                  <option value="SUBMIT_APPLICATION">요청 제출</option>
                  <option value="ADD_TO_WISHLIST">위시리스트에 추가</option>
                  <option value="FIND_LOCATION">위치 찾기</option>
                  <option value="LEAD">잠재 고객</option>
                  <option value="ADD_TO_CART">장바구니에 담기</option>
                  <option value="CUSTOMIZE_PRODUCT">제품 맞춤 주문</option>
                  <option value="START_TRIAL">체험판 시작</option>
                  <option value="CONTENT_VIEW">콘텐츠 조회</option>
                  {props.customConversionList.map((item : any) => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))}
                </select>
              </div>
            </dd>
          </dl>
          <dl className="vertical">
            <dt>
              <div className="dt-inner">
            <span className="fz-16 fc-1">
              추적 도메인
              <Link to="#" id="convDomainTooltip" className="ico-tooltip" title=""></Link>
              <i className="fz-12 fc-5">*</i>
            </span>
              </div>
            </dt>
            <dd>
              <div className="form-group">
                <div className={`input-group expand ${props.fbAdSetErrorChk[props.makerSetNum - 1].convDomain ? 'negative' : ''}`}>
                  <div className="inner-input-group">
                    <input type="text" {...register('convDomain')} className="tf-comm" placeholder="추적할 도메인을 입력해 주세요." disabled={!props.isAdAccountSelect}/>
                  </div>
                </div>
              </div>
            </dd>
          </dl>
          <dl className="vertical">
            <dt>
              <div className="dt-inner">
                <span className="fz-16 fc-1">
                  기여 설정
                  <Link to="#" id="attributionSpecTooltip" className="ico-tooltip" title=""></Link>
                  <i className="fz-12 fc-5">*</i>
                </span>
              </div>
            </dt>
            <dd>
              <div className="form-group">
                <select id="attributionSpecSelect" {...register('attributionSpec')} className="select2-single expand" disabled={!props.isAdAccountSelect}>
                  <option value="CLICK_ONE_DAYS">클릭 후 1일</option>
                  <option value="CLICK_SEVEN_DAYS">클릭 후 7일</option>
                  <option value="CLICK_ONE_VIEW_ONE" selected>클릭 또는 조회 후 1일</option>
                  <option value="CLICK_SEVEN_VIEW_ONE">클릭 후 7일 또는 조회 후 1일</option>
                </select>
              </div>
            </dd>
          </dl>
          { props.makerSetNum === 2 || props.makerSetNum === 3
            ? <Fragment>
              <dl className="vertical">
                <dt>
                  <div className="dt-inner">
                    <span className="fz-16 fc-1">
                        { props.makerSetNum === 2 ? '맞춤 타겟' : '카테고리 별 맞춤 타겟' }
                    </span>
                  </div>
                </dt>
                <dd>
                  { props.makerSetNum === 3
                    ? <Fragment>
                      <div className="form-group">
                        <span className="comp-txt">
                            <span className="table">
                                <span className="table-cell">
                                    <b className="fz-12 fc-2">카테고리</b>
                                </span>
                            </span>
                        </span>
                      </div>
                      <div className="form-group">
                        <select id="categorySelect" className="select2-single expand">
                          <option></option>
                          <option value="DEFAULT">기본</option>
                          <option value="GAME_HOBBY">게임·취미</option>
                          <option value="DONATION">기부·후원</option>
                          <option value="PARTY">모임</option>
                          <option value="PET">반려동물</option>
                          <option value="BABY_KIDS">베이비·키즈</option>
                          <option value="BEAUTY">뷰티</option>
                          <option value="SOCIAL_CAMPAIGN">소셜·캠페인</option>
                          <option value="SPORTS_MOBILITY">스포츠·모빌리티</option>
                          <option value="TRAVEL_LEISURE">여행·레저</option>
                          <option value="PUBLISHING">출판</option>
                          <option value="CHARACTER_GOODS">캐릭터·굿즈</option>
                          <option value="CULTURE_ARTIST">컬쳐·아티스트</option>
                          <option value="CLASS_CONSULTING">클래스·컨설팅</option>
                          <option value="TECH_DOMESTIC">테크·가전</option>
                          <option value="FASHION">패션·잡화</option>
                          <option value="FOOD">푸드</option>
                          <option value="HOME_LIVING">홈·리빙</option>
                        </select>
                      </div>
                    </Fragment>
                    : <Fragment></Fragment>
                  }
                  <div className="form-group">
                    <span className="comp-txt">
                      <span className="table">
                        <span className="table-cell">
                          <b className="fz-12 fc-2">다음 중 하나 이상에 포함된 사람 포함</b>
                          </span>
                        </span>
                    </span>
                  </div>
                  <div className="form-group">
                    <select id="customAudienceSelect" {...register('customAudience')} className="select2-tags expand" multiple={true}>
                      {props.customAudienceList.map((item : any) => (
                        <option key={item.id} value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <span className="comp-txt">
                      <span className="table">
                        <span className="table-cell">
                          <b className="fz-12 fc-2">다음 중 하나 이상에 포함된 사람 제외</b>
                        </span>
                       </span>
                    </span>
                  </div>
                  <div className="form-group">
                    <select id="exCustomAudienceSelect" {...register('exCustomAudience')} className="select2-tags expand" multiple={true}>
                      {props.customAudienceList.map((item : any) => (
                        <option key={item.id} value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                </dd>
              </dl>
            </Fragment>
            : <Fragment></Fragment>
          }
        </div>
      </div>
    </Fragment>
  );
};

const MngMdFbAdTab = (props : any, ref : any) => {
  const [makerSetNum, setMakerSetNum] = useState(1);
  const [fbAdSets, setFbAdSets] = useState<fbAdSet[]>([
    {
      instagramActorId: '', destinationType: 'WEBSITE', customEventType: 'SEARCH', convDomain: '', attributionSpec: 'CLICK_ONE_VIEW_ONE', customAudience: '', exCustomAudience: '',
    },
    {
      instagramActorId: '', destinationType: 'WEBSITE', customEventType: 'SEARCH', convDomain: '', attributionSpec: 'CLICK_ONE_VIEW_ONE', customAudience: '', exCustomAudience: '',
    },
    {
      instagramActorId: '', destinationType: 'WEBSITE', customEventType: 'SEARCH', convDomain: '', attributionSpec: 'CLICK_ONE_VIEW_ONE', customAudience: '', exCustomAudience: '',
    },
  ]);
  const [fbAdSetErrorChk, isFbAdSetError] = useState([
    {
      pageId: false, convDomain: false,
    },
    {
      pageId: false, convDomain: false,
    },
    {
      pageId: false, convDomain: false,
    },
  ]);
  const [fbAdCategory, setFbCategory] = useState<categoryAudience[]>([]);
  const [isModify, setModify] = useState(false);
  const [modifyCnt, addModify] = useState(0);
  const getAdSetForm = () => {
    isFbAdSetError((prevState) => {
      const updateState = [...prevState];
      for (let i = 0; i < 3; i += 1) {
        updateState[i].pageId = props.mediaFbPageList.filter((v: mediaFbPage) => v.sortOrder == i+1 && v.useYn) <= 0;
        updateState[i].convDomain = fbAdSets[i].convDomain === '';
      }

      return updateState;
    });
    return {
      fbAdSetErrorChk,
      fbAdSets,
      fbAdCategory,
    };
  };
  const setUpdateForm = (modifyForm : fbAdSet[], modifyCategory : categoryAudience[], isReset : boolean) => { // eslint-disable-line no-unused-vars
    setFbAdSets((prevState) => {
      const updateState = [...prevState];
      for (let i = 0; i < 3; i += 1) {
        updateState[i] = modifyForm[i];
      }
      console.log(updateState);
      return updateState;
    });

    setFbCategory(() => {
      const updateState = [];
      for (let i = 0; i < modifyCategory.length; i += 1) {
        updateState[i] = modifyCategory[i];
      }
      return updateState;
    });

    if (makerSetNum > 1) {
      setMakerSetNum(1);
    } else if (!isModify) {
      setModify(true);
    } else if (isReset) {
      addModify(modifyCnt + 1);
    }
  };
  useEffect(() => { // 토큰 등록 해지 되면 모든 값 초기화
    setMakerSetNum(1);
  }, [props.isTokenReg]);
  useImperativeHandle(ref, () => ({
    getAdSetForm,
    setUpdateForm,
  }));
  return (
    <Fragment>
      <div className="col col-7">
        <section className="wrap-section wrap-tbl">
          <div className="box-header">
            <div className="box-tit">
              <h2 className="fz-20 fc-1 fw-bold">광고 세트 설정</h2>
            </div>
            <div className="box-option">

            </div>
          </div>
          <div className="box-body">
            <div className="comp-tab">
              <a className={ `tab ${makerSetNum === 1 ? 'selected' : ''}` } href="#!" onClick={(e) => {
                e.preventDefault();
                setMakerSetNum(1);
              }}>
                <span className="tab-item">메이커 세트</span>
              </a>
              <a className={ `tab ${makerSetNum === 2 ? 'selected' : ''}` } href="#!" onClick={(e) => {
                e.preventDefault();
                setMakerSetNum(2);
              }}>
                <span className="tab-item">와디즈 기본 세트</span>
              </a>
              <a className={ `tab ${makerSetNum === 3 ? 'selected' : ''}` } href="#!" onClick={(e) => {
                e.preventDefault();
                setMakerSetNum(3);
              }}>
                <span className="tab-item">와디즈 확장 세트</span>
              </a>
            </div>
            <SetForm isTokenReg={props.isTokenReg}
                     isAdAccountSelect={props.isAdAccountSelect}
                     isPixelSelect={props.isPixelSelect}
                     makerSetNum={makerSetNum}
                     adPageList={props.adPageList}
                     adsVolumes={props.adsVolumes}
                     instagramAccountList={props.instagramAccountList}
                     customAudienceList={props.customAudienceList}
                     customConversionList={props.customConversionList}
                     setShowFbPageDialog={props.setShowFbPageDialog}
                     mediaFbPageList={props.mediaFbPageList}
                     fbAdSets={fbAdSets}
                     setFbAdSets={setFbAdSets}
                     fbAdCategory={fbAdCategory}
                     setFbCategory={setFbCategory}
                     fbAdSetErrorChk={fbAdSetErrorChk}
                     isFbAdSetError={isFbAdSetError}
                     isModify={isModify}
                     modifyCnt={modifyCnt}/>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default forwardRef(MngMdFbAdTab);
