import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { alertify } from 'alertifyjs'
import { fetchPostUpload, uploadFileToS3 } from '@biz/api'
import { useUploadIdQuery } from '@biz/query'

type ParameterType = {
  file: File
}
type FunctionType = () => UseMutationResult<unknown, unknown, ParameterType, unknown>
export const useUploadMediaMutation: FunctionType = () => {
  const { refetch } = useUploadIdQuery()
  return useMutation({
    mutationFn: async ({ file }) => {
      const { data: uploadId } = await refetch()
      if (!uploadId) {
        // type guard
        return
      }
      const res = await fetchPostUpload({
        uploadId,
        originFileName: file.name,
        myS3Upload: 'creationFb',
      }).catch((e) => {
        alertify.error(`시스템 또는 네트워크 에러로 파일을 등록할 수 없습니다. \n 잠시후 다시 시도해주세요.`)
      })

      if (res?.ok === false) {
        alertify.error((res?.data as { msg: string }).msg)
        return
      }

      const { presignUrl, publicUrl, fileId } = res?.data || {}
      if (!presignUrl) {
        // type guard
        return
      }
      await uploadFileToS3(presignUrl, file)

      if (!publicUrl) {
        // type guard
        return
      }
      return { file, publicUrl, uploadId, fileId }
    },
    onSuccess: (newData) => {
      // if (!!attachedDocuments && !!newData) {
      //   update(attachedDocuments.concat([newData]))
      // }
    },
  })
}
