import { useEffect, useRef } from 'react'
import { Dialog } from 'rc-easyui'
import NumberFormat from 'react-number-format'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query' // try next react-query;
import alertify from 'alertifyjs'
import dayjs from 'dayjs'

import { ApiUtil2 } from '@biz/api'

interface IBzmMngCoupon {
  ok: boolean
  data: any
  makerIds: any
  couponReqCost: number
  expireDate: string
  couponReason: string
}
const BzmMngMakerCouponDialog = (props: any) => {
  const dialogRef = useRef<any>()
  const { getValues, setValue, register } = useForm<IBzmMngCoupon>()
  // 취소 버튼
  const closeCouponDialog = () => {
    props.setCouponDialog(false)
  }
  // 쿠폰 주기 관련
  const confirmApi = () => {
    const date = $('#expireDate').val() as string
    setValue('expireDate', date)
    const { makerIds, couponReqCost, couponReason } = getValues()
    const result = ApiUtil2.post<IBzmMngCoupon>('/api/bzmAdmin/mng/bzmMngMaker/couponReq', {
      makerIds,
      couponReqCost,
      expireDate: date,
      couponReason,
    })
    return result
  }
  const confirmApiEvent = useMutation(confirmApi, {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        // 여기서 계속 수정 처리
        alertify.success('쿠폰 지급을 완료하였습니다.')
        props.setCouponDialog(false)
        props.afterEvent()
      }
    },
  })
  const couponCofirm = () => {
    alertify
      .confirm('쿠폰을 지급하시겠습니까?', () => {
        confirmApiEvent.mutate()
      })
      .set({ labels: { cancel: '취소', ok: '확인' } })
      .setHeader('')
  }
  useEffect(() => {
    setValue('makerIds', props.couponMakerIds)
  })

  // 유효 기간 관련
  useEffect(() => {
    $('.datepicker-single').daterangepicker({
      singleDatePicker: true,
      locale: {
        format: 'YYYY-MM-DD',
      },
      startDate: dayjs().add(30, 'days').format('YYYYMMDD'),
      mindate: dayjs().startOf('day'),
    })
    return () => {
      // 달력관련 모든걸 remove 해줘야 한다 한페이지에서 동작하는거라 이거 안해주면 달력 관련 소스가 계속 늘어남
      $('.daterangepicker').remove()
    }
  }, [])

  return (
    <>
      <form>
        <Dialog title="쿠폰 지급" modal resizable shadow={false} ref={dialogRef} style={{ width: '900px' }}>
          <div className="dialog">
            <div className="dialog-body">
              <div className="wrap-section wrap-tbl">
                <div className="box-body">
                  <div className="tbl">
                    <dl>
                      <dt>
                        <div className="dt-inner">
                          <span className="fz-16 fc-1">지급 대상</span>
                        </div>
                      </dt>
                      <dd>
                        <div className="form-group">
                          <div className="input-group expand">
                            <div className="inner-input-group">
                              <input type="text" className="tf-comm" value={props.couponTitle} disabled />
                            </div>
                            <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                          </div>
                        </div>
                      </dd>
                    </dl>
                    <dl>
                      <dt>
                        <div className="dt-inner">
                          <span className="fz-16 fc-1">지급 금액</span>
                        </div>
                      </dt>
                      <dd>
                        <div className="form-group">
                          <div className="input-group expand">
                            <div className="inner-input-group">
                              <NumberFormat
                                id="couponReqCost"
                                className="tf-comm tf-unit"
                                thousandSeparator={true}
                                placeholder="1원~1억 원 사이로 입력해 주세요."
                                maxLength={11}
                                allowNegative={false}
                                onChange={(e: any) => {
                                  setValue('couponReqCost', Number(e.target.value.replaceAll(',', '')))
                                }}
                              />
                              <span className="fz-14 fc-1">원</span>
                            </div>
                            <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                          </div>
                        </div>
                      </dd>
                    </dl>
                    <dl>
                      <dt>
                        <div className="dt-inner">
                          <span className="fz-16 fc-1">유효 기간</span>
                        </div>
                      </dt>
                      <dd>
                        <div className="form-group">
                          <div className="comp-datepicker expand">
                            <div className="inner-datepicker">
                              <i className="ico ico-calendar"></i>
                              <input type="text" className="tf-comm datepicker-single" id="expireDate" readOnly />
                            </div>
                          </div>
                        </div>
                      </dd>
                    </dl>
                    <dl>
                      <dt>
                        <div className="dt-inner">
                          <span className="fz-16 fc-1">지급 사유</span>
                        </div>
                      </dt>
                      <dd>
                        <div className="form-group">
                          <div className="comp-radio block">
                            <input
                              type="radio"
                              id="inp-radio-09"
                              value="COUPON_BY_FIRST_PROMOTION"
                              {...register('couponReason')}
                            />
                            <label htmlFor="inp-radio-09">첫 광고 프로모션</label>
                          </div>
                          <div className="comp-radio block">
                            <input
                              type="radio"
                              id="inp-radio-10"
                              value="COUPON_BY_COMPENSATION"
                              {...register('couponReason')}
                            />
                            <label htmlFor="inp-radio-10">보상</label>
                          </div>
                          <div className="comp-radio block">
                            <input
                              type="radio"
                              id="inp-radio-11"
                              value="COUPON_BY_PROMOTION"
                              {...register('couponReason')}
                            />
                            <label htmlFor="inp-radio-11">프로모션</label>
                          </div>
                          <div className="comp-radio block">
                            <input
                              type="radio"
                              id="inp-radio-12"
                              value="COUPON_BY_POST_PAYMENT"
                              {...register('couponReason')}
                            />
                            <label htmlFor="inp-radio-12">후불 정산</label>
                          </div>
                          <div className="comp-radio block">
                            <input
                              type="radio"
                              id="inp-radio-14"
                              value="SUCCESS_BOOSTING_TARGET_PROMOTION"
                              {...register('couponReason')}
                            />
                            <label htmlFor="inp-radio-14">성공 부스팅 72시간 타겟광고 프로모션</label>
                          </div>
                          <div className="comp-radio block">
                            <input
                              type="radio"
                              id="inp-radio-13"
                              value="COUPON_BY_ETC"
                              {...register('couponReason')}
                              defaultChecked={true}
                            />
                            <label htmlFor="inp-radio-13">기타 (알림톡 미발송)</label>
                          </div>
                        </div>
                      </dd>
                    </dl>
                    <dl className="vertical">
                      <dd>
                        <div className="form-group">
                          <div className="comp-help">
                            <ul className="help-list">
                              <li className="list">
                                <span className="fz-14 fc-4 bullet">
                                  지급은 메이커의 활성 충전 사업자 번호로 지급됩니다.
                                </span>
                              </li>
                              <li className="list">
                                <span className="fz-14 fc-4 bullet">
                                  활성 충전 사업자 번호를 선택하지 않은 메이커의 경우 메이커 계정에 임의 지급된 후
                                  메이커가 충전 사업자 번호를 선택하는 시점에 활성 사업자 번호로 지급 됩니다. (메이커
                                  계정으로 지급된 쿠폰은 환수 처리)
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div className="dialog-footer">
                <button type="button" className="btn btn-secondary-outline large" onClick={closeCouponDialog}>
                  취소
                </button>
                <button type="button" className="btn btn-primary large" onClick={couponCofirm}>
                  확인
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </form>
    </>
  )
}
export default BzmMngMakerCouponDialog
