import React, { Fragment, useEffect, useState } from 'react';

const BellAlertDetail = (props:any) => {
  const [text, setText] = useState('');

  useEffect(() => {
    $('#bellAlertDetailDialog').dialog({
      width: 900, title: '알림', closeOnEscape: true,
    });
    $('#close').click(() => {
      $('#bellAlertDetailDialog').dialog('close');
      props.setIsClicked(false);
    });
    return () => {
      $('#bellAlertDetailDialog').dialog('destroy');
    };
  }, []);

  useEffect(() => {
    $('#bellAlertDetailDialog').dialog('open');
    setText(props.text);
  }, [props.bellAlertId]);

  return (
        <Fragment>
            <div>
                <div id="bellAlertDetailDialog" style={{ display: 'none' }}>
                    <div className="dialog-body">
                        <ul className="box-body" style={{ minHeight: 500 }}>
                            <b className="fz-16 fc-1">
                                {text.split('\n').map((txt, index) => {
                                        let returnCreationId = null;
                                        if (txt.includes('소재. ')) {
                                            console.log('반려 소재 케이스');
                                            returnCreationId = txt.substring(txt.indexOf('소재. ') + 4, txt.indexOf(' 가'));
                                            console.log(returnCreationId);
                                        }
                                        if (returnCreationId != null) {
                                            const href = `/mngCamp/mngCamp?srchType=creationId&keyword=${returnCreationId}`;
                                            txt = txt.replace(returnCreationId, `<a href="${href}">${returnCreationId}</a>`);
                                            return (
                                                <div key={index}>
                                                    <span dangerouslySetInnerHTML={{ __html: txt }} />
                                                    <br />
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div key={index}>
                                                    {txt}
                                                    <br />
                                                </div>
                                            );
                                        }
                                    }
                                )}</b>
                        </ul>
                    </div>
                    <div className="dialog-footer">
                        <button type="button" className="btn btn-secondary-outline" id="close">닫기</button>
                    </div>
                </div>
            </div>
        </Fragment>
  );
};

export default BellAlertDetail;
