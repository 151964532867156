import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { Dialog } from 'rc-easyui';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import AccAdjustDialogTaxbillDetail from './AccAdjustDialogTaxbillDetail';
import { ApiUtil2, ApiUtil3 } from '@biz/api'
import alertify from 'alertifyjs'

interface IRegTaxbillForm {
  adjustId?: number|undefined;
  plusTargetId?: number|undefined;
  plusComment?: string;
  plusSupDate?: string;
  plusRegDate?: string;
  plusSendDate?: string;
  minusTargetId?: number|undefined;
  minusComment?: string;
  minusSupDate?: string;
  minusRegDate?: string;
  minusSendDate?: string;
}

const AccAdjustDialogTaxbill = (props: any) => {
  const navigate = useNavigate();

  // 현재 dialog 접근 경로
  const { source } = props;

  // dialog
  const dialogRef = useRef<any>();

  // tab 요소
  const [selectedTab, setSelectedTab] = useState<string>('plus');
  const [plusInfo, setPlusInfo] = useState();
  const [minusInfo, setMinusInfo] = useState();

  // form
  const {
    register, setValue, getValues,
  } = useForm<IRegTaxbillForm>();

  const changeTab = (tabName: string) => {
    // if (tabName === 'plus') {
    //   // fixme. do something.
    // } else {
    //   // fixme. do something.
    // }
    setSelectedTab(tabName);
  };

  const moveToCenter = () => {
    dialogRef.current.center();
  };

  // 취소 버튼
  const closeDialog = () => {
    // $('#accAdjustRegDialogTaxbill').dialog('close');
    props.setTaxbillDialog(false);

    // window.console.table(location);
    if (source === 'regAdjust') {
      navigate(`/accAdjust/mng/info?id=${props.accAdjustId}`);
    }
  };

  const validate = (data: any) => {
    const todayDate = dayjs().startOf('day').format('YYYYMMDD');
    if (plusInfo !== undefined) {
      if (data.plusSupDate === undefined || data.plusRegDate === undefined || data.plusSendDate === undefined) {
        throw new Error('공급일자, 작성일자, 발행일자는 필수 입력 항목입니다.');
      }
      if (data!.plusSupDate > todayDate) {
        throw new Error('공급일자는 당일보다 미래 일자를 입력할 수 없습니다..');
      }
      if (data!.plusSupDate > data!.plusRegDate || data!.plusSupDate > data!.plusSendDate) {
        throw new Error('공급일자는 작성일자나 발행일자보다 미래 일자를 입력할 수 없습니다.');
      }
      if (data!.plusRegDate > data!.plusSendDate) {
        throw new Error('작성일자는 발행일자보다 미래 일자를 입력할 수 없습니다');
      }
      if (data!.plusRegDate < todayDate) {
        throw new Error('발행일자는 당일보다 미래 일자를 입력할 수 없습니다.');
      }
      if (data.plusComment === undefined || data.plusComment.length < 1) {
        throw new Error('세금계산서 항목을 기입해 주세요.');
      }
    }

    if (minusInfo !== undefined) {
      if (data.minusSupDate === undefined || data.minusRegDate === undefined || data.minusSendDate === undefined) {
        throw new Error('공급일자, 작성일자, 발행일자는 필수 입력 항목입니다.');
      }
      if (data!.minusSupDate > todayDate) {
        throw new Error('공급일자는 당일보다 미래 일자를 입력할 수 없습니다..');
      }
      if (data!.minusSupDate > data!.minusRegDate || data!.minusSupDate > data!.minusSendDate) {
        throw new Error('공급일자는 작성일자나 발행일자보다 미래 일자를 입력할 수 없습니다.');
      }
      if (data!.minusRegDate > data!.minusSendDate) {
        throw new Error('작성일자는 발행일자보다 미래 일자를 입력할 수 없습니다');
      }
      if (data!.minusRegDate < todayDate) {
        throw new Error('발행일자는 당일보다 미래 일자를 입력할 수 없습니다.');
      }
      if (data.minusComment === undefined || data.minusComment.length < 1) {
        throw new Error('세금계산서 항목을 기입해 주세요.');
      }
    }
  };

  // 확인 버튼
  const regTaxbill = () => {
    const data = { ...getValues() };
    window.console.table(data);

    try {
      validate(data);
    } catch (ex: any) {
      alertify.error(ex.message);
      return;
    }

    alertify.confirm('세금계산서를 발행 하시겠습니까?<br><br>'
      + '* 세금계산서 발행 후 취소를 하셔도 임의조정 진행은 취소 되지 않습니다.<br>'
      + '* 금액이동을 하신 경우 가산/감산 세금계산서 2장이 발행되게 됩니다.'
      + ' 가산/감산 탭의 기입한 내용을 꼭 확인해 주세요.', () => {
      const url = `/api/accAdjust/taxbill/send${source === 'taxbill' ? 'ByTaxbill' : ''}`;
      ApiUtil3.post(url, data).then((res) => {
        const commonLoader = document.querySelector('.loader');
        if (commonLoader !== null) commonLoader.classList.remove('none');
        if (res.data.ok === true && res.data.data === 0) {
          alertify.success('세금계산서 발행이 정상적으로 진행 되었습니다.', 3, () => {
            if (commonLoader !== null) commonLoader.classList.add('none');
            closeDialog();
          });
        } else {
          alertify.error('세금계산서 발행이 실패한 건이 있습니다.', 3, () => {
            if (commonLoader !== null) commonLoader.classList.add('none');
            closeDialog();
          });
        }
      });
    }).set({ labels: { cancel: '취소', ok: '발행' } }).setHeader('');
  };

  const loadAccAdjust = () => {
    // window.console.log(`임의조정 ID >> ${props.accAdjustId}`);
    ApiUtil2.get('/api/accAdjust/reg/adjustInfo', {
      params: {
        id: props.accAdjustId,
      },
    }).then((resp) => {
      const adjustInfo = resp.data.data;
      setValue('adjustId', props.accAdjustId);
      if (adjustInfo.plus != null) {
        setValue('plusTargetId', adjustInfo.plus.adjustTargetId);
        setPlusInfo(adjustInfo.plus);
      } else {
        setValue('plusTargetId', undefined);
        setPlusInfo(undefined);
      }
      if (adjustInfo.minus != null) {
        setValue('minusTargetId', adjustInfo.minus.adjustTargetId);
        setMinusInfo(adjustInfo.minus);
      } else {
        setValue('minusTargetId', undefined);
        setMinusInfo(undefined);
      }
      setSelectedTab(adjustInfo.adjustDiv === 'minus' ? 'minus' : 'plus');
    });
  };

  const loadTaxbillInfo = () => {
    ApiUtil2.get('/api/accAdjust/taxbill/info', {
      params: {
        id: props.adjustTargetId,
      },
    }).then((resp) => {
      const adjustInfo = resp.data.data;
      setValue('adjustId', props.accAdjustId);
      if (adjustInfo.adjustDiv === 'plus') {
        setValue('plusTargetId', adjustInfo.adjustTargetId);
        setPlusInfo(adjustInfo);
      } else {
        setValue('minusTargetId', adjustInfo.adjustTargetId);
        setMinusInfo(adjustInfo);
      }
      setSelectedTab(adjustInfo.adjustDiv === 'minus' ? 'minus' : 'plus');
    });
  };

  useEffect(() => {
    // 세금계산서 발행에 필요한 정보 가져오기
    if (source === 'taxbill') {
      // 세금계산서 신규 발행 접근
      loadTaxbillInfo();
    } else {
      // 임의조정 등록, 임의조정 상세 화면에서 세금계산서 발행 접근
      loadAccAdjust();
    }
  }, []);

  return (
    <Fragment>
      <form>
        <Dialog title="세금계산서 정보 입력" modal resizable shadow={false} ref={dialogRef} style={{ width: '1200px' }}>
          <div className="dialog">
            <div className="dialog-body">
              {/* Comp-Tab : Start */}
              <div className="comp-tab">
                <button className={`tab ${selectedTab === 'plus' ? 'selected' : ''}`} style={{ display: `${plusInfo === undefined ? 'none' : ''}` }} onClick={() => changeTab('plus')}>
                  <span className="tab-item">가산대상</span>
                </button>
                <button className={`tab ${selectedTab === 'minus' ? 'selected' : ''}`} style={{ display: `${minusInfo === undefined ? 'none' : ''}` }} onClick={() => changeTab('minus')}>
                  <span className="tab-item">감산대상</span>
                </button>
              </div>
              {
                plusInfo === undefined || <AccAdjustDialogTaxbillDetail selectedTab={selectedTab} adjustDiv={'plus'} register={register} setValue={setValue} adjustDetail={plusInfo} moveToCenter={moveToCenter} />
              }
              {
                minusInfo === undefined || <AccAdjustDialogTaxbillDetail selectedTab={selectedTab} adjustDiv={'minus'} register={register} setValue={setValue} adjustDetail={minusInfo} moveToCenter={moveToCenter} />
              }
            </div>
            <div className="dialog-footer">
              <button type="button" className="btn btn-secondary-outline large" onClick={() => closeDialog()}>취소
              </button>
              <button type="button" className="btn btn-primary large" onClick={regTaxbill}>발행</button>
            </div>
          </div>
        </Dialog>
      </form>
    </Fragment>
  );
};

export default AccAdjustDialogTaxbill;
