import { useQuery, type QueryClient } from '@tanstack/react-query'
import { fetchCreationEditingCheck } from '@biz/api'
import { useCurrentCampaignIdQuery, loadCurrentCampaignId } from '../useCurrentCampaignIdQuery'

const queryKey = ['@biz/query/createTargetAd/CreationEditingCheck']
export const useCreationEditingCheckQuery = () => {
  const { data: campaignId } = useCurrentCampaignIdQuery()
  return useQuery({
    queryKey: queryKey,
    queryFn: () =>
      !!campaignId ? fetchCreationEditingCheck(campaignId).then((res) => res.data) : Promise.reject(false),
    enabled: Boolean(campaignId),
  })
}

export const loadCreationEditingCheck = (queryClient: QueryClient) => async () => {
  const campaignId = (await loadCurrentCampaignId(queryClient)()) as number
  return await queryClient.fetchQuery({
    queryKey,
    queryFn: () => fetchCreationEditingCheck(campaignId).then((res) => res.data),
  })
}
