import { useQuery, type UseQueryResult, type QueryClient } from '@tanstack/react-query'
import { fetchProjectList, type ProjectInfoType } from '@biz/api'

const makeQueryKey = () => ['@libs/biz/query/ProjectSelector/ProjectList']
const makeQueryOption = () => ({
  queryKey: makeQueryKey(),
  queryFn: () => fetchProjectList(),
})

type UseFunctionType = () => UseQueryResult<ProjectInfoType[]>
export const useProjectListQuery: UseFunctionType = () => useQuery(makeQueryOption())

type LoadFunctionType = (queryClient: QueryClient) => () => Promise<ProjectInfoType[]>
export const loadProjectList: LoadFunctionType = (queryClient) => async () =>
  await queryClient.fetchQuery(makeQueryOption())
