import { ReactComponent as StartIcon } from '@common/svg/star.svg'
import { TooltipHelp } from './TooltipHelp'
import styles from './CampaignCaption.module.scss'

type CampaignCaptionFunction = {
  children: React.ReactNode
  tooltip?: React.ReactNode
  required?: boolean
}
export const CampaignCaption: React.FC<CampaignCaptionFunction> = ({ children, tooltip, required = true }) => (
  <dt className={styles.containCampaignCaption}>
    <div className={styles.title}>
      {children}
      {tooltip && <TooltipHelp tooltip={tooltip} />}
      {required && <StartIcon />}
    </div>
  </dt>
)
