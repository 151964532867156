import { useState } from 'react'
import { Modal, Button } from 'antd'
import { type ImageMetaDataType } from '@biz/query'
import { ImageList } from './ImageList'
import { PreviewPanel } from './PreviewPanel'
import { MediaUploader } from './MediaUploader'
import styles from './ImagePickerModal.module.scss'

type ImagePickerModalFunction = {
  isOpen: boolean
  isSlideImageOnly: boolean
  onClose: () => void
  onConfirm: (data: ImageMetaDataType) => void
}
export const ImagePickerModal: React.FC<ImagePickerModalFunction> = ({
  isOpen,
  isSlideImageOnly,
  onClose,
  onConfirm,
}) => {
  const [selectedImage, setSelectedImage] = useState<ImageMetaDataType | null>(null)
  return (
    <Modal
      width={1200}
      open={isOpen}
      closable={false}
      title={null}
      footer={[
        <div className={styles.modalFooter} key={'footer'}>
          <Button type="default" block size="large" onClick={onClose} style={{ width: 200 }}>
            {'취소'}
          </Button>
          <Button
            type="primary"
            block
            size="large"
            onClick={() => {
              if (!!selectedImage) {
                onConfirm(selectedImage)
              }
            }}
            style={{ width: 200 }}
            disabled={selectedImage?.isFetching}
          >
            {'제출'}
          </Button>
        </div>,
      ]}
      className={styles.ImagePickerModal}
    >
      <div className={styles.customBody}>
        <ImageList onSelect={setSelectedImage} />

        <section className={styles.previewPanel}>
          <div>
            <Button
              href="https://bizstatic.wadiz.kr/static/usingGuide/%E1%84%8B%E1%85%AA%E1%84%83%E1%85%B5%E1%84%8C%E1%85%B3+%E1%84%80%E1%85%AA%E1%86%BC%E1%84%80%E1%85%A9+%E1%84%89%E1%85%B5%E1%86%B7%E1%84%89%E1%85%A1+%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%8E%E1%85%A2%E1%86%A8.pdf"
              target="_blank"
              type="primary"
              className={styles.creativeGuideButton}
            >
              {'소재 가이드'}
            </Button>
          </div>
          <PreviewPanel mediaData={selectedImage} />
          <MediaUploader isSlideOnly={isSlideImageOnly} value={selectedImage} onChange={setSelectedImage} />
        </section>
      </div>
    </Modal>
  )
}
