import React, {Fragment, useEffect} from 'react';

const PomLimitAdjustment = () => {
    return (
        <Fragment>
            <div className="wrap-section wrap-tbl">
                {/*<div className="box-header">*/}
                {/*    <div className="box-tit">*/}
                {/*        <span className="fz-16 fc-1">*/}
                {/*            한도액 조정*/}
                {/*        </span>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="box-body">
                    <div className="tbl">
                        <dl>
                            <dt>
                                <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        한도액
                                    </span>
                                </div>
                            </dt>
                            <dd>
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="inner-input-group">
                                            <span className="fz-16 fc-1">
                                            <input type="number" step="1" className="tf-comm"
                                                   placeholder="숫자만 입력해 주세요."
                                                   id="pomLimitValue"/> 만원
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        조정사유
                                    </span>
                                </div>
                            </dt>
                            <dd>
                                <div className="form-group">
                                    <div className="input-group small">
                                        <div className="inner-input-group">
                                                        <textarea className="tf-textarea" id="comment_" maxLength={500}
                                                                  style={{width: 500, minWidth: 500}}
                                                        />
                                        </div>
                                        <p className="fz-12 fc-3"><span className="fc-2" id='byteCount_'>0</span> / 500
                                        </p>
                                    </div>
                                </div>
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default PomLimitAdjustment;