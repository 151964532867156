import { Fragment, useMemo, useRef, useState, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import dayjs from 'dayjs'
import { Modal } from 'antd'
import { ApiUtil2 } from '@biz/api'
import type { CreationDetail } from '@biz/api'
import { useAIInspectionHistoryForMakerQuery, useAIInspectionHistoryQuery } from '@biz/query'
import NoDataTemplate from '../../common/NoDataTemplate'
import { ReactComponent as CloseIcon } from './assets/close-icon.svg'
import CreationDataInspHisPreviewDialog from './CreationDataInspHisPreviewDialog'
import CreationDataInspHisCustomTooltip from './CreationDataInspHisCustomTooltip'
import { FailReasonViewModal } from './FailReasonViewModal'
import styles from './CreationInspectionHistoryModal.module.scss'

const WabizInspStatusMap: any = {
  ing: '검수요청',
  ok: '승인',
  fail: '반려',
  ok_ai: '승인',
  ing_ai_fail: '반려',
  ing_ai_ok: '승인',
  stop_by_admin: '강제중단',
}
const CreationTypeMap: any = {
  image: '이미지',
  video: '영상',
  slide: '슬라이드',
}

const REVIEWER_TYPE: {
  AI: string
  AD_ADMIN: string
  NONE: string
} = {
  AI: 'AI',
  AD_ADMIN: '광고 관리자',
  NONE: '', // 검수 진행 중인 경우
}

const timeRenderer = (props: ICellRendererParams) => {
  if (props.value === undefined || props.value === null) return '-'
  else return dayjs(props.value).format('YY-MM-DD HH:mm:ss')
}

interface CreationDataInspHisDialogProps {
  isOpen: boolean
  searchType: 'admin' | 'maker'
  creationDataId: string | number
  projectId?: string
  setShowCreationDataInspHisDialog: React.Dispatch<React.SetStateAction<boolean>>
}

/**
 * 소재 검수 이력 모달
 */
export const CreationInspectionHistoryModal = ({
  isOpen,
  searchType,
  creationDataId,
  projectId,
  setShowCreationDataInspHisDialog,
}: CreationDataInspHisDialogProps) => {
  // 메이커용 AI 소재 검수 이력 (legacy)
  const {
    data: makerAiInspectionHistory,
    isSuccess: isSuccessMakerAiHistory,
    invalidateQuery: invalidateMakerAiHistoryQuery,
  } = useAIInspectionHistoryForMakerQuery(creationDataId, searchType)
  // 관리자용 AI 소재 검수 이력
  const {
    data: AdminAiInspectionHistory,
    isSuccess: isSuccessAdminAiHistory,
    invalidateQuery: invalidateAdminAiHistoryQuery,
  } = useAIInspectionHistoryQuery(creationDataId, searchType)

  // 현재 searchType에 해당하는 쿼리의 data를 사용
  const aiInspectionHistory = searchType === 'admin' ? AdminAiInspectionHistory : makerAiInspectionHistory
  const isSuccessAIHistory = searchType === 'admin' ? isSuccessAdminAiHistory : isSuccessMakerAiHistory
  const invalidateAIHistoryQuery =
    searchType === 'admin' ? invalidateAdminAiHistoryQuery : invalidateMakerAiHistoryQuery

  const gridRef = useRef<AgGridReact>(null)
  const [rowData, setRowData] = useState<any[]>([])

  // 소재 미리보기 다이얼로그
  const [showCreationDataPreviewDialog, setShowCreationDataPreviewDialog] = useState<boolean>(false)
  const [previewData, setPreviewData] = useState<any>('')

  // 소재 사유 다이얼로그
  const [isOpenFailReasonViewModal, setIsOpenFailReasonViewModal] = useState(false)
  const [failReason, setFailReason] = useState<string>('')
  const [reviewerType, setReviewerType] = useState<keyof typeof REVIEWER_TYPE>('NONE')

  // 소재 중단 상태 여부
  const [isSuspendedStatus, setIsSuspendedStatus] = useState(false)

  const setCreationDataPreviewParams = (data: any) => {
    setPreviewData(data)
    setShowCreationDataPreviewDialog(true)
  }

  // 소재 미리보기 다이얼로그파람값
  const previewRenderer = (props: ICellRendererParams) => {
    return props.data.reviewerType === 'AI' || props.data.wabizInspStatus === 'ing' ? (
      <span>
        <button
          id={`preview${props.data.id}`}
          className="btn btn-primary xsmall"
          onClick={() => setCreationDataPreviewParams(props.data)}
        >
          소재 보기
        </button>
      </span>
    ) : null
  }

  // AI 검수인 경우 해당 이력
  const getMatchedAIHistory = (data: ICellRendererParams['data']) => {
    if (data.reviewerType === 'AI' && isSuccessAIHistory) {
      const matchedAIHistory = aiInspectionHistory?.find(
        (aiHistory) => aiHistory.creationDataGptHisId === data.creationDataGptHisId
      )
      return matchedAIHistory
    }
  }

  // 반려 사유 보기
  const handleReasonButtonClick = (data: any) => {
    const aiReason = getMatchedAIHistory(data)?.comments

    setFailReason(aiReason ?? data.wabizInspMsg)
    setReviewerType(data.reviewerType)
    setIsSuspendedStatus(data.wabizInspStatus === 'stop_by_admin')
    setIsOpenFailReasonViewModal(true)
  }

  const columnDefs = [
    {
      field: 'reviewerType',
      headerName: '검수자',
      cellRenderer: (props: ICellRendererParams) => {
        return REVIEWER_TYPE[props.value as keyof typeof REVIEWER_TYPE] ?? ''
      },
    },
    {
      field: 'creationType',
      headerName: '유형',
      cellRenderer: (props: ICellRendererParams) => {
        return CreationTypeMap[props.value]
      },
    },
    {
      field: 'wabizInspStatus',
      headerName: '상태',
      cellRenderer: (props: ICellRendererParams) => {
        return WabizInspStatusMap[props.value]
      },
    },
    {
      field: 'wabizInspReqTime',
      headerName: '검수요청일시',
      cellRenderer: timeRenderer,
    },
    {
      field: 'wabizInspConfirmTime',
      headerName: '검수일시',
      cellRenderer: timeRenderer,
    },
    {
      field: 'wabizInspMsg',
      headerName: '사유 보기',
      cellRenderer: (props: ICellRendererParams) => {
        const matchedAIHistory = getMatchedAIHistory(props.data)
        const gptInspStatus = matchedAIHistory?.gptInspStatus
        const wabizInspStatus = props.data.wabizInspStatus

        const isRejectedStatus =
          gptInspStatus === 'fail' || wabizInspStatus?.includes('fail') || wabizInspStatus === 'stop_by_admin'

        return isRejectedStatus ? (
          <span>
            <button
              id={`inspMsg-${props.data.id}`}
              className="btn btn-primary xsmall"
              onClick={() => handleReasonButtonClick(props.data)}
            >
              {wabizInspStatus === 'stop_by_admin' ? '중단' : '반려'} 사유 보기
            </button>
          </span>
        ) : null
      },
    },
    {
      field: 'preview',
      headerName: '비고',
      cellRenderer: previewRenderer,
    },
  ]

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      // resizable: false,
      suppressMovable: true,
      sortable: false,
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      tooltipComponent: CreationDataInspHisCustomTooltip,
    }),
    []
  )

  const handleClose = () => {
    setShowCreationDataInspHisDialog(false)
  }

  const loadData = () => {
    // 관리자용
    if (searchType === 'admin') {
      ApiUtil2.get(`/api/admin/campaigns/creations/${creationDataId}/inspection-history`).then((resp) => {
        setRowData(resp.data.data)
      })
    } else {
      if (projectId) {
        // 메이커용
        ApiUtil2.get('/api/camp/make02/getInspHis', {
          params: {
            creationDataId: creationDataId,
            projectId: projectId,
          },
        }).then((resp) => {
          setRowData(resp.data.data)
        })
      }
    }
  }

  useEffect(() => {
    // 검수 이력 모달 열 때마다 새로 갱신
    if (isOpen) {
      invalidateAIHistoryQuery()
      loadData()
    }
  }, [isOpen])

  const classNames = {
    body: styles.body,
    mask: styles.mask,
    header: styles.header,
    footer: styles.footer,
    content: styles.content,
  }

  return (
    <Fragment>
      {/* 소재 보기 모달 */}
      {showCreationDataPreviewDialog && (
        <CreationDataInspHisPreviewDialog
          previewData={previewData}
          setShowCreationDataPreviewDialog={setShowCreationDataPreviewDialog}
        />
      )}

      {/* 사유 보기 모달 */}
      <FailReasonViewModal
        isOpen={isOpenFailReasonViewModal}
        reason={failReason}
        reviewerType={reviewerType}
        isSuspendedStatus={isSuspendedStatus}
        handleClose={() => setIsOpenFailReasonViewModal(false)}
      />

      <form>
        <Modal
          open={isOpen}
          title="검수 이력"
          footer={null}
          width={1200}
          className={styles.container}
          classNames={classNames}
          closeIcon={<CloseIcon />}
          onCancel={handleClose}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            rowHeight={48}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            domLayout={'autoHeight'}
            noRowsOverlayComponent={NoDataTemplate}
            onGridReady={loadData}
            tooltipShowDelay={0}
            tooltipHideDelay={100}
          />
        </Modal>
      </form>
    </Fragment>
  )
}
