import React, {
  Fragment, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import dayjs from 'dayjs';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridSizeChangedEvent, ICellRendererParams } from 'ag-grid-community';
import { useForm } from 'react-hook-form';
import qs from 'qs';
import { useOutletContext } from 'react-router';
import alertify from 'alertifyjs'
import Pagination from '../common/Pagination';
import RptSrchFilter from '../rpt/common/RptSrchFilter';
import RptAnalysisFilter from '../rpt/common/RptAnalysisFilter';
import { ApiUtil2 } from '@biz/api'
import RptMetric from '../rpt/common/RptMetric';
import NoDataTemplate from '../common/NoDataTemplate';
import { ApiUtil } from '@biz/api'
import RptTotalDown from '../rpt/common/RptTotalDown';
import DlDialog from "../mngMakerAccount/DlDialog";

interface RptMakerChartReq01 {
    startDate: string;
    endDate: string;
    rptDateDiv: 'day'|'week'|'month'|'total';
    rptItemDiv: 'media'|'adSet'|'device'|'gender'|'age'|'region';
    chartData01: 'cost'|'fees'|'impCnt'|'clickCnt'|'ctr'|'cpc'|'convCnt'|'convRate'|'convByCost'|'convCa'|'roas'|'cpm'|'viewImp';
    chartData02: 'cost'|'fees'|'impCnt'|'clickCnt'|'ctr'|'cpc'|'convCnt'|'convRate'|'convByCost'|'convCa'|'roas'|'cpm'|'viewImp';
    isStart: boolean;
}

interface RptMakerChartData {
    basicDate: string;
    rptItemDiv: string;
    cost: number;
    fees: number;
    impCnt: number;
    clickCnt: number;
    ctr: number;
    cpc: number;
    convCnt: number;
    convRate: number;
    convByCost: number;
  convCa: number;
    roas: number;
    cpm: number;
    viewImp: number;
}

interface RptMakerChartDataList {
    ok: boolean;
    data: RptMakerChartData[];
}

interface MakerRptGrid {
    projectDiv3:any[],
    campViewStatus:any[],
  mediaDiv5:any[],
    startDate:any,
    endDate:any,
    compareStartDate:any,
    compareEndDate:any,
    searchDiv:string,
    searchTxt:any[],
}

interface MakerRptGridData {
    ok: boolean;
}

interface MakerRptGridDataList {
    ok: boolean;
    data: MakerRptGridData[];
}

interface IRptMetric {
    rptMetricId: number;
    memberId: number;
    groupNm: string;
    metricList: string;
    srchYn: boolean;
}

interface IRptMetricOne {
    ok: boolean;
    data: IRptMetric;
}

interface IRptDim {
    rptDimId: number;
    memberId: number;
    groupNm: string;
    dimList: string;
    srchYn: boolean;
}

interface IRptDimOne {
    ok: boolean;
    data: IRptDim;
}

const metricDivEnum:any = {
  campBudget: '예산', wadizFee: '수수료 (VAT 제외)', adspend: '비용 (VAT 제외)', impCnt: '노출', clickCnt: '클릭', clickRate: '클릭률 (CTR)', avgClickAdspend: '클릭당 비용 (CPC)', convCnt: '전환', convRate: '전환율', avgConvAdspend: '전환당 비용', convCa: '전환 매출', rvnRate: '수익률 (ROAS)', cpm: 'CPM', frequency: '게재빈도',
};

const dimDivEnum:any = {
  projectName: '프로젝트', projectDiv4: '프로젝트 유형', adSetName: '광고세트', creationLinkId: '광고소재ID', creationLinkName: '광고소재', mediaDiv5: '노출매체', device: '노출기기', gender: '성별', age: '나이', region: '지역',
};

const gridColumnDef = [
  {
    headerName: '일자',
    field: 'basicDate',
    minWidth: 120,
  },
  {
    headerName: '메이커명',
    field: 'makerNm',
    minWidth: 150,
  },
  {
    headerName: '메이커ID',
    field: 'makerId',
    minWidth: 150,
  },
  {
    headerName: '프로젝트',
    field: 'projectId',
    minWidth: 120,
  },
  {
    headerName: '프로젝트 유형',
    field: 'projectDiv3',
    minWidth: 200,
  },
  {
    headerName: '캠페인명',
    field: 'campNm',
    minWidth: 250,
  },
  {
    headerName: '지불 방식',
    field: 'payType',
    minWidth: 250,
  },
  {
    headerName: '캠페인 상태',
    field: 'campViewStatus',
    minWidth: 150,
  },
  {
    headerName: '캠페인 시작일',
    field: 'campStartDate',
    minWidth: 120,
  },
  {
    headerName: '캠페인 종료일',
    field: 'campEndDate',
    minWidth: 120,
  },
];

const Integration = () => {
  /** 설정 부분 */
  const setTitle = useOutletContext<any>();
  const [isRptDownloadOpened, setIsRptDownloadOpened] = useState<boolean>(false);
  const gridRef = useRef<AgGridReact>(null);
  const childRef = useRef<any>(null);
  const rptSrchFilterRef = useRef<any>(null);
  const [chartOptions, setChartOptions] = useState({});
  const [rowData, setRowData] = useState<any[]>();
  const [isDlDialogOpened, setIsDlDialogOpened] = useState<boolean>(false);
  const [dlParam, setDlParam] = useState<any>(null);
  const [chartData, setChartData] = useState<RptMakerChartReq01>({
    startDate: dayjs().subtract(2, 'd').format('YYYYMMDD'),
    endDate: dayjs().subtract(1, 'd').format('YYYYMMDD'),
    rptDateDiv: 'day',
    rptItemDiv: 'media',
    chartData01: 'convByCost',
    chartData02: 'impCnt',
    isStart: true,
  });
  const [columnDefs, setColumnDefs] = useState<ColDef[]>(gridColumnDef);
  const {
    setValue, getValues,
  } = useForm<MakerRptGrid>();
  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: true,
    suppressMovable: true,
    sortable: true,
    minWidth: 200,
  }), []);
  const onGridReady = useCallback(() => {
    const rptSrchFilterSrchData = rptSrchFilterRef.current!.rptSrchFilterSrch();
    if ($('#searchTxt').val() !== '') setValue('searchTxt', [$('#searchTxt').val()]);
    setValue('projectDiv3', rptSrchFilterSrchData.projectDiv3);
    setValue('campViewStatus', rptSrchFilterSrchData.campViewStatus);
    setValue('mediaDiv5', rptSrchFilterSrchData.mediaDiv5);
    const {
      projectDiv3, campViewStatus, mediaDiv5, startDate, endDate, compareStartDate, compareEndDate, searchDiv, searchTxt,
    } = getValues();
    const columnData:any = [];
    ApiUtil.get<IRptDimOne>('/api/rptCommon/analysisFilter/srchData', { params: { } }).then((resp) => {
      const dimJson = JSON.parse(resp.data.data.dimList);
      const dimData = dimJson.rptFilterDiv.split(',').filter((item:any) => item !== 'campNm' && item !== 'projectName' && item !== 'projectDiv4' && item !== 'creationLinkName').map((dim:any) => ({
        headerName: dimDivEnum[`${dim}`],
        field: dim,
        minWidth: 180,
      }));
      if (dimJson.rptFilterDiv.indexOf('creationLinkName') > -1) {
        dimData.push({
          headerName: dimDivEnum.creationLinkId,
          field: 'creationLinkId',
          minWidth: 180,
        });
        dimData.push({
          headerName: dimDivEnum.creationLinkName,
          field: 'creationLinkName',
          minWidth: 180,
        });
      }
      if (dimJson.rptDimensionDiv !== 'empty') {
        dimData.push({
          headerName: dimDivEnum[`${dimJson.rptDimensionDiv}`],
          field: dimJson.rptDimensionDiv,
          minWidth: 180,
        });
      }
      gridColumnDef.map((col:any) => columnData.push(col));
      dimData.map((col:any) => columnData.push(col));
      ApiUtil.get<IRptMetricOne>('/api/rptCommon/rptMetric/srchData', { params: { } }).then((resp2) => {
        const metricData = resp2.data.data.metricList.split(',').map((metric:any) => {
          const numberFormatRenderer = (props: ICellRendererParams) => (props!.data[`${metric}`] === undefined ? '-' : (String(props!.data[`${metric}`]).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')));
          return {
            headerName: metricDivEnum[`${metric}`],
            field: metric,
            cellRenderer: numberFormatRenderer,
            minWidth: 120,
          };
        });
        metricData.map((col:any) => columnData.push(col));
        if ($('#dateCompareSwitch').prop('checked')) {
          columnData.shift();
          columnData.unshift({
            headerName: '구분',
            field: 'rptDiv',
            minWidth: 90,
          });
        }
        setColumnDefs(columnData);
      });
      ApiUtil2.post<MakerRptGridDataList>('/api/mngRpt/integration/list', {
        projectDiv3, campViewStatus, mediaDiv5, startDate, endDate, compareStartDate, compareEndDate, searchDiv, searchTxt,
      }).then((resp2) => {
        setRowData(resp2.data.data);
      });
    });
  }, []);
  const ChartTemplate = () => <Fragment><HighchartsReact highcharts={Highcharts} options={chartOptions} /></Fragment>;
  /** 설정 부분 */
  /** 이벤트 함수 부분 */
  const datePickerNextEvent = () => {
    $('#datepicker').data('daterangepicker').addInterval();
    const startDate = $('#datepicker').data('daterangepicker').startDate.format('YYYYMMDD');
    const endDate = $('#datepicker').data('daterangepicker').endDate.format('YYYYMMDD');
    if (!$('#dateCompareSwitch').prop('checked')) {
      setChartData((current:RptMakerChartReq01) => {
        const newData = { ...current };
        newData.startDate = startDate;
        newData.endDate = endDate;
        newData.isStart = true;
        return newData;
      });
    }
    setValue('startDate', startDate);
    setValue('endDate', endDate);
    onGridReady();
  };
  const dateCompareSwitchEvent = (event:any) => {
    if (event.currentTarget.checked) {
      $('#compareDateHeaderDom').show();
      $('#compareDateDom').show();
      if ($('#collapse-01-01').hasClass('panel-collapse collapse in')) $('#chartDom').trigger('click');
      setValue('compareStartDate', $('#compareDatepicker').data('daterangepicker').startDate.format('YYYYMMDD'));
      setValue('compareEndDate', $('#compareDatepicker').data('daterangepicker').endDate.format('YYYYMMDD'));
      $('#chartDom').addClass('none');
    } else {
      $('#compareDateHeaderDom').hide();
      $('#compareDateDom').hide();
      if (!$('#collapse-01-01').hasClass('panel-collapse collapse in')) $('#chartDom').trigger('click');
      $('#chartDom').removeClass('none');
      setValue('compareStartDate', '');
      setValue('compareEndDate', '');
    }
  };
  const datePickerPrevEvent = () => {
    $('#datepicker').data('daterangepicker').subtractInterval();
    const startDate = $('#datepicker').data('daterangepicker').startDate.format('YYYYMMDD');
    const endDate = $('#datepicker').data('daterangepicker').endDate.format('YYYYMMDD');
    if (!$('#dateCompareSwitch').prop('checked')) {
      setChartData((current:RptMakerChartReq01) => {
        const newData = { ...current };
        newData.startDate = startDate;
        newData.endDate = endDate;
        newData.isStart = true;
        return newData;
      });
    }
    setValue('startDate', startDate);
    setValue('endDate', endDate);
    onGridReady();
  };
  const onGridSizeChanged = (params: GridSizeChangedEvent) => {
    params.api.sizeColumnsToFit();
  };
  const changePagination = () => {
        childRef.current!.onPaginationChanged();
  };

  const setIsRptDownloadOpenedEvent = () => {
    setIsRptDownloadOpened(false);
  };
    /** 이벤트 함수 부분 */
    /** 훅 부분 */
  useEffect(() => {
    setTitle('통합 리포트');
    const datePickerOptionConfig = {
      opens: 'left',
      /** 20230420 https://wadiz.atlassian.net/jira/software/c/projects/BIZ/boards/99?modal=detail&selectedIssue=BIZ-289&assignee=61089be3a539cb00688760bc */
      startDate: dayjs().subtract(2, 'd'),
      endDate: dayjs().subtract(1, 'd'),
      ranges: {
        '비교 ': dateCompareSwitchEvent,
        '오늘 ': [dayjs().startOf('day'), dayjs().endOf('day').subtract(59, 'm')],
        '어제 ': [dayjs().startOf('day').subtract(1, 'days'), dayjs().endOf('day').subtract(59, 'm').subtract(1, 'days')],
        '이번 주': [dayjs().startOf('day').day(0), dayjs().day(1).endOf('day').subtract(-7, 'd')
          .subtract(59, 'm')],
        '저번 주': [dayjs().startOf('day').subtract(1, 'week').day(0), dayjs().endOf('day').subtract(1, 'week').day(6)
          .subtract(59, 'm')],
        '최근 7일': [dayjs().startOf('day').subtract(7, 'd'), dayjs().endOf('day').subtract(1, 'd').subtract(59, 'm')],
        '최근 30일': [dayjs().startOf('day').subtract(30, 'd'), dayjs().endOf('day').subtract(1, 'd').subtract(59, 'm')],
      },
    };
    $('#datepicker').daterangepicker(datePickerOptionConfig, (start:any, end:any) => {
      $('#daterangepicker_span').text(($('#datepicker').data('daterangepicker').chosenLabel));
      const startDate = new Date(start);
      const endDate = new Date(end);
      if (Math.abs((startDate.getTime() - endDate.getTime()) / (1000 * 60 * 60 * 24)) >= 31) {
        alertify.error('조회기간은 최대 31일 간 선택이 가능합니다.');
        /** 20230420 https://wadiz.atlassian.net/jira/software/c/projects/BIZ/boards/99?modal=detail&selectedIssue=BIZ-289&assignee=61089be3a539cb00688760bc */
        $('#datepicker').data('daterangepicker').setStartDate(dayjs().subtract(2, 'd'));
        $('#datepicker').data('daterangepicker').setEndDate(dayjs().subtract(1, 'd'));
      } else {
        if (!$('#dateCompareSwitch').prop('checked')) {
          setChartData((current:RptMakerChartReq01) => {
            const newData = { ...current };
            newData.startDate = start.format('YYYYMMDD');
            newData.endDate = end.format('YYYYMMDD');
            newData.isStart = true;
            return newData;
          });
        }
        setValue('startDate', start.format('YYYYMMDD'));
        setValue('endDate', end.format('YYYYMMDD'));
        onGridReady();
      }
    });
    setValue('startDate', $('#datepicker').data('daterangepicker').startDate.format('YYYYMMDD'));
    setValue('endDate', $('#datepicker').data('daterangepicker').endDate.format('YYYYMMDD'));
    setValue('searchDiv', 'makerName');
    $('#compareDatepicker').daterangepicker(datePickerOptionConfig, (start:any, end:any) => {
      $('#compareDatepickerSpan').text(($('#compareDatepicker').data('daterangepicker').chosenLabel));
      const startDate = new Date(start);
      const endDate = new Date(end);
      if (Math.abs((startDate.getTime() - endDate.getTime()) / (1000 * 60 * 60 * 24)) >= 31) {
        alertify.error('비교기간은 최대 31일 간 선택이 가능합니다.');
        /** 20230420 https://wadiz.atlassian.net/jira/software/c/projects/BIZ/boards/99?modal=detail&selectedIssue=BIZ-289&assignee=61089be3a539cb00688760bc */
        $('#compareDatepicker').data('daterangepicker').setStartDate(dayjs().subtract(2, 'd'));
        $('#compareDatepicker').data('daterangepicker').setEndDate(dayjs().subtract(1, 'd'));
      } else {
        setValue('compareStartDate', start.format('YYYYMMDD'));
        setValue('compareEndDate', end.format('YYYYMMDD'));
        onGridReady();
      }
    });
    $('.select2-single').select2({ width: 'auto', minimumResultsForSearch: Infinity });
    $('#chartDateConfigSelect').on('select2:select', (e:any) => {
      if (!$('#dateCompareSwitch').prop('checked')) {
        setChartData((current:RptMakerChartReq01) => {
          const newData = { ...current };
          newData.rptDateDiv = e.target.value;
          newData.isStart = false;
          return newData;
        });
      }
    });
    $('#chartDateItemSelect').on('select2:select', (e:any) => {
      if (!$('#dateCompareSwitch').prop('checked')) {
        setChartData((current:RptMakerChartReq01) => {
          const newData = { ...current };
          newData.rptItemDiv = e.target.value;
          newData.isStart = false;
          return newData;
        });
      }
    });
    $('#chartRptLineSelect').on('select2:select', (e:any) => {
      if (!$('#dateCompareSwitch').prop('checked')) {
        setChartData((current:RptMakerChartReq01) => {
          const newData = { ...current };
          newData.chartData01 = e.target.value;
          newData.isStart = false;
          return newData;
        });
      }
    });
    $('#chartRptRodSelect').on('select2:select', (e:any) => {
      if (!$('#dateCompareSwitch').prop('checked')) {
        setChartData((current:RptMakerChartReq01) => {
          const newData = { ...current };
          newData.chartData02 = e.target.value;
          newData.isStart = false;
          return newData;
        });
      }
    });
    $('#searchDiv').on('select2:select', (e:any) => {
      setValue('searchDiv', e.target.value);
    });
    $('#rptResetBtn').on('click', () => {
      onGridReady();
    });
    $('#rptSearchDown').on('click', () => {
      setDlParam({
        ...getValues(),
      });
      setIsDlDialogOpened(true);

      // window.location.assign(`/api/mngRpt/integration/searchDown?${q}`);
    });
    $('#searchTxt').on('keyup', (e:any) => {
      if (e.key === 'Enter') onGridReady();
    });

    $(document).on('click', '#rptTotalDown', () => {
      if (!isRptDownloadOpened) {
        setIsRptDownloadOpened(true);
      }
    });
    return () => {
      setIsRptDownloadOpened(false);
      $('.daterangepicker').remove();
    };
  }, []);
  useEffect(() => {
    const api = chartData.isStart ? ApiUtil : ApiUtil2;
    api.post<RptMakerChartDataList>('/api/mngRpt/integration/chart', chartData).then((resp) => {
      const lineDiv: any = $('#chartRptLineSelect option:selected').val();
      const columnsDiv = $('#chartRptRodSelect option:selected').val();
      let categoriesData: string[] = [];
      const itemDivs: any[] = [];
      const itemDivLineData: any = {};
      const itemDivColumnsData: any = {};
      const chartSeries: any = [];
      resp.data.data.map((a:any) => {
        categoriesData.push(a.basicDate);
        if (itemDivs.filter((i:string) => i === a.rptItemDiv).length === 0) itemDivs.push(a.rptItemDiv);
        return null;
      });
      categoriesData = categoriesData.filter((arr:string, index:number, cellback:string[]) => index === cellback.findIndex((loc) => loc === arr));
      categoriesData.sort();
      itemDivs.forEach((val:any) => {
        categoriesData.forEach((cate:any) => {
          if (resp.data.data.filter((i:any) => i.basicDate === cate && i.rptItemDiv === val).length === 0) {
            if (itemDivLineData[`${val}`] === undefined) itemDivLineData[`${val}`] = [0];
            else itemDivLineData[`${val}`].push(0);
            if (itemDivColumnsData[`${val}`] === undefined) itemDivColumnsData[`${val}`] = [0];
            else itemDivColumnsData[`${val}`].push(0);
          } else {
            const item:any = resp.data.data.filter((i:any) => i.basicDate === cate && i.rptItemDiv === val).pop();
            if (itemDivLineData[`${val}`] === undefined) itemDivLineData[`${val}`] = [item[`${lineDiv}`]];
            else itemDivLineData[`${val}`].push(item[`${lineDiv}`]);
            if (itemDivColumnsData[`${val}`] === undefined) itemDivColumnsData[`${val}`] = [item[`${columnsDiv}`]];
            else itemDivColumnsData[`${val}`].push(item[`${columnsDiv}`]);
          }
        });
        const colorData = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        const lineJson = {
          name: val, type: 'line', color: colorData, yAxis: 0, data: itemDivLineData[`${val}`],
        };
        const columnsJson = {
          name: val, type: 'column', color: colorData, yAxis: 1, data: itemDivColumnsData[`${val}`],
        };
        chartSeries.push(lineJson);
        chartSeries.push(columnsJson);
      });
      setChartOptions(
        {
          chart: { type: 'line' },
          title: {
            text: '',
          },
          xAxis: {
            categories: categoriesData,
          },
          yAxis: [{ title: { text: $('#chartRptLineSelect option:selected').text() } }, { title: { text: $('#chartRptRodSelect option:selected').text() }, opposite: true }],
          plotOptions: { line: { dataLabels: { enabled: true } }, column: { dataLabels: { enabled: true } } },
          series: chartSeries,
        },
      );
    });
  }, [chartData]);
  /** 훅 부분 */
  return (
    <Fragment>
      <section className="wrap-section wrap-graph">
        {isRptDownloadOpened ? (
          <RptTotalDown
            setIsRptDownloadOpened={setIsRptDownloadOpenedEvent}
            startDate={getValues('startDate')}
            endDate={getValues('endDate')}
            isMaker={false}
          />
        ) : null}
        <div className="box-header">
          <div className="box-tit">
            <h2 className="fz-20 fc-1 fw-bold">리포트</h2>
          </div>
          <div className="box-option">
            <span className="fz-14 fc-2">조회기간</span>
            <div className="comp-datepicker">
              <div className="inner-datepicker">
                <i className="ico ico-calendar"></i>
                <input type="text" className="tf-comm datepicker-range" id="datepicker" readOnly />
                <span className="fz-12 fc-2 daterangepicker_span" id="datepickerSpan">
                  사용자 설정
                </span>
              </div>
              <button type="button" className="btn btn-prev" onClick={datePickerPrevEvent}></button>
              <button type="button" className="btn btn-next" onClick={datePickerNextEvent}></button>
            </div>
            <span className="fz-14 fc-2" style={{ display: 'none' }} id="compareDateHeaderDom">
              비교기간
            </span>
            <div className="comp-datepicker" style={{ display: 'none' }} id="compareDateDom">
              <div className="inner-datepicker">
                <i className="ico ico-calendar"></i>
                <input type="text" className="tf-comm datepicker-range" id="compareDatepicker" readOnly />
                <span className="fz-12 fc-2 compareDaterangepicker_span" id="compareDatepickerSpan">
                  사용자 설정
                </span>
              </div>
              <button type="button" className="btn btn-prev" onClick={datePickerPrevEvent}></button>
              <button type="button" className="btn btn-next" onClick={datePickerNextEvent}></button>
            </div>
            <span className="fz-14 fc-2">비교</span>
            <div className="comp-switch">
              <input type="checkbox" id="dateCompareSwitch" onClick={(event) => dateCompareSwitchEvent(event)} />
              <label htmlFor="dateCompareSwitch">
                <i className="ico"></i>
              </label>
            </div>
            <button type="button" className="btn btn-primary large" onClick={onGridReady}>
              조회
            </button>
          </div>
        </div>
        <div className="box-body">
          <div className="panel-group comp-accordion" id="comp-accordion-01" role="tablist" aria-multiselectable="true">
            <div className="panel panel-default">
              <div
                role="tabpanel"
                className="panel-collapse collapse in"
                id="collapse-01-01"
                aria-labelledby="heading-01-01"
              >
                <div className="panel-body">
                  <div className="wrap-filter">
                    <div className="inner-filter">
                      <div className="box-left">
                        <div className="item-filter">
                          <div className="filter-tit">
                            <p className="fz-12 fc-2">기간설정</p>
                          </div>
                          <div className="box-filter">
                            <select className="select2-single w-150" id="chartDateConfigSelect" defaultValue="day">
                              <option value="day">일간</option>
                              <option value="week">주간</option>
                              <option value="month">월간</option>
                              <option value="total">기간합계</option>
                            </select>
                          </div>
                        </div>
                        <div className="item-filter">
                          <div className="filter-tit">
                            <p className="fz-12 fc-2">표시 항목</p>
                          </div>
                          <div className="box-filter">
                            <select className="select2-single w-150" id="chartDateItemSelect" defaultValue="media">
                              <option value="media">노출매체</option>
                              <option value="adSet">광고세트</option>
                              <option value="device">노출기기</option>
                              <option value="gender">성별</option>
                              <option value="age">나이</option>
                              <option value="region">지역</option>
                            </select>
                          </div>
                        </div>
                        <div className="item-filter">
                          <div className="filter-tit">
                            <p className="fz-12 fc-2">광고성과1 (꺾은선)</p>
                          </div>
                          <div className="box-filter">
                            <select className="select2-single w-150" id="chartRptLineSelect" defaultValue="convByCost">
                              <option value="cost">비용</option>
                              <option value="fees">수수료</option>
                              <option value="impCnt">노출수</option>
                              <option value="clickCnt">클릭수</option>
                              <option value="ctr">클릭률 (CTR)</option>
                              <option value="cpc">클릭당 비용 (CPC)</option>
                              <option value="convCnt">전환수</option>
                              <option value="convRate">전환율</option>
                              <option value="convByCost">전환당 비용</option>
                              <option value="convCa">전환 매출</option>
                              <option value="roas">수익률 (ROAS)</option>
                              <option value="cpm">CPM</option>
                              <option value="viewImp">게재빈도</option>
                            </select>
                          </div>
                        </div>
                        <div className="item-filter">
                          <div className="filter-tit">
                            <p className="fz-12 fc-2">광고성과2 (막대)</p>
                          </div>
                          <div className="box-filter">
                            <select className="select2-single w-150" id="chartRptRodSelect" defaultValue="impCnt">
                              <option value="cost">비용</option>
                              <option value="fees">수수료</option>
                              <option value="impCnt">노출수</option>
                              <option value="clickCnt">클릭수</option>
                              <option value="ctr">클릭률 (CTR)</option>
                              <option value="cpc">클릭당 비용 (CPC)</option>
                              <option value="convCnt">전환수</option>
                              <option value="convRate">전환율</option>
                              <option value="convByCost">전환당 비용</option>
                              <option value="convCa">전환 매출</option>
                              <option value="roas">수익률 (ROAS)</option>
                              <option value="cpm">CPM</option>
                              <option value="viewImp">게재빈도</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ChartTemplate />
                </div>
              </div>
              <div className="panel-heading" role="tab" id="heading-01-01">
                <h4 className="panel-title">
                  <a
                    role="button"
                    data-toggle="collapse"
                    data-parent="#comp-accordion-01"
                    id="chartDom"
                    href="#collapse-01-01"
                    aria-controls="collapse-01-01"
                    aria-expanded="true"
                  >
                    <button type="button" className="btn btn btn-primary btn-ico small w-150">
                      <i className="ico ico-arrow"></i>
                    </button>
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wrap-section wrap-datagrid">
        <div className="box-header h-auto">
          <div className="box-tit">
            <RptSrchFilter ref={rptSrchFilterRef} />
            <select className="select2-single w-150" id="searchDiv" defaultValue="makerName">
              <option value="makerName">메이커명</option>
              <option value="makerId">메이커ID</option>
              <option value="projectId">프로젝트ID</option>
              <option value="campId">캠페인ID</option>
              <option value="campNm">캠페인명</option>
            </select>
            <div className="input-group comp-search">
              <div className="inner-input-group">
                <input type="text" className="tf-comm" id="searchTxt" placeholder="검색어를 입력해 주세요." />
                <i className="ico ico-search"></i>
              </div>
            </div>
          </div>
          <div className="box-option">
            <button type="button" className="btn btn btn-secondary-outline btn-ico w-auto" id="rptResetBtn">
              <i className="ico ico-refresh"></i>새로고침
            </button>
            <RptAnalysisFilter onGridReady={() => {}} /> {/* ISMS */}
            <RptMetric onGridReady={() => {}} /> {/* ISMS */}
            <div className="comp-dropdown open-right">
              <a className="dropdown-toggle" data-toggle="dropdown" href="#!">
                <div className="box-left">
                  <i className="ico ico-download"></i>
                  <span className="fz-14 fc-2 fw-medium">다운로드</span>
                </div>
                <div className="box-right">
                  <i className="ico ico-arrow"></i>
                </div>
              </a>
              <div className="dropdown-menu expand">
                <ul className="opt-selectbox">
                  <li className="opt-menu" id="rptSearchDown">
                    <p className="fc-1">조회 내역 다운로드</p>
                  </li>
                  <li className="opt-menu" id="rptTotalDown">
                    <p className="fc-1">전체 내역 다운로드</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="box-body">
          <div className="ag-grid">
            <div className="ag-grid-inner">
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                // onGridReady={onGridReady}
                onGridSizeChanged={onGridSizeChanged}
                rowHeight={48}
                domLayout={'autoHeight'}
                pagination={true}
                paginationPageSize={10}
                suppressPaginationPanel={true}
                onPaginationChanged={changePagination}
                noRowsOverlayComponent={NoDataTemplate}
                alwaysShowHorizontalScroll={true}
              ></AgGridReact>
            </div>
          </div>
        </div>
        <div className="box-footer">
          <Pagination gridRef={gridRef} ref={childRef} />
        </div>
      </section>
      {isDlDialogOpened ? (
        <DlDialog
          setIsDlDialogOpened={setIsDlDialogOpened}
          dlUrl={'/api/mngRpt/integration/searchDown'}
          dlParam={dlParam}
        />
      ) : null}
    </Fragment>
  )
};

export default Integration;
