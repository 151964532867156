import React, { Fragment, useEffect } from 'react';

const AccAdjustRegSingle = (props: any) => {
  const comma = (str: string) => {
    const str2 = String(str);
    return str2.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  };

  const uncomma = (str: string) => {
    const str2 = String(str);
    return str2.replace(/[^\d]+/g, '');
  };

  useEffect(() => {
    // initialize
  }, []);

  return (
    <Fragment>
      <dl className="column-two">
        <dt>
          <div className="dt-inner">
            <span className="fz-16 fc-1">메이커ID</span>
          </div>
        </dt>
        <dd>
          <div className="form-group">
            {/* Input-Group : Start */}
            <div className="input-group">
              <div className="inner-input-group" onClick={props.openUserNoPopup} onKeyUp={(e: any) => e.handleKeyUp}
                   role="presentation">
                <input type="text" className="tf-comm" placeholder="임의조정 대상을 선택해 주세요."
                       disabled value={props.adjustTarget?.makerLoginId || ''} />
              </div>
              <p className="fz-12">체크 / 에러 문구 내용 영역</p>
            </div>
            {/* Input-Group : End */}
          </div>
          <div className="form-group">
            <button type="button" className="btn btn-primary-outline" onClick={props.openUserNoPopup}>임의조정 대상 조회_메이커</button>
          </div>
        </dd>
        <dt>
          <div className="dt-inner">
            <span className="fz-16 fc-1">메이커명</span>
          </div>
        </dt>
        <dd>
          <div className="form-group">
            {/* Input-Group : Start */}
            <div className="input-group">
              <div className="inner-input-group">
                <input type="text" className="tf-comm" value={props.adjustTarget?.makerName || ''} disabled/>
              </div>
              <p className="fz-12">체크 / 에러 문구 내용 영역</p>
            </div>
            {/* Input-Group : End */}
          </div>
        </dd>
      </dl>
      <dl>
        <dt>
          <div className="dt-inner">
            <span className="fz-16 fc-1">충전 사업자 번호</span>
          </div>
        </dt>
        <dd>
          <div className="form-group">
            {/* Input-Group : Start */}
            <div className="input-group">
              <div className="inner-input-group">
                <input type="text" className="tf-comm" value={props.adjustTarget?.userNo || ''} disabled/>
              </div>
              <p className="fz-12">체크 / 에러 문구 내용 영역</p>
            </div>
            {/* Input-Group : End */}
          </div>
        </dd>
      </dl>
      <dl className="column-two">
        <dt>
          <div className="dt-inner">
            <span className="fz-16 fc-1">유상 비즈머니 잔액</span>
          </div>
        </dt>
        <dd>
          <div className="form-group">
            {/* Input-Group : Start */}
            <div className="input-group">
              <div className="inner-input-group">
                <input type="text" className="tf-comm tf-unit" value={comma(uncomma(props.adjustTarget?.paidCost)) || ''} disabled/>
                <span className="fz-12 fc-1">원</span>
              </div>
              <p className="fz-12">체크 / 에러 문구 내용 영역</p>
            </div>
            {/* Input-Group : End */}
          </div>
        </dd>
        <dt>
          <div className="dt-inner">
            <span className="fz-16 fc-1">쿠폰 잔액</span>
          </div>
        </dt>
        <dd>
          <div className="form-group">
            {/* Input-Group : Start */}
            <div className="input-group">
              <div className="inner-input-group">
                <input type="text" className="tf-comm tf-unit" value={comma(uncomma(props.adjustTarget?.freeCost)) || ''} disabled/>
                <span className="fz-12 fc-1">원</span>
              </div>
              <p className="fz-12">체크 / 에러 문구 내용 영역</p>
            </div>
            {/* Input-Group : End */}
          </div>
        </dd>
      </dl>
    </Fragment>
  );
};
export default AccAdjustRegSingle;
