import { axiosInstance } from '../axios'

export type DailyDataType = {
  basicDate: string
  adUse: number
  paidUse: number
  freeUse: number
  pomUse: number
}
type DailyStateType = {
  adUse: number
  paidUse: number
  freeUse: number
  pomUse: number
}
type ResponseType = {
  list: DailyDataType[]
  rows: number
  stats: DailyStateType[]
}
type FunctionType = (startDate: string, endDate: string) => Promise<ResponseType>
export const fetchDailyList: FunctionType = (startDate, endDate) =>
  axiosInstance
    .get(`/api/mngRpt/sales/dp/daily?startDate=${startDate}&endDate=${endDate}`, {
      headers: {
        'Content-Type': 'application/json;',
      },
    })
    .then((res) => res?.data?.data)
