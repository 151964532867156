import { Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import className from 'classnames/bind'
import styles from './MainBoardPreviewDeprecated.module.scss'

type MainBoardPreviewFunctionType = {
  bgColor: string
  imgUrl: string
  mainCopy: string | undefined
  subCopy: string | undefined
  isDeleteAble: boolean
  onDelete?: () => void
  onPreview?: () => void
}
const cx = className.bind(styles)

export const MainBoardPreviewDeprecated: React.FC<MainBoardPreviewFunctionType> = ({
  bgColor,
  imgUrl,
  mainCopy,
  subCopy,
  isDeleteAble,
  onDelete,
  onPreview = () => {},
}) => (
  <div className={styles.container}>
    <div style={{ backgroundColor: bgColor }} className={styles.preview}>
      <p>{mainCopy}</p>
      <p>{subCopy}</p>
      <img src={imgUrl} alt="메인보드이미지" />
    </div>
    {isDeleteAble && (
      <Button type="text" className={styles.antCloseButton} icon={<CloseOutlined />} onClick={onDelete} />
    )}
    <Button size="middle" onClick={onPreview} block>
      광고 미리보기
    </Button>
  </div>
)
