import { useState, useEffect } from 'react';
import { Button, DatePicker, Flex } from 'antd';
import dayjs, { type Dayjs } from 'dayjs';
import { RedoOutlined } from '@ant-design/icons';

import type { ParamType, ProductType } from '@biz/api'
import { ReactComponent as CalendarOutline } from './calendarOutline.svg';
import { PopButton } from './PopButton';
import { CheckAll } from './CheckAll';

type RangeFilter = [Dayjs | null, Dayjs | null];
const currentRange = () =>
  [dayjs().subtract(1, 'week'), dayjs()] as RangeFilter;
const makeParams = (
  range: RangeFilter,
  adType?: 'display' | 'push',
  product?: string
) => ({
  adDiv: adType || 'display',
  sectionCode: product || '',
  startDate: range[0]?.format('YYYYMMDD') || '',
  endDate: range[1]?.format('YYYYMMDD') || '',
});
const firstAdDivProduct = (
  products: ProductType[],
  currentDiv: 'display' | 'push'
) => products?.find((p) => p.adDiv === currentDiv)?.sectionCode;

type SearchToolBoxFunction = {
  downloader: React.ReactNode;
  products: ProductType[];
  onChange: (params: ParamType) => void;
};
export const SearchToolBox: React.FC<SearchToolBoxFunction> = ({
  downloader,
  products,
  onChange,
}) => {
  const [range, setRange] = useState(currentRange());
  const [adType, setAdType] = useState<'display' | 'push'>('display');

  const productOptions = (products || [])
    .filter((p) => p.adDiv === adType)
    .map((p) => ({
      label: p.adProduct,
      value: p.sectionCode,
    }));
  const [product, setProduct] = useState(
    firstAdDivProduct(products, 'display')
  );
  useEffect(() => {
    // onChange(makeParams(range, adType, product));
  }, []);

  return (
    <Flex justify='space-between'>
      <Flex gap={16}>
        <PopButton
          content={
            <DatePicker.RangePicker
              value={range}
              onChange={(r) => {
                const newRange = r ?? currentRange();
                setRange(newRange);
                onChange(makeParams(newRange, adType, product));
              }}
              suffixIcon={
                <CalendarOutline style={{ width: 16, color: '#495057' }} />
              }
              cellRender={(current) => {
                return (
                  <div className='ant-picker-cell-inner'>
                    {(current as Dayjs).date()}
                  </div>
                );
              }}
              size='middle'
              disabledDate={(current: Dayjs) =>
                current > dayjs().startOf('day')
              }
            />
          }
          isDataExist={!!range?.[0]}
        >
          {'기간'}
        </PopButton>
        <PopButton
          content={
            <CheckAll
              value={adType}
              onChange={(v) => {
                const adT = v as 'display' | 'push';
                setAdType(adT);

                const selectedProduct = products?.find(
                  (p) => p.adDiv === v
                )?.sectionCode;
                if (selectedProduct) {
                  setProduct(selectedProduct);
                  onChange(makeParams(range, adT, selectedProduct));
                }
              }}
              style={{
                width: 216,
                padding: 4,
                display: 'flex',
                flexDirection: 'column',
                gap: 14,
              }}
              options={[
                { label: '디스플레이', value: 'display' },
                { label: '푸시', value: 'push' },
              ]}
            />
          }
          isDataExist={false}
        >
          {'광고 유형'}
        </PopButton>
        <PopButton
          content={
            <CheckAll
              value={product}
              onChange={(v) => {
                setProduct(v);
                onChange(makeParams(range, adType, v));
              }}
              style={{
                width: 298,
                padding: 4,
                display: 'flex',
                flexDirection: 'column',
                gap: 14,
              }}
              options={productOptions}
            />
          }
          isDataExist={false}
        >
          {'광고 상품'}
        </PopButton>
        <div>
          <Button
            type='text'
            icon={<RedoOutlined style={{ transform: 'rotate(270deg)' }} />}
            style={{ backgroundColor: '#fff', padding: '0' }}
            onClick={() => {
              setRange(currentRange());
              setAdType('display');
              setProduct(firstAdDivProduct(products, 'display'));
              onChange(makeParams(range, adType, product));
            }}
          >
            {'초기화'}
          </Button>
          <Button
            onClick={() => onChange(makeParams(range, adType, product))}
            style={{
              borderColor: '#495057',
              backgroundColor: '#495057',
              color: '#fff',
              marginLeft: 16,
            }}
          >
            {'조회'}
          </Button>
        </div>
      </Flex>
      {downloader}
    </Flex>
  );
};
