import { useMutation, useQueryClient, type UseMutationResult } from '@tanstack/react-query'
import { fetchPostAdSetCreationOnOff, type Response, type Payload } from '@biz/api'
import { silentUpdateCreativeData } from '../useCreativeDataQuery'

type FunctionType = () => UseMutationResult<unknown, unknown, Payload, unknown>

/**
 * 광고세트 타겟 설정 ON/OFF 업데이트
 */
export const useAdSetCreationOnOffMutation: FunctionType = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload) =>
      fetchPostAdSetCreationOnOff(payload).then((response) => {
        silentUpdateCreativeData(queryClient)
        return response
      }),
  })
}
