import { axios } from '../axios'

export type ImageInfoType = {
  id: number //  148807265
  refId: number // 148807185
  sortOrder: number // 1
  afName: string // '일대일테스트이미지.png'
  fileSize: number // 18798
  fileName: string // 'https://rc2-bizstatic.wadiz.kr/static/creationFb/20240716/9892830/148807249/148807265.png'
  dlAuthDiv: string //  'ALL'
  afMeta: {
    width: string // '1919'
    height: string // '1919'
    fileDiv?: 'img' | 'vod' | 'VIDEO' | 'IMAGE' //  'IMG'
    ext?: string // 'png'
  }
}

type ResponseType = {
  ok: boolean
  data: Partial<ImageInfoType>[][]
}

type FunctionType = () => Promise<ResponseType>
export const fetchImageList: FunctionType = () => axios.get('/api/camp/make02/makerFbCreationFileList')
