import React, {
  Fragment, useEffect, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { ApiUtil } from '@biz/api'
import alertify from 'alertifyjs'

interface IRptDimForm {
    searchDimId: number;
    rptDimId: number;
    groupNm: string;
    date: any[];
    div: any[];
    ddiv: any[];
}

interface IRptDim {
    rptDimId: number;
    memberId: number;
    groupNm: string;
    dimList: string;
    srchYn: boolean;
}

interface IRptDimList {
    ok: boolean;
    data: IRptDim[];
}

const RptAnalysisFilter = (props: any) => {
  const [filters, setFilters] = useState<IRptDim[]>([]);
  const [filter, setFilter] = useState<IRptDim>({
    rptDimId: 0,
    groupNm: '기본',
    memberId: 0,
    dimList: '{rptFilterDate: "day", rptFilterDivs:"campNm", rptDimensionDivs:"empty"}',
    srchYn: false,
  });
  const [rptFilterDate, setRptFilterDate] = useState([{
    rptDimId: 0,
    date: 'day',
  }]);
  const [rptFilterDivs, setRptFilterDivs] = useState([{
    rptDimId: 0,
    div: ['campNm'],
  }]);
  const [rptDimensionDivs, setRptDimensionDivs] = useState([{
    rptDimId: 0,
    ddiv: 'empty',
  }]);
  const [searchDimId, setSearchDimId] = useState(0);
  const { register, setValue, getValues } = useForm<IRptDimForm>({
    defaultValues: {
      searchDimId: 0,
      rptDimId: 0,
      groupNm: '기본',
      date: [{
        rptDimId: 0,
        date: 'day',
      }],
      div: [{
        rptDimId: 0,
        div: ['campNm'],
      }],
      ddiv: [{
        rptDimId: 0,
        ddiv: 'empty',
      }],
    },
  });
  const { onGridReady } = props;

  const analysisFilterSrch = (rptDimId:number) => {
    setValue('searchDimId', rptDimId);
    setSearchDimId(rptDimId);
    ApiUtil.post('/api/rptCommon/analysisFilter/srchYn', { rptDimId: getValues().searchDimId }).then(() => {
      onGridReady();
    });
  };

  const filterGroupCharge = (e:any, a:IRptDim) => {
    setFilter(a);
    setRptFilterDate([...rptFilterDate.filter((it:any) => it.rptDimId !== a.rptDimId), getValues().date.filter((it:any) => it.rptDimId === a.rptDimId).length > 0 ? getValues().date.filter((it:any) => it.rptDimId === a.rptDimId)[0] : { rptDimId: a.rptDimId, date: JSON.parse(a.dimList).rptFilterDate }]);
    setRptFilterDivs([...rptFilterDivs.filter((it:any) => it.rptDimId !== a.rptDimId), getValues().div.filter((it:any) => it.rptDimId === a.rptDimId).length > 0 ? getValues().div.filter((it:any) => it.rptDimId === a.rptDimId)[0] : { rptDimId: a.rptDimId, div: JSON.parse(a.dimList).rptFilterDiv.split(',') }]);
    setRptDimensionDivs([...rptDimensionDivs.filter((it:any) => it.rptDimId !== a.rptDimId), getValues().ddiv.filter((it:any) => it.rptDimId === a.rptDimId).length > 0 ? getValues().ddiv.filter((it:any) => it.rptDimId === a.rptDimId)[0] : { rptDimId: a.rptDimId, ddiv: JSON.parse(a.dimList).rptDimensionDiv }]);
    setValue('rptDimId', a.rptDimId);
    setValue('groupNm', a.groupNm);
  };

  useEffect(() => {
    ApiUtil.get<IRptDimList>('/api/rptCommon/analysisFilter/list').then((resp) => {
      setFilters(resp.data.data);
      resp.data.data.map((a:IRptDim) => {
        if (a.srchYn) {
          setSearchDimId(a.rptDimId);
        }
        return null;
      });
    });
  }, []);
  useEffect(() => {
    $('#rptAnalysisFilterOpenBtn').on('click', () => {
      $('#rptFilterDialog').dialog({ width: 1200, title: '분석필터', closeOnEscape: true });
      $('#rptFilterDialog').dialog('open');
    });
    $('#newFilterOpenBtn').on('click', () => {
      $('#newFilterDialog').dialog({ width: 600, title: '신규 분석필터 등록', closeOnEscape: true });
      $('#newFilterDialog').dialog('open');
      $('#newFilterNameTxt').val('');
    });
    $('#rptAnalysisFilterDialogCanclBtn').on('click', () => {
      $('#rptFilterDialog').dialog('close');
    });
    $('#newFilterDialogCanclBtn').on('click', () => {
      $('#newFilterDialog').dialog('close');
    });
    $('#deleteFilterBtn').on('click', () => {
      const dataId = getValues().rptDimId;
      if (dataId === 0) {
        alertify.error('기본 분석필터는 삭제가 불가 합니다.');
      } else {
        ApiUtil.post<IRptDimList>('/api/rptCommon/analysisFilter/delete', { rptDimId: dataId }).then((resp) => {
          setFilters(resp.data.data);
          if (resp.data.data.length === 0) {
            setFilter({
              rptDimId: 0,
              groupNm: '기본',
              memberId: 0,
              dimList: '{rptFilterDate: "day", rptFilterDivs:"campNm", rptDimensionDivs:"empty"}',
              srchYn: true,
            });
            setValue('rptDimId', 0);
            setValue('groupNm', '기본');
            setValue('date', []);
            setValue('div', []);
            setValue('ddiv', []);
            $('#rptDayRadio').trigger('click');
            $('#rptDimensionEmptyR').trigger('click');
          } else {
            setFilter(resp.data.data[resp.data.data.length - 1]);
            setValue('rptDimId', resp.data.data[resp.data.data.length - 1].rptDimId);
            setValue('groupNm', resp.data.data[resp.data.data.length - 1].groupNm);
            setValue('date', getValues().date.filter((it:any) => it.rptDimId !== dataId));
            setValue('div', getValues().div.filter((it:any) => it.rptDimId !== dataId));
            setValue('ddiv', getValues().ddiv.filter((it:any) => it.rptDimId !== dataId));
          }
        });
      }
    });
    $('#defFilter').on('click', () => {
      setFilter({
        rptDimId: 0,
        groupNm: '기본',
        memberId: 0,
        dimList: '{rptFilterDate: "day", rptFilterDivs:"campNm", rptDimensionDivs:"empty"}',
        srchYn: false,
      });
      setValue('rptDimId', 0);
    });
    $('#newFilterDialogSaveBtn').on('click', (e:any) => {
      e.preventDefault();
      ApiUtil.post<IRptDimList>('/api/rptCommon/analysisFilter/insert', { groupNm: $('#newFilterNameTxt').val() }).then((resp) => {
        setFilters(resp.data.data);
        setFilter(resp.data.data[resp.data.data.length - 1]);
        setRptFilterDate([...getValues().date, { rptDimId: resp.data.data[resp.data.data.length - 1].rptDimId, date: JSON.parse(resp.data.data[resp.data.data.length - 1].dimList).rptFilterDate }]);
        setRptFilterDivs([...getValues().div, { rptDimId: resp.data.data[resp.data.data.length - 1].rptDimId, div: JSON.parse(resp.data.data[resp.data.data.length - 1].dimList).rptFilterDiv.split(',') }]);
        setRptDimensionDivs([...getValues().ddiv, { rptDimId: resp.data.data[resp.data.data.length - 1].rptDimId, ddiv: JSON.parse(resp.data.data[resp.data.data.length - 1].dimList).rptDimensionDiv }]);
        setValue('rptDimId', resp.data.data[resp.data.data.length - 1].rptDimId);
        setValue('groupNm', resp.data.data[resp.data.data.length - 1].groupNm);
        $('#newFilterDialog').dialog('close');
      });
    });
    $('#rptAnalysisFilterDialogSaveBtn').on('click', () => {
      const { date, div, ddiv } = getValues();
      ApiUtil.post('/api/rptCommon/analysisFilter/update', { rptFilterDate: date, rptFilterDiv: div, rptDimensionDiv: ddiv }).then(() => {
        alertify.success('정상적으로 저장 되었습니다.');
        $('#rptFilterDialog').dialog('close');
      });
    });
    return () => {
      if ($('#rptFilterDialog').length > 0 && $.data($('#rptFilterDialog')[0], 'dialog')) {
        $('#rptFilterDialog').dialog('destroy')
      }
    };
  }, []);
  useEffect(() => {
    $('input[name="rptFilterDate"]').on('change', (e:any) => {
      setRptFilterDate([...getValues().date.filter((it:any) => it.rptDimId !== getValues().rptDimId), { rptDimId: getValues().rptDimId, date: e.target.value }]);
      setValue('date', [...getValues().date.filter((it:any) => it.rptDimId !== getValues().rptDimId), { rptDimId: getValues().rptDimId, date: e.target.value }]);
    });
    $('input[name="rptFilterDiv"]').on('click', () => {
      let data:any = [];
      $('input[name="rptFilterDiv"]:checked').map((a:any, i:any) => data.push($(i).val()));
      if (data.indexOf('project') === -1 && data.indexOf('projectType') > -1) {
        data = data.filter((it: string) => it !== 'projectType');
      }
      setRptFilterDivs([...getValues().div.filter((it:any) => it.rptDimId !== getValues().rptDimId), { rptDimId: getValues().rptDimId, div: data }]);
      setValue('div', [...getValues().div.filter((it:any) => it.rptDimId !== getValues().rptDimId), { rptDimId: getValues().rptDimId, div: data }]);
    });
    $('input[name="rptDimensionDiv"]').on('click', (e:any) => {
      setRptDimensionDivs([...getValues().ddiv.filter((it:any) => it.rptDimId !== getValues().rptDimId), { rptDimId: getValues().rptDimId, ddiv: e.target.value }]);
      setValue('ddiv', [...getValues().ddiv.filter((it:any) => it.rptDimId !== getValues().rptDimId), { rptDimId: getValues().rptDimId, ddiv: e.target.value }]);
    });
  }, []);
  useEffect(() => {
    $('#newFilterNameTxt').on('keyup', (e:any) => {
      if (e.target.value.length > 10) {
        e.target.value = e.target.value.substring(0, 9) + ((e.target.value.substring(11, 12) === '') ? e.target.value.substring(10, 11) : e.target.value.substring(11, 12));
        e.preventDefault();
      }
    });
  }, []);
  return (<Fragment>
        <div className="comp-dropdown">
            <a className="dropdown-toggle" data-toggle="dropdown" href="#!">
                <div className="box-left">
                    <i className="ico ico-analysis-filter"></i>
                    <span className="fz-14 fc-2 fw-medium">분석필터</span>
                </div>
                <div className="box-right">
                    <i className="ico ico-arrow"></i>
                </div>
            </a>
            <div className="dropdown-menu">
                <ul className="opt-selectbox">
                    <li className={searchDimId === 0 ? 'opt-menu selected' : 'opt-menu'} onClick={() => analysisFilterSrch(0)} role='presentation'>
                        <p className="fc-1">기본 분석대상</p>
                    </li>
                     {
                         filters?.map((a:IRptDim) => (
                            <li className={searchDimId === a.rptDimId ? 'opt-menu selected' : 'opt-menu'} key={a.rptDimId} onClick={() => analysisFilterSrch(a.rptDimId)} role='presentation' >
                                <p className="fc-1" >{a.groupNm}</p>
                            </li>
                         ))
                     }
                    <li className="opt-menu bottom">
                        <a className="fw-medium txt-link" href="#!" id="rptAnalysisFilterOpenBtn">분석 대상 설정</a>
                    </li>
                </ul>
            </div>
        </div>
          <div id="rptFilterDialog" className="dialog" style={{ display: 'none' }}>
              <div className="dialog-body">
                  <div className="container-fluid">
                      <div className="row flex-container">
                          <div className="col col-6">
                              <div className="wrap-section wrap-tbl">
                                  <div className="box-header">
                                      <div className="box-tit">
                                          <h2 className="fz-20 fc-1 fw-bold">분석필터 그룹</h2>
                                      </div>
                                      <div className="box-option">
                                          <button type="button" className="btn btn-primary w-100" id="newFilterOpenBtn">신규</button>
                                          <button type="button" className="btn btn-secondary-outline w-100" id="deleteFilterBtn">삭제</button>
                                      </div>
                                  </div>
                                  <div className="box-body">
                                      <table className="tbl selected group-list">
                                          <thead></thead>
                                          <colgroup>
                                              <col width="50%" />
                                          </colgroup>
                                          <tbody>
                                          <tr>
                                              <td className={filter.rptDimId === 0 ? 'selected' : ''} id="defFilter">
                                                  <span className="fz-16 fc-3">기본 분석필터</span>
                                              </td>
                                          </tr>
                                          {
                                              filters?.map((a:IRptDim) => {
                                                $(`#filterGroup02${a.rptDimId}`).off('click');
                                                $(`#filterGroup02${a.rptDimId}`).on('click', (e:any) => {
                                                  e.preventDefault();
                                                  filterGroupCharge(e, a);
                                                });
                                                return (<tr key={a.rptDimId} >
                                                      <td className={filter?.rptDimId === a.rptDimId ? 'selected' : ''} id={`filterGroup02${a.rptDimId}`}>
                                                          <span className="fz-16 fc-3">{a.groupNm}</span>
                                                      </td>
                                                  </tr>);
                                              })
                                          }
                                          </tbody>
                                      </table>
                                  </div>
                              </div>
                          </div>
                          <div className="col col-6">
                              <div className="wrap-section wrap-tbl">
                                  <div className="box-header">
                                      <div className="box-tit">
                                          <h2 className="fz-20 fc-1 fw-bold">대상 항목</h2>
                                      </div>
                                  </div>
                                  <div className="box-body">
                                      <div className="tbl">
                                          <dl className="vertical">
                                              <dt>
                                                  <div className="dt-inner">
                                                      <span className="fz-16 fc-1">시간별</span>
                                                  </div>
                                              </dt>
                                              <dd>
                                                  <div className="form-group">
                                                      <div className="comp-radio block">
                                                            <input type="radio" id="rptDayRadio" name="rptFilterDate" value="day" checked={rptFilterDate.filter((it) => it.rptDimId === filter?.rptDimId && it.date === 'day').length > 0} disabled={filter.rptDimId === 0} readOnly />
                                                          <label htmlFor="rptDayRadio">일간</label>
                                                      </div>
                                                      <div className="comp-radio block">
                                                          <input type="radio" id="rptWeekRadio" name="rptFilterDate" value="week" checked={rptFilterDate.filter((it) => it.rptDimId === filter?.rptDimId && it.date === 'week').length > 0} disabled={filter.rptDimId === 0} readOnly />
                                                          <label htmlFor="rptWeekRadio">주간</label>
                                                      </div>
                                                      <div className="comp-radio block">
                                                          <input type="radio" id="rptMonthRadio" name="rptFilterDate" value="month" checked={rptFilterDate.filter((it) => it.rptDimId === filter?.rptDimId && it.date === 'month').length > 0} disabled={filter.rptDimId === 0} readOnly />
                                                          <label htmlFor="rptMonthRadio">월간</label>
                                                      </div>
                                                      <div className="comp-radio block">
                                                          <input type="radio" id="rptTotalRadio" name="rptFilterDate" value="total" checked={rptFilterDate.filter((it) => it.rptDimId === filter?.rptDimId && it.date === 'total').length > 0} disabled={filter.rptDimId === 0} readOnly />
                                                          <label htmlFor="rptTotalRadio">기간합계</label>
                                                      </div>
                                                  </div>
                                              </dd>
                                          </dl>
                                          <dl className="vertical">
                                              <dt>
                                                  <div className="dt-inner">
                                                      <span className="fz-16 fc-1">게재별</span>
                                                  </div>
                                              </dt>
                                              <dd>
                                                  <div className="form-group">
                                                      <div className="comp-checkbox block">
                                                          <input type="checkbox" id="rptProjectCb" name="rptFilterDiv" value="projectName" checked={rptFilterDivs.filter((it) => it.rptDimId === filter?.rptDimId && (it.div.filter((it2) => it2 === 'projectName').length > 0)).length > 0} disabled={filter.rptDimId === 0} readOnly />
                                                          <label htmlFor="rptProjectCb">프로젝트</label>
                                                      </div>
                                                      <div className="comp-checkbox block">
                                                          <input type="checkbox" id="rptProjectTypeCb" name="rptFilterDiv" value="projectDiv4" checked={rptFilterDivs.filter((it) => it.rptDimId === filter?.rptDimId && (it.div.filter((it2) => it2 === 'projectDiv4').length > 0)).length > 0} disabled={filter.rptDimId === 0 || (rptFilterDivs.filter((it) => it.rptDimId === filter?.rptDimId && (it.div.filter((it2) => it2 === 'projectName').length === 0)).length !== 0)} readOnly />
                                                          <label htmlFor="rptProjectTypeCb">프로젝트 유형</label>
                                                      </div>
                                                      <div className="comp-checkbox block">
                                                          <input type="checkbox" id="rptCampCb" name="rptFilterDiv" value="campNm" checked disabled readOnly />
                                                          <label htmlFor="rptCampCb">캠페인</label>
                                                      </div>
                                                      <div className="comp-checkbox block">
                                                          <input type="checkbox" id="rptAdSetCb" name="rptFilterDiv" value="adSetName" checked={rptFilterDivs.filter((it) => it.rptDimId === filter?.rptDimId && (it.div.filter((it2) => it2 === 'adSetName').length > 0)).length > 0} disabled={filter.rptDimId === 0} readOnly />
                                                          <label htmlFor="rptAdSetCb">광고세트</label>
                                                      </div>
                                                      <div className="comp-checkbox block">
                                                          <input type="checkbox" id="rptAdCreationCb" name="rptFilterDiv" value="creationLinkName" checked={rptFilterDivs.filter((it) => it.rptDimId === filter?.rptDimId && (it.div.filter((it2) => it2 === 'creationLinkName').length > 0)).length > 0} disabled={filter.rptDimId === 0} readOnly />
                                                          <label htmlFor="rptAdCreationCb">광고소재</label>
                                                      </div>
                                                      <div className="comp-checkbox block">
                                                          <input type="checkbox" id="rptImpMdCb" name="rptFilterDiv" value="mediaDiv5" checked={rptFilterDivs.filter((it) => it.rptDimId === filter?.rptDimId && (it.div.filter((it2) => it2 === 'mediaDiv5').length > 0)).length > 0} disabled={filter.rptDimId === 0} readOnly />
                                                          <label htmlFor="rptImpMdCb">노출매체</label>
                                                      </div>
                                                      <div className="comp-radio block">
                                                          <input type="radio" id="rptDimensionEmptyR" name="rptDimensionDiv" value="empty" checked={rptDimensionDivs.filter((it) => it.rptDimId === filter?.rptDimId && it.ddiv === 'empty').length > 0} disabled={filter.rptDimId === 0} readOnly />
                                                          <label htmlFor="rptDimensionEmptyR">선택없음</label>
                                                      </div>
                                                      <div className="comp-radio block">
                                                          <input type="radio" id="rptDimensionImpDeviceR" name="rptDimensionDiv" value="device" checked={rptDimensionDivs.filter((it) => it.rptDimId === filter?.rptDimId && it.ddiv === 'device').length > 0} disabled={filter.rptDimId === 0} readOnly />
                                                          <label htmlFor="rptDimensionImpDeviceR">노출기기</label>
                                                      </div>
                                                      <div className="comp-radio block">
                                                          <input type="radio" id="rptDimensionGenderR" name="rptDimensionDiv" value="gender" checked={rptDimensionDivs.filter((it) => it.rptDimId === filter?.rptDimId && it.ddiv === 'gender').length > 0} disabled={filter.rptDimId === 0} readOnly />
                                                          <label htmlFor="rptDimensionGenderR">성별</label>
                                                      </div>
                                                      <div className="comp-radio block">
                                                          <input type="radio" id="rptDimensionAgeR" name="rptDimensionDiv" value="age" checked={rptDimensionDivs.filter((it) => it.rptDimId === filter?.rptDimId && it.ddiv === 'age').length > 0} disabled={filter.rptDimId === 0} readOnly />
                                                          <label htmlFor="rptDimensionAgeR">나이</label>
                                                      </div>
                                                      <div className="comp-radio block">
                                                          <input type="radio" id="rptDimensionRegionR" name="rptDimensionDiv" value="region" checked={rptDimensionDivs.filter((it) => it.rptDimId === filter?.rptDimId && it.ddiv === 'region').length > 0} disabled={filter.rptDimId === 0} readOnly />
                                                          <label htmlFor="rptDimensionRegionR">지역</label>
                                                      </div>
                                                  </div>
                                              </dd>
                                          </dl>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="dialog-footer">
                  <button type="button" className="btn btn-secondary-outline large" id="rptAnalysisFilterDialogCanclBtn">취소</button>
                  <button type="button" className="btn btn-primary large" id="rptAnalysisFilterDialogSaveBtn">저장</button>
              </div>
          </div>
      <div id="newFilterDialog" className="dialog" style={{ display: 'none' }}>
          <div className="dialog-body">
              <div className="wrap-section wrap-tbl">
                  <div className="box-body">
                      <div className="tbl">
                          <dl>
                              <dt>
                                  <div className="dt-inner">
                                      <span className="fz-16 fc-1">그룹명</span>
                                  </div>
                              </dt>
                              <dd>
                                  <div className="form-group">
                                      <div className="input-group expand">
                                          <div className="inner-input-group">
                                              <input type="text" className="tf-comm" id="newFilterNameTxt" {...register('groupNm', { required: { value: true, message: '그룹명을 입력하세요.' }, minLength: { value: 2, message: '그룹명은 2~10자까지 입력해 주세요.' }, maxLength: { value: 10, message: '그룹명은 2~10자까지 입력해 주세요.' } })} placeholder="그룹명은 2~10자까지 입력해 주세요." />
                                          </div>
                                          <p className="fz-12"></p>
                                      </div>
                                  </div>
                              </dd>
                          </dl>
                      </div>
                  </div>
              </div>
          </div>
          <div className="dialog-footer">
              <button type="button" className="btn btn-secondary-outline large" id="newFilterDialogCanclBtn">취소</button>
              <button type="button" className="btn btn-primary large" id="newFilterDialogSaveBtn">등록</button>
          </div>
      </div>
      </Fragment>
  );
};

export default RptAnalysisFilter;
