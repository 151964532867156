import { Form } from 'antd'
import { ReactComponent as InfoIcon } from '@common/svg/info.svg'
import { useProjectListQuery } from '@biz/query'
import { targetFormRegistry } from '@biz/ui'
import styles from './AdultProjectNoticeFormItem.module.scss'

const Notice: React.FC = () => (
  <div className={styles.AdultProjectNoticeFormItem}>
    <InfoIcon />
    <div className={styles.notice}>
      {'선택하신 프로젝트는 성인 인증이 필요한 프로젝트입니다. 캠페인 타겟이 20세 이상으로 자동 선택됩니다.'}
    </div>
  </div>
)

const { ProjectSelectorName } = targetFormRegistry
export const AdultProjectNoticeFormItem = () => {
  const { data: projectList } = useProjectListQuery()
  return (
    <Form.Item dependencies={[ProjectSelectorName]}>
      {({ getFieldValue }) => {
        const selectedProjectName = getFieldValue(ProjectSelectorName)
        const project = projectList?.find((n) => n.id === selectedProjectName)
        return !!project && project.isAdultContent === true && <Notice />
      }}
    </Form.Item>
  )
}
