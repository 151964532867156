import dayjs from 'dayjs'
import type { ProjectInfoType } from '@biz/api'
import type { FundingType } from './FundingTypeItem/fundingType'

// CampProjectSelectDialog.tsx에서는 프로젝트를 선택할 수 없다고 메시지를 찍게 되어 있는데
// 그렇게 하는 것보다 프로젝트를 선택하지 못하게 하면 된다.
const checkFunding = (projectInfo: ProjectInfoType) => {
  const today = dayjs()
  const endDay = dayjs(projectInfo.adPossEndTime)
  const startDay = dayjs(projectInfo.adPossStartTime)
  if (!endDay.isValid() || !startDay.isValid()) {
    return true
  }
  return endDay.diff(today, 'day', false) + 1 > 1 && endDay.diff(startDay, 'day', false) + 1 > 3
}

const checkComingSoon = (projectInfo: ProjectInfoType) => {
  const today = dayjs()
  const endDay = dayjs(projectInfo.rcsEndTime)
  const startDay = dayjs(projectInfo.rcsPostTime)
  if (!endDay.isValid() || !startDay.isValid()) {
    return true
  }
  return endDay.diff(today, 'day', false) + 1 > 1 && endDay.diff(startDay, 'day', false) + 1 > 3
}

export const checkDayLimit = (projectInfo: ProjectInfoType, fundingType: FundingType) =>
  fundingType === 'FUNDING_ING' ? checkFunding(projectInfo) : checkComingSoon(projectInfo)

