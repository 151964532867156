import React, {
  forwardRef,
  Fragment, useEffect, useRef
} from 'react';
import { Dialog } from 'rc-easyui';
import { PlainObject } from 'select2';
import { isEmpty } from 'lodash';
import alertify from 'alertifyjs'
import { useMutation } from '@tanstack/react-query'
import { ApiUtil2 } from '@biz/api'

const MngMdFbPageAddDialog = (props: any, ref: any) => {
  const adPageSelect = useRef<HTMLSelectElement>(null)

  useEffect(() => {
    // 광고 노출 페이지, 광고 노출 인스타그램 계정 목록 변경 되면 초기화
    $(adPageSelect.current as PlainObject).select2({
      width: '600px',
      placeholder: '광고를 노출 대상 목록에 추가할 페이지를 선택해 주세요.',
    })
  }, [])

  const mediaFbPageAdd = useMutation(
    (formData: any) => ApiUtil2.post(`/api/mngMdFb/mediaFb/fbPage/${props.mediaConfigId}`, formData),
    {
      onSuccess: (resp) => {
        if (resp.data.ok) {
          alertify.success('정상적으로 추가되었습니다')
          props.setShowFbPageAddDialog(false)
          props.setMediaFbPageList(resp.data.data)
        }
      },
    }
  )

  const pageAdd = () => {
    if (isEmpty(adPageSelect.current?.value)) {
      alertify.error('추가하실 페이지를 선택하신 후,<br/>다시 시도해주세요.')
      return
    }
    const pageId = adPageSelect.current?.value
    const name = adPageSelect.current?.selectedOptions[0].textContent

    // 매체 설정 신규 추가 시에는 useState만 갱신
    // 매체 설정 수정 시에는 useState 갱신 + DB 갱신
    if (
      props.mediaFbPageList.filter((v: any) => v.adDpPageId == pageId && v.sortOrder == props.makerSetNum).length > 0
    ) {
      alertify.error('선택하신 페이지는 이미 추가된 페이지입니다.<br/>확인 후 다시 시도해 주세요.')
      return
    }

    if (props.isUpdate && props.mediaConfigId != null) {
      const formData = {
        adDpPageId: pageId,
        name,
        useYn: false,
        sortOrder: props.makerSetNum,
      }
      mediaFbPageAdd.mutate(formData)
    } else {
      props.setMediaFbPageList((prevState: any) => {
        const updateState = [...prevState]
        updateState.push({
          adDpPageId: pageId,
          name,
          useYn: false,
          sortOrder: props.makerSetNum,
        })

        return updateState
      })

      alertify.success('정상적으로 추가되었습니다')
      props.setShowFbPageAddDialog(false)
    }
  }

  return (
    <Fragment>
      <Dialog title="페이지 관리" modal resizable shadow={false} style={{ width: '600px' }}>
        <div id="dialog32" className="dialog">
          <div className="dialog-body">
            <div className="wrap-section wrap-tbl">
              <div className="box-body">
                <div className="tbl">
                  <dl className="vertical">
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">페이지 선택</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <select className="select2-single-search expand" ref={adPageSelect}>
                          <option></option>
                          {props.adPageList.map((item: any) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div className="dialog-footer">
            <button
              type="button"
              className="btn btn-secondary-outline large"
              onClick={() => props.setShowFbPageAddDialog(false)}
            >
              취소
            </button>
            <button type="button" className="btn btn-primary large" onClick={pageAdd}>
              추가
            </button>
          </div>
        </div>
      </Dialog>
    </Fragment>
  )
}

export default forwardRef(MngMdFbPageAddDialog)
