import { useState, useCallback } from 'react';
import { Typography, Flex, DatePicker, Button } from 'antd';
import dayjs, { type Dayjs } from 'dayjs';
import { useDownloadFile } from './_queries/useDownloadFile';
import { ReactComponent as CalendarOutline } from './calendarOutline.svg';

export const PeriodPicker: React.FC = () => {
  const [loading, onLoading] = useState(false);
  const [period, setPeriod] = useState<[Dayjs | null, Dayjs | null] | null>(
    null
  );
  const downloader = useDownloadFile();
  const doDownload = useCallback(() => {
    if (!period || !period[0] || !period[1]) {
      return;
    }
    const params = `startDate=${period[0]?.format('YYYYMMDD') || ''}&endDate=${
      period[1]?.format('YYYYMMDD') || ''
    }`;
    downloader({ params, onLoading });
  }, [downloader, period]);
  return (
    <Flex gap={16}>
      <Typography.Text
        style={{ height: 40, lineHeight: '40px', fontSize: '14px' }}
      >
        {'기간 설정'}
      </Typography.Text>
      <DatePicker.RangePicker
        value={period}
        onChange={setPeriod}
        suffixIcon={<CalendarOutline style={{ width: 16, color: '#495057' }} />}
        cellRender={(current) => {
          return (
            <div className='ant-picker-cell-inner'>
              {(current as Dayjs).date()}
            </div>
          );
        }}
        disabledDate={(current: Dayjs, { from }) => {
          const now = dayjs().startOf('day');
          if (from) {
            return Math.abs(current.diff(from, 'days')) > 30 || current > now;
          }
          return current > now;
        }}
        style={{ width: 300 }}
        placeholder={['시작일', '종료일']}
      />
      <Button
        type='primary'
        disabled={!period || !period[0] || !period[1]}
        style={{
          width: 120,
          height: 40,
          color: '#fff',
        }}
        onClick={doDownload}
        loading={loading}
      >
        {'다운로드'}
      </Button>
    </Flex>
  );
};
