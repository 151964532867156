import { useState, useEffect } from 'react'
import alertify from 'alertifyjs'
import { Form } from 'antd'
import { GlobalSpin } from '@platform/share/ui'
import { type ValidCheckInfoType } from '@biz/api'
import { InspectionBypassModal, targetFormRegistry } from '@biz/ui'
import { useCampaignValidQuery } from '@biz/query'
import { useCheckListQuery } from './_queries'
import { MakerCheckListModal } from './MakerCheckListModal'

const { CreativeAssetsName, ProjectSelectorName } = targetFormRegistry
type KeywordValidationStepFunction = {
  isProcess: boolean
  onClose: () => void
  onNextProcess: () => void
  initData: any
}
export const KeywordValidationStep: React.FC<KeywordValidationStepFunction> = ({
  isProcess,
  onClose,
  onNextProcess,
  initData,
}) => {
  const form = Form.useFormInstance()
  const confirmData = form.getFieldsValue()

  // 1. 입력된 콘텐츠에서 키워드를 검수한다.
  const creativeAssets = confirmData?.[CreativeAssetsName] || initData?.[CreativeAssetsName]
  const projectId = confirmData?.[ProjectSelectorName]
  const isValidReady = isProcess === true && (creativeAssets || []).length > 0 && !!projectId

  const useCampaignValidQueryResult = useCampaignValidQuery(isValidReady)
  const { data: res, isLoading: isValidDataLoading } = useCampaignValidQueryResult
  const { data: validData, ok: validation } = res || ({} as { ok: boolean; data: ValidCheckInfoType })
  const inChecking = isProcess === true && isValidDataLoading

  // 2. 문제 있는 키워드가 있다면, 그냥 진행할지 여부를 묻고 진행한다.
  const [isIgnore, setIgnore] = useState(false)
  const isIgnoreOpen = isProcess === true && !isValidDataLoading && validData?.catProhKwdValid === false && !isIgnore

  //    체크리스트를 확인하고, onNextProcess로 간다.
  const inCheckList = Boolean(validData?.catProhKwdValid) || isIgnore
  const { data: checkListData, isLoading: isCheckListLoading } = useCheckListQuery(inCheckList)
  const isLoading = isProcess === true && (isValidDataLoading || isCheckListLoading)

  useEffect(() => {
    if (validation === false) {
      const { title, msg } = validData as unknown as { title: string; msg: string } // TODO 타입정리가 필요함.
      alertify.error(msg || '알려지지 않은 오류가 있습니다. ', { title })
      onClose()
    }
  }, [validation, validData])

  useEffect(() => {
    // 쿼리의 enabled 옵션으로 최초 실행 여부를 결정하고 이후 쿼리 재실행은 refetch 함수를 사용해야 합니다.
    if (isValidReady) {
      useCampaignValidQueryResult.refetch()
    }
  }, [isValidReady])

  return (
    <>
      <GlobalSpin loading={isLoading} />
      {!inChecking && (
        <InspectionBypassModal
          category={validData?.category || ''}
          keywords={validData?.catProhKwd || []}
          isOpen={isIgnoreOpen}
          onClose={onClose}
          onConfirm={() => setIgnore(true)}
        />
      )}
      {!isLoading && (
        <MakerCheckListModal data={checkListData} isOpen={isProcess} onClose={onClose} onConfirm={onNextProcess} />
      )}
    </>
  )
}
