import { ConfigProvider } from 'antd'
import { ToastWrapper, wadizLocale, antdTheme } from '@common/ui'
import { Pages } from '../pages'

import '../assets/css/fonts/NotoSansKR/NotoSansKR.css'
import '../assets/css/common.css'
import '../assets/css/layout.css'
import '../assets/css/plugin.css'

export default function App() {
  // useEffect(() => {
  //   const fetchData = async () => {
  //     urlRef.current = location.pathname
  //     setUrl(urlRef.current)
  //     // console.log(urlRef)
  //     //쉐도우 로그인상태와 로그인 화면에서는 권한체크안한다
  //     if (location.pathname === '/common/login' || auth !== 'ROLE_ADMIN') return
  //     try {
  //       await ApiUtil2.get('/api/common/adminLogReg', {
  //         params: {
  //           data: { url: urlRef.current },
  //         },
  //       })
  //     } catch (error) {
  //       console.error('API 호출 에러:', error)
  //     }
  //   }
  //   fetchData()
  // }, [location.pathname])
  return (
    <ConfigProvider locale={wadizLocale} theme={antdTheme}>
      <ToastWrapper />
      <Pages />
    </ConfigProvider>
  )
}
