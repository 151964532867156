import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {AgGridReact} from "ag-grid-react";
import {ColDef, ICellRendererParams} from "ag-grid-community";
import NoDataTemplate from "../../../common/NoDataTemplate";
import { ApiUtil2 } from '@biz/api'
import {IAODTInfo} from "./AODTManagementDialog";
interface targetInfo {
    serialId: string;
    makerId: string;
    makerNm: string;
    projectDiv: string;
    project2Id: string;
    serviceType: string;
    serviceId: string;
    serviceArea: string;
    basicFee: number;
    calCompleteDate: string;
    amountFee: number;
    adjust: number;
    modifiedYn: boolean;
}
const AODTGrid = (props: any) => {
    const gridRef = useRef<AgGridReact>(null);
    const amountFeeRenderer = (e: any) => (e.data.amountFee === 0 || e.data.amountFee === undefined ? '-' : e.data.amountFee.toLocaleString());
    const adjustFeeRenderer = (e: any) => ((e.data.adjust === 0 || e.data.adjust === undefined) && !e.data.modifiedYn ? '-' : e.data.adjust.toLocaleString());
    const basicFeeRenderer = (e: any) => ((e.data.basicFee / 100) + '%');
    const projectDivRenderer = (e: any) => e.data.projectDiv === 'FUNDING' ? '펀딩' : '스토어';
    const serviceTypeRenderer = (e: any) => e.data.serviceType === 'BIZ_AD' ? '광고' : '컨설팅';
    const tooltipRenderer = (props: ICellRendererParams) => {
        let serialId = props.data.serialId;
        const tooltip = <div id={serialId}
                             style={{textOverflow: 'ellipsis',
                                 whiteSpace: 'nowrap',
                                 overflow: 'hidden',}}>{serialId}</div>
        $('#'+`${serialId}`).tooltip({
            content: '<p class="fz-12 fc-0">'+serialId+'</p>'
        });
        return (
            tooltip
        )
    };

    const [rowData, setRowData] = useState<targetInfo[]>([]);
    const [columnDefs] = useState<ColDef[]>([
        {
            field: 'serialId',
            headerName: '정산 Serial',
            cellClass: 'ag-left-aligned-cell',
            cellRenderer: tooltipRenderer,
        },
        {
            field: 'makerId',
            headerName: '메이커 번호',
            cellClass: 'ag-left-aligned-cell',
        },
        {
            field: 'makerNm',
            headerName: '메이커명',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
        },
        {
            field: 'projectDiv',
            headerName: '프로젝트 유형',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
            cellRenderer: projectDivRenderer,

        },
        {
            field: 'project2Id',
            headerName: '프로젝트 번호',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
        },
        {
            field: 'serviceType',
            headerName: '서비스 유형',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
            cellRenderer: serviceTypeRenderer,
        }
    ]);

    const [columnDefs2] = useState<ColDef[]>([
        {
            field: 'serviceId',
            headerName: '서비스 ID',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
        },
        {
            field: 'serviceArea',
            headerName: '서비스 영역',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
            cellRenderer: (props: ICellRendererParams) => {
                let serviceArea = props.data.serviceArea;
                if (serviceArea == 'FBITG') return '페이스북/인스타';
                else if (serviceArea == 'INTEGRATION_PLATFORM') return '통합광고 플랫폼';
                else if (serviceArea == 'FB') return '페이스북';
                else if (serviceArea == 'INSTAGRAM') return '인스타';
                else if (serviceArea == 'GG') return '구글DA';
                else if (serviceArea == 'BIZ_CENTER') return '비즈센터';
            }
        },
        {
            field: 'basicFee',
            headerName: '수수료율',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
            cellRenderer: basicFeeRenderer,
        },
        {
            field: 'calCompleteDate',
            headerName: '정산 완료일',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
        },
        {
            field: 'amountFee',
            headerName: '최종 정산 금액',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
            cellRenderer: amountFeeRenderer,
        },
        {
            field: 'adjust',
            headerName: '조정금액',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
            cellRenderer: adjustFeeRenderer,
        },
    ]);

    const onGridReady = () => {
        ApiUtil2.get<IAODTInfo>('/api/bzmAdmin/postpaidMoneyManagement/AODT/targetInfo?serialId='+props.serialId
        ).then((resp) => {
            let recentData = resp.data.data[0];
            const arr: targetInfo[] = [{
                serialId: recentData.serialId,
                makerId: recentData.makerId,
                makerNm: recentData.makerNm,
                projectDiv: recentData.projectDiv,
                project2Id: recentData.project2Id,
                serviceType: recentData.serviceType,
                serviceId: recentData.serviceId,
                serviceArea: recentData.serviceArea,
                basicFee: recentData.basicFee,
                calCompleteDate: recentData.calCompleteDate,
                amountFee: recentData.amountFee,
                adjust: recentData.adjust,
                modifiedYn: recentData.modifiedYn
            }];
            setRowData(arr);
        });
    };

    const defaultColDef = useMemo<ColDef>(() => ({
        flex: 1,
        resizable: false,
        suppressMovable: true,
        sortable: true,
    }), []);

    useEffect(()=>{
        onGridReady()
    }, [])

    return (
        <Fragment>
            <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                onGridReady={() => onGridReady()}
                defaultColDef={defaultColDef}
                rowHeight={48}
                domLayout={'autoHeight'}
            />
            <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs2}
                onGridReady={() => onGridReady()}
                defaultColDef={defaultColDef}
                rowHeight={48}
                domLayout={'autoHeight'}
            />
            { rowData === undefined ? <NoDataTemplate/> : null}
        </Fragment>
    );
};

export default AODTGrid;
