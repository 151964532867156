import { useQuery } from '@tanstack/react-query'
import { fetchValidCheck } from '@biz/api'
import { useCurrentCampaignIdQuery } from '@biz/query'

export const useCampaignValidQuery = (isEnabled: boolean, creationDataId?: number) => {
  const { data: campaignId } = useCurrentCampaignIdQuery()
  return useQuery({
    queryKey: ['@apps/biz/TargetCreatePage/useCampaignValidQuery', `${campaignId}${creationDataId || ''}`],
    queryFn: () => (!!campaignId ? fetchValidCheck(campaignId, creationDataId) : Promise.reject({})),
    enabled: isEnabled && Boolean(campaignId),
  })
}
