import React, {
  Fragment, useEffect, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import alertify from 'alertifyjs'
import { IMakerConfig } from './MakerSetting';
import { ApiUtil } from '@biz/api'

const MakerSettingDialog = (props : any) => {
  /** 다이얼로그 */
  const [bankList, setBankList] = useState<string[][]>([]);

  function regexFunc(value:any) {
    const regex = /[^0-9-]/gi;
    if (regex.test(value)) {
      $('#refundAccNum').val('');
    }
  }

  const successEvent = (msg:string) => {
    alertify.success(msg, 1);
  };

  const confirmSuccess = (msg:string) => {
    successEvent(msg);
  };

  const {
    handleSubmit, register,
  } = useForm<IMakerConfig>();

  const onSubmit = () => {
    if (($('#bank').val() === '' || $('#refundAccName').val() === '' || $('#refundAccNum').val() === '')) {
      alertify.error('미입력 항목이 있습니다.');
      return;
    }

    ApiUtil.post<IMakerConfig>('/api/maker/setting/refundAcc', {
      makerId: props.makerId, refundBank: $('#bank').val(), refundAccName: $('#refundAccName').val(), refundAccNum: $('#refundAccNum').val(),
    }).then(() => {
      confirmSuccess('변경사항이 저장되었습니다.');
      props.setIsAccClicked(false);
      props.getPrevData();
      $('#accApiRegDialog').dialog('close');
    });
  };

  // 벨리데이션시 사용
  const onErr = (error: any) => {
    if (error.refundAccNum !== undefined) {
      if (error.projectId.message !== '') {
        alertify.error(error.projectId.message);
      }
    }
  };

  useEffect(() => {
    $('#accApiRegDialog').dialog({ width: 900, title: '계좌 정보를 입력해주세요', closeOnEscape: true });

    /** 셀렉트박스 */
    $('#bank').select2({ width: 'auto', minimumResultsForSearch: Infinity, placeholder: '선택하세요.' });

    /** bankList */
    ApiUtil.get('/api/maker/setting/bankList').then((resp) => {
      const options = Object.keys(resp.data.data).map((key) => [String(key), resp.data.data[key]]);
      setBankList(options);
    });

    /** 등록버튼 */
    $('#submitBtn').on('click', () => {
      onSubmit();
    });
  }, []);

  useEffect(() => {
    $('#refundAccNum').on('change', (e:any) => {
      console.log(e);
      regexFunc($('#refundAccNum').val());
    });
  }, [$('#refundAccNum').val()]);

  useEffect(() => {
    /** 다이얼로그 닫기 */
    $('#accClose').on('click', () => {
      props.setIsAccClicked(false);
      $('#accApiRegDialog').dialog('close');
    });
    return () => {
      props.setIsAccClicked(false);
      $('#accApiRegDialog').dialog('destroy');
    };
  }, []);

  return (
      <>
        <Fragment>
          <form>
            <div id="accApiRegDialog" style={{ display: 'none' }}>
              <div className="dialog-body">
                <form onSubmit={handleSubmit(onSubmit, onErr)}>
                  <div className="wrap-section wrap-tbl">
                    <div className="box-body">
                      <div className="tbl">
                        <dl>
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">
                                  은행
                              </span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <select id="bank" className="select2-single expand">
                                <option></option>
                                {
                                  bankList === undefined ? '' : bankList.map((a:any, k:any) => (
                                      <option key={k} value={a[0]}>{a[1]}</option>
                                  ))
                                }
                              </select>
                            </div>
                          </dd>
                        </dl>
                        <dl>
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">
                                  이름
                               </span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <div className="input-group expand">
                                <div className="inner-input-group">
                                  <input type="text" className="tf-comm tf-unit" {...register('refundAccName')} id='refundAccName'/>
                                </div>
                                <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                              </div>
                            </div>
                          </dd>
                        </dl>
                        <dl>
                          <dt>
                            <div className="dt-inner">
                              <span className="fz-16 fc-1">
                                  계좌번호
                              </span>
                            </div>
                          </dt>
                          <dd>
                            <div className="form-group">
                              <div className="input-group expand">
                                <div className="inner-input-group">
                                  <input
                                      type="text"
                                      className="tf-comm tf-unit"
                                      placeholder="계좌번호를 입력해 주세요."
                                      id='refundAccNum'
                                  />
                                </div>
                                <p className="fz-12 fc-4">계좌 소유주와 동일해야 합니다.</p>
                                <p className="fz-12 fc-4">하이푼(-)을 제외한 숫자만 입력해주세요.</p>
                                <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                              </div>
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="dialog-footer">
                  <button type="button" className="btn btn-secondary-outline large" id="accClose">취소</button>
                  <button type="button" className="btn btn-primary large" id="submitBtn">등록</button>
                </div>
              </div>
            </div>
          </form>
        </Fragment>
      </>
  );
};

export default MakerSettingDialog;
