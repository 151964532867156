import React, { Fragment, useRef } from 'react';
import { Dialog } from 'rc-easyui';
import { Link } from 'react-router-dom';

const ItgDialog2 = (props :any) => {
  const dialogRef = useRef<any>();
  const closeEvnet = () => {
    props.setShowItgDialog(false);
  };
  return (
        <Fragment>
            <Dialog title='이미지 미리보기' modal resizable shadow={false} ref={dialogRef} style={{ width: '600px' }}>
            <div id="itgDialog" className="dialog" style={{ display: 'none' }}>
                <div className="dialog-body">
                    <div className="wrap-section no-border">
                        <div className="box-body">
                            <div className="wrap-preview">
                                <div className="box-body">
                                    <div className="box-group flex-container">
                                        <div className="box-left">
                                            <img
                                                src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Insta-01.png"
                                                alt="img1"/>
                                        </div>
                                        <div className="box-right">
                                            <img
                                                src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Insta-02.png"
                                                alt="img2"/>
                                        </div>
                                    </div>
                                    <div className="box-group">
                                        <div className="box-left">
                                            <img
                                                src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Ico-logo-wadiz-48x48.png"
                                                className="logo" alt="img3"/>
                                        </div>
                                        <div className="box-right">
                                            <p className="fz-14 fc-1 fw-medium lh15">와디즈</p>
                                            <p className="fz-12 fc-3 lh15">광고</p>
                                        </div>
                                    </div>
                                    <div className="box-group">
                                        {props.delYn ?
                                            <div className="comp-no-data">
                                                <div className="box-group">
                                                    <i className="ico ico-no-img"></i>
                                                    <p className="fz-14 fc-2">비즈센터 서버에서 삭제된 이미지/영상입니다.</p>
                                                </div>
                                            </div>
                                            :
                                            <div id="carousel-example-generic-01" className="carousel slide"
                                                 data-ride="carousel" data-interval="false">
                                                <ol className="carousel-indicators">
                                                    {props.row.afList.map((file: any, index: number) => {
                                                        const makeLikey = `fileLiKey_${file.id}`;
                                                        const active = index === 0 ? 'active' : '';
                                                        return (
                                                            <li key={makeLikey} id={makeLikey}
                                                                data-target="#carousel-example-generic-01"
                                                                data-slide-to={index} className={active}>{index + 1}</li>
                                                        );
                                                    })}
                                                </ol>
                                                <div className="carousel-inner" role="listbox">
                                                    {props.row.afList.map((file: any, index: number) => {
                                                        const makeFilekey = `fileKey_${file.id}`;
                                                        const fileIndex = (`00${index + 1}`).slice(-2);
                                                        const active = index === 0 ? 'active' : '';
                                                        const itemClass = `item item${fileIndex} ${active}`;
                                                        return (
                                                            <Link to="#" key={makeFilekey} className={itemClass}>
                                                                {
                                                                    file.afMeta.fileDiv === 'VIDEO' && file.afMeta.ext !== 'gif'
                                                                        ? <video
                                                                            autoPlay
                                                                            controls
                                                                            muted
                                                                            loop
                                                                            style={{width: '100%'}}
                                                                        >
                                                                            <source src={`${file.fileName}`}/>
                                                                        </video>
                                                                        : <img
                                                                            src={`${file.fileName}`}
                                                                            // style={{ width: '100%'}}
                                                                            alt=""/>
                                                                }
                                                            </Link>
                                                        );
                                                    })}
                                                </div>
                                                {props.row.afList.length > 1
                                                    ? <><a className="left carousel-control"
                                                           href="src/components/views/camp/make02/Make02Step03CreationFb#carousel-example-generic-01"
                                                           role="button"
                                                           data-slide="prev">
                                                        <span className="sr-only"></span>
                                                    </a><a className="right carousel-control"
                                                           href="src/components/views/camp/make02/Make02Step03CreationFb#carousel-example-generic-01"
                                                           role="button"
                                                           data-slide="next">
                                                        <span className="sr-only"></span>
                                                    </a></>
                                                    : null
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div className="box-group">
                                        <div className="box-row flex-container">
                                            <div className="box-left">
                                                <span id="itgClickBtn" className="fz-14 fc-1 fw-medium lh15">{props.row !== undefined ? props.row.clickBtnDiv : ''}</span>
                                            </div>
                                            <div className="box-right">
                                                <i className="ico ico-arrow"></i>
                                            </div>
                                        </div>
                                        <div className="box-row border-top">
                                            <img
                                                src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Insta-03.png"
                                                className="logo" alt="img5"/>
                                        </div>
                                    </div>
                                    <div className="box-group">
                                        {
                                            props.row !== undefined ? props.row.adTitle01.split('\n').map((data: any) => (
                                                // eslint-disable-next-line react/jsx-key
                                                <p id="itgAdDesc" className="fz-14 fc-2 lh15 edit">
                                                    {data}
                                                    <br />
                                                </p>
                                            )) : ''
                                        }
                                    </div>
                                </div>
                                <div className="box-footer">
                                    <img
                                        src="https://wabiz-static-dev.s3.ap-northeast-2.amazonaws.com/static/preview/Img-Insta-04.png"
                                        className="logo" alt="img6"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dialog-footer">
                    <button id="itgClose" type="button" className="btn btn-secondary-outline large" onClick={closeEvnet}>닫기</button>
                </div>
            </div>
            </Dialog>
        </Fragment>
  );
};

export default ItgDialog2;
