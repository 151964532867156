import React, {
  createContext,
  Fragment, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import NoDataTemplate from '../common/NoDataTemplate';
import { ApiUtil2 } from '@biz/api'
import Pagination from '../common/Pagination';
import alertify from 'alertifyjs'
import IpUseNTemplate from '../common/IpUseNTemplate';
import AdminIpRegPopUp from './AdminIpRegPopUp';

interface IAdminWhiteIp {
    id: number;
    ip: string;
    ipDesc: string;
    updateTime: string;
    modifier: string;
}

export interface IAdminWhiteIpList {
    ok: boolean;
    data: IAdminWhiteIp[];
}

const dateFormatRenderer = (props: ICellRendererParams) => {
  const { date } = props.value;
  const { time } = props.value;
  return dayjs(new Date(date.year, date.month - 1, date.day, time.hour, time.minute)).format('YYYY-MM-DD HH:mm');
};

export const AdminIpContext = createContext({
  adminIpId: 'step01',
  setAdminIpId: (value: any) => value,
});

const MngAdminIp = () => {
  const [ipUseYn, setIpUseYn] = useState<boolean>(true);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [adminIpId, setAdminIpId] = useState<any>(null);

  const ipRenderer = (adminInfo: ICellRendererParams) => {
    const { value } = adminInfo;

    return <button onClick={() => {
      setIsClicked(true);
      setAdminIpId(adminInfo.data.id);
    }}>{value}</button>;
  };

  useEffect(() => {
    ApiUtil2.get('/api/mngAccount/admin/ip/useYn').then((resp) => {
      setIpUseYn(resp.data.data);
    });
  }, []);

  const { handleSubmit, register, watch } = useForm();
  const childRef = useRef<any>(null);
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>();
  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'ip',
      headerName: '접속 허용 IP',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
      cellRenderer: ipRenderer,
    },
    {
      field: 'ipDesc',
      headerName: '메모',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'updateTime',
      headerName: '최근 수정일시',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: dateFormatRenderer,
    },
    {
      field: 'modifier',
      headerName: '수정자',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
  ]);
    // 컬럼 이동 금지 suppressMovable: true
  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: true,
  }), []);

  const onGridReady = useCallback(() => {
    const { ipOrDesc } = watch();
    ApiUtil2.get<IAdminWhiteIpList>('/api/mngAccount/admin/adminIps', {
      params: {
        data: {
          ipOrDesc,
        },
      },
    }).then((resp) => {
      setRowData(resp.data.data);
    });
  }, []);
    // 그리드에서 페이징 변경시 pagination 에 있는 함수 호출
  const changePagination = () => {
        childRef.current!.onPaginationChanged();
  };

  const ipUseYnHandler = () => {
    const msg:string = ipUseYn ? '관리자 접속 허용 IP 기능 사용을 중단 하시겠습니까?\n <br> 중단 시 모든 IP에서 접속이 가능합니다.' : '관리자 접속 허용 IP 기능을 사용하시겠습니까? <br> 사용 시 등록된 IP로만 관리자 접속이 <br> 가능합니다.';
    alertify.confirm(msg, () => {
      ApiUtil2.post('/api/mngAccount/admin/ip/ipUseYnProcess', { ipUseYn: !ipUseYn }).then(() => {
        setIpUseYn(!ipUseYn);
      });
    }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
  };

  const deleteHandler = () => {
    const selectedRows = gridRef.current!.api.getSelectedRows();
    const adminIps = selectedRows.map((adminData) => adminData.id);
    if (adminIps.length === 0) {
      alertify.error('삭제할 IP를 선택 후 다시 시도해 주세요.');
    } else {
      alertify.confirm('선택하신 접속 허용 IP를 삭제 하시겠습니까?\n <br> 삭제 시 해당 IP에서 관리자 접속이 불가 합니다.', () => {
        ApiUtil2.post('/api/mngAccount/admin/ip/delete', { adminIps }).then(() => {
          onGridReady();
          alertify.success('정상적으로 삭제 되었습니다.\n');
        });
      }).set({ labels: { cancel: '취소', ok: '삭제' } }).setHeader('');
    }
  };

  const clickHandler = () => {
    setIsClicked(true);
  };

  return (
      <Fragment>
          {isClicked
            ? <AdminIpContext.Provider value={{ adminIpId, setAdminIpId }}>
              <AdminIpRegPopUp setIsClicked={setIsClicked} onGridReady={onGridReady}/>
              </AdminIpContext.Provider>
            : null}
            <section className="wrap-section wrap-datagrid">
                <form onSubmit={handleSubmit(onGridReady)}>
                    <div className="wrap-filter">
                        <div className="inner-filter">
                            <div className="box-left">
                                <div className="item-filter">
                                    <div className="filter-tit">
                                        <p className="fz-12 fc-2">IP / 메모</p>
                                    </div>
                                    <div className="box-filter">
                                        <div className="input-group">
                                            <div className="inner-input-group">
                                                <input type="text" className="tf-comm w-650" {...register('ipOrDesc')}
                                                       placeholder="IP / 메모를 입력해 주세요."
                                                       />
                                            </div>
                                            <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-right">
                                <button type="submit" className="btn btn-tertiary-mint btn-ico">
                                    <i className="ico ico-filter"></i>
                                    조회
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="box-header">
                    <div className="box-tit">
                        <button type="button" className="btn btn-primary-outline" onClick={ipUseYnHandler}> { ipUseYn ? 'IP 관리 사용' : 'IP 관리 미사용'}
                        </button>
                        { !ipUseYn ? ''
                          : <>
                                <button type="button" className="btn btn btn-primary" onClick={clickHandler}>신규 등록
                                </button>
                                <button type="button" className="btn btn btn-secondary-outline"
                                        onClick={deleteHandler}>삭제
                                </button>
                            </>
                        }

                    </div>
                </div>
                <div className="box-body">
                    <div className="ag-grid">
                        <div className="ag-grid-inner">
                            {ipUseYn ? <AgGridReact
                                ref={gridRef}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                onGridReady={onGridReady}
                                rowHeight={48}
                                pagination={true}
                                paginationPageSize={10}
                                suppressPaginationPanel={true}
                                onPaginationChanged={changePagination}
                                domLayout={'autoHeight'}
                                noRowsOverlayComponent={NoDataTemplate}
                                rowSelection={'multiple'}
                            /> : <IpUseNTemplate/>}
                        </div>
                    </div>
                </div>
                {ipUseYn ? <div className="box-footer">
                    <Pagination gridRef={gridRef} ref={childRef}/>
                </div> : null }
                <div className="box-footer">
                    <div className='box-right'>
                        <Link to="/mngAccount/admin"><button className='btn btn-primary'>확인</button></Link>
                    </div>
                </div>
            </section>
        </Fragment>
  );
};

export default React.memo(MngAdminIp);
