import { ColDef } from 'ag-grid-community';
import { DataFormat } from 'select2';
import { GridCustomHeader } from '../common/GridCustomHeader';

export interface IMultiSelectData {
  text: string;
  selected: boolean;
}

export interface IMultiSelect {
  texts: Map<string, string>;
  selects: Map<string, boolean>;
}

export interface ISearchForm {
  searchType: string;
  keyword: string;
}

export interface ITxSearchForm extends ISearchForm {
  searchTypeForCamp?: string;
  searchTypeForConsulting?: string;
  startDate: string;
  endDate: string;
  maker: string;
}

export interface IMakerSearchForm extends ISearchForm {
  searchTypeForMaker: string;
  makerStatus: string;
  accStatus: string;
}

export interface IComponents {
  searchUrl: string;
  colDefs: ColDef[];
  searchTypes: DataFormat[];
  callback: any;
}

export const campGrid: IComponents = {
  searchUrl: '/api/accAdjust/reg/campList',
  colDefs: [
    { field: 'makerLoginId', headerName: '메이커ID', cellClass: 'ag-left-aligned-cell' },
    { field: 'makerName', headerName: '메이커명', cellClass: 'ag-left-aligned-cell' },
    {
      field: 'inUserNo',
      headerComponent: GridCustomHeader,
      headerComponentParams: { header1: '충전', header2: '사업자 번호' },
    },
    {
      field: 'userNo',
      headerComponent: GridCustomHeader,
      headerComponentParams: { header1: '소진', header2: '사업자 번호' },
    },
    { field: 'campId', headerName: '캠페인ID' },
    { field: 'campName', headerName: '캠페인 명' },
    {
      field: 'campStatusDesc',
      headerComponent: GridCustomHeader,
      headerComponentParams: { header1: '캠페인', header2: '상태' },
    },
    {
      field: 'campStartDate',
      headerComponent: GridCustomHeader,
      headerComponentParams: { header1: '캠페인', header2: '시작일' },
    },
    {
      field: 'campEndDate',
      headerComponent: GridCustomHeader,
      headerComponentParams: { header1: '캠페인', header2: '종료일' },
    },
    {
      field: 'paidCost',
      cellClass: 'ag-right-aligned-cell',
      headerComponent: GridCustomHeader,
      headerComponentParams: { header1: '소진 유상머니', header2: '(VAT 포함)' },
    },
    {
      field: 'freeCost',
      cellClass: 'ag-right-aligned-cell',
      headerComponent: GridCustomHeader,
      headerComponentParams: { header1: '소진 쿠폰', header2: '(VAT 포함)' },
    },
    {
      field: 'deptCost',
      cellClass: 'ag-right-aligned-cell',
      headerComponent: GridCustomHeader,
      headerComponentParams: { header1: '부채', header2: '부채(VAT 포함)' },
    },
  ],
  searchTypes: [{ id: 'inUserNo', text: '충전 사업자 번호' }, { id: 'txUserNo', text: '소진 사업자 번호' }, { id: 'campId', text: '캠페인ID' }, { id: 'campName', text: '캠페인명' }, { id: 'campStatus', text: '캠페인 상태' }],
  callback: (data: ITxSearchForm) => {
    // 디버깅용
    window.console.debug(data);
    return {
      startDate: data.startDate, endDate: data.endDate, maker: data.maker, searchType: data.searchTypeForCamp, keyword: data.keyword,
    };
  },
};

export const consultingGrid: IComponents = {
  searchUrl: '/api/accAdjust/reg/consultingList',
  colDefs: [
    { field: 'makerLoginId', headerName: '메이커ID', cellClass: 'ag-left-aligned-cell' },
    { field: 'makerName', headerName: '메이커명', cellClass: 'ag-left-aligned-cell' },
    {
      field: 'inUserNo',
      headerComponent: GridCustomHeader,
      headerComponentParams: { header1: '충전', header2: '사업자 번호' },
    },
    {
      field: 'userNo',
      headerComponent: GridCustomHeader,
      headerComponentParams: { header1: '소진', header2: '사업자 번호' },
    },
    { field: 'projectId', headerName: '프로젝트ID' },
    { field: 'projectName', headerName: '프로젝트명' },
    {
      field: 'consultingStatusDesc',
      headerName: '광고 대행 상태',
      headerComponent: GridCustomHeader,
      headerComponentParams: { header1: '광고 대행', header2: '상태' },
    },
    { field: 'regTime', headerName: '신청일' },
    {
      field: 'paidCost',
      cellClass: 'ag-right-aligned-cell',
      headerComponent: GridCustomHeader,
      headerComponentParams: { header1: '소진 유상머니', header2: '(VAT 포함)' },
    },
    {
      field: 'freeCost',
      cellClass: 'ag-right-aligned-cell',
      headerComponent: GridCustomHeader,
      headerComponentParams: { header1: '소진 쿠폰', header2: '(VAT 포함)' },
    },
  ],
  searchTypes: [{ id: 'projectId', text: '프로젝트ID' }, { id: 'projectName', text: '프로젝트명' }, { id: 'inUserNo', text: '충전 사업자 번호' }, { id: 'txUserNo', text: '소진 사업자 번호' }, { id: 'consultingStatus', text: '광고 대행 상태' }],
  callback: (data: ITxSearchForm) => {
    window.console.debug(data);
    return {
      startDate: data.startDate, endDate: data.endDate, maker: data.maker, searchType: data.searchTypeForConsulting, keyword: data.keyword,
    };
  },
};

export const makerGrid: IComponents = {
  searchUrl: '/api/accAdjust/reg/makerList',
  colDefs: [
    { field: 'makerLoginId', headerName: '메이커ID', cellClass: 'ag-left-aligned-cell' },
    { field: 'makerName', headerName: '메이커명', cellClass: 'ag-left-aligned-cell' },
    { field: 'makerStatusDesc', headerName: '계정 상태' },
    { field: 'userNo', headerName: '충전 사업자 번호' },
    { field: 'accStatusDesc', headerName: '활성여부' },
    { field: 'paidCost', headerName: '현재 보유 잔액(유상)', cellClass: 'ag-right-aligned-cell' },
    { field: 'freeCost', headerName: '현재 보유 잔액(쿠폰)', cellClass: 'ag-right-aligned-cell' },
  ],
  searchTypes: [{ id: 'makerId', text: '메이커ID', selected: true }, { id: 'makerName', text: '메이커명' }, { id: 'userNo', text: '충전 사업자 번호' }],
  callback: (data: IMakerSearchForm) => {
    const param = {
      makerStatus: data.makerStatus, accStatus: data.accStatus, searchType: data.searchTypeForMaker, keyword: data.keyword,
    };
    window.console.table(param);
    return param;
  },
};

export const defaultColDef: ColDef = {
  flex: 1,
  resizable: false,
  suppressMovable: true,
  sortable: false,
  headerClass: 'ag-center-aligned-header',
  cellClass: 'ag-center-aligned-cell',
};

export const toMultiSelect = (obj: any) : IMultiSelect => {
  const texts = new Map<string, string>();
  const selects = new Map<string, boolean>();
  Object.entries(obj).forEach(([key, value]) => {
    const { text, selected } = value as IMultiSelectData;
    texts.set(key, text);
    selects.set(key, selected);
  });
  return {
    texts, selects,
  };
};

export const toMultiSelectByMap = (texts:Map<string, string>, selects:Map<string, boolean>) : IMultiSelect => ({
  texts, selects,
});

// eslint-disable-next-line no-unused-vars
export const multiSelectHandler = (key: string, checked: boolean, prevState: IMultiSelect, updateStatus: (newState: Map<string, boolean>) => void, updateLabel: (label: string) => void, updateForm: (value: string) => void) => {
  // window.console.log(`${key} : ${checked}`);
  // deep copy
  const newStatus = new Map<string, boolean>(prevState.selects);
  if (key === 'all') {
    newStatus.forEach((v, k) => {
      newStatus.set(k, checked);
    });
  } else {
    newStatus.set(key, checked);
  }

  // 체크된 항목 찾기. '전체'는 항목에서 제외
  const selected = Array.from(newStatus).map(([k, v]) : string => {
    if (k === 'all') return '';
    return v === true ? k : '';
  }).filter((v) => v !== '');

  const count = selected.length;

  if (count === prevState.selects.size - 1) { // '전체'를 제외한 나머지 가 모두 선택되었으면 '전체'도 선택함
    newStatus.set('all', true);
  } else if (count === 0) { // 1도 선택된게 없으면 '전체' 포함 모두 선택 시켜줌
    newStatus.forEach((v, k) => {
      newStatus.set(k, true);
    });
  } else { // 1개라도 해제 되었으면 '전체'는 선택 해제
    newStatus.set('all', false);
  }
  const labels: Array<string> = [];
  // 드랍다운에 보여줄 라벨 정하기
  if (newStatus.get('all') === true) {
    labels.push('전체');
  } else {
    const label = prevState.texts.get(selected[0]);
    labels.push(`${label}`);
    if (count > 1) labels.push(` 외 ${count}`);
  }

  updateForm(newStatus.get('all') === true ? 'all' : selected.join(','));
  updateLabel(labels.join(''));
  updateStatus(newStatus);
};
