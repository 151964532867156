import { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ApiUtil } from '@biz/api'
import alertify from 'alertifyjs'

interface IRptMetricForm {
  searchMetricId: number
  rptMetricId: number
  groupNm: string
  div: any[]
}

interface IRptMetric {
  rptMetricId: number
  memberId: number
  groupNm: string
  metricList: string
  srchYn: boolean
}

interface IRptMetricList {
  ok: boolean
  data: IRptMetric[]
}

const metricDivEnum: any = {
  campBudget: '예산',
  wadizFee: '수수료 (VAT 제외)',
  adspend: '비용 (VAT 제외)',
  impCnt: '노출',
  clickCnt: '클릭',
  clickRate: '클릭률 (CTR)',
  avgClickAdspend: '클릭당 비용 (CPC)',
  convCnt: '전환',
  convRate: '전환율',
  avgConvAdspend: '전환당 비용',
  convCa: '전환 매출',
  rvnRate: '수익률 (ROAS)',
  cpm: 'CPM',
  frequency: '게재빈도',
}

const RptMetric = (props: any) => {
  const [metrics, setMetrics] = useState<IRptMetric[]>([
    {
      rptMetricId: 0,
      groupNm: '기본 광고성과',
      memberId: 0,
      metricList:
        'campBudget,wadizFee,adspend,impCnt,clickCnt,clickRate,avgClickAdspend,convCnt,convRate,avgConvAdspend,convCa,rvnRate,cpm,frequency',
      srchYn: false,
    },
  ])
  const [metric, setMetric] = useState<IRptMetric>({
    rptMetricId: 0,
    groupNm: '기본 광고성과',
    memberId: 0,
    metricList:
      'campBudget,wadizFee,adspend,impCnt,clickCnt,clickRate,avgClickAdspend,convCnt,convRate,avgConvAdspend,convCa,rvnRate,cpm,frequency',
    srchYn: false,
  })
  const [rptMetricDivs, setRptMetricDivs] = useState([
    {
      rptMetricId: 0,
      div: [
        'campBudget',
        'wadizFee',
        'adspend',
        'impCnt',
        'clickCnt',
        'clickRate',
        'avgClickAdspend',
        'convCnt',
        'convRate',
        'avgConvAdspend',
        'convCa',
        'rvnRate',
        'cpm',
        'frequency',
      ],
    },
  ])
  const [rptMetricSelects, setRptMetricSelects] = useState([
    'campBudget',
    'wadizFee',
    'adspend',
    'impCnt',
    'clickCnt',
    'clickRate',
    'avgClickAdspend',
    'convCnt',
    'convRate',
    'avgConvAdspend',
    'convCa',
    'rvnRate',
    'cpm',
    'frequency',
  ])
  const [searchMetricId, setSearchMetricId] = useState(0)
  const { register, setValue, getValues } = useForm<IRptMetricForm>({
    defaultValues: {
      searchMetricId: 0,
      rptMetricId: 0,
      groupNm: '기본 광고성과',
      div: [
        {
          rptMetricId: 0,
          div: [
            'campBudget',
            'wadizFee',
            'adspend',
            'impCnt',
            'clickCnt',
            'clickRate',
            'avgClickAdspend',
            'convCnt',
            'convRate',
            'avgConvAdspend',
            'convCa',
            'rvnRate',
            'cpm',
            'frequency',
          ],
        },
      ],
    },
  })
  const { onGridReady } = props

  const rptMetricSrch = (rptMetricId: number) => {
    setValue('searchMetricId', rptMetricId)
    setSearchMetricId(rptMetricId)
    ApiUtil.post('/api/rptCommon/rptMetric/srchYn', { rptMetricId: getValues().searchMetricId }).then(() => {
      onGridReady()
    })
  }

  const metricSelectStart = (event: any, ui: any) => {
    ui.item.data('start_pos', ui.item.index())
  }

  const metricSelectUpdate = (event: any, ui: any) => {
    const spos = ui.item.data('start_pos')
    const epos = ui.item.index()
    const dataRowChkList: any = getValues().div.filter((it: any) => it.rptMetricId === getValues().rptMetricId)[0].div
    const item = dataRowChkList.splice(spos, 1)
    dataRowChkList.splice(epos, 0, item[0])
    setRptMetricSelects(dataRowChkList)
    setRptMetricDivs([
      ...getValues().div.filter((it: any) => it.rptMetricId !== getValues().rptMetricId),
      { rptMetricId: getValues().rptMetricId, div: dataRowChkList },
    ])
    setValue('div', [
      ...getValues().div.filter((it: any) => it.rptMetricId !== getValues().rptMetricId),
      { rptMetricId: getValues().rptMetricId, div: dataRowChkList },
    ])
  }

  const metricGroupCharge = (e: any, a: IRptMetric) => {
    setMetric(a)
    const data: [] =
      getValues().div.filter((it: any) => it.rptMetricId === a.rptMetricId).length > 0
        ? getValues().div.filter((it: any) => it.rptMetricId === a.rptMetricId)[0].div
        : a.metricList.split(',')
    setRptMetricSelects(data)
    setRptMetricDivs([
      ...rptMetricDivs.filter((it: any) => it.rptMetricId !== a.rptMetricId),
      getValues().div.filter((it: any) => it.rptMetricId === a.rptMetricId).length > 0
        ? getValues().div.filter((it: any) => it.rptMetricId === a.rptMetricId)[0]
        : { rptMetricId: a.rptMetricId, div: a.metricList.split(',') },
    ])
    setValue('rptMetricId', a.rptMetricId)
    setValue('groupNm', a.groupNm)
    setValue('div', [
      ...getValues().div.filter((it: any) => it.rptMetricId !== a.rptMetricId),
      getValues().div.filter((it: any) => it.rptMetricId === a.rptMetricId).length > 0
        ? getValues().div.filter((it: any) => it.rptMetricId === a.rptMetricId)[0]
        : { rptMetricId: a.rptMetricId, div: a.metricList.split(',') },
    ])
    $('#sortable01')
      .sortable({
        connectWith: '.sortable',
        start: metricSelectStart,
        update: metricSelectUpdate,
        disabled: false,
      })
      .disableSelection()
  }

  useEffect(() => {
    ApiUtil.get<IRptMetricList>('/api/rptCommon/rptMetric/list').then((resp) => {
      setMetrics(resp.data.data)
      resp.data.data.map((a: IRptMetric) => {
        if (a.srchYn) {
          setSearchMetricId(a.rptMetricId)
          setValue('searchMetricId', a.rptMetricId)
        }
        return null
      })
    })
  }, [])
  useEffect(() => {
    $('#rptMetricOpenBtn').on('click', () => {
      $('#rptMetricDialog').dialog({ width: 1200, title: '성과 항목 설정', closeOnEscape: true })
      $('#rptMetricDialog').dialog('open')
    })
    $('#newMetricOpenBtn').on('click', () => {
      $('#newMetricDialog').dialog({ width: 600, title: '신규 성과 항목 등록', closeOnEscape: true })
      $('#newMetricDialog').dialog('open')
      $('#newMetricNameTxt').val('')
    })
    $('#rptMetricDialogCanclBtn').on('click', () => {
      $('#rptMetricDialog').dialog('close')
    })
    $('#newMetricDialogCanclBtn').on('click', () => {
      $('#newMetricDialog').dialog('close')
    })
    $('#deleteMetricBtn').on('click', () => {
      const dataId = getValues().rptMetricId
      if (dataId === 0) {
        alertify.error('기본 광고 성과 그룹은 삭제가 불가 합니다.')
      } else {
        ApiUtil.post<IRptMetricList>('/api/rptCommon/rptMetric/delete', { rptMetricId: dataId }).then((resp) => {
          setMetrics(resp.data.data)
          if (resp.data.data.length === 0) {
            setMetric({
              rptMetricId: 0,
              groupNm: '기본 광고성과',
              memberId: 0,
              metricList:
                'campBudget,wadizFee,adspend,impCnt,clickCnt,clickRate,avgClickAdspend,convCnt,convRate,avgConvAdspend,convCa,rvnRate,cpm,frequency',
              srchYn: true,
            })
            setRptMetricSelects([
              'campBudget',
              'wadizFee',
              'adspend',
              'impCnt',
              'clickCnt',
              'clickRate',
              'avgClickAdspend',
              'convCnt',
              'convRate',
              'avgConvAdspend',
              'convCa',
              'rvnRate',
              'cpm',
              'frequency',
            ])
            setValue('rptMetricId', 0)
            setValue('groupNm', '기본')
            setValue('div', [])
          } else {
            setMetric(resp.data.data[resp.data.data.length - 1])
            setRptMetricSelects(resp.data.data[resp.data.data.length - 1].metricList.split(','))
            setValue('rptMetricId', resp.data.data[resp.data.data.length - 1].rptMetricId)
            setValue('groupNm', resp.data.data[resp.data.data.length - 1].groupNm)
            setValue(
              'div',
              getValues().div.filter((it: any) => it.rptMetricId !== dataId)
            )
          }
        })
      }
    })
    $('#defMetric').on('click', () => {
      setMetric({
        rptMetricId: 0,
        groupNm: '기본',
        memberId: 0,
        metricList:
          'campBudget,wadizFee,adspend,impCnt,clickCnt,clickRate,avgClickAdspend,convCnt,convRate,avgConvAdspend,convCa,rvnRate,cpm,frequency',
        srchYn: false,
      })
      setRptMetricSelects([
        'campBudget',
        'wadizFee',
        'adspend',
        'impCnt',
        'clickCnt',
        'clickRate',
        'avgClickAdspend',
        'convCnt',
        'convRate',
        'avgConvAdspend',
        'convCa',
        'rvnRate',
        'cpm',
        'frequency',
      ])
      setRptMetricDivs([
        ...rptMetricDivs.filter((it: any) => it.rptMetricId !== 0),
        getValues().div.filter((it: any) => it.rptMetricId === 0).length > 0
          ? getValues().div.filter((it: any) => it.rptMetricId === 0)[0]
          : {
              rptMetricId: 0,
              div: [
                'campBudget',
                'wadizFee',
                'adspend',
                'impCnt',
                'clickCnt',
                'clickRate',
                'avgClickAdspend',
                'convCnt',
                'convRate',
                'avgConvAdspend',
                'convCa',
                'rvnRate',
                'cpm',
                'frequency',
              ],
            },
      ])
      setValue('rptMetricId', 0)
      setValue('groupNm', '기본')
      $('#sortable01')
        .sortable({
          connectWith: '.sortable',
          start: metricSelectStart,
          update: metricSelectUpdate,
          disabled: true,
        })
        .disableSelection()
    })
    $('#newMetricDialogSaveBtn').on('click', (e: any) => {
      e.preventDefault()
      ApiUtil.post<IRptMetricList>('/api/rptCommon/rptMetric/insert', { groupNm: $('#newMetricNameTxt').val() }).then(
        (resp) => {
          setMetrics(resp.data.data)
          setMetric(resp.data.data[resp.data.data.length - 1])
          setRptMetricSelects(resp.data.data[resp.data.data.length - 1].metricList.split(','))
          setRptMetricDivs([
            ...rptMetricDivs,
            {
              rptMetricId: resp.data.data[resp.data.data.length - 1].rptMetricId,
              div: resp.data.data[resp.data.data.length - 1].metricList.split(','),
            },
          ])
          setValue('rptMetricId', resp.data.data[resp.data.data.length - 1].rptMetricId)
          setValue('groupNm', resp.data.data[resp.data.data.length - 1].groupNm)
          setValue('div', [
            ...getValues().div,
            {
              rptMetricId: resp.data.data[resp.data.data.length - 1].rptMetricId,
              div: resp.data.data[resp.data.data.length - 1].metricList.split(','),
            },
          ])
          $('#newMetricDialog').dialog('close')

          $('#sortable01')
            .sortable({
              connectWith: '.sortable',
              start: metricSelectStart,
              update: metricSelectUpdate,
              disabled: false,
            })
            .disableSelection()
        }
      )
    })
    $('#rptMetricDialogSaveBtn').on('click', () => {
      const { div } = getValues()
      ApiUtil.post('/api/rptCommon/rptMetric/update', { rptMetricDiv: div }).then(() => {
        alertify.success('정상적으로 저장 되었습니다.')
        $('#rptMetricDialog').dialog('close')
      })
    })

    return () => {
      const dialogCheck = $('#rptMetricDialog')
      if (dialogCheck.length > 0 && $.data($('#rptMetricDialog')[0], 'dialog')) {
        $('#rptMetricDialog').dialog('destroy')
      }
    }
  }, [])
  useEffect(() => {
    $('input[name="metricDiv"]').on('click', (e: any) => {
      let data: any = getValues().div.filter((it: any) => it.rptMetricId === getValues().rptMetricId)[0].div
      if (e.target.checked) data.push(e.target.value)
      else data = data.filter((it: any) => it !== e.target.value)
      setRptMetricSelects(data)
      setRptMetricDivs([
        ...getValues().div.filter((it: any) => it.rptMetricId !== getValues().rptMetricId),
        { rptMetricId: getValues().rptMetricId, div: data },
      ])
      setValue('div', [
        ...getValues().div.filter((it: any) => it.rptMetricId !== getValues().rptMetricId),
        { rptMetricId: getValues().rptMetricId, div: data },
      ])
    })
  }, [])
  useEffect(() => {
    rptMetricSelects.map((a: any) => {
      $(`#rptMetric${a}Delete`).off('click')
      $(`#rptMetric${a}Delete`).on('click', () => {
        if (getValues().rptMetricId !== 0 && a !== 'adspend') {
          $(`#${a}MetricCb`).trigger('click')
        }
      })
      return null
    })
  }, [rptMetricSelects])
  useEffect(() => {
    $('#newMetricNameTxt').on('keyup', (e: any) => {
      if (e.target.value.length > 10) {
        e.target.value =
          e.target.value.substring(0, 9) +
          (e.target.value.substring(11, 12) === ''
            ? e.target.value.substring(10, 11)
            : e.target.value.substring(11, 12))
        e.preventDefault()
      }
    })
  }, [])
  return (
    <Fragment>
      <div className="comp-dropdown">
        <a className="dropdown-toggle" data-toggle="dropdown" href="#!">
          <div className="box-left">
            <i className="ico ico-performance-item"></i>
            <span className="fz-14 fc-2 fw-medium">성과 항목</span>
          </div>
          <div className="box-right">
            <i className="ico ico-arrow"></i>
          </div>
        </a>
        <div className="dropdown-menu">
          <ul className="opt-selectbox">
            <li
              className={searchMetricId === 0 ? 'opt-menu selected' : 'opt-menu'}
              onClick={() => rptMetricSrch(0)}
              role="presentation"
            >
              <p className="fc-1">기본 광고성과</p>
            </li>
            {metrics?.map((a: IRptMetric) => (
              <li
                className={searchMetricId === a.rptMetricId ? 'opt-menu selected' : 'opt-menu'}
                key={a.rptMetricId}
                onClick={() => rptMetricSrch(a.rptMetricId)}
                role="presentation"
              >
                <p className="fc-1">{a.groupNm}</p>
              </li>
            ))}
            <li className="opt-menu bottom">
              <a className="fw-medium txt-link" href="#!" id="rptMetricOpenBtn">
                성과 항목 설정
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div id="rptMetricDialog" className="dialog" style={{ display: 'none' }}>
        <div className="dialog-body">
          <div className="container-fluid">
            <div className="row flex-container">
              <div className="col col-4">
                <div className="wrap-section wrap-tbl">
                  <div className="box-header">
                    <div className="box-tit">
                      <h2 className="fz-20 fc-1 fw-bold">성과 항목 설정</h2>
                    </div>
                    <div className="box-option">
                      <button type="button" className="btn btn-primary w-auto" id="newMetricOpenBtn">
                        신규
                      </button>
                      <button type="button" className="btn btn-secondary-outline w-auto" id="deleteMetricBtn">
                        삭제
                      </button>
                    </div>
                  </div>
                  <div className="box-body">
                    <table className="tbl group-list">
                      <thead></thead>
                      <colgroup>
                        <col width="50%" />
                      </colgroup>
                      <tbody>
                        <tr>
                          <td className={metric?.rptMetricId === 0 ? 'selected' : ''} id="defMetric">
                            <span className="fz-16 fc-3">기본 광고성과</span>
                          </td>
                        </tr>
                        {metrics?.map((a: IRptMetric) => {
                          $(`#metricGroup${a.rptMetricId}`).off('click')
                          $(`#metricGroup${a.rptMetricId}`).on('click', (e: any) => {
                            e.preventDefault()
                            metricGroupCharge(e, a)
                          })
                          return (
                            <tr key={a.rptMetricId}>
                              <td
                                className={metric?.rptMetricId === a.rptMetricId ? 'selected' : ''}
                                id={`metricGroup${a.rptMetricId}`}
                              >
                                <span className="fz-16 fc-3">{a.groupNm}</span>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col col-4">
                <div className="wrap-section wrap-tbl">
                  <div className="box-header">
                    <div className="box-tit">
                      <h2 className="fz-20 fc-1 fw-bold">성과 항목</h2>
                    </div>
                  </div>
                  <div className="box-body">
                    <div className="tbl">
                      <dl className="vertical">
                        <dd>
                          <div className="form-group">
                            <div className="comp-checkbox block">
                              <input
                                type="checkbox"
                                id="campBudgetMetricCb"
                                name="metricDiv"
                                value="campBudget"
                                checked={
                                  rptMetricDivs.filter(
                                    (it) =>
                                      it.rptMetricId === metric?.rptMetricId &&
                                      it.div.filter((it2) => it2 === 'campBudget').length > 0
                                  ).length > 0
                                }
                                disabled={metric.rptMetricId === 0}
                                readOnly
                              />
                              <label htmlFor="campBudgetMetricCb">예산</label>
                            </div>
                            <div className="comp-checkbox block">
                              <input
                                type="checkbox"
                                id="wadizFeeMetricCb"
                                name="metricDiv"
                                value="wadizFee"
                                checked={
                                  rptMetricDivs.filter(
                                    (it) =>
                                      it.rptMetricId === metric?.rptMetricId &&
                                      it.div.filter((it2) => it2 === 'wadizFee').length > 0
                                  ).length > 0
                                }
                                disabled={metric.rptMetricId === 0}
                                readOnly
                              />
                              <label htmlFor="wadizFeeMetricCb">수수료 (VAT 제외)</label>
                            </div>
                            <div className="comp-checkbox block">
                              <input
                                type="checkbox"
                                id="adspendMetricCb"
                                name="metricDiv"
                                value="adspend"
                                checked
                                disabled
                              />
                              <label htmlFor="adspendMetricCb">비용 (VAT 제외)</label>
                            </div>
                            <div className="comp-checkbox block">
                              <input
                                type="checkbox"
                                id="impCntMetricCb"
                                name="metricDiv"
                                value="impCnt"
                                checked={
                                  rptMetricDivs.filter(
                                    (it) =>
                                      it.rptMetricId === metric?.rptMetricId &&
                                      it.div.filter((it2) => it2 === 'impCnt').length > 0
                                  ).length > 0
                                }
                                disabled={metric.rptMetricId === 0}
                                readOnly
                              />
                              <label htmlFor="impCntMetricCb">노출</label>
                            </div>
                            <div className="comp-checkbox block">
                              <input
                                type="checkbox"
                                id="clickCntMetricCb"
                                name="metricDiv"
                                value="clickCnt"
                                checked={
                                  rptMetricDivs.filter(
                                    (it) =>
                                      it.rptMetricId === metric?.rptMetricId &&
                                      it.div.filter((it2) => it2 === 'clickCnt').length > 0
                                  ).length > 0
                                }
                                disabled={metric.rptMetricId === 0}
                                readOnly
                              />
                              <label htmlFor="clickCntMetricCb">클릭</label>
                            </div>
                            <div className="comp-checkbox block">
                              <input
                                type="checkbox"
                                id="clickRateMetricCb"
                                name="metricDiv"
                                value="clickRate"
                                checked={
                                  rptMetricDivs.filter(
                                    (it) =>
                                      it.rptMetricId === metric?.rptMetricId &&
                                      it.div.filter((it2) => it2 === 'clickRate').length > 0
                                  ).length > 0
                                }
                                disabled={metric.rptMetricId === 0}
                                readOnly
                              />
                              <label htmlFor="clickRateMetricCb">클릭률 (CTR)</label>
                            </div>
                            <div className="comp-checkbox block">
                              <input
                                type="checkbox"
                                id="avgClickAdspendMetricCb"
                                name="metricDiv"
                                value="avgClickAdspend"
                                checked={
                                  rptMetricDivs.filter(
                                    (it) =>
                                      it.rptMetricId === metric?.rptMetricId &&
                                      it.div.filter((it2) => it2 === 'avgClickAdspend').length > 0
                                  ).length > 0
                                }
                                disabled={metric.rptMetricId === 0}
                                readOnly
                              />
                              <label htmlFor="avgClickAdspendMetricCb">클릭당 비용 (CPC)</label>
                            </div>
                            <div className="comp-checkbox block">
                              <input
                                type="checkbox"
                                id="convCntMetricCb"
                                name="metricDiv"
                                value="convCnt"
                                checked={
                                  rptMetricDivs.filter(
                                    (it) =>
                                      it.rptMetricId === metric?.rptMetricId &&
                                      it.div.filter((it2) => it2 === 'convCnt').length > 0
                                  ).length > 0
                                }
                                disabled={metric.rptMetricId === 0}
                                readOnly
                              />
                              <label htmlFor="convCntMetricCb">전환</label>
                            </div>
                            <div className="comp-checkbox block">
                              <input
                                type="checkbox"
                                id="convRateMetricCb"
                                name="metricDiv"
                                value="convRate"
                                checked={
                                  rptMetricDivs.filter(
                                    (it) =>
                                      it.rptMetricId === metric?.rptMetricId &&
                                      it.div.filter((it2) => it2 === 'convRate').length > 0
                                  ).length > 0
                                }
                                disabled={metric.rptMetricId === 0}
                                readOnly
                              />
                              <label htmlFor="convRateMetricCb">전환율</label>
                            </div>
                            <div className="comp-checkbox block">
                              <input
                                type="checkbox"
                                id="avgConvAdspendMetricCb"
                                name="metricDiv"
                                value="avgConvAdspend"
                                checked={
                                  rptMetricDivs.filter(
                                    (it) =>
                                      it.rptMetricId === metric?.rptMetricId &&
                                      it.div.filter((it2) => it2 === 'avgConvAdspend').length > 0
                                  ).length > 0
                                }
                                disabled={metric.rptMetricId === 0}
                                readOnly
                              />
                              <label htmlFor="avgConvAdspendMetricCb">전환당 비용</label>
                            </div>
                            <div className="comp-checkbox block">
                              <input
                                type="checkbox"
                                id="convCaMetricCb"
                                name="metricDiv"
                                value="convCa"
                                checked={
                                  rptMetricDivs.filter(
                                    (it) =>
                                      it.rptMetricId === metric?.rptMetricId &&
                                      it.div.filter((it2) => it2 === 'convCa').length > 0
                                  ).length > 0
                                }
                                disabled={metric.rptMetricId === 0}
                                readOnly
                              />
                              <label htmlFor="convCaMetricCb">전환 매출</label>
                            </div>
                            <div className="comp-checkbox block">
                              <input
                                type="checkbox"
                                id="rvnRateMetricCb"
                                name="metricDiv"
                                value="rvnRate"
                                checked={
                                  rptMetricDivs.filter(
                                    (it) =>
                                      it.rptMetricId === metric?.rptMetricId &&
                                      it.div.filter((it2) => it2 === 'rvnRate').length > 0
                                  ).length > 0
                                }
                                disabled={metric.rptMetricId === 0}
                                readOnly
                              />
                              <label htmlFor="rvnRateMetricCb">수익률 (ROAS)</label>
                            </div>
                            <div className="comp-checkbox block">
                              <input
                                type="checkbox"
                                id="cpmMetricCb"
                                name="metricDiv"
                                value="cpm"
                                checked={
                                  rptMetricDivs.filter(
                                    (it) =>
                                      it.rptMetricId === metric?.rptMetricId &&
                                      it.div.filter((it2) => it2 === 'cpm').length > 0
                                  ).length > 0
                                }
                                disabled={metric.rptMetricId === 0}
                                readOnly
                              />
                              <label htmlFor="cpmMetricCb">CPM</label>
                            </div>
                            <div className="comp-checkbox block">
                              <input
                                type="checkbox"
                                id="frequencyMetricCb"
                                name="metricDiv"
                                value="frequency"
                                checked={
                                  rptMetricDivs.filter(
                                    (it) =>
                                      it.rptMetricId === metric?.rptMetricId &&
                                      it.div.filter((it2) => it2 === 'frequency').length > 0
                                  ).length > 0
                                }
                                disabled={metric.rptMetricId === 0}
                                readOnly
                              />
                              <label htmlFor="frequencyMetricCb">게재빈도</label>
                            </div>
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-4">
                <div className="wrap-section wrap-tbl">
                  <div className="box-header">
                    <div className="box-tit">
                      <h2 className="fz-20 fc-1 fw-bold">선택 항목</h2>
                    </div>
                  </div>
                  <div className="box-body">
                    <div className="tbl">
                      <dl className="vertical">
                        <dd>
                          <div className="form-group">
                            <ul id="sortable01" className="sortable">
                              {rptMetricSelects?.map((a: any) => (
                                <li className="item-sortable" key={a}>
                                  <i className="ico ico-drag"></i>
                                  <span className="fz-16 fc-1">{metricDivEnum[a]}</span>
                                  <i className="ico ico-delete" id={`rptMetric${a}Delete`}></i>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dialog-footer">
          <button type="button" className="btn btn-secondary-outline large" id="rptMetricDialogCanclBtn">
            취소
          </button>
          <button type="button" className="btn btn-primary large" id="rptMetricDialogSaveBtn">
            저장
          </button>
        </div>
      </div>
      <div id="newMetricDialog" className="dialog" style={{ display: 'none' }}>
        <div className="dialog-body">
          <div className="wrap-section wrap-tbl">
            <div className="box-body">
              <div className="tbl">
                <dl>
                  <dt>
                    <div className="dt-inner">
                      <span className="fz-16 fc-1">그룹명</span>
                    </div>
                  </dt>
                  <dd>
                    <div className="form-group">
                      <div className="input-group expand">
                        <div className="inner-input-group">
                          <input
                            type="text"
                            className="tf-comm"
                            id="newMetricNameTxt"
                            {...register('groupNm', {
                              required: { value: true, message: '그룹명을 입력하세요.' },
                              minLength: { value: 2, message: '그룹명은 2~10자까지 입력해 주세요.' },
                              maxLength: { value: 10, message: '그룹명은 2~10자까지 입력해 주세요.' },
                            })}
                            placeholder="그룹명은 2~10자까지 입력해 주세요."
                          />
                        </div>
                        <p className="fz-12"></p>
                      </div>
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div className="dialog-footer">
          <button type="button" className="btn btn-secondary-outline large" id="newMetricDialogCanclBtn">
            취소
          </button>
          <button type="button" className="btn btn-primary large" id="newMetricDialogSaveBtn">
            등록
          </button>
        </div>
      </div>
    </Fragment>
  )
}
export default RptMetric
