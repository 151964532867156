import { AdStateType } from './AdStateType'
const baseTable: { [key in AdStateType]: { name: string; color: string } } = {
  Creating: { name: '작성중', color: '#495057' },
  Recreating: { name: '수정중', color: '#FFB300' },
  Submitted: { name: '요청', color: '#7FA4FF' },
  Accepted: { name: '계약중', color: '#FF7C09' },
  Scheduled: { name: '예정', color: '#5CDEDE' },
  In_Progress: { name: '진행중', color: '#00B2B2' },
  Completed: { name: '완료', color: '#495057' },
  Feedback: { name: '피드백', color: '#FFB300' },
  Feedback_in_Contr: { name: '(계약중)피드백', color: '#FFB300' },
  Payment_Failed: { name: '결제실패', color: '#F25555' },
  Feedback_in_Sched: { name: '(예정)피드백', color: '#FFB300' },
  Feedback_in_Prog: { name: '(진행중)수정요청', color: '#FFB300' },
  Modified_in_Contr: { name: '(계약중)수정요청', color: '#FFB300' },
  Modified_in_Failed: { name: '(결제실패)수정요청', color: '#FFB300' },
  Modified_in_Sched: { name: '(예정)수정요청', color: '#FFB300' },
  Modified_in_Prog: { name: '(진행중)수정요청', color: '#FFB300' },
  Canceled: { name: '(요청)취소', color: '#868E96' },
  Canceled_C: { name: '(계약중/수정요청)취소', color: '#868E96' },
  Canceled_F: { name: '(결제실패/수정요청)취소', color: '#868E96' },
  Canceled_S: { name: '(예약/수정요청)취소', color: '#868E96' },
  Canceled_P: { name: '(진행중/수정요청)취소', color: '#868E96' },
  Canceled_in_Sched: { name: '(예정)취소', color: '#868E96' },
  Canceled_in_Prog: { name: '(진행중)취소', color: '#868E96' },
  Refused_C: { name: '(계약중/수정요청)거절', color: '#8C5CD4' },
  Refused_F: { name: '(결제실패/수정요청)거절', color: '#8C5CD4' },
  Refused_S: { name: '(예약/수정요청)거절', color: '#8C5CD4' },
  Refused_P: { name: '(계약중/수정요청)거절', color: '#8C5CD4' },
  Rejected: { name: '반려', color: '#8C5CD4' },
  Rejected_in_Contr: { name: '(계약중)반려', color: '#8C5CD4' },
  Deleted: { name: '삭제', color: '#868E96' },
  None: { name: '삭제', color: '#868E96' },
  Unknown: { name: '삭제', color: '#868E96' },
}

const selector = (tag: 'name' | 'color') =>
  Object.keys(baseTable).reduce(
    (result, key) => ({ ...result, [key as AdStateType]: baseTable[key as AdStateType][tag] }),
    {}
  )

export const adStateList: { [index: string]: string } = selector('name')
export const adStateColorList: { [index: string]: string } = selector('color')
export const feedbackReasonList: { [index: string]: string } = {
  Period: '광고 기간',
  Image: '광고 이미지',
  Copy: '광고 카피',
  Payment: '결제 수단',
  Etc: '기타',
}
