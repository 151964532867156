import { axios } from '../axios'

export type CheckListInfoType = {
  catCode: string //"A0090"
  catCodeDesc: string //  "디자인"
  creationCheckData: string // "[{\"is
}

type ReturnType = {
  ok: boolean
  data: CheckListInfoType
}

type FunctionType = (campId: string | number) => Promise<ReturnType>
export const fetchCheckList: FunctionType = (campId) =>
  axios.get(`/api/camp/make02/findCatCreationCheckConfig?campId=${campId}`)
