import React, {
  createContext, Fragment, useCallback,
} from 'react';
import { useParams } from 'react-router';
import CreationDetailItg from './CreationDetailItg';
import CreationDetailGdn from './CreationDetailGdn';
import CreationDetailFb from './CreationDetailFb';

export const GdnCreationDataIdContext = createContext({
  creationDataId: 'step01',
  setCreationDataId: (value: any) => value,
});

const CreationDetailEmpty = () => {
  const { mediaDiv4, campId } = useParams();

  const CreationDetailEvent = useCallback(() => {
    if (mediaDiv4 === 'GDN') { // 구글
      return <CreationDetailGdn campId={campId}/>;
    }
    if (mediaDiv4 === 'ITG') { // 인스타그램
      return <CreationDetailItg campId={campId}/>;
    }
    return <CreationDetailFb campId={campId}/>; // 페북 , 페북/인스타
  }, [mediaDiv4]);

  return (
        <Fragment>
            <CreationDetailEvent/>
        </Fragment>
  );
};

export default CreationDetailEmpty;
