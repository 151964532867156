import { ReactComponent as InfoIcon } from '@common/svg/info.svg'
import styles from './InfoBox.module.scss'

type InfoBoxFunction = {
  children?: React.ReactNode
  className?: string
  description?: string
  option?: 'warning' | 'info'
  title?: React.ReactNode
  noTitle?: React.ReactNode
}

export const InfoBox: React.FC<InfoBoxFunction> = ({ title, children, option, className, description, noTitle }) => (
  <div
    className={`${styles.container} ${option === 'warning' ? styles.warning : ''} ${
      option === 'info' ? styles.info : ''
    } ${!!className ? className : ''}`}
  >
    {title && (
      <div className={styles.title}>
        <InfoIcon />
        {title}
      </div>
    )}

    {description && (
      <div className={styles.description}>
        <InfoIcon />
        <p>{description}</p>
      </div>
    )}
    {noTitle && (
      <div className={styles.noTitle}>
        <InfoIcon />
        <div>{noTitle}</div>
      </div>
    )}
    {children}
  </div>
)
