import React, { Fragment, useEffect } from 'react';
import dayjs from 'dayjs';
import alertify from 'alertifyjs'
import { ApiUtil2 } from '@biz/api'

const AtamPopUp = (props:any) => {
  let depositAccount = ''; // fixme 와디즈에서 주는거로 하드코딩 해야 함.
  const accountHolder = '와디즈(주)';
  const bankDatas:any[] = [{ id: '', text: '' }];
  const now = dayjs();
  const atamExpireDate = now.add(6, 'd').format('YYYY-MM-DD 23:59');
  let bankName:any;
  useEffect(() => {
    const tempBanks = [{ value: '93203848004062', name: '기업은행' }];

    tempBanks.forEach((item:any) => {
      bankDatas.push({ id: item.value, text: item.name });
    });

    /** 다이얼로그 */
    $('#atamPopUp').dialog({
      title: '무통장 입금', width: 900, closeOnEscape: true,
    });

    /** select2 */
    $('#selectBank').select2({
      width: '330px',
      minimumResultsForSearch: Infinity,
      placeholder: '선택하세요.',
      data: bankDatas,
    }).on('select2:select', (e:any) => {
      depositAccount = e.params.data.id;
      bankName = e.params.data.text;
      $('#depositAccount').text(e.params.data.id);
    });

    /** 취소 */
    $('#atamPopUpCloseBtn').on('click', () => {
      $('#atamPopUp').dialog('close');
      props.setIsAtamPopUpOpened(false);
    });

    /** 등록 */
    $('#atamPopUpRegBtn').on('click', () => {
      if ($('#nameOfDepositor').val() === '') {
        alertify.error('입금자명을 입력하세요.');
        return;
      }
      if ($('#selectBank').val() === '') {
        alertify.error('입금 은행을 선택하세요.');
        return;
      }
      const msg = `금액: ${props.chargeAmount.toLocaleString()}<br>은행: ${$('#selectBank').val()}<br>계좌: ${depositAccount}<br>마감기간: ${atamExpireDate}`;
      alertify.confirm(msg, () => {
        ApiUtil2.post('/api/bzm/charge/atamReq', {
          chargeAmount: props.chargeAmount, nameOfDepositor: $('#nameOfDepositor').val(), depositAccount, bankName, accountHolder, projectId: props.currProjectId,
        }).then(() => {
          $('#atamPopUp').dialog('close');
          props.setIsAtamPopUpOpened(false);
          if (localStorage.getItem('chargeShowType') !== 'general') {
            $('#generalChargeDialog').dialog('close');
            props.propsClicked(false);
          }
          alertify.success('무통장 입금 신청이 완료되었습니다.');
        });
      }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('무통장 입금 신청 완료');
    });

    return () => {
      props.setIsAtamPopUpOpened(false);
      $('#atamPopUp').dialog('destroy');
    };
  }, []);
  return (
        <Fragment>
            <form>
            <div id="atamPopUp" className="dialog" style={{ display: 'none' }}>
                <div className="dialog-body">
                    <div className="wrap-section wrap-tbl">
                        <div className="box-body">
                            <div className="tbl">
                                <dl>
                                    <dt>
                                        <div className="dt-inner">
                                            <span className="fz-16 fc-1">
                                                입금 예정액
                                            </span>
                                        </div>
                                    </dt>
                                    <dd>
                                        <div className="form-group">
                                            <span className="comp-txt">
                                                <span className="table">
                                                    <span className="table-cell">
                                                        <b className="fz-14 fc-2">{Number(props.chargeAmount).toLocaleString()}</b>
                                                    </span>
                                                </span>
                                            </span>
                                        </div>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>
                                        <div className="dt-inner">
                                            <span className="fz-16 fc-1">
                                                입금자명
                                            </span>
                                        </div>
                                    </dt>
                                    <dd>
                                        <div className="form-group">
                                            <div className="input-group expand">
                                                <div className="inner-input-group">
                                                    <input type="text" className="tf-comm tf-unit" id='nameOfDepositor'
                                                    />
                                                </div>
                                                <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                            </div>
                                        </div>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>
                                        <div className="dt-inner">
                                            <span className="fz-16 fc-1">
                                                입금 은행
                                            </span>
                                        </div>
                                    </dt>
                                    <dd>
                                        <div className="form-group">
                                            <select className="select2-single expand" id='selectBank'>
                                                <option></option>
                                            </select>
                                        </div>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>
                                        <div className="dt-inner">
                                            <span className="fz-16 fc-1">
                                                입금 계좌
                                            </span>
                                        </div>
                                    </dt>
                                    <dd>
                                        <div className="form-group">
                                            <span className="comp-txt">
                                                <span className="table">
                                                    <span className="table-cell">
                                                        <b className="fz-14 fc-2" id='depositAccount'></b>
                                                    </span>
                                                </span>
                                            </span>
                                        </div>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>
                                        <div className="dt-inner">
                                            <span className="fz-16 fc-1">
                                                예금주명
                                            </span>
                                        </div>
                                    </dt>
                                    <dd>
                                        <div className="form-group">
                                            <span className="comp-txt">
                                                <span className="table">
                                                    <span className="table-cell">
                                                        <b className="fz-14 fc-2">{accountHolder}</b>
                                                    </span>
                                                </span>
                                            </span>
                                        </div>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>
                                        <div className="dt-inner">
                                            <span className="fz-16 fc-1">
                                                입금 마감시간
                                            </span>
                                        </div>
                                    </dt>
                                    <dd>
                                        <div className="form-group">
                                            <span className="comp-txt">
                                                <span className="table">
                                                    <span className="table-cell">
                                                        <b className="fz-14 fc-2">{now.add(6, 'd').format('YYYY년 MM월 DD일 오후 23시 59분')}</b>
                                                    </span>
                                                </span>
                                            </span>
                                        </div>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dialog-footer">
                    <button type="button" className="btn btn-secondary-outline large"
                            id='atamPopUpCloseBtn'>취소
                    </button>
                    <button type="button" className="btn btn-primary large" id='atamPopUpRegBtn'>무통장 입금</button>
                </div>
            </div>
        </form>
        </Fragment>
  );
};

export default React.memo(AtamPopUp);
