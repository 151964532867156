import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { DataFormat } from 'select2';

export interface IMultiSelect {
  texts: Map<string, string>;
  selects: Map<string, boolean>;
}
export interface IBzmSearchForm {
  searchType: string;
  keyword: string;
}

export interface IBzmComponents {
  searchUrl: string;
  colDefs: ColDef[];
  searchTypes: DataFormat[];
  callback: any;
}
// 비즈머니 유효기간 다이얼로그용
export interface IBzmComponents2 {
  searchUrl: string;
  colDefs: ColDef[];
  searchTypes: DataFormat[];
  costTypes: DataFormat[];
  callback: any;
}

export interface IBzmTxSearchForm extends IBzmSearchForm {
  searchTypeForCamp?: string;
  searchTypeForConsulting?: string;
  startDate: string;
  endDate: string;
  makerId: number;
}
export interface IBzmExpireSearchForm extends IBzmSearchForm {
  searchTypeForRefund?: string;
  searchTypeForMaker?: string;
  category:string;
  costType:string;
  projectId:string;
  makerId: number;
  accRefundId?:number;
  showType:string;
}
// 렌더러 모음
const costRenderer = (props: ICellRendererParams) => props.value.toLocaleString();
export const campGridBzm: IBzmComponents = {
  searchUrl: '/api/bzmAdmin/mng/bzmMngMaker/campList',
  colDefs: [
    {
      field: 'inUserNo',
      headerName: '충전 사업자 번호',
    },
    {
      field: 'userNo',
      headerName: '소진 사업자 번호',
    },
    {
      field: 'campId',
      headerName: '캠페인 ID',
    }, {
      field: 'campName',
      headerName: '캠페인명',
    },
    {
      field: 'campStartDate',
      headerName: '캠페인 시작일',
    },
    {
      field: 'campEndDate',
      headerName: '캠페인 종료일',
    },
    {
      field: 'paidCost',
      headerName: '소진 유상머니 (VAT 포함)',
      cellRenderer: costRenderer,
    },
    {
      field: 'freeCost',
      headerName: '소진 쿠폰 (VAT 포함)',
      cellRenderer: costRenderer,
    },
  ],
  searchTypes: [{ id: 'inUserNo', text: '충전 사업자 번호' }, { id: 'txUserNo', text: '소진 사업자 번호' }, { id: 'campId', text: '캠페인ID' }, { id: 'campName', text: '캠페인명' }],
  callback: (data: IBzmTxSearchForm) => ({
    startDate: data.startDate, endDate: data.endDate, makerId: data.makerId, searchType: data.searchTypeForCamp, keyword: data.keyword,
  }),
};

export const consultingGridBzm: IBzmComponents = {
  searchUrl: '/api/bzmAdmin/mng/bzmMngMaker/consultingList',
  colDefs: [
    {
      field: 'inUserNo',
      headerName: '충전 사업자 번호',
    },
    {
      field: 'userNo',
      headerName: '소진 사업자 번호',
    },
    {
      field: 'projectId',
      headerName: '프로젝트 ID',
    },
    {
      field: 'projectName',
      headerName: '프로젝트 명',
    },
    {
      field: 'consultingStatusDesc',
      headerName: '광고 대행 상태',
    },
    { field: 'regTime', headerName: '신청일' },
    {
      field: 'paidCost',
      headerName: '소진 유상머니 (VAT 포함)',
      cellRenderer: costRenderer,
    },
    {
      field: 'freeCost',
      headerName: '소진 쿠폰 (VAT 포함)',
      cellRenderer: costRenderer,
    },
  ],
  searchTypes: [{ id: 'projectId', text: '프로젝트ID' }, { id: 'projectName', text: '프로젝트명' }, { id: 'inUserNo', text: '충전 사업자 번호' }, { id: 'txUserNo', text: '소진 사업자 번호' }, { id: 'consultingStatus', text: '광고 대행 상태' }],
  callback: (data: IBzmTxSearchForm) => ({
    startDate: data.startDate, endDate: data.endDate, makerId: data.makerId, searchType: data.searchTypeForConsulting, keyword: data.keyword,
  }),
};

// 비즈머니 유효기간 관련 ,,
export const refundExpireGridBzm: IBzmComponents2 = {
  searchUrl: '/api/bzmAdmin/mng/bzmExpireList',
  colDefs: [
    {
      field: 'category',
      headerName: '구분',
    },
    {
      field: 'costType',
      headerName: '비즈머니 유형',
    },
    {
      field: 'costDiv',
      headerName: '충전방식',
    },
    {
      field: 'balance',
      headerName: '금액',
      cellRenderer: costRenderer,
    },
    {
      field: 'expire',
      headerName: '유효기간',
    },
  ],
  searchTypes: [{ id: '', text: '전체' }, { id: '환불 신청', text: '환불 신청' }],
  costTypes: [{ id: '', text: '전체' }, { id: 'paid', text: '유상 비즈머니' }, { id: 'free', text: '쿠폰' }],
  callback: (data: IBzmExpireSearchForm) => ({
    makerId: data.makerId, searchType: data.searchTypeForRefund, costType: data.costType, projectId: data.projectId, showType: data.showType, accRefundId: data.accRefundId,
  }),
};
export const makerExpireGridBzm: IBzmComponents2 = {
  searchUrl: '/api/bzmAdmin/mng/bzmMngMaker/bzmExpireList',
  colDefs: [
    {
      field: 'category',
      headerName: '구분',
    },
    {
      field: 'costType',
      headerName: '비즈머니 유형',
    },
    {
      field: 'costDiv',
      headerName: '충전방식',
    },
    {
      field: 'balance',
      headerName: '금액',
      cellRenderer: costRenderer,
    },
    {
      field: 'expire',
      headerName: '유효기간',
    },
  ],
  searchTypes: [{ id: 'all', text: '전체' }, { id: '예치금', text: '예치금' }, { id: '보유 잔액', text: '보유 잔액' }],
  costTypes: [{ id: 'all', text: '전체' }, { id: 'paid', text: '유상 비즈머니' }, { id: 'free', text: '쿠폰' }],
  callback: (data: IBzmExpireSearchForm) => ({
    makerId: data.makerId, searchType: data.searchTypeForMaker, costType: data.costType, projectId: data.projectId, showType: data.showType,
  }),
};
// eslint-disable-next-line no-unused-vars
export const multiSelectHandler = (key: string, checked: boolean, prevState: IMultiSelect, updateStatus: (newState: Map<string, boolean>) => void, updateLabel: (label: string) => void, updateForm: (value: string) => void) => {
  // window.console.log(`${key} : ${checked}`);
  // deep copy
  const newStatus = new Map<string, boolean>(prevState.selects);
  if (key === 'all') {
    newStatus.forEach((v, k) => {
      newStatus.set(k, checked);
    });
  } else {
    newStatus.set(key, checked);
  }

  // 체크된 항목 찾기. '전체'는 항목에서 제외
  const selected = Array.from(newStatus).map(([k, v]) : string => {
    if (k === 'all') return '';
    return v === true ? k : '';
  }).filter((v) => v !== '');

  const count = selected.length;

  if (count === prevState.selects.size - 1) { // '전체'를 제외한 나머지 가 모두 선택되었으면 '전체'도 선택함
    newStatus.set('all', true);
  } else if (count === 0) { // 1도 선택된게 없으면 '전체' 포함 모두 선택 시켜줌
    newStatus.forEach((v, k) => {
      newStatus.set(k, true);
    });
  } else { // 1개라도 해제 되었으면 '전체'는 선택 해제
    newStatus.set('all', false);
  }

  const labels: Array<string> = [];
  // 드랍다운에 보여줄 라벨 정하기
  if (newStatus.get('all') === true) {
    labels.push('전체');
  } else {
    const label = prevState.texts.get(selected[0]);
    labels.push(`${label}`);
    if (count > 1) labels.push(` 외 ${count}`);
  }

  updateForm(newStatus.get('all') === true ? 'all' : selected.join(','));
  updateLabel(labels.join(''));
  updateStatus(newStatus);
};
