import { ApiUtil2 } from '../legacy'

type ReturnType = {
  ok: boolean
  data: { linkUrl: string }
}

type FunctionType = (campId: number, snsAdId: number | string) => Promise<ReturnType>
export const fetchFacebookLink: FunctionType = (campId, snsAdId) =>
  ApiUtil2.get('/api/camp/make02/fbPreviewLink?', {
    params: {
      data: {
        campId: campId,
        snsAdId: snsAdId,
      },
    },
  }).then((res) => res.data)
