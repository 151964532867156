import React, {
  useEffect, Fragment, useRef, useState, useMemo, useCallback,
} from 'react';
import {Link, useLocation, useOutletContext} from 'react-router-dom';
import dayjs, {Dayjs} from 'dayjs';
import {AgGridReact} from 'ag-grid-react';
import {ColDef, ICellRendererParams} from 'ag-grid-community';
import {useForm} from 'react-hook-form';
import { DataFormat } from 'select2';
import { ApiUtil2 } from '@biz/api'
import { ApiUtil } from '@biz/api'
import { PageTitle } from '@biz/ui'
import RangeDatePicker from '../../views/accAdjust/RangeDatePicker';
import Pagination from '../../views/common/Pagination';
import { ICampListSearchForm, IComponentsForMngAdCamp } from './MngAdCampListUtil';
import SingleSelect2 from '../../views/accAdjust/SingleSelect2';
import NoDataTemplate2 from '../../views/common/NoDataTemplate2';
import RangeDatePicker2 from '../../views/mngMd/RangeDatePicker2';
import DlDialog from "../../views/mngMakerAccount/DlDialog";

const CampViewStatusMap:any = {
  temp: '임시저장',
  insp_req: '검수요청',
  invalid: '수정필요',
  wait: '진행대기',
  ing_update: '진행(수정필요)',
  ing_insp: '진행(검수중)',
  ing: '진행',
  stop_creation_off: '중단(소재OFF)',
  stop_camp_off: '중단(캠페인OFF)',
  stop_camp_range: '중단(캠페인기간)',
  complete: '종료',
  del_ready: '종료',
  del_complete: '종료'
};
const MediaDiv4Map:any = {
  FB: '페이스북',
  ITG: '인스타그램',
  FBITG: '페이스북&인스타그램',
  GDN: '구글배너',
};

const nodataRenderer = (props: ICellRendererParams) => (props.value === undefined ? '-' : props.value);
const campBudgetTotalRenderer = (props: ICellRendererParams) =>  (props.value === undefined || props.value === null ? '-' : props.value.toLocaleString());
const statusRenderer = (props: ICellRendererParams) => (props.data.actYn ? CampViewStatusMap[props.value] : '삭제');
const mediaDiv4Renderer = (props: ICellRendererParams) => MediaDiv4Map[props.value];
const campPerdRenderer = (props:ICellRendererParams) => `${dayjs(props.data.campStartTime).format('YYYY.MM.DD')} ~ ${dayjs(props.data.campEndTime).format('YYYY.MM.DD')}`;
// eslint-disable-next-line react/prop-types,no-nested-ternary
const setCtrRenderer = (props: ICellRendererParams) => (props.data.ctr === undefined ? '로딩중' : props.data.ctr === 0 ? '-' : `${props.data.ctr}%`);
// eslint-disable-next-line react/prop-types,no-nested-ternary
const setConvRateRenderer = (props: ICellRendererParams) => (props.data.convRate === undefined ? '로딩중' : props.data.convRate === 0 ? '-' : `${props.data.convRate}%`);
// eslint-disable-next-line react/prop-types,no-nested-ternary
const setRoasRenderer = (props: ICellRendererParams) => (props.data.roas === undefined ? '로딩중' : props.data.roas === 0 ? '-' : `${Math.floor(props.data.roas)}%`);
// eslint-disable-next-line react/prop-types,no-nested-ternary
const setCostPerConvRenderer = (props: ICellRendererParams) => (props.data.costPerConv === undefined ? '로딩중' : props.data.costPerConv === 0 ? '-' : `${props.data.costPerConv.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`);

/**
 * 캠페인 조회
 */
const MngAdCampList = () => {
    const campNameRenderer = (props: ICellRendererParams) => <Link className="txt-link" to={`/mngAd/camp/detail/${props.data.campId}/${getValues('rptStartDate')}/${getValues('rptEndDate')}`}>{props.value}</Link>;
    const childRef = useRef<any>(null);
    const gridRef = useRef<AgGridReact>(null);
    const [rowData, setRowData] = useState<any[]>([]);
    const [noDataMsg, setNoDataMsg] = useState<string>('검색조건을 입력하세요');
    const [rowDataEmpty, setRowDataEmpty] = useState<boolean>(true);
    const {
        register, getValues, setValue,
    } = useForm<ICampListSearchForm>();
    // 필터
    const allIncludedYnSelectData: DataFormat[] = [{id: 'false', text: '일부 포함', selected: true}, {id: 'true', text: '포함'}];
    const [isDlDialogOpened, setIsDlDialogOpened] = useState<boolean>(false);
    const url = useLocation();

  // 프리퀀시
  // eslint-disable-next-line no-shadow
  const updateItems = (rptData:any) => {
    const itemsToUpdate: any[] = [];
        gridRef.current!.api!.forEachNode((rowNode, index) => {
          const { data } = rowNode;
          data.ctr = rptData[index].ctr;
          data.convRate = rptData[index].convRate;
          data.costPerConv = rptData[index].costPerConv;
          data.roas = rptData[index].roas;
          // data.frequency = rptData[index].frequency;
          itemsToUpdate.push(data);
        });
        gridRef.current!.api!.applyTransaction({ update: itemsToUpdate })!;
  };

  const updateItem = (id:any, res:any) => {
    const itemsToUpdate: any[] = [];
        gridRef.current!.api!.forEachNode((rowNode) => {
          const { data } = rowNode;
          if (data.id === id) {
            data.frequency = res === 0 ? '-' : res.toFixed(1);
          }
          itemsToUpdate.push(data);
        });
        gridRef.current!.api!.applyTransaction({ update: itemsToUpdate })!;
  };
    /** 프리퀀시 개별클릭 */
  const confirmFrequency = (e: any) => {
    // eslint-disable-next-line no-nested-ternary
    ApiUtil.get('/api/mngAd/camp/rpt/frequency', {
      params: {
        data: {
          id: e.id,
        },
      },
    }).then((resp) => {
      updateItem(e.id, resp.data.data);
    });
  };
  const updateFrequencies = (rptData:any) => {
    if (rptData.length === 0) return;
    const itemsToUpdate: any[] = [];
        gridRef.current!.api!.forEachNode((rowNode, index) => {
          const { data } = rowNode;
          const { frequency } = rptData[index];
          data.frequency = frequency === 0 ? '-' : frequency.toFixed(1);
          // data.frequency = '-';
          itemsToUpdate.push(data);
        });
        gridRef.current!.api!.applyTransaction({ update: itemsToUpdate })!;
        $('#frequencyAll').prop('disabled', false);
  };

  // grid 요소
  const campListGrid: IComponentsForMngAdCamp = {
    searchUrl: '/api/mngAd/camp/list',
    colDefs: [
      { field: 'memberName', headerName: '메이커명', minWidth: 150 },
      { field: 'makerLoginId', headerName: '메이커ID', minWidth: 150 },
      {
        field: 'projectNm', headerName: '프로젝트명', minWidth: 200, cellRenderer: nodataRenderer,
      },
      {
        field: 'projectId', headerName: '프로젝트ID', cellRenderer: nodataRenderer, minWidth: 150,
      },
      {
        field: 'campNm', headerName: '캠페인명', cellRenderer: campNameRenderer, minWidth: 200,
      },
      { field: 'campId', headerName: '캠페인ID', minWidth: 150 },
      {
        field: 'campPerd', headerName: '캠페인 기간', minWidth: 200, cellRenderer: campPerdRenderer,
      },
      {
        field: 'campBudgetTotal1', headerName: '총 예산', minWidth: 200, cellRenderer: campBudgetTotalRenderer
      },
      {
        field: 'campViewStatus', headerName: '상태', minWidth: 150, cellRenderer: statusRenderer,
      },
      {
        field: 'mediaDiv4', headerName: '노출 매체', minWidth: 200, cellRenderer: mediaDiv4Renderer,
      },
      {
        field: 'ctr', headerName: '클릭률(CTR)', cellRenderer: setCtrRenderer, minWidth: 150,
      },
      {
        field: 'convRate', headerName: '전환율', cellRenderer: setConvRateRenderer, minWidth: 150,
      },
      {
        field: 'costPerConv', headerName: '전환당 비용', cellRenderer: setCostPerConvRenderer, minWidth: 150,
      },
      {
        field: 'roas', headerName: '수익률(ROAS)', cellRenderer: setRoasRenderer, minWidth: 150,
      },
      {
        field: 'frequency',
        headerName: '게재 빈도',
        headerClass: 'ag-center-aligned-header',
        cellClass: 'ag-center-aligned-cell',
        minWidth: 150,
        // eslint-disable-next-line consistent-return
        cellRenderer: (params: any) => {
          if (params.data.frequency !== undefined) {
            return params.data.frequency === 0 ? '-' : params.data.frequency;
          }
          if (params.data.frequency === undefined || params.data.frequency) {
            // eslint-disable-next-line react/prop-types
            return <a id={`f-${params.data.id}`} className="txt-link frequency" href="#javascript" onClick={() => confirmFrequency(params.data)}>보기</a>;
          }
        },
      },
    ],
    searchTypes: [{ id: 'memberName', text: '메이커명', selected: true }, { id: 'makerId', text: '메이커ID' }, { id: 'projectNm', text: '프로젝트명' }, { id: 'projectId', text: '프로젝트ID' }],
    allIncludedYn: [{ id: 'false', text: '일부 포함', selected: true }, { id: 'true', text: '포함' }],
    callback: (data: ICampListSearchForm) => {
      const param = {
        allIncludedYn: data.allIncludedYn, campStartDate: data.campStartDate, campEndDate: data.campEndDate, rptStartDate: data.rptStartDate, rptEndDate: data.rptEndDate, searchType: data.searchType, keyword: data.keyword,
      };
      return param;
    },
  };

  const [columnDefs] = useState<IComponentsForMngAdCamp>(campListGrid);
  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: false,
    headerClass: 'ag-center-aligned-header',
    cellClass: 'ag-center-aligned-cell',
  }), []);

  /** 전체보기 */
  const onFrequencyReady = useCallback(() => {
    $('#frequencyAll').prop('disabled', true);
    ApiUtil.get('/api/mngAd/camp/rpt/frequencyAll', {
      params: {
        data: columnDefs.callback(getValues()),
      },
    }).then((resp) => {
      updateFrequencies(resp.data.data);
    });
  }, []);

  const changePagination = () => {
        childRef!.current!.onPaginationChanged();
  };

  const updateSearchTypeForCampList = (value: any) => {
    setValue('searchType', value);
  };
  const updateAllIncludedYn = (value: any) => {
    setValue('allIncludedYn', value);
  };
  const updateCampPerdDate = (start:Dayjs, end:Dayjs) => {
    const startDate = start.format('YYYYMMDD');
    const endDate = end.format('YYYYMMDD');
    setValue('campStartDate', startDate);
    setValue('campEndDate', endDate);
  };

  const loadDataRpt = useCallback(() => {
    ApiUtil.get('/api/mngAd/camp/rpt', {
      params: {
        data: columnDefs.callback(getValues()),
      },
    }).then((resp) => {
      updateItems(resp.data.data);
    });
  }, []);

  const loadData = useCallback((gridReloadd:any) => {
    if (gridReloadd !== false) {
          gridRef.current!.api.showLoadingOverlay();
    }
    ApiUtil2.get(columnDefs.searchUrl, {
      params: {
        data: columnDefs.callback(getValues()),
      },
    }).then((resp) => {
      setRowData(resp.data.data);
      if (resp.data.data.length === 0) {
        setNoDataMsg('검색 결과가 없습니다.');
        gridRef.current!.api.showLoadingOverlay();
      }
      (document.querySelector<HTMLElement>('.ag-root-wrapper.ag-ltr.ag-layout-auto-height')! as any).style.height = '';
      (document.querySelector<HTMLElement>('.ag-root-wrapper-body.ag-focus-managed.ag-layout-auto-height')! as any).style.height = '';
      loadDataRpt();
    });
    return setRowData([]);
  }, []);

  const updateRptDate = (start:Dayjs, end:Dayjs) => {
    const startDate = start.format('YYYYMMDD');
    const endDate = end.format('YYYYMMDD');
    setValue('rptStartDate', startDate);
    setValue('rptEndDate', endDate);
    if (getValues('rptStartDate') !== undefined) loadDataRpt();
  };

  const noRowsOverlayComponentParams = useMemo(() => ({
    noDataMsg,
  }), [noDataMsg]);

    const onBtnExport = useCallback(() => {
        setIsDlDialogOpened(true)
        // const data: ICampListSearchForm = getValues();
        // const q = qs.stringify(data);
        // window.location.assign(`/api/mngAd/camp/download?${q}`);
    }, []);

    useEffect(() => {
        if (rowData.length !== 0) {
            setRowDataEmpty(false);
        }
    });
    useEffect(() => {
        // 툴팁
        $('#campPerdTooltip').tooltip({
            content: '<p class="fz-12 fc-0 fw-medium">설정한 기간에 부합하는 캠페인 목록이 조회됩니다.</p><p class="fz-12 fc-0">‘일부 포함’ 선택 시: 설정한 기간에 캠페인 기간이 1일이라도 겹치는 목록이 조회됩니다.</p><p class="fz-12 fc-0">‘포함’ 선택 시 : 설정한 기간에 캠페인 기간이 완벽하게 포함되는 목록이 조회됩니다.\n</p>',
        });

        $('#rptPerdTooltip').tooltip({
            content: '<p class="fz-12 fc-0 fw-medium">클릭률, 전환율, 전환당 비용, 수익률, 게재빈도에 대한 <br> 조회 기간을 설정합니다.</p>',
        });
        return () => {
            $('.tooltip').remove();
        };
    }, []);
    return (
      <Fragment>
        <PageTitle>캠페인 조회</PageTitle>
        {isDlDialogOpened ? (
          <DlDialog
            url={url.pathname}
            setIsDlDialogOpened={setIsDlDialogOpened}
            allIncludedYn={$('#allIncludedYn').val()}
            srchType={getValues('searchType')}
            keyword={getValues('keyword')}
            rptStartDate={getValues('rptStartDate')}
            rptEndDate={getValues('rptEndDate')}
            campStartDate={getValues('campStartDate')}
            campEndDate={getValues('campEndDate')}
          />
        ) : null}

        <section className="wrap-section wrap-datagrid">
          <div className="wrap-filter">
            <div className="inner-filter">
              <div className="box-left">
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">
                      캠페인 기간 <Link to="#" className="ico-tooltip tooltip-f" title="" id="campPerdTooltip"></Link>
                    </p>
                  </div>
                  <div className="box-filter">
                    <SingleSelect2
                      id={`allIncludedYn`}
                      data={allIncludedYnSelectData}
                      selectEvent={updateAllIncludedYn}
                      clss={'w-150'}
                    />
                  </div>
                </div>
                <div className="item-filter">
                  <div className="box-filter">
                    <RangeDatePicker
                      changeEvent={updateCampPerdDate}
                      option={{
                        startDate: dayjs().startOf('day').subtract(30, 'd'),
                        endDate: dayjs().startOf('day').subtract(1, 'd'),
                      }}
                    />
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">검색어</p>
                  </div>
                  <div className="box-filter">
                    <SingleSelect2
                      data={campListGrid.searchTypes}
                      selectEvent={updateSearchTypeForCampList}
                      clss={'w-150'}
                    />
                  </div>
                </div>
                <div className="item-filter">
                  <div className="box-filter">
                    <div className="input-group comp-search">
                      <div className="inner-input-group">
                        <input
                          type="text"
                          className="tf-comm"
                          placeholder="검색어를 입력해 주세요."
                          {...register('keyword')}
                        ></input>
                        <i className="ico ico-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-right">
                <button type="submit" className="btn btn-tertiary-mint btn-ico" onClick={loadData}>
                  <i className="ico ico-filter"></i>필터 조회
                </button>
              </div>
            </div>
          </div>
          <div className="box-header">
            <div className="box-tit">
              <div className="filter-tit">
                <p className="fz-12 fc-2">
                  성과조회기간 <Link to="#" className="ico-tooltip tooltip-f" title="" id="rptPerdTooltip"></Link>
                </p>
              </div>
              <div className="box-filter">
                <RangeDatePicker2
                  changeEvent={updateRptDate}
                  option={{
                    startDate: dayjs().startOf('day').subtract(30, 'd'),
                    endDate: dayjs().startOf('day').subtract(1, 'd'),
                  }}
                  rowDataEmpty={rowDataEmpty}
                />
              </div>
            </div>
            <div className="box-option">
              <button
                id="frequencyAll"
                type="button"
                className="btn btn-primary-outline"
                onClick={onFrequencyReady}
                disabled={rowDataEmpty}
              >
                게재빈도 전체보기
              </button>
              <button
                type="button"
                className="btn btn btn-secondary-outline btn-ico"
                onClick={onBtnExport}
                disabled={rowDataEmpty}
              >
                <i className="ico ico-download"></i>다운로드
              </button>
            </div>
          </div>
          <div className="box-body">
            <div className="ag-grid">
              <div className="ag-grid-inner">
                <AgGridReact
                  ref={gridRef}
                  rowData={rowData}
                  columnDefs={columnDefs.colDefs}
                  defaultColDef={defaultColDef}
                  rowHeight={48}
                  pagination={true}
                  paginationPageSize={10}
                  suppressPaginationPanel={true}
                  onPaginationChanged={changePagination}
                  domLayout={'autoHeight'}
                  noRowsOverlayComponent={NoDataTemplate2}
                  noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                  alwaysShowHorizontalScroll={true}
                ></AgGridReact>
              </div>
            </div>
          </div>
          <div className="box-footer">
            <Pagination gridRef={gridRef} ref={childRef} />
          </div>
        </section>

        {/* 로딩 overlay */}
        <div className="loader none">
          <div className="stage">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
          <p className="txt-loader">LOADING</p>
        </div>
      </Fragment>
    )
};
export default MngAdCampList;
