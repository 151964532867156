import React, {forwardRef, Fragment, useEffect, useRef, useState} from 'react';
import {Dialog} from "rc-easyui";
import {PlainObject} from "select2";
import {useForm} from "react-hook-form";
import _ from "lodash";
import alertify from 'alertifyjs'
import { useMutation } from '@tanstack/react-query'
import { ApiUtil2 } from '@biz/api'


interface CheckListForm {
  catCode: string,
  sortOrder: number,
  content: string,
  isNew:boolean,
  isCommon:boolean,

  sortOrderPrev: number,
  contentPrev: string,
}

const CreationCheckDialog = (props : any, ref : any) => {
  const dialogRef = useRef<any>();
  const sortOrderSelect = useRef<HTMLSelectElement>(null);

  const [memoLength, setMemoLength] = useState<number>(0);
  const { register, setValue, getValues, watch } = useForm<CheckListForm>();

  const clearMemo = () => {
    setValue('content', '');
    setMemoLength(0);
  }

  const postList = useMutation((formData : any) => ApiUtil2.post(`/api/mngAd/creation/creationCheckMng/checkList`, formData), {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        alertify.success('정상적으로 적용되었습니다.');
        props.setShowCreationCheckDialog(false);
        props.onGridReady();
      }
    },
  });

  const modList = useMutation((formData : any) => ApiUtil2.post(`/api/mngAd/creation/creationCheckMng/checkListMod`, formData), {
    onSuccess: (resp) => {
      if (resp.data.ok) {
        alertify.success('정상적으로 적용되었습니다.');
        props.setShowCreationCheckDialog(false);
        props.onGridReady();
      }
    },
  });

  const submitList = () => {
    const formData = {
      ...getValues(),
      content : encodeURIComponent(getValues('content')),
      contentPrev : encodeURIComponent((getValues('contentPrev'))),
    };

    if (_.isEmpty(formData.content)) {
      alertify.error(props.isNew ? '등록하실 체크리스트 내용을 기입해 주세요.' : '수정하실 체크리스트 내용을 기입해 주세요.');
      return;
    }
    if (props.isNew) {
      postList.mutate(formData);
    } else {
      modList.mutate(formData);
    }
  };

  useEffect(() => {
    $(sortOrderSelect.current as PlainObject).select2({
      width: '100px',
      minimumResultsForSearch: Infinity
    }).on('select2:select', (e : any) => {
      setValue('sortOrder', e.params.data.id);
    });

    setValue('catCode', props.catCode);
    setValue('isNew', props.isNew);
    setValue('sortOrder', props.selectedOrder);
    setValue('content', decodeURIComponent(props.selectedContent));
    setValue('isCommon', props.isCommon);

    if (!props.isNew) {
      $(sortOrderSelect.current as PlainObject).val(props.selectedOrder).trigger('change');
      setValue('sortOrderPrev', props.selectedOrder);
      setValue('contentPrev', decodeURIComponent(props.selectedContent));
    }
  }, []);

  return (
    <Fragment>
      <Dialog title={`셀프 체크리스트 ${ props.isNew ? '등록' : '수정' }`} modal resizable shadow={false} ref={dialogRef} style={{ width: '900px' }}>
        <div className="dialog">
          <div className="dialog-body">
            <div className="wrap-section wrap-tbl">
              <div className="box-body">
                <div className="tbl">
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">
                            카테고리명
                        </span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <span className="comp-txt">
                            <span className="table">
                                <span className="table-cell">
                                    <b className="fz-14 fc-2">{props.cateDivDesc}{props.catCode != 'COMMON' && props.isCommon ? '(공통)' : ''}</b>
                                </span>
                            </span>
                        </span>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">
                            노출순서
                        </span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <select className="select2-single" ref={sortOrderSelect}>
                          {
                            Array(props.isNew ? props.markCheckList.length + 1 : props.markCheckList.length).fill(0).map((_, i) => (
                              <option key={i+1} value={i+1}>{i+1}</option>
                            ))
                          }
                          {/*{props.markCheckList.map((item : any, idx : any) => (*/}
                          {/*  <option key={idx} value={idx}>{idx}</option>*/}
                          {/*))}*/}
                        </select>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">
                            내용
                        </span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <div className="input-group expand">
                          <div className="inner-input-group">
                            <textarea className="tf-textarea" maxLength={100} { ...register('content') } onChange={(e) => setMemoLength(e.target.value.length)} disabled={props.catCode != 'COMMON' && props.isCommon && !props.isNew}></textarea>
                            <i className="ico ico-delete" onMouseDown={clearMemo}></i>
                          </div>
                          <p className="fz-12 fc-3"><span className="fc-2">{memoLength}</span> / 100</p>
                          <p className="fz-12"><i className="ico"></i>체크 / 에러 문구 내용 영역</p>
                        </div>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div className="dialog-footer">
            <button type="button" className="btn btn-secondary-outline large" onClick={(e) => {
              props.setShowCreationCheckDialog(false);
            }}>취소</button>
            <button type="button" className="btn btn-primary large" onClick={submitList}>{ props.isNew ? '등록' : '수정' }</button>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
}


export default forwardRef(CreationCheckDialog);
