import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { legacyAPIErrorFilter, fetchPostPayment, type PaymentResult, type PaymentData } from '@biz/api'
import { useCurrentCampaignIdQuery } from '@biz/query'

// CATION 이 쿼리는 반드시 로컬에서 사용할 것으로 판단하고, 일반적인 작명을 했다. 만약 이동하려면, 이름 변경이 필요하다.
type FunctionType = () => UseMutationResult<
  { ok: boolean; data: PaymentResult },
  unknown,
  Omit<PaymentData, 'campId'>,
  unknown
>
export const usePaymentMutation: FunctionType = () => {
  const { data: campaignId } = useCurrentCampaignIdQuery()
  return useMutation({
    mutationFn: (data) =>
      !!campaignId
        ? fetchPostPayment({ ...data, campId: campaignId }).catch((err) => {
            return err?.response?.data || err
          })
        : Promise.reject('no campaign id'),
  })
}
