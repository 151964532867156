import { useQuery } from '@tanstack/react-query'
import { fetchPomValidation } from '@biz/api'
import { useCurrentCampaignIdQuery } from '@biz/query'

export const usePomValidQuery = (isEnabled: boolean) => {
  const { data: campaignId } = useCurrentCampaignIdQuery()
  return useQuery({
    queryKey: ['@apps/biz-center/targetCreatePage/usePomValid'],
    queryFn: () => (!!campaignId ? fetchPomValidation(campaignId).then((res) => res) : Promise.reject({})),
    refetchOnMount: 'always',
    enabled: isEnabled && Boolean(campaignId),
  })
}
