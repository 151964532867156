import { Tip } from './Tip'
import styles from './CampaignRange.module.scss'
export const BottomCaption = () => (
  <div className={styles.content}>
    <Tip>{'캠페인 기간을 최소 5일 이상으로 설정해야 성과를 높일 수 있어요!'}</Tip>
    <ul className={styles.bottomContent}>
      <li>{'와디즈 타겟 광고는 광고 성과를 높이기 위해 머신러닝 기술을 사용합니다.'}</li>
      <li>{'머신러닝은 5일이 지나야 최적화된 타겟 유저를 찾을 수 있어요.'}</li>
    </ul>
  </div>
)
