import React, {
  Fragment, useContext, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useMemberIdQuery } from '@biz/query'
import { ApiUtil, ApiUtil2 } from '@biz/api'
import NoBellAlertTemplate from '../../common/NoBellAlertTemplate';

const BellAlertForHome = () => {
  const { data: loginMemberId } = useMemberIdQuery()
  const navigator = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    ApiUtil2.get('/api/makerCommon/bellAlert', {
    }).then((resp) => {
      setData(resp.data.data);
    });
  }, [loginMemberId]);

  const BellAlertItem = (item: any) => {
    const formattedDate = `${item.item.regTime.date.year}-${item.item.regTime.date.month}-${item.item.regTime.date.day}`;
    return (
            <div className="form-group">
                <span className="fz-14 fc-4">{ formattedDate }</span>
                <Link to={`/makerCommon/bellAlert/${item.item.id}`} className={item.item.viewYn === false ? 'fz-14 fc-2 txt-dot ico-unread' : 'fz-14 fc-2 txt-dot'} onClick={() => { ApiUtil.post('/api/makerCommon/bellAlert/read', { id: item.item.id }); }}>{item.item.text}</Link>
            </div>
    );
  };

  return (
        <Fragment>
            <div className="box box-alert-list">
                <section className="wrap-section wrap-tbl">
                    <div className="box-header">
                        <div className="box-tit">
                            <h2 className="fz-20 fw-bold fc-1">알림</h2>
                        </div>
                        <div className="box-option">
                            <button type="button" className="btn btn-secondary-txt btn-ico"
                                    onClick={() => navigator('/makerCommon/bellAlert')}>
                                <i className="ico ico-more"></i>더보기
                            </button>
                        </div>
                    </div>
                    <div className="box-body">
                        {data.length === 0 ? <NoBellAlertTemplate/>
                          : <div className="tbl">
                                <dl className="vertical">
                                    <dd id="list">
                                        {data.map((item: any, index: any) => (index < 7
                                          ? <BellAlertItem key={index} item={item}/> : ''))}
                                    </dd>
                                </dl>
                            </div>
                        }
                    </div>
                </section>
            </div>
        </Fragment>
  );
};

export default React.memo(BellAlertForHome);
