import { axios } from '../axios'

export type ValidCheckInfoType = {
  catProhKwd: string[]
  catInspDiv: string
  category: string
  catProhKwdValid: boolean
  catProhKwdValidsByCreationData: { [index: string]: boolean }
}

type ReturnType = {
  ok: boolean
  data: ValidCheckInfoType
}

type FunctionType = (campId: string | number, creationDataId?: string | number) => Promise<ReturnType>
export const fetchValidCheck: FunctionType = (campId, creationDataId) =>
  axios.get(
    `/api/camp/make02/creationDataCatProhKwdValid?campId=${campId}${
      creationDataId ? '&creationDataId=' + creationDataId : ''
    }`
  )
