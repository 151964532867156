import alertify from 'alertifyjs'

/* 비즈센터 레거시 API 중, 에러 상황에서 { ok: false, data: { msg: string, title: string } } 의 형식으로 에러를 올리는
  API를 대비하기 위함이다.
 */
export const legacyAPIErrorFilter = (fetchAPI: Promise<any>, errorMessage: string) =>
  fetchAPI.then((res) => {
    if (res?.ok === false) {
      const { msg, title } = res?.data as unknown as { msg: string; title: string }
      alertify.error(msg || errorMessage, { title })
    }
    return res
  })
