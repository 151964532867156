import React, {
    Fragment, useEffect, useMemo, useState
} from 'react';
import {useForm} from 'react-hook-form';
import PLTargetGrid from "../postpaidMoneyLimit/PLTargetGrid";
import ALHistoryGrid from "../postpaidMoneyLimit/PLHistoryGrid";
import alertify from 'alertifyjs'
import { ApiUtil3 } from '@biz/api'
import {IAEMRegReq} from "../allowedEntrepreneurManagement/AllowedEntrepreneurRegDialog";
import AODTGrid from "./AODTGrid";
import AODTHistoryGrid from "./AODTHistoryGrid";
export interface IAODTRegReq {
    adjust: string;
    cause: string;
    serialId: number;
}

export interface IAODTInfo {
    data:any
    serialId: number;
    makerId: number;
    makerNm: string;
    projectDiv: string;
    projectId: number;
    serviceType: string;
    serviceID: number;
    serviceArea: string;
    basicFees: number;
    completeDate: string;
    amountFee: number;
    date: string;
    executor: string;
    adjustmentPayment: number;
    comment: string;
}
const AODTManagementDialog = (props: any) => {
    const {watch} = useForm<IAODTRegReq>();
    const {
        adjust, cause, serialId
    } = watch();

    const formData = {
        adjust, cause, serialId
    };

    useEffect(() => {
        /** 다이얼로그 */
        $('#AODTMngDialog').dialog({
            title: '관리', closeOnEscape: true, width: 900,
        });

        $(document).on('click', '#cancelBtn', () => {
            $('#AODTMngDialog').dialog('close');
            props.setIsDialogOpened(false);
            $('#AODTMngDialog').dialog('destroy');
        });

        $('#adjust').on('keyup', () => {
            let value = $('#adjust').val() as string;
            const regex = /^\d+$/;
            if (!regex.test(value)) {
                $('#adjust').val(value.replace(/[^\d]/g, ''));
            }
            if (value.length > 10) {
                $('#adjust').val(value.substring(0, 10));
            }
        });

        $('#cause').on('keyup', () => {
            let value = $('#cause').val() as string;
            const maxLengthInBytes = 500;
            const encoder = new TextEncoder();
            const encodedValue = encoder.encode(value);
            const length = encodedValue.length;

            if (length > maxLengthInBytes) {
                const slicedValue = encodedValue.slice(0, maxLengthInBytes);
                const decoder = new TextDecoder();
                value = decoder.decode(slicedValue);
                $('#cause').val(value);
            }
            $('#byteCount').text(length);
        })

        $('#reg').on('click', () => {
           formData.adjust = $('#adjust').val() as string;
           formData.cause = $('#cause').val() as string;
           formData.serialId = props.serialId;

           if (formData.adjust === '') {
               alertify.error('조정금액을 입력해주세요.');
               return;
           }
            alertify.confirm('조정금액을 ' + $('#adjust').val() + '원으로 조정합니다.', () => {
                ApiUtil3.post<IAEMRegReq>('/api/bzmAdmin/postpaidMoneyManagement/AODT/modify', formData).then(() => {
                    $('#AODTMngDialog').dialog('close');
                    props.setIsDialogOpened(false);
                    props.onGridReady();
                });
            }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
        });

        return () => {
            $('#AODTMngDialog').dialog('destroy');
        };
    }, []);

    return (
        <Fragment>
            <form>
                <div id='AODTMngDialog' className="dialog" style={{display: 'none'}}>
                    <div className="dialog-body">
                        <div className="wrap-section wrap-tbl">
                            <div className="box-header">
                                <div className="box-tit">
                                    <span className="fz-16 fc-1">
                                        대상 정보
                                    </span>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="ag-grid">
                                    <div className="ag-grid-inner">
                                        <AODTGrid serialId={props.serialId}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wrap-section wrap-tbl">
                            <div className="box-header">
                                <div className="box-tit">
                                    <span className="fz-16 fc-1">
                                        히스토리
                                    </span>
                                </div>
                            </div>
                            <div className="box-body" style={{maxHeight: 500, overflow: 'auto'}}>
                                <div className="ag-grid">
                                    <div className="ag-grid-inner">
                                        <AODTHistoryGrid serialId={props.serialId}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wrap-section wrap-tbl">
                            <div className="box-header">
                                <div className="box-tit">
                                    <span className="fz-16 fc-1">
                                        차감대상 조정
                                    </span>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="tbl">
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    조정금액
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="inner-input-group">
                                                        <input type="number" step="1" className="tf-comm"
                                                               placeholder="숫자만 입력해 주세요."
                                                               id="adjust"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            <div className="dt-inner">
                                                <span className="fz-16 fc-1">
                                                    조정사유
                                                </span>
                                            </div>
                                        </dt>
                                        <dd>
                                            <div className="form-group">
                                                <div className="input-group small">
                                                    <div className="inner-input-group">
                                                        <textarea className="tf-textarea" id="cause" maxLength={500}
                                                                  style={{width : 500, minWidth: 500}}
                                                                  />
                                                    </div>
                                                    <p className="fz-12 fc-3"><span className="fc-2" id='byteCount'>0</span> / 500</p>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dialog-footer">
                        <button type="button" className="btn btn-secondary-outline" id={'cancelBtn'}>취소
                        </button>
                        <button type="button" className="btn btn-primary" id="reg">확인</button>
                    </div>
                </div>
            </form>
        </Fragment>
    );
};
export default React.memo(AODTManagementDialog);
