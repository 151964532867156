import { useState, useCallback } from 'react'
import { Button, Modal } from 'antd'
import { useRemainAmountQuery } from '@biz/query'
import { ChargeAmountInput } from './ChargeAmountInput'
import { PaymentType, type PayType } from './PaymentType'
import { InfoBox } from './InfoBox/'
import { Notification, PurchaseReport } from './Contents'
import { SelectBizNumber } from './SelectBizNumber'
import { BizMoneyChargeActionButton } from './BizMoneyChargeActionButton'

import styles from './BizMoneyChargeButton.module.scss'

export const BizMoneyChargeButton: React.FC = () => {
  const [selectedBizNumber, setSelectedBizNumber] = useState<{ projectId: string; bizNo: string } | null>(null)

  const [amount, setAmount] = useState(10000) // 기본 충전 금액
  const [payType, setPayType] = useState<PayType>(null)
  const [isOpen, setOpen] = useState(false)
  const doClose = useCallback(() => {
    setAmount(10000)
    setOpen(false)
  }, [])

  return (
    <>
      <Button size="large" type="primary" onClick={() => setOpen(true)} className={styles.containBizMoneyChargeButton}>
        {'비즈머니 충전'}
      </Button>
      <Modal
        open={isOpen}
        onCancel={doClose}
        width={640}
        title={<h2 className={styles.title}>{'비즈머니 충전'}</h2>}
        footer={
          <div className={styles.footerStyle}>
            <Button size="large" block onClick={doClose}>
              {'취소'}
            </Button>
            <BizMoneyChargeActionButton
              projectId={selectedBizNumber?.projectId}
              amount={amount}
              payType={payType}
              onComplete={doClose}
            />
          </div>
        }
        className={styles.modalContainer}
      >
        <section>
          <div className={styles.subTitle}>{'충전 사업자 번호'}</div>
          <SelectBizNumber onChange={setSelectedBizNumber} />

          <div className={styles.content}>
            <InfoBox noTitle={<PurchaseReport />} />
          </div>
        </section>
        <section>
          {/* 충전금액 */}
          <ChargeAmountInput value={amount} onChange={setAmount} />
          <div className={styles.content}>
            <InfoBox title={'아래 내용을 꼭 확인해 주세요'}>
              <Notification />
            </InfoBox>
          </div>
        </section>
        <PaymentType value={payType} onChange={setPayType} />
      </Modal>
    </>
  )
}
