import { Outlet } from 'react-router-dom'
import { LogoHeader } from './LogoHeader'
import { ErrorContainer } from './ErrorContainer'

export const ErrorLayout = () => (
  <div style={{ height: '100vh' }}>
    <LogoHeader />
    <ErrorContainer>
      <Outlet />
    </ErrorContainer>
  </div>
)
