import React, {Fragment} from 'react';
import {Link, useLocation} from "react-router-dom";

const PomForMakerTab = () => {
    const url = useLocation();
    return (
        <Fragment>
            <div className="comp-tab">
                <Link to="/pom/dailyUsage" className={url.pathname === '/pom/dailyUsage' ? 'tab selected' : 'tab' }>
                    <span className="tab-item">일간 이용내역</span>
                </Link>
                <Link to="/pom/dailyUsage/detail" className={url.pathname === '/pom/dailyUsage/detail' ? 'tab selected' : 'tab' }>
                    <span className="tab-item">상세 내역</span>
                </Link>
            </div>
        </Fragment>
    );
};

export default PomForMakerTab;