import { useQuery, type QueryClient } from '@tanstack/react-query'
import { loginLocalStorage, fetchLoginCheck, type LoginCheckType, type AuthorityType } from '@biz/api'
import { setLoginStatus, getLoginStatus } from '../useLoginStatusQuery'
import { setRoleGroup } from '../useRoleGroupQuery'
import { setRole } from '../useRoleQuery'
import { setMemberId } from '../useMemberIdQuery'
import { setShadowLogin } from '../useShadowLoginQuery'
import { loadMakerInfo } from '../useMakerInfoQuery'

const replaceToDevUrl = (url: string) =>
  url.replace(/(redirect_uri=)[^&]*/, `redirect_uri=${encodeURIComponent('http://localhost:3000/callback/wadiz/api')}`)
type ResponseDataType = { ok: boolean; data: LoginCheckType | string }
const queryKey = ['@biz/query/auth/LoginCheck']
const makeQueryOption = (enableRedirect: boolean) => ({
  queryKey,
  queryFn: () =>
    fetchLoginCheck().then((res: ResponseDataType) => {
      const { ok, data: redirectURL } = res as { ok: boolean; data: string }
      if (enableRedirect && ok === false) {
        window.location.href =
          import.meta.env.VITE_WADIZ_REDIRECT_TARGET === 'localhost' ? replaceToDevUrl(redirectURL) : redirectURL
      }
      return res
    }),
  enabled: !loginLocalStorage.isExistUser(),
})

type UserInfo = {
  authorities: string[] // (28) ['ROLE_MNG_RPT_SALES_DP_PUSH', 'ROLE_ADMIN_MNG_BIZMONEY', 'ROLE_MNG_RPT_INTEGRATION', 'ROLE_ADMIN', 'ROLE_MNG_ACCOUNT_MAKER', 'ROLE_ADMIN_BIZMONEY_DETAIL', 'ROLE_MNG_ACCOUNT_ADMIN', 'ROLE_MNG_COMMUNITY_SYSTEM_DATA', 'ROLE_MNG_COMMUNITY_FAQ', 'ROLE_MNG_MD', 'ROLE_USER_ADMIN', 'ROLE_MNG_AD_CREATION', 'ROLE_MNG_TAXBILL', 'ROLE_EDIT_ALL', 'ROLE_MNG_RPT_FINANCE_DP_PUSH', 'ROLE_MNG_MONITORING', 'ROLE_MNG_ACC_ADJUST', 'ROLE_ADMIN_LOG', 'ROLE_MNG_AD_CAMP_LIST', 'ROLE_SHADOW', 'ROLE_MNG_ACC_ADJUST_TAXBILL', 'ROLE_MNG_ADMIN_SETTING', 'ROLE_ADMIN_MNG_POSTPAID_PAYMENT', 'ROLE_LOGIN', 'ROLE_ADMIN_ACTION', 'ROLE_MNG_RPT_FINANCE', 'ROLE_MNG_COMMUNITY_NOTICE', 'ROLE_MNG_RPT_SALES']
  id: number
  loginId: string
  memberName: string
  roleGroup: string // : "USER_ADMIN"
}
export const useLoginCheckQuery = () => useQuery(makeQueryOption(false))
export const loadLoginCheck = (queryClient: QueryClient) => async () => {
  const enableRedirect = (await getLoginStatus(queryClient)()) || true
  const loginData = await queryClient.fetchQuery(makeQueryOption(enableRedirect))
  setLoginStatus(queryClient)(loginData.ok)
  if (!loginData.ok) {
    return loginData
  }

  const userInfoContainer = loginLocalStorage.getUserInfo()
  const userInfo: UserInfo = userInfoContainer.data
  setRoleGroup(queryClient)(userInfo?.roleGroup)

  const { shadowInfo, shadowRoleInfo } = loginLocalStorage.getShadowInfo()
  if (shadowInfo !== null && shadowRoleInfo !== null) {
    setMemberId(JSON.parse(shadowInfo).id)
    setRole(queryClient)('ROLE_USER_MAKER')
    setShadowLogin(queryClient)(shadowRoleInfo.indexOf('ROLE_ADMIN_ACTION') >= 0)
    return loginData
  }

  if (userInfo !== null) {
    if (userInfo?.authorities === undefined) {
      return loginData
    }
    if (userInfo?.authorities.indexOf('ROLE_USER_MAKER') >= 0) {
      setRole(queryClient)('ROLE_USER_MAKER')
      await loadMakerInfo(queryClient)()
    } else {
      setRole(queryClient)('ROLE_ADMIN')
    }
    setMemberId(queryClient)(userInfo?.id)
    return loginData
  }

  await loadMakerInfo(queryClient)()

  return loginData
}
