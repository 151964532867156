import { useState, useCallback, useEffect } from 'react'
import { Upload, Button } from 'antd'
import { bytesToSize } from '@common/utils'
import { type ImageMetaDataType } from '@biz/query'
import { GifTypeSelector } from '../GifTypeSelector'
import { checkBeforeUpload } from './_utils/checkBeforeUpload'
import { useUploadMediaMutation } from './_queries/useUploadMediaMutation'
import { MediaFileDescriptor } from './MediaFileDescriptor'
import styles from './MediaUploader.module.scss'

type CustomRequestParams = {
  // action : ""
  // data : {}
  // filename : "file"
  // headers : {}
  // method : "post"
  file: File
  // onError : ƒ onError(err, ret)
  // onProgress : ƒ onProgress(e3)
  // onSuccess : ƒ onSuccess(ret, xhr)
}
type MediaUploaderFunction = {
  isSlideOnly: boolean
  value: ImageMetaDataType | null
  onChange: (v: ImageMetaDataType) => void
}
export const MediaUploader: React.FC<MediaUploaderFunction> = ({ isSlideOnly, value, onChange }) => {
  const mutation = useUploadMediaMutation()
  const { fileSize, fileName, localFile, isFetching } = value || {}

  const [loaderStatus, setLoaderStatus] = useState<string | undefined>()
  const [isOpen, setOpen] = useState(false)
  const customRequest = useCallback(
    async (param: any) => {
      const { file } = param as CustomRequestParams
      if (!file) {
        return
      }
      const checkedSpec = await checkBeforeUpload(isSlideOnly)(file)
      if (!checkedSpec) {
        return
      }
      onChange({ localFile: file, isFetching: true })
      setLoaderStatus('선택하신 이미지를 업로드 합니다')

      const uploadResult = await mutation.mutateAsync({ file })
      const {
        file: uploadFile,
        publicUrl,
        uploadId,
        fileId,
      } = uploadResult as {
        file: File
        publicUrl: string
        uploadId: number
        fileId: number
      }
      setLoaderStatus(undefined)
      const { width, height, duration } = checkedSpec
      const imageType = file.type.split('/')[0].toLocaleLowerCase()
      const fileDiv = imageType === 'image' || imageType === 'img' ? 'img' : 'vod'
      onChange({
        localFile: undefined,
        isFetching: false,
        uploadId,
        fileId,
        fileName: file.name,
        publicUrl,
        fileSize: file.size,
        afMeta: {
          width: '' + width,
          height: '' + height,
          fileDiv,
          ext: file.type.split('/')[1],
        },
        afName: file.name,
      })
    },
    [value, onChange]
  )
  useEffect(() => {
    const isUploadCompleteState = localFile === undefined
    const isSelectedGifFile =
      isUploadCompleteState && Boolean(value?.afMeta?.ext === 'gif' || value?.localFile?.type === 'image/gif')
    setOpen(isSelectedGifFile && !!value && value?.gifStyle === undefined)
  }, [value, setOpen])
  return (
    <div className={styles.MediaUploader}>
      {!value ? (
        <div className={styles.placeholder}>{'첨부 파일 업로드'}</div>
      ) : (
        <MediaFileDescriptor
          name={(isFetching ? localFile?.name : fileName) || ''}
          size={bytesToSize(Number(isFetching ? localFile?.size : fileSize))}
          state={loaderStatus}
        />
      )}
      <Upload
        accept="image/jpeg, image/jpg, image/png, image/gif, video/mp4, video/quicktime"
        customRequest={customRequest}
        maxCount={1}
        showUploadList={false}
      >
        <Button type="text" className={styles.button}>
          {'파일 선택'}
        </Button>
      </Upload>
      <GifTypeSelector
        isOpen={isOpen}
        onSelect={(sel) => {
          setOpen(false)
          onChange({ ...value, gifStyle: sel })
        }}
      />
    </div>
  )
}
