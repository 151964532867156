import { axios } from '../axios'

export type ImageAssetType = Partial<{
  afId: number | string // creationData.afList[].id
  campId: number //
  creationDataId: number // creationData.id
  gifDiv: 'vod' | 'img'
}>

type ResponseType = {
  ok: boolean
  data: boolean
}
type FunctionType = (data: ImageAssetType) => Promise<ResponseType>
export const fetchPostPreviewImage: FunctionType = (data) =>
  axios.post('/api/camp/make02/fbCreationDataFilePreviewInsert', data)
