import { Modal, Button } from 'antd'
import styles from './BudgetUpdateNoticeModal.module.scss'

export type BudgetUpdateInfoData = {
  campaignName: string
  rptTotalCost: number
  reducedBudget: number
  beforeCampBudgetTotal: number // 사용자가 입력한 값
  afterCampBudgetTotal: number
  cost: number
}
type BudgetUpdateNoticeModalFunction = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  info: BudgetUpdateInfoData | null
}
export const BudgetUpdateNoticeModal: React.FC<BudgetUpdateNoticeModalFunction> = ({
  isOpen,
  onClose,
  onConfirm,
  info,
}) =>
  !!info && (
    <Modal
      width={800}
      open={isOpen}
      closable={false}
      title={
        <div className={styles.modalTitle}>
          <div className={styles.campaignName}>[ {info.campaignName} ]</div>
          <div>캠페인 예산을 수정하시겠습니까?</div>
        </div>
      }
      footer={[
        <div className={styles.modalFooter} key={'footer'}>
          <Button type="default" block size="large" onClick={onClose} style={{ width: 200 }}>
            {'취소'}
          </Button>
          <Button type="primary" block size="large" onClick={onConfirm} style={{ width: 200 }}>
            {'수정'}
          </Button>
        </div>,
      ]}
      className={styles.containBudgetUpdateNoticeModal}
    >
      <div className={styles.customBody}>
        <div className={styles.notice}>
          <div>메이커님의 현재 캠페인의 광고 소진 비용을 확인하셨나요?</div>
          <div>
            캠페인 예산 감액 시 아래와 같은 경우 광고 <strong>노출 매체로부터 감액 요청이 거절</strong>될 수 있습니다.
          </div>
        </div>
        <ul className={styles.boxNotice}>
          <li>변경하시고자 하는 캠페인의 예산이 현재 소진 금액보다 적거나 비슷한 경우</li>
          <li>매체와의 연동 딜레이로 인해 비즈센터의 금액보다 더 많이 쓰여졌을 수 있습니다.</li>
          <li>캠페인 총 광고기간 * 소재개수를 계산하여, 최소 소재당 일일예산이 충족되지 않는 경우</li>
          <li>일별 소재 1개당 최소 예산인 5천 원 이상</li>
        </ul>
        <div className={styles.notice}>
          <div>
            캠페인 예산은
            <strong> 현재 소진된 금액보다 30% 이상 금액 혹은 소진된 금액이 없을 시 캠페인 최소 예산 이상 금액</strong>
            으로만 감액이 가능합니다.
          </div>
          <div>
            (현재 소진된 금액 : <strong style={{ color: '#00c4c4' }}>{info.rptTotalCost || 0}</strong> 원 / 감액 가능한
            예산 : <strong style={{ color: '#00c4c4' }}>{info.reducedBudget || 0}</strong> 원)
          </div>
          <div>
            감액 실패 시 알림으로 안내드리며, 메이커님의 원치 않으시는 예산 소진을 최소화하기 위해
            <br />
            <strong>캠페인이 자동 OFF 처리되며, 이전 설정하신 예산으로 자동 변경됩니다.</strong>
          </div>
        </div>
        <div className={styles.grayBox}>
          <div>
            현재 예산 <strong>{info.beforeCampBudgetTotal || 0}</strong> 원에서
          </div>
          <div>
            예산 <strong>{info.afterCampBudgetTotal || 0}</strong> 원으로 변경하시겠습니까?
          </div>
        </div>
      </div>
    </Modal>
  )

