import React, {Fragment, useEffect, useState} from 'react';
import {useOutletContext} from "react-router-dom";
import {useNavigate, useParams} from "react-router";
import { ApiUtil2 } from '@biz/api'
import { bytesToSize } from '../../../fileUploader/MultiFileUploaderForConsulting'
import { ApiUtil } from '@biz/api'
import alertify from 'alertifyjs'
import PomLimitAdjustmentInInquiryDialog from './PomLimitAdjustmentInInquiryDialog'
import PomInquiryProcessHistoryDialog from './PomInquiryProcessHistoryDialog'
import { ApiUtil3 } from '@biz/api'

export interface IDetailOfInquiry {
    pomInquiryId: number
    projectConfigId: number
    makerId: number
    makerLoginId: string,
    makerNm: string,
    makerNegativeScore: number,
    totalNegativeIssueCount: number,
    project2Id: string,
    projectNm: string,
    userNo: string,
    status: string,
    comment: string,
    updateTime: string,
    regTime: string,
    text: string,
    pomCurrentLimitValue: number,
    /** 사용자 신청 금액 */
    pomLimitValue: number,
    pomUseSum: number,
    afs: any[],
    significant: string,
    pomRemain: number,
    pomPaySum: number,
    data:any,
}
export const fileDownHandler = async (filePath: any, fileName : any) => {
    const response = await fetch(filePath);
    const file = await response.blob();
    ApiUtil.get('/api/common/isLogin').then((resp) => {
        if (resp.data.data === true) {
            const downloadUrl = window.URL.createObjectURL(file); // 해당 file을 가리키는 url 생성
            const anchorElement = document.createElement('a');
            document.body.appendChild(anchorElement);
            anchorElement.download = fileName as any; // a tag에 download 속성을 줘서 클릭할 때 다운로드가 일어날 수 있도록 하기
            anchorElement.href = downloadUrl; // href에 url 달아주기
            anchorElement.click(); // 코드 상으로 클릭을 해줘서 다운로드를 트리거
            document.body.removeChild(anchorElement); // cleanup - 쓰임을 다한 a 태그 삭제
        } else {
            alertify.error('와디즈 비즈센터 회원만 다운로드 가능합니다. ');
        }
    });
};
const PomInquiryDetail = () => {
    const setTitle = useOutletContext<any>();
    const navigate = useNavigate();
    const [isHistoryDialogOpened, setIsHistoryDialogOpened] = useState<boolean>(false);
    const [isAdjustmentOfPomLimitDialogOpened, setIsAdjustmentOfPomLimitDialogOpened] = useState<boolean>(false);
    const [projectConfigId, setProjectConfigId] = useState<number>();
    const { makerId, project2Id, pomInquiryId } = useParams();
    const [data, setData] = useState<IDetailOfInquiry>();
    const [answerAvailability, setAnswerAvailability] = useState<boolean>(true);
    const [commentCount, setCommentCount] = useState<number>(0);
    const [significantCount, setSignificantCount] = useState<number>(0);
    const [currentPomLimitValue, setCurrentPomLimitValue] = useState<number>(0);
    const [prevSignificant, setPrevSignificant] = useState('');

    const backHistory = () => {
        if (prevSignificant !== $('#significant').val()) {
            alertify.confirm('특이사항 항목에 변경하신 내용이 있습니다.<br><br>'
                + '저장하지 않으시고 이전 화면으로 이동하시겠습니까?.<br>', () => {
                navigate('/bzmAdmin/postpaidMoneyManagement/inquiry');
            }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
        } else {
            navigate('/bzmAdmin/postpaidMoneyManagement/inquiry');
        }
    }

    const statusHandler = (status: string) => {
        if (status === 'PERMITTED') return '승인';
        if (status === 'REJECTED') return '반려';
        if (status === 'HOLDING') return '보류';
        if (status === 'WAITING') return '대기';
    }

    const pomInquiryHistoryDialogHandler = () => {
        setIsHistoryDialogOpened(true);
    }
    const adjustPomLimitDialogHandler = () => {
        setIsAdjustmentOfPomLimitDialogOpened(true);
    }

    const setAnswerAvailabilityFlag = (status: any) => {
        if (status === 'PERMITTED' || status == 'REJECTED') {
            setAnswerAvailability(false);
        }
    }

    const replyHandler = () => {
        const status = $("input[type=radio][name=status]:checked").val();
        const comment = $('#comment').val();
        alertify.confirm('답변을 완료 하시겠습니까?<br><br> 답변 완료 시, 해당 문의를 작성한 메이커에게 알림톡이 발송됩니다.', () => {
            ApiUtil3.post('/api/bzmAdmin/postpaidMoneyManagement/inquiry/detail/reply', {status, comment, pomInquiryId}).then(() => {
               alertify.success('답변이 완료되었습니다.');
                setAnswerAvailabilityFlag(status);
            });
        }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
    }
    const significantHandler = () => {
        const significant = $('#significant').val();
        alertify.confirm('특이사항을 저정 하시겠습니까?', () => {
            ApiUtil3.post('/api/bzmAdmin/postpaidMoneyManagement/inquiry/detail/saveSignificant', {significant, pomInquiryId}).then(() => {
                alertify.success('특이사항이 정상적으로 저장 되었습니다.');
            });
        }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
    }
    const getData = () => {
        ApiUtil2.get<IDetailOfInquiry>('/api/bzmAdmin/postpaidMoneyManagement/inquiry/detail', { params: { makerId, project2Id, pomInquiryId } }).then((resp) => {
            if (resp.data.data.significant !== '' && resp.data.data.significant !== undefined) {
                setPrevSignificant(resp.data.data.significant)
            }

            setData(resp.data);
            setProjectConfigId(resp.data.data.projectConfigId);
            setCurrentPomLimitValue(resp.data.data.pomCurrentLimitValue);
            let status = resp.data.data.status;
            if (status !== '' && status !== undefined) {
                $('input:radio[name=status]:input[id='+status+']').attr('checked', 'checked');
            }
            if (status === 'PERMITTED' || status == 'REJECTED') {
                setAnswerAvailabilityFlag(status);
            }
            let comment = resp.data.data.comment as string;
            let significant = resp.data.data.significant as string;
            if (comment !== '' && comment !== undefined) {
                setCommentCount(comment.length);
            }
            if (significant !== '' && significant !== undefined) {
                setSignificantCount(significant.length);
            }
        });
    }
    useEffect(() => {
        setTitle('한도 증액 신청 상세내역');
        getData();

        $('#comment').on('keyup', () => {
            const value = $('#comment').val() as string;
            setCommentCount(value.length);
            if (value.length > 500) {
                $('#comment').val(value.substring(0, 500));
            }
        })

        $('#significant').on('keyup', () => {
            const value = $('#significant').val() as string;
            setSignificantCount(value.length);
            if (value.length > 400) {
                $('#significant').val(value.substring(0, 400));
            }
        });

        return () => {
            setTitle('');
        };
    }, []);

    return (
        <Fragment>
            { isHistoryDialogOpened ? <PomInquiryProcessHistoryDialog setIsHistoryDialogOpened={setIsHistoryDialogOpened} pomInquiryId={data?.data.pomInquiryId}/> :  null }
            { isAdjustmentOfPomLimitDialogOpened ? <PomLimitAdjustmentInInquiryDialog setIsAdjustmentOfPomLimitDialogOpened={setIsAdjustmentOfPomLimitDialogOpened} projectConfigId={projectConfigId} project2Id={project2Id} currentPomLimitValue={currentPomLimitValue} setCurrentPomLimitValue={setCurrentPomLimitValue} pomUseSum={data?.data.pomUseSum} pomRemain={data?.data.pomRemain} pomPaySum={data?.data.pomPaySum} getData={getData}/> :  null }
            <div className="wrap-section wrap-tbl">
                <div className="box-header">
                    <div className="box-tit">
                        <button type="button" className="btn btn-primary" onClick={backHistory}>이전 목록으로</button>
                    </div>
                    <div className="box-option">
                        <button type="button" className="btn btn-primary" onClick={pomInquiryHistoryDialogHandler}>히스토리</button>
                    </div>
                </div>
                <div className="box-body">
                    <div className="tbl">
                        <dl className="vertical">
                            {/* 메이커 정보 */}
                            <dd>
                                <div className="form-group">
                                    <div className="wrap-section wrap-tbl">
                                        <div className="box-header">
                                            <div className="box-tit">
                                                <h2 className="fz-20 fc-1 fw-bold">메이커 정보</h2>
                                            </div>
                                        </div>
                                        <div className="box-body">
                                            <div className="tbl">
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col col-10-4">
                                                            <dl className="vertical">
                                                                <dt>
                                                                    <div className="dt-inner">
                                                                <span className="fz-16 fc-1">
                                                                    메이커ID
                                                                </span>
                                                                    </div>
                                                                </dt>
                                                                <dd>
                                                                    <div className="form-group">
                                                                        <div className="input-group expand">
                                                                            <div className="inner-input-group">
                                                                                <input type="text" className="tf-comm" disabled value={data?.data.makerLoginId || ''}/>
                                                                            </div>
                                                                            <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                                        </div>
                                                                    </div>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                        <div className="col col-10-3">
                                                            <dl className="vertical">
                                                                <dt>
                                                                    <div className="dt-inner">
                                                                <span className="fz-16 fc-1">
                                                                    메이커명
                                                                </span>
                                                                    </div>
                                                                </dt>
                                                                <dd>
                                                                    <div className="form-group">
                                                                        <div className="input-group expand">
                                                                            <div className="inner-input-group">
                                                                                <input type="text" className="tf-comm" disabled value={data?.data.makerNm || ''}/>
                                                                            </div>
                                                                            <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                                        </div>
                                                                    </div>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                        <div className="col col-10-3">
                                                            <dl className="vertical">
                                                                <dt>
                                                                    <div className="dt-inner">
                                                                <span className="fz-16 fc-1">
                                                                    부정점수 / 부정건수
                                                                </span>
                                                                    </div>
                                                                </dt>
                                                                <dd>
                                                                    <div className="form-group">
                                                                        <div className="input-group expand">
                                                                            <div className="inner-input-group">
                                                                                <input type="text" className="tf-comm" disabled value={`${data?.data.makerNegativeScore === undefined ? 0 : data?.data.makerNegativeScore} / ${data?.data.totalNegativeIssueCount}`}/>
                                                                            </div>
                                                                            <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                                        </div>
                                                                    </div>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </dd>
                            {/* 메이커 정보 끝 */}
                            {/* 프로젝트 정보 정보 */}
                            <dd>
                                <div className="form-group">
                                    <div className="wrap-section wrap-tbl">
                                        <div className="box-header">
                                            <div className="box-tit">
                                                <h2 className="fz-20 fc-1 fw-bold">프로젝트 정보</h2>
                                            </div>
                                        </div>
                                        <div className="box-body">
                                            <div className="tbl">
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col col-10-3">
                                                            <dl className="vertical">
                                                                <dt>
                                                                    <div className="dt-inner">
                                                                    <span className="fz-16 fc-1 fw-bold">
                                                                        프로젝트 ID
                                                                    </span>
                                                                    </div>
                                                                </dt>
                                                                <dd>
                                                                    <div className="form-group">
                                                                        <div className="input-group expand">
                                                                            <div className="inner-input-group">
                                                                                <input type="text" className="tf-comm" disabled value={data?.data.project2Id || ''}/>
                                                                            </div>
                                                                            <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                                        </div>
                                                                    </div>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                        <div className="col col-10-4">
                                                            <dl className="vertical">
                                                                <dt>
                                                                    <div className="dt-inner">
                                                                    <span className="fz-16 fc-1 fw-bold">
                                                                        프로젝트명
                                                                    </span>
                                                                    </div>
                                                                </dt>
                                                                <dd>
                                                                    <div className="form-group">
                                                                        <div className="input-group expand">
                                                                            <div className="inner-input-group">
                                                                                <input type="text" className="tf-comm" disabled value={data?.data.projectNm || ''}/>

                                                                            </div>
                                                                            <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                                        </div>
                                                                    </div>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                        <div className="col col-10-3">
                                                            <dl className="vertical">
                                                                <dt>
                                                                    <div className="dt-inner">
                                                                    <span className="fz-16 fc-1 fw-bold">
                                                                        사업자등록번호(주민등록번호)
                                                                    </span>
                                                                    </div>
                                                                </dt>
                                                                <dd>
                                                                    <div className="form-group">
                                                                        <div className="input-group expand">
                                                                            <div className="inner-input-group">
                                                                                <input type="text" className="tf-comm" disabled value={data?.data.userNo || ''}/>
                                                                            </div>
                                                                            <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                                        </div>
                                                                    </div>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </dd>
                            {/* 프로젝트 정보 끝 */}
                            {/* 문의 정보 */}
                            <dd>
                                <div className="form-group">
                                    <div className="wrap-section wrap-tbl">
                                        <div className="box-header">
                                            <div className="box-tit">
                                                <h2 className="fz-20 fc-1 fw-bold">신청 정보</h2>
                                            </div>
                                        </div>
                                        <div className="box-header">
                                            <div className="box-tit">
                                                <h2 className="fz-20 fc-1 fw-bold">처리 상태</h2>
                                            </div>
                                            <dd>
                                                <button type="button" className="btn btn-secondary-outline" style={{color:'red'}}>{statusHandler(data?.data.status) || ''}</button>
                                            </dd>
                                        </div>
                                        <dl className="vertical">
                                            <div className="row">
                                                <div className="col col-10-3">
                                                    <dl className="vertical">
                                                        <dt>
                                                            <div className="dt-inner">
                                                            <span className="fz-16 fc-1 fw-bold">
                                                                최종 변경일시
                                                            </span>
                                                            </div>
                                                        </dt>
                                                        <dd>
                                                            <div className="form-group">
                                                                <div className="input-group expand">
                                                                    <div className="inner-input-group">
                                                                        <input type="text" className="tf-comm" disabled value={data?.data.updateTime || ''}/>
                                                                    </div>
                                                                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                                </div>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                                <div className="col col-10-3">
                                                    <dl className="vertical">
                                                        <dt>
                                                            <div className="dt-inner">
                                                            <span className="fz-16 fc-1 fw-bold">
                                                                최초 등록일시
                                                            </span>
                                                            </div>
                                                        </dt>
                                                        <dd>
                                                            <div className="form-group">
                                                                <div className="input-group expand">
                                                                    <div className="inner-input-group">
                                                                        <input type="text" className="tf-comm" disabled value={data?.data.regTime || ''}/>

                                                                    </div>
                                                                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                                </div>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                                <div className="col col-10-4">
                                                    <dl className="vertical">
                                                        <dt>
                                                            <div className="dt-inner">
                                                            <span className="fz-16 fc-1 fw-bold">
                                                                한도액 / 한도 잔액 / 정산 예정 금액
                                                            </span>
                                                            </div>
                                                        </dt>
                                                        <dd>
                                                            <div className="form-group">
                                                                <div className="input-group expand">
                                                                    <div className="inner-input-group">
                                                                        <input type="text" className="tf-comm" disabled value={`${(currentPomLimitValue).toLocaleString()} 원 / ${data?.data.pomRemain.toLocaleString()} 원 / ${data?.data.pomUseSum.toLocaleString()} 원`}/>
                                                                    </div>
                                                                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                                                                </div>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </dl>
                                        <div className="box-body">
                                            <div className="tbl">
                                                <dl className="vertical">
                                                    <dt>
                                                        <div className="dt-inner">
                                                            <span className="fz-16 fc-1 fw-bold">한도 신청금액</span>
                                                        </div>
                                                    </dt>
                                                    <dd>
                                                        <div className="box-body">
                                                            <div className="form-group">
                                                                <div className="input-group">
                                                                    <div className="inner-input-group">
                                                                        <input type="text" className="tf-comm" disabled value={`${data?.data.pomLimitValue.toLocaleString()} 만원`}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </dd>
                                                    <dt>
                                                        <div className="dt-inner">
                                                            <span className="fz-16 fc-1 fw-bold">
                                                                참고 내용
                                                            </span>
                                                        </div>
                                                    </dt>
                                                    <dd>
                                                        <div className="form-group">
                                                            <div className="input-group expand">
                                                                <div className="inner-input-group">
                                                                    <textarea style={{minHeight: '300px', resize: 'none'}} className="tf-comm" disabled defaultValue={data?.data.text || ''}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </dd>
                                                </dl>
                                            </div>
                                        </div>
                                        {/* 첨부파일 있냐 없냐 처리해야 함 fixme*/}
                                        { data?.data.afs.length > 0 ?
                                            <dl className="vertical">
                                                <dt>
                                                    <div className="box-tit">
                                                        <h2 className="fz-20 fc-1">첨부 파일 총 {data?.data.afs.length} 개</h2>
                                                    </div>
                                                </dt>
                                                <dd>
                                                    <div className="comp-file-list">
                                                        <div className="file-list-bottom">
                                                            {data?.data.afs.map((item:any, key:number) => (
                                                                <div className="file-item" key={key}>
                                                                    <i className="ico ico-file-list"></i>
                                                                    <span className="fz-12 fc-2">{item.afName}</span>
                                                                    <span className="fz-12 fc-3">({bytesToSize(item.fileSize)})</span>
                                                                    <i className="ico ico-download" onClick={() => fileDownHandler(item.fileName, item.afName)}></i>
                                                                </div>
                                                                ))}
                                                        </div>
                                                    </div>
                                                </dd>
                                            </dl>
                                            : null
                                        }
                                    </div>
                                </div>
                            </dd>
                            {/*답변하기*/}
                            <dd>
                                <div className="form-group">
                                    <div className="wrap-section wrap-tbl">
                                        <div className="box-header">
                                            <div className="box-tit">
                                                <h2 className="fz-20 fc-1 fw-bold">답변하기</h2>
                                            </div>
                                        </div>
                                        <div className="box-body">
                                            <div className="tbl">
                                                <dl>
                                                    <dt>
                                                        <div className="dt-inner">
                                                            <span className="fz-16 fc-1 fw-bold">상태 변경</span>
                                                        </div>
                                                    </dt>
                                                    <dd>
                                                        <div className="box-header no-border">
                                                            <div className="box-tit">
                                                                <div className="form-group">
                                                                    <div className="comp-radio">
                                                                        <input type="radio" id="PERMITTED" value='PERMITTED' name="status" defaultChecked={true} disabled={!answerAvailability}/>
                                                                        <label htmlFor="PERMITTED">승인
                                                                        </label>
                                                                    </div>
                                                                    <div className="comp-radio">
                                                                        <input type="radio" id="HOLDING" value='HOLDING' name="status" disabled={!answerAvailability}/>
                                                                        <label htmlFor="HOLDING">보류
                                                                        </label>
                                                                    </div>
                                                                    <div className="comp-radio">
                                                                        <input type="radio" id="REJECTED" value='REJECTED' name="status" disabled={!answerAvailability}/>
                                                                        <label htmlFor="REJECTED">반려
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="box-option">
                                                                <button type="button" className="btn btn-primary" onClick={adjustPomLimitDialogHandler}>한도 조정
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </dd>
                                                </dl>
                                                <dl>
                                                    <dt>
                                                        <div className="dt-inner">
                                                            <span className="fz-16 fc-1 fw-bold">변경 사유</span>
                                                        </div>
                                                    </dt>
                                                    <dd>
                                                        <div className="box-body">
                                                            <div className="form-group">
                                                                <div className="input-group expand">
                                                                    <div className="inner-input-group">
                                                                        <textarea id='comment' className="tf-textarea" defaultValue={data?.data.comment || ''} disabled={!answerAvailability}></textarea>
                                                                    </div>
                                                                    <p className="fz-12 fc-3"><span className="fc-2">{commentCount}</span> / 500</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="box-header no-border">
                                                            <div className="box-option">
                                                                <button type="button" className="btn btn-primary" onClick={replyHandler} disabled={!answerAvailability}>답변 완료
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </dd>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </dd>
                            <dd>
                                <div className="form-group">
                                    <div className="wrap-section wrap-tbl">
                                        <div className="box-header">
                                            <div className="box-tit">
                                                <h2 className="fz-20 fc-1 fw-bold">추가 정보</h2>
                                            </div>
                                        </div>
                                        <div className="box-body">
                                            <div className="tbl">
                                                <dl className="vertical">
                                                    <dt>
                                                        <div className="dt-inner"><span className="fz-16 fc-1 fw-bold">진행 특이사항 (메모)</span>
                                                        </div>
                                                    </dt>
                                                    <dd>
                                                        <div className="form-group">
                                                            <div className="input-group expand">
                                                                <div className="inner-input-group">
                                                                    <textarea id='significant' className="tf-textarea" defaultValue={data?.data.significant || ''}></textarea>
                                                                </div>
                                                                <p className="fz-12 fc-3"><span className="fc-2">{significantCount}</span> / 400</p>
                                                            </div>
                                                        </div>
                                                        <div className="box-header no-border">
                                                            <div className="box-option">
                                                                <button type="button" className="btn btn-primary" onClick={significantHandler}>저장
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </dd>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default PomInquiryDetail;
