import { Fragment, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import alertify from 'alertifyjs'
import _ from 'lodash'

import { categoryCodeTable } from '@biz/api'
import type { CategoryCode, CategoryInspectionConfig } from '@biz/api'
import { useCategoryAllQuery, useCategoryByCodeQuery, useCreateCategoryMutation } from '@biz/query'
import { PageTitle } from '@biz/ui'

/**
 * 카테고리 검수 정보 관리
 */
const CategoryInspMng = () => {
  const { getValues, setValue, register, watch } = useForm<CategoryInspectionConfig & { catCodeStr?: string }>()

  const localCategoryRef = useRef<any>({})
  const prevLocalCategoryRef = useRef<any>({})
  const [categories, setCategories] = useState<typeof categoryCodeTable | []>([])
  const [selectedCategoryCode, setSelectedCategoryCode] = useState<CategoryCode>('COMMON')

  const { data: allCategoriesConfigs } = useCategoryAllQuery()
  const { refetch: refetchCategoryByCode } = useCategoryByCodeQuery(selectedCategoryCode)
  const createCategoryMutation = useCreateCategoryMutation()

  const destroyTagging = () => {
    $('#kwdTag').tagging('destroy')
    $('#kwdTag').off('add:after')
    $('#kwdTag').off('remove:after')
  }

  const createTagging = () => {
    $('#kwdTag').tagging({
      'edit-on-delete': false,
      'no-duplicate-text': '키워드는 중복하여 등록하실 수 없습니다.',
      'no-duplicate-callback': function (message: string) {
        alertify.error(message[0])
      },
      'pre-tags-separator': '|',
      placeholder: '키워드를 입력해 주세요.',
    })

    if (_.isEmpty(localCategoryRef.current[selectedCategoryCode].catProhKwd)) {
      $('#kwdTag').tagging('reset')
    } else {
      $('#kwdTag').tagging('refresh', localCategoryRef.current[selectedCategoryCode].catProhKwd)
    }

    // BUG: 한글 키워드 추가 시 마지막 글자가 사라지지 않는 문제가 있어 키워드 추가 이후 입력 칸을 초기화하는 방식으로 해결합니다.
    $('#kwdTag').on('keyup', function (event) {
      if ([' ', ',', 'Enter'].includes(event.key)) {
        $(this).find('input.type-zone').val('')
      }
    })

    $('#kwdTag').on('add:after', function (el: any, text: any, tagging: any) {
      const { tags } = tagging
      const tagTexts: any[] = []

      if (text.indexOf('|') != -1) {
        $('#kwdTag').tagging('remove', text.replaceAll('|', '\\|'))
        $('#kwdTag').tagging('add', text.replaceAll('|', ''))

        return
      }

      let isCommonKwd = false

      if (selectedCategoryCode == 'COMMON') {
        _.each(localCategoryRef.current, (cateDiv: any) => {
          if (cateDiv.catCode == 'COMMON') return
          const idx = cateDiv.catProhKwd.split('|').indexOf(text)

          if (idx != -1) {
            let kwdArr = cateDiv.catProhKwd.split('|')
            kwdArr.splice(idx, 1)
            localCategoryRef.current[cateDiv.catCode].catProhKwd = kwdArr.join('|')
          }
        })
      } else {
        if (localCategoryRef.current['COMMON'].catProhKwd.split('|').indexOf(text) != -1) {
          alertify.error('공통 카테고리에 이미 등록한 키워드입니다.')
          $('#kwdTag').tagging('remove', text)
          isCommonKwd = true
        }
      }

      if (isCommonKwd) return

      _.each(tags, (tag: any) => {
        tagTexts.push(tag.pure_text)
      })

      setValue('catProhKwd', tagTexts.length == 0 ? '' : tagTexts.join('|'))
    })

    $('#kwdTag').on('remove:after', (el: any, text: any, tagging: any) => {
      const { tags } = tagging
      const tagTexts: any[] = []

      _.each(tags, (tag: any) => {
        tagTexts.push(tag.pure_text)
      })

      setValue('catProhKwd', tagTexts.length == 0 ? '' : tagTexts.join('|'))
    })
  }

  const handleSave = () => {
    const formData = Object.values(localCategoryRef.current) as CategoryInspectionConfig[]

    createCategoryMutation.mutate(formData, {
      onSuccess: (response) => {
        if (response.ok) {
          alertify.success('키워드 저장을 완료하였습니다.', 2, () => {
            window.location.href = '/mngAd/creation'
          })
        }
      },
    })
  }

  const handleCancel = () => {
    let isChanged = false

    Object.entries(localCategoryRef.current).map((entry: any) => {
      if (typeof prevLocalCategoryRef.current[entry[0]] == 'undefined') {
        isChanged = true

        return
      } else if (
        prevLocalCategoryRef.current[entry[0]].catInspDiv != entry[1].catInspDiv ||
        prevLocalCategoryRef.current[entry[0]].catProhKwd != entry[1].catProhKwd
      ) {
        isChanged = true

        return
      }
    })

    if (isChanged) {
      alertify
        .confirm('저장하지 않고 이전 화면으로 이동할까요?', () => {
          window.location.href = '/mngAd/creation'
        })
        .set({ labels: { cancel: '취소', ok: '확인' } })
        .setHeader('변경 사항이 있습니다')
    } else {
      window.location.href = '/mngAd/creation'
    }
  }

  const saveLocalCategory = () => {
    if (getValues('catCode')) {
      localCategoryRef.current[getValues('catCode')] = getValues()
    }
  }

  useEffect(() => {
    // 조회한 모든 카테고리 저장
    if (allCategoriesConfigs) {
      setCategories(allCategoriesConfigs.enumMap)

      _.each(allCategoriesConfigs.catInspConfigs, (cateConfig: any) => {
        localCategoryRef.current[cateConfig.catCode] = cateConfig
        prevLocalCategoryRef.current[cateConfig.catCode] = cateConfig
      })
    }
  }, [allCategoriesConfigs])

  useEffect(() => {
    if (!_.isEmpty($('#kwdTag')[0].innerHTML)) {
      destroyTagging()
    }

    if (localCategoryRef.current[selectedCategoryCode]) {
      setValue('catCode', localCategoryRef.current[selectedCategoryCode].catCode)
      setValue('desc', localCategoryRef.current[selectedCategoryCode].desc)
      setValue('catInspDiv', localCategoryRef.current[selectedCategoryCode].catInspDiv)
      setValue('catProhKwd', localCategoryRef.current[selectedCategoryCode].catProhKwd)

      createTagging()
    } else {
      // 카테고리 코드별 검수 정보 조회
      refetchCategoryByCode().then((categoryConfig) => {
        const { data } = categoryConfig

        if (data) {
          setValue('catCode', data.catCode)
          setValue('desc', data.desc)
          setValue('catInspDiv', data.catInspDiv)
          setValue('catProhKwd', data.catProhKwd)

          localCategoryRef.current[selectedCategoryCode] = data
          prevLocalCategoryRef.current[selectedCategoryCode] = data

          createTagging()
        }
      })
    }
  }, [selectedCategoryCode])

  useEffect(() => {
    saveLocalCategory()
  }, [watch('catInspDiv'), watch('catProhKwd')])

  useEffect(() => {
    return () => {
      destroyTagging()
    }
  }, [])

  return (
    <Fragment>
      <PageTitle>카테고리 관리</PageTitle>
      <section className="wrap-section wrap-tbl">
        <div className="box-body">
          <div className="tbl">
            <dl className="vertical">
              <dd>
                <div className="form-group">
                  <div className="container-fluid">
                    <div className="row flex-container">
                      <div className="col col-4">
                        <div className="wrap-section wrap-tbl">
                          <div className="box-header">
                            <div className="box-tit">
                              <h2 className="fz-20 fc-1 fw-bold">카테고리 목록</h2>
                            </div>
                          </div>
                          <div className="box-body h-500 scroll-y">
                            <table className="tbl group-list">
                              <tbody>
                                {Object.entries(categories).map(([categoryCode, categoryName]) => (
                                  <tr key={categoryCode}>
                                    <td
                                      className={selectedCategoryCode == categoryCode ? 'selected' : ''}
                                      onClick={() => setSelectedCategoryCode(categoryCode as CategoryCode)}
                                    >
                                      <span className="fz-16 fc-3">{categoryName}</span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="col col-8">
                        <div className="wrap-section wrap-tbl">
                          <div className="box-header">
                            <div className="box-tit">
                              <h2 className="fz-20 fc-1 fw-bold">카테고리 설정</h2>
                            </div>
                          </div>
                          <div className="box-body">
                            <div className="tbl">
                              <dl>
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">카테고리명</span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <span className="comp-txt">
                                      <span className="table">
                                        <span className="table-cell">
                                          <b className="fz-14 fc-2">{getValues('desc')}</b>
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                </dd>
                              </dl>
                              <dl style={{ display: selectedCategoryCode == 'COMMON' ? 'none' : 'flex' }}>
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">카테고리 검수 구분</span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <div className="comp-radio">
                                      <input type="radio" id="catInspDiv_kwd" value="kwd" {...register('catInspDiv')} />
                                      <label htmlFor="catInspDiv_kwd">일반</label>
                                    </div>
                                    <div className="comp-radio">
                                      <input
                                        type="radio"
                                        id="catInspDiv_must"
                                        value="must"
                                        {...register('catInspDiv')}
                                      />
                                      <label htmlFor="catInspDiv_must">검수 필요</label>
                                    </div>
                                    <div className="comp-radio">
                                      <input
                                        type="radio"
                                        id="catInspDiv_AI"
                                        value="aiApprove"
                                        {...register('catInspDiv')}
                                      />
                                      <label htmlFor="catInspDiv_AI">AI 자동 검수</label>
                                    </div>
                                  </div>
                                </dd>
                              </dl>
                              <dl>
                                <dt>
                                  <div className="dt-inner">
                                    <span className="fz-16 fc-1">검수 필요 키워드 등록</span>
                                  </div>
                                </dt>
                                <dd>
                                  <div className="form-group">
                                    <div className="tf-comm expand h-274" id="kwdTag"></div>
                                  </div>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div className="box-footer">
          <div className="box-right">
            <button type="button" className="btn btn-secondary-outline" onClick={handleCancel}>
              취소
            </button>
            <button type="button" className="btn btn btn-primary" onClick={handleSave}>
              저장
            </button>
          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default CategoryInspMng
