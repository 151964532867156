import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import { useOutletContext } from 'react-router';
import { useForm } from 'react-hook-form'
import alertify from 'alertifyjs'
import { ApiUtil2, ApiUtil3 } from '@biz/api'
import AtamPopUp from './AtamPopUp'
import { PgParam } from './Charge'

const GeneralCharge = () => {
  const chargeAmountRef = useRef<number>(0)
  const [currUserNo, setCurrUserNo] = useState<any>(null)
  const [isAtamPopUpOpened, setIsAtamPopUpOpened] = useState<boolean>(false)
  const [currProjectId, setCurrProjectId] = useState<any>()
  const setTitle = useOutletContext<any>()
  const { getValues, setValue } = useForm()
  useEffect(() => {
    setTitle('비즈머니 충전')
    /** 충전 사업자 번호 가져오기 */
    const userNoDatas: any[] = []
    ApiUtil2.get('/api/bzm/common/findUserNos').then((resp) => {
      /** 초기 데이터 삽입 */
      if (resp.data.data.usingUserNo === undefined || resp.data.data.changeable) {
        /** 활성 사업자 번호가 없는 경우 */
        resp.data.data.all.forEach((item: any) => {
          userNoDatas.push({
            id: item.userNo,
            text: item.userNo,
            bizDiv: item.bizDiv,
            projectId: item.projectId,
          })
        })
      } else if (resp.data.data.usingUserNo !== undefined && !resp.data.data.changeable) {
        /** 활성 사업자번호가 있고, 충전 사업자번호 변경 가능 조건이 해당하지 않는 경우 */
        userNoDatas.push({
          id: resp.data.data.usingUserNo.userNo,
          text: resp.data.data.usingUserNo.userNo,
          bizDiv: resp.data.data.usingUserNo.bizDiv,
          projectId: resp.data.data.usingUserNo.projectId,
        })
        if (resp.data.data.usingUserNo.userNo !== undefined) {
          setCurrUserNo(resp.data.data.usingUserNo.userNo)
          setCurrProjectId(resp.data.data.usingUserNo.projectId)
          setValue('currProjectId', resp.data.data.usingUserNo.projectId)
          localStorage.setItem('currUserNo', resp.data.data.usingUserNo.userNo)
        }
      } else if (resp.data.data.usingUserNo !== undefined) {
        /** 활성 사업자번호가 있고, 충전 사업자 번호 변경 가능 조건이 가능한 경우 */
        userNoDatas.push({
          id: resp.data.data.usingUserNo.userNo,
          text: resp.data.data.usingUserNo.userNo,
          bizDiv: resp.data.data.usingUserNo.bizDiv,
          projectId: resp.data.data.usingUserNo.projectId,
        })
        resp.data.data.all.forEach((item: any) => {
          if (item.userNo !== resp.data.data.usingUserNo.userNo) {
            userNoDatas.push({
              id: item.userNo,
              text: item.userNo,
              bizDiv: item.bizDiv,
              projectId: item.projectId,
            })
          }
        })
      }
      /** Select2 */
      $('#userNo')
        .select2({
          width: '330px',
          minimumResultsForSearch: Infinity,
          placeholder: '선택하세요.',
          data: userNoDatas,
        })
        .on('select2:select', (e: any) => {
          setCurrUserNo(e.params.data.id)
          localStorage.setItem('currUserNo', e.params.data.id)
          setCurrProjectId(e.params.data.projectId)
          setValue('currProjectId', e.params.data.projectId)
        })

      if (resp.data.data.usingUserNo !== undefined && !resp.data.data.changeable) {
        /** M1720 desc-a2 */
        $('#userNo').select2().val(resp.data.data.usingUserNo.userNo).trigger('change')
        $('#userNo').attr('disabled', 'disabled')
      } else if (resp.data.data.usingUserNo === undefined && resp.data.data.all.length === 0) {
        /** M1720 desc-a1 */
        /** 연결 된 사업자 번호가 없을 때 */
        $('#userNo').select2({ placeholder: '등록된 프로젝트가 없습니다.' })
        $('#userNo').attr('disabled', 'disabled')
      }
    })

    /** tooltip */
    $('#normalChargeTooltip').tooltip({
      content:
        '<p class="fz-12 fc-0">비즈센터에서 사용할 비즈머니를 충전합니다. 충전 가능 최소 금액은 1만원입니다.</p>',
    })
    $('#conditionOfChangeUserNo').tooltip({
      content:
        '<p class="fz-12 fc-0 fw-medium">다음의 조건이 충족되야 변경이 가능합니다.</p>' +
        '<p class="fz-12 fc-0"> 1. 자동충전 비활성화되어야 합니다.</br>' +
        '2. 쿠폰을 포함하여 비즈머니 잔액이 0원이 되야 합니다.</br>' +
        '3. 무통장 입금 대기건이 없어야 합니다.</br>' +
        '4. 진행중이거나 정산이 완료되지 않은 캠페인이 없어야 합니다.</br>' +
        '5. 진행중인 광고대행이 없어야 합니다.</br>' +
        '6. 진행중인 환불 요청이 없어야 합니다.</br>' +
        '</p>',
    })
    $('#autoChargeTooltip').tooltip({
      content:
        '<p class="fz-12 fc-0">자동 충전이란 \'비즈머니 잔액이 사전 설정 금액 미만이 되면 미리 등록한 신용/체크카드로 사전에 설정한 금액만큼 자동으로 충전되는 기능입니다.\n</p>',
    })

    /** 충전 금액 키업 이벤트 */
    $('#chargeAmountInput').on('keyup', () => {
      const value = $('#chargeAmountInput').val() as string
      if (/[^0-9,]/g.test(value)) {
        $('#chargeAmountInput').val(value.substring(0, value.length - 1))
      } else {
        $('#chargeAmountInput').val(value.replace(/,/g, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,'))
      }
      chargeAmountRef.current = Number(value.replaceAll(',', ''))
    })

    /** 충전 금액 포커스 아웃 이벤트 */
    $('#chargeAmountInput').focusout(() => {
      const value = $('#chargeAmountInput').val() as string
      if (Number(value.replaceAll(',', '')) < 10000) {
        $('#chargeAmountInput').val('')
      } else if (/[^0-9,]/g.test(value)) {
        $('#chargeAmountInput').val('')
      }
    })

    return () => {
      localStorage.removeItem('currUserNo')
      localStorage.removeItem('chargeAmount')
      localStorage.removeItem('pgType')
      setTitle('')
    }
  }, [])
  const userNo = { projectId: currProjectId }

  // 카드 결제창 띄우기
  const cardPayment = () =>
    ApiUtil3.post('/api/pg/payment/ready', { ...userNo, cost: chargeAmountRef.current, txDiv: 'bi001' }).then((res) => {
      if (!res.data.data.ok) {
        if (res.data.data.msg !== undefined) {
          alertify.error(res.data.data.msg)
        }
      }
      const pgParam: PgParam = { ...res.data.data }
      loadTossPayments(pgParam.clientKey).then((tossPayments) => {
        tossPayments
          .requestPayment('카드', {
            amount: chargeAmountRef.current,
            orderId: pgParam.orderId,
            orderName: pgParam.orderName,
            successUrl: `${window.location.origin}/${pgParam.successUrl}`,
            failUrl: `${window.location.origin}/${pgParam.failUrl}`,
          })
          .then()
      })
    })

  // 계쫘 결제창 띄우기
  const accountPayment = () =>
    ApiUtil3.post('/api/pg/payment/ready', { ...userNo, cost: chargeAmountRef.current, txDiv: 'bi003' }).then((res) => {
      if (!res.data.data.ok) {
        if (res.data.data.msg !== undefined) {
          alertify.error(res.data.data.msg)
        }
      }
      const pgParam: PgParam = { ...res.data.data }
      loadTossPayments(pgParam.clientKey).then((tossPayments) => {
        tossPayments
          .requestPayment('계좌이체', {
            amount: chargeAmountRef.current,
            orderId: pgParam.orderId,
            orderName: pgParam.orderName,
            successUrl: `${window.location.origin}/${pgParam.successUrl}`,
            failUrl: `${window.location.origin}/${pgParam.failUrl}`,
          })
          .then()
      })
    })

  const atrtHandler = () => {
    if (currUserNo === undefined) {
      alertify.error('충전 사업자 번호를 선택해주세요.')
      return
    }

    if (Number(chargeAmountRef.current) < 10000) {
      alertify.error('최소 충전 금액은 10,000원 이상 입니다.')
      return
    }
    localStorage.setItem('pgType', 'creditOrAtrt')
    alertify
      .alert(
        '',
        '충전하는 계좌가 출금 전용 계좌일 경우, 환불이 어렵습니다. 환불 실패로 인한 개별 입금 처리는 영업일 기준 30일 이상 소요되며, PG사 수수료가 청구됩니다.',
        () => {
          accountPayment()
        }
      )
      .setting({ label: '확인' })
  }

  const cardHandler = () => {
    if (currUserNo === undefined) {
      alertify.error('충전 사업자 번호를 선택해주세요.')
      return
    }
    if (Number(chargeAmountRef.current) < 10000) {
      alertify.error('최소 충전 금액은 10,000원 이상 입니다.')
      return
    }
    localStorage.setItem('pgType', 'creditOrAtrt')
    cardPayment()
  }

  const atamHandler = () => {
    if (currUserNo === undefined) {
      alertify.error('충전 사업자 번호를 선택해주세요.')
      return
    }

    if (Number(chargeAmountRef.current) < 10000) {
      alertify.error('최소 충전 금액은 10,000원 이상 입니다.')
      return
    }
    setIsAtamPopUpOpened(true)
  }

  const chargeAmountHandler1 = () => {
    chargeAmountRef.current += 10000
    $('#chargeAmountInput').val(Number(chargeAmountRef.current).toLocaleString())
  }

  const chargeAmountHandler3 = () => {
    chargeAmountRef.current += 30000
    $('#chargeAmountInput').val(Number(chargeAmountRef.current).toLocaleString())
  }

  const chargeAmountHandler10 = () => {
    chargeAmountRef.current += 100000
    $('#chargeAmountInput').val(Number(chargeAmountRef.current).toLocaleString())
  }

  const chargeAmountHandler30 = () => {
    chargeAmountRef.current += 300000
    $('#chargeAmountInput').val(Number(chargeAmountRef.current).toLocaleString())
  }

  const chargeAmountHandler100 = () => {
    chargeAmountRef.current += 1000000
    $('#chargeAmountInput').val(Number(chargeAmountRef.current).toLocaleString())
  }
  return (
    <Fragment>
      {isAtamPopUpOpened ? (
        <AtamPopUp
          setIsAtamPopUpOpened={setIsAtamPopUpOpened}
          chargeAmount={chargeAmountRef.current}
          currProjectId={currProjectId}
        />
      ) : null}
      <div className="wrap-section wrap-tbl">
        <div className="box-header">
          <div className="box-tit">
            <h2 className="fz-20 fc-1 fw-bold">
              일반 충전
              <button className="ico-tooltip" title="" id="normalChargeTooltip" />
            </h2>
          </div>
        </div>
        <div className="box-body">
          <div className="tbl">
            <dl className="colum-two">
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">충전사업자번호</span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  <select className="select2-single w-300" id="userNo">
                    <option value=""></option>
                  </select>
                </div>
                <div className="form-group">
                  <p className="comp-txt">
                    <span className="table">
                      <span className="table-cell">
                        <b className="fz-12 fc-3 lh20">
                          <i className="fz-12 fc-5">*</i>선택한 사업자번호를 기준으로 비즈머니가 관리됩니다.
                        </b>
                        <b className="fz-12 fc-3 lh20">
                          <i className="fz-12 fc-5">*</i>사업자번호 변경조건
                          <button className="ico-tooltip" title="" id="conditionOfChangeUserNo" />
                        </b>
                      </span>
                    </span>
                  </p>
                </div>
              </dd>
              <dt>
                <div className="dt-inner">
                  <span className="fz-16 fc-1">충전 금액</span>
                </div>
              </dt>
              <dd>
                <div className="form-group">
                  <div className="input-group">
                    <div className="inner-input-group">
                      <input
                        type="text"
                        className="tf-comm tf-unit"
                        id="chargeAmountInput"
                        placeholder="금액을 입력해 주세요."
                      />
                      <span className="fz-14 fc-1">원</span>
                    </div>
                    <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                  </div>
                </div>
                <div className="form-group">
                  <button type="button" className="btn btn-primary-outline small" onClick={chargeAmountHandler1}>
                    +10,000원
                  </button>
                  <button type="button" className="btn btn-primary-outline small" onClick={chargeAmountHandler3}>
                    +30,000원
                  </button>
                  <button type="button" className="btn btn-primary-outline small" onClick={chargeAmountHandler10}>
                    +100,000원
                  </button>
                  <button type="button" className="btn btn-primary-outline small" onClick={chargeAmountHandler30}>
                    +300,000원
                  </button>
                  <button type="button" className="btn btn-primary-outline small" onClick={chargeAmountHandler100}>
                    +1,000,000원
                  </button>
                </div>
              </dd>
            </dl>
            <dl className="vertical">
              <dd>
                <div className="form-group txt-right">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={cardHandler}
                    disabled={getValues('currProjectId') === undefined}
                  >
                    신용카드
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={atrtHandler}
                    disabled={getValues('currProjectId') === undefined}
                  >
                    실시간 계좌이체
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={atamHandler}
                    disabled={getValues('currProjectId') === undefined}
                    style={{ display: 'none' }}
                  >
                    무통장 입금
                  </button>
                </div>
              </dd>
            </dl>
            <dl className="vertical">
              <dd>
                <div className="form-group">
                  <div className="comp-help">
                    <ul className="help-list">
                      <li className="list">
                        <span className="fz-14 fc-4 bullet">
                          일반 충전은 카드 결제 / 계좌이체
                          {/* / 무통장 입금을 */}를 통해 가능합니다.
                        </span>
                      </li>
                      <li className="list">
                        <span className="fz-14 fc-4 bullet">
                          충전한 비즈머니는 와디즈 비즈센터에서만 사용 가능하며, 다른 와디즈 서비스와 호환되지 않습니다.
                        </span>
                      </li>
                      <li className="list">
                        <span className="fz-14 fc-4 bullet">
                          충전한 비즈머니가 모두 소진된 이후 추가 과금이 발생하여 마이너스 비즈머니가 발생할 수 있으며,
                          비용이 청구됩니다.
                        </span>
                      </li>
                      <li className="list">
                        <span className="fz-14 fc-4 bullet">
                          세금계산서는 소진된 유상 광고비에 대해 발행됩니다. (쿠폰금액 등 무상으로 지급된 비즈머니는
                          포함되지 않음.)
                        </span>
                      </li>
                      <li className="list">
                        <span className="fz-14 fc-4 bullet">
                          비즈머니 충전 매출 전표는 부가세 신고 대상이 아니며 이에 대해 와디즈는 관여하지 않습니다.
                        </span>
                      </li>
                      <li className="list">
                        <span className="fz-14 fc-4 bullet">
                          충전 주체와 사용 주체가 다른 경우 와디즈는 불이익에 대하여 책임지지 않습니다.
                        </span>
                      </li>
                      <li className="list">
                        <span className="fz-14 fc-4 bullet">
                          결제에 문제가 있는 경우 하단 문서를 참조하세요
                          <br />
                          <a
                            target="_blank"
                            href="https://pgdownload.uplus.co.kr/banner/errguide/pc_errorguide.html"
                            className="txt-link"
                            rel="noreferrer"
                          >
                            https://pgdownload.uplus.co.kr/banner/errguide/pc_errorguide.html
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default GeneralCharge;
