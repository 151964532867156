import React, {
  Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import {useLocation, useOutletContext} from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import { ApiUtil2 } from '@biz/api'
import { useRoleGroupQuery } from '@biz/query'
import NoDataTemplate from '../common/NoDataTemplate'
import LogTooltip from './LogTooltip'
import alertify from 'alertifyjs'
import DlDialog from '../mngMakerAccount/DlDialog'
import IsmsSelectLogDialog from './IsmsSelectLogDialog'

const campIdCellRenderer = (props: ICellRendererParams) => {
  const { campId } = props.data
  return campId === undefined ? '-' : campId
}

// eslint-disable-next-line consistent-return
const onCellClicked = (params: any) => {
  if (params.event.altKey === true && params.data.logLink !== '') {
    // alt키
    window.open(`${params.data.logLink}`)
    // console.log(params.data.logLink);
  }
}

const IntegrationLog = () => {
  const { data: roleGroup } = useRoleGroupQuery()
  const url = useLocation()
  const [isDlDialogOpened, setIsDlDialogOpened] = useState<boolean>(false)
  const [isIsmsDialogOpened, setIsIsmsDialogOpened] = useState<boolean>(false)
  const [type, setType] = useState<String>('')
  const setTitle = useOutletContext<any>()
  const gridRef = useRef<AgGridReact>(null)
  const [rowData, setRowData] = useState<any[]>()
  let startDate = dayjs().startOf('day').subtract(7, 'd').format('YYYYMMDD')
  let endDate = dayjs().endOf('day').subtract(1, 'd').subtract(59, 'm').format('YYYYMMDD')

  const errorEvent = (msg: string) => {
    alertify.error(msg, 1)
  }

  const confirmError = (msg: string) => {
    errorEvent(msg)
  }

  const onGridReady = useCallback(() => {
    if ($('#keyword').val() === '') {
      confirmError('검색어를 입력해주세요.')
      return
    }
    gridRef.current!.api.sizeColumnsToFit()
    ApiUtil2.get('/api/integration/log/list', {
      params: {
        data: {
          startDate,
          endDate,
          srchType: $('#srchType').val(),
          keyword: $('#keyword').val(),
        },
      },
    }).then((resp) => {
      setRowData(resp.data.data)
    })
  }, [])

  const [columnDefs] = useState<ColDef[]>([
    {
      minWidth: 500,
      field: 'menuDesc',
      tooltipField: 'menuDesc',
      headerName: '구분',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'userLoginNm',
      headerName: '실행자ID',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'makerNm',
      headerName: '메이커ID',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'eventTime',
      headerName: '실행일시',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'campId',
      headerName: '대상 캠페인ID',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: campIdCellRenderer,
    },
  ])

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      resizable: false,
      suppressMovable: true,
      sortable: true,
      tooltipComponent: LogTooltip,
    }),
    []
  )

  useEffect(() => {
    setTitle('통합 로그')
    return () => {
      setTitle('')
    }
  }, [])

  useEffect(() => {
    /** 엔터키 이벤트 */
    $(document).on('keyup', '#keyword', (e: any) => {
      if (e.keyCode === 13) {
        onGridReady()
        return false
      }
      return true
    })

    $('#srchType')
      .select2({
        width: '150',
        minimumResultsForSearch: Infinity,
      })
      .on('select2:select')

    $('#datepicker').daterangepicker(
      {
        opens: 'left',
        ranges: {
          오늘: [dayjs().startOf('day'), dayjs().endOf('day').subtract(59, 'm')],
          '어제 ': [
            dayjs().startOf('day').subtract(1, 'days'),
            dayjs().endOf('day').subtract(59, 'm').subtract(1, 'days'),
          ],
          '이번 주': [dayjs().startOf('day').day(0), dayjs().day(1).endOf('day').subtract(-7, 'd').subtract(59, 'm')],
          '저번 주': [
            dayjs().startOf('day').subtract(1, 'week').day(0),
            dayjs().endOf('day').subtract(1, 'week').day(6).subtract(59, 'm'),
          ],
          '최근 7일': [
            dayjs().startOf('day').subtract(7, 'd'),
            dayjs().endOf('day').subtract(1, 'd').subtract(59, 'm'),
          ],
          '최근 30일': [
            dayjs().startOf('day').subtract(30, 'd'),
            dayjs().endOf('day').subtract(1, 'd').subtract(59, 'm'),
          ],
        },
        maxDate: dayjs(),
        minDate: dayjs().startOf('day').subtract(31, 'd'),
        startDate: dayjs().startOf('day').subtract(7, 'd'),
        endDate: dayjs().endOf('day').subtract(1, 'd').subtract(59, 'm'),
      },
      (start: any, end: any) => {
        // console.log($('#datepicker').data('daterangepicker').chosenLabel);

        $('#datepickerSpan').text($('#datepicker').data('daterangepicker').chosenLabel)
        startDate = start.format('YYYYMMDD')
        endDate = end.format('YYYYMMDD')
        // console.log(`탑 헤더에서 선택한 날짜는 ${startDate} ~ ${endDate}입니다.`);
      }
    )
    return () => {
      // 달력관련 모든걸 remove 해줘야 한다 한페이지에서 동작하는거라 이거 안해주면 달력 관련 소스가 계속 늘어남
      $('#datepicker').remove()
    }
  }, [])

  useEffect(() => {
    const noDataGrid = document.querySelector<HTMLElement>(
      '.ag-root-wrapper-body.ag-focus-managed.ag-layout-auto-height'
    )! as any
    const noTreeDataGrid2 = document.querySelector<HTMLElement>('.ag-root-wrapper.ag-ltr.ag-layout-auto-height')! as any
    const noDataTempleteArea = document.querySelector<HTMLElement>(
      '.ag-overlay-wrapper.ag-layout-auto-height.ag-overlay-no-rows-wrapper'
    )! as any
    if (noDataGrid !== null) {
      noDataGrid.style.height = '548px'
    }
    if (noTreeDataGrid2 !== null) {
      noTreeDataGrid2.style.height = '548px'
    }
    if (noDataTempleteArea !== null) {
      noDataTempleteArea.style.height = '548px'
    }
  }, [])

  return (
    <Fragment>
      {isDlDialogOpened ? (
        <DlDialog
          url={url.pathname}
          type={type}
          setIsDlDialogOpened={setIsDlDialogOpened}
          keyword={$('#keyword').val()}
          srchType={$('#srchType').val()}
          startDate={startDate}
          endDate={endDate}
        />
      ) : null}
      {isIsmsDialogOpened ? <IsmsSelectLogDialog setIsIsmsDialogOpened={setIsIsmsDialogOpened} /> : null}
      <section className="wrap-section wrap-datagrid">
        <div className="wrap-filter">
          <div className="inner-filter">
            <div className="box-left">
              <div className="item-filter">
                <div className="filter-tit">
                  <p className="fz-12 fc-2">검색대상</p>
                </div>
                <div className="box-filter">
                  <select id="srchType" className="select2-single">
                    <option value="userId">메이커ID</option>
                    <option value="userLoginId">실행자ID</option>
                  </select>
                </div>
                <div className="box-filter">
                  <div className="input-group comp-search">
                    <div className="inner-input-group">
                      <input id="keyword" type="text" className="tf-comm" placeholder="검색어를 입력해 주세요."></input>
                      <i className="ico ico-search"></i>
                    </div>
                  </div>
                </div>
                <div className="box-filter">
                  <button type="button" className="btn btn-primary w-100" onClick={onGridReady}>
                    검색
                  </button>
                </div>
              </div>
              <div className="item-filter">
                <div className="filter-tit">
                  <p className="fz-12 fc-2">조회기간</p>
                </div>
                <div className="box-filter">
                  <div className="comp-datepicker">
                    <div className="inner-datepicker">
                      <i className="ico ico-calendar"></i>
                      <input id="datepicker" type="text" className="tf-comm datepicker-range right" readOnly></input>
                      <span className="fz-12 fc-2" id="datepickerSpan">
                        최근 7일
                      </span>
                    </div>
                    <button type="button" className="btn btn-prev"></button>
                    <button type="button" className="btn btn-next"></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-header">
          <div className="box-option">
            <button
              type="button"
              className="btn btn btn-secondary-outline btn-ico"
              onClick={() => {
                setIsDlDialogOpened(true)
                setType('다운로드')
              }}
            >
              <i className="ico ico-download"></i>다운로드
            </button>
            {roleGroup === 'USER_ADMIN' ? (
              <button
                type="button"
                className="btn btn btn-secondary-outline btn-ico"
                onClick={() => {
                  setIsDlDialogOpened(true)
                  setType('접속로그 확인')
                }}
              >
                <i className="ico ico-download"></i>접속로그 확인
              </button>
            ) : null}
            {roleGroup === 'USER_ADMIN' ? (
              <button
                type="button"
                className="btn btn btn-secondary-outline btn-ico"
                onClick={() => {
                  setIsIsmsDialogOpened(true)
                  setType('전체 접속로그 확인')
                }}
              >
                <i className="ico ico-download"></i>전체 접속로그 확인
              </button>
            ) : null}
          </div>
        </div>
        <div className="box-body">
          <div className="ag-grid">
            <div className="ag-grid-inner">
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                tooltipShowDelay={0}
                tooltipHideDelay={5000}
                rowHeight={48}
                domLayout={'autoHeight'}
                onCellClicked={onCellClicked}
                noRowsOverlayComponent={NoDataTemplate}
              ></AgGridReact>
              {rowData === undefined ? <NoDataTemplate /> : null}
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default IntegrationLog;
