import { Breadcrumb as BreadcrumbAntd } from 'antd'
import { ReactComponent as ChevronRight } from '@common/svg/chevronRight.svg'

type BreadcrumbProps = {
  className?: string
  path: string[]
}
export const Breadcrumb: React.FC<BreadcrumbProps> = ({ className, path }) => (
  <BreadcrumbAntd
    className={className}
    items={path.map((p) => ({ title: p }))}
    separator={<ChevronRight style={{ fill: '#868E96', width: '16px', height: '22px' }} />}
  />
)
