import React, {
  Fragment, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import qs from 'qs';
import { Link } from 'react-router-dom';
import { useOutletContext, useParams } from 'react-router';
import Pagination from '../common/Pagination';
import NoDataTemplate from '../common/NoDataTemplate';
import { ApiUtil2 } from '@biz/api'
import alertify from 'alertifyjs'
import MakerSelectDialog from './MakerSelectDialog';
import DlDialog from "../mngMakerAccount/DlDialog";

interface IBzmoneyUsageSummaryForAdmin {
    basicDate:any,
    makerLoginId: any,
    userNo: any,
    paidCharging:any,
    couponCharging:any,
    paidExhaustion:any,
    couponExhaustion:any,
    paidReturn:any,
    couponReturn:any,
    paidRedemption:any,
    couponRedemption:any,
    refund:any,
    adjustPaid:any,
    adjustCoupon:any,
    depositDeducted:any,
    bt002:any,
    depositReturn:any,
    /** 20230203 부채 추가 */
    dept:any,
    redemptionOfDept:any,
    freeRedemptionOfDept:any,
}

export interface IBzmoneyUsageSummaryForAdminList {
    ok: boolean;
    data: IBzmoneyUsageSummaryForAdmin[];
}

export interface BzmoneyUsageSummaryOrDetailsSrchFormForAdmin {
    makerIds:any[],
    userNo:any,
    startDate:any,
    endDate:any,
  srchUnit:any,
}
const dateFormatter = (props:any) => {
  const year = props.data.basicDate.substring(0, 4);
  const month = props.data.basicDate.substring(4, 6);
  const day = props.data.basicDate.substring(6, 8);
  if (props.data.basicDate.length < 7) { /** 월간 조회 기준 */
    return `${year}-${month}`;
  }
  if (props.data.convertedDate !== '') {
    return props.data.convertedDate;
  }
  return `${year}-${month}-${day}`;
};
const dateFormatterForDetails = (props: ICellRendererParams) => {
  const { date } = props.value;
  const { time } = props.value;
  return dayjs(new Date(date.year, date.month - 1, date.day, time.hour, time.minute)).format('YYYY-MM-DD HH:mm');
};

const paidChargingFormatter = (props:any) => (props.data.paidCharging === 0 ? '-' : props.data.paidCharging.toLocaleString());
const couponChargingFormatter = (props:any) => (props.data.couponCharging === 0 ? '-' : props.data.couponCharging.toLocaleString());
const paidExhaustionFormatter = (props:any) => (props.data.paidExhaustion === 0 ? '-' : props.data.paidExhaustion.toLocaleString());
const couponExhaustionFormatter = (props:any) => (props.data.couponExhaustion === 0 ? '-' : props.data.couponExhaustion.toLocaleString());
const paidReturnFormatter = (props:any) => (props.data.paidReturn === 0 ? '-' : props.data.paidReturn.toLocaleString());
const couponReturnFormatter = (props:any) => (props.data.couponReturn === 0 ? '-' : props.data.couponReturn.toLocaleString());
const paidRedemptionFormatter = (props:any) => (props.data.paidRedemption === 0 ? '-' : props.data.paidRedemption.toLocaleString());
const couponRedemptionFormatter = (props:any) => (props.data.couponRedemption === 0 ? '-' : props.data.couponRedemption.toLocaleString());
const refundFormatter = (props:any) => (props.data.refund === 0 ? '-' : props.data.refund.toLocaleString());
const adjustPaidFormatter = (props:any) => (props.data.adjustPaid === 0 ? '-' : props.data.adjustPaid.toLocaleString());
const adjustCouponFormatter = (props:any) => (props.data.adjustCoupon === 0 ? '-' : props.data.adjustCoupon.toLocaleString());
const depositDeductedFormatter = (props:any) => (props.data.depositDeducted === 0 ? '-' : props.data.depositDeducted.toLocaleString());
const bt002Formatter = (props:any) => (props.data.bt002 === 0 ? '-' : props.data.bt002.toLocaleString());
const depositReturnFormatter = (props:any) => (props.data.depositReturn === 0 ? '-' : props.data.depositReturn.toLocaleString());
const paidRenderer = (props:any) => (props.data.paid === 0 ? '-' : Math.abs(props.data.paid).toLocaleString());
const freeRenderer = (props:any) => (props.data.free === 0 ? '-' : Math.abs(props.data.free).toLocaleString());
const depBalanceRenderer = (props:any) => (props.data.depBalance === 0 ? '-' : props.data.depBalance.toLocaleString());
const bzmBalanceRenderer = (props:any) => (props.data.bzmBalance === 0 ? '-' : props.data.bzmBalance.toLocaleString());
const deptReturnFormatter = (props:any) => (props.data.dept === 0 || props.data.dept === undefined ? '-' : Math.abs(props.data.dept).toLocaleString());
const redemptionOfDeptReturnFormatter = (props:any) => (props.data.redemptionOfDept === 0 || props.data.redemptionOfDept === undefined ? '-' : props.data.redemptionOfDept.toLocaleString());
const freeRedemptionOfDeptReturnFormatter = (props:any) => (props.data.freeRedemptionOfDept === 0 || props.data.freeRedemptionOfDept === undefined ? '-' : props.data.freeRedemptionOfDept.toLocaleString());

const BzmoneyUsageSummaryAndDetailsForAdmin = () => {
  // 비즈머니 관리에서 넘어오는 파라미터값
  const { projectId } = useParams();
  const { makerId } = useParams();
  const setTitle = useOutletContext<any>();
  const [isMakerSelectDialogOpened, setIsMakerSelectDialogOpened] = useState<boolean>(false);
  const [tabStatus, setTabStatus] = useState<any>('summary');
  const [isDateValid, setIsDateValid] = useState<boolean>(true);
  const childRefOfBzmoneyUsageSummary = useRef<any>(null);
  const childRefOfBzmoneyUsageDetails = useRef<any>(null);
  const gridRefOfBzmoneyUsageSummary = useRef<AgGridReact>(null);
  const gridRefOfBzmoneyUsageDetails = useRef<AgGridReact>(null);
  const [rowDataOfBzmoneyUsageSummaryData, setRowDataOfBzmoneyUsageSummaryData] = useState<any[]>();
  const [rowDataOfBzmoneyUsageDetailsData, setRowDataOfBzmoneyUsageDetailsData] = useState<any[]>();
  let isSelectedAll = true;
  let dataTxDivs:any[] = ['paidCharging', 'freeCharging', 'exhaustion', 'return', 'depositDeducted', 'bt002', 'depositReturn', 'paidRedemption', 'couponRedemption', 'adjust', 'redemptionOfDept'];
  const [dataTxDivsForDownload, setDataTxDivsForDownLoad] = useState<any[]>(dataTxDivs);
  const [txDivMsg, setTxDivMsg] = useState<any>('');
  const [isDlDialogOpened, setIsDlDialogOpened] = useState<boolean>(false);
  const [dlParam, setDlParam] = useState<any>(null);

  const [columnDefsOfBzmoneyUsageSummary] = useState<ColDef[]>([
    {
      field: 'basicDate', headerName: '날짜', cellRenderer: dateFormatter,
    },
    {
      field: 'makerLoginId',
      headerName: '메이커 ID',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
    },
    {
      field: 'userNo',
      headerName: '충전 사업자번호',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
    },
    {
      field: 'paidCharging',
      headerName: '유상 충전',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: paidChargingFormatter,
    },
    {
      field: 'couponCharging',
      headerName: '쿠폰 충전',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: couponChargingFormatter,
    },
    {
      field: 'paidExhaustion',
      headerName: '유상 소진',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: paidExhaustionFormatter,
    },
    {
      field: 'couponExhaustion',
      headerName: '쿠폰 소진',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: couponExhaustionFormatter,
    },
    {
      field: 'paidReturn',
      headerName: '유상 환급',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: paidReturnFormatter,
    },
    {
      field: 'couponReturn',
      headerName: '쿠폰 환급',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: couponReturnFormatter,
    },
    {
      field: 'paidRedemption',
      headerName: '유상 환수',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: paidRedemptionFormatter,
    },
    {
      field: 'couponRedemption',
      headerName: '쿠폰 환수',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: couponRedemptionFormatter,
    },
    {
      field: 'refund',
      headerName: '환불',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: refundFormatter,
    },
    {
      field: 'adjustPaid',
      headerName: '임의조정 유상',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: adjustPaidFormatter,
    },
    {
      field: 'adjustCoupon',
      headerName: '임의조정 쿠폰',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: adjustCouponFormatter,
    },
    {
      field: 'depositDeducted',
      headerName: '예치금 차감',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: depositDeductedFormatter,
    },
    {
      field: 'bt002',
      headerName: '예치금 반환',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: bt002Formatter,
    },
    {
      field: 'depositReturn',
      headerName: '예치금 환급',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: depositReturnFormatter,
    },
    {
      field: 'dept',
      headerName: '부채',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: deptReturnFormatter,
    },
    {
      field: 'redemptionOfDept',
      headerName: '부채 유상상환',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: redemptionOfDeptReturnFormatter,
    },
    {
      field: 'freeRedemptionOfDept',
      headerName: '부채 쿠폰상환',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: freeRedemptionOfDeptReturnFormatter,
    },

  ]);
  const [columnDefsOfBzmoneyUsageDetails] = useState<ColDef[]>([
    { field: 'regTime', headerName: '날짜', cellRenderer: dateFormatterForDetails },
    {
      field: 'loginId',
      headerName: '메이커 ID',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
    },
    {
      field: 'memberName',
      headerName: '메이커명',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
    },
    {
      field: 'inUserNo',
      headerName: '충전 사업자번호',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
    },
    {
      field: 'txUserNo',
      headerName: '소진 사업자 번호',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
    },
    {
      field: 'txDivDesc',
      headerName: '구분',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
    },
    {
      field: 'paid',
      headerName: '유상 비즈머니',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: paidRenderer,
    },
    {
      field: 'free',
      headerName: '쿠폰',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: freeRenderer,
    },
    {
      field: 'dept',
      headerName: '부채',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: deptReturnFormatter,
    },
    {
      field: 'depBalance',
      headerName: '예치금 잔액',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: depBalanceRenderer,
    },
    {
      field: 'bzmBalance',
      headerName: '비즈머니 잔액',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: bzmBalanceRenderer,
    },
    {
      field: 'comment',
      headerName: '비고',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
    },
    {
      field: 'regName',
      headerName: '진행자',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
    },
  ]);

  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: true,
    suppressMovable: true,
    sortable: true,
  }), []);
  const {
    handleSubmit, setValue, getValues, register,
  } = useForm<BzmoneyUsageSummaryOrDetailsSrchFormForAdmin>();

  const onGridReadyOfBzmoneyUsageSummary = useCallback(() => {
    const {
      userNo, startDate, endDate, srchUnit, makerIds,
    } = getValues();
    // if (makerIds === undefined || makerIds.length === 0) {
    //   alertify.error('메이커를 선택해주세요.');
    //   return;
    // }

    ApiUtil2.get<IBzmoneyUsageSummaryForAdminList>('/api/bzmAdmin/detail/summary', {
      params: {
        makerIds, userNo, startDate, endDate, srchUnit,
      },
      paramsSerializer: (params) => qs.stringify(params),
    }).then((resp) => {
      setRowDataOfBzmoneyUsageSummaryData(resp.data.data);
    });
  }, []);

  const onGridReadyOfBzmoneyUsageDetails = useCallback(() => {
    const {
      userNo, startDate, endDate, makerIds,
    } = getValues();
    if (!dataTxDivs.length) {
      alertify.error('구분 탭에서 상세사항을 선택해주세요.');
      return;
    }
    ApiUtil2.get<IBzmoneyUsageSummaryForAdminList>('/api/bzmAdmin/detail/details', {
      params: {
        makerIds, userNo, startDate, endDate, txDivs: dataTxDivs,
      },
      paramsSerializer: (params) => qs.stringify(params),
    }).then((resp) => {
      setRowDataOfBzmoneyUsageDetailsData(resp.data.data);
    });
  }, []);

  const downloadHandler = () => {
    if (tabStatus == 'summary') {
      setDlParam({
        makerIds : getValues('makerIds') !== undefined ? getValues('makerIds') : '',
        userNo : getValues('userNo'),
        startDate : getValues('startDate'),
        endDate : getValues('endDate'),
        srchUnit : getValues('srchUnit'),
      });
    } else {
      setDlParam({
        makerIds : getValues('makerIds') !== undefined ? getValues('makerIds') : '',
        userNo : getValues('userNo'),
        startDate : getValues('startDate'),
        endDate : getValues('endDate'),
        txDivs : dataTxDivsForDownload,
      });
    }

    setIsDlDialogOpened(true);
    // window.location.assign(tabStatus === 'summary'
    //   ? `/api/bzmAdmin/detail/summary/download?makerIds=${getValues('makerIds') !== undefined ? getValues('makerIds') : ''}&userNo=${getValues('userNo')}&startDate=${getValues('startDate')}&endDate=${getValues('endDate')}&srchUnit=${getValues('srchUnit')}`
    //   : `/api/bzmAdmin/detail/details/download?makerIds=${getValues('makerIds') !== undefined ? getValues('makerIds') : ''}&userNo=${getValues('userNo')}&startDate=${getValues('startDate')}&endDate=${getValues('endDate')}&txDivs=${dataTxDivsForDownload}`);
  };

  useEffect(() => {
    setTitle('비즈머니 내역');
    /** 초기 값 */
    setValue('startDate', dayjs().startOf('day').subtract(30, 'd').format('YYYYMMDD'));
    setValue('endDate', dayjs().startOf('day').format('YYYYMMDD'));
    setValue('srchUnit', 'daily');
    /** Select2 */
    $('#srchUnit').select2({
      width: '150',
      minimumResultsForSearch: Infinity,
      placeholder: '선택하세요.',
    }).on('select2:select', (e:any) => {
      setValue('srchUnit', e.params.data.id);
    });

    const getDateDiff = (d1:any, d2:any) => {
      const date1 = new Date(d1);
      const date2 = new Date(d2);

      const diffDate = date1.getTime() - date2.getTime();

      return Math.abs(diffDate / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일
    };

    /** datepicker */
    $('#datepicker').daterangepicker({
      opens: 'left',
      startDate: dayjs().startOf('day').subtract(30, 'd'),
      endDate: dayjs().startOf('day'),
      ranges: {
        '오늘 ': [dayjs().startOf('day'), dayjs().endOf('day')],
        '어제 ': [dayjs().startOf('day').subtract(1, 'days'), dayjs().endOf('day').subtract(1, 'days')],
        '이번 주': [dayjs().startOf('day').day(1), dayjs().day(1).endOf('day').subtract(-6, 'd')],
        '저번 주': [dayjs().startOf('day').subtract(1, 'week').day(1), dayjs().endOf('day').subtract(1, 'week').day(7)],
        '최근 7일': [dayjs().startOf('day').subtract(7, 'd'), dayjs().endOf('day').subtract(1, 'd')],
        '최근 30일': [dayjs().startOf('day').subtract(30, 'd'), dayjs().endOf('day').subtract(1, 'd')],
      },
    }, (start:any, end:any) => {
      const startDate = start.format('YYYYMMDD');
      const endDate = end.format('YYYYMMDD');
      if (getDateDiff(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')) > 92) {
        alertify.error('조회 기간은 최대 93일까지 설정할 수 있습니다.');
        setIsDateValid(false);
        return;
      }
      setIsDateValid(true);
      setValue('startDate', startDate);
      setValue('endDate', endDate);
    });

    $('#datepicker').val(`${dayjs().startOf('day').subtract(30, 'd').format('YY-MM-DD')} ~ ${dayjs().startOf('day').format('YY-MM-DD')}`);

    $(document).on('click', '#makerIdsInput', () => {
      setIsMakerSelectDialogOpened(true);
    });

    return () => {
      setIsMakerSelectDialogOpened(false);
      setTitle('');
    };
  }, []);

  useEffect(() => {
    setTxDivMsg(`${$(`#${dataTxDivs[0]}`).data('nm')} 외 ${dataTxDivs.length - 1} 개`);
    dataTxDivs.forEach((e) => {
      $('#allOfTxDivs').prop('checked', true);
      $(`#${e}`).prop('checked', true);
    });

    $('#dropdown-menu').on('click', (event) => {
      // 클릭시 드랍다운 창이 닫히기 때문에 해당 부분 처리
      event.stopPropagation();
    });

    $('#allOfTxDivs').on('click', () => {
      if (isSelectedAll) {
        isSelectedAll = false;
      } else {
        isSelectedAll = true;
      }
      if (isSelectedAll === true) {
        dataTxDivs = ['paidCharging', 'freeCharging', 'exhaustion', 'return', 'depositDeducted', 'bt002', 'depositReturn', 'paidRedemption', 'couponRedemption', 'adjust', 'redemptionOfDept'];
        setDataTxDivsForDownLoad(dataTxDivs);
        dataTxDivs.forEach((e) => {
          $(`#${e}`).prop('checked', true);
        });
      } else {
        $('#allOfTxDivs').prop('checked', false);
        dataTxDivs.forEach((e) => {
          $(`#${e}`).prop('checked', false);
        });
        dataTxDivs = [];
        setTxDivMsg('선택해 주세요.');
        setDataTxDivsForDownLoad(dataTxDivs);
      }
    });
    $('.txDivsChkBox').on('click', (e:any) => {
      if ($(`#${e.target.id}`).is(':checked')) {
        if (e.target.id !== 'allOfTxDivs') {
          dataTxDivs.push(e.target.id);
          setDataTxDivsForDownLoad(dataTxDivs);
        }
      } else if (!$(`#${e.target.id}`).is(':checked')) {
        $('#allOfTxDivs').prop('checked', false);
        dataTxDivs = dataTxDivs.filter((elem:any) => elem !== e.target.id);
        setDataTxDivsForDownLoad(dataTxDivs);
      }
      if (!dataTxDivs.length) {
        setTimeout(() => {
          dataTxDivs = ['paidCharging', 'freeCharging', 'exhaustion', 'return', 'depositDeducted', 'bt002', 'depositReturn', 'paidRedemption', 'couponRedemption', 'adjust', 'redemptionOfDept'];
          setTxDivMsg(`${$(`#${dataTxDivs[0]}`).data('nm')} 외 ${dataTxDivs.length - 1} 개`);
          setDataTxDivsForDownLoad(dataTxDivs);
          $('#allOfTxDivs').prop('checked', true);
          dataTxDivs.forEach((v) => {
            $(`#${v}`).prop('checked', true);
          });
          isSelectedAll = true;
        }, 100);
      }

      if (dataTxDivs.length === 1) {
        setTxDivMsg(`${$(`#${dataTxDivs[0]}`).data('nm')}`);
      } else if (dataTxDivs.length > 1) {
        setTxDivMsg(`${$(`#${dataTxDivs[0]}`).data('nm')} 외 ${dataTxDivs.length - 1} 개`);
      }
    });
    if (tabStatus === 'summary') {
      $('#summaryBtn').prop('class', 'tab selected');
      $('#detailsBtn').prop('class', 'tab');
      $('#srchUnitFilter').show();
      $('#txDivsFilter').hide();
    } else {
      $('#detailsBtn').prop('class', 'tab selected');
      $('#summaryBtn').prop('class', 'tab');
      $('#srchUnitFilter').hide();
      $('#txDivsFilter').show();
    }
  }, [tabStatus]);

  // 그리드에서 페이징 변경시 pagination 에 있는 함수 호출
  const changePaginationOfBzmoneyUsageSummary = () => {
        childRefOfBzmoneyUsageSummary.current!.onPaginationChanged();
  };
  const changePaginationOfBzmoneyUsageDetails = () => {
    childRefOfBzmoneyUsageDetails.current!.onPaginationChanged();
  };

  const summaryHandler = () => {
    setTabStatus('summary');
  };
  const detailsHandler = () => {
    setTabStatus('details');
  };
  // 비즈머니 관리 관련
  const bzmMngMakerInit = () => {
    if (projectId !== undefined && makerId !== undefined) {
      detailsHandler();
      ApiUtil2.get('/api/bzmAdmin/detail/details/getBzmDetailByProjectIdAndMakerId', { params: { data: { projectId, makerId } } }).then((resp) => {
        console.log('resp >> ', resp);
        setValue('userNo', resp.data.data.userNo);
        $('#makerIdsInput').val(`${resp.data.data.makerLoginId} 외 0 의 계정`);
        setValue('makerIds', [makerId]);
        setValue('userNo', resp.data.data.userNo);
        setRowDataOfBzmoneyUsageDetailsData(resp.data.data.bzmoneyUsageDetails);
      });
    }
  };

  const AddPagination = useCallback(() => {
    if (tabStatus === 'summary') {
      return <Fragment><Pagination gridRef={ gridRefOfBzmoneyUsageSummary } ref={childRefOfBzmoneyUsageSummary}/></Fragment>;
    }
    return <Fragment><Pagination gridRef={gridRefOfBzmoneyUsageDetails} ref={childRefOfBzmoneyUsageDetails}/></Fragment>;
  }, [tabStatus]);

  useEffect(() => {
    bzmMngMakerInit();
  }, []);
  return (
    <Fragment>
      {isMakerSelectDialogOpened ? (
        <MakerSelectDialog setIsMakerSelectDialogOpened={setIsMakerSelectDialogOpened} setValue={setValue} />
      ) : null}
      <div className="comp-tab">
        <button className="tab selected" id="summaryBtn" onClick={summaryHandler}>
          <span className="tab-item">요약 내역</span>
        </button>
        <button className="tab" id="detailsBtn" onClick={detailsHandler}>
          <span className="tab-item">상세 내역</span>
        </button>
      </div>
      <div className="comp-tab-content selected">
        <section className="wrap-section wrap-datagrid">
          <form
            onSubmit={handleSubmit(
              tabStatus === 'summary' ? onGridReadyOfBzmoneyUsageSummary : onGridReadyOfBzmoneyUsageDetails
            )}
          >
            <div className="wrap-filter">
              <div className="inner-filter">
                <div className="box-left">
                  <div className="item-filter">
                    <div className="filter-tit">
                      <p className="fz-12 fc-2">조회기간</p>
                    </div>
                    <div className="box-filter">
                      <div className="comp-datepicker">
                        <div className="inner-datepicker">
                          <i className="ico ico-calendar"></i>
                          <input type="text" className="tf-comm datepicker-range" id="datepicker" readOnly />
                          <span className="fz-12 fc-2">사용자 설정</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item-filter">
                    <div className="filter-tit">
                      <p className="fz-12 fc-2">{tabStatus === 'summary' ? '조회기간' : '구분'}</p>
                    </div>
                    <div className="box-filter" id="srchUnitFilter">
                      <select className="select2-single w-300" id="srchUnit">
                        <option value="daily">일간</option>
                        <option value="weekly">주간</option>
                        <option value="monthly">월간</option>
                      </select>
                    </div>
                    <div className="box-filter" id="txDivsFilter" style={{ display: 'none' }}>
                      <div className="comp-dropdown w-200 select2 txDivs">
                        <Link to="#" className="dropdown-toggle" data-toggle="dropdown">
                          <div className="box-left">
                            <span className="fz-14">{txDivMsg}</span>
                          </div>
                          <div className="box-right">
                            <i className="ico ico-arrow"></i>
                          </div>
                        </Link>
                        <div className="dropdown-menu expand" id="dropdown-menu">
                          <ul className="opt-selectbox">
                            <li className="opt-menu">
                              <div className="comp-checkbox small">
                                <input type="checkbox" id="allOfTxDivs" className="txDivsChkBox" />
                                <label htmlFor="allOfTxDivs">전체</label>
                              </div>
                            </li>
                            <li className="opt-menu">
                              <div className="comp-checkbox small">
                                <input type="checkbox" id="paidCharging" data-nm="유상 충전" className="txDivsChkBox" />
                                <label htmlFor="paidCharging">유상 충전</label>
                              </div>
                            </li>
                            <li className="opt-menu">
                              <div className="comp-checkbox small">
                                <input type="checkbox" id="freeCharging" data-nm="무상 충전" className="txDivsChkBox" />
                                <label htmlFor="freeCharging">무상 충전</label>
                              </div>
                            </li>
                            <li className="opt-menu">
                              <div className="comp-checkbox small">
                                <input type="checkbox" id="exhaustion" data-nm="소진" className="txDivsChkBox" />
                                <label htmlFor="exhaustion">소진</label>
                              </div>
                            </li>
                            <li className="opt-menu">
                              <div className="comp-checkbox small">
                                <input type="checkbox" id="return" data-nm="환급" className="txDivsChkBox" />
                                <label htmlFor="return">환급</label>
                              </div>
                            </li>
                            <li className="opt-menu">
                              <div className="comp-checkbox small">
                                <input
                                  type="checkbox"
                                  id="depositDeducted"
                                  data-nm="예치금 차감"
                                  className="txDivsChkBox"
                                />
                                <label htmlFor="depositDeducted">예치금 차감</label>
                              </div>
                            </li>
                            <li className="opt-menu">
                              <div className="comp-checkbox small">
                                <input type="checkbox" id="bt002" data-nm="예치금 반환" className="txDivsChkBox" />
                                <label htmlFor="bt002">예치금 반환</label>
                              </div>
                            </li>
                            <li className="opt-menu">
                              <div className="comp-checkbox small">
                                <input
                                  type="checkbox"
                                  id="depositReturn"
                                  data-nm="예치금 환급"
                                  className="txDivsChkBox"
                                />
                                <label htmlFor="depositReturn">예치금 환급</label>
                              </div>
                            </li>
                            <li className="opt-menu">
                              <div className="comp-checkbox small">
                                <input
                                  type="checkbox"
                                  id="paidRedemption"
                                  data-nm="유상 환수"
                                  className="txDivsChkBox"
                                />
                                <label htmlFor="paidRedemption">유상 환수</label>
                              </div>
                            </li>
                            <li className="opt-menu">
                              <div className="comp-checkbox small">
                                <input
                                  type="checkbox"
                                  id="couponRedemption"
                                  data-nm="무상 환수"
                                  className="txDivsChkBox"
                                />
                                <label htmlFor="couponRedemption">무상 환수</label>
                              </div>
                            </li>
                            <li className="opt-menu">
                              <div className="comp-checkbox small">
                                <input type="checkbox" id="adjust" data-nm="임의조정" className="txDivsChkBox" />
                                <label htmlFor="adjust">임의조정</label>
                              </div>
                            </li>
                            <li className="opt-menu">
                              <div className="comp-checkbox small">
                                <input
                                  type="checkbox"
                                  id="redemptionOfDept"
                                  data-nm="부채 상환"
                                  className="txDivsChkBox"
                                />
                                <label htmlFor="redemptionOfDept">부채 상환</label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item-filter">
                    <div className="filter-tit">
                      <p className="fz-12 fc-2">충전 사업자 번호</p>
                    </div>
                    <div className="box-filter">
                      <input
                        className="tf-comm tf-unit"
                        id="userNo"
                        {...register('userNo')}
                        placeholder="충전 사업자 번호를 입력하세요."
                      ></input>
                    </div>
                  </div>
                  <div className="item-filter">
                    <div className="filter-tit">
                      <p className="fz-12 fc-2">메이커 ID</p>
                    </div>
                    <div className="box-filter">
                      <div className="input-group">
                        <input
                          className="tf-comm tf-unit"
                          id="makerIdsInput"
                          placeholder="메이커 Id를 선택하세요."
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box-right">
                  <button type="submit" className="btn btn-tertiary-mint btn-ico" disabled={!isDateValid}>
                    <i className="ico ico-filter"></i> 조회{' '}
                  </button>
                </div>
              </div>
            </div>
          </form>
          {rowDataOfBzmoneyUsageSummaryData !== undefined || rowDataOfBzmoneyUsageDetailsData !== undefined ? (
            <Fragment>
              <div className="box-header">
                <div className="box-option">
                  <button type="button" className="btn btn btn-secondary-outline btn-ico" onClick={downloadHandler}>
                    <i className="ico ico-download"></i>다운로드
                  </button>
                </div>
              </div>
              <div className="box-body">
                <div className="ag-grid">
                  <div className="ag-grid-inner">
                    <AgGridReact
                      ref={tabStatus === 'summary' ? gridRefOfBzmoneyUsageSummary : gridRefOfBzmoneyUsageDetails}
                      rowData={
                        tabStatus === 'summary' ? rowDataOfBzmoneyUsageSummaryData : rowDataOfBzmoneyUsageDetailsData
                      }
                      columnDefs={
                        tabStatus === 'summary' ? columnDefsOfBzmoneyUsageSummary : columnDefsOfBzmoneyUsageDetails
                      }
                      defaultColDef={defaultColDef}
                      // onGridReady={tabStatus === 'summary' ? onGridReadyOfBzmoneyUsageSummary : onGridReadyOfBzmoneyUsageDetails}
                      rowHeight={48}
                      pagination={true}
                      paginationPageSize={10}
                      suppressPaginationPanel={true}
                      onPaginationChanged={
                        tabStatus === 'summary'
                          ? changePaginationOfBzmoneyUsageSummary
                          : changePaginationOfBzmoneyUsageDetails
                      }
                      domLayout={'autoHeight'}
                      alwaysShowHorizontalScroll={true}
                      noRowsOverlayComponent={NoDataTemplate}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
              <div className="box-footer">
                <AddPagination />
              </div>
            </Fragment>
          ) : (
            <NoDataTemplate />
          )}
        </section>
      </div>
      {isDlDialogOpened ? (
        <DlDialog
          setIsDlDialogOpened={setIsDlDialogOpened}
          dlUrl={
            tabStatus === 'summary' ? '/api/bzmAdmin/detail/summary/download' : '/api/bzmAdmin/detail/details/download'
          }
          dlParam={dlParam}
        />
      ) : null}
    </Fragment>
  )
};

export default BzmoneyUsageSummaryAndDetailsForAdmin;
