import React, {
  Fragment, useRef, useState,
} from 'react';
import { Link } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { useForm } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import { Dialog } from 'rc-easyui';
import { ApiUtil2 } from '@biz/api'
import {
  defaultColDef, IComponents,
} from '../../accAdjust/AccAdjustTargetUtil';
import { campGridBzm, consultingGridBzm, IBzmTxSearchForm } from '../BzmAdminUtil';
import SingleSelect2 from '../../accAdjust/SingleSelect2';
import RangeDatePicker from '../../accAdjust/RangeDatePicker';
import NoDataTemplate from '../../common/NoDataTemplate';
import Pagination from '../../common/Pagination';
import alertify from 'alertifyjs'

const resizableColDef = { ...defaultColDef, ...{ resizable: true } };

const BzmMakerSearchUserNoDialog = (props: any) => {
  // grid 요소
  const childRef = useRef<any>(null);
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>([]);
  const [columnDefs, setColumnDefs] = useState<IComponents>(campGridBzm);
  // dialog 요소
  const dialogRef = useRef<any>();
  const [selectedTab, setSelectedTab] = useState<string>('camp');

  // form
  const {
    setValue, getValues, register,
  } = useForm<IBzmTxSearchForm>();

  const moveToCenter = () => {
    dialogRef.current.center();
  };

  const loadData = () => {
    setValue('makerId', props.makerId);
    ApiUtil2.get(columnDefs.searchUrl, {
      params: {
        data: columnDefs.callback(getValues()),
      },
    }).then((resp) => {
      setRowData(resp.data.data);
    });
  };
  const changeTab = (tabName: string) => {
    if (tabName === 'camp') {
      setColumnDefs({ ...campGridBzm });
    } else if (tabName === 'consulting') {
      setColumnDefs({ ...consultingGridBzm });
    }

    setRowData([]);
    setSelectedTab(tabName);
  };

  // 취소 버튼
  const closeUserNoPopup = () => {
    // $('#accAdjustRegUserNoPopup').dialog('close');
    props.setUserNoDialog(false);
  };

  // 확인 버튼
  const selectUserNo = () => {
    const selects = gridRef!.current!.api.getSelectedRows();
    if (selects === undefined || selects.length < 1) {
      alertify.error('환급 대상을 선택해 주세요.');
      return;
    }

    props.setBzmReturnTarget(selects[0]);
    closeUserNoPopup();
  };

  const registerDate = (start:Dayjs, end:Dayjs) => {
    setValue('startDate', start.format('YY-MM-DD'));
    setValue('endDate', end.format('YY-MM-DD'));
  };

  const updateSearchTypeForCamp = (value: any) => {
    setValue('searchTypeForCamp', value);
  };

  const updateSearchTypeForConsulting = (value: any) => {
    setValue('searchTypeForConsulting', value);
  };

  const changePagination = () => {
    childRef!.current!.onPaginationChanged();
  };

  return (
    <Fragment>
      <form>
        <Dialog title="환급 사업자 번호 조회" modal resizable shadow={false} ref={dialogRef} style={{ width: '1200px' }}>
          <div className="dialog">
            <div className="dialog-body">
              <div className="comp-tab">
                <Link to="#" className={`tab ${selectedTab === 'camp' ? 'selected' : ''}`} onClick={() => changeTab('camp')}>
                  <span className="tab-item">캠페인 조회</span>
                </Link>
                <Link to="#"className={`tab ${selectedTab === 'consulting' ? 'selected' : ''}`}
                        onClick={() => changeTab('consulting')}>
                  <span className="tab-item">광고 대행 조회</span>
                </Link>
              </div>
              {/* Comp-Tab : End */}

              {/* Comp-Tab-Content : Start */}
              <div className="comp-tab-content selected">
                {/* Wrap-Datagrid : Start */}
                <section className="wrap-section wrap-datagrid">
                  {/* Wrap-Filter : Start */}
                  <div className="wrap-filter">
                    <div className="inner-filter">
                      <div className="box-left">
                        <div className="item-filter">
                          <div className="filter-tit">
                            <p className="fz-12 fc-2">조회기간</p>
                          </div>
                          <div className="box-filter">
                            <RangeDatePicker changeEvent={registerDate} option={{ startDate: dayjs().startOf('day').subtract(30, 'd') }}
                                  />
                          </div>
                        </div>
                        <div className="item-filter">
                          <div className="filter-tit">
                            <p className="fz-12 fc-2">검색구분</p>
                          </div>
                          <div className="box-filter" style={{ display: `${selectedTab === 'camp' ? 'block' : 'none'}` }}>
                            <SingleSelect2 data={campGridBzm.searchTypes} selectEvent={updateSearchTypeForCamp} clss={'w-150'} />
                          </div>
                          <div className="box-filter" style={{ display: `${selectedTab === 'consulting' ? 'block' : 'none'}` }}>
                            <SingleSelect2 data={consultingGridBzm.searchTypes} selectEvent={updateSearchTypeForConsulting} clss={'w-150'} />
                          </div>
                        </div>

                        <div className="item-filter">
                          <div className="filter-tit">
                            <p className="fz-12 fc-2">검색어</p>
                          </div>
                          <div className="box-filter">
                            {/* Input-Group : Start */}
                            <div className="input-group">
                              <div className="inner-input-group">
                                <input type="text" className="tf-comm" placeholder="검색어를 입력해 주세요." {...register('keyword')} />

                              </div>
                              <p className="fz-12">체크 / 에러 문구 내용 영역</p>
                            </div>
                            {/* Input-Group : End */}
                          </div>
                        </div>
                      </div>
                      <div className="box-right">
                        <button type="submit" className="btn btn-tertiary-mint btn-ico" onClick={loadData}>
                          <i className="ico ico-filter"></i>필터 조회
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="box-body">
                    <div className="ag-grid">
                      <div className="ag-grid-inner">
                        <AgGridReact
                          ref={gridRef}
                          rowData={rowData}
                          // onGridReady={loadData}
                          onGridSizeChanged={moveToCenter}
                          columnDefs={columnDefs.colDefs}
                          defaultColDef={resizableColDef}
                          onPaginationChanged={changePagination}
                          rowHeight={48}
                          pagination={true}
                          paginationPageSize={10}
                          suppressPaginationPanel={true}
                          rowSelection={'single'}
                          domLayout={'autoHeight'}
                          noRowsOverlayComponent={NoDataTemplate}
                        ></AgGridReact>
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <Pagination
                      gridRef={gridRef}
                      ref={childRef}
                    />
                  </div>
                </section>
              </div>
            </div>
            <div className="dialog-footer">
              <button type="button" className="btn btn-secondary-outline large" onClick={closeUserNoPopup}>취소</button>
              <button type="button" className="btn btn-primary large" onClick={selectUserNo}>확인</button>
            </div>
          </div>
        </Dialog>
      </form>
    </Fragment>
  );
};

export default BzmMakerSearchUserNoDialog;
