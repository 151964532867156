import { Fragment, useEffect, useRef, useState } from 'react'
import { bytesToSize } from '../fileUploader/MultiFileUploaderForConsulting'
import alertify from 'alertifyjs'
import { ApiUtil, ApiUtil3 } from '@biz/api'
import { Dialog } from 'rc-easyui'
import { fileDownHandler } from '../bizmoneyAdmin/postpaidMoney/pomInquiry/PomInquiryDetail'

export class Af {
  fileName: string | undefined
  fileSize: number = 0
  filePath: string | undefined
  uploadId: any
  afId: any
  constructor(
    fileName: string | undefined,
    fileSize: number,
    filePath: string | undefined,
    uploadId?: number | undefined
  ) {
    this.fileName = fileName
    this.fileSize = fileSize
    this.filePath = filePath
    this.uploadId = uploadId
  }
}

const PomInquiryRegDialog = (props: any) => {
  const dialogRef = useRef<any>()
  const afs = useRef<Af[]>([])
  const afsSize = useRef<number>(0)
  const uploadIdRef = useRef<number>()
  const [fileCnt, setFileCnt] = useState<number>(0)
  const scrollRef = useRef(null)
  const maxFileSize = 31457280
  const fileUploadHandler = async (e: any) => {
    const file: File = e.target.files[0]
    const fileName: string = file.name
    const fileSize: number = file.size

    console.log(file.type)

    if (
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg' ||
      file.type === 'image/png' ||
      file.type === 'application/pdf'
    ) {
      // 성공
    } else {
      alertify.error('다음의 확장자만 허용합니다.<br/>JPEG, JPG, PNG, PDF')
      return false
    }

    if (afs.current.length >= 5) {
      alertify.error('파일은 최대 5개 업로드 가능합니다.')
      return
    }

    if (afsSize.current + fileSize >= maxFileSize) {
      alertify.error('업로드 가능한 용량을 초과하였습니다.\n' + '30MB 이내')
      return
    }

    await ApiUtil.get('/api/common/getUploadId').then((response) => {
      uploadIdRef.current = response.data.data
    })

    const xhr = new XMLHttpRequest()
    const data = { uploadId: uploadIdRef.current, originFileName: file.name, myS3Upload: 'pom_inquiry' }
    ApiUtil3.post('/api/file/uploadReq', data).then((resp) => {
      const { presignUrl } = resp.data.data
      const { publicUrl } = resp.data.data

      xhr.responseType = 'json'
      xhr.open('PUT', presignUrl, true) // xhr url에다가 s3 url 반환해줘야 함.
      xhr.setRequestHeader('Access-Control-Allow-Headers', '*')
      xhr.send(file)

      const af = new Af(fileName, fileSize, publicUrl, uploadIdRef.current)
      afs.current.push(af)
      afsSize.current += fileSize
      setFileCnt(afs.current.length)
      // @ts-ignore
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
    })
  }
  const fileDeleteHandler = (e: any) => {
    let id = e.currentTarget.id
    let idxToDel = afs.current.findIndex((it) => {
      return it.uploadId == id
    })
    if (idxToDel !== -1) {
      const temp = [...afs.current]
      temp.splice(idxToDel, 1)
      afs.current = temp
    }
    let filesSize = 0
    afs.current.map((it: any) => (filesSize += it.fileSize))
    afsSize.current -= filesSize
    setFileCnt(afs.current.length)
  }

  useEffect(() => {
    $(document).on('click', '#cancelBtn', () => {
      props.setIsPomInquiryRegDialogOpened(false)
      props.setIsInitialGuide(true)
    })

    $('#regBtn').on('click', () => {
      if ($('#pomLimitValue').val() === '') {
        alertify.error('한도 신청금액을 입력해주세요.')
        return
      }
      if (afs.current.length === 0) {
        alertify.error('필수 첨부 서류를 업로드해 주세요.')
        return
      }
      alertify
        .confirm(
          '',
          '제출하시겠습니까?',
          () => {
            ApiUtil3.post('/api/pom/status/regPomInquiry', {
              project2Id: props.project2Id,
              text: $('#text').val(),
              afs: afs.current,
              pomLimitValue: $('#pomLimitValue').val(),
            }).then(() => {
              alertify.success('제출이 완료되었습니다.')
              props.setIsPomInquiryRegDialogOpened(false)
              props.setIsInitialGuide(true)
            })
          },
          {}
        )
        .set({ labels: { cancel: '취소', ok: '확인' } })
    })

    const inputName = 'pomLimitValue'
    $('#' + inputName).on('keyup', () => {
      let value = $('#' + inputName).val() as string
      const regex = /^\d+$/
      if (!regex.test(value)) {
        $('#' + inputName).val(value.replace(/[^\d]/g, ''))
      }
      if (value.length > 6) {
        $('#' + inputName).val(value.substring(0, 6))
      }
    })

    $('#text').on('keyup', () => {
      let value = $('#text').val() as string
      const maxLengthInBytes = 500
      const encoder = new TextEncoder()
      const encodedValue = encoder.encode(value)
      const length = encodedValue.length

      if (length > maxLengthInBytes) {
        const slicedValue = encodedValue.slice(0, maxLengthInBytes)
        const decoder = new TextDecoder()
        value = decoder.decode(slicedValue)
        $('#text').val(value)
      }
      $('#byteCount').text(length)
    })
  }, [])

  return (
    <Fragment>
      <form>
        <Dialog modal resizable shadow={false} ref={dialogRef} style={{ width: '900px' }}>
          <div className="dialog-body">
            <div className="wrap-section wrap-tbl">
              <div className="box-body">
                <div className="tbl">
                  <dl style={{ maxHeight: 100 }}>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">한도 증액 신청</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="box-header no-border">
                        <div className="box-option">
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              props.setIsPomLimitIncreasementApplicationGuideDialogOpened(true)
                              props.setIsInitialGuide(false)
                            }}
                          >
                            작성 가이드
                          </button>
                        </div>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">선택 항목</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <p className="comp-txt">
                          <span className="table">
                            <span className="table-cell">
                              <b className="fz-14 fc-4">{props.projectNm}</b>
                            </span>
                          </span>
                        </p>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">*한도 신청 금액</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <div className="input-group">
                          <div className="inner-input-group">
                            <input
                              type="number"
                              step="1"
                              className="tf-comm"
                              placeholder="숫자만 입력해 주세요."
                              id="pomLimitValue"
                            />
                            <span className="fz-12 fc-1">만원</span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <p className="fz-12 fc-3">
                          기입하는 금액은 증액할 금액이 아닌 변경하고자 하시는 최대 한도액 입니다.
                        </p>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">참고 내용(선택)</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <div className="input-group small">
                          <div className="inner-input-group">
                            <textarea
                              className="tf-textarea"
                              id="text"
                              maxLength={500}
                              style={{ width: 500, minWidth: 500, minHeight: 300 }}
                            />
                          </div>
                          <p className="fz-12 fc-3">
                            <span className="fc-2" id="byteCount">
                              0
                            </span>{' '}
                            / 500
                          </p>
                        </div>
                      </div>
                      <p className="fz-12 fc-3">
                        *해당 접수신청은 한도 증액에 관한 참고, 그 외 다른 질의는 ad-biz@wadiz.kr로 프로젝트 정보와 함께
                        별도 문의 주시기 바랍니다.
                      </p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <div className="dt-inner">
                        <span className="fz-16 fc-1">*파일 첨부(필수)</span>
                      </div>
                    </dt>
                    <dd>
                      <div className="form-group">
                        <div className="comp-file-upload expand">
                          <input type="file" id="fileUploader" multiple={true} onChange={fileUploadHandler} />
                          <i className="ico i-16 ico-file"></i>
                          <input
                            type="text"
                            id="filePlaceHolder"
                            className="tf-comm"
                            placeholder="파일을 선택하세요."
                            readOnly={true}
                          />
                          <label className="btn" htmlFor="fileUploader">
                            첨부파일 +
                          </label>
                        </div>
                      </div>
                      <div>
                        <p className="fz-12 fc-3">
                          보증보험 서류 또는 나이스 신용평가 등급 서류를 업로드해 주세요. (*필수)
                        </p>
                      </div>
                    </dd>
                  </dl>
                  <div ref={scrollRef}>
                    {afs.current.length > 0 ? (
                      <dl>
                        <dt></dt>
                        <dd>
                          <div className="form-group">
                            <div className="comp-file-list">
                              <div className="file-list-top">
                                <span className="fz-14 fc-2">
                                  첨부 파일
                                  <span className="fc-5">
                                    <span className="fc-2" id="fileCnt">
                                      {fileCnt}
                                    </span>
                                  </span>
                                  개
                                </span>
                              </div>
                              <div className="file-list-bottom">
                                {afs.current.map((af, key: number) => {
                                  return (
                                    <div className="file-item" key={key} id={`file-${af.uploadId}`}>
                                      <i className="ico ico-file-list"></i>
                                      <span className="fz-12 fc-2">{af.fileName}</span>
                                      <span className="fz-12 fc-3">({bytesToSize(af.fileSize)})</span>
                                      <button onClick={() => fileDownHandler(af.filePath, af.fileName)}>
                                        <i className="ico ico-download"></i>
                                      </button>
                                      <button onClick={fileDeleteHandler} id={`${af.uploadId}`}>
                                        <i className="ico ico-delete" />
                                      </button>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        </dd>
                      </dl>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dialog-footer">
            <button type="button" className="btn btn-primary-outline" id={'cancelBtn'}>
              취소
            </button>
            <button type="button" className="btn btn-primary" id={'regBtn'}>
              확인
            </button>
          </div>
        </Dialog>
      </form>
    </Fragment>
  )
}

export default PomInquiryRegDialog
