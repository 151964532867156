import { axiosInstance } from '../axios'

type DownFunction = (params: string) => Promise<Blob>
export const fetchDownloadFinanceReport: DownFunction = (params) =>
  axiosInstance
    .get(`/api/mngRpt/finance/dp/download?${params}`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'text/plain; charset=euc-kr',
      },
    })
    .then((res) => res.data)
