import { Form, Input } from 'antd'
import { targetFormRegistry } from '@biz/ui'
import styles from './InputAdTitle.module.scss'

type InputAdProps = {
  containerName: number
  onUpdate?: () => void
  disabled?: boolean
}
export const InputAdTitle: React.FC<InputAdProps> = ({ containerName, onUpdate, disabled }) => (
  <Form.Item name={[containerName, targetFormRegistry.InputAdTitleName]} className={styles.InputAdTitle}>
    <Input.TextArea
      placeholder="광고 제목을 입력하세요(선택)"
      showCount
      maxLength={255}
      autoSize={{ minRows: 1, maxRows: 2 }}
      onBlur={onUpdate}
      disabled={disabled}
    />
  </Form.Item>
)
