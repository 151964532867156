import { axios } from '../axios'

export type AdditionalCampaignInfo = {
  id: number // 302483541
  adSetOrder: number // 1
  genderDiv: string // 'FEMALE'
  location: string // '[{"key":"2005","name":"Incheon, South Korea","type":"region","countryCode":"KR","countryName":"대한민국","region":"undefined","regionId":null,"supportsRegion":true,"supportsCity":true}]'
  age: string // '21,41'
  adSetExtYn: boolean // false
  snsApiOkYn: boolean // false
  useYn: boolean // true
  syncYn: boolean // false
  campNm: string // '##테스트 타겟광고 내용 입력 API 확인용'
  adminCampYn: boolean // false
  campId: number // 302483540
  orgHierarchy: []
  creationLinks: []
}

type ReturnType = {
  ok: boolean
  data: AdditionalCampaignInfo
}

type FunctionType = (campId: number) => Promise<ReturnType>
export const fetchAdditionalCampaignInfo: FunctionType = (campId) =>
  axios.get(`/api/camp/make02/fbAdSetDataSearch?campId=${campId}`)
