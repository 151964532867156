import { Fragment, useRef } from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { ApiUtil3, downloadUrl } from '@biz/api'
import { Dialog } from 'rc-easyui'

/**
 * 소재 검수 이력 -> 이미지/동영상 미리보기
 * */
const PreviewImgDialog2 = (props: any) => {
  const dialogRef = useRef<any>()
  const chunkedFile = _.chunk(props.fileData, 5)

  // 다운로드용
  const imgLoad = (id: any, afName: any) => {
    ApiUtil3.post('/api/file/downloadReq2', { fileId: id }).then((resp: any) => {
      downloadUrl(resp.data.data, afName)
    })
  }

  const closeEvent = () => {
    props.setFileDetailDialog(false)
  }

  return (
    <Fragment>
      <form>
        <Dialog title="이미지 미리보기" modal resizable shadow={false} ref={dialogRef} style={{ width: '600px' }}>
          <div className="dialog">
            <div className="dialog-body">
              <div className="wrap-section wrap-tbl wrap-thumb-history">
                <div className="box-body">
                  <div className="tbl">
                    <dl className="vertical">
                      <dd>
                        {chunkedFile.map((a: any, k: any) => (
                          <div className="form-group" key={k}>
                            <div className="flex-container">
                              {a.map((aa: any, kk: any) => (
                                <div className="box-group col-10-2" key={kk}>
                                  <div
                                    className="comp-thumb"
                                    id={`detailImg_${aa.id}_${aa.afName}_${kk}`}
                                    data-url={aa.fileName}
                                    onClick={() => window.open(aa.fileName)}
                                  >
                                    {aa.afName.split('.')[1] === 'PNG' ||
                                    aa.afName.split('.')[1] === 'png' ||
                                    aa.afName.split('.')[1] === 'jpg' ? (
                                      <span className="txt-size">
                                        {JSON.parse(aa.afMeta).width}x{JSON.parse(aa.afMeta).height}
                                      </span>
                                    ) : (
                                      <i className="ico ico-video"></i>
                                    )}
                                    {JSON.parse(aa.afMeta).fileDiv === 'VIDEO' &&
                                    JSON.parse(aa.afMeta).ext !== 'gif' ? (
                                      // eslint-disable-next-line jsx-a11y/media-has-caption
                                      <video
                                        autoPlay
                                        controls
                                        muted
                                        // style={{ width: '100%' }}
                                      >
                                        <source src={`${aa.fileName}`} />
                                      </video>
                                    ) : (
                                      <img
                                        src={`${aa.fileName}`}
                                        // style={{ width: '100%' }}
                                        alt="img22"
                                      />
                                    )}
                                  </div>
                                  <Link
                                    to="#!"
                                    className="txt-underline"
                                    id={`detailImgDown_${aa.id}_${aa.afName}_${kk}`}
                                    data-id={aa.id}
                                    data-afname={aa.afName}
                                    onClick={() => imgLoad(aa.id, aa.afName)}
                                  >
                                    <p className="fz-12 fc-2">{aa.afName}</p>
                                  </Link>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <div className="dialog-footer">
              <button id="closePreview" type="button" className="btn btn-secondary-outline large" onClick={closeEvent}>
                닫기
              </button>
            </div>
          </div>
        </Dialog>
      </form>
    </Fragment>
  )
}

export default PreviewImgDialog2
