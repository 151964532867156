import { useCreationsByAdSetQuery, useFacebookLinkQuery } from '@biz/query'

export const useFacebookLink = (creationDataId?: number) => {
  const { data: linkDataList } = useCreationsByAdSetQuery()
  const snsInfo = linkDataList?.find((n) => `${n.creationDataId}` === `${creationDataId}`)
  const isEnabledFacebookLink = !!snsInfo && !!snsInfo?.snsAdId && !!snsInfo?.snsApiOkYn
  const { data: facebookLink } = useFacebookLinkQuery(isEnabledFacebookLink, snsInfo?.snsAdId)

  return isEnabledFacebookLink && !!facebookLink?.linkUrl
    ? () => {
        window.open(facebookLink?.linkUrl, '_blank')
      }
    : undefined
}
