import { axiosInstance } from '../axios'

type AdDataType = {
  basicDate: string
  adUse: number
  paidUse: number
  freeUse: number
  pomUse: number
}
type DailyStateType = {
  adUse: number
  paidUse: number
  freeUse: number
  pomUse: number
}
type ResponseType = {
  list: AdDataType[]
  rows: number
  stats: DailyStateType[]
}
export type ParamType = {
  adDiv: 'display' | 'push'
  sectionCode: string
  startDate: string
  endDate: string
}
type ParamTypeKeys = keyof ParamType

export const spreadParam = (params: ParamType) => {
  const result = (Object.keys(params) as ParamTypeKeys[]).reduce(
    (a, k, n) => (params[k] === undefined ? a : `${a}${(n === 0 ? '' : '&') + k}=${params[k]}`),
    '?'
  )
  return result === '?' ? '' : result
}

type FunctionType = (props: ParamType) => Promise<ResponseType>
export const fetchAdList: FunctionType = (param) =>
  axiosInstance
    .get(`/api/mngRpt/sales/dp/ads${spreadParam(param)}`, {
      headers: {
        'Content-Type': 'application/json;',
      },
    })
    .then((res) => res?.data?.data)
