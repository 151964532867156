import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import alertify from 'alertifyjs'
import CkEditor5N from '../../ckEditor5/CkEditor5N';
import { ApiUtil } from '@biz/api'

const PrivateDataPost = () => {
  const setTitle = useOutletContext<any>();
  const [version, setVersion] = useState<string>('');
  const [text, setText] = useState<string>('');
  const systemDataDiv = 'PRIVATE_DATA';

  function regexFunc(value: any) {
    const regex = /[^.0-9]/g; // 숫자와 .만가능
    if (regex.test(value)) {
      $('#version').val('');
    }
    return value;
  }

  const versionHandler = (e: {
        preventDefault: () => void;
        target: { value: React.SetStateAction<string>; };
    }) => {
    e.preventDefault();
    regexFunc($('#version').val());
    setVersion(regexFunc($('#version').val()));
  };

  const successEvent = (msg:string) => {
    alertify.success(msg, 1);
  };

  const confirmSuccess = (msg:string) => {
    successEvent(msg);
  };

  const submitHandler = (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    const body = {
      systemDataDiv,
      version,
      text,
    };

    alertify.confirm('저장하시겠습니까?', () => {
      ApiUtil.post('/api/community/systemData/post', body).then(() => {
        confirmSuccess('저장되었습니다.');
        window.location.href = '/community/systemData';
      });
    }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
  };

  useEffect(() => {
    setTitle('개인정보 처리방침 및 광고 서비스 이용약관');
    return () => {
      setTitle('');
    };
  }, []);

  return (
        <>
            <section className="wrap-section wrap-tbl wrap-editor">
                <div className="box-header">
                    <h2 className="fz-20 fc-1 fw-bold">개인정보 처리방침</h2>
                </div>
                <div className="box-body">
                    <div className="tbl">
                        <dl>
                            <dt>
                                <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        버전
                                    </span>
                                </div>
                            </dt>
                            <dd>
                                <div className="form-group">
                                    <input id="version" type="text" maxLength={5} className="tf-comm" value={version} onChange={versionHandler} placeholder="최대 5자 입력가능"/>
                                </div>
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                <div className="dt-inner">
                                    <span className="fz-16 fc-1">
                                        내용
                                    </span>
                                </div>
                            </dt>
                            <dd>
                                <div className="form-group">
                                    <CkEditor5N
                                        setText={setText}
                                        data={text}
                                    />
                                </div>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className="box-footer">
                    <div className="box-right">
                        <button onClick={() => { window.location.href = '/community/systemData'; }} className="btn btn-secondary-outline">취소</button>
                        <button className="btn btn-primary" onClick={submitHandler}>등록</button>
                    </div>
                </div>
            </section>
        </>
  );
};

export default PrivateDataPost;
