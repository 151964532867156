import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Dialog } from 'rc-easyui';
import { ApiUtil2 } from '@biz/api'
import { defaultColDef } from '../AccAdjustTargetUtil';

/*
  react-easyui사용한 버전
  jquery 안써도 되는듯
*/
const AccAdjustDialogTaxbillHistory = (props: any) => {
  // grid 요소
  const gridRef = useRef<AgGridReact>(null);
  const dialogRef = useRef<Dialog>(null);
  const [rowData, setRowData] = useState<any[]>([]);

  const columnDefs = [
    { field: 'taxbillStatusDesc', headerName: '발행상태', cellClass: 'ag-left-aligned-cell' },
    { field: 'updTime', headerName: '변경일시', cellClass: 'ag-left-aligned-cell' },
    { field: 'updName', headerName: '변경자' },
  ];

  // 취소 버튼
  const closeDialog = () => {
    props.setHistoryDialog(false);
  };

  useEffect(() => {
    window.console.log('팝업 초기화 요청');
  }, []);

  const onGridReady = () => {
    ApiUtil2.get('/api/accAdjust/taxbill/history', {
      params: {
        id: props.taxbillId,
      },
    }).then((resp) => {
      setRowData(resp.data.data);
    });
  };

  useEffect(() => {
    // something
    dialogRef?.current?.center();
    onGridReady();
  }, [props.historyDialog]);

  return (
    <Fragment>
      <Dialog title="세금계산서 발행 히스토리" modal resizable shadow={false} ref={dialogRef} style={{ width: '800px' }}>
        <div className="dialog">
          <div className="dialog-body">
            {/* Comp-Tab-Content : Start */}
            <div className="comp-tab-content selected">
              {/* Wrap-Datagrid : Start */}
              <section className="wrap-section wrap-datagrid">
                {/* Wrap-Filter : Start */}
                <div className="box-body">
                  <div className="ag-grid">
                    <div className="ag-grid-inner">
                      <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowHeight={48}
                        domLayout={'autoHeight'}
                        // noRowsOverlayComponent={NoDataTemplate}
                      ></AgGridReact>
                    </div>
                  </div>
                </div>
              </section>
              {/* Wrap-Datagrid : End */}
            </div>
            {/* Comp-Tab-Content : End */}
          </div>
          <div className="dialog-footer">
            <button type="button" className="btn btn-primary large" onClick={closeDialog}>확인</button>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default AccAdjustDialogTaxbillHistory;
