import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState,} from 'react';
import {Link, useOutletContext} from 'react-router-dom';
import {AgGridReact} from 'ag-grid-react';
import {ColDef, GridSizeChangedEvent, ICellRendererParams} from 'ag-grid-community';
import {useForm} from 'react-hook-form';

import { ApiUtil2 } from '@biz/api'
import { ApiUtil } from '@biz/api'
import { PageTitle } from '@biz/ui'

import Pagination from '../../views/common/Pagination';
import NoDataTemplate from '../../views/common/NoDataTemplate';
import DlDialog from "../../views/mngMakerAccount/DlDialog";

export interface ISearch {
    name?: string;
    status?: string;
    api: { sizeColumnsToFit: () => void; };
}

/* aggrid renderer */
export const numberFormatRenderer = (props: ICellRendererParams) => {
    return props.data.totalBudget === 0 ? '-' : props.data.totalBudget.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
const adminIdRenderer = (props: ICellRendererParams) => (props.data.adminNm === undefined ? '-' : props.data.adminNm);
const detailRenderer = (props: ICellRendererParams) => {
  const { consultingId } = props.data;
  return <Link to={`/mngAd/consulting/detail/${consultingId}`}>보기</Link>;
};

const Consulting = () => {
  const gridRef: any = useRef(null); // useRef 에러 수정(초기화)
  const childRef = useRef<any>(null);
  const [statusList, setStatusList] = useState<string[][]>([]);
  const [rowData, setRowData] = useState<any[]>();
  const { handleSubmit } = useForm<ISearch>();

  const [isDlDialogOpened, setIsDlDialogOpened] = useState<boolean>(false);
  const [dlParam, setDlParam] = useState<any>(null);


  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'regTime',
      headerName: '신청일',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      width: 50,
    },
    {
      field: 'makerNm',
      headerName: '메이커명',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
      width: 50,
    },
    {
      field: 'consultingId',
      headerName: '고유번호',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      width: 50,
    },
    {
      field: 'projectNm',
      headerName: '프로젝트명',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
      width: 100,
    },
    {
      field: 'totalBudget',
      headerName: '광고예산',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: numberFormatRenderer,
      width: 50,
    },
    {
      field: 'adminNm',
      headerName: '담당자ID',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
      cellRenderer: adminIdRenderer,
      width: 50,
    },
    {
      field: 'consultingStatusDes',
      headerName: '진행상태',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      width: 50,
    },
    {
      field: '상세정보',
      headerName: '상세정보',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: detailRenderer,
      width: 50,
    },
  ]);

  const popupParent = useMemo(() => document.body, []);

  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: true,
  }), []);

  const onGridReady = useCallback(() => {
    const name = $('#name').val();
    const status = $('#status').val();
    ApiUtil2.post('/api/mngAd/consulting/list', { name, status }).then((resp) => setRowData(resp.data.data));
  }, []);

  const changePagination = () => {
    childRef.current!.onPaginationChanged();
  };

  const onGridSizeChanged = (params: GridSizeChangedEvent) => {
    params.api.sizeColumnsToFit();
  };

  const onBtnExport = useCallback(() => {
    // api 불러서 다운로드하는걸루
    // gridRef.current.api.exportDataAsCsv();
    const name = $('#name').val();
    const status = $('#status').val();

    setDlParam({
      status,
      name
    });

    setIsDlDialogOpened(true);
  }, []);

  useEffect(() => {
    /* consultingStatus */
    $('#status').select2({
      width: '150',
      minimumResultsForSearch: Infinity,
    }).on('select2:select', (e) => {
      const status = e.params.data.id;
      const name = $('#name').val();
      ApiUtil.post('/api/mngAd/consulting/list', { name, status }).then((resp) => {
        setRowData(resp.data.data);
      });
    });

    /* consultingStatus */
    ApiUtil.get('/api/mngAd/consulting/getStatus').then((resp) => {
      const options = Object.keys(resp.data.data).map((key) => [String(key), resp.data.data[key]]);
      setStatusList(options);
    });
  }, []);

  return (
    <Fragment>
      <PageTitle>광고 대행 관리</PageTitle>
      <section className="wrap-section wrap-datagrid">
        <div className="wrap-filter">
          <form onSubmit={handleSubmit(onGridReady)}>
            <div className="inner-filter">
              <div className="box-left">
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">진행상태</p>
                  </div>
                  <div className="box-filter">
                    <select id="status" className="select2-single">
                      <option value="all">전체</option>
                      {statusList.map((a: any) => (
                        <option key={a} value={a[0]}>
                          {a[1]}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="box-filter">
                    <div className="input-group comp-search">
                      <div className="inner-input-group">
                        <input
                          id="name"
                          type="text"
                          className="tf-comm"
                          placeholder="메이커명 또는 담당자ID를 입력하세요."
                        />
                        <i className="ico ico-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-right">
                <button type="submit" className="btn btn-tertiary-mint btn-ico">
                  <i className="ico ico-filter"></i>필터 조회
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="box-header">
          <div className="box-tit">
            <h2 className="fz-20 fc-1 fw-bold">광고 대행 신청 리스트</h2>
          </div>
          <div className="box-option">
            <button type="button" className="btn btn btn-secondary-outline btn-ico" onClick={onBtnExport}>
              <i className="ico ico-download"></i>다운로드
            </button>
            <Link to="/mngAd/consulting/setting">
              <button type="button" className="btn btn-primary">
                광고 대행 세팅
              </button>
            </Link>
          </div>
        </div>
        <div className="box-body">
          <div className="ag-grid">
            <div className="ag-grid-inner">
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                // rowSelection={'multiple'}
                onGridReady={onGridReady}
                onGridSizeChanged={onGridSizeChanged}
                rowHeight={48}
                // getRowHeight={getRowHeight}
                pagination={true}
                paginationPageSize={10}
                popupParent={popupParent}
                suppressPaginationPanel={true}
                suppressExcelExport={true}
                // suppressScrollOnNewData={true}
                onPaginationChanged={changePagination}
                domLayout={'autoHeight'}
                noRowsOverlayComponent={NoDataTemplate}
              ></AgGridReact>
            </div>
          </div>
        </div>
        <div className="box-footer">
          <Pagination gridRef={gridRef} ref={childRef} />
        </div>
      </section>
      {isDlDialogOpened ? (
        <DlDialog
          setIsDlDialogOpened={setIsDlDialogOpened}
          dlUrl={'/api/mngAd/consulting/download'}
          dlParam={dlParam}
        />
      ) : null}
    </Fragment>
  )
};

export default Consulting;
