import axios from 'axios'
import alertify from 'alertifyjs'

import { baseOptions } from '../axios/baseOptions'

const ApiUtil3 = axios.create({
  ...baseOptions,
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  transformRequest: [(data) => new URLSearchParams({ data: JSON.stringify(data) })],
})
ApiUtil3.interceptors.request.use(
  (config) => {
    // 요청 로딩바
    const commonLoader = document.querySelector('.loader')
    if (commonLoader !== null) commonLoader.classList.remove('none')
    return config
  },
  (error) => {
    // 에러시 로딩 종료
    const commonLoader = document.querySelector('.loader')
    if (commonLoader !== null) commonLoader.classList.add('none')
    alertify.error(error.message)
    return Promise.reject(error)
  }
)

ApiUtil3.interceptors.response.use(
  (response) => {
    // window.console.table(response.data);
    // 권한 해제로 인한 API 호출 오류시
    if (response.data !== undefined || response.data.data !== undefined) {
      if (response.data.ok !== undefined && !response.data.ok) {
        if (response.data.data.msg !== undefined) {
          const commonLoader = document.querySelector('.loader')
          if (commonLoader !== null) commonLoader.classList.add('none')
          alertify.error(response.data.data.msg)
          return Promise.reject(response)
        }
      }
    }
    // 그리드의 height 사이즈 변경으로 인한 공통 처리 부분
    const grid = document.querySelector<HTMLElement>(
      '.ag-root-wrapper-body.ag-focus-managed.ag-layout-auto-height'
    )! as any
    if (grid !== null) {
      if (response.data !== undefined) {
        if (response.data.length !== undefined) {
          if (response.data.length === 0) {
            ;(
              document.querySelector<HTMLElement>(
                '.ag-root-wrapper-body.ag-focus-managed.ag-layout-auto-height'
              )! as any
            ).style.height = '548px'
            ;(
              document.querySelector<HTMLElement>('.ag-root-wrapper.ag-ltr.ag-layout-auto-height')! as any
            ).style.height = '548px'
          } else {
            ;(
              document.querySelector<HTMLElement>(
                '.ag-root-wrapper-body.ag-focus-managed.ag-layout-auto-height'
              )! as any
            ).style.height = ''
            ;(
              document.querySelector<HTMLElement>('.ag-root-wrapper.ag-ltr.ag-layout-auto-height')! as any
            ).style.height = ''
          }
        }
      }
      if (response.data.data !== undefined) {
        if (response.data.data.length === 0) {
          ;(
            document.querySelector<HTMLElement>('.ag-root-wrapper-body.ag-focus-managed.ag-layout-auto-height')! as any
          ).style.height = '548px'
          ;(document.querySelector<HTMLElement>('.ag-root-wrapper.ag-ltr.ag-layout-auto-height')! as any).style.height =
            '548px'
        } else {
          ;(
            document.querySelector<HTMLElement>('.ag-root-wrapper-body.ag-focus-managed.ag-layout-auto-height')! as any
          ).style.height = ''
          ;(document.querySelector<HTMLElement>('.ag-root-wrapper.ag-ltr.ag-layout-auto-height')! as any).style.height =
            ''
        }
      }
    }
    // 요청완료 로딩 종료
    const commonLoader = document.querySelector('.loader')
    if (commonLoader !== null) commonLoader.classList.add('none')
    return response
  },
  (error) => {
    // 에러시 로딩 종료
    const commonLoader = document.querySelector('.loader')
    if (commonLoader !== null) commonLoader.classList.add('none')
    alertify.error(error.message)

    return Promise.reject(error)
  }
)

export { ApiUtil3 }
