import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query'
import alertify from 'alertifyjs'
import type { GeoInfoType } from '@biz/api'

const queryKey = ['@biz/query/targetAdContext/useCachedLocationList']
export const overrideCachedList = (queryClient: QueryClient) => async (list: GeoInfoType[]) =>
  queryClient.setQueryData(queryKey, list)

/* 사용자의 입력을 받고,
 */
export const updateCachedList = (queryClient: QueryClient) => (sel: GeoInfoType) => {
  const geoList = queryClient.getQueryData(queryKey) as GeoInfoType[]
  // 이미 있음
  if (!!geoList.find((n) => n.key === sel.key)) {
    return geoList.map((n) => n.name)
  }

  // '대한민국'을 선택했거나, 혹시 '대한민국'이 선택된 경우엔 무조건 입력한 지역만 남기면 된다.
  if (sel.type === 'country' || !!geoList.find((n) => n.type === 'country')) {
    queryClient.setQueryData(queryKey, [sel])
    alertify.error(
      '지역은 국가, 행정구역, 도시 단위로 선택이 가능하며,' +
        '선택된 지역 중 연관이 있는 지역이 있고 서로 단위가 다른 지역을 선택한 경우 나중에 선택한 지역만 출력 됩니다. <br> (단, 동일 단위의 지역이거나 연관이 없는 지역의 경우 다수 선택이 가능합니다.)<br>' +
        'ex) 대한민국 선택 후 부산 선택 시 부산만 출력, 수원 선택 후 경기도 선택 시 경기도만 출력'
    )
    return [sel.name]
  }

  // 나머지는 시티
  const newList = [...geoList, sel]
  queryClient.setQueryData(queryKey, newList)
  return newList.map((n) => n.name)
}
export const dryUpdateCachedList = (queryClient: QueryClient) => (sel: GeoInfoType) => {
  const geoList = queryClient.getQueryData(queryKey) as GeoInfoType[]
  // 이미 있음
  if (!!geoList.find((n) => n.key === sel.key)) {
    return geoList.map((n) => n.name)
  }
  // 국가 단위 선택으로 리스트 자체가 바뀜
  if (sel.type === 'country' || !!geoList.find((n) => n.type === 'country')) {
    return [sel.name]
  }
  return [...geoList, sel].map((n) => n.name)
}
export const removeOneInCachedList = (queryClient: QueryClient) => (sel: string) => {
  const geoList = queryClient.getQueryData(queryKey) as GeoInfoType[]
  const newList = geoList.filter((n) => n.name !== sel)
  queryClient.setQueryData(queryKey, newList)
}
export const useCachedLocationListQuery = () => {
  const queryClient = useQueryClient()
  return {
    ...useQuery({
      queryKey,
      queryFn: () => [] as GeoInfoType[],
    }),
    dryUpdate: dryUpdateCachedList(queryClient),
    update: updateCachedList(queryClient),
    remove: removeOneInCachedList(queryClient),
  }
}
export const loadCachedLocationList = (queryClient: QueryClient) => () =>
  queryClient.getQueryData(queryKey) as GeoInfoType[]
