import React, { Fragment, useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query' // try next react-query;
import { useRoleQuery } from '@biz/query'
import { ApiUtil, ApiUtil2 } from '@biz/api'

const ConsultingApplicationStatus = () => {
  const defaultDataCnt = 0;
  const [summaryData, setSummaryData] = useState<any>([]);
  const [recentlyRegData, setRecentlyRegData] = useState<any>([]);
  const [creationDataInspStatusCnt, setCreationDataInspStatusCnt] = useState<any>([]);

  const { data: auth } = useRoleQuery()
  const loginCheckAdminHomeApi = async () => {
    const { data } = await ApiUtil2.get('/api/loginCheck');
    return data;
  };
  const loginCheckAdminHome = useQuery(['loginCheckAdminHomeApi'], loginCheckAdminHomeApi, {
    enabled: false,
  })
  const loginCheckAdminHomeEvent = () => {
    loginCheckAdminHome.refetch().then((r) => {
      if (r.isSuccess) {
        if (r.data.ok) {
          if (auth === 'ROLE_ADMIN') {
            ApiUtil.get('/api/common/applicationStatus/summary').then((resp) => {
              setSummaryData(resp.data.data);
            });
            ApiUtil.get('/api/common/applicationStatus/recentlyRegCnt').then((resp) => {
              setRecentlyRegData(resp.data.data);
            });
            ApiUtil.get('/api/common/applicationStatus/creationDataInspStatusCnt').then((resp) => {
              setCreationDataInspStatusCnt(resp.data.data);
            });
          }
        }
      }
    });
  };
  useEffect(() => {
    // 쉐도우 로그인 시 권한 문제 때문에 여기서는 한번 로그인 체크해서 권한 확인한다.
    loginCheckAdminHomeEvent();
  }, []);

  const RecentlyRegComponent = (item: any) => (
        <tr>
            <th>
                <span className="fz-14 fc-2">{item.item.regTime}</span>
            </th>
            <td>
                <span className="fz-14 fc-2">{item.item.cnt} <span className="fc-3">건</span></span>
            </td>
        </tr>
  );
  return (
        <Fragment>
            <div className="col col-4">
                <div className="box box-current-state">
                    <section className="wrap-section wrap-tbl">
                        <div className="box-header">
                            <div className="box-tit">
                                <h2 className="fz-20 fw-bold fc-1">컨설팅 신청 현황</h2>
                            </div>
                        </div>
                        <div className="box-body">
                            <div className="tbl">
                                <dl className="vertical">
                                    <dt>
                                        <div className="dt-inner">
                                          <span className="fz-16 fc-1">
                                              <i className="bullet-bar"></i>요약
                                          </span>
                                        </div>
                                    </dt>
                                    <dd>
                                        <div className="form-group">
                                            <table className="tbl">
                                                <colgroup>
                                                    <col width="50%"/>
                                                </colgroup>
                                                <tbody>
                                                <tr>
                                                    <th>
                                                        <span className="fz-14 fc-2">진행</span>
                                                    </th>
                                                    <td>
                                                        <span className="fz-14 fc-2">{summaryData.length !== 0 ? summaryData.proceedCnt : defaultDataCnt} <span
                                                            className="fc-3">건</span></span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="fz-14 fc-2">미진행</span>
                                                    </th>
                                                    <td>
                                                        <span className="fz-14 fc-2">{summaryData.length !== 0 ? summaryData.waitCnt : defaultDataCnt} <span
                                                            className="fc-3">건</span></span>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </dd>
                                </dl>
                                <dl className="vertical">
                                    <dt>
                                        <div className="dt-inner">
                                          <span className="fz-16 fc-1">
                                              <i className="bullet-bar"></i>최근 신규 등록건
                                          </span>
                                        </div>
                                    </dt>
                                    <dd>
                                        <div className="form-group">
                                            <table className="tbl">
                                                <colgroup>
                                                    <col width="50%"/>
                                                </colgroup>
                                                <tbody>
                                                {recentlyRegData.map((item: any, index: any) => (
                                                    <RecentlyRegComponent key={index} item={item}/>))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                        <div className="box-header h-auto">
                            <div className="box-tit">
                                <h2 className="fz-20 fw-bold fc-1">소재 검수 현황</h2>
                            </div>
                        </div>
                        <div className="box-body">
                            <div className="tbl">
                                <dl className="vertical">
                                    <dd>
                                        <div className="form-group">
                                            <table className="tbl">
                                                <colgroup>
                                                    <col width="50%"/>
                                                </colgroup>
                                                <tbody>
                                                <tr>
                                                    <th>
                                                        <span className="fz-14 fc-2">검수중</span>
                                                    </th>
                                                    <td>
                                                        <span className="fz-14 fc-2">{creationDataInspStatusCnt.length !== 0 ? creationDataInspStatusCnt.inspectingCnt : defaultDataCnt} <span
                                                            className="fc-3">건</span></span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="fz-14 fc-2">재검수중</span>
                                                    </th>
                                                    <td>
                                                        <span className="fz-14 fc-2">{creationDataInspStatusCnt.length !== 0 ? creationDataInspStatusCnt.reInspectingCnt : defaultDataCnt} <span className="fc-3">건</span></span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="fz-14 fc-2">반려</span>
                                                    </th>
                                                    <td>
                                                        <span className="fz-14 fc-2">{creationDataInspStatusCnt.length !== 0 ? creationDataInspStatusCnt.failCnt : defaultDataCnt} <span className="fc-3">건</span></span>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </Fragment>
  );
};
export default React.memo(ConsultingApplicationStatus);
