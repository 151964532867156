import { useMutation, useQueryClient, type UseMutationResult } from '@tanstack/react-query'
import { fetchPostFileImage, type FileImageAssetType } from '@biz/api'
import { silentUpdateCreativeData } from '../useCreativeDataQuery'

type FunctionType = () => UseMutationResult<unknown, unknown, FileImageAssetType, unknown>
export const useFileImageMutation: FunctionType = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data) =>
      fetchPostFileImage(data).then((res) => {
        silentUpdateCreativeData(queryClient)
        return res
      }),
  })
}
