import React, {
  Fragment, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridSizeChangedEvent, ICellRendererParams } from 'ag-grid-community';
import { ApiUtil2 } from '@biz/api'
import Pagination from '../../../common/Pagination'
import NoConsultingTemplate from '../../../common/NoConsultingTemplate';

const adminIdRenderer = (props: ICellRendererParams) => (props.data.adminNm === undefined ? '-' : props.data.adminNm);
const numberFormatRenderer = (props: ICellRendererParams) => (props.data.consultingFees === 0 ? '-'  : props.data.consultingFees.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,'));

const Make01 = () => {
  const setTitle = useOutletContext<any>();
  const gridRef = useRef<AgGridReact>(null);
  const childRef = useRef<any>(null);
  const [rowData, setRowData] = useState<any[]>();
  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'regTime',
      headerName: '신청일',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      width: 50,
    },
    {
      field: 'consultingId',
      headerName: '고유번호',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      width: 50,
    },
    {
      field: 'projectNm',
      headerName: '프로젝트명',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
      width: 200,
    },
    {
      field: 'consultingFees',
      headerName: '광고대행 수수료(VAT미포함)',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      width: 60,
      cellRenderer: numberFormatRenderer,
    },
    {
      field: 'adminNm',
      headerName: '담당자ID',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: adminIdRenderer,
      width: 50,
    },
    {
      field: 'consultingStatusDes',
      headerName: '진행상태',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      width: 50,
    },
  ]);

  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: true,
  }), []);

  const onGridReady = useCallback((e: { api: { sizeColumnsToFit: () => void; }; }) => {
    e.api.sizeColumnsToFit();
    ApiUtil2.get('/api/camp/make01/consultingList').then((resp) => {
      setRowData(resp.data.data);
    });
  }, []);

  const changePagination = () => {
        childRef.current!.onPaginationChanged();
  };

  // onGridSizeChanged will get called when grid is ready and every time the grid's width changes
  const onGridSizeChanged = (params: GridSizeChangedEvent) => {
    params.api.sizeColumnsToFit();
  };

  useEffect(() => {
    /** 광고 대행 신청 이후 정상적으로 등록되었다면 세션에 담긴 컨설팅 정보 제거 */
    sessionStorage.removeItem('make01ReqInfo');
    setTitle('광고 대행 요청하기');
    return () => {
      setTitle('');
    };
  }, []);

  return (
        <Fragment>
            <section className="wrap-section wrap-datagrid">
                <div className="box-header">
                    <div className="box-tit">
                        <h2 className="fz-20 fc-1 fw-bold">광고대행 신청내역</h2>
                    </div>
                    <div className="box-option">
                        <Link to="/camp/make01/req">
                            <button type="button" className="btn btn-primary">광고 대행 신청</button>
                        </Link>
                    </div>
                </div>
                <div className="box-body">
                    <div className="ag-grid">
                        <div className="ag-grid-inner">
                            <AgGridReact
                                ref={gridRef}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                // rowSelection={'multiple'}
                                onGridReady={onGridReady}
                                onGridSizeChanged={onGridSizeChanged}
                                rowHeight={48}
                                // getRowHeight={getRowHeight}
                                pagination={true}
                                paginationPageSize={10}
                                suppressPaginationPanel={true}
                                // suppressScrollOnNewData={true}
                                onPaginationChanged={changePagination}
                                domLayout={'autoHeight'}
                                noRowsOverlayComponent={NoConsultingTemplate}
                            ></AgGridReact>
                        </div>
                    </div>
                </div>
                <div className="box-footer">
                    <Pagination gridRef={gridRef} ref={childRef}/>
                </div>
            </section>
        </Fragment>
  );
};

export default Make01;
