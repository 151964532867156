import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import PomForAdminTab from "./PomForAdminTab";
import {AgGridReact} from "ag-grid-react";
import {ColDef} from "ag-grid-community";
import {useForm} from "react-hook-form";
import dayjs from "dayjs";
import alertify from 'alertifyjs'
import {useOutletContext} from "react-router";
import { ApiUtil2 } from '@biz/api'
import NoDataTemplate from "../../../common/NoDataTemplate";
import Pagination from "../../../common/Pagination";
import MakerSelectDialog from "../../../bizmoney/MakerSelectDialog";
import qs from "qs";

interface IPomDailyUsageDetailForAdmin {
    basicDate: string,
    makerLoginId: string,
    makerNm: string,
    project2Id: string,
    campId: number,
    pomDiv: string,
    pom: number,
    atPomRemain: number,
    atCampRemain: number,
    atPomAvailable: number,
    note: string,
}
interface IPomDailyUsageDetailsForAdminList {
    ok: boolean;
    data: IPomDailyUsageDetailForAdmin[];
}

interface PomDailyUsageDetailForAdminSrchForm {
    startDate: string,
    endDate: string,
    srchType: string,
    project2Id: string,
    makerIds: number[],
}
const dateFormatter = (props:any) => {
    const year = props.data.basicDate.substring(0, 4);
    const month = props.data.basicDate.substring(4, 6);
    const day = props.data.basicDate.substring(6, 8);
    return `${year}-${month}-${day}`;
};
const initTooltip = () => {
    $('#pomDiv').tooltip({ content: '<p class="fz-12 fc-0 fw-medium">메이커가 사용한 후불머니 내역입니다.</p><p class="fz-12 fc-0 fw-medium">광고 캠페인에서 실제 소진된 비용(매체비+수수료+부가세)입니다. 해당 비용은 광고 소진일의 +4일 이후 확정된 금액입니다. </p>' });
    $('#pom').tooltip({ content: '<p class="fz-12 fc-0 fw-medium">후불머니의 사용 금액입니다.</p>' });
    $('#atCampRemain').tooltip({ content: '<p class="fz-12 fc-0 fw-medium">캠페인에 할당된 후불머니의 잔액입니다.</p>' });
    $('#atPomAvailable').tooltip({ content: '<p class="fz-12 fc-0 fw-medium">예치금 정산된 금액을 제외한 금액입니다. 표기된 금액만큼 사용이 가능합니다.</p>' });
    $('#note').tooltip({ content: '<p class="fz-12 fc-0 fw-medium">후불머니가 사용된 캠페인명입니다.</p>' });
};
const PomDailyUsageDetailForAdmin = () => {
    const setTitle = useOutletContext<any>();
    const childRef = useRef<any>(null);
    const [isDateValid, setIsDateValid] = useState<boolean>(true);
    const gridRef = useRef<AgGridReact>(null);
    const [rowData, setRowData] = useState<any[]>();
    const [isMakerSelectDialogOpened, setIsMakerSelectDialogOpened] = useState<boolean>(false);
    const [columnDefs] = useState<ColDef[]>([
        { field: 'basicDate', headerName: '날짜', cellRenderer: dateFormatter, cellClass: 'ag-center-aligned-cell', },
        {
            field: 'makerLoginId',
            headerName: '메이커 ID',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-center-aligned-cell',
        },
        {
            field: 'makerNm',
            headerName: '메이커명',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-center-aligned-cell',
        },
        {
            field: 'project2Id',
            headerName: '프로젝트 ID',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-center-aligned-cell',
        },
        {
            field: 'campId',
            headerName: '캠페인 ID',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-center-aligned-cell',
        },
        {
            field: 'pomDiv',
            headerName: '구분',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-center-aligned-cell',
            headerComponentParams: {
                template:
                    '<div class="ag-cell-label-container" role="presentation">'
                    + '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>'
                    + '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">'
                    + '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>'
                    + '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>'
                    + '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>'
                    + '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>'
                    + '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="pomDiv" title=""></a>'
                    + '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>'
                    + '  </div>'
                    + '</div>',
            },
        },
        {
            field: 'pom',
            headerName: '후불머니',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-right-aligned-cell',
            cellRenderer: (props:any) => (props.data.pom === 0 || props.data.pom === undefined ? '-' : props.data.pom.toLocaleString()),
            headerComponentParams: {
                template:
                    '<div class="ag-cell-label-container" role="presentation">'
                    + '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>'
                    + '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">'
                    + '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>'
                    + '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>'
                    + '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>'
                    + '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>'
                    + '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="pom" title=""></a>'
                    + '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>'
                    + '  </div>'
                    + '</div>',
            },
        },
        /** 현재 메이커 상세랑 약간 상이한 부분이 있음, 추가 수정의 여지가 있어 내비 둠. */
        {
            field: 'atCampRemain',
            headerName: '캠페인 후불머니 잔액',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-right-aligned-cell',
            cellRenderer: (props:any) => (props.data.atCampRemain === 0 || props.data.atCampRemain === undefined ? '-' : props.data.atCampRemain.toLocaleString()),
            headerComponentParams: {
                template:
                    '<div class="ag-cell-label-container" role="presentation">'
                    + '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>'
                    + '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">'
                    + '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>'
                    + '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>'
                    + '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>'
                    + '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>'
                    + '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="atCampRemain" title=""></a>'
                    + '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>'
                    + '  </div>'
                    + '</div>',
            },
        },
        {
            field: 'atPomAvailable',
            headerName: '프로젝트 한도 잔액',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-right-aligned-cell',
            cellRenderer: (props:any) => (props.data.atPomAvailable === 0 || props.data.atPomAvailable === undefined ? '-' : props.data.atPomAvailable.toLocaleString()),
            headerComponentParams: {
                template:
                    '<div class="ag-cell-label-container" role="presentation">'
                    + '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>'
                    + '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">'
                    + '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>'
                    + '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>'
                    + '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>'
                    + '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>'
                    + '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="atPomAvailable" title=""></a>'
                    + '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>'
                    + '  </div>'
                    + '</div>',
            },
        },
        {
            field: 'note',
            headerName: '비고',
            headerClass: 'ag-center-aligned-header',
            cellClass: 'ag-left-aligned-cell',
            cellRenderer: (props:any) => props.data.serviceNm + '(' + props.data.serviceId + ')',
            headerComponentParams: {
                template:
                    '<div class="ag-cell-label-container" role="presentation">'
                    + '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>'
                    + '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">'
                    + '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>'
                    + '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>'
                    + '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>'
                    + '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>'
                    + '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><a class="ico-tooltip tooltip-f" id="note" title=""></a>'
                    + '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>'
                    + '  </div>'
                    + '</div>',
            },
        },
    ]);
    const defaultColDef = useMemo<ColDef>(() => ({
        flex: 1,
        resizable: true,
        suppressMovable: true,
        sortable: true,
    }), []);

    const {
        handleSubmit, setValue, getValues, register
    } = useForm<PomDailyUsageDetailForAdminSrchForm>();

    const onGridReady = useCallback(() => {
        const {
            startDate, endDate, srchType, makerIds
        } = getValues();
        ApiUtil2.get<IPomDailyUsageDetailsForAdminList>('/api/bzmAdmin/postpaidMoneyDetail/dailyUsage/detail', {
            params: {
                startDate, endDate, srchType, project2Id: $('#project2Id').val(), makerIds
            },
            paramsSerializer: (params) => qs.stringify(params),
        }).then((resp) => {
            setRowData(resp.data.data);
            initTooltip();
        });
    }, []);

    const downloadHandler = () => {
        window.location.assign(
            `/api/bzmAdmin/postpaidMoneyDetail/dailyUsage/detail/download?startDate=${getValues('startDate')}&endDate=${getValues('endDate')}&srchType=${getValues("srchType")}&project2Id=${$('#project2Id').val()}&makerIds=${getValues("makerIds")}`,
        );
    };
    const changePagination = () => {
        childRef.current!.onPaginationChanged();
    };

    useEffect(() => {
        setTitle('후불머니 상세내역');

        /** 초기 값 */
        setValue('startDate', dayjs().startOf('day').subtract(31, 'd').format('YYYYMMDD'));
        setValue('endDate', dayjs().startOf('day').format('YYYYMMDD'));
        setValue("makerIds", []);

        const getDateDiff = (d1:any, d2:any) => {
            const date1 = new Date(d1);
            const date2 = new Date(d2);
            const diffDate = date1.getTime() - date2.getTime();
            return Math.abs(diffDate / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일
        };

        /** Select2 */
        $('#srchType').select2({
            width: '200px',
            minimumResultsForSearch: Infinity,
            placeholder: '선택하세요.',
        });

        /** datepicker */
        $('#datepicker').daterangepicker({
            maxDate: dayjs().startOf('day'),
            startDate: dayjs().startOf('day').subtract(31, 'd'),
            endDate: dayjs().startOf('day'),
            opens: 'left',
            ranges: {
                '오늘 ': [dayjs().startOf('day'), dayjs().endOf('day')],
                '어제 ': [dayjs().startOf('day').subtract(1, 'days'), dayjs().endOf('day').subtract(1, 'days')],
                '이번 주': [dayjs().startOf('day').day(1), dayjs().day(1).endOf('day').subtract(-6, 'd')],
                '저번 주': [dayjs().startOf('day').subtract(1, 'week').day(1), dayjs().endOf('day').subtract(1, 'week').day(7)],
                '최근 7일': [dayjs().startOf('day').subtract(7, 'd'), dayjs().endOf('day').subtract(1, 'd')],
                '최근 30일': [dayjs().startOf('day').subtract(30, 'd'), dayjs().endOf('day').subtract(1, 'd')],
            },
        }, (start:any, end:any) => {
            const startDate = start.format('YYYYMMDD');
            const endDate = end.format('YYYYMMDD');
            if (getDateDiff(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')) > 92) {
                alertify.error('조회 기간은 최대 93일까지 설정할 수 있습니다.');
                setIsDateValid(false);
                return;
            }
            setIsDateValid(true);
            setValue('startDate', startDate);
            setValue('endDate', endDate);
        });
        initTooltip();

        $('#srchType').select2({
            width: '150px',
            minimumResultsForSearch: Infinity,
        }).on('select2:select', (e : any) => {
            setValue('srchType', e.params.data.id);
        });

        $(document).on('click', '#makerIdsInput', () => {
            setIsMakerSelectDialogOpened(true);
        });

        return () => {
            setIsMakerSelectDialogOpened(false);
            setTitle('');
        };
    }, []);
    return (
        <Fragment>
            <PomForAdminTab/>
            {isMakerSelectDialogOpened ? <MakerSelectDialog setIsMakerSelectDialogOpened={setIsMakerSelectDialogOpened} setValue={setValue}/> : null}
            <div className="comp-tab-content selected">
                <section className="wrap-section wrap-datagrid">
                    <form onSubmit={handleSubmit(onGridReady)}>
                        <div className="wrap-filter">
                            <div className="inner-filter">
                                <div className="box-left">
                                    <div className="item-filter">
                                        <div className="filter-tit">
                                            <p className="fz-12 fc-2">조회기간</p>
                                        </div>
                                        <div className="box-filter">
                                            <div className="comp-datepicker">
                                                <div className="inner-datepicker">
                                                    <i className="ico ico-calendar"></i>
                                                    <input type="text" className="tf-comm datepicker-range" id='datepicker' readOnly/>
                                                    <span className="fz-12 fc-2">사용자 설정</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-filter">
                                        <div className="filter-tit">
                                            <p className="fz-12 fc-2">검색 구분</p>
                                        </div>
                                        <div className="box-filter">
                                            <select className="select2-single w-150"
                                                    id="srchType" {...register('srchType')}>
                                                <option value="ALL" defaultChecked={true}>전체</option>
                                                <option value="EXHAUST">소진</option>
                                                <option value="RETURN_POM">잔액 반환</option>
                                                <option value="EXCEED">초과</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="item-filter">
                                        <div className="filter-tit">
                                            <p className="fz-12 fc-2">프로젝트 ID</p>
                                        </div>
                                        <div className="box-filter">
                                            <div className="input-group comp-search">
                                                <div className="inner-input-group">
                                                    <input id="project2Id" type="text" className="tf-comm" placeholder="프로젝트 id를 입력해 주세요."/>
                                                    <i className="ico ico-search"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-filter">
                                        <div className="filter-tit">
                                            <p className="fz-12 fc-2">메이커 ID</p>
                                        </div>
                                        <div className="box-filter">
                                            <div className="input-group comp-search">
                                                <div className="inner-input-group">
                                                    <input id="makerIdsInput" type="text" className="tf-comm" placeholder="메이커 id를 입력해 주세요."/>
                                                    <i className="ico ico-search"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="box-right">
                                    <button type="submit" className="btn btn-tertiary-mint btn-ico" disabled={!isDateValid}><i className="ico ico-filter"></i> 조회 </button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="box-header">
                        <div className="box-option">
                            <button type="button" className="btn btn btn-secondary-outline btn-ico" onClick={downloadHandler}><i className="ico ico-download"></i>다운로드</button>
                        </div>
                    </div>
                    <div className="box-body">
                        <div className="ag-grid">
                            <div className="ag-grid-inner">
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={rowData}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    rowHeight={48}
                                    pagination={true}
                                    paginationPageSize={10}
                                    suppressPaginationPanel={true}
                                    onPaginationChanged={changePagination}
                                    domLayout={'autoHeight'}
                                    noRowsOverlayComponent={NoDataTemplate}
                                ></AgGridReact>
                            </div>
                        </div>
                    </div>
                    { rowData === undefined ? <NoDataTemplate/> : null}
                    <div className="box-footer">
                        <Pagination gridRef={gridRef} ref={childRef}/>
                    </div>
                </section>
            </div>
        </Fragment>
    );
};

export default PomDailyUsageDetailForAdmin;
