import { useQuery, useQueryClient, type QueryClient } from '@tanstack/react-query'
import alertify from 'alertifyjs'
import { fetchGeoList, type GeoInfoType } from '@biz/api'
import { useCurrentCampaignIdQuery, loadCurrentCampaignId } from '../useCurrentCampaignIdQuery'

const queryRoot = '@biz/query/createTargetAd/LocationList'
const queryFunction = (props: { campId: number; query: string }) =>
  fetchGeoList(props).then((res) => {
    if (res?.ok === true) {
      const geoList = (res?.data || []) as GeoInfoType[]
      const cityRegionIdList = geoList.filter((n) => n.type === 'city').map((n) => `${n.regionId}`)
      return geoList.filter((n) => n.type !== 'region' || !cityRegionIdList.includes(n.key))
    }
    const { msg, title } = res?.data as { msg: string; title: string }
    alertify.error(msg || '지역 정보를 가져오지 못했습니다', { title })
  })

export const useLocationListQuery = ({ keyword }: { keyword?: string }) => {
  const queryClient = useQueryClient()
  const { data: campId } = useCurrentCampaignIdQuery()
  const enabled = !!campId && !!keyword && keyword.length > 0
  const queryKey = [queryRoot, `${campId}-${keyword}`]
  return {
    ...useQuery({
      queryKey,
      queryFn: () => (enabled ? queryFunction({ campId, query: keyword }) : Promise.resolve([])),
      enabled,
    }),
    update: (updateData: string[]) => queryClient.setQueryData(queryKey, updateData),
  }
}

export const loadLocationList =
  (queryClient: QueryClient) =>
  async ({ keyword }: { keyword: string }) => {
    const campId = Number(await loadCurrentCampaignId(queryClient)())
    const queryKey = [queryRoot, `${campId}-${keyword}`]
    return (
      queryClient.getQueryData(queryKey) ??
      (await queryClient.fetchQuery({
        queryKey,
        queryFn: () => queryFunction({ campId, query: keyword }),
      }))
    )
  }
