import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import dayjs, { Dayjs } from 'dayjs';
import { DataFormat } from 'select2';
import { useForm } from 'react-hook-form';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Link } from 'react-router-dom';
import { useNavigate, useOutletContext } from 'react-router';
import NoDataTemplate from '../common/NoDataTemplate';
import Pagination from '../common/Pagination';
import { ApiUtil2 } from '@biz/api'
import RangeDatePicker from './RangeDatePicker';
import SingleSelect2 from './SingleSelect2';
import DlDialog from "../mngMakerAccount/DlDialog";

interface IMngAdjustSearchForm {
  startDate: string;
  endDate: string;
  adjustDiv: string | undefined,
  taxbillYn: string | undefined,
  searchType: string | undefined,
  keyword?: string | undefined,
}

// type Select2Ids = 'adjustDiv' | 'taxbillYn' | 'searchType';

const adjustDetailLinkRenderer = (props: ICellRendererParams) => {
  const { adjustId } = props.data;
  return <Link className="txt-link" to={`/accAdjust/mng/info?id=${adjustId}`}>{props.value}</Link>;
};

const AccAdjustMng = () => {
  const setTitle = useOutletContext<any>();
  const navigate = useNavigate();
  const childRef = useRef<any>(null);
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>();
  const [isDlDialogOpened, setIsDlDialogOpened] = useState<boolean>(false);
  const [dlParam, setDlParam] = useState<any>(null);


  // form
  const {
    register, setValue, handleSubmit, getValues,
  } = useForm<IMngAdjustSearchForm>();

  // datepicker
  const registerDate = (start:Dayjs, end:Dayjs) => {
    setValue('startDate', start.format('YY-MM-DD'));
    setValue('endDate', end.format('YY-MM-DD'));
  };

  const defaultColDef: ColDef = {
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: false,
    headerClass: 'ag-center-aligned-header',
    cellClass: 'ag-center-aligned-cell',
  };

  const columnDefs: Array<ColDef> = [
    {
      field: 'makerLoginId', headerName: '메이커ID', cellRenderer: adjustDetailLinkRenderer, cellClass: 'ag-left-aligned-cell',
    },
    {
      field: 'makerNm', headerName: '메이커명', cellClass: 'ag-left-aligned-cell',
    },
    { field: 'userNo', headerName: '충전 사업자 번호' },
    { field: 'adjustDivDesc', headerName: '방식' },
    { field: 'taxbillYnDesc', headerName: '세금계산서 발행 여부' },
    { field: 'bzmPaid', headerName: '임의조정 유상', cellClass: 'ag-right-aligned-cell' },
    { field: 'bzmFree', headerName: '임의조정 쿠폰', cellClass: 'ag-right-aligned-cell' },
    { field: 'regTime', headerName: '임의조정 일시' },
    { field: 'regName', headerName: '진행자' },
  ];

  const onGridReady = () => {
    const data:IMngAdjustSearchForm = getValues();
    ApiUtil2.get('/api/accAdjust/mng/list', {
      params: {
        data: { ...data },
      },
    }).then((resp) => {
      setRowData(resp.data.data);
    });
  };

  const changePagination = () => {
    childRef?.current?.onPaginationChanged();
  };

  const downloadHandler = () => {
    setDlParam({
      ...getValues(),
    });
    setIsDlDialogOpened(true);
    // const url = `/api/accAdjust/mng/list/download?data=${encodeURIComponent(JSON.stringify(data))}`;
    // window.location.assign(url);
  };

  const adjustDivs:DataFormat[] = [{ id: '', text: '전체', selected: true }, { id: 'single', text: '단일조정' }, { id: 'at', text: '금액이동' }];
  const taxbillYns:DataFormat[] = [{ id: '', text: '전체', selected: true }, { id: 'true', text: '발행' }, { id: 'false', text: '미발행' }];
  const searchTypes:DataFormat[] = [{ id: 'makerId', text: '메이커ID', selected: true }, { id: 'makerName', text: '메이커명' }, { id: 'userNo', text: '충전 사업자 번호' }];

  const updateAdjustDiv = (data: any) => {
    setValue('adjustDiv', data);
  };

  const updateTaxbillYn = (data: any) => {
    setValue('taxbillYn', data);
  };

  const updateSearchType = (data: any) => {
    setValue('searchType', data);
  };

  useEffect(() => {
    setTitle('임의조정 관리');
    // const adjustDivs:DataFormat[] = [{ id: '', text: '전체', selected: true }, { id: 'single', text: '단일조정' }, { id: 'at', text: '금액이동' }];
    // makeSelect2('adjustDiv', adjustDivs);
    //
    // const taxbillYns:DataFormat[] = [{ id: '', text: '전체', selected: true }, { id: 'true', text: '발행' }, { id: 'false', text: '미발행' }];
    // makeSelect2('taxbillYn', taxbillYns);
    //
    // const searchTypes:DataFormat[] = [{ id: 'makerId', text: '메이커ID', selected: true }, { id: 'makerName', text: '메이커명' }, { id: 'userNo', text: '충전 사업자 번호' }];
    // makeSelect2('searchType', searchTypes, 180);
  }, []);

  return (
    <Fragment>
      {/* Wrap-Datagrid : Start */}
      <section className="wrap-section wrap-datagrid">
        {/* Wrap-Filter : Start */}
        <form onSubmit={handleSubmit(onGridReady)}>
          <div className="wrap-filter">
            <div className="inner-filter">
              <div className="box-left">
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">조회기간</p>
                  </div>
                  <div className="box-filter">
                    <RangeDatePicker changeEvent={registerDate} option={{ startDate: dayjs().startOf('day').subtract(30, 'd') }} />
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">임의조정 방식</p>
                  </div>
                  <div className="box-filter">
                    {/* <select className="select2-single w-150" id="adjustDiv" /> */}
                    <SingleSelect2 data={adjustDivs} selectEvent={updateAdjustDiv} />
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">세금계산서 발행 여부</p>
                  </div>
                  <div className="box-filter">
                    {/* <select className="select2-single w-150" id="taxbillYn" /> */}
                    <SingleSelect2 data={taxbillYns} selectEvent={updateTaxbillYn} />
                  </div>
                </div>
                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">검색 구분</p>
                  </div>
                  <div className="box-filter">
                    {/* <select className="select2-single w-150" id="searchType" /> */}
                    <SingleSelect2 data={searchTypes} selectEvent={updateSearchType} />
                  </div>
                </div>

                <div className="item-filter">
                  <div className="filter-tit">
                    <p className="fz-12 fc-2">검색어</p>
                  </div>
                  <div className="box-filter">
                    <div className="input-group comp-search">
                      <div className="inner-input-group">
                        <input type="text" className="tf-comm"
                               placeholder="검색어를 입력해 주세요." {...register('keyword')}></input>
                        <i className="ico ico-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-right">
                <button type="submit" className="btn btn-tertiary-mint btn-ico"><i className="ico ico-filter"></i>필터 조회
                </button>
              </div>
            </div>
          </div>
        </form>
        {/* Wrap-Filter : End */}
        <div className="box-header">
          <div className="box-tit">
            <button type="button" className="btn btn btn-primary" onClick={() => navigate('/accAdjust/mng/regist')}>임의조정 등록</button>
          </div>
          <div className="box-option">
            <button type="button" className="btn btn btn-secondary-outline btn-ico" onClick={downloadHandler}><i className="ico ico-download"></i>다운로드
            </button>
          </div>
        </div>

        <div className="box-body">
          <div className="ag-grid">
            <div className="ag-grid-inner">
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                onGridReady={() => onGridReady()}
                rowHeight={48}
                pagination={true}
                paginationPageSize={10}
                suppressPaginationPanel={true}
                onPaginationChanged={changePagination}
                domLayout={'autoHeight'}
                noRowsOverlayComponent={NoDataTemplate}
              ></AgGridReact>
            </div>
          </div>
        </div>
        <div className="box-footer">
           <Pagination gridRef={gridRef} ref={childRef}/>
        </div>
      </section>
      {/* Wrap-Datagrid : End */}
      {
        isDlDialogOpened ?
          <DlDialog setIsDlDialogOpened={setIsDlDialogOpened}
                    dlUrl={'/api/accAdjust/mng/list/download'}
                    dlParam={dlParam}
          /> : null
      }
    </Fragment>
  );
};
export default AccAdjustMng;
