import React, {
  Fragment, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import NoDataTemplate from '../common/NoDataTemplate';
import { ApiUtil2 } from '@biz/api'

const MakerSelectDialog = (props:any) => {
  const addedMakerIds:any[] = [];
  let makerIdOrNm = '';
  const gridRefOfSrchedMakers = useRef<AgGridReact>(null);
  const [rowDataOfSrchedMakers, setRowDataOfSrchedMakers] = useState<any[]>();
  const defaultColDefOfSrchedMakers = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: true,
  }), []);

  const gridRefOfSelectedMakers = useRef<AgGridReact>(null);
  const defaultColDefOfSelectedMakers = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: true,
  }), []);

  const delBtnRenderer = (e:any) => <button className='btn btn-primary small' id={`del-${e.data.id}`} data-id={e.data.id} style={{ width: '100%', height: '100%' }}>삭제</button>;
  const [selectedMakers] = useState<ColDef[]>([
    {
      field: 'makerLoginId',
      headerName: '메이커ID',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
    },
    {
      field: 'makerNm',
      headerName: '메이커명',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
    },
    {
      cellRenderer: delBtnRenderer,
    },
  ]);

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      $(document).on('click', '[id^=add-]', (e) => {
        const { id } = e.currentTarget.dataset
        const { makerloginid } = e.currentTarget.dataset
        const { makernm } = e.currentTarget.dataset

        if (gridRefOfSelectedMakers.current !== null) {
          addedMakerIds.push({ id, makerLoginId: makerloginid, makerNm: makernm })
          $(`#add-${id}`).prop('disabled', true)
          gridRefOfSelectedMakers.current!.api.setRowData(addedMakerIds)
          $('#selectedCnt').text(`선택 대상 (${addedMakerIds.length}개 계정 선택)`)
        }
      })

      $(document).on('click', '[id^=del-]', (e) => {
        const { id } = e.currentTarget.dataset
        addedMakerIds.splice(0)
        if (gridRefOfSelectedMakers.current !== null) {
          gridRefOfSelectedMakers.current!.api.forEachNode((node) => {
            const { data } = node
            if (data.id !== id) {
              addedMakerIds.push(data)
            }
          })
          gridRefOfSelectedMakers.current!.api.setRowData(addedMakerIds)
          $(`#add-${id}`).prop('disabled', false)
          $('#selectedCnt').text(`선택 대상 (${addedMakerIds.length}개 계정 선택)`)
        }
      })
    }
    return () => {
      isSubscribed = false
      $(document).off('click', '[id^=add-]')
    }
  }, [])

  const addBtnRenderer = (e: any) => {
    let addDisabled = false
    addedMakerIds.forEach((disabled: any) => {
      if (`${e.data.makerId}` === `${disabled.id}`) {
        addDisabled = true
      }
    })
    return (
      <button
        className="btn btn-primary small"
        style={{ width: '100%', height: '100%' }}
        id={`add-${e.data.makerId}`}
        data-id={e.data.makerId}
        data-makerloginid={e.data.makerLoginId}
        data-makernm={e.data.makerNm}
        disabled={addDisabled}
      >
        추가
      </button>
    )
  }

  const [srchedMakers] = useState<ColDef[]>([
    {
      field: 'makerLoginId',
      headerName: '메이커ID',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
    },
    {
      field: 'makerNm',
      headerName: '메이커명',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
    },
    {
      cellRenderer: addBtnRenderer,
      cellRendererParams: {
        clicked(field: any) {
          alert(`${field} was clicked`)
        },
      },
    },
  ])

  const onGridReady = useCallback(() => {
    $('#makerSelectDialog').dialog({
      title: '조회 대상 선택',
      width: 1200,
      height: 900,
    })
    $('#dialogBody').css('height', '90%')
    $('.dialog-footer').css('height', '10%')
  }, [])

  const onGridReadyOfSrchedMakers = useCallback(() => {
    ApiUtil2.get('/api/bzmAdmin/detail/srchMaker', {
      params: {
        data: {
          makerIdOrNm,
        },
      },
    }).then((resp) => {
      setRowDataOfSrchedMakers(resp.data.data)
      onGridReady()
    })
  }, [setRowDataOfSrchedMakers, onGridReady])

  useEffect(() => {
    props.setValue('makerIds', [])
    $('#makerIdsInput').val('')
    //  $('#makerSelectDialog').dialog({
    //    title: '조회 대상 선택',
    //    width: 1200,
    //    height: 900,
    //  })

    let isSubscribe = true
    if (isSubscribe) {
      $(document).on('click', '#makerSelectDialogCloseBtn', () => {
        $('#makerSelectDialog').dialog('close')
        props.setIsMakerSelectDialogOpened(false)
      })

      $(document).on('click', '#makerSelectDialogBtn', () => {
        console.log('add')
        const arrIds: any[] = []
        addedMakerIds.forEach((item: any) => {
          arrIds.push(item.id)
        })
        props.setValue('makerIds', arrIds)
        if (addedMakerIds.length > 0) {
          $('#makerIdsInput').val(`${addedMakerIds[0].makerLoginId} 외 ${addedMakerIds.length - 1} 개의 계정`)
        }
        $('#makerSelectDialog').dialog('close')
        props.setIsMakerSelectDialogOpened(false)
      })

      $(document).on('keyup', '#makerIdOrNm', (e: any) => {
        const value = $('#makerIdOrNm').val() as string
        makerIdOrNm = value
        if (e.keyCode === 13) {
          onGridReadyOfSrchedMakers()
          return false
        }
        return true
      })

      $(document).on('click', '#srchBtn', () => {
        onGridReadyOfSrchedMakers()
      })
    }

    return () => {
      //  props.setIsMakerSelectDialogOpened(false)
      isSubscribe = false
      if ($('#makerSelectDialog').length > 0 && $.data($('#makerSelectDialog')[0], 'dialog')) {
        $('#makerSelectDialog').dialog('destroy')
      }
    }
  }, [])

  return (
    <Fragment>
      <form>
        <div id="makerSelectDialog" className="dialog" style={{ display: 'none' }}>
          <div className="dialog-body" id="dialogBody">
            <div className="container-fluid">
              <div className="row">
                <div className="col col-6">
                  <div className="wrap-section wrap-tbl">
                    <div className="wrap-filter">
                      <div className="inner-filter">
                        <div className="box-left">
                          <div className="item-filter">
                            <div className="box-filter">
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="tf-comm"
                                  id="makerIdOrNm"
                                  placeholder="조회하실 메이커ID나 메이커명을 입력해 주세요."
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="box-right">
                          <button type="button" id="srchBtn" className="btn btn-primary">
                            조회
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="box-body">
                      <div className="ag-grid">
                        <div className="ag-grid-inner">
                          <AgGridReact
                            ref={gridRefOfSrchedMakers}
                            rowData={rowDataOfSrchedMakers}
                            columnDefs={srchedMakers}
                            defaultColDef={defaultColDefOfSrchedMakers}
                            onGridReady={onGridReady}
                            rowHeight={48}
                            domLayout={'autoHeight'}
                            noRowsOverlayComponent={NoDataTemplate}
                          ></AgGridReact>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col col-6">
                  <div className="wrap-section wrap-tbl">
                    <div className="wrap-filter">
                      <div className="inner-filter">
                        <div className="box-left">
                          <div className="item-filter">
                            <div className="box-filter">
                              <div className="input-group">
                                <span className="fz-12 fc-2" id="selectedCnt">
                                  선택 대상 ({addedMakerIds.length}개 계정 선택)
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="box-body">
                      <div className="ag-grid">
                        <div className="ag-grid-inner">
                          <AgGridReact
                            ref={gridRefOfSelectedMakers}
                            columnDefs={selectedMakers}
                            defaultColDef={defaultColDefOfSelectedMakers}
                            rowHeight={48}
                            domLayout={'autoHeight'}
                            // noRowsOverlayComponent={NoDataTemplate}
                          ></AgGridReact>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dialog-footer">
            <button type="button" className="btn btn-secondary-outline large" id="makerSelectDialogCloseBtn">
              취소
            </button>
            <button type="button" className="btn btn-primary large" id="makerSelectDialogBtn">
              확인
            </button>
          </div>
        </div>
      </form>
    </Fragment>
  )
};

export default MakerSelectDialog;
