import { Modal, Button } from 'antd'
import styles from './EditModeConfirmModal.module.scss'

type EditModeConfirmModalFunction = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}
export const EditModeConfirmModal: React.FC<EditModeConfirmModalFunction> = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal
      width={600}
      open={isOpen}
      closable={false}
      title={<div className={styles.modalTitle}>{'소재를 수정 하시겠습니까?'}</div>}
      footer={[
        <div className={styles.modalFooter}>
          <Button type="default" block size="large" onClick={onClose} style={{ width: 200 }}>
            {'취소'}
          </Button>
          <Button type="primary" block size="large" onClick={onConfirm} style={{ width: 200 }}>
            {'수정하기'}
          </Button>
        </div>,
      ]}
      className={styles.containEditModeConfirmModal}
    >
      <div className={styles.customBody}>
        <div>수정이 완료된 이후에는 “변경요청” 버튼을 눌러주세요</div>
        <div>'변경요청'을 진행하지 않은 경우 수정된 소재 정보가 SNS 매체에 반영되지 않습니다.</div>
      </div>
    </Modal>
  )
}
