import React, {
  Fragment, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { useOutletContext } from 'react-router-dom';
import NoDataTemplate from '../common/NoDataTemplate';
import { ApiUtil2 } from '@biz/api'

const dateFormatter = (props: any) => {
  const year = props.data.expireDate.substring(0, 4);
  const month = props.data.expireDate.substring(4, 6);
  const day = props.data.expireDate.substring(6, 8);
  return `${year}-${month}-${day}`;
};
const costRenderer = (e: any) => (e.data.cost === 0 || e.data.cost === undefined ? '-' : e.data.cost.toLocaleString());
const freeRenderer = (e: any) => (e.data.free === 0 || e.data.free === undefined ? '-' : e.data.free.toLocaleString());
const paidRenderer = (e: any) => (e.data.paid === 0 || e.data.paid === undefined ? '-' : e.data.paid.toLocaleString());
const totalRenderer = (e: any) => (e.data.total === 0 || e.data.total === undefined ? '-' : e.data.total.toLocaleString());
const BzmStatus = () => {
  const [isUserNoClicked, setIsUserNoClicked] = useState<boolean>(false);
  const setTitle = useOutletContext<any>();
  const [userNoText, setUserNoText] = useState('');
  /** 비즈머니 현황 상세 */
  const gridRefOfBzmStatusDetails = useRef<AgGridReact>(null);
  const [rowDataOfBzmStatusDetails, setRowDataOfBzmStatusDetails] = useState<any[]>();
  const [columnDefOfBzmStatusDetails] = useState<ColDef[]>([
    {
      field: 'costDiv',
      headerName: '유형',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'cost',
      headerName: '금액',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: costRenderer,
    },
    {
      field: 'expireDate',
      headerName: '유효기간',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: dateFormatter,
    },
  ]);
  const gridRefOfBzmStatus = useRef<AgGridReact>(null);
  const userNoRenderer = (e: any) => {
    setIsUserNoClicked(false);
    const userNoHandler = () => {
      setUserNoText(e.data.userNo);
      setIsUserNoClicked(true);
      ApiUtil2.get('/api/bzm/status/detail', {
        params: {
          userNo: e.data.userNo,
        },
      }).then((resp) => {
        if (resp.data.data.length === 0) {
          setRowDataOfBzmStatusDetails([{ costDiv: '-', cost: '-', expireDate: '-' }]);
        } else {
          setRowDataOfBzmStatusDetails(resp.data.data);
        }
      });
    };
    return <button onClick={userNoHandler}>{e.data.userNo}</button>;
  };
  const [rowDataOfBzmStatus, setRowDataOfBzmStatus] = useState<any[]>();
  const [columnDefOfBzmStatus] = useState<ColDef[]>([
    {
      field: 'liveAccYn',
      headerName: '상태',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
    },
    {
      field: 'userNo',
      headerName: '충전사업자번호',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-center-aligned-cell',
      cellRenderer: userNoRenderer,
    },
    {
      field: 'free',
      headerName: '쿠폰',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: freeRenderer,
    },
    {
      field: 'paid',
      headerName: '유상비즈머니',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: paidRenderer,
    },

    {
      field: 'total',
      headerName: '합계',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-right-aligned-cell',
      cellRenderer: totalRenderer,
    },
  ]);

  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: true,
  }), []);

  const onGridReadyOfBzmStatus = useCallback(() => {
    const tempArr:any[] = [];
    ApiUtil2.get('/api/bzm/status', {}).then((resp) => {
      if (resp.data.data.length < 1) {
        setRowDataOfBzmStatus([]);
      }
      resp.data.data.forEach((v:any) => {
        if (v.total > 0) {
          tempArr.push(v);
        }
        if (tempArr.length >= 1) {
          setRowDataOfBzmStatus(tempArr);
        } else {
          setRowDataOfBzmStatus([]);
        }
      });
    });
  }, []);

  useEffect(() => {
    setTitle('비즈머니 현황');
    return () => {
      setTitle('');
      setIsUserNoClicked(false);
    };
  }, []);
  return (
        <Fragment>
            <div className="comp-tab-content selected">
                <section className="wrap-section wrap-datagrid">
                    <div className="box-header">
                        <span className="fz-14 fc-4 bullet">잔액이 남아있는 정보만 표시됩니다.</span>
                    </div>
                    <div className="box-body">
                        <div className="ag-grid">
                            <div className="ag-grid-inner">
                                <AgGridReact
                                    ref={gridRefOfBzmStatus}
                                    rowData={rowDataOfBzmStatus}
                                    columnDefs={columnDefOfBzmStatus}
                                    defaultColDef={defaultColDef}
                                    onGridReady={onGridReadyOfBzmStatus}
                                    rowHeight={48}
                                    domLayout={'autoHeight'}
                                    noRowsOverlayComponent={NoDataTemplate}
                                ></AgGridReact>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {isUserNoClicked
              ? <div className="comp-tab-content selected">
                    <section className="wrap-section wrap-datagrid">
                        <div className="box-header">
                            <span className="fz-14 fc-4 bullet">{userNoText}</span>
                        </div>
                        <div className="box-body">
                            <div className="ag-grid">
                                <div className="ag-grid-inner">
                                    <AgGridReact
                                        ref={gridRefOfBzmStatusDetails}
                                        rowData={rowDataOfBzmStatusDetails}
                                        columnDefs={columnDefOfBzmStatusDetails}
                                        defaultColDef={defaultColDef}
                                        rowHeight={48}
                                        domLayout={'autoHeight'}
                                    ></AgGridReact>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
              : null
            }
        </Fragment>
  );
};

export default BzmStatus;
