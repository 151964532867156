import styles from './ErrorPage.module.scss'

export const ErrorPage: React.FC = () => (
  <>
    <h2 className={styles.header}>
      죄송합니다.
      <br />
      현재 찾을 수 없는 페이지를 요청하셨습니다.
    </h2>
    <p className={styles.message}>
      존재하지 않는 주소를 입력하셨거나, 요청하신 페이지의 주소가 변경, 삭제되어 찾을 수 없습니다.
      <br />
      궁금한 점이 있으시면 언제든 고객센터를 통해 문의해 주시기 바랍니다.
    </p>
  </>
)
