import { Modal, Button } from 'antd'
import styles from './GifTypeSelector.module.scss'

type GifTypeSelectorFunction = {
  isOpen: boolean
  onSelect: (sel: 'vod' | 'img' | undefined) => void
}
export const GifTypeSelector: React.FC<GifTypeSelectorFunction> = ({ isOpen, onSelect }) => (
  <Modal
    width={600}
    open={isOpen}
    closable={false}
    title={<div className={styles.modalTitle}>{'업로드하고자 하는 소재의 유형을 선택해주세요.'}</div>}
    footer={[
      <div className={styles.modalFooter} key={'git-type-select-footer'}>
        <Button type="default" block size="large" onClick={() => onSelect('img')} style={{ width: 200 }}>
          {'이미지'}
        </Button>
        <Button type="primary" block size="large" onClick={() => onSelect('vod')} style={{ width: 200 }}>
          {'영상'}
        </Button>
      </div>,
    ]}
    className={styles.containGifTypeSelector}
  >
    <div className={styles.customBody}>{'움직이는 GIF이미지 업로드를 원하실 경우 반드시 영상을 선택해주세요.'}</div>
  </Modal>
)
