import { thousandsGrouping } from '@common/utils'
import { type PaymentInfoType } from '@biz/api'
import { StarComment } from '../../StarComment'
import { BizMoneyChargeButton } from './BizMoneyChargeButton'
import styles from './PaymentConfirmInfo.module.scss'

type PaymentConfirmInfoFunction = {
  info?: PaymentInfoType
  payType?: 'POST_PAYMENT' | 'BIZ_MONEY' | null
  currentCost?: number
}
export const PaymentConfirmInfo: React.FC<PaymentConfirmInfoFunction> = ({ info, payType, currentCost }) => {
  const { campBudgetTotal2, campBudgetTotalVat } = info || {}
  return (
    <div className={styles.containPaymentConfirmInfo}>
      <div className={styles.payment}>
        <div className={styles.paySection}>
          <div className={styles.payType}>{payType === 'BIZ_MONEY' ? '비즈머니 잔액' : '후불머니 사용가능 잔액'}</div>
          <div>{`${thousandsGrouping(currentCost || 0)} 원`}</div>
        </div>
        {payType === 'BIZ_MONEY' && <BizMoneyChargeButton />}
      </div>
      <div className={styles.confirmPayment}>
        <span>{`'확인'버튼을 누르면 `}</span>
        <span style={{ color: '#00c4c4' }}>{thousandsGrouping(campBudgetTotal2 || 0)}</span>
        <span>{`원이 결제됩니다.`}</span>
      </div>
      <StarComment>
        <span>{'(10% 부가세 금액 '}</span>
        <span style={{ color: '#00c4c4' }}>{thousandsGrouping(campBudgetTotalVat || 0)}</span>
        <span>{'원이 결제됩니다.'}</span>
      </StarComment>
    </div>
  )
}
