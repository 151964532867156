import { Form, Tooltip } from 'antd'
import { ReactComponent as HelpIcon } from '@common/svg/help.svg'
import { CampaignCaption } from '@biz/ui'
import styles from './CampaignObjective.module.scss'

export const CampaignObjective: React.FC = () => (
  <div>
    <CampaignCaption>캠페인 목표</CampaignCaption>
    <dd>
      <Form.Item>
        <div role="presentation" className={styles.tag}>
          {'전환'}
          <Tooltip
            title={'전환 최대화 및 전환당비용 최소화를 목표로 캠페인이 진행됩니다'}
            arrow={false}
            placement="bottomLeft"
            trigger="hover"
          >
            <div className={styles.icon}>
              <HelpIcon />
            </div>
          </Tooltip>
        </div>
        {/* <div role="presentation" className={styles.tag}>
          {'트래픽'}
          < tooltip={'클릭 최대화 및 클릭당비용 최소화를 목표로 캠페인이 진행됩니다.'} />
        </div>
        <div role="presentation" className={styles.tag}>
          {'트래픽'}
          < tooltip={'클릭 최대화 및 클릭당비용 최소화를 목표로 캠페인이 진행됩니다.'} />
        </div> */}
      </Form.Item>
    </dd>
  </div>
)
