import { axios } from '../axios'
import type { CTAListType, CTAListDescriptionType } from './ctaListType'
import type { CategoryType } from './categoryType'
import type { MediaType, MediaDescriptionType } from './mediaType'
import type { CampaignStatusType, CampaignStatusDescriptionType } from './campaignStatus'
import type { CreationViewStatusType, CreationViewStatusDescriptionType } from './creationViewStatusTable'
import type { ImageInfoType } from './fetchImageList'

type CustomTimeType = {
  date: { year: number; month: number; day: number }
  time: { hour: number; minute: number; second: number; nano: number }
}
export type CreativeDataType = {
  actYn: boolean
  adDesc: string
  adTitle01: string
  adTitle02: string
  afList: ImageInfoType[]
  clickBtnDiv: CTAListType // "LEARN_MORE_FB"
  editStatus: string // "none"
  id: number // 263175571
  sortOrder: number // 1
  validYn: boolean //  false
  wabizInspStatus: string // "none"
  wabizInspConfirmTime: string // "202411071448"
  // creationViewStatus: CreationViewStatusType
  wabizInspReqDate: any // {date: {…}, time: {…}}
}
export type CreativesManageDataType = {
  actyn: boolean // true
  adDesc: string // ''
  adSetId: number // 263175569
  adSetOrder: number // 1
  adTitle01: string // ''
  adTitle02: string //''
  adminCampYn: boolean // false
  af: []
  campEndTime: CustomTimeType
  campId: number // 263175568
  campNm: string // '임시_202409291431'
  campPeriodValid: boolean
  campStartTime: CustomTimeType
  campViewStatus: CampaignStatusType
  campViewStatusDesc: CampaignStatusDescriptionType
  catCode: string // 'B0800'
  catCodeDesc: string // '디자인 > 문구'
  catDiv: CategoryType
  clickBtnDiv: CTAListDescriptionType
  creationData: CreativeDataType
  creationDataId: string // '263175571'
  creationViewStatus: CreationViewStatusType
  creationViewStatusDesc: CreationViewStatusDescriptionType
  id: number // 263175572
  mediaConfigSnsAdId: string // '367441705393622'
  mediaDiv4: MediaType // 'FBITG'
  mediaDiv4Des: MediaDescriptionType // '페이스북&인스타그램'
  orgHierarchy: []
  projectId: string // 'F26767'
  snsApiOkYn: boolean // false
  snsInspStatus: string // 'NONE'
  sortOrder: number // 1
  syncYn: boolean // false bchgcyc
  useYn: boolean //  true
  wabizInspAdminId: string // 'null'
  syncLastTime: string // "202411071448"
}

type ReturnType = {
  ok: boolean
  data: CreativesManageDataType[]
}

type FunctionType = (campaignId: number) => Promise<ReturnType>
export const fetchCreativesManageData: FunctionType = (campaignId) =>
  axios.get(`/api/camp/make02/fbCreationDataSearch?campId=${campaignId}`)
