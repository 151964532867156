import React, { useMemo } from 'react';
import { ITooltipParams } from 'ag-grid-community';

// eslint-disable-next-line react/display-name
export default (props: ITooltipParams & { color: string }) => {
  const data = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex!)!.data,
    [],
  );

  return (
        <div className="ag-grid-tooltip">
            <p className="fz-12 fc-0" dangerouslySetInnerHTML={{ __html: data.desc }}></p>
        </div>
  );
};
