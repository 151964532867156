import {Link, useLocation} from "react-router-dom";
import React, {Fragment} from "react";

const PostpaidMoneyManagementTab = () => {
    const url = useLocation();
    return (
        <Fragment>
            <div className="comp-tab">
                <Link to="/bzmAdmin/postpaidMoneyManagement" className={url.pathname === '/bzmAdmin/postpaidMoneyManagement' ? 'tab selected' : 'tab' }>
                    <span className="tab-item">차감대상 조정</span>
                </Link>
                <Link to="/bzmAdmin/postpaidMoneyManagement/AEM" className={url.pathname === '/bzmAdmin/postpaidMoneyManagement/AEM' ? 'tab selected' : 'tab' }>
                    <span className="tab-item">허용 사업자 관리</span>
                </Link>
                {/* 해당 기능 임시 주석 */}
                {/*<Link to="/bzmAdmin/postpaidMoneyManagement/PL" className={url.pathname === '/bzmAdmin/postpaidMoneyManagement/PL' ? 'tab selected' : 'tab' }>*/}
                {/*    <span className="tab-item">한도 설정</span>*/}
                {/*</Link>*/}
                <Link to="/bzmAdmin/postpaidMoneyManagement/inquiry" className={url.pathname === '/bzmAdmin/postpaidMoneyManagement/inquiry' ? 'tab selected' : 'tab' }>
                    <span className="tab-item">한도 증액 신청 내역</span>
                </Link>
            </div>
        </Fragment>
    );
};
export default PostpaidMoneyManagementTab
