import { axios } from '../axios'

type TurnOnType = {
  adSetId: string | number
  campId: number
  useYn: boolean
}

type ResponseType = {
  ok: boolean
  data: Partial<TurnOnType>
}

type FunctionType = (props: TurnOnType) => Promise<Partial<ResponseType>>
export const fetchPostAdSetTurnOn: FunctionType = (props) => axios.post('/api/camp/make02/fbAdSetOnOffUpdate', props)
