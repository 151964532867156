import React, {
  Fragment, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useOutletContext } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { useNavigate } from 'react-router';
import { ApiUtil2 } from '@biz/api'
import NoDataTemplate from '../common/NoDataTemplate';
import alertify from 'alertifyjs'
import AdminRoleRegModal from './AdminRoleRegModal';

const MngAdminRole = () => {
  const navigator = useNavigate();
  const [adminRoleId, setAdminRoleId] = useState(null);
  const [adminRoleData, setAdminRoleData] = useState<any>([]);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const setTitle = useOutletContext<any>();
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>();
  const [initArray, setInitArray] = useState<any[]>();
  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'depth_1',
      headerName: '1depth',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
    },
    {
      field: 'depth_2',
      headerName: '2depth',
      headerClass: 'ag-center-aligned-header',
      cellClass: 'ag-left-aligned-cell',
    },
  ]);
  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: false,
    suppressMovable: true,
    sortable: true,
  }), []);

  const onGridReady = useCallback(() => {
    ApiUtil2.get('/api/mngAccount/admin/authoritiesByMenu', {
    }).then((resp) => {
      setRowData(resp.data.data);
    });
  }, []);

  const adminRoleInfoReady = useCallback(() => {
    ApiUtil2.get('/api/mngAccount/admin/adminRoles').then((resp) => {
      setAdminRoleId(resp.data.data[0].adminRoleId);
      setAdminRoleData(resp.data.data);
      setIsDataLoaded(true);
    });
  }, []);

  const saveHandler = () => {
    const selectedRows = gridRef.current!.api.getSelectedRows();
    const selectedMenuAuthorities = selectedRows.map((menuData) => menuData.menuRole.attrib).join();
    ApiUtil2.post('/api/mngAccount/admin/adminRoles/regAuthoritiesOfMenu', { adminRoleId, adminRoleList: selectedMenuAuthorities }).then(() => {
      // onGridReady();
      alertify.success('정상적으로 등록 되었습니다.');
      setInitArray(selectedRows);
    });
  };

  const delHandler = () => {
    alertify.confirm('선택하신 권한을 삭제하시겠습니까? <br> 삭제하신 권한은 복구할 수 없습니다.', () => {
      ApiUtil2.post('/api/mngAccount/admin/adminRoles/delete', { adminRoleId }).then(() => {
        adminRoleInfoReady();
        alertify.success('정상적으로 삭제 되었습니다.');
      });
    }).set({ labels: { cancel: '취소', ok: '삭제' } }).setHeader('');
  };

  useEffect(() => {
    adminRoleInfoReady();
    setTitle('관리자 권한 관리');
    return () => {
      setIsDataLoaded(false);
      setTitle('');
    };
  }, []);

  useEffect(() => {
    /** 최초 0번 인덱스 체크박스 셀렉 */
    if (isDataLoaded === true) {
      let init: any[] = [];
      init = [];
      init.push(adminRoleData[0].adminRoles.split(','));
      setInitArray(init);
        gridRef.current!.api.forEachNode((node: any) => {
          for (let i = 0; i < init[0].length; i += 1) {
            if (node.data.menuRole.attrib === init[0][i]) {
              node.setSelected(true);
            }
          }
        });
        gridRef.current!.api.redrawRows();
    }
  }, [isDataLoaded]);

  const cancelHandler = () => {
    if (initArray?.length !== gridRef.current!.api.getSelectedRows().length && initArray![0].length !== gridRef.current!.api.getSelectedRows().length) {
      alertify.confirm('변경하신 내용이 있습니다. <br> 변경하신 내용을 저장하지 않고 이전 화면으로 이동할까요?', () => {
        navigator('/mngAccount/admin');
      }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
    } else if (initArray?.length !== 0 ? initArray![0].length === gridRef.current!.api.getSelectedRows().length : initArray!.length === gridRef.current!.api.getSelectedRows().length) {
      navigator('/mngAccount/admin');
    } else {
      navigator('/mngAccount/admin');
    }
  };

  const TrItem = (item: any) => {
    const adminRoleHandler = (data:any) => {
      ApiUtil2.get('/api/mngAccount/admin/adminRoles/getAuthoritiesOfMenuByAdminRoleId', {
        params: {
          data: {
            adminRoleId: item.item.adminRoleId,
          },
        },
      }).then((resp) => {
          gridRef.current!.api.deselectAll();
          gridRef.current!.api.forEachNode((node:any) => {
            for (let i = 0; i < resp.data.data.length; i += 1) {
              if (node.data.menuRole.attrib === resp.data.data[i]) {
                node.setSelected(true);
              }
            }
          });
      });

      setAdminRoleId(data.adminRoleId);
    };

    return (
          <tr onClick={() => adminRoleHandler(item.item)}>
              <td className={item.item.adminRoleId === adminRoleId ? 'selected' : ''} id={item.item.adminRoleId}>
                  <span className="fz-16 fc-3">{item.item.adminRoleNm} / {item.item.count}</span>
              </td>
          </tr>
    );
  };
  const modalHandler = () => {
    setIsModalOpened(true);
  };
  return (
        <Fragment>
            {isModalOpened ? <AdminRoleRegModal onGridReady={onGridReady} setIsModalOpened={setIsModalOpened} adminRoleInfoReady={adminRoleInfoReady}/> : null}
        <section className="wrap-section wrap-tbl">
            <div className="box-body">
                <div className="tbl">
                    <dl className="vertical">
                        <dd>
                            <div className="form-group">
                                <div className="form-group">
                                    <div className="container-fluid">
                                        <div className="row flex-container">
                                            <div className="col col-4">
                                                <div className="wrap-section wrap-tbl">
                                                    <div className="box-header">
                                                        <div className="box-tit">
                                                            <h2 className="fz-20 fc-1 fw-bold">권한</h2>
                                                        </div>
                                                        <div className="box-option">
                                                            <button type="button" className="btn btn-primary w-100" onClick={modalHandler}>신규 등록</button>
                                                            <button type="button" className="btn btn-secondary-outline w-100" onClick={delHandler}>삭제
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="box-body">
                                                        <table className="tbl selected group-list">
                                                            <colgroup>
                                                                <col width="50%"/>
                                                            </colgroup>
                                                            <tbody>
                                                            {adminRoleData.map((item:any, index:any) => <TrItem key={index} index={index} item={item}/>)}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col col-8">
                                                <section className="wrap-section wrap-datagrid">
                                                    <div className="box-header">
                                                        <div className="box-tit">
                                                            <h2 className="fz-20 fc-1 fw-bold">메뉴별 사용 권한</h2>
                                                        </div>
                                                    </div>
                                                    <div className="box-body">
                                                        <AgGridReact
                                                            ref={gridRef}
                                                            rowData={rowData}
                                                            columnDefs={columnDefs}
                                                            defaultColDef={defaultColDef}
                                                            onGridReady={onGridReady}
                                                            rowHeight={48}
                                                            domLayout={'autoHeight'}
                                                            noRowsOverlayComponent={NoDataTemplate}
                                                            rowSelection={'multiple'}
                                                            suppressRowClickSelection={true}
                                                        ></AgGridReact>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </dl>
                </div>
            </div>
            <div className="box-footer">
                <div className="box-right">
                    <button type="button" className="btn btn-secondary-outline" onClick={cancelHandler}
                    >취소
                    </button>
                    <button type="button" className="btn btn-primary" onClick={saveHandler}>저장</button>
                </div>
            </div>
        </section>
        </Fragment>
  );
};

export default MngAdminRole;
