import { axiosInstance } from '../axios'

export type ProductType = {
  sectionCode: string
  adProduct: string
  adDiv: 'display' | 'push'
}
type ResponseType = {
  ok: boolean
  data: ProductType[]
}

type FunctionType = () => Promise<ResponseType>
export const fetchProducts: FunctionType = () =>
  axiosInstance
    .get('/api/mngRpt/sales/dp/products', {
      headers: {
        'Content-Type': 'application/json;',
      },
    })
    .then((res) => res.data)
