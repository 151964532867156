import React, {
  Fragment, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridSizeChangedEvent, ICellRendererParams } from 'ag-grid-community';
import NoDataTemplate from '../common/NoDataTemplate';
import Pagination from '../common/Pagination';
import { ApiUtil2 } from '@biz/api'
import alertify from 'alertifyjs'
import MngTaxbillDetailHis from './MngTaxbillDetailHis';

const MngTaxbillDetail = () => {
  const setTitle = useOutletContext<any>();
  const { taxbillId } = useParams();
  const navigate = useNavigate();
  const gridRef: any = useRef(null);
  const childRef = useRef<any>(null);
  const [isTaxbillHisOpened, setIsTaxbillHisOpened] = useState<boolean>(false);
  const [rowData, setRowData] = useState<any[]>();
  const columnDefs = [
    {
      headerName: '대상 일지',
      children: [
        {
          field: 'basicDate',
          headerName: '일자',
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-center-aligned-cell',
        },
      ],
    },
    {
      headerName: '유상 비즈머니',
      children: [
        {
          field: 'paidBzmOut',
          headerName: '광고 소진액',
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-right-aligned-cell',
          cellRenderer: (props: ICellRendererParams) => (String(props!.data.paidBzmOut).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')),
        },
        {
          field: 'paidAdtVat',
          headerName: '대행 수수료',
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-right-aligned-cell',
          cellRenderer: (props: ICellRendererParams) => (String(props!.data.paidAdtVat).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')),
        },
        {
          field: 'paidBzmIn',
          headerName: '환급액',
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-right-aligned-cell',
          cellRenderer: (props: ICellRendererParams) => (String(props!.data.paidBzmIn).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')),
        },
      ],
    },
    {
      headerName: '쿠폰',
      children: [
        {
          field: 'freeOut',
          headerName: '광고 소진액',
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-right-aligned-cell',
          cellRenderer: (props: ICellRendererParams) => (String(props!.data.freeOut).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')),
        },
        {
          field: 'freeAdtVat',
          headerName: '대행 수수료',
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-right-aligned-cell',
          cellRenderer: (props: ICellRendererParams) => (String(props!.data.freeAdtVat).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')),
        },
        {
          field: 'freeIn',
          headerName: '환급액',
          headerClass: 'ag-center-aligned-header',
          cellClass: 'ag-right-aligned-cell',
          cellRenderer: (props: ICellRendererParams) => (String(props!.data.freeIn).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')),
        },
      ],
    },
  ];
  const [taxbillInfo, setTaxbillInfo] = useState<any>(undefined);
  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    resizable: true,
    suppressMovable: true,
    sortable: true,
    // autoHeight: true,
  }), []);
  const onGridReady = useCallback(() => {
    ApiUtil2.get('/api/mngTaxbill/mngTaxbill/detail', {
      params: {
        id: taxbillId,
      },
    }).then((resp) => {
      const res = resp.data.data;
      setTaxbillInfo(res.taxbillInfo);
      setRowData(res.gridData);
    });
  }, []);

  const onGridSizeChanged = (params: GridSizeChangedEvent) => {
    params.api.sizeColumnsToFit();
  };
  const changePagination = () => {
    childRef.current!.onPaginationChanged();
  };

  const taxbillPub = () => {
    alertify.confirm('세금계산서를 발행하시겠습니까?', () => {
      ApiUtil2.post('/api/mngTaxbill/mngTaxbill/detail/pub', { taxbillId }).then((resp) => {
        if (resp.data.ok === true) {
          if (resp.data.data.taxbillStatus === 'fail') alertify.error(resp.data.data.errMsg);
          else alertify.success('정상적으로 발행 되었습니다.\n');
        } else {
          alertify.error(resp.data.data.msg);
        }
        setTaxbillInfo(resp.data.data);
      });
    }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
  };
  const taxbillCancel = () => {
    alertify.confirm('발행 된 세금계산서를 취소 하시겠습니까?<br>취소의 경우 `-`의 수정 세금계산서가 발행됩니다.', () => {
      ApiUtil2.post('/api/mngTaxbill/mngTaxbill/detail/cancel', { taxbillId }).then((resp) => {
        if (resp.data.ok === true) {
          if (resp.data.data.taxbillStatus === 'fail') alertify.error(resp.data.data.errMsg);
          else alertify.success('정상적으로 발행 되었습니다.\n');
        } else {
          alertify.error(resp.data.data.msg);
        }
        setTaxbillInfo(resp.data.data);
      });
    }).set({ labels: { cancel: '취소', ok: '확인' } }).setHeader('');
  };
  const taxbillHis = () => {
    if (!isTaxbillHisOpened) {
      setIsTaxbillHisOpened(true);
    }
  };
  const setIsTaxbillHisOpenedEvent = () => {
    setIsTaxbillHisOpened(false);
  };
  useEffect(() => {
    setTitle('세금계산서 관리');
    return () => {
      setIsTaxbillHisOpened(false);
      setTitle('');
    };
  }, []);
  return (
        <Fragment>
            <div className="wrap-section wrap-tbl">
                {isTaxbillHisOpened ? <MngTaxbillDetailHis setIsTaxbillHisOpenedEvent={setIsTaxbillHisOpenedEvent} taxbillId={taxbillId} /> : null }
                <div className="box-header">
                    <div className="box-tit">
                        <button type="button" className="btn btn-secondary-outline" onClick={() => navigate(-1)}>이전 목록으로</button>
                    </div>
                    <div className="box-option">
                        {
                            taxbillInfo?.taxbillCancellable === false && taxbillInfo?.taxbillStatus !== 'reserved'
                              ? <button type="button" className="btn btn-primary" onClick={() => taxbillPub()}>발행</button> : null
                        }
                        {
                            taxbillInfo?.taxbillCancellable === true
                              ? <button type="button" className="btn btn-danger-outline" onClick={() => taxbillCancel()}>발행취소</button> : null
                        }
                        <button type="button" className="btn btn-info-outline" onClick={taxbillHis}>히스토리</button>
                    </div>
                </div>
                <div className="box-body">
                    <div className="tbl">
                        <dl className="vertical">
                            <dd>
                                <div className="form-group">
                                    <div className="wrap-section wrap-tablegrid">
                                        <div className="box-header">
                                            <div className="box-tit">
                                                <h2 className="fz-20 fc-1 fw-bold">발급 대상 정보</h2>
                                            </div>
                                        </div>
                                        <div className="box-body">
                                            <div className="tbl">
                                                <table>
                                                    <colgroup>
                                                        <col width="10%" />
                                                    </colgroup>
                                                    <thead>
                                                    <tr>
                                                        <th>메이커ID</th>
                                                        <th>사업자번호/주민번호</th>
                                                        <th>구분</th>
                                                        <th>상호</th>
                                                        <th>대표자명 / 개인 성명</th>
                                                        <th>이메일</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{taxbillInfo?.makerLoginId}</td>
                                                            <td>{taxbillInfo?.userNo}</td>
                                                            <td>{taxbillInfo?.bizDiv}</td>
                                                            <td>{taxbillInfo?.comName}</td>
                                                            <td>{taxbillInfo?.comRepNm}</td>
                                                            <td>{taxbillInfo?.comEmail}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wrap-section wrap-tablegrid">
                                        <div className="box-header">
                                            <div className="box-tit">
                                                <h2 className="fz-20 fc-1 fw-bold">발급 항목</h2>
                                            </div>
                                        </div>
                                        <div className="box-body">
                                            <div className="tbl">
                                                <table>
                                                    <colgroup>
                                                        <col width="10%"></col>
                                                        <col width="400"></col>
                                                    </colgroup>
                                                    <thead>
                                                    <tr>
                                                        <th>공급일자</th>
                                                        <th>항목</th>
                                                        <th>공급가액</th>
                                                        <th>부가세</th>
                                                        <th>합계</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        taxbillInfo?.items?.map((it:any) => (
                                                            <tr key={it}>
                                                                <td>{it?.basicDate}</td>
                                                                <td>{it?.subject}</td>
                                                                <td>{String(it?.supplyPrice).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}</td>
                                                                <td>{String(it?.tax).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}</td>
                                                                <td>{String(it?.total).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}</td>
                                                            </tr>))
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wrap-section wrap-datagrid wrap-double-header">
                                        <div className="box-header">
                                            <div className="box-tit">
                                                <h2 className="fz-20 fc-1 fw-bold">일자별 내역</h2>
                                            </div>
                                        </div>
                                        <div className="box-body">
                                            <div className="ag-grid">
                                                <div className="ag-grid-inner">
                                                    <AgGridReact
                                                        ref={gridRef}
                                                        rowData={rowData}
                                                        columnDefs={columnDefs}
                                                        defaultColDef={defaultColDef}
                                                        onGridReady={onGridReady}
                                                        onGridSizeChanged={onGridSizeChanged}
                                                        rowHeight={48}
                                                        domLayout={'autoHeight'}
                                                        pagination={true}
                                                        paginationPageSize={10}
                                                        suppressPaginationPanel={true}
                                                        noRowsOverlayComponent={NoDataTemplate}
                                                        onPaginationChanged={changePagination}
                                                    ></AgGridReact>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-footer">
                                            <Pagination gridRef={gridRef} ref={childRef}/>
                                        </div>
                                    </div>
                                </div>
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
        </Fragment>
  );
};

export default MngTaxbillDetail;
