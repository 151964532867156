import React from 'react'
import type { AIComment } from '@biz/api'

import styles from './AIInspectionHistoryContent.module.scss'

type CommentGroups = {
  [key: string]: Omit<AIComment, 'commentType'>[]
}

interface AIInspectionHistoryContentProps {
  comments: AIComment[]
}

/**
 * AI 검수 이력 컨텐츠
 */
export const AIInspectionHistoryContent = ({ comments = [] }: AIInspectionHistoryContentProps) => {
  const sectionNameMap = {
    adTitle01: '광고 제목',
    adTitle02: '광고 설명',
    adDesc: '본문',
    img: '이미지',
    video: '동영상',
    none: '검수 이력 데이터 오류',
  }

  // commentType(본문, 동영상, 이미지, 설명 등) 별로 분류되도록 데이터 재정렬
  const commentGroups: CommentGroups | undefined = comments.reduce((acc, comment) => {
    const { commentType, ...rest } = comment

    if (!acc[commentType]) {
      acc[commentType] = []
    }
    acc[commentType]!.push(rest)
    return acc
  }, {} as CommentGroups)

  if (!commentGroups) return null

  return (Object.keys(commentGroups) as Array<AIComment['commentType']>).map((group, index) => {
    const groupName = group.split('-')[0] as keyof typeof sectionNameMap // 섹션 타입
    const groupOrder = group.split('-')[1] // 섹션 타입 순서

    // 검수 이력 데이터 오류의 경우 commentType이 none으로 내려온다
    if (group === 'none') return <span style={{ fontStyle: 'italic' }}>잠시 후 다시 시도해 주세요.</span>

    return (
      <div className={styles.container} key={group}>
        {/* <div className={styles.section}> */}
        <strong className={styles.title}>
          {sectionNameMap[groupName]}
          {(groupName === 'img' || groupName === 'video') && ` (${groupOrder}번)`}
          {/* 이미지, 동영상의 경우 여러장 있을 수 있으므로 순서 표기 */}
        </strong>

        <ul>
          {commentGroups[group]?.map((comment) => {
            return (
              <li className={styles.comment} key={comment.id}>
                <span className={styles.text}>{comment.text}</span>
                <p className={styles.reason}>{comment.reason}</p>
                <p className={styles.suggestion}>{comment.suggestion}</p>
              </li>
            )
          })}
        </ul>
      </div>
    )
  })
}
