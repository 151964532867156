import React, {
  Fragment, useEffect,
} from 'react';
import { useNavigate } from 'react-router';

const PgErrorDialog = (props:any) => {
  const navigate = useNavigate();

  useEffect(() => {
    /** 결제 에러 다이얼로그 */
    $('#pgErrorDialog').dialog({
      title: '결제 에러 안내', width: 900, closeOnEscape: true,
    });

    /** 취소 */
    $(document).on('click', '#pgErrorConfirmBtn', () => {
      $('#pgErrorDialog').dialog('close');
      props.setPgModalStatus(null);
      navigate('/bzm/charge');
    });

    return () => {
      $('#pgErrorDialog').dialog('close');
      $('#pgErrorDialog').dialog('destroy');
    };
  }, []);

  return (
    <Fragment>
      <form>
        <div id="pgErrorDialog" className="dialog" style={{ display: 'none' }}>
          <div className="dialog-body">
            <div className="wrap-section wrap-tbl">
              <div className="box-body">
                <div className="tbl">
                  <dl className="vertical">
                      <dd>
                          <div className="form-group">
                              <p className="comp-txt center">
                                <span className="table">
                                    <span className="table-cell">
                                        <b className="fz-20 fc-1 fw-medium">에러가 발생했습니다.</b>
                                        <b className="fz-20 fc-1 fw-medium">확인 후 다시 시도해주세요.</b>
                                    </span>
                                </span>
                              </p>
                          </div>
                      </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
            <div className="dialog-footer">
                <button type="button" className="btn btn-primary large" id='pgErrorConfirmBtn'>확인</button>
            </div>
        </div>
      </form>
    </Fragment>
  );
};
export default PgErrorDialog;
