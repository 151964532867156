import React from 'react'

interface FailCreationDialogProps {
  campId: number | undefined
  creationDataId: string | null
  isStopCreation?: boolean
  getPrevData: any
}
/**
 * 소재 반려(중단) 모달
 */
const FailCreationDialog = ({ campId, creationDataId, isStopCreation }: FailCreationDialogProps) => {
  return (
    <>
      <form>
        <div id="returnDialog" className="dialog" style={{ display: 'none' }}>
          <div className="dialog-body">
            <div className="wrap-section wrap-tbl no-border">
              <div className="box-body">
                <div className="input-group expand">
                  <div className="inner-input-group">
                    <input id="campId" type="hidden" defaultValue={campId!!} />
                    <input id="creationDataId" type="hidden" defaultValue={creationDataId?.toString()} />
                    <textarea
                      id="msg"
                      className="tf-textarea"
                      placeholder={`${isStopCreation ? '중단' : '반려'} 사유를 입력해주세요.`}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dialog-footer">
            <button type="button" className="btn btn-secondary-outline" id="closeBtn">
              취소
            </button>
            <button type="button" className="btn btn btn-primary" id="okBtn">
              {isStopCreation ? '중단' : '반려'}하기
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

export default FailCreationDialog
