import React, { useEffect, useRef } from 'react'
import dayjs from 'dayjs'
import alertify from 'alertifyjs'

export const daterangepickerCreationOption = {
  opens: 'left',
  ranges: {
    오늘: [dayjs().startOf('day'), dayjs().endOf('day').subtract(59, 'm')],
    어제: [dayjs().startOf('day').subtract(1, 'days'), dayjs().endOf('day').subtract(59, 'm').subtract(1, 'days')],
    '이번 주': [dayjs().startOf('day').day(0), dayjs().day(1).endOf('day').subtract(-7, 'd').subtract(59, 'm')],
    '저번 주': [
      dayjs().startOf('day').subtract(1, 'week').day(0),
      dayjs().endOf('day').subtract(1, 'week').day(6).subtract(59, 'm'),
    ],
    '최근 7일': [dayjs().startOf('day').subtract(7, 'd'), dayjs().endOf('day').subtract(1, 'd').subtract(59, 'm')],
    '최근 30일': [dayjs().startOf('day').subtract(30, 'd'), dayjs().endOf('day').subtract(1, 'd').subtract(59, 'm')],
  },
  startDate: dayjs().startOf('day').subtract(7, 'd'),
  endDate: dayjs().endOf('day').subtract(1, 'd').subtract(59, 'm'),
  maxSpan: { days: 30 },
}

const RangeDatePicker2 = (props: any) => {
  const datepicker = useRef<any>()

  const getDateDiff = (d1: any, d2: any) => {
    const date1 = new Date(d1)
    const date2 = new Date(d2)
    const diffDate = date1.getTime() - date2.getTime()
    return Math.abs(diffDate / (1000 * 60 * 60 * 24)) // 밀리세컨 * 초 * 분 * 시 = 일
  }

  useEffect(() => {
    window.console.debug('date range picker')

    let option = {
      ...daterangepickerCreationOption,
      ...props.option,
    }

    $(datepicker.current)
      .daterangepicker(option, (start: any, end: any) => {
        if (getDateDiff(start, end) > 30) {
          alertify.error('조회 기간은 최대 30일까지 설정할 수 있습니다.')
        }
        option.startDate = start
        option.endDate = end
        option = { ...daterangepickerCreationOption, ...option }
        props.changeEvent(option.startDate, option.endDate)
        $('#datepicker').val(`${option.startDate.format('YY-MM-DD')} ~ ${option.endDate.format('YY-MM-DD')}`)
        $('#datepickerSpan').text($('#datepicker').data('daterangepicker').chosenLabel)
      })
      .on('apply.daterangepicker', function (ev: any, picker: any) {
        if (
          getDateDiff(picker.startDate, picker.endDate) < 30 &&
          picker.startDate !== picker.oldStartDate &&
          picker.endDate !== picker.oldEndDate
        ) {
          props.onGridReady()
        }
      })

    props.changeEvent(option.startDate, option.endDate)
  }, [])

  return (
    <div className={`comp-datepicker ${props.disabled ? 'disabled' : ''}`}>
      <div className="inner-datepicker">
        <i className="ico ico-calendar"></i>
        <input
          type="text"
          id="datepicker"
          className={`tf-comm datepicker-range right`}
          readOnly
          disabled={props.disabled}
          ref={datepicker}
        />
        <span className="fz-12 fc-2" id="datepickerSpan">
          최근 7일
        </span>
      </div>
      <button type="button" className="btn btn-prev"></button>
      <button type="button" className="btn btn-next"></button>
    </div>
  )
}

export default RangeDatePicker2
