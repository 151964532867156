import alertify from 'alertifyjs'

// useAttachDocumentQuery의 파일리스트 타입을 흉내냄
type AttachedDocumentSubType = {
  file?: File
  fileName?: string
  fileSize?: number
}
const allowFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf']
const maximumFileSize = 30 * 1024 * 1024
type BeforeUploadFunctionType = (fileList: AttachedDocumentSubType[]) => (file: File) => Promise<boolean>
export const generateBeforeUpload: BeforeUploadFunctionType = (fileList) => async (file) => {
  if (file.name.length > 99) {
    alertify.error('파일 이름이 너무 깁니다.\n' + '100 글자를 넘지않게 해주세요.')
    return false
  }
  if (fileList.map((n) => n.file?.name || n.fileName || '').includes(file.name)) {
    alertify.error(`'${file.name}'과 같은 이름의 파일이 이미 선택되어 있습니다.\n 이름을 바꾸어서 다시 시도해주세요.`)
    return false
  }
  if (!allowFileTypes.includes(file.type)) {
    alertify.error('첨부 파일은 30MB 이내의 PDF, JPG, JPEG, PNG 파일만 \n' + '업로드가 가능합니다.')
    return false
  }
  if (fileList.length >= 10) {
    alertify.error('첨부 파일은 최대 10개까지만 업로드 가능합니다.\n')
    return false
  }
  if (fileList.reduce((sum, n) => sum + Number(n.file?.size || n.fileSize), 0) + file.size > maximumFileSize) {
    alertify.error('업로드 가능한 용량을 초과하였습니다.\n' + '30MB 이내')
    return false
  }
  return true
}
