import { useQuery } from '@tanstack/react-query'
import { fetchBizNumberListInfo } from '@biz/api'

export const useBizNumberListInfoQuery = () =>
  useQuery({
    queryKey: ['@biz/query/useBizNumberListInfo'],
    queryFn: () =>
      fetchBizNumberListInfo().then((res) => {
        const { all, changeable, reason, usingUserNo } = res.data
        const allList = all.map(({ userNo, bizDiv, projectId }) => ({ userNo, bizDiv, projectId }))

        // GeneralChangeDialog 파일 29 라입 이후 코드를 참고
        // 구조체가 모양이 이상해서 하나로 만든다. { userNo, bizDiv, projectId }
        /** 활성 사업자 번호가 없는 경우 */
        if (usingUserNo === undefined) {
          return { list: allList }
        }
        /** 활성 사업자번호가 있고, 충전 사업자번호 변경 가능 조건이 해당하지 않는 경우 */
        if (!changeable) {
          return { list: [usingUserNo], current: usingUserNo, disabled: true }
        }
        /** 활성 사업자번호가 있고, 충전 사업자 번호 변경 가능 조건이 가능한 경우 */
        return {
          list: [usingUserNo, ...allList.filter((n) => n.userNo !== usingUserNo.userNo)],
          disabled: false,
        }
      }),
  })
