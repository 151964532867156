import { Header, Footer } from '../layout'
import Login from './Login'
import styles from './AdminLoginPage.module.scss'

export const AdminLoginPage: React.FC = () => {
  return (
    <div className={`${styles.AdminLoginPage} wrap login`}>
      <Header />
      <div className="content">
        <div className="inner-content">
          <div className="content-body">
            <Login />
          </div>
          <footer className="content-footer">
            <Footer />
          </footer>
        </div>
      </div>
    </div>
  )
}
